import React from "react";
import { connect } from "react-redux";
import {
  CardHeader,
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { isFunction } from "lodash";

export const Dialog = ({
  open,
  setOpen,
  children,
  title,
  action,
  ...props
}) => {
  const close = () => {
    isFunction(setOpen) && setOpen(false);
  };

  return (
    <MuiDialog {...{ ...props, open }}>
      <DialogTitle>
        <CardHeader
          {...{ title }}
          action={
            <>
              <IconButton onClick={close}>
                <AiOutlineClose />
              </IconButton>
              {action}
            </>
          }
        />
      </DialogTitle>
      {children}
    </MuiDialog>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
