import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import Store from "./config/store";
import { StoreProvider } from "./stores/setupContext";
import App from "./App";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import * as serviceWorker from "./serviceWorkerRegistration";
import Axios from "axios";
// import { createStore } from "./stores/createStore";

// Sentry.init({
//   dsn: "https://53fecc23a02440908fda2f5aae574ddf@restigo-sentry.restigo.co.il/1",
//   integrations: [
//     new Integrations.BrowserTracing(),
//     new Sentry.Integrations.Breadcrumbs({
//       console: false,
//     }),
//   ],
//   ignoreErrors: ["ResizeObserver loop limit exceeded"],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// window.onReceiveFCMToken();
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={Store}>
    <StoreProvider>
      <App />
    </StoreProvider>
  </Provider>
);

//service worker that saving the index.html in the cache , just uncomment to register it .

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register(`${process.env.PUBLIC_URL}/service-worker.js`)
//       .then((registration) => {
//         console.log(
//           "Service Worker registered with scope:",
//           registration.scope
//         );
//       })
//       .catch((error) => {
//         console.error("Service Worker registration failed:", error);
//       });
//   });
// }

// "analyze": "cross-env REACT_APP_INTERACTIVE_ANALYZE=1 yarn build",
// "start": "craco start",
// "build": "craco build",
// "test": "craco test",
// "start": "react-scripts start",
// "build": "react-scripts --max_old_space_size=4096 build",
// "test": "react-scripts test",
