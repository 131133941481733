import { cloneDeep, isNumber, isNaN } from "lodash";
import { isObject } from "lodash-es";
import React, { useMemo } from "react";
import {
  FaPencilAlt,
  FaPlus,
  FaUserTimes,
  FaStarOfDavid,
} from "react-icons/fa";

import ButtonIcon from "../../../components/buttons/ButtonIcon";
import BtnTooltip from "../../../components/tooltips/BtnTooltip";
import { COLOR_RESTIGO_PRIMARY } from "../../../const/colors";
import { BsClockHistory } from "react-icons/bs";

import {
  thousandSeparator,
  twoZeroAfterDot,
  fixDate,
  getHebDay,
  fixHour,
  getSafe,
  fixPrice,
} from "../../../helpers";

export const transfer_hidden = [
  "employee_name",
  "employee_number",
  "week_day",
  "role_name",
  "hours_175",
  "hours_200",
  "break",
  "id",
  "shift_id",
  "role_id",
  "notes",
  "deleted",
  "permanent",
  "department_id",
  "standby",
  "actual_start",
  "actual_end",
  "manual_start",
  "manual_end",
  "clock_start",
  "clock_end",
  "planned_start",
  "planned_end",
  "trim_start",
  "trim_end",
  "salary_type",
  "global_salary",
  "employee_number",
];
export const transfer_aggregates = [
  { field: "hours_100", aggregate: "sum" },
  { field: "hours_125", aggregate: "sum" },
  { field: "hours_150", aggregate: "sum" },
  { field: "hours_150_shabat", aggregate: "sum" },
  { field: "hours_in_holiday", aggregate: "sum" },
  { field: "hours_175", aggregate: "sum" },
  { field: "hours_200", aggregate: "sum" },
  { field: "hours_total", aggregate: "sum" },
  { field: "break", aggregate: "sum" },
  { field: "cost", aggregate: "sum" },
];

export const transfer_columns = [
  {
    Header: ({ title }) => <h3>{title}</h3>,
    id: "title",
    Footer: "",
    columns: [
      {
        Header: "שם עובד",
        accessor: "employee_name",
        Footer: 'סה"כ',
      },
      {
        Header: "מ. עובד",
        accessor: "employee_number",
      },
      {
        Header: "תאריך",
        accessor: "working_date",
        Cell: (props) => `${fixDate(props.row.original.working_date)}`,
        Footer: 'סה"כ',
      },
      {
        Header: "יום",
        accessor: "week_day",
        Cell: (props) => getHebDay(props.row.original.week_day),
      },
      {
        Header: "תפקיד",
        accessor: "role_name",
        Cell: (props) =>
          props.row.original.role_name
            ? props.row.original.role_name
            : "לא שובץ",
      },
      {
        Header: "סוג עלות",
        accessor: "salary_type",
      },
      {
        Header: "שכר גלובלי",
        accessor: "global_salary",
        Cell: (props) =>
          `${thousandSeparator(
            twoZeroAfterDot(props.row.original.global_salary)
          )}`,
      },
      {
        Header: "שכר",
        accessor: "salary",
        Cell: (props) =>
          `${thousandSeparator(twoZeroAfterDot(props.row.original.salary))}`,
      },
      {
        Header: "סניף מעביר",
        accessor: "source_branch_name",
      },
      {
        Header: "סניף קולט",
        accessor: "destination_branch_name",
      },
      {
        Header: "שעת התחלה",
        accessor: "actual_start",
        Cell: (props) => `${fixHour(props.row.original.actual_start)}`,
      },
      {
        Header: "שעת סיום",
        accessor: "actual_end",
        Cell: (props) => `${fixHour(props.row.original.actual_end)}`,
      },
      {
        Header: "התחלה - מנהל",
        accessor: "manual_start",
        Cell: (props) => `${fixHour(props.row.original.manual_start)}`,
      },
      {
        Header: "סיום - מנהל",
        accessor: "manual_end",
        Cell: (props) => `${fixHour(props.row.original.manual_end)}`,
      },
      {
        Header: "התחלה - שעון",
        accessor: "clock_start",
        Cell: (props) => `${fixHour(props.row.original.clock_start)}`,
      },
      {
        Header: "סיום - שעון",
        accessor: "clock_end",
        Cell: (props) => `${fixHour(props.row.original.clock_end)}`,
      },
      {
        Header: "התחלה - חיתוך",
        accessor: "trim_start",
        Cell: (props) => `${fixHour(props.row.original.trim_start)}`,
      },
      {
        Header: "סיום - חיתוך",
        accessor: "trim_end",
        Cell: (props) => `${fixHour(props.row.original.trim_end)}`,
      },
      {
        Header: "הערות",
        accessor: "notes",
      },
      {
        Header: "100%",
        accessor: "hours_100",
        Cell: (props) => mergeEventRows(props, "hours_100"),

        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.hours_100 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "125%",
        accessor: "hours_125",
        Cell: (props) => mergeEventRows(props, "hours_125"),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.hours_125 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "150%",
        accessor: "hours_150",
        Cell: (props) => mergeEventRows(props, "hours_150"),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.hours_150 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות שבת",
        accessor: "hours_150_shabat",
        Cell: (props) => mergeEventRows(props, "hours_150_shabat"),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.hours_150_shabat + sum,
                0
              ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות חג",
        accessor: "hours_in_holiday",
        Cell: (props) => mergeEventRows(props, "hours_in_holiday"),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.hours_in_holiday + sum,
                0
              ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "175%",
        accessor: "hours_175",
        Cell: (props) => mergeEventRows(props, "hours_175"),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.hours_175 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "200%",
        accessor: "hours_200",
        Cell: (props) => mergeEventRows(props, "hours_200"),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.hours_200 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: 'סה"כ שעות',
        accessor: "hours_total",
        Cell: (props) => mergeEventRows(props, "hours_total"),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce((sum, row) => row.values.hours_total + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "הפסקות",
        accessor: "break",
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.break + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "עלות",
        accessor: "cost",
        Cell: (props) => {
          const { row } = props;
          if (!row.original.cost) {
            return "";
          }
          return (
            <div className="money">
              {" "}
              <span>&#8362;</span>
              {thousandSeparator(twoZeroAfterDot(props.row.original.cost))}
            </div>
          );
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.cost + sum, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
    ],
  },
];

const sumFooter = (rows, key) =>
  rows.reduce(
    (sum, row) =>
      row.depth ? sum : (row.original[key] ? row.original[key] : 0) + sum,
    0
  );

export const waiter_hidden = [
  "working_days",
  "employee_accountancy_number",
  "employee_identifier",
  "hours_100_waiter",
  "hours_125_waiter",
  "hours_150_waiter",
  "hours_150_shabat_waiter",
  "hours_in_holiday_waiter",
  "hours_175_waiter",
  "hours_200_waiter",
  "hours_total_waiter",
  "social_hours_waiter",
  "hours_125_no_waiter",
  "hours_150_no_waiter",
  "hours_150_shabat_no_waiter",
  "hours_in_holiday_no_waiter",
  "hours_175_no_waiter",
  "hours_200_no_waiter",
  "social_hours_no_waiter",
  "hours_total_no_waiter",
  "hebrew_create_date",
  "employee_number",
  "role_name",
  "meal_cost",
  "travel_cost",
  "vacation",
  "sick",
  "reserve",
  "tips_allowance",
  "tips_cash",
  "tips_complete",
  "tips_credit",
  "tips_total",
  "waiting_minutes",
  "waiting_salary",
  "cash_tip_before_allowance",
  "credit_tip_before_allowance",
  "tips_total_before_allowance",
  "allowance_percentage",
  "bonus",
  "hours_total_both",
  "neto_for_payment",
  "cost_raw",
  "current_salary_type",
  "salary",
];
export const waiter_aggregates = [
  { field: "hours_100_no_waiter", aggregate: "sum" },
  { field: "hours_125_no_waiter", aggregate: "sum" },
  { field: "hours_150_no_waiter", aggregate: "sum" },
  { field: "hours_150_shabat_no_waiter", aggregate: "sum" },
  { field: "hours_in_holiday_no_waiter", aggregate: "sum" },
  { field: "hours_175_no_waiter", aggregate: "sum" },
  { field: "hours_200_no_waiter", aggregate: "sum" },
  { field: "hours_total_no_waiter", aggregate: "sum" },
  { field: "social_hours_no_waiter", aggregate: "sum" },
  { field: "hours_100_waiter", aggregate: "sum" },
  { field: "hours_125_waiter", aggregate: "sum" },
  { field: "hours_150_waiter", aggregate: "sum" },
  { field: "hours_150_shabat_waiter", aggregate: "sum" },
  { field: "hours_in_holiday_waiter", aggregate: "sum" },
  { field: "hours_175_waiter", aggregate: "sum" },
  { field: "hours_200_waiter", aggregate: "sum" },
  { field: "hours_total_waiter", aggregate: "sum" },
  { field: "hours_total_both", aggregate: "sum" },
  { field: "social_hours_waiter", aggregate: "sum" },
  { field: "meal_cost", aggregate: "sum" },
  { field: "travel_cost", aggregate: "sum" },
  { field: "cash_tip_before_allowance", aggregate: "sum" },
  { field: "credit_tip_before_allowance", aggregate: "sum" },
  { field: "tips_total_before_allowance", aggregate: "sum" },
  { field: "allowance_percentage", aggregate: "sum" },
  { field: "tips_allowance", aggregate: "sum" },

  { field: "bonus", aggregate: "sum" },
  { field: "neto_for_payment", aggregate: "sum" },
  { field: "total_including_tips", aggregate: "sum" },
  { field: "tip_bruto_and_bonus", aggregate: "sum" },
  { field: "tip_bonus", aggregate: "sum" },
  { field: "vacation", aggregate: "sum" },
  { field: "sick", aggregate: "sum" },
  { field: "reserve", aggregate: "sum" },
];

export const waiter_columns = [
  {
    Header: "שם פרטי",
    accessor: "employee_first_name",
  },
  {
    Header: "שם משפחה",
    accessor: "employee_last_name",
  },
  {
    Header: "ת.ז.",
    accessor: "employee_identifier",
  },
  {
    Header: "מחלקה",
    accessor: "department_name",
    Cell: ({ row }) => {
      return row.original.department_name;
    },
    Footer: 'סה"כ',
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"תפקיד בו שובץ העובד בסידור עבודה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תפקיד
      </>
    ),
    accessor: "role_name",
    Cell: ({ row }) => {
      return row.original.role_name;
    },
    Footer: 'סה"כ',
  },
  {
    Header: "מ. עובד",
    accessor: "employee_number",
    Footer: ({ rows }) =>
      rows.reduce((sum, row) => (row.depth ? sum : 1 + sum), 0),
  },

  {
    Header: "תאריך הקמה",
    accessor: "hebrew_create_date",
  },
  {
    Header: "מס' עובד במע' שכר ",
    accessor: "employee_accountancy_number",
  },
  {
    Header: "סוג עלות",
    accessor: "current_salary_type",
    // Cell: ({ row }) =>
    //   row.original.global_salary ? "גלובלי" : row.original.current_salary_type,
  },
  {
    Header: "סוג עלות שכר",
    accessor: "salary",
    Cell: ({ row }) => {
      return (
        <div className="money money__footer">
          {row.depth === 1 || row.depth === 0
            ? !isNaN(Number(row.original.salary))
              ? `₪${thousandSeparator(twoZeroAfterDot(row.original.salary))}`
              : isObject(row.original.salary)
              ? Object.values(row.original.salary).join(", ")
              : row.original.salary
            : ""}
        </div>
      );
    },
  },
  {
    Header: "ימי עבודה בפועל",
    accessor: "actual_working_days",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "actual_working_days"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={'סה"כ ימי עבודה בהם יש לעובד כניסה ויציאה (בכל המחלקות)'}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        ימי עבודה מלצור
      </>
    ),
    accessor: "actual_working_days_waiter",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "actual_working_days_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי עבודה בפועל",
    accessor: "working_days",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "working_days"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "100%",
    accessor: "hours_100_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_100_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_100_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "125%",
    accessor: "hours_125_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_125_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_125_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "150%",
    accessor: "hours_150_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_150_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_150_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות שבת",
    accessor: "hours_150_shabat_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_150_shabat_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_150_shabat_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות חג",
    accessor: "hours_in_holiday_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_in_holiday_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_in_holiday_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "175%",
    accessor: "hours_175_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_175_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_175_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "200%",
    accessor: "hours_200_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_200_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_200_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={'סה"כ שעות 100% + שעות 100% בשבת'}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שעות סוציאליות
      </>
    ),
    accessor: "social_hours_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.social_hours_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "social_hours_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: 'סה"כ שעות',
    accessor: "hours_total_no_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_total_no_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_total_no_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "100% מלצור",
    accessor: "hours_100_waiter",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_100_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_100_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "125% מלצור",
    accessor: "hours_125_waiter",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_125_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_125_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "150% מלצור",
    accessor: "hours_150_waiter",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_150_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_150_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות שבת מלצור",
    accessor: "hours_150_shabat_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_150_shabat_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_150_shabat_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות חג מלצור",
    accessor: "hours_in_holiday_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_in_holiday_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_in_holiday_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "175% מלצור",
    accessor: "hours_175_waiter",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_175_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_175_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "200% מלצור",
    accessor: "hours_200_waiter",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_200_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_200_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות סוציאליות מלצור",
    accessor: "social_hours_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.social_hours_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "social_hours_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: 'סה"כ שעות מלצור',
    accessor: "hours_total_waiter",
    Cell: (props) =>
      `${twoZeroAfterDot(props.row.original.hours_total_waiter)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_total_waiter"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: 'סה"כ שעות עבודה',
    accessor: "hours_total_both",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_total_both)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_total_both"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"תעריף אוכל עובדים * ימי עבודה בפועל"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        אוכל עובדים
      </>
    ),
    accessor: "meal_cost",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${twoZeroAfterDot(props.row.original.meal_cost)}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "meal_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "נסיעות",
    accessor: "travel_cost",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${twoZeroAfterDot(props.row.original.travel_cost)}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "travel_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={" שכר עובד + נסיעות + בונוס + השלמה לטיפ מינימום"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שכר
      </>
    ),
    accessor: "cost_raw",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(twoZeroAfterDot(props.row.original.cost_raw))}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "cost_raw"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ טיפ ברוטו',
    accessor: "tips_total_before_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.tips_total_before_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_total_before_allowance"),

        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={"% הפרשה שמופרש מטיפ ברוטו לטובת תשלום תנאים סוציאליים"}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        % הפרשה
      </>
    ),
    accessor: "allowance_percentage",
    Cell: (props) => (
      <div className="money">
        {Math.round(Number(props.row.original.allowance_percentage))}%
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total_allowance = sumFooter(self.rows, "tips_allowance");

      const total_tips_total_before_allowance = sumFooter(
        self.rows,
        "tips_total_before_allowance"
      );

      return (
        <div className="money money__footer">
          {Number(
            total_tips_total_before_allowance
              ? twoZeroAfterDot(
                  (100 * total_allowance) / total_tips_total_before_allowance
                )
              : 0
          )}
          %
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"סכום הפרשה מהטיפים למסעדה עבור תנאים סוציאליים"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        הפרשה למסעדה
      </>
    ),
    accessor: "tips_allowance",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_allowance)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_allowance"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"טיפ מזומן אחרי הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        טיפ מזומן
      </>
    ),
    accessor: "tips_cash",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_cash)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_cash"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "טיפ באשראי",
    accessor: "tips_credit",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_credit)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_credit"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={" טיפ מזומן אחרי הפרשה + טיפ אשראי אחרי הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        סה"כ טיפ נטו
      </>
    ),
    accessor: "tips_total",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_total)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_total"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={'סה"כ השלמה לטיפ מינימום'}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        השלמה למינימום{" "}
      </>
    ),
    accessor: "tips_complete",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_complete)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_complete"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },

  {
    Header: () => (
      <>
        <BtnTooltip text={"טיפ ברוטו אחראי הפרשה + השלמה למינימום"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        נטו לתשלום
      </>
    ),
    accessor: "neto_for_payment",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.neto_for_payment)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "neto_for_payment"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ דקות המתנה',
    accessor: "waiting_minutes",
    Cell: (props) => (
      <div className="money">
        {twoZeroAfterDot(props.row.original.waiting_minutes)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "waiting_minutes"),
        [self.rows]
      );

      return (
        <div className="money money__footer">{twoZeroAfterDot(total)}</div>
      );
    },
  },
  {
    Header: "תשלום המתנה",
    accessor: "waiting_salary",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.waiting_salary)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "waiting_salary"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "ימי חופש",
    accessor: "vacation",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "vacation"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי מחלה",
    accessor: "sick",
    width: 1,
    Footer: (self) => {
      const total = useMemo(() => sumFooter(self.rows, "sick"), [self.rows]);

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי מילואים",
    accessor: "reserve",
    width: 1,
    Footer: (self) => {
      const total = useMemo(() => sumFooter(self.rows, "reserve"), [self.rows]);

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
];

export const mergeEventRows = (props, field) => {
  const { row } = props;
  // if (!row.original.cost) {
  //   return "";
  // }
  return `${twoZeroAfterDot(row.original[field])}`;
};

export const futureAssignsReport_hidden = [
  "identifier",
  "employee_number",
  "role_name",
  "mobileNumber",
  "email",
  "daily_notes",
  "planned_time_difference",
];

export const futureAssignsReport_columns = [
  {
    Header: "שם עובד",
    accessor: "employee_name",
    Footer: 'סה"כ',
  },
  {
    Header: "ת.ז.",
    accessor: "identifier",
  },
  {
    Header: "מ. עובד",
    accessor: "employee_number",
    Footer: (self) => {
      return self.rows.length;
    },
  },
  {
    Header: "תאריך",
    accessor: "working_date",
    Cell: (props) => `${fixDate(props.row.original.working_date)}`,
    width: 1,
  },
  {
    Header: "יום",
    accessor: "week_day",
    Cell: (props) => getHebDay(props.row.original.week_day),
  },
  {
    Header: "הערות יומיות",
    accessor: "daily_notes",
  },
  {
    Header: "תפקיד",
    accessor: "role_name",
    Cell: (props) =>
      props.row.original.role_name ? props.row.original.role_name : "לא שובץ",
  },
  {
    Header: "מחלקה",
    accessor: "department_name",
  },
  {
    Header: "מס' טלפון",
    accessor: "mobileNumber",
  },
  {
    Header: "מייל",
    accessor: "email",
  },
  {
    Header: "התחלה - תכנון",
    accessor: "planned_start",
    Cell: (props) => `${fixHour(props.row.original.planned_start)}`,
  },
  {
    Header: "סיום - תכנון",
    accessor: "planned_end",
    Cell: (props) => `${fixHour(props.row.original.planned_end)}`,
  },
  {
    Header: 'סה"כ שעות תכנון',
    accessor: "planned_time_difference",
    Cell: (props) => `${fixHour(props.row.original.planned_time_difference)}`,
    Footer: (self) => {
      const hoursStringToDecimal = (hoursString) => {
        if (!hoursString) {
          return 0;
        }
        const [hoursPart, minutesPart] = hoursString.split(":");
        return Number(hoursPart) + Number(minutesPart) / 60;
      };
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) =>
              hoursStringToDecimal(row.values.planned_time_difference) + sum,
            0
          ),
        [self.rows]
      );
      return twoZeroAfterDot(total);
    },
  },
  {
    Header: "הערות",
    accessor: "notes",
  },
];

export const tips_hidden = [
  "employee_name",
  "employee_number",
  "notes",
  "tip_per_hour_bruto",
  "aprooved_at",
  // "total_tip_before_allowance",
  "cash_before_allowance",
  "credit_before_allowance",
  "neto_for_payment",
  "identifier",
  "total_tip",
  "total_tip_before_allowance",
  "allowance_percentage",
  "allowance",
  "employer_pension",
  "employer_compensation",
  "employer_social_security",
  "employee_pension",
  "employee_social_security",
  "employee_irs",
  "total_allowance_from_credit",
  "total_allowance_from_cash",
  "complete",
  "tip_per_hour_neto",
  "employer_pension",
  "employer_compensation",
  "employer_social_security",
  "employee_pension",
  "employee_social_security",
  "employee_irs",
];

export const summary_tips_hidden = [
  // "employee_name",
  "employee_id",
  "tip_per_hour_bruto",
  "cash_before_allowance",
  "credit_before_allowance",
  "neto_for_payment",
  "employee_number",
  "identifier",
  "total_tip_before_allowance",
  "allowance_percentage",
  "allowance",
  "employer_pension",
  "employer_compensation",
  "employer_social_security",
  "employee_pension",
  "employee_social_security",
  "employee_irs",
];

export const manualHoursTable = [
  {
    Header: "שם עובד",
    accessor: "employee_name",
  },
  {
    Header: "תאריך",
    accessor: "working_date",
  },
  {
    Header: "יום",
    accessor: "week_day",
    Cell: ({ value }) => {
      return getHebDay(value);
    },
  },
  {
    Header: "משמרת",
    accessor: "shift_name",
  },
  {
    Header: "תפקיד",
    accessor: "role_name",
  },
  {
    Header: "שעת התחלה",
    accessor: "manual_start",
  },
  {
    Header: "שעת סיום",
    accessor: "manual_end",
  },
  {
    Header: "שעת התחלה - שעון",
    accessor: "clock_start",
  },
  {
    Header: "שעת סיום - שעון",
    accessor: "clock_end",
  },
  {
    Header: "פעולות",
    accessor: "actions",
    Cell: ({ row, setEventToEdit, setShowModal }) => {
      const event = row.original;
      // const {
      //   manual_start,
      //   manual_end,
      //   clock_start,
      //   clock_end,
      //   planned_start,
      //   planned_end,
      //   trim_start,
      //   trim_end,
      //   role_name,
      //   employee_id,
      //   employee_name,
      //   actual_end,
      //   actual_start,
      // } = row.original;
      return (
        <div>
          {/* <div className="flex-center">
            <ButtonIcon
              style={{
                width: "unset",
                height: "unset",
                marginLeft: "0.5rem",
                color: "#FFCE00",
                backgroundColor: "transparent",
                borderBottom: "1px solid black",
                borderRadius: "0",
                lineHeight: "0.8",
              }}
              alt="ערוך שעות בפועל ידני"
              icon={FaPencilAlt}
              iconSize="1.4em"
              onClick={() => {
                setEventToEdit({
                  ...event,
                  hours: {
                    manual: {
                      start: manual_start,
                      end: manual_end,
                    },
                    trim: {
                      start: trim_start,
                      end: trim_end,
                    },
                    planned: {
                      start: planned_start,
                      end: planned_end,
                    },
                    clock: {
                      start: clock_start,
                      end: clock_end,
                    },
                  },
                  role: { name: role_name },
                  employee: { name: employee_name, id: employee_id },
                  openHoursEdit: true,
                });
              }}
            />
            <ButtonIcon
              style={{
                width: "unset",
                height: "unset",
                color: " rgb(219, 30, 30)",
                backgroundColor: "transparent",
                lineHeight: "0.8",
              }}
              alt="מחק משמרת"
              icon={FaUserTimes}
              iconSize="1.4em"
              // onClick={() => {
              //   if (actual_start || actual_end) {
              //     handleEventToDelete({ event, index: row.index });
              //   } else {
              //     deleteEvent({
              //       event,
              //       isReport: true,
              //       onSuccess: () => {
              //         data.splice(row.index, 1);
              //         setDataState(data);
              //       },
              //     });
              //   }
              // }}
            />
          </div> */}
          <ButtonIcon
            style={{
              width: "unset",
              height: "unset",
              color: COLOR_RESTIGO_PRIMARY,
              backgroundColor: "transparent",
              lineHeight: "0.8",
            }}
            alt="הסטוריית עדכונים"
            icon={BsClockHistory}
            onClick={() =>
              setShowModal({
                openModal: true,
                logs: event.logs,
              })
            }
            iconSize="1.4em"
          />
        </div>
      );
    },
  },
];
export const tips_aggregates = [
  { field: "tip_hours", aggregate: "sum" },
  { field: "cash", aggregate: "sum" },
  { field: "credit", aggregate: "sum" },
  { field: "cash_before_allowance", aggregate: "sum" },
  { field: "credit_before_allowance", aggregate: "sum" },
  { field: "allowance", aggregate: "average" },
  { field: "complete", aggregate: "sum" },
  { field: "tip_per_hour_bruto", aggregate: "average" },
  { field: "tip_per_hour_neto", aggregate: "average" },
  { field: "total_tip", aggregate: "sum" },
  { field: "total_tip_before_allowance", aggregate: "sum" },
  { field: "allowance_percentage", aggregate: "sum" },
  { field: "tip_days", aggregate: "sum" },
  { field: "working_days", aggregate: "sum" },
  { field: "neto_for_payment", aggregate: "sum" },
  { field: "employer_pension", aggregate: "sum" },
  { field: "employer_compensation", aggregate: "sum" },
  { field: "employer_social_security", aggregate: "sum" },
  { field: "employee_pension", aggregate: "sum" },
  { field: "employee_social_security", aggregate: "sum" },
  { field: "employee_irs", aggregate: "sum" },
  { field: "total_allowance_from_credit", aggregate: "sum" },
  { field: "total_allowance_from_cash", aggregate: "sum" },
];
export const tips_columns = [
  {
    Header: ({ title }) => <h3>{title}</h3>,
    id: "title",
    Footer: "",
    columns: [
      {
        Header: "תאריך",
        accessor: "date",
        Cell: (props) => `${fixDate(props.row.original.date)}`,
        Footer: 'סה"כ',
      },
      {
        Header: "שם עובד",
        accessor: "employee_name",
      },
      {
        Header: "ת.ז.",
        accessor: "identifier",
      },
      {
        Header: "מ. עובד",
        accessor: "employee_number",
      },
      {
        Header: "יום",
        accessor: "week_day",
        Cell: (props) => getHebDay(props.row.original.week_day),
      },
      {
        Header: "תפקיד",
        accessor: "role_name",
      },
      {
        Header: "סטטוס",
        accessor: "aprooved_at",
        Cell: ({ value }) => (value ? `אושר ב: ${value}` : "לא אושר"),
      },
      {
        Header: "משמרת",
        accessor: "shift_name",
      },
      {
        Header: "הערות",
        accessor: "notes",
      },
      {
        Header: "שעת התחלה",
        accessor: "start_time",
        Cell: (props) => fixHour(props.row.original.start_time),
      },
      {
        Header: "שעת סיום",
        accessor: "end_time",
        Cell: (props) => fixHour(props.row.original.end_time),
      },
      {
        Header: ' סה"כ שעות טיפ',
        accessor: "tip_hours",
        Cell: (props) => twoZeroAfterDot(props.row.values.tip_hours),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.tip_hours + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "טיפ לשעה (ברוטו)",
        accessor: "tip_per_hour_bruto",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(
              twoZeroAfterDot(props.row.original.tip_per_hour_bruto)
            )}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.tip_per_hour_bruto + sum,
                0
              ) / self.rows.length,
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "טיפ לשעה (נטו)",
        accessor: "tip_per_hour_neto",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(
              twoZeroAfterDot(props.row.original.tip_per_hour_neto)
            )}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.tip_per_hour_neto + sum,
                0
              ) / self.rows.length,
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: 'סה"כ טיפ ברוטו',
        accessor: "total_tip_before_allowance",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(
              twoZeroAfterDot(props.row.original.total_tip_before_allowance)
            )}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.total_tip_before_allowance + sum,
                0
              ),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {thousandSeparator(twoZeroAfterDot(total))}
            </div>
          );
        },
      },
      {
        Header: "פנסיה מעביד",
        accessor: "employer_pension",
        Cell: (props) => fixPrice(props.row.original.employer_pension),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "employer_pension"),
            [self.rows]
          );

          return fixPrice(total);
        },
      },
      {
        Header: "פיצויים מעביד",
        accessor: "employer_compensation",
        Cell: (props) => fixPrice(props.row.original.employer_compensation),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "employer_compensation"),
            [self.rows]
          );

          return fixPrice(total);
        },
      },
      {
        Header: "ביטוח לאומי מעביד",
        accessor: "employer_social_security",
        Cell: (props) => fixPrice(props.row.original.employer_social_security),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "employer_social_security"),
            [self.rows]
          );

          return fixPrice(total);
        },
      },
      {
        Header: "פנסיה עובד",
        accessor: "employee_pension",
        Cell: (props) => fixPrice(props.row.original.employee_pension),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "employee_pension"),
            [self.rows]
          );

          return fixPrice(total);
        },
      },
      {
        Header: "ביטוח לאומי עובד",
        accessor: "employee_social_security",
        Cell: (props) => fixPrice(props.row.original.employee_social_security),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "employee_social_security"),
            [self.rows]
          );

          return fixPrice(total);
        },
      },
      {
        Header: "מס הכנסה",
        accessor: "employee_irs",
        Cell: (props) => fixPrice(props.row.original.employee_irs),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "employee_irs"),
            [self.rows]
          );

          return fixPrice(total);
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip
              text={"% הפרשה שמופרש מטיפ ברוטו לטובת תשלום תנאים סוציאליים"}
            >
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            % הפרשה
          </>
        ),
        accessor: "allowance_percentage",
        Cell: (props) => (
          <div className="money">
            {Math.round(
              Number(
                (100 * props.row.original.allowance) /
                  props.row.original.total_tip_before_allowance
              )
            )}
            %
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total_allowance = useMemo(
            () => self.rows.reduce((sum, row) => row.values.allowance + sum, 0),
            [self.rows]
          );
          const total_total_tip_before_allowance = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.total_tip_before_allowance + sum,
                0
              ),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              {total_total_tip_before_allowance
                ? twoZeroAfterDot(
                    (100 * total_allowance) / total_total_tip_before_allowance
                  )
                : 0}
              %
            </div>
          );
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={"טיפ מזומן אחרי הפרשה"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            טיפ מזומן
          </>
        ),
        accessor: "cash",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(twoZeroAfterDot(props.row.original.cash))}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.cash + sum, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },

      {
        Header: () => (
          <>
            <BtnTooltip text={"טיפ אשראי אחרי הפרשה"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            טיפ אשראי
          </>
        ),
        accessor: "credit",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(twoZeroAfterDot(props.row.original.credit))}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.credit + sum, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "השלמה לבסיס",
        accessor: "complete",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(twoZeroAfterDot(props.row.original.complete))}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.complete + sum, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "טיפ מזומן לפני הפרשה",
        accessor: "cash_before_allowance",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(
              twoZeroAfterDot(props.row.original.cash_before_allowance)
            )}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.cash_before_allowance + sum,
                0
              ),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "טיפ אשראי לפני הפרשה",
        accessor: "credit_before_allowance",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(
              twoZeroAfterDot(props.row.original.credit_before_allowance)
            )}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.credit_before_allowance + sum,
                0
              ),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={"סכום הפרשה מהטיפים למסעדה עבור תנאים סוציאליים"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            הפרשה למסעדה
          </>
        ),
        accessor: "allowance",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(twoZeroAfterDot(props.row.original.allowance))}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.allowance + sum, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "הפרשה מאשראי",
        accessor: "total_allowance_from_credit",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(
              twoZeroAfterDot(props.row.original.total_allowance_from_credit)
            )}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.total_allowance_from_credit + sum,
                0
              ),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "הפרשה ממזומן",
        accessor: "total_allowance_from_cash",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(
              twoZeroAfterDot(props.row.original.total_allowance_from_cash)
            )}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows.reduce(
                (sum, row) => row.values.total_allowance_from_cash + sum,
                0
              ),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={"טיפ ברוטו אחראי הפרשה + השלמה למינימום"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            נטו לתשלום
          </>
        ),
        accessor: "neto_for_payment",
        Cell: (props) => (
          <div className="money">
            <span>&#8362;</span>
            {twoZeroAfterDot(props.row.original.neto_for_payment)}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "neto_for_payment"),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {thousandSeparator(twoZeroAfterDot(total))}
            </div>
          );
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={" טיפ מזומן אחרי הפרשה + טיפ אשראי אחרי הפרשה"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            סה"כ טיפ נטו
          </>
        ),
        accessor: "total_tip",
        Cell: (props) => (
          <div className="money">
            {" "}
            <span>&#8362;</span>
            {thousandSeparator(twoZeroAfterDot(props.row.original.total_tip))}
          </div>
        ),
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () => self.rows.reduce((sum, row) => row.values.total_tip + sum, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {thousandSeparator(twoZeroAfterDot(total))}
            </div>
          );
        },
      },
    ],
  },
];

export const daily_tips_columns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <i className="fa fa-minus" />
        ) : (
          <i className="fa fa-plus" />
        )}
      </span>
    ),

    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row

              paddingRight: `${row.depth * 2}rem`,
            },
          })}
        >
          {row.isExpanded ? (
            <i className="fa fa-minus" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </span>
      ) : null,
  },
  {
    Header: "תאריך",
    accessor: "date",
    Footer: 'סה"כ',
  },
  {
    Header: "יום בשבוע",
    accessor: "week_day",
    Cell: (props) => {
      if (isNumber(props.row.original.week_day)) {
        return getHebDay(props.row.original.week_day);
      } else {
        return props.row.original.shiftName;
      }
    },
  },
  {
    Header: "סה״כ טיפ",
    accessor: "total_tip",
    Cell: ({ value }) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(value))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_tip"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"סה״כ טיפ מזומן לפני הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        מזומן
      </>
    ),
    accessor: "cash",
    Cell: ({ value }) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(value))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(() => sumFooter(self.rows, "cash"), [self.rows]);

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"סה״כ טיפ אשראי לפני הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        אשראי
      </>
    ),
    accessor: "credit",
    Cell: ({ value }) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(value))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(() => sumFooter(self.rows, "credit"), [self.rows]);

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"ממוצע השלמה ליום"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        השלמה
      </>
    ),
    accessor: "complete",
    Cell: ({ value }) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(value))}
      </div>
    ),
    width: 1,
    Footer: ({ data, rows }) => {
      const mainRows = rows.filter((row) => row.depth === 0);
      const total = useMemo(
        () =>
          mainRows.reduce((sum, row) => row.values.complete + sum, 0) /
          mainRows.length,
        [rows]
      );
      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"ממוצע טיפ ברוטו לפני הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        טיפ לשעה (ברוטו)
      </>
    ),
    accessor: "tip_per_hour_bruto",
    Cell: ({ value }) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(value))}
      </div>
    ),
    width: 1,
    Footer: ({ data, rows }) => {
      const mainRows = rows.filter((row) => row.depth === 0);
      const total = useMemo(
        () =>
          mainRows.reduce(
            (sum, row) => row.values.tip_per_hour_bruto + sum,
            0
          ) / mainRows.length,
        [rows]
      );
      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  ,
  {
    Header: () => (
      <>
        <BtnTooltip text={"ממוצע הפרשה ליום"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        הפרשה
      </>
    ),
    accessor: "allowance",
    Cell: ({ value }) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(value))}
      </div>
    ),
    width: 1,
    Footer: ({ data, rows }) => {
      const mainRows = rows.filter((row) => row.depth === 0);
      const total = useMemo(
        () =>
          mainRows.reduce((sum, row) => row.values.allowance + sum, 0) /
          mainRows.length,
        [rows]
      );
      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={"% הפרשה שמופרש מטיפ ברוטו לטובת תשלום תנאים סוציאליים"}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        % הפרשה
      </>
    ),
    accessor: "allowance_percentage",
    Cell: ({ value }) => (
      <div className="money">{Math.round(Number(value))}%</div>
    ),
    width: 1,
    Footer: ({ data, rows }) => {
      const mainRows = rows.filter((row) => row.depth === 0);
      const total = useMemo(
        () =>
          mainRows.reduce(
            (sum, row) => row.values.allowance_percentage + sum,
            0
          ) / mainRows.length,
        [rows]
      );
      return (
        <div className="money money__footer">
          {Number(twoZeroAfterDot(total))}%
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"ממוצע טיפ נטו אחרי הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        טיפ לשעה (נטו)
      </>
    ),
    accessor: "tip_per_hour_neto",

    Cell: ({ value }) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(value))}
      </div>
    ),
    width: 1,
    Footer: ({ data, rows }) => {
      const mainRows = rows.filter((row) => row.depth === 0);
      const total = useMemo(
        () =>
          mainRows.reduce((sum, row) => row.values.tip_per_hour_neto + sum, 0) /
          mainRows.length,
        [rows]
      );
      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  ,
];
export const summary_tips_columns = [
  {
    Header: "שם עובד",
    accessor: "employee_name",
  },
  {
    Header: "ת.ז.",
    accessor: "identifier",
  },
  {
    Header: "מ. עובד",
    accessor: "employee_number",
    Footer: (self) => self.rows.length,
  },
  {
    Header: "ימי טיפ",
    accessor: "tip_days",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.tip_days + sum, 0),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי עבודה",
    accessor: "working_days",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.working_days + sum, 0),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: () => {
      return (
        <>
          <BtnTooltip text={"סה״כ שעות טיפ ללא הפסקות"}>
            <i
              style={{ fontSize: "1.4rem" }}
              className="fas fa-info-circle"
            ></i>
          </BtnTooltip>
          סה"כ שעות טיפ
        </>
      );
    },
    accessor: "tip_hours",
    Cell: (props) => twoZeroAfterDot(props.row.values.tip_hours),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.tip_hours + sum, 0),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "טיפ לשעה (ברוטו)",
    accessor: "tip_per_hour_bruto",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.tip_per_hour_bruto)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) => row.values.tip_per_hour_bruto + sum,
            0
          ) / self.rows.length,
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "טיפ לשעה (נטו)",
    accessor: "tip_per_hour_neto",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.tip_per_hour_neto)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) => row.values.tip_per_hour_neto + sum,
            0
          ) / self.rows.length,
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ טיפ ברוטו',
    accessor: "total_tip_before_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.total_tip_before_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) => row.values.total_tip_before_allowance + sum,
            0
          ),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "פנסיה מעביד",
    accessor: "employer_pension",
    Cell: (props) => fixPrice(props.row.original.employer_pension),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "employer_pension"),
        [self.rows]
      );

      return fixPrice(total);
    },
  },
  {
    Header: "פיצויים מעביד",
    accessor: "employer_compensation",
    Cell: (props) => fixPrice(props.row.original.employer_compensation),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "employer_compensation"),
        [self.rows]
      );

      return fixPrice(total);
    },
  },
  {
    Header: "ביטוח לאומי מעביד",
    accessor: "employer_social_security",
    Cell: (props) => fixPrice(props.row.original.employer_social_security),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "employer_social_security"),
        [self.rows]
      );

      return fixPrice(total);
    },
  },
  {
    Header: "פנסיה עובד",
    accessor: "employee_pension",
    Cell: (props) => fixPrice(props.row.original.employee_pension),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "employee_pension"),
        [self.rows]
      );

      return fixPrice(total);
    },
  },
  {
    Header: "ביטוח לאומי עובד",
    accessor: "employee_social_security",
    Cell: (props) => fixPrice(props.row.original.employee_social_security),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "employee_social_security"),
        [self.rows]
      );

      return fixPrice(total);
    },
  },
  {
    Header: "מס הכנסה",
    accessor: "employee_irs",
    Cell: (props) => fixPrice(props.row.original.employee_irs),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "employee_irs"),
        [self.rows]
      );

      return fixPrice(total);
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={"% הפרשה שמופרש מטיפ ברוטו לטובת תשלום תנאים סוציאליים"}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        % הפרשה
      </>
    ),
    accessor: "allowance_percentage",
    Cell: (props) => (
      <div className="money">
        {Math.round(Number(props.row.original.allowance_percentage))}%
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) => row.values.allowance_percentage + sum,
            0
          ),
        [self.rows]
      );
      return (
        <div className="money money__footer">{Math.round(Number(total))}%</div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"טיפ מזומן אחרי הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        טיפ מזומן
      </>
    ),
    accessor: "cash",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(props.row.original.cash))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.cash + sum, 0),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },

  {
    Header: () => (
      <>
        <BtnTooltip text={"טיפ אשראי אחרי הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        טיפ אשראי
      </>
    ),
    accessor: "credit",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(props.row.original.credit))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.credit + sum, 0),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "השלמה לבסיס",
    accessor: "complete",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(props.row.original.complete))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.complete + sum, 0),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "טיפ מזומן לפני הפרשה",
    accessor: "cash_before_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.cash_before_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) => row.values.cash_before_allowance + sum,
            0
          ),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "טיפ אשראי לפני הפרשה",
    accessor: "credit_before_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.credit_before_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce(
            (sum, row) => row.values.credit_before_allowance + sum,
            0
          ),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"סכום הפרשה מהטיפים למסעדה עבור תנאים סוציאליים"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        הפרשה למסעדה
      </>
    ),
    accessor: "allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(props.row.original.allowance))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.allowance + sum, 0),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"טיפ ברוטו אחראי הפרשה + השלמה למינימום"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        נטו לתשלום
      </>
    ),
    accessor: "neto_for_payment",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.neto_for_payment)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "neto_for_payment"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={" טיפ מזומן אחרי הפרשה + טיפ אשראי אחרי הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        סה"כ טיפ נטו
      </>
    ),
    accessor: "total_tip",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(props.row.original.total_tip))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.total_tip + sum, 0),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
];

export const anomalies_columns = [
  {
    Header: "סניף",
    accessor: "branch_name",
  },
  {
    Header: "ת.ז.",
    accessor: "identifier",
  },
  {
    Header: "שם עובד",
    accessor: "employee_name",
  },
  {
    Header: "מ. עובד",
    accessor: "employee_number",
  },
  {
    Header: "תאריך",
    accessor: "working_date",
    Cell: (props) => `${fixDate(props.row.original.working_date)}`,
  },
  {
    Header: "יום",
    accessor: "week_day",
    Cell: (props) => getHebDay(props.row.original.week_day),
  },
  {
    Header: "שעת התחלה",
    accessor: "actual_start",
    Cell: (props) => `${fixHour(props.row.original.actual_start)}`,
  },
  {
    Header: "שעת סיום",
    accessor: "actual_end",
    Cell: (props) => `${fixHour(props.row.original.actual_end)}`,
  },

  {
    Header: "תפקיד",
    accessor: "role_name",
    Cell: (props) =>
      props.row.original.role_name ? (
        props.row.original.role_name
      ) : (
        <span style={{ color: "red", fontWeight: "500" }}> לא שובץ</span>
      ),
  },
  {
    Header: "משמרת",
    accessor: "shift_name",
    Cell: (props) =>
      props.row.original.shift_name ? (
        props.row.original.shift_name
      ) : (
        <span style={{ color: "red", fontWeight: "500" }}> לא שובץ</span>
      ),
  },
  {
    Header: "סיבות חריגה",
    accessor: "anomalies",
    Cell: (props) =>
      props.row.original.anomalies.map((item, i) => (
        <div style={{ margin: 0 }} key={i}>
          {item?.heb_message}
        </div>
      )),
    sortType: (rowA, rowB, columnId) => {
      const firtCodeA = getSafe(() => rowA.original.anomalies[0].code, 0);
      const firtCodeB = getSafe(() => rowB.original.anomalies[0].code, 0);
      return firtCodeA === firtCodeB ? 0 : firtCodeA > firtCodeB ? 1 : -1;
    },
  },
  {
    Header: "",
    accessor: "buttons",
    Cell: (props) => {
      const {
        actual_end,
        actual_start,
        manual_start,
        manual_end,
        clock_start,
        clock_end,
        planned_start,
        planned_end,
        trim_start,
        trim_end,
        role_name,
        employee_id,
        employee_name,
        working_date,
      } = props.row.original;
      const event = props.row.original;
      const {
        handleEventToDelete,
        deleteEvent,
        row,
        setDataState,
        setEventToEdit,
      } = props;
      let data = cloneDeep(props.data);
      const { isManager, blocked_from_date, isAllowedToEdit } = props;
      const is_blocked_by_date = blocked_from_date
        ? working_date < blocked_from_date
        : false;

      const disabled = !isManager || is_blocked_by_date || !isAllowedToEdit;

      return disabled ? (
        ""
      ) : (
        <div>
          <div className="flex-center">
            <ButtonIcon
              style={{
                width: "unset",
                height: "unset",
                marginLeft: "0.5rem",
                color: "#FFCE00",
                backgroundColor: "transparent",
                borderBottom: "1px solid black",
                borderRadius: "0",
                lineHeight: "0.8",
              }}
              alt="ערוך שעות בפועל ידני"
              icon={FaPencilAlt}
              iconSize="1.4em"
              onClick={() => {
                setEventToEdit({
                  ...event,
                  hours: {
                    manual: {
                      start: manual_start,
                      end: manual_end,
                    },
                    trim: {
                      start: trim_start,
                      end: trim_end,
                    },
                    planned: {
                      start: planned_start,
                      end: planned_end,
                    },
                    clock: {
                      start: clock_start,
                      end: clock_end,
                    },
                  },
                  role: { name: role_name },
                  employee: { name: employee_name, id: employee_id },
                  openHoursEdit: true,
                });
              }}
            />
            <ButtonIcon
              style={{
                width: "unset",
                height: "unset",
                color: " rgb(219, 30, 30)",
                backgroundColor: "transparent",
                lineHeight: "0.8",
              }}
              alt="מחק משמרת"
              icon={FaUserTimes}
              iconSize="1.4em"
              onClick={() => {
                if (actual_start || actual_end) {
                  handleEventToDelete({ event, index: row.index });
                } else {
                  deleteEvent({
                    event,
                    isReport: true,
                    onSuccess: () => {
                      data.splice(row.index, 1);
                      setDataState(data);
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      );
    },
  },
];
