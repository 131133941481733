const isFile = (input) => "File" in window && input instanceof File;

//TODO  Object.entries(data).forEach(([key, value]) => { EXTRACY key value

const objectToFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach((value) => {
    if (isAssignable(value[1])) {
      typeof value[1] !== "undefined" && formData.append(value[0], value[1]);
    } else if (Array.isArray(value[1])) {
      arrayToFormData(value[0], value[1], formData);
    } else {
      appendToFormData(value[0], value[1], formData);
    }
  });
  return formData;
};

const arrayToFormData = (name, array, formData) => {
  array.forEach((value, index) => {
    if (isAssignable(value)) {
      typeof value !== "undefined" && formData.append(`${name}[]`, value);
    } else if (Array.isArray(value)) {
      arrayToFormData(name, value, formData);
    } else {
      appendToFormData(`${name}[${index}]`, value, formData);
    }
  });
};

const appendToFormData = (name, data, formData) => {
  Object.entries(data).forEach(([key, value]) => {
    if (isAssignable(value)) {
      typeof value !== "undefined" && formData.append(`${name}[${key}]`, value);
    } else if (Array.isArray(value)) {
      arrayToFormData(`${name}[${key}]`, value, formData);
    } else {
      appendToFormData(`${name}[${key}]`, value, formData);
    }
  });
};

const isAssignable = (value) => {
  return value === null || typeof value !== "object" || isFile(value)
    ? true
    : false;
};

const getLatestDate = (values) => {
  if (values.length === 0) {
    return null;
  }
  let latestDate = values[0].signed?.created_at;
  values.forEach((value) => {
    if (latestDate < value.signed?.created_at) {
      latestDate = value.signed?.created_at;
    }
  });
  return latestDate;
};
function is_israeli_id_number(id) {
  id = String(id).trim();
  if (id.length > 9 || isNaN(id)) return false;
  id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
  const validate =
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0;
  return validate ? true : false;
}
const objectToFormDataTasks = (data) => {
  if (data.taskParts[0].hasOwnProperty("task_part_options")) {
    delete data.taskParts[0].task_part_options;
  }
  const formData = new FormData();
  Object.entries(data).forEach((value) => {
    if (isAssignable(value[1])) {
      typeof value[1] !== "undefined" && formData.append(value[0], value[1]);
    } else if (Array.isArray(value[1])) {
      arrayToFormData(value[0], value[1], formData);
    } else {
      appendToFormData(value[0], value[1], formData);
    }
  });
  return formData;
};
export {
  isFile,
  objectToFormData,
  getLatestDate,
  is_israeli_id_number,
  objectToFormDataTasks,
};
