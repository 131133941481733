import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { setDialogModal } from "../../../../redux/actions/DialogAction";
import { logout } from "../../../../redux/actions/loginAction";
import {
  addHoursToEvent,
  deleteEvent,
} from "../../../../redux/actions/scheduleAction";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderAction";
import ErrorAttendanceTable from "./ErrorAttendanceTable";
import Axios from "axios";
import { dateReqFormat } from "../../../../helpers";
import {
  EDIT_SCHEDULE_PRIVILEGE,
  SHOW_ONLY_MY_EMPLOYEE_REPORTS,
} from "../../../../../src/privilegesIndexes";

const EditUnvalidShifts = ({
  trigerAnomaliesFetchData,
  user,
  token,
  date,
  type,
  checkedBranches,
  isManager,
  selected_branch,
  handleSubmit,
  deleteEvent,
  checkedEmployees,
  checkedDepartments,
  openUnvalidShiftReport,
  setOpenUnvalidShiftReport,
  setIsHaveAnomaliesInReport,
  logout,
  setDialogModal,
  startLoading,
  stopLoading,
  setAttendanceAnomaliesErrors,
  attendanceAnomaliesErrors,
}) => {
  const [eventToEdit, setEventToEdit] = useState(null);
  const [observerChangesInData, setObserverChangesInData] = useState(0);

  const fetchData = useMemo(
    () => async () => {
      const route =
        type === "salary_by_identifier" ? "multipleAnomalies" : "anomalies";
      const startDate = dateReqFormat(date.start);
      const endDate = dateReqFormat(date.end);
      try {
        const response = await Axios.get(`reports/employees/${route}`, {
          params: {
            branch:
              type === "salary_by_identifier"
                ? checkedBranches
                : isManager
                ? selected_branch.id
                : user.branch_id,
            startDate, //required
            endDate, //required
            department_ids: isManager ? checkedDepartments : null,
            employee_ids: isManager ? checkedEmployees : [user.id],
            show_all_branches: false,
          },
          headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
      } catch (e) {
        switch (e?.response?.status) {
          case 401:
            logout();
            break;
          default:
            break;
        }
        return [];
      }
    },
    [date, user, token]
  );

  const filterFetchedData = async () => {
    const anomalies = await fetchData();
    const onlyPresented = anomalies
      .map((anomaly) => {
        const { actual_start, actual_end } = anomaly;
        if ((actual_start === null) ^ (actual_end === null)) {
          return anomaly;
        }
      })
      .filter((anomaly) => anomaly !== undefined);

    setAttendanceAnomaliesErrors(onlyPresented);
  };

  const updateTable = async (event, remove_id = null) => {
    setAttendanceAnomaliesErrors((prev) =>
      prev.filter((employee) => employee.id !== remove_id)
    );
    setObserverChangesInData((prev) => prev + 1);
    setEventToEdit(null);
  };

  const handleEventToDelete = ({ event, index }) => {
    setDialogModal({
      title: "מחיקת שיבוץ",
      text: "פעולה זו תסיר את העובד ואת השעות בפועל משיבוץ זה לצמיתות. האם אתה בטוח בפעולה זו?",
      btnConfirmText: "מחק",
      btnAbortClass: "btn--disabled",
      onConfirm: () => onConfirmDelete({ event, index }),
    });
  };

  const onConfirmDelete = ({ event, index }) => {
    try {
      startLoading("מוחק משמרת...");
      deleteEvent({
        event,
        isReport: true,
        onSuccess: () => {
          let tempData = cloneDeep(attendanceAnomaliesErrors);
          tempData.splice(index, 1);
          setAttendanceAnomaliesErrors(tempData);
          setObserverChangesInData((prev) => prev + 1);
          stopLoading();
        },
      });
    } catch (error) {
      stopLoading();
    }
  };

  const confirmViewingUnvalidShifts = () => {
    if (observerChangesInData > 0) {
      handleSubmit();
      setObserverChangesInData(0);
    }
    setOpenUnvalidShiftReport(false);
  };

  useEffect(() => {
    filterFetchedData();
    setObserverChangesInData(0);
  }, [trigerAnomaliesFetchData]);

  useEffect(() => {
    setIsHaveAnomaliesInReport(attendanceAnomaliesErrors?.length > 0);
  }, [attendanceAnomaliesErrors]);

  const closeModalWithUnvalidShifts = () => {
    setOpenUnvalidShiftReport(false);
  };

  useEffect(() => {
    if (openUnvalidShiftReport) {
      setOpenUnvalidShiftReport(true);
    }
  }, [openUnvalidShiftReport]);

  return (
    <>
      <Dialog
        open={openUnvalidShiftReport}
        onClose={closeModalWithUnvalidShifts}
        maxWidth={"xl"}
      >
        <DialogTitle>
          {"עובדים ללא שעת כניסה או יציאה"}
          <IconButton
            aria-label="close"
            onClick={closeModalWithUnvalidShifts}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ErrorAttendanceTable
            attendanceData={attendanceAnomaliesErrors}
            cellsPropsById={() => {
              return {
                setEventToEdit,
                handleEventToDelete,
              };
            }}
            eventToEdit={eventToEdit}
            setEventToEdit={setEventToEdit}
            updateTable={updateTable}
            handleEventToDelete={handleEventToDelete}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirmViewingUnvalidShifts}
            variant="contained"
            color="primary"
          >
            אישור
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => {
  const { user, token } = state.auth;
  const { selected_branch } = state.branches;
  const { privileges_buttons, employees_transfer } = state.user_privileges;
  const isManager =
    privileges_buttons.includes(EDIT_SCHEDULE_PRIVILEGE) &&
    !privileges_buttons.includes(SHOW_ONLY_MY_EMPLOYEE_REPORTS);
  return {
    user,
    token,
    isManager,
    selected_branch,
  };
};
export default connect(mapStateToProps, {
  setDialogModal,
  addHoursToEvent,
  deleteEvent,
  logout,
  startLoading,
  stopLoading,
})(EditUnvalidShifts);
