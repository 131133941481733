import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import BtnTooltip from "../../../../../components/tooltips/BtnTooltip";
import Popover from "react-popover";
// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { useMediaQuery, useTheme, Grid, Box } from "@mui/material";

// ======== ICONS ========

// ======== CONSTANTS ========
import HoursPerDay from "./HoursPerDay";

// ======== COMPONENTS ========

export const EditHoursPerDay = ({
  handleStateOpenEditRoleHours,
  isOpen,
  index,
  day,
}) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Popover
      preferPlace="left"
      tipSize={11}
      enterExitTransitionDurationMs={300}
      isOpen={isOpen}
      body={
        <HoursPerDay
          handleStateOpenEditRoleHours={handleStateOpenEditRoleHours}
          index={index}
          day={day}
        />
      }
    >
      <div>
        <BtnTooltip text={"עדכן שעות"}>
          <button
            type="button"
            onClick={() => handleStateOpenEditRoleHours(index)} // Call the function directly
            className="btn btn--none padding-0 fit-content"
          >
            <i className="fas fa-clock"></i>
          </button>
        </BtnTooltip>
      </div>
    </Popover>
  );
};

export default EditHoursPerDay;
