import React from "react";
import DayPicker from "react-day-picker";
import { eachDayOfInterval, endOfWeek, startOfWeek } from "date-fns";

const MONTHS = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];

const WEEKDAYS_LONG = [
  "ראשון",
  "שני",
  "שלישי",
  "רביעי",
  "חמישי",
  "שישי",
  "שבת",
];
const WEEKDAYS_SHORT = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];

export default class Example extends React.Component {
  state = {
    hoverRange: undefined,
    selectedDays: this.getSelectedDays(this.props.selected),
    // selectedDays: getWeekDays(getWeekRange(this.props.selected)),
  };

  getSelectedDays(date) {
    return eachDayOfInterval({
      start: startOfWeek(date),
      end: endOfWeek(date),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({
        selectedDays: this.getSelectedDays(this.props.selected),
      });
    }
  }

  handleDayChange = (date) => {
    if (this.props.afterDate < date || this.props.beforeDate > date) return;
    this.setState({
      selectedDays: this.getSelectedDays(date),
    });
  };

  handleDayEnter = (date) => {
    if (this.props.afterDate < date || this.props.beforeDate > date) return;
    this.setState({
      hoverRange: { from: startOfWeek(date), to: endOfWeek(date) },
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  render() {
    const { hoverRange, selectedDays } = this.state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const { afterDate, beforeDate, selected, setDate, setShow, isLoading } =
      this.props;
    return (
      <div className="SelectedWeekExample">
        <DayPicker
          months={MONTHS}
          month={selected}
          toMonth={afterDate}
          fromMonth={beforeDate}
          showOutsideDays
          modifiers={modifiers}
          onDayClick={this.handleDayChange}
          onDayMouseEnter={this.handleDayEnter}
          onDayMouseLeave={this.handleDayLeave}
          weekdaysLong={WEEKDAYS_LONG}
          weekdaysShort={WEEKDAYS_SHORT}
          selectedDays={selectedDays}
          disabledDays={[
            {
              after: afterDate,
              before: beforeDate,
            },
          ]}
        />
        {selectedDays.length === 7 && (
          <button
            disabled={isLoading}
            type="button"
            onClick={() => {
              setDate(selectedDays[0]);
              setShow(false);
            }}
            className="btn"
            style={{ color: "#00a2ea", height: "fit-content" }}
          >
            {" "}
            אישור{" "}
          </button>
        )}
      </div>
    );
  }
}
