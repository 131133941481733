export const START_LOADING = "START_LOADING"
export const STOP_LOADING = "STOP_LOADING"

export const startLoading = (text) => async (dispatch) => {
    
  dispatch({ type: START_LOADING, text});
};

export const stopLoading = () => async (dispatch) => {
  dispatch({ type: STOP_LOADING });
};

