import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import AddButton from "../../../components/buttons/AddButton";
import SalarySubTypeRow from "./SalarySubTypeRow";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const MobileSalarySubType = ({
  fields,
  append,
  handlesSubSalaryTypeChange,
  shift_positions,
  salary_by_sub_types,
}) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <>
      <Grid
        item
        style={{ display: "flex", justifyContent: "flex-end" }}
        xs={12}
      >
        <AddButton
          onClickHandler={() => {
            append({
              subtype: null,
              salary_type: null,
              salary: null,
            });
          }}
          label="הוספת שכר"
        />
      </Grid>
      <Grid item container xs={12}>
        {fields.map((field, index) => {
          return (
            <Grid item container spacing={1} xs={12}>
              <SalarySubTypeRow
                shift_positions={shift_positions}
                salary_by_sub_types={salary_by_sub_types}
                handlesSubSalaryTypeChange={handlesSubSalaryTypeChange}
                index={index}
                field={field}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MobileSalarySubType;
