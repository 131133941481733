import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { Autocomplete as MuiAutoComplete } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr";
import ErrorMessage from "../ErrorMessage";
import { FaLink } from "react-icons/fa";
import { COLOR_RESTIGO_PRIMARY } from "../../../const/colors";
import { isFunction } from "lodash";
import { useStyles } from "../../../styles/Goods";

export const selectAll = { id: "*", label: "בחר הכל" };
export const unselectAll = { id: "-*", label: "הסר הכל" };

const filter = createFilterOptions(); // for auto complete add a new option

const ControlAutoComplete = ({
  name,
  sx,
  firstSelectAll,
  multiple,
  options,
  handleInputChange,
  defaultChecked = null,
  rules,
  defaultValue, //= multiple ? [] : null,
  inputProps,
  createIfNotExists = false,
  autoOpen = null,
  inputOnChange,
  redReset,
  handleClear,
  hideDropdown = false,
  saveOnBlur = false,
  ...autoCompleteProps
}) => {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      {...{ name, control, rules, ...(!!defaultValue && { defaultValue }) }}
      render={({
        field: { name, onBlur, onChange, ref: inputRef, value },
        fieldState: { error },
      }) => (
        <AutoComplete
          onChange={(e, value) => {
            onChange(value);
          }}
          {...{
            name,
            firstSelectAll,
            multiple,
            options,
            error,
            onBlur,
            inputRef,
            value,
            handleInputChange,
            sx,
            inputProps,
            createIfNotExists,
            autoOpen,
            setValue,
            inputOnChange,
            redReset,
            handleClear,
            hideDropdown,
            saveOnBlur,
            defaultChecked,
            ...autoCompleteProps,
          }}
        />
      )}
    />
  );
};

const AutoComplete = ({
  name,
  firstSelectAll,
  options,
  error,
  onChange = () => {},
  handleInputChange,
  value,
  inputRef,
  limitTags = -1,
  openOnFocus = false,
  multiple,
  inputProps,
  disableClearable,
  defaultChecked,
  sx,
  className,
  createIfNotExists = false,
  autoOpen,
  setValue,
  inputOnChange,
  redReset,
  handleClear,
  hideDropdown,
  saveOnBlur,
  ...autoCompleteProps
}) => {
  const [optionsList, setOptionsList] = useState(options);

  const classes = useStyles();

  useEffect(() => {
    let newOptionList = [];
    if (multiple) {
      if (options?.length !== value?.length) {
        newOptionList = [selectAll, ...options];
      } else if (!disableClearable) {
        newOptionList = [unselectAll, ...options];
      } else {
        newOptionList = [...options];
      }
    } else {
      newOptionList = [...options];
    }
    setOptionsList(newOptionList);
  }, [options, multiple, value, disableClearable]);

  const select = useCallback(
    (e, value, reason, details) => {
      if (firstSelectAll) {
        firstSelectAll = false;
        return [...options];
      }

      switch (details?.option) {
        case selectAll:
          return [...options];

        case unselectAll:
          if (defaultChecked) {
            return [defaultChecked];
          } else {
            return [];
          }
        default:
          return value;
      }
    },
    [options]
  );
  const icon = <GrCheckbox fontSize="small" />;
  const checkedIcon = <GrCheckboxSelected fontSize="small" />;
  const maxLength = 10; // Define a length after which you want to show the tooltip

  return (
    <MuiAutoComplete
      className={className || (redReset && classes.redReset)}
      sx={{
        ...sx,
      }}
      id={name}
      isOptionEqualToValue={(option, value) => {
        return (
          option.id == value ||
          (option.id === value.id && option.label === value.label) ||
          (option.id === 0 &&
            option.label &&
            option.id === value.id &&
            option.label === value.label) ||
          (value.id === -1 &&
            value.label &&
            option.id === value.id &&
            option.label === value.label)
        );
      }}
      value={value || null}
      getOptionDisabled={(option) => option.disabled}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.label
        );

        if (createIfNotExists && inputValue !== "" && !isExisting) {
          filtered.push({
            id: -1,
            label: inputValue,
            inputValue,
          });
        }
        return filtered;
      }}
      onChange={(e, value, reason, details) => {
        const selected = select(e, value, reason, details);
        if (reason === "clear") {
          if (isFunction(handleClear)) {
            if (handleClear()) return;
          }
          setValue(name, null);
        }

        if (!value) return false;

        onChange(e, selected);

        if (saveOnBlur) {
          onChange(e, value);
        }

        if (isFunction(handleInputChange)) handleInputChange(value, name);
      }}
      options={optionsList ?? []}
      disableCloseOnSelect={multiple}
      renderOption={(props, option, { selected }) => {
        // HARDCODED for GOODS/ITEMS development
        // TODO: make generic
        if (option && option?.isConnected) {
          return (
            <Box {...props} key={props.id}>
              <FaLink
                style={{
                  fontSize: 10,
                  marginLeft: 5,
                  color: COLOR_RESTIGO_PRIMARY,
                }}
              />
              {option.label}
            </Box>
          );
        }

        return (
          <div {...props} key={props.id}>
            {multiple && (
              <Checkbox
                // icon={icon}
                // checkedIcon={checkedIcon}

                style={{ marginLeft: 8 }}
                checked={selected}
              />
            )}
            {option.renderIcon && option.renderIcon}
            <p>{typeof option === "string" ? option : option?.label}</p>
          </div>
        );
      }}
      renderInput={(params) => {
        return (
          <Tooltip
            title={value?.label?.length > maxLength ? value?.label : ""}
            placement="top"
          >
            <TextField
              error={!!error}
              inputRef={inputRef}
              helperText={
                !!error && <ErrorMessage>{error?.message}</ErrorMessage>
              }
              onChange={inputOnChange}
              onBlur={(e) => {
                if (saveOnBlur && e?.target?.value) {
                  const newValue = {
                    id: -1,
                    label: e.target.value,
                  };
                  const newSaveOnBlurValues = [...value, newValue];
                  onChange(e, newSaveOnBlurValues);
                }
              }}
              {...{
                ...params,
                ...inputProps,

                // HARDCODED for GOODS/ITEMS development
                // TODO: make generic

                ...(params?.InputProps?.endAdornment?.props?.ownerState?.value
                  ?.isConnected && {
                  ...(params.InputProps.startAdornment = (
                    <InputAdornment position="start">
                      <FaLink
                        style={{
                          fontSize: 10,
                          color: COLOR_RESTIGO_PRIMARY,
                        }}
                      />
                    </InputAdornment>
                  )),
                }),
              }}
            />
          </Tooltip>
        );
      }}
      {...{
        ...(autoOpen && { open: true }),
        ...(hideDropdown && { open: false }),
        limitTags,
        multiple,
        disableClearable,
        openOnFocus,
        ...autoCompleteProps,
      }}
    />
  );
};
export { ControlAutoComplete, AutoComplete };
