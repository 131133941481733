import {
  RESET_PAGINATION_DATA,
  SET_CURRENT_PROCEDURE,
} from "../actions/proceduresActions/types";
import { SET_PAGINATION_FILTERS } from "../actions/proceduresActions/types";
import {
  ADD_CATEGORY,
  ADD_EMAIL,
  GET_PROCEDURES_EMAILS,
  GET_PROCEDURES_TYPE,
  RESET_PROCEDURES_FILTERS,
  RESET_REPORTS_FILTERS,
  SET_BRANCH_EMPLOYEE,
  SET_DATA_LOADING,
  SET_DELETED_AT,
  SET_EMPLOYEE_PROCEDURES,
  SET_NEW_PROCEDURES_TYPE,
  SET_PROCEDURES,
  SET_PROCEDURE_FILTER,
  SET_PROCEDURE_TYPE,
  SET_REPORTS_FILTER,
  SET_SHOW_PROCEDURE,
  SET_SHOW_PROCEDURES,
  UPDATE_CATEGORY,
  UPDATE_EMAIL,
} from "../actions/proceduresActions/types";

const INITIAL_STATE = {
  procedures: [],
  branchEmployees: [],
  employeeProcedures: [],
  showProcedure: null,
  proceduresType: [],
  procedureEmails: [],
  filter: {
    status: [2],
    branchId: [],
    categoryId: [],
    departments: [],
    categoryManageStatus: [2],
    emailManageStatus: [2],
    reportsFilters: {
      branches: [],
      departments: [],
      freeSearch: "",
    },

    currentPage: 1,
    per_page: 15,
    total: 0,
  },
  currentProcedureType: 3,
  selectedNewProcedure: 3,
  isDataLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROCEDURES:
      return { ...state, procedures: payload };
    case SET_BRANCH_EMPLOYEE:
      return { ...state, branchEmployees: payload };
    case SET_SHOW_PROCEDURES:
      return { ...state, showProcedure: payload };
    case SET_PROCEDURE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, [payload.name]: payload.value },
      };
    case SET_PAGINATION_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          currentPage: payload.current_page,
          per_page: payload.per_page,
          total: payload.total,
        },
      };
    case SET_REPORTS_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          reportsFilters: {
            ...state.filter.reportsFilters,
            [payload.name]: payload.value,
          },
        },
      };
    case SET_NEW_PROCEDURES_TYPE:
      return {
        ...state,
        proceduresType: [...state.proceduresType, payload],
      };
    case SET_CURRENT_PROCEDURE:
      return {
        ...state,
        selectedNewProcedure: payload,
      };
    case SET_PROCEDURE_TYPE:
      return {
        ...state,
        currentProcedureType: payload,
      };
    case SET_DELETED_AT:
      return {
        ...state,
        procedures: [...payload],
      };
    case GET_PROCEDURES_TYPE:
      return {
        ...state,
        proceduresType: [...payload],
      };
    case GET_PROCEDURES_EMAILS:
      return {
        ...state,
        procedureEmails: [...payload],
      };
    case SET_SHOW_PROCEDURE:
      return {
        ...state,
        showProcedure: payload,
      };
    case RESET_PROCEDURES_FILTERS:
      return { ...state, filter: { ...INITIAL_STATE.filter } };

    case RESET_REPORTS_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          reportsFilters: { ...INITIAL_STATE.filter.reportsFilters },
        },
      };
    case RESET_PAGINATION_DATA:
      return {
        ...state,
        filter: { ...state.filter, currentPage: 1, per_page: 15, total: 0 },
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        proceduresType: [...payload],
      };

    case ADD_CATEGORY:
      return {
        ...state,
        proceduresType: [...state.proceduresType, payload],
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        procedureEmails: [...payload],
      };

    case ADD_EMAIL:
      return {
        ...state,
        procedureEmails: [...state.procedureEmails, payload],
      };
    case SET_EMPLOYEE_PROCEDURES:
      return { ...state, employeeProcedures: payload };
    case SET_DATA_LOADING:
      return { ...state, isDataLoading: payload };
    default:
      return state;
  }
};
