import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";

// ======== ICONS ========

// ======== CONSTANTS ========
import { login } from "../services/api";

// ======== COMPONENTS ========
import { Button, Grid } from "@mui/material";
import Form from "../../../components/Forms/Form";
import PermanentInputForm from "./PermanentInputForm";

export const PermanentPassword = (props) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========

  // ======== HANDLERS ========
  const handleSubmit = async (credentials, methods) => {
    try {
      dispatch(login(credentials));
    } catch (error) {
      console.error(error);
    }

    // dispatch(stopLoading());
  };
  // ======== CONSTANTS ========
  const defaultValues = useMemo(
    () => ({
      email: "",
      password: "",
      remember_me: false,
    }),
    []
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Form
          formId="login-by-permanent"
          onSubmit={handleSubmit}
          mode="onBlur"
          defaultValues={defaultValues}
        >
          <PermanentInputForm />
        </Form>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Button
            type="submit"
            form="login-by-permanent"
            variant="contained"
            color="primary"
            size="large"
            sx={isMobile ? { width: "100%" } : {}}
          >
            התחבר\י
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PermanentPassword;
