import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import {
  BASE_URL,
  CASE_BY_CATEGORY,
  CATEGORIES_URL,
  CONST_EXPENSE,
  DELETE_CATEGORY_GOAL,
  EXPENSE_TYPE,
  GET_CATEGORY_GOALS,
  GET_CONST_EXPENSE_DETAILS,
  GET_FEE_EXPENSE_DETAILS,
  GET_ITEMS_BY_CATEGORY,
  GET_ITEMS_BY_SUPPLIER,
  GET_ITEMS_NO_FILTER,
  GET_ITEM_ORDER_DETAILS,
  GET_OPEN_ORDERS,
  GET_OPERATIONAL_ORDERS_BY_CATEGORY,
  GET_ORDERS_BY_DATE,
  GET_PULLS_TRANSFERS,
  GET_REFUND_ORDERS,
  GET_SUPPLIERS,
  OPERATIONAL_EXPENSES,
  SUPPLIER_TABLE,
  UPDATE_CATEGORY_GOAL,
  getOperationalExpenses,
  getOperationalPieData,
  getOperationalTablesData,
} from "../../pages/expenseSummary/consts";
import { format } from "date-fns";
import { defineds } from "../../components/datepicker/const";
import { spread, uniqueId } from "lodash";
import { CategoryGoalsStatus } from "../../pages/expenseSummary/modals/CategoryGoalsConsts";

const currentDate = new Date();

const INITIAL_STATE = {
  bulletsData: {},
  openOrdersModal: {
    openOrders: [],
    isLoading: false,
    openModal: false,
  },
  refundOrdersModal: {
    refundOrders: [],
    isLoading: false,
    openModal: false,
  },
  pullsAndTransfersModal: {
    pullsAndTransfers: [],
    isLoading: false,
    openModal: false,
  },
  constExpenseModal: {
    data: [],
    isLoading: false,
    openModal: false,
    modalName: null,
  },
  OrdersByDateModal: {
    data: [],
    isLoading: false,
    openModal: false,
  },
  AllItemsModal: {
    data: [],
    isLoading: false,
    openModal: false,
  },
  expenseTables: {
    categoryGoals: [],
    saveGoal: false,
    openCategoryGoalsModal: false,
    operationalMainTable: [],
    mainTable: [],
    isLoading: false,
    openOperationalMainTable: false,
    mainSuppliersTable: [],
    subTable: {
      data: [],
      openTable: false,
      isLoading: false,
    },
    orderDetailTable: {
      data: [],
      openTable: false,
      isLoading: false,
    },
  },
  filters: {
    categories: [],
    suppliers: [],
    expense_type: OPERATIONAL_EXPENSES,
    selectedDates: {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 1
      ),
      key: "selection",
      label: "החודש",
    },
    branch_id: null,
    category_ids: [],
    suppliers_ids: [],
  },
  selectedTab: 0,
  isLoading: false,
};
const expenseSummarySlice = createSlice({
  name: "expenseSummary",
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
    closeOpenOrdersModal: (state, action) => {
      state.openOrdersModal.openModal = false;
    },
    closeAllItemsModal: (state, action) => {
      state.AllItemsModal.openModal = false;
    },
    closeRefundOrdersModal: (state, action) => {
      state.refundOrdersModal.openModal = false;
    },
    closeConstExpenseModal: (state, action) => {
      state.constExpenseModal.openModal = false;
    },
    closeOrdersByDateModal: (state, action) => {
      state.OrdersByDateModal.openModal = false;
    },
    openGoalsModal: (state, action) => {
      state.expenseTables.openCategoryGoalsModal = action.payload;
    },
    closePullAndTransferModal: (state, action) => {
      state.pullsAndTransfersModal.openModal = false;
    },
    addCategoryGoal: (state, action) => {
      state.expenseTables.categoryGoals.push({
        id: uniqueId("new_goal"),
        category_name: null,
        goal: null,
        goal_type: 1,
        status: CategoryGoalsStatus.TYPE_PENDING,
        show: true,
        is_new: true,
      });
    },
    deleteCategoryGoal: (state, action) => {
      state.expenseTables.categoryGoals =
        state.expenseTables.categoryGoals.filter(
          (category) => category.id !== action.payload
        );
    },
    setGoalEdit: (state, action) => {
      const category = action.payload.category;
      category.show = action.payload.show;
      const categoryIndex = state.expenseTables.categoryGoals.findIndex(
        (category) =>
          category.category_id === action.payload.category.category_id
      );

      state.expenseTables.categoryGoals[categoryIndex] = category;
    },
    updateGoalsField: (state, action) => {
      const { index, category } = action.payload;
      state.expenseTables.categoryGoals[index] = category;
    },
    resetSubTables: (state, action) => {
      state.expenseTables.subTable.data = [];
      state.expenseTables.subTable.isLoading = false;
      state.expenseTables.subTable.openTable = false;
      state.expenseTables.orderDetailTable.data = [];
      state.expenseTables.orderDetailTable.isLoading = false;
      state.expenseTables.orderDetailTable.openTable = false;
    },
    setTabId: (state, action) => {
      state.selectedTab = action.payload;
    },
    setExpenseType: (state, action) => {
      state.filters.expense_type = action.payload;
    },
    resetState: (state, action) => {
      state.bulletsData = INITIAL_STATE.bulletsData;
      state.expenseTables = INITIAL_STATE.expenseTables;
      state.isLoading = INITIAL_STATE.isLoading;
      state.openOrdersModal = INITIAL_STATE.openOrdersModal;
      state.refundOrdersModal = INITIAL_STATE.refundOrdersModal;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExpenseData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpenseData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bulletsData = action.payload.bullets_data;
      state.expenseTables.mainTable = action.payload.order_by_category;
      state.expenseTables.mainSuppliersTable =
        action.payload.order_by_suppliers;
    });
    builder.addCase(fetchExpenseData.rejected, (state, action) => {
      state.isLoading = false;
      state.bulletsData = {};
      state.expenseTables.mainTable = [];
    });

    builder.addCase(getOpenOrders.pending, (state) => {
      state.openOrdersModal.isLoading = true;
    });
    builder.addCase(getOpenOrders.fulfilled, (state, action) => {
      state.openOrdersModal.isLoading = false;
      state.openOrdersModal.openOrders = action.payload;
      state.openOrdersModal.openModal = true;
    });

    builder.addCase(getOpenOrders.rejected, (state, action) => {
      state.openOrdersModal.isLoading = false;
      state.openOrdersModal.openModal = false;
      state.openOrdersModal.openOrders = [];
    });
    builder.addCase(getRefundOrders.pending, (state) => {
      state.refundOrdersModal.isLoading = true;
    });
    builder.addCase(getRefundOrders.fulfilled, (state, action) => {
      state.refundOrdersModal.isLoading = false;
      state.refundOrdersModal.refundOrders = action.payload;
      state.refundOrdersModal.openModal = true;
    });

    builder.addCase(getRefundOrders.rejected, (state, action) => {
      state.refundOrdersModal.isLoading = false;
      state.refundOrdersModal.openModal = false;
      state.refundOrdersModal.refundOrders = [];
    });
    builder.addCase(getOrderItems.pending, (state, action) => {
      state.expenseTables.subTable.isLoading = true;
      state.expenseTables.subTable.openTable = true;
      state.expenseTables.subTable.data = [];
    });
    builder.addCase(getOrderItems.fulfilled, (state, action) => {
      state.expenseTables.subTable.isLoading = false;

      state.expenseTables.subTable.data = action.payload;
    });
    builder.addCase(getOrderItems.rejected, (state, action) => {
      state.expenseTables.subTable.isLoading = false;
      state.expenseTables.subTable.openTable = false;

      state.expenseTables.subTable.data = [];
    });
    builder.addCase(getItemOrderDetails.pending, (state, action) => {
      state.expenseTables.orderDetailTable.isLoading = true;
      state.expenseTables.orderDetailTable.openTable = true;
      state.expenseTables.orderDetailTable.data = [];
    });
    builder.addCase(getItemOrderDetails.fulfilled, (state, action) => {
      state.expenseTables.orderDetailTable.isLoading = false;
      state.expenseTables.orderDetailTable.data = action.payload;
    });
    builder.addCase(getItemOrderDetails.rejected, (state, action) => {
      state.expenseTables.orderDetailTable.isLoading = false;
      state.expenseTables.orderDetailTable.openTable = false;
      state.expenseTables.orderDetailTable.data = [];
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.filters.categories = action.payload.map(
        ({ id, name, const_category_id }) => ({
          label: name,
          value: parseInt(id),
          const_id: const_category_id,
        })
      );
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.filters.categories = [];
    });
    builder.addCase(getSuppliers.fulfilled, (state, action) => {
      state.filters.suppliers = action.payload.map(({ supplier_id, name }) => ({
        label: name,
        value: parseInt(supplier_id),
      }));
    });
    builder.addCase(getSuppliers.rejected, (state, action) => {
      console.log(action);
      state.filters.suppliers = [];
    });
    builder.addCase(getCategoryGoals.fulfilled, (state, action) => {
      state.expenseTables.categoryGoals = action.payload;
    });
    builder.addCase(updateCategoryGoal.pending, (state, action) => {
      state.expenseTables.saveGoal = true;
    });
    builder.addCase(updateCategoryGoal.fulfilled, (state, action) => {
      const { categoryIndex, updatedCategory } = action.payload;

      state.expenseTables.categoryGoals[categoryIndex] = updatedCategory;
      const mainTableCatIndex = state.expenseTables.mainTable.findIndex(
        (item) => item.category_id === updatedCategory.category_id
      );

      if (state.expenseTables.mainTable[mainTableCatIndex]) {
        state.expenseTables.mainTable[mainTableCatIndex].goal =
          updatedCategory.goal;

        state.expenseTables.mainTable[mainTableCatIndex].goal_type =
          updatedCategory.goal_type;
      }

      state.expenseTables.saveGoal = false;
    });
    builder.addCase(updateCategoryGoal.rejected, (state, action) => {
      console.log(action);

      state.expenseTables.saveGoal = false;
    });
    builder.addCase(deleteCategoryGoal.fulfilled, (state, action) => {
      state.expenseTables.categoryGoals =
        state.expenseTables.categoryGoals.filter(
          (goal) => goal.id !== action.payload
        );

      //TODO:Fix it so when delete the main table get updated.
      // const mainTableCatIndex = state.expenseTables.mainTable.findIndex(
      //   (item) => item.category_id === action.payload
      // );
      // state.expenseTables.mainTable[mainTableCatIndex].goal = 0;

      // state.expenseTables.mainTable[mainTableCatIndex].goal_type = 0;
    });
    builder.addCase(fetchExpenseOperationalData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpenseOperationalData.fulfilled, (state, action) => {
      state.isLoading = false;
      let spreadData = {};

      action.payload.forEach((data) => {
        if (data.config.url.includes(getOperationalTablesData)) {
          state.expenseTables.operationalMainTable = data.data;
        } else {
          spreadData = { ...spreadData, ...data.data };
        }
      });
      state.bulletsData = spreadData;
    });

    builder.addCase(getConstExpenses.pending, (state, action) => {
      if (action.meta.arg.selectedTab === CASE_BY_CATEGORY) {
        state.constExpenseModal.openModal = true;
      }
      state.constExpenseModal.isLoading = true;
      state.expenseTables.isLoading = true;
    });
    builder.addCase(getConstExpenses.fulfilled, (state, action) => {
      state.constExpenseModal.isLoading = false;
      state.constExpenseModal.modalName =
        action.payload.expense_type === CONST_EXPENSE
          ? "הוצאות קבועות"
          : "עמלות";
      if (action.payload.selectedTab === "byCategory") {
        state.constExpenseModal.data = action.payload.data;
        state.constExpenseModal.openModal = true;
      } else {
        state.expenseTables.isLoading = false;
        state.expenseTables.mainSuppliersTable = action.payload.data;
        state.expenseTables.openOperationalMainTable = true;
      }
    });
    builder.addCase(getConstExpenses.rejected, (state, action) => {
      state.constExpenseModal.isLoading = false;
      state.constExpenseModal.data = [];
      state.constExpenseModal.openModal = false;
      state.constExpenseModal.modalName = null;
    });
    builder.addCase(getOperationalOrdersByCategory.pending, (state, action) => {
      state.expenseTables.isLoading = true;
    });
    builder.addCase(
      getOperationalOrdersByCategory.fulfilled,
      (state, action) => {
        if (action.payload.selected_tab === SUPPLIER_TABLE) {
          state.expenseTables.isLoading = false;
          state.expenseTables.mainSuppliersTable = action.payload.data;
        } else {
          state.expenseTables.isLoading = false;
          state.expenseTables.mainTable = action.payload.data;
        }
        state.expenseTables.openOperationalMainTable = true;
      }
    );
    builder.addCase(
      getOperationalOrdersByCategory.rejected,
      (state, action) => {
        state.expenseTables.isLoading = false;
        state.expenseTables.mainTable = [];
        state.expenseTables.openOperationalMainTable = false;
      }
    );
    builder.addCase(getOrdersByDate.pending, (state, action) => {
      state.OrdersByDateModal.isLoading = true;
      state.OrdersByDateModal.openModal = true;
    });
    builder.addCase(getOrdersByDate.fulfilled, (state, action) => {
      state.OrdersByDateModal.isLoading = false;
      state.OrdersByDateModal.data = action.payload;
    });
    builder.addCase(getOrdersByDate.rejected, (state, action) => {
      state.OrdersByDateModal.isLoading = false;
      state.OrdersByDateModal.openModal = false;
      state.OrdersByDateModal.data = [];
    });
    builder.addCase(getAllItems.pending, (state, action) => {
      state.AllItemsModal.isLoading = true;
      state.AllItemsModal.openModal = true;
    });
    builder.addCase(getAllItems.fulfilled, (state, action) => {
      state.AllItemsModal.isLoading = false;
      state.AllItemsModal.data = action.payload;
    });
    builder.addCase(getAllItems.rejected, (state, action) => {
      state.AllItemsModal.isLoading = false;
      state.AllItemsModal.openModal = false;
      state.AllItemsModal.data = [];
    });
    builder.addCase(getPullsAndTransfers.pending, (state, action) => {
      state.pullsAndTransfersModal.isLoading = true;
      state.pullsAndTransfersModal.openModal = true;
    });
    builder.addCase(getPullsAndTransfers.fulfilled, (state, action) => {
      state.pullsAndTransfersModal.pullsAndTransfers = action.payload;
      state.pullsAndTransfersModal.isLoading = false;
    });
    builder.addCase(getPullsAndTransfers.rejected, (state, action) => {
      state.pullsAndTransfersModal.isLoading = false;
      state.pullsAndTransfersModal.openModal = false;
      state.pullsAndTransfersModal.pullsAndTransfers = [];
    });
  },
});

export const fetchExpenseData = createAsyncThunk(
  "expenseSummary/fetchExpenseData",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    let systemCategories = [];
    filters.category_ids.forEach((checkedCategory) => {
      const category = filters.categories.find(
        (category) => category.value === checkedCategory
      );

      if (category?.value !== category?.const_id) {
        systemCategories.push(category.const_id);
      }
    });
    const res = await Axios.get(BASE_URL, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
        category_ids: [...filters.category_ids, ...systemCategories],
        suppliers_ids: filters.suppliers_ids,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);
export const getOpenOrders = createAsyncThunk(
  "expenseSummary/getOpenOrders",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    const res = await Axios.get(`${BASE_URL}/${GET_OPEN_ORDERS}`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const getPullsAndTransfers = createAsyncThunk(
  "expenseSummary/getPullsAndTransfers",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    let systemCategories = [];
    filters.category_ids.forEach((checkedCategory) => {
      const category = filters.categories.find(
        (category) => category.value === checkedCategory
      );

      if (category?.value !== category?.const_id) {
        systemCategories.push(category.const_id);
      }
    });
    const res = await Axios.get(`${BASE_URL}/${GET_PULLS_TRANSFERS}`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
        category_ids: [...filters.category_ids, ...systemCategories],
        suppliers_ids: filters.suppliers_ids,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const getRefundOrders = createAsyncThunk(
  "expenseSummary/getRefundOrders",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    const res = await Axios.get(`${BASE_URL}/${GET_REFUND_ORDERS}`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const getCategories = createAsyncThunk(
  "expenseSummary/getCategories",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const res = await Axios.get(`${CATEGORIES_URL}`, {
      params: {
        status: [1],
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const getSuppliers = createAsyncThunk(
  "expenseSummary/getSuppliers",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    const res = await Axios.get(`${BASE_URL}/${GET_SUPPLIERS}`, {
      params: {
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const getOrderItems = createAsyncThunk(
  "expenseSummary/getOrderItems",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    const { order_type, id } = payload;

    let category_ids = [id];
    if (order_type === "category") {
      let category = filters.categories.find(
        (category) => category.value === id || category.const_id === id
      );

      if (category.const_id === id) {
        category_ids.push(category.value);
      } else if (category?.value !== category?.const_id) {
        category_ids.push(category.const_id);
      }
    }

    const res = await Axios.get(
      `${BASE_URL}/${
        order_type === "category"
          ? GET_ITEMS_BY_CATEGORY
          : GET_ITEMS_BY_SUPPLIER
      }`,
      {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id ?? payload.branch_id,
          id: order_type === "category" ? category_ids : id,
          expense_type: payload.expense_type,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return data;
  }
);
export const getItemOrderDetails = createAsyncThunk(
  "expenseSummary/getItemOrderDetails",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    const res = await Axios.get(`${BASE_URL}/${GET_ITEM_ORDER_DETAILS}`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
        item_id: payload.item_id,
        expense_type: payload.expense_type,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const getCategoryGoals = createAsyncThunk(
  "expenseSummary/getCategoryGoals",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;

    const res = await Axios.get(`${BASE_URL}/${GET_CATEGORY_GOALS}`, {
      params: {
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const goals = await res.data;

    const modedGoals = goals.map((goal) => ({ ...goal, show: false }));

    return modedGoals;
  }
);

export const updateCategoryGoal = createAsyncThunk(
  "expenseSummary/updateCategoryGoal",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;

    let category_ids = [payload.category.category_id];
    let category = filters.categories.find(
      (category) =>
        category.value === payload.category.category_id ||
        category.const_id === payload.category.category_id
    );

    if (category.const_id === payload.category.category_id) {
      category_ids.push(category.value);
    } else if (category?.value !== category?.const_id) {
      category_ids.push(category.const_id);
    }

    delete payload.category.show;
    if (
      typeof payload.category.id === "string" &&
      payload.category.id.includes("new_goal")
    ) {
      delete payload.category.id;
    }
    const res = await Axios.post(
      `${BASE_URL}/${UPDATE_CATEGORY_GOAL}`,
      { payload: payload.category },
      {
        params: {
          branch_id: filters.branch_id.id ?? payload.category.branch_id,
          category_id: payload.category.category_id,
          categories_id: category_ids,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.status === 200 || res.status === 201) {
      const updatedCategory = res.data;
      return { updatedCategory, categoryIndex: payload.categoryIndex };
    }
  }
);

export const deleteCategoryGoal = createAsyncThunk(
  "expenseSummary/deleteCategoryGoal",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    try {
      const res = await Axios.delete(`${BASE_URL}/${DELETE_CATEGORY_GOAL}`, {
        params: {
          category_id: payload,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.status === 200) {
        return payload;
      }
    } catch (err) {}
  }
);
export const fetchExpenseOperationalData = createAsyncThunk(
  "expenseSummary/operational",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;

    const operationData =  Axios.get(
      `${BASE_URL}/${getOperationalExpenses}`,
      {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id ?? payload.branch_id,
          expense_type: filters.expense_type,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const operationPieData =  Axios.get(
      `${BASE_URL}/${getOperationalPieData}`,
      {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id ?? payload.branch_id,
          expense_type: filters.expense_type,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const operationTableData =  Axios.get(
      `${BASE_URL}/${getOperationalTablesData}`,
      {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id ?? payload.branch_id,
          expense_type: filters.expense_type,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const responses = await Axios.all([
      operationData,
      operationPieData,
      operationTableData,
    ]);
    const status = responses.every((response) => response.status === 200);

    if (status) {
      return responses;
    }
  }
);

export const getConstExpenses = createAsyncThunk(
  "expenseSummary/getConstExpenses",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    const selectedTab = payload.selectedTab === 1 ? "byCategory" : "bySupplier";

    const res = await Axios.get(
      `${BASE_URL}/${
        payload.expense === CONST_EXPENSE
          ? GET_CONST_EXPENSE_DETAILS
          : GET_FEE_EXPENSE_DETAILS
      }`,
      {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id ?? payload.branch_id,
          expense_type: filters.expense_type,
          selectedTab: selectedTab,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return { data, selectedTab, expense_type: payload.expense };
  }
);
export const getOperationalOrdersByCategory = createAsyncThunk(
  "expenseSummary/getOperationalOrdersByCategory",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    const res = await Axios.get(
      `${BASE_URL}/${GET_OPERATIONAL_ORDERS_BY_CATEGORY}`,
      {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id ?? payload.branch_id,
          expense_type: filters.expense_type,
          selectedTab: payload.selectedTab,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return { data, selected_tab: payload.selectedTab };
  }
);

export const getOrdersByDate = createAsyncThunk(
  "expenseSummary/getOrdersByDate",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    let systemCategories = [];
    filters.category_ids.forEach((checkedCategory) => {
      const category = filters.categories.find(
        (category) => category.value === checkedCategory
      );

      if (category?.value !== category?.const_id) {
        systemCategories.push(category.const_id);
      }
    });
    const res = await Axios.get(`${BASE_URL}/${GET_ORDERS_BY_DATE}`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
        category_ids: [...filters.category_ids, ...systemCategories],
        suppliers_ids: filters.suppliers_ids,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);

export const getAllItems = createAsyncThunk(
  "expenseSummary/getAllItems",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    let systemCategories = [];
    filters.category_ids.forEach((checkedCategory) => {
      const category = filters.categories.find(
        (category) => category.value === checkedCategory
      );

      if (category?.value !== category?.const_id) {
        systemCategories.push(category.const_id);
      }
    });
    const res = await Axios.get(`${BASE_URL}/${GET_ITEMS_NO_FILTER}`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
        category_ids: [...filters.category_ids, ...systemCategories],
        suppliers_ids: filters.suppliers_ids,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const {
  reducer: expenseSummaryReducer,
  actions: expenseSummaryActions,
} = expenseSummarySlice;
