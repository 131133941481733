// import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { useMemo } from "react";
import { useAsyncDebounce } from "react-table";
import { COLOR_RESTIGO_PRIMARY } from "../../const/colors";
import { AutoComplete, selectAll } from "../Forms/inputs/AutoComplete";
import { VscTriangleDown } from "react-icons/vsc";
import { useScreenSize } from "../../Hooks";
import { isFunction } from "lodash";
import TableSearch from "./components/TableSearch";

const useSetFilter = (setFilter) => {
  const setDebounceFilter = useAsyncDebounce((value) => {
    setFilter(value);
  }, 200);
  return setDebounceFilter;
};

const MultiSelectFilter = ({
  multiple = true,
  column: {
    setFilter,
    preFilteredRows,
    id,
    filterValue,
    Header,
    filterAt,
    filterAllText = "הכל",
    filterLabel,
    filterOptions,
  },
}) => {
  const { isMobile } = useScreenSize();
  const onChange = useSetFilter(setFilter);
  const options = useMemo(() => {
    let options;
    if (filterOptions) {
      options = new Set(...filterOptions);
    } else {
      options = new Set();
      switch (id) {
        case "status":
          preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
          });
          break;
        case "branches":
          preFilteredRows.forEach((row) => {
            row.values.branches.forEach((branch) => {
              options.add(branch.name);
            });
          });
          break;
        case "employee_types":
          preFilteredRows.forEach((row) => {
            row.values.employee_types.forEach((et) => {
              options.add(et.title);
            });
          });
          break;

        default:
          preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
          });
          break;
      }
    }
    return [...options.values()];
  }, [id, preFilteredRows, filterOptions]);

  return (
    <AutoComplete
      size="small"
      className={isMobile ? "customAutoMob" : "customAuto"}
      popupIcon={<VscTriangleDown color={COLOR_RESTIGO_PRIMARY} />}
      renderTags={(value, getTagProps, ownerState) => {
        //console.log(ownerState.options,value)
        let ownerStateClone = [...ownerState.options];
        if (typeof ownerStateClone[0] !== "string")
          ownerStateClone = [
            ...ownerStateClone.slice(1, ownerStateClone.length),
          ];
        return ownerStateClone.length === value.length
          ? filterAllText
          : value.length > 3
          ? value.slice(0, 3).join(", ") + "+" + String(value.length - 3)
          : value.join(", ");
      }}
      onChange={(e, value) => {
        //console.log(value);
        onChange(value);
      }}
      {...{ options, multiple }}
      value={filterValue ?? options}
      disableClearable={false}
      inputProps={{
        variant: "outlined",
        sx: { minWidth: "150px" },
        label: isMobile ? Header : filterLabel, //?? Header,
      }}
    />
  );
};

const TextFilter = ({ column: { setFilter, Header } }) => {
  const onChange = useSetFilter(setFilter);

  return (
    <TextField
      placeholder="חפש כאן..."
      label={`חפש ב${Header}`}
      type="search"
      onChange={(e) => {
        onChange(e.target.value || undefined);
      }}
    />
  );
};

const GlobalFilter = ({ setGlobalFilter }) => {
  const onChange = useSetFilter(setGlobalFilter);
  return (
    <TextField
      sx={{
        width: "100%",
        marginTop: "2rem",
      }}
      type="search"
      placeholder="חפש כאן..."
      label="חיפוש כללי"
      onChange={(e) => {
        onChange(e.target.value || undefined);
      }}
    />
  );
};

const TableSearchGlobalFilter = ({ setGlobalFilter, collapsedSearch }) => {
  const onChange = useSetFilter(setGlobalFilter);

  return (
    <TableSearch
      isCollapse={collapsedSearch}
      handleSearchChange={(value) => {
        onChange(value);
      }}
    />
  );
};

export { MultiSelectFilter, TextFilter, GlobalFilter,TableSearchGlobalFilter };
