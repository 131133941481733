import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useScreenSize } from "../../../../Hooks";
import ErrorEmployeesTable from "../tips/ErrorEmployeesTable";

const LockDayErrorEmployeeDialog = ({
  open,
  setOpen,
  errorEmployees,
  setErrorEmployees,
  lockDay,
  date,
  setDate,
}) => {
  const { isMobile } = useScreenSize()
  const canLock = Object.values(errorEmployees).every(
    (event) => event.addressed
  );
  const handleSubmit = () => {
    if (canLock) {
      setErrorEmployees({});
      setDate(null);
      lockDay(date);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>לא ניתן לנעול יום, קיימים עובדים ללא שעת כניסה או יציאה </DialogTitle>
      <DialogContent>
        <ErrorEmployeesTable
          errorEmployees={errorEmployees}
          setErrorEmployees={setErrorEmployees}
          type="lock_day"
          tableTiltle=" "
          isMobile={isMobile}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            setErrorEmployees({});
            setDate(null);
          }}
          variant="contained"
        >
          ביטול
        </Button>
        <Button
          disabled={!canLock}
          onClick={() => handleSubmit()}
          color="primary"
          variant="contained"
        >
          נעל יום
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LockDayErrorEmployeeDialog;
