import CsvWorker from "./csv.worker.js";

// ======== CONSTANTS ========
import translations from "../../../i18n/translations";

// ======== COMPONENTS ========
import BtnTooltip from "../../tooltips/BtnTooltip";
import { IconButton } from "@mui/material";

// ======== REDUX ========
import { startLoading, stopLoading } from "../../../redux/actions/loaderAction";
import { useDispatch } from "react-redux";

export const DownloadCsv = ({
  data,
  columns,
  tooltipMessage = "",
  Icon = null,
  translation_endpoint = "",
  fileName = "export",
}) => {
  const dispatch = useDispatch();

  const handleCsvExport = () => {
    const isBrowserEnviroment = typeof window !== "undefined";

    if (isBrowserEnviroment) {
      /**
       * worker can be made with 2 options
       * 1. new Worker('path/to/worker);
       * 2. Blob which contains string of the worker code (aka inline worker)
       * Webpack doesnt support option 1 natively so we use option 2
       */
      const workerCode = CsvWorker.toString();
      const workerBlob = new Blob([`(${workerCode})()`]);
      const worker = new Worker(URL.createObjectURL(workerBlob));

      dispatch(startLoading("מכין קובץ נתונים..."));
      worker.postMessage({
        data,
        translations,
        columns,
        translation_endpoint,
      });

      worker.onmessage = (e) => {
        const csvBlob = e.data;
        const downloadUrl = URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(downloadUrl);
        worker.terminate();
        dispatch(stopLoading());
      };
    }
  };

  return (
    <BtnTooltip placement="top" text={tooltipMessage}>
      <IconButton style={{ color: "#1D6F42" }} onClick={handleCsvExport}>
        {Icon && <Icon size="1.3em" />}
      </IconButton>
    </BtnTooltip>
  );
};

export default DownloadCsv;
