// roleReducer.js
import { FETCH_ROLES, FETCH_ROLES_MAP } from "../actions/scheduleAction/types";
import { mapKeys } from "lodash";
import { LOGOUT } from "../actions/loginAction/types";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ROLES_MAP:
      return { ...mapKeys(action.payload, "department_id") };
    case LOGOUT_ADMIN:
    case LOGOUT:
      return {};
    default:
      return state;
  }
};
