export const SIDEBAR_COLLAPSED = "SIDEBAR_COLLAPSED";
export const SET_DATE = "SET_DATE";
export const SET_IS_MOBILE = "SET_IS_MOBILE";
export const SET_IS_TABLET = "SET_IS_TABLET";
export const SET_ACTIVE = "SET_ACTIVE";
export const SET_TITLE = "SET_TITLE";
export const EDIT_MODE_SCHEDULE_CHANGE = "EDIT_MODE_SCHEDULE_CHANGE";
export const SET_ADMIN_ACTIVE = "SET_ADMIN_ACTIVE";
export const SET_IS_FRAME = "SET_IS_FRAME";
export const DRAG_CELL_START = "DRAG_CELL_START";
export const DRAG_CELL_END = "DRAG_CELL_END";

export const SET_CURRENT_VERSION_DATE = "SET_CURRENT_VERSION_DATE";
export const SET_SHOW_VERSION_MODAL = "SET_SHOW_VERSION_MODAL";
export const SET_WEEK = "SET_WEEK";
