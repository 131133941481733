//SET

export const SET_PROCEDURES = "SET_PROCEDURES";
export const SET_BRANCH_EMPLOYEE = "SET_BRANCH_EMPLOYEE";
export const SET_SHOW_PROCEDURES = "SET_SHOW_PROCEDURES";
export const SET_PROCEDURE_FILTER = "SET_PROCEDURE_FILTER";
export const SET_REPORTS_FILTER = "SET_REPORTS_FILTER";
export const SET_DELETED_AT = "SET_DELETED_AT";
export const SET_SHOW_PROCEDURE = "SET_SHOW_PROCEDURE";
export const SET_PROCEDURE_TYPE = "SET_PROCEDURE_TYPE";
export const SET_NEW_PROCEDURES_TYPE = "SET_NEW_PROCEDURES_TYPE";
export const SET_CURRENT_PROCEDURE = "SET_CURRENT_PROCEDURE";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_EMAIL = "ADD_EMAIL";
export const SET_DATA_LOADING = "SET_DATA_LOADING";
export const SET_EMPLOYEE_PROCEDURES = "SET_EMPLOYEE_PROCEDURES";
export const SET_PAGINATION_FILTERS = "SET_PAGINATION_FILTERS";

//RESET
export const RESET_PROCEDURES_FILTERS = "RESET_PROCEDURE_FILTERS";
export const RESET_REPORTS_FILTERS = "RESET_REPORTS_FILTERS";
export const RESET_PAGINATION_DATA = "RESET_PAGINATION_DATA";

//GET
export const GET_PROCEDURES_TYPE = "GET_PROCEDURES_TYPES";
export const GET_PROCEDURES_EMAILS = "GET_PROCEDURES_EMAILS";
//UPDATE

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
