import {
  Box,
  Menu,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { convertFromHTML, convertToHTML } from "draft-convert";
import {
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
} from "draft-js";
import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  createContext,
  useCallback,
} from "react";
import {
  BsTypeBold,
  BsTypeItalic,
  BsTypeStrikethrough,
  BsTypeUnderline,
} from "react-icons/bs";
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from "../ErrorMessage";
import { REQUIRED_MESSAGE } from "../constants";
import { COLOR_RESTIGO_PRIMARY } from "../../../const/colors";
import { AiOutlineUnorderedList, AiOutlineOrderedList } from "react-icons/ai";

const TAB = "tab";

const colorStyleMap = {
  "color-black": {
    color: "rgb(0,0,0)",
  },
  "color-red": {
    color: "rgb(255, 0, 0)",
  },
  "color-orange": {
    color: "rgb(255, 127, 0)",
  },
  "color-yellow": {
    color: "rgb(180, 180, 0)",
  },
  "color-green": {
    color: "rgb(0, 180, 0)",
  },
  "color-blue": {
    color: "rgb(0, 0, 255)",
  },
  "color-indigo": {
    color: "rgb(75, 0, 130)",
  },
  "color-violet": {
    color: "rgb(127, 0, 255)",
  },
};
const fontSizeStyleMap = {
  "fonstSize-10": {
    fontSize: "10px",
  },
  "fonstSize-12": {
    fontSize: "12px",
  },
  "fonstSize-14": {
    fontSize: "14px",
  },
  "fonstSize-16": {
    fontSize: "16px",
  },
  "fonstSize-18": {
    fontSize: "18px",
  },
  "fonstSize-20": {
    fontSize: "20px",
  },
  "fonstSize-22": {
    fontSize: "22px",
  },
  "fonstSize-24": {
    fontSize: "24px",
  },
};

const Context = createContext();
const RichTextEditor2 = ({
  placeholder,
  spellCheck,
  label,
  name,
  onBlur,
  inputRef,
  inlineStyle,
  colorize,
  fontSize,
  entities,
  editorState,
  setEditorState,
  style,
  decorator,
}) => {
  const [isEditorFocus, setIsEditorFocus] = useState(false);
  const [entitiesMenuOpen, setEntitiesMenuOpen] = useState(false);
  const editorRef = useRef(null);
  const editorBoxRef = useRef(null);

  const focus = useCallback(() => {
    editorRef.current?.focus();
  }, [editorRef]);

  const handleKeyCommand = (command, editorState) => {
    if (command === TAB) {
      const currentState = editorState;
      let newEditorState = Modifier.replaceText(
        currentState.getCurrentContent(),
        currentState.getSelection(),
        "    "
      );
      let ne = EditorState.push(
        currentState,
        newEditorState,
        "insert-characters"
      );
      setEditorState(ne);
      return TAB;
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
    }
    return !!newState;
  };
  const keyBindingFn = (e) => {
    if (e.keyCode === 9) {
      return TAB;
    }
    return getDefaultKeyBinding(e);
  };

  return (
    <div>
      <Context.Provider value={{ editorState, setEditorState, focus }}>
        <Panel
          {...{
            colorize,
            entities,
            fontSize,
            inlineStyle,
            entitiesMenuOpen,
            setEntitiesMenuOpen,
            editorBoxRef,
          }}
        />
      </Context.Provider>
      <div>
        <label htmlFor={name}>{label}</label>
      </div>
      <Box
        sx={{
          borderWidth: "1.5px",
          borderStyle: "solid",
          borderRadius: "5px",
          borderColor: isEditorFocus ? COLOR_RESTIGO_PRIMARY : "gray",
          minHeight: "10rem",
          maxHeight: "70vh",
          cursor: "text",
          padding: "30px",
          overflowY: "scroll",
          ...style,
        }}
        onClick={focus}
        ref={editorBoxRef}
        onContextMenu={(e) => {
          e.preventDefault();
          if (editorState.getSelection().isCollapsed()) {
            return;
          }
          setEntitiesMenuOpen(true);
        }}
      >
        <Editor
          onChange={setEditorState}
          editorState={editorState || EditorState.createEmpty(decorator)}
          {...{
            handleKeyCommand,
            keyBindingFn,
            placeholder,
            spellCheck,
          }}
          ref={(ref) => {
            editorRef.current = ref;
            if (inputRef) {
              inputRef.current = ref;
            }
          }}
          customStyleMap={{
            ...colorStyleMap,
            ...fontSizeStyleMap,
          }}
          onFocus={() => {
            setIsEditorFocus(true);
          }}
          onBlur={(e) => {
            setIsEditorFocus(false);
            onBlur && onBlur(e);
          }}
        />
      </Box>
    </div>
  );
};

const Panel = ({
  inlineStyle,
  colorize,
  fontSize,
  entities,
  entitiesMenuOpen,
  setEntitiesMenuOpen,
  editorBoxRef,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        height: "3.5rem",
        flexWrap: "wrap",
        marginBottom: "3rem",
        gap: 6,
      }}
    >
      {inlineStyle && <InlineStyleButtons />}
      <BlockStyleButtons />
      {colorize && <Colorize styleMap={colorStyleMap} options={COLORS} />}
      {fontSize && (
        <Colorize styleMap={fontSizeStyleMap} options={FONT_SIZES} />
      )}
      <EntitiesMenu
        open={entitiesMenuOpen}
        onClose={() => {
          setEntitiesMenuOpen(false);
        }}
        anchorEl={editorBoxRef.current}
        {...{ entities }}
      />
    </div>
  );
};

const EntitiesMenu = ({ entities, open, anchorEl, onClose }) => {
  const { editorState, setEditorState } = useContext(Context);
  const removeEntity = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      return;
    }
    setEditorState(RichUtils.toggleLink(editorState, selection, null));
  };
  return (
    <Menu
      {...{ open, anchorEl, onClose }}
      keepMounted
      disableAutoFocus
      autoFocus={false}
    >
      {entities?.map((entity, key) => {
        return <EntityMenuItem {...{ entity, key, onClose }} />;
      })}
      <MenuItem onMouseDown={removeEntity}>הסר</MenuItem>
    </Menu>
  );
};

const EntityMenuItem = ({ entity: { name, options }, onClose }) => {
  const { editorState, setEditorState } = useContext(Context);
  const [open, setOpen] = useState(false);
  const menuItemRef = useRef(null);

  const setEntity = (e, value) => {
    e.preventDefault();
    let newEditorState = editorState;
    let entityKey = null;
    const selection = newEditorState.getSelection();
    if (!selection.isCollapsed()) {
      if (value) {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
          name,
          "IMMUTABLE",
          value
        );
        entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        newEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity,
        });
      }
      const updateEditorState = RichUtils.toggleLink(
        newEditorState,
        selection,
        entityKey
      );
      setEditorState(updateEditorState);
    }
    onClose();
    setOpen(false);
  };

  return (
    <>
      <MenuItem
        ref={menuItemRef}
        onMouseDown={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        disabled={!options?.length}
      >
        {name}
      </MenuItem>
      <Menu
        disableAutoFocus
        autoFocus={false}
        keepMounted
        anchorEl={menuItemRef.current}
        {...{ open }}
        onClose={() => {
          setOpen(false);
        }}
      >
        {options.map((option, key) => {
          return (
            <MenuItem
              {...{ key }}
              onMouseDown={(e) => {
                setEntity(e, option);
              }}
            >
              {option.label ?? option}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const BlockStyleButtons = () => {
  const { editorState, setEditorState } = useContext(Context);
  const [blockType, setBlockType] = useState(null);
  useEffect(() => {
    if (!editorState) {
      return;
    }
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    setBlockType(blockType);
  }, [editorState]);

  return (
    <ToggleButtonGroup value={blockType} exclusive>
      {[
        { label: <AiOutlineOrderedList />, value: "unordered-list-item" },
        { label: <AiOutlineUnorderedList />, value: "ordered-list-item" },
      ].map(({ label, value }, key) => (
        <ToggleButton
          {...{ value, key }}
          onMouseDown={(e) => {
            e.preventDefault();
            setEditorState(RichUtils.toggleBlockType(editorState, value));
          }}
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const INLINE_STYLES = [
  { label: <BsTypeBold />, value: "BOLD" },
  { label: <BsTypeItalic />, value: "ITALIC" },
  { label: <BsTypeUnderline />, value: "UNDERLINE" },
  { label: <BsTypeStrikethrough />, value: "STRIKETHROUGH" },
];

const InlineStyleButtons = () => {
  const { editorState, setEditorState } = useContext(Context);
  const style = editorState.getCurrentInlineStyle();

  return (
    <ToggleButtonGroup>
      {INLINE_STYLES.map(({ label, value }, key) => (
        <ToggleButton
          {...{ key, value }}
          selected={style.has(value)}
          onMouseDown={(e) => {
            e.preventDefault();
            setEditorState(RichUtils.toggleInlineStyle(editorState, value));
          }}
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const COLORS = [
  { label: "Black", value: "color-black" },
  { label: "red", value: "color-red" },
  { label: "Orange", value: "color-orange" },
  { label: "Yellow", value: "color-yellow" },
  { label: "Green", value: "color-green" },
  { label: "Blue", value: "color-blue" },
  { label: "Indigo", value: "color-indigo" },
  { label: "Violet", value: "color-violet" },
];
const FONT_SIZES = [
  { label: "10", value: "fonstSize-10" },
  { label: "12", value: "fonstSize-12" },
  { label: "14", value: "fonstSize-14" },
  { label: "16", value: "fonstSize-16" },
  { label: "18", value: "fonstSize-18" },
  { label: "20", value: "fonstSize-20" },
  { label: "22", value: "fonstSize-22" },
  { label: "24", value: "fonstSize-24" },
];

const Colorize = ({ options, styleMap }) => {
  const { editorState, setEditorState, focus } = useContext(Context);

  let color = options[0].value;
  let style = editorState.getCurrentInlineStyle();
  options.forEach(({ value }) => {
    if (!style.has(value)) {
      return;
    }
    color = value;
  });

  const toggle = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const selection = editorState.getSelection();
    const nextContentState = Object.keys(styleMap).reduce(
      (contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color);
      },
      editorState.getCurrentContent()
    );

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );
    const currentStyle = editorState.getCurrentInlineStyle();
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }
    if (!currentStyle.has(value)) {
      nextEditorState = [value].reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }
    setEditorState(nextEditorState);
  };

  return (
    <span>
      <Select
        onChange={toggle}
        value={color}
        MenuProps={{
          TransitionProps: {
            onExited: focus,
          },
        }}
        renderValue={(value) => (
          <p
            style={{
              backgroundColor: styleMap[value]?.color,
              ...styleMap[value],
            }}
          >
            {options.find((option) => option.value === value).label}
          </p>
        )}
        sx={{
          width: "10rem",
          maxHeight: "4rem",
        }}
      >
        {options.map(({ label, value }, key) => {
          return (
            <MenuItem
              sx={{
                backgroundColor: styleMap[value]?.color,
                ...styleMap[value],
                "&:hover, &.Mui-selected, &:hover.Mui-selected": {
                  backgroundColor: styleMap[value]?.color,
                  opacity: 0.7,
                },
              }}
              {...{ value, key }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </span>
  );
};

const ControlRichTextEditor = ({
  name,
  rules,
  required,
  decorator,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      {...{
        name,
        control,
      }}
      rules={{
        ...rules,
        ...(required && {
          validate: {
            required: (v) => {
              return (v && getAsHtml(v) !== "<p></p>") || REQUIRED_MESSAGE;
            },
          },
        }),
      }}
      render={({
        field: { ref, name, onBlur, onChange, value },
        fieldState: { error },
      }) => {
        return (
          <>
            <RichTextEditor2
              {...{
                name,
                onBlur,
                ...(!!error && { style: { borderColor: "red" } }),
                decorator,
                ...props,
              }}
              editorState={value || EditorState.createEmpty(decorator)}
              setEditorState={onChange}
              inputRef={ref}
            />
            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        );
      }}
    />
  );
};

const RichTextEditor = ({ decorator, ...props }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator)
  );

  useEffect(() => {
    setEditorState(EditorState.createEmpty(decorator));
  }, [decorator]);

  return (
    <RichTextEditor2
      {...{ editorState, setEditorState, decorator, ...props }}
    />
  );
};

const getAsHtml = (editorState, entityToHTML) => {
  const html = convertToHTML({
    styleToHTML: (style) => {
      const styleMap = { ...colorStyleMap, ...fontSizeStyleMap };
      if (styleMap[style]) {
        return <span style={{ ...styleMap[style] }} />;
      }
    },
    entityToHTML,
  })(editorState.getCurrentContent());
  return html;
};

const getFromHtml = (html, decorator, htmlToEntity) => {
  const raw = EditorState.createWithContent(
    convertFromHTML({
      htmlToStyle: (nodeName, { style }, currentStyle) => {
        let newStyle = currentStyle;
        const availableStyles = [
          {
            key: "color",
            value: colorStyleMap,
          },
          {
            key: "fontSize",
            value: fontSizeStyleMap,
          },
        ];
        if (nodeName === "span") {
          availableStyles.forEach((s) => {
            const ss = style[s.key];
            if (ss) {
              const foundEntry = Object.entries(s?.value)?.find(
                ([, value]) => ss === value[s.key]
              );

              if (foundEntry) {
                const [styleColor] = foundEntry;
                if (styleColor) {
                  newStyle = newStyle.add(styleColor);
                }
              }
            }
          });
        }
        return newStyle;
      },
      htmlToEntity,
    })(html ?? ""),
    decorator
  );
  return raw;
};

export { RichTextEditor, ControlRichTextEditor, getAsHtml, getFromHtml };
