// COLORS
export const RED_COLOR = "#ee3131";
export const GREEN_COLOR = "#07980a";
export const BLUE_COLOR = "#00a2ea";
export const LIGHT_GREY = "#D3D3D3";

export const EMPTY_NAME_ERROR = "יש להזין שם";
export const EMPTY_VALUE_ERROR = "יש להזין ערך";
export const EMPTY_OVER_PERCENT_ERROR = "ערך באחזוים לא יכול להיות גדול מ100%";
export const EMPTY_OVER_VALUE_ERROR =
  "ערך בשקלים לא יכול להיות יותר גדול מ1000 ";

export const VAT_COEFFICIENT = 1.17;

export const REGEX_YOUTUBE_URL =
  "^(?:https?://)(?:www.)?(youtu.*be.*)/(watch?v=|embed/|v|shorts|)(.*?((?=[&#?])|$))";

// GENERAL - TABS
export const TAB_UNIFIED_PRODUCTS = 0; // מוצר על -> tab moved to ניהול מוצרים but still uses this page's redux store
export const TAB_COST_OF_SALE = 1; //  עמלת מכירה
export const TAB_PREPARATIONS = 2; // הכנות
export const TAB_DISHES = 3; // מנות (ברירת מחדל)

export const TAB_OPTIONS = [
  {
    id: TAB_COST_OF_SALE,
    label: "עמלת מכירה",
  },
  {
    id: TAB_PREPARATIONS,
    label: "הכנות",
  },
  {
    id: TAB_DISHES,
    label: "מנות",
  },
];

// PRODUCT FILTERS - STATUS
export const FILTERS_STATUS_OPTIONS = [
  {
    id: 1,
    label: "פעילים",
  },
  {
    id: 0,
    label: "לא פעילים",
  },
];

export const FILTERS_LINK_CODE_OPTIONS = [
  {
    id: 1,
    label: "מקושר לקופה",
  },
  {
    id: 0,
    label: "לא מקושר לקופה",
  },
];

export const FILTERS_DISH_INGREDIENT_OPTIONS = [
  {
    id: 1,
    label: "כולל",
  },
  {
    id: 0,
    label: "לא כולל",
  },
];

export const GROUP_CHANGE_STATUS = [
  { id: -1, name: "סטטוס" },
  { id: 1, name: "פעיל" },
  { id: 0, name: "לא פעיל" },
];

// PRODUCT FORM
export const SCALES = [
  'ק"ג',
  "גרם",
  "גלון",
  "יחידות",
  "ליטר",
  'מ"ל',
  'מ"מ',
  'מ"ר',
  "מטר",
  'ס"ל',
  'ס"מ',
  'סמ"ר',
  "קרטון",
  "שקית",
  "נספק",
  "סמ״ק",
  "אינץ׳",
  "cc",
];

// PRODUCT FORM
export const SALE_SCALE = [
  { id: 0, label: "מנה" },
  { id: 1, label: "ארוחה" },
  { id: 2, label: "יחידה" },
];

// FEES
export const FEES_TYPE = [
  { type: 1, label: "אחוז" },
  { type: 2, label: "ש״ח" },
];

export const FREE_SEARCH_TYPE_ITEM = 0;
export const FREE_SEARCH_TYPE_PREPARATION = 2;
export const FREE_SEARCH_TYPE_DISH = 3;
export const FREE_SEARCH_TYPE_UNIFIED = 4;
export const FREE_SEARCH_TYPE_TEMP = 5;

export const FREE_SEARCH_TYPE = [
  { id: FREE_SEARCH_TYPE_ITEM, label: "חומר גלם" },
  { id: FREE_SEARCH_TYPE_PREPARATION, label: "הכנה" },
  { id: FREE_SEARCH_TYPE_DISH, label: "מנה" },
  { id: FREE_SEARCH_TYPE_TEMP, label: "מוצר זמני" },
];

export const INGREDIENT_SCALE_ML = 'מ"ל';
export const INGREDIENT_SCALE_LT = "ליטר";

export const INGREDIENT_SCALE_GR = "גרם";
export const INGREDIENT_SCALE_KG = 'ק"ג';

export const INGREDIENT_LITER_OPTIONS = [
  INGREDIENT_SCALE_ML,
  INGREDIENT_SCALE_LT,
];
export const INGREDIENT_KILO_OPTIONS = [
  INGREDIENT_SCALE_GR,
  INGREDIENT_SCALE_KG,
];

export const ITEM_TYPE_RAW = 0;
export const ITEM_TYPE_PREPARATIONS = 1;
export const ITEM_TYPE_BASIC = 2;
export const ITEM_TYPE_UNIFIED_PRODUCT = 3;

export const PRODUCT_ITEM_TYPE = {
  [ITEM_TYPE_RAW]: "חומר גלם",
  [ITEM_TYPE_PREPARATIONS]: "הכנה",
  [ITEM_TYPE_BASIC]: "בסיס",
  [ITEM_TYPE_UNIFIED_PRODUCT]: "מוצר על",
};

export const PRODUCT_INGREDIENTS_EDIT = {
  ["lost"]: "פחת",
  ["quantity"]: "כמות",
  ["price"]: "עלות ללא מע״מ",
  ["price_VAT"]: "עלות כולל מע״מ",
};

// UNIFIED PRODUCTS

export const BOX_TYPES = [
  "מארז",
  "קרטון",
  "שקית",
  "תבנית",
  "שרוול",
  "קופסא",
  "דלי",
  "צנצנת",
  "שק",
  "כד",
  "חריץ",
  "גליל",
  "גביע",
  "פח",
  "ואקום",
  "חפיסה",
  "חבילה",
  "ארגז",
  "בקבוק",
  "מגש",
  "ג'ריקן",
  "חבית",
];

export const BOX_SCALES = [
  "יחידות",
  "בקבוקים",
  "בלוקים",
  "שקיות",
  "קופסאות",
  "צנצנות",
  "שרוולים",
  "פחיות",
  "חבילות",
];

export const COUNT_TYPE_NONE = 0;
export const COUNT_TYPE_ITEM = 1;
export const COUNT_TYPE_WEIGHT = 2;
export const COUNT_TYPE_BOX_TYPE = 3;
export const COUNT_TYPE_BOX_SCALE = 4;
export const COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE = 5;
export const COUNT_TYPE_NONE_INVENTORY_IS_MANAGED = 6;
export const COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE_AND_WEIGHT = 7;

export const COUNT_TYPES_HEBREW = {
  [COUNT_TYPE_NONE]: "לא נספר במלאי",
  [COUNT_TYPE_ITEM]: "מוצר",
  [COUNT_TYPE_WEIGHT]: "שקיל - יחידת מידה",
  [COUNT_TYPE_BOX_TYPE]: "צורת מארז",
  [COUNT_TYPE_BOX_SCALE]: "יחידת מידה במארז",
  [COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE]: "מארז + יחידת מידה במארז",
  [COUNT_TYPE_NONE_INVENTORY_IS_MANAGED]: "לא מנוהל מלאי",
  [COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE_AND_WEIGHT]:
    "מארז + יחידת מידה במארז + שקיל",
};

export const EXPENSES_TYPE = [
  { id: 1, label: "Food Cost" },
  { id: 2, label: "הוצאות תפעול" },
  { id: 3, label: "הוצאות הנהלה וכלליות" },
];

// CALCULATE WORTH FOR PRODUCT

/* 
      INGREDIENT DYNAMIC WORTH CALCULATION

      New product/item ingredient worth recalculate DYNAMICALLY.
      each time the user chage the: quantity, lost, scaleType, input.

      The worth calculation different for product and item.

      Unit Cost for product CAN be the same for VAT and without VAT.
      Worth for item CAN be the same if the item have no tax.
*/

export const calculateIngredientWorth = (
  ingredient,
  quantity,
  lost,
  scaleType
) => {
  const { unit_cost_with_VAT, unit_cost_without_VAT, scale } = ingredient;

  const worth_with_VAT = quantity * (1 + lost / 100) * unit_cost_with_VAT;

  const worth_without_VAT = quantity * (1 + lost / 100) * unit_cost_without_VAT;

  const inverted_worth_with_VAT = scaleWorthInverter(
    worth_with_VAT,
    scale,
    scaleType
  );

  const inverted_worth_without_VAT = scaleWorthInverter(
    worth_without_VAT,
    scale,
    scaleType
  );

  return {
    worth_with_VAT: inverted_worth_with_VAT,
    worth_without_VAT: inverted_worth_without_VAT,
  };
};

// CALCULATE UNIT COST FOR ITEM
export const unitCost = ({
  by_scale,
  price,
  package_items,
  discount,
  inventory,
}) => {
  if (!discount) discount = 0;

  return by_scale
    ? (price * (1 - discount / 100)) / (package_items * inventory)
    : (price * (1 - discount / 100)) / inventory;
};

export const WorthSum = (props, showTax = false) => {
  // EDIT MODE
  const {
    rows,
    editId,
    ingredientEdit: {
      quantity: editModeQuantity,
      lost: editModeLost,
      scaleType: editModeScale,
    },
  } = props;
  return rows.reduce(
    (previousIngredientWorth, { original: currentIngredient }) => {
      // REAL MODE
      const { ingredientable_id, type_id, quantity, lost, scaleType } =
        currentIngredient;

      const isInEditMode = `${type_id}.${ingredientable_id}` === editId;

      const { worth_without_VAT, worth_with_VAT } = calculateIngredientWorth(
        currentIngredient,
        isInEditMode ? editModeQuantity : quantity,
        isInEditMode ? editModeLost : lost,
        isInEditMode ? editModeScale : scaleType
      );

      const currentWorth = showTax ? worth_with_VAT : worth_without_VAT;

      return previousIngredientWorth + currentWorth;
    },
    0
  );
};

export const ingredientUnifiedWorthInverter = (
  props,
  ingredient,
  showTax = false
) => {
  const {
    editId,
    ingredientEdit: {
      quantity: editModeQuantity,
      lost: editModeLost,
      scaleType: editModeScale,
    },
  } = props;

  let {
    id,
    scale, // original scale from DB
    lost,
    scaleType,
    unit_cost,
    worth_without_vat,
    worth_with_vat,
  } = ingredient;

  const isInEditMode = id === editId;

  let worth = showTax ? worth_with_vat : worth_without_vat;

  const taxRatio =
    worth_without_vat !== 0
      ? parseFloat((worth_with_vat / worth_without_vat).toFixed(2))
      : 0;

  if (isInEditMode) {
    lost = editModeLost;
    scaleType = editModeScale;
    worth = (1 + editModeLost / 100) * unit_cost * editModeQuantity;
    // if (type_id === FREE_SEARCH_TYPE_DISH) worth *= quantity;
    if (showTax) worth *= taxRatio;
  }

  worth = scaleWorthInverter(worth, scale, scaleType);

  return worth;
};

export const scaleWorthInverter = (worth, scale, scaleType) => {
  const isNewScale = scale !== scaleType;

  const isSmallScale =
    scaleType === INGREDIENT_SCALE_ML || scaleType === INGREDIENT_SCALE_GR;
  const isLargeScale =
    scaleType === INGREDIENT_SCALE_LT || scaleType === INGREDIENT_SCALE_KG;

  if (isNewScale && isSmallScale) worth /= 1000;
  if (isNewScale && isLargeScale) worth *= 1000;

  return +worth.toFixed(2);
};

export const htmlSpecialCharacters = (string) =>
  `${string}`
    .replace("&amp;", "")
    .replace("&quot;", "''")
    .replace("quot;", "''")
    .replace(/&nbsp;/g, " ")
    .replace("&quot;", "''")
    .replace(/&#039;/g, "'");

export const MAX_INGREDIENT_AMOUNT_LENGTH = 6;
