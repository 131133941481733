import { useMemo } from "react";
import Button from "../../components/buttons/Button";
import { fixPercentage, fixPrice } from "../../helpers";
import { sumFooter } from "../../components/tables/helpers";
import { RiDownloadCloud2Line } from "react-icons/ri";
import ButtonIcon from "../../components/buttons/ButtonIcon";
import { PriceCell } from "../../components/tables/components/PriceFooter";
import { Link, Tooltip } from "@mui/material";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiDownloadCloud } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { COLOR_ABORT, COLOR_RESTIGO_PRIMARY } from "../../const/colors";

export const BASE_URL = "purchaseSummary";
export const GET_SUPPLIER_ORDER_SUMMARY = "getSupplierOrderSummary";
export const GET_SUPPLIER_ITEM_ORDERS_SUMMARY = "getSupplierItemOrdersSummary";
export const GET_SUPPLIER_GOALS = "getSupplierGoals";
export const UPDATE_SUPPLIER_GOAL = "updateOrCreateSupplierGoal";
export const DELETE_SUPPLIER_GOAL = "deleteSupplierGoal";
export const UPLOAD_SUPPLIER_FILES = "uploadSupplierFiles";
export const GET_SUPPLIER_FILES = "getSupplierFiles";
export const DELETE_SUPPLIER_FILES = "deleteSupplierFiles";
export const DOWNLOAD_ZIP_FILE = "downloadZipFile";

export const CASE_SENT = 0;
export const CASE_RECEIVED = 1;

export const purchaseSummaryTablesOptions = [
  {
    text: "הזמנות שנשלחו",
  },
  { text: "הזמנות שנקלטו" },
];

export const purchaseHiddenColumns = [];

export const ordersBySupplierColumns = (table, tab, foodCost) => {
  return [
    ...(table === "by_supp"
      ? [
          {
            Header: "שם מוצר",
            accessor: "item_name",
            Cell: ({ value, row, openSupplierItemOrders }) => {
              const { item_id } = row.original;

              const splitString = value?.split(".");
              let newString = "";

              splitString?.forEach((string) => (newString += string));

              const toolTipRows = [
                `מחיר: ${fixPrice(row.original.catalog_price)}`,
                `מקט: ${row.original.part_number}`,
                `צורת מארז: ${row.original.box_type}`,
              ];
              return (
                <Tooltip
                  title={
                    <div
                      className="flex-column-right"
                      style={{ fontSize: "1.5rem" }}
                    >
                      {toolTipRows.map((row, key) => (
                        <span {...{ key }}>{row}</span>
                      ))}
                    </div>
                  }
                  placement="top-start"
                >
                  <Link
                    component="button"
                    sx={{
                      fontSize: "1.6rem",
                    }}
                    onClick={() => openSupplierItemOrders(item_id)}
                  >
                    {newString}
                  </Link>
                </Tooltip>
              );
            },
            Footer: () => "סה״כ",
          },
        ]
      : [
          {
            Header: "שם ספק",
            accessor: "supplier_name",
            Cell: ({ value, row, openCategoryItemsTable }) => {
              const { supplier_id } = row.original;

              const splitString = value?.split(".");
              let newString = "";

              splitString?.forEach((string) => (newString += string));
              return table === "by_supp" ? (
                value
              ) : (
                <Button
                  className="btn--hyperlink"
                  style={{ fontSize: "1.5rem" }}
                  onClick={() => openCategoryItemsTable(supplier_id)}
                  textValue={newString}
                />
              );
            },
            Footer: () => "סה״כ",
          },
        ]),
    ...(table === "by_supp"
      ? [{ Header: "מקט", accessor: "part_number" }]
      : []),

    {
      Header: "סה״כ הזמנות שנשלחו",
      accessor: "total_sent_without_vat",
      Cell: ({ value, row }) => {
        return <span>{fixPrice(value)}</span>;
      },
      Footer: (self) => {
        const total = useMemo(
          () => sumFooter(self.rows, "total_sent_without_vat"),
          [self.rows]
        );

        return <div className="money">{fixPrice(total)}</div>;
      },
    },
    {
      Header: "סה״כ הזמנות שנקלטו",
      accessor: "total_received_without_vat",
      Cell: ({ value, row }) => {
        return <span>{fixPrice(value)}</span>;
      },
      Footer: (self) => {
        const total = useMemo(
          () => sumFooter(self.rows, "total_received_without_vat"),
          [self.rows]
        );

        return <div className="money">{fixPrice(total)}</div>;
      },
    },

    {
      Header: "ע.רשת נשלחו",
      accessor: "markup_sent",
      Cell: ({ value, row }) => {
        return <span>{value <= 0 ? "" : fixPrice(value)}</span>;
      },
      Footer: (self) => {
        const total = useMemo(
          () => sumFooter(self.rows, "markup_sent"),
          [self.rows]
        );

        return <div className="money">{fixPrice(total)}</div>;
      },
    },
    {
      Header: "ע.רשת נקלטו",
      accessor: "markup_received",
      Cell: ({ value, row }) => {
        return <span>{value <= 0 ? "" : fixPrice(value)}</span>;
      },
      Footer: (self) => {
        const total = useMemo(
          () => sumFooter(self.rows, "markup_received"),
          [self.rows]
        );

        return <div className="money">{fixPrice(total)}</div>;
      },
    },

    ...(table === "by_supp"
      ? [
          {
            Header: "% מרכש",
            accessor: "purchase_precent",
            Cell: ({ value, row }) => {
              return <span>{fixPercentage(value <= 0 ? 0 : value, 1)}</span>;
            },
            Footer: ({ data, rows }) => {
              const total_fees = sumFooter(rows, "purchase_precent");
              return total_fees > 0
                ? fixPercentage((total_fees / foodCost) * 100, 1)
                : 0;
            },
          },
        ]
      : [
          {
            Header: "% הכנסה נשלחו",
            accessor: "income_precent_sent",
            Cell: ({ value, row }) => {
              return <span>{fixPercentage(value, 1)}</span>;
            },
            Footer: ({ data, rows, current_incomes }) => {
              const total_fees = sumFooter(rows, "total_sent_without_vat");
              return total_fees > 0
                ? fixPercentage((total_fees / current_incomes) * 100, 1)
                : 0;
            },
          },
          {
            Header: "% הכנסה נקלטו",
            accessor: "income_precent_received",
            Cell: ({ value, row }) => {
              return <span>{fixPercentage(value, 1)}</span>;
            },
            Footer: ({ data, rows, current_incomes }) => {
              const total_fees = sumFooter(rows, "total_received_without_vat");
              return total_fees > 0
                ? fixPercentage((total_fees / current_incomes) * 100, 1)
                : 0;
            },
          },
          {
            Header: "מרחק מיעד",
            accessor: "goal_gap",
            Cell: ({ row }) => {
              const goal = row.original.goal;
              const goalGap = goal - row.original.total_sent_without_vat;

              return !goal ? (
                ""
              ) : (
                <span
                  style={{
                    color:
                      goalGap > row.original.total_sent_without_vat
                        ? "red"
                        : "green",
                  }}
                >
                  {fixPrice(Math.abs(goalGap))}
                </span>
              );
            },
          },
          {
            Header: "יעד",
            accessor: "goal",
            Cell: ({ value, row }) => {
              if (value === 0) {
                return;
              }
              return <span>{`${fixPrice(value)}`}</span>;
            },
          },
        ]),
    ...(table !== "by_supp"
      ? [
          {
            Header: "פעולות נוספות",
            accessor: "actions",
            Cell: ({ row, setSupplierFiles, getSupplierFiles }) => {
              return (
                <>
                  <ButtonIcon
                    icon={AiOutlineFileSearch}
                    style={{ backgroundColor: "transparent" }}
                    onClick={() => {
                      setSupplierFiles(
                        row.original.supplier_id,
                        row.original.supplier_name
                      );
                      getSupplierFiles(row.original.supplier_id);
                    }}
                    className="btn--normal"
                    alt="קבצים משוייכים "
                  />
                </>
              );
            },
          },
        ]
      : []),
  ];
};

export const itemOrdersBySupplierColumns = (tab, foodCost) => {
  return [
    {
      Header: "שם מוצר",
      accessor: "item_name",
      Footer: () => "סה״כ",
    },
    { Header: "שם ספק", accessor: "supplier_name" },
    { Header: "שם סניף", accessor: "branch_name" },
    {
      Header: tab === CASE_SENT ? "מספר הזמנה" : "מספר מסמך",
      accessor: "file_number",
      Cell: ({ value, row, setViewToShow }) => {
        const { new_order_id, new_received_order_id } = row.original;

        return (
          <Button
            className="btn--hyperlink"
            style={{ fontSize: "1.5rem" }}
            onClick={() =>
              setViewToShow(
                tab === CASE_SENT ? { new_order_id } : { new_received_order_id }
              )
            }
            textValue={tab === CASE_SENT ? new_order_id : value}
          />
        );
      },
    },
    ...(tab === CASE_RECEIVED
      ? [
          {
            Header: "כמות מוצרים בקליטה",
            accessor: "received_amount",
          },
          {
            Header: "כמות מוצרים בהזמנה",
            accessor: "ordered_amount",
          },
        ]
      : [
          {
            Header: "כמות מוצרים בהזמנה",
            accessor: "item_amount",
          },
        ]),
    {
      Header: "מחיר בהזמנה",
      accessor: "ordered_price",
    },
    ...(tab === CASE_RECEIVED
      ? [
          {
            Header: " מחיר בקליטה",
            accessor: "received_price",
          },
          {
            Header: "הפרש",
            accessor: "prices_gap",
            Cell: PriceCell,
          },
        ]
      : []),

    {
      Header: "סה״כ ללא מע״מ",
      accessor:
        tab === CASE_SENT
          ? "total_sent_without_vat"
          : "total_received_without_vat",
      Cell: ({ value, row }) => {
        return <span>{fixPrice(value)}</span>;
      },
      Footer: (self) => {
        const total = useMemo(
          () =>
            sumFooter(
              self.rows,
              tab === CASE_SENT
                ? "total_sent_without_vat"
                : "total_received_without_vat"
            ),
          [self.rows]
        );

        return <div className="money">{fixPrice(total)}</div>;
      },
    },
    {
      Header: "סה״כ כולל מע״מ",
      accessor:
        tab === CASE_SENT ? "total_sent_with_vat" : "total_received_with_vat",
      Cell: ({ value, row }) => {
        return <span>{fixPrice(value)}</span>;
      },
      Footer: (self) => {
        const total = useMemo(
          () =>
            sumFooter(
              self.rows,
              tab === CASE_SENT
                ? "total_sent_with_vat"
                : "total_received_with_vat"
            ),
          [self.rows]
        );

        return <div className="money">{fixPrice(total)}</div>;
      },
    },

    {
      Header: "ע.רשת ללא מע״מ",
      accessor: tab === CASE_SENT ? "markup_sent" : "markup_received",
      Cell: ({ value, row }) => {
        return <span>{value <= 0 ? "" : fixPrice(value)}</span>;
      },
      Footer: (self) => {
        const total = useMemo(
          () =>
            sumFooter(
              self.rows,
              tab === CASE_SENT ? "markup_sent" : "markup_received"
            ),
          [self.rows]
        );

        return <div className="money">{fixPrice(total)}</div>;
      },
    },

    {
      Header: "% מרכש",
      accessor:
        tab === CASE_SENT
          ? "purchase_precent_sent"
          : "purchase_precent_received",
      Cell: ({ value, row }) => {
        return <span>{fixPercentage(value, 1)}</span>;
      },
      Footer: ({ data, rows }) => {
        const total_fees = sumFooter(
          rows,
          tab === CASE_SENT
            ? "purchase_precent_sent"
            : "purchase_precent_received"
        );
        return total_fees > 0
          ? fixPercentage((total_fees / foodCost) * 100, 1)
          : 0;
      },
    },
  ];
};

export const supplierFilesColumns = () => {
  return [
    {
      Header: "שם הקובץ",
      accessor: "real_name",
    },
    { Header: "תאריך העלאה", accessor: "created_at" },
    {
      Header: "פעולות נוספות",
      accessor: "actions",
      Cell: ({ row, deleteSupplierFiles }) => {
        return (
          <div style={{ display: "flex" }}>
            {/* <ButtonIcon
              icon={RiDownloadCloud2Line}
              // icon={FiDownloadCloud}
              onClick={() => {
                const link = document.createElement("a");
                link.href = row.original.download_link;
                link.click();
              }}
              style={{
                backgroundColor: "transparent",
                color: COLOR_RESTIGO_PRIMARY,
              }}
              className="btn--normal"
              alt="הורד קובץ"
            /> */}
            <ButtonIcon
              icon={MdDelete}
              style={{
                backgroundColor: "transparent",
                color: COLOR_ABORT,
              }}
              onClick={() => deleteSupplierFiles(row.original.id)}
              className="btn--normal"
              alt="מחק קובץ"
            />
          </div>
        );
      },
    },
  ];
};
