import { CircularProgress } from "@mui/material";
import React from "react";

function Loader(props) {
  return (
    <CircularProgress
      size={"10rem"}
      color="primary"
      sx={{ position: "absolute", top: "25%", left: "50%" }}
    />
  );
}

export default Loader;
