import {
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Axios from "axios";
import { default as Grid } from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  FaCheck,
  FaPencilAlt,
  FaSatelliteDish,
  FaSave,
  FaTimes,
} from "react-icons/fa";
import Popover from "react-popover";
import { connect } from "react-redux";
import SwitchInput from "../../../../../components/inputs/SwitchInput";
import { COLOR_ABORT, COLOR_CONFIRM } from "../../../../../const/colors";
import { fixDateHours } from "../../../../../helpers";
import { useScreenSize } from "../../../../../Hooks";
import { setConfirmBar } from "../../../../../redux/actions/confirmAction";
import { setError } from "../../../../../redux/actions/errorAction";

const EditMakorClientModel = ({
  makorClient,
  updateDataAfterServerCall,
  setError,
  token,
  adminId,
  setConfirmBar,
}) => {
  const { id, ip, port, by_range, branch_name, updater_name } = makorClient;
  const { isMobile } = useScreenSize()
  // ============= state ===========
  const [data, setData] = useState({
    ip,
    port,
    by_range,
  });

  const [open, setopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkingPort, setCheckingPort] = useState(false);
  const [isPortOpen, setIsPortOpen] = useState(null);



  // =============== handlers & functions ==============

  const handleChange = (key, value) => {
    switch (key) {
      default:
        setData({ ...data, [key]: value });
    }
  };

  const handleClose = () => {
    setopen(false);
    // update state on close (delete changes)
    setData({
      ip,
      port,
      by_range,
    });
  };
  
  //================= server calls ===================
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await Axios.put(
        `admin/attendanceClocks/makor/${id}`,
        { ...data, updated_by: adminId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const makorClient = response.data;
      setopen(false);
      updateDataAfterServerCall(makorClient);
      setConfirmBar("הגדרות מקור עודכנו בהצלחה");
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message, "ארעה שגיאה בשרת");
    }
    setLoading(false);
  };


  const checkPort = async () => {
    setCheckingPort(true);
    try {
      const response = await Axios.get("admin/attendanceClocks/checkPort", {
        params: {
          ip: data.ip,
          port: data.port,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      setIsPortOpen(response.data.isOpen);
    } catch (err) {
      console.error(err);
    }
    setCheckingPort(false);
  };

  //================= effects =======================

  useEffect(() => {
    //update state on data change (from outside)
    setData({
      ip,
      port,
      by_range,
    });
  }, [ip, port, by_range]);

  useEffect(() => {
    if (isPortOpen !== null) {
      setTimeout(() => setIsPortOpen(null), 2000);
    }
  }, [isPortOpen]);
  return (
    <Popover
      place="right"
      isOpen={open}
      onOuterAction={() => handleClose()}
      style={{ zIndex: 4 }}
      body={
        <Card className="padding-05" style={{ maxWidth: "30rem" }}>
          <CardHeader
            className="padding-05 padding-bottom-0"
            title={`עריכת נתוני שעון - ${branch_name}`}
            action={
              <IconButton onClick={() => handleClose()}>
                <AiOutlineClose />
              </IconButton>
            }
          />
          <Grid container className="padding-05" spacing={2}>
            <Grid xs={12}>
              <TextField
                size="small"
                label="IP"
                onChange={(e) => handleChange("ip", e.target.value)}
                value={data.ip}
              />
            </Grid>
            <Grid xs={10}>
              <TextField
                fullWidth
                size="small"
                label="port"
                onChange={(e) => handleChange("port", e.target.value)}
                value={data.port}
              />
            </Grid>
            <Grid xs={2}>
              <div className="flex-end">
              <Tooltip
                arrow
                placement="right"
                title={
                  <Typography align="left" style={{ color: "white" }}>
                    בדיקת תקשורת
                  </Typography>
                }
              >
                <IconButton onClick={checkPort}>
                  {checkingPort ? (
                    <CircularProgress size="2rem" color="inherit" />
                  ) : isPortOpen === null ? (
                    <FaSatelliteDish />
                  ) : isPortOpen ? (
                    <FaCheck color={COLOR_CONFIRM} />
                  ) : (
                    <FaTimes color={COLOR_ABORT} />
                  )}
                </IconButton>
              </Tooltip>
              </div>
            </Grid>
            <Grid xs={12}>
              <div className="flex">
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    <Typography align="left" style={{ color: "white" }}>
                      לבדיקה אם השעון יכול לטעון לפי טווח תאריכים יש לבדוק עם
                      המתכנתים
                    </Typography>
                  }
                >
                  <i
                    style={{ fontSize: "1.6rem" }}
                    className="fas fa-info-circle"
                  ></i>
                </Tooltip>
                <SwitchInput
                  className="flex-between"
                  checked_default={!!data.by_range}
                  onChange={(enable) => {
                    handleChange("by_range", enable);
                  }}
                  label="טעינה לפי טווח תאריכים"
                  style={{ marginBottom: "0.5rem" }}
                  disabled={loading}
                />
              </div>
            </Grid>
          </Grid>
          <hr />
          <div className="flex-between">
            <div>
              <p>{updater_name ? `עודכן לאחרונה ע״י ${updater_name}` : null}</p>
            </div>
            <IconButton color="primary" onClick={handleSubmit}>
              {loading ? (
                <CircularProgress size="2rem" color="inherit" />
              ) : (
                <FaSave />
              )}
            </IconButton>
          </div>
        </Card>
      }
    >
      <IconButton onClick={() => setopen(!open)}>{<FaPencilAlt />}</IconButton>
    </Popover>
  );
};
const mapStateToProps = (state) => {
  const { token, admin } = state.authAdmin;
  const { isMobile } = state.publicValues;
  return { token, isMobile, adminId: admin.id };
};
export default connect(mapStateToProps, { setError,setConfirmBar })(EditMakorClientModel);
