import { Skeleton } from "@mui/material";
import React from "react";

const MuiSkeletonTable = ({ size = 20 }) =>
  Array(size)
    .fill()
    .map((item, index) => (
      <Skeleton
        key={index}
        variant="rect"
        height={45}
        style={{ marginTop: "0.5rem" }}
      />
    ));

export default MuiSkeletonTable;
