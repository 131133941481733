import React from "react";
import { I18n } from "react-redux-i18n";
import SingleExcelFile from "../../../../components/excel/SingleExcelFile";
import MultiExcelFile from "../../../../components/excel/MultiExcelFile";
import { fixDate } from "../../../../helpers";

const ExcelExportFileButtons = (props) => {
  const {
    type,
    sortedData,
    showAllBranches,
    selected_branch,
    date,
    isMobile,
    columnsToExcel,
    checkedColumns,
    hiddenColumns,
    aggregates,
  } = props;
  return (
    <>
      {type === "transfers" ? (
        <SingleExcelFile
          multi={true}
          data={[].concat.apply(
            [],
            sortedData.map((item) => item.body)
          )}
          fileName={`${I18n.t(`pageNav.${type}`)} ${
            showAllBranches ? "כולל" : selected_branch.name.replaceAll(".", "")
          } ${fixDate(date.start)} - ${fixDate(date.end)}`}
          style={isMobile ? { width: "40%" } : {}}
          columns={
            columnsToExcel
              ? columnsToExcel
                  .map((column) => {
                    if (
                      checkedColumns.includes(column.accessor) ||
                      !hiddenColumns.includes(column.accessor) ||
                      column.accessor === "employee_name"
                    ) {
                      return column;
                    }
                    return null;
                  })
                  .filter((el) => el)
              : []
          }
          aggregates={aggregates}
        />
      ) : (
        <MultiExcelFile
          buttonStyle={
            isMobile
              ? {
                  width: "100%",
                  paddingLeft: 0,
                  paddingRight: 0,
                  margin: 0,
                }
              : {}
          }
          style={isMobile ? { width: "80%" } : {}}
          columns={
            columnsToExcel
              ? columnsToExcel
                  .map((column) => {
                    if (
                      checkedColumns.includes(column.accessor) ||
                      !hiddenColumns.includes(column.accessor) ||
                      column.accessor === "employee_name"
                    ) {
                      return column;
                    }
                    return null;
                  })
                  .filter((el) => el)
              : []
          }
          aggregates={aggregates}
          data={sortedData}
          fileName={`${I18n.t(
            `pageNav.${type}`
          )} ${selected_branch.name.replaceAll(".", "")} ${fixDate(
            date.start
          )} - ${fixDate(date.end)}`}
        />
      )}
    </>
  );
};

export default ExcelExportFileButtons;
