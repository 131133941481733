import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import SelectComponent from "../../../components/Forms/inputs/Select";
import FormCheckBox from "../../../components/Forms/inputs/FormCheckBox";
import moment from "moment";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";

const PART_EMPLOYEE_LIST = 0;
const PART_TYPE_IDENTIFER = 1;
const PART_TYPE_EMPLOYEE_TYPE_TITLE = 2;
const PART_DATE = 3;
const PART_OTHER_PARTICIPANTS = 4;
const PART_SUBJECT = 5;
const PART_MAIN_CONTENT = 6;
const PART_SALARY_FIELD = 7;
const PART_NEW_SALARY_FIELD = 8;
const PART_UPDATE_DATE = 9;
const INPUT = "שדה";
const PART_BONUS_SALARY_FIELD = 10;
const PART_WORKER_QUESTION = 11;
const PART_BRANCH_LIST = 12;

const REQUIRED_CHECKBOX = {
  name: "required",
  component: FormCheckBox,
  label: "שדה חובה",
  defaultValue: true,
};

const INPUT_LABEL_FIELD = (defaultValue = "") => ({
  name: "label",
  component: FormTextField,
  label: "כותרת השדה",
  defaultValue,
  rules: { required: REQUIRED_MESSAGE },
});

const PARTS = {
  [PART_EMPLOYEE_LIST]: {
    label: "שם העובד",
    defaultValue: () => "שם העובד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם העובד"),
      {
        name: "value",
        component: FormTextField,
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: ControlAutoComplete,
      size: "small",
      inputProps: { label: "שם העובד" },
    },
  },
  [PART_BRANCH_LIST]: {
    label: "שם סניף",
    defaultValue: () => "שם סניף",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם סניף"),
      {
        name: "value",
        component: FormTextField,
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: ControlAutoComplete,
      size: "small",
      inputProps: { label: "שם סניף" },
    },
  },
  [PART_TYPE_EMPLOYEE_TYPE_TITLE]: {
    label: "תפקיד",
    defaultValue: () => "תפקיד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תפקיד"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "תפקיד",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      disabled: true,
    },
  },
  [PART_TYPE_IDENTIFER]: {
    label: "ת.ז.",
    defaultValue: () => "ת.ז.",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("ת.ז."),
      {
        name: "value",
        component: FormTextField,
        placeholder: "ת.ז.",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,

      rules: {
        pattern: {
          value: new RegExp(/^\d{0,9}$/),
          message: "מספר ת.ז. לא תקין יש לנסות שוב",
        },
      },
    },
  },
  [PART_NEW_SALARY_FIELD]: {
    label: "שכר נוכחי",
    defaultValue: () => "שכר נוכחי",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שכר נוכחי"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "שכר נוכחי",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,
    },
  },
  [PART_SALARY_FIELD]: {
    label: "שכר מבוקש",
    defaultValue: () => "שכר מבוקש",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שכר מבוקש"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "שכר מבוקש",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,
    },
  },
  [PART_OTHER_PARTICIPANTS]: {
    label: "נוכחים נוספים",
    defaultValue: () => "נוכחים נוספים",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("נוכחים נוספים"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "נוכחים נוספים",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_DATE]: {
    label: "תאריך",
    defaultValue: () => "תאריך",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        placeholder: "תאריך",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_SUBJECT]: {
    label: "נושא",
    defaultValue: () => "נושא",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("נושא"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "נושא",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_UPDATE_DATE]: {
    label: "תאריך עדכון",
    defaultValue: () => "תאריך עדכון",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך עדכון"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        placeholder: "תאריך עדכון",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_MAIN_CONTENT]: {
    label: "פירוט השיחה",
    defaultValue: () => "פירוט השיחה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("פירוט השיחה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "פירוט השיחה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_BONUS_SALARY_FIELD]: {
    label: "בונוס חד פעמי",
    defaultValue: () => "בונוס חד פעמי",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("בונוס חד פעמי"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "בונוס חד פעמי",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,
    },
  },
  [PART_WORKER_QUESTION]: {
    label: "שאלת עובד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שאלת עובד"),
      {
        name: "value",
        component: FormTextField,
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: SelectComponent,
      size: "small",
      options: ["עדכון שכר", "בונוס חד פעמי"],
    },
  },
};

export {
  PARTS,
  PART_EMPLOYEE_LIST,
  INPUT,
  PART_TYPE_EMPLOYEE_TYPE_TITLE,
  PART_TYPE_IDENTIFER,
  PART_DATE,
  PART_OTHER_PARTICIPANTS,
  PART_SUBJECT,
  PART_MAIN_CONTENT,
  PART_SALARY_FIELD,
  PART_NEW_SALARY_FIELD,
  PART_UPDATE_DATE,
  PART_BONUS_SALARY_FIELD,
  PART_WORKER_QUESTION,
  PART_BRANCH_LIST,
};
