//Fetch

export const GET_ALL_LOGS = "GET_ALL_LOGS";
export const GET_ADMINS_LIST = "GET_ADMINS_LIST";
export const GET_ALL_NETWORKS = "GET_ALL_NETWORKS";
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
//SET

export const SET_USER_LOGS_PAGINATION = "SET_USER_LOGS_PAGINATION";
export const SET_USER_LOGS_FILTERS = "SET_USER_LOGS_FILTERS";
