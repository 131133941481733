import Axios from "axios";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";

import ReactTable from "../../../../components/tables/ReactTable";
import { useScreenSize } from "../../../../Hooks";
import { EDIT_SCHEDULE_PRIVILEGE } from "../../../../privilegesIndexes";
import { setConfirm } from "../../../../redux/actions/confirmAction";
import { setError } from "../../../../redux/actions/errorAction";
import { createFormJson } from "../../../../redux/actions/helpers";
import { logout } from "../../../../redux/actions/loginAction";
import { addHoursToEvent } from "../../../../redux/actions/scheduleAction";
import {
  error_employees_columns,
  lock_day_error_employees_columns,
} from "./columns";

const ErrorEmployeesTable = ({
  errorEmployees,
  setErrorEmployees,
  canEditSchedule,
  addHoursToEvent,
  type = "tips",
  tableTiltle = "לא ניתן לחשב טיפ, קיימים עובדים ללא שעת כניסה או יציאה",
}) => {
  const { isMobile } = useScreenSize();
  const [currentLoadingEventId, setCurrentLoadingEventId] = useState(null);
  const saveManualHours = async (shiftEventId, start, end) => {
    console.log(shiftEventId, start, end);
    setCurrentLoadingEventId(shiftEventId);
    const saved = await addHoursToEvent({ event_id: shiftEventId, start, end });
    if (saved) {
      const employees = { ...errorEmployees };
      employees[shiftEventId].start = start;
      employees[shiftEventId].end = end;
      employees[shiftEventId].addressed = true;
      setErrorEmployees(employees);
    }
    setCurrentLoadingEventId(null);
  };

  const updateHours = (shiftEventId, start, end) => {
    const employees = { ...errorEmployees };
    employees[shiftEventId].start = start;
    employees[shiftEventId].end = end;
    setErrorEmployees(employees);
  };

  const columns = useMemo(
    () =>
      type === "tips"
        ? error_employees_columns(canEditSchedule, currentLoadingEventId)
        : lock_day_error_employees_columns(isMobile),
    [canEditSchedule, currentLoadingEventId, type, isMobile]
  );

  return (
    <ReactTable
      showSearch={false}
      data={Object.values(errorEmployees)}
      columns={columns}
      cellsPropsById={() => {
        return { saveManualHours, currentLoadingEventId, updateHours };
      }}
      title={tableTiltle}
    />
  );
};

const mapStateToProps = (state) => {
  const { privileges_buttons } = state.user_privileges;
  const { token } = state.auth;

  const canEditSchedule = privileges_buttons.includes(EDIT_SCHEDULE_PRIVILEGE);

  return {
    token,
    canEditSchedule,
  };
};
export default connect(mapStateToProps, {
  addHoursToEvent,
})(ErrorEmployeesTable);
