import React, { useMemo } from "react";
import { aggregateBy, process } from "@progress/kendo-data-query";
import DownloadExcel from "./DownloadExcel";
import { getSafe } from "../../../helpers";
import { isFunction } from "lodash";
import DownloadCsv from "./DownloadCsv";

// ======== ICONS ========
import { RiFileExcel2Line } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";

const SingleExcelFile = ({
  fileName,
  aggregates = [],
  data,
  columns,
  cellsConfig = null, // use to config specific cell value - object with function: {name: (data,row) => decode(data)}
  rowConfig = null, // use to config all row - function: (row) => foo.
  colConfig = null, // use to modify the default column array, fn(cols):newCols
  translation_endpoint = "",
  is_sub = false,
  footerConfig,
  customFooterTitle,
  customBtnToolTipText = "",
}) => {
  const group = useMemo(() => [{ field: "file_title" }], []);

  const configCellsValues = (data) => {
    let rows = getSafe(() => data);
    if (isFunction(rowConfig)) {
      rows.forEach((row, idx) => {
        row = rowConfig(row);
        rows[idx] = row;
      });
      data = rows;
    }

    if (cellsConfig != null) {
      let rows = getSafe(() => data);
      const config = Object.entries(cellsConfig);
      if (rows) {
        rows.forEach((row) => {
          config.forEach(([key, func]) => {
            if (key in row) {
              row[key] = func(row[key], row);
            }
          });
        });
        data = rows;
      }
    }
    return data;
  };

  const dataAfterConfig = useMemo(
    () => configCellsValues(data),
    [data, cellsConfig]
  );

  const columnsAfterConfig = useMemo(
    () => (isFunction(colConfig) ? colConfig(columns) : columns),
    [columns]
  );

  const MAX_EXCEL_LINES = 1000;
  const exportToCsv = dataAfterConfig.length > MAX_EXCEL_LINES;

  //the process function will crash the browser if the data is too large, only DownloadCsv component can handle it.
  const arrangedData = useMemo(
    () =>
      !exportToCsv && {
        data: process([].concat.apply([], dataAfterConfig), { group }).data,
        aggregates: aggregateBy(dataAfterConfig, aggregates),
      },
    [dataAfterConfig, aggregates, group]
  );

  return exportToCsv ? (
    <DownloadCsv
      data={dataAfterConfig}
      columns={columnsAfterConfig.map((col) => ({
        id: col.id,
      }))}
      tooltipMessage={is_sub ? "ייצוא לCSV מפורט" : "ייצוא לCSV"}
      Icon={is_sub ? SiMicrosoftexcel : RiFileExcel2Line}
      {...{ translation_endpoint }}
      fileName={fileName.replace(".", "")}
    />
  ) : (
    <DownloadExcel
      fileName={fileName.replace(".", "")}
      data={arrangedData.data}
      aggregates={arrangedData.aggregates}
      customFooterTitle={customFooterTitle}
      columns={columnsAfterConfig}
      customBtnToolTipText={customBtnToolTipText}
      {...{ group, translation_endpoint, is_sub, footerConfig }}
    />
  );
};

export default SingleExcelFile;
