// PermissionFilter

import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Col, Row } from "react-flexbox-grid";
import Button from "../../../components/buttons/Button";

class PermissionFilter extends React.Component {
  renderError = ({ error, touched }) => {
    if (touched && error)
      return (
        <p>
          * <span className="error-msg">{error}</span>{" "}
        </p>
      );
  };

  //called in <Field>(redux-form tag) and render the input by the props
  //input is a field in the <Field> tag that handle the input behind the scenes
  renderInput = ({ type, placeholder, input, meta, className }) => {
    return (
      <div className={className}>
        <input type={type} placeholder={placeholder} {...input} />
        {this.renderError(meta)}
      </div>
    );
  };

  render() {
    const { isMobile } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <div className={`permissions--filter ${isMobile?"flex-column-center":"flex-start flex-align-end"}`} style={{ margin: "1rem" }}>
          <div className="permissions--filter__field">
            <label>
              שם הרשת
              <Field
                name="network_name"
                type="text"
                component={this.renderInput}
              />
            </label>
          </div>
          <div className="permissions--filter__field">
            <label>
              שם הסניף
              <Field
                name="branch_name"
                type="text"
                component={this.renderInput}
              />
            </label>
          </div>
          <div className="permissions--filter__field">
            <Button
              textValue="טען הרשאות"
              type="submit"
              className="btn--submit"
            />
          </div>
        </div>
      </form>
    );
  }
}

const validate = (formValues) => {
  const { branch_name, network_name } = formValues;
  const errors = {};
  if (branch_name && branch_name.length < 2)
    errors.branch_name = "שדה זה מחוייב ב2 תווים ומעלה";
  if (network_name && network_name.length < 2)
    errors.network_name = "שדה זה מחוייב ב2 תווים ומעלה";
  return errors;
};

const formWrapped = reduxForm({
  form: "permissions_filter",
  enableReinitialize: true,
  validate,
})(PermissionFilter);

const mapStateToProps = (state) => {
  const privilegesObject = state.admin.privileges;
  const privileges = Object.values(privilegesObject);
  return { privileges };
};

export default connect(mapStateToProps)(formWrapped);
