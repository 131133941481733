import React from "react";
import { Translate } from "react-redux-i18n";

const NewButton = ({ className="" ,onClick , textValue, style, icon, type="button"}) => {
  return (
    <button type={type} style={style} className={`btn btn--normal ${className}`} onClick={onClick}>
     {icon}<div><Translate value={`button.${textValue}`} /></div>
    </button>
  );
};


export default NewButton;
