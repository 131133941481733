import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import FormCheckBox from "../../../components/Forms/inputs/FormCheckBox";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";

const PART_CLOTH = 0;
const PART_EMPLOYEE_LIST = 1;
const PARTS = {
  [PART_CLOTH]: {
    label: "מחיר",
    extraData: [
      { name: "label", component: FormTextField, label: "שם פריט" },
      {
        name: "value",
        component: FormTextField,
        label: "מחיר",

        rules: { required: REQUIRED_MESSAGE },
        type: "number",
      },
    ],
  },
};

export { PARTS, PART_CLOTH, PART_EMPLOYEE_LIST };
