import React from "react";
import Tooltip from "rc-tooltip";
import { connect } from "react-redux";
import { useScreenSize } from "../../Hooks";

const BtnTooltip = ({
  children,
  text,
  placement = "top",
  overlyStyle,
  hide = false,
  isClickable = false,
  maxWidth = "24rem",
  textColor = "inherit",
}) => {
  const { isMobile } = useScreenSize();
  return hide ? (
    <>{children}</>
  ) : !isMobile || isClickable ? (
    <Tooltip
      trigger={isClickable ? ["hover", "click"] : "hover"}
      overlayStyle={overlyStyle}
      placement={placement}
      overlayInnerStyle={{
        direction: "rtl",
        maxWidth,
        textAlign: "right",
      }}
      overlay={
        <span
          style={{ color: textColor, direction: "rtl" }}
          className="u-right-text"
        >
          {text}
        </span>
      }
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
const mapStateToProps = (state) => {
  const { isMobile } = state.publicValues;
  return { isMobile };
};

export default connect(mapStateToProps)(BtnTooltip);
