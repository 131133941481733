import React from "react";
import PropTypes from "prop-types";
import BtnTooltip from "../tooltips/BtnTooltip";

const NewButtonIcon = ({ onClick, icon, className="", alt="" }) => {
  return (
    <BtnTooltip text={alt}>
          <button className={`btn btn--icon ${className}`} onClick={onClick}>
      {icon}
    </button>
    </BtnTooltip>
  );
};

NewButtonIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NewButtonIcon;
