import {
  addMonths,
  endOfToday,
  endOfYesterday,
  startOfToday,
  startOfYesterday,
} from "date-fns";
import { RESET_PAGINATION_DATA } from "../actions/proceduresActions/types";
import { SET_PAGINATION_FILTERS } from "../actions/proceduresActions/types";
import {} from "../actions/proceduresActions/types";
import {
  GET_ADMINS_LIST,
  GET_ALL_BRANCHES,
  GET_ALL_LOGS,
  GET_ALL_NETWORKS,
  SET_USER_LOGS_FILTERS,
  SET_USER_LOGS_PAGINATION,
} from "../actions/userManageLogsAction/types";

const INITIAL_STATE = {
  logs: [],
  adminList: [],
  networks: [],
  branches: [],
  filters: {
    current_page: 1,
    per_page: 15,
    total: 0,
    last_page: 0,
    selectedDates: {
      startDate: startOfToday(),
      endDate: endOfToday(),
      key: "selection",
      label: "היום",
    },
    selectedAdmins: [],
    selectedNetworks: [],
    // selectedBranches: [],
    branchName: null,
    userFirstName: null,
    userLastName: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_LOGS:
      return {
        ...state,
        logs: payload,
      };
    case GET_ADMINS_LIST:
      return { ...state, adminList: payload };
    case GET_ALL_NETWORKS:
      return { ...state, networks: payload };
    case GET_ALL_BRANCHES:
      return { ...state, branches: payload };
    case SET_USER_LOGS_PAGINATION:
      return {
        ...state,
        filters: {
          ...state.filters,
          current_page: payload.current_page,
          per_page: payload.per_page,
          total: payload.total,
          last_page: payload.last_page,
        },
      };
    case SET_USER_LOGS_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, [payload.name]: payload.value },
      };
    default:
      return state;
  }
};
