import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Button from "../components/buttons/Button";
import Modal from "react-responsive-modal";
import { restoreAdminOTP } from "../redux/actions/AdminLoginAction";

class PasswordModal extends React.Component {
  renderError = ({ error, touched }) => {
    if (touched && error)
      return (
        <p>
          * <span className="error-msg">{error}</span>{" "}
        </p>
      );
  };

  //called in <Field>(redux-form tag) and render the input by the props
  //input is a field in the <Field> tag that handle the input behind the scenes
  renderInput = ({ type, placeholder, input, meta, className, ...rest }) => {
    return (
      <div className={className}>
        <input type={type} placeholder={placeholder} {...input} {...rest} />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    const {login_admin_web_id}=this.props;
    this.props.restoreAdminOTP(login_admin_web_id,formValues)
  };

 
  render() {
    const {phone_number,identifier,email, setOTPModal, open,restoreAdminPassword} =this.props;
    return (
      <Modal
        styles={{
          modal: { padding: "3rem", maxWidth: "40rem", textAlign: "center" },
        }}
        open={open}
        center
        animationDuration={0}
        onClose={() => setOTPModal(null)}
      >
        <div style={{ textAlign: "right" }}>
          <form  onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <h1>מערכת רסטיגו</h1>
            <h2>הזן קוד אימות</h2>
            <p style={{ display: "inline-block" }}>
              קוד אימות נשלח כעת לסלולרי שלך שמספרו
            </p>{" "}
            <p style={{ direction: "ltr", display: "inline-block" }}>
              {phone_number?phone_number:""}
            </p>
            <p>אנא הקלד את הקוד ולחץ על כניסה</p>
            <label>
              קוד אימות:
              <Field
                maxLength="6"
                name="code"
                type="text"
                icon="lock"
                className="login__card--input"
                placeholder=""
                component={this.renderInput}
              />
            </label>
            <p>
              לא קיבלת את הקוד? לחץ לקבלת הקוד פעם נוספת ב-
              <Button
                onClick={() => restoreAdminPassword({identifier,email,phone_number}, setOTPModal)}
                className="btn--hyperlink"
                textValue="SMS"
              />
            </p>
            <div className={`slide ${this.props.code?this.props.code.length===6 ? "slide--expand" : "":""}`}>
              {" "}
              <h2>הגדר סיסמא</h2>
              <Field
                name="password"
                type="password"
                icon="lock"
                className="login__card--input"
                placeholder="הגדר סיסמא חדשה"
                component={this.renderInput}
              />
              <Field
                name="password_second"
                type="password"
                icon="lock"
                className="login__card--input"
                placeholder="וידוא סיסמא"
                component={this.renderInput}
              />
              <Button type="submit" className="btn--submit" textValue="כניסה" />
            </div>
          </form>
        </div>
        
      </Modal>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,10}$/;
  if (!formValues.password) errors.password = "נדרש להגדיר סיסמא";
  else if (!formValues.password.match(decimal))
    errors.password =
      "סיסמא חייבת להכיל לפחות פעם אחת: אות גדולה, אות קטנה, מספר, וסימן מיוחד($%&..) באורך של 8-10 תוים";

  if (!formValues.password_second) errors.password_second = "וודא סיסמא";
  else if (!formValues.password)
    errors.password_second = "הגדר סיסמא בשדה הראשון";
  else if (formValues.password_second !== formValues.password)
    errors.password_second = "סיסמא לא תואמת";

  if (!formValues.code) errors.code = "נדרש להכניס קוד אימות";
  else if (formValues.code.length !== 6)
    errors.code = "הכנס 6 ספרות";

  return errors;
};

const selector = formValueSelector('restore_password')


const mapStateToProps = (state) => {
    const code = selector(state,'code')
  return { locale: state.i18n.locale, code };
};

const formWrapped = reduxForm({
  form: "restore_password",
  enableReinitialize: true,
  validate,
})(PasswordModal);

export default connect(mapStateToProps, {restoreAdminOTP})(formWrapped);
