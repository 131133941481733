import { isUndefined, forEach } from "lodash";
import { SHIFT_REQUEST_TYPE_BY_SHIFT } from "../../const";
import { getSafe } from "../../helpers";

export const createFormJson = (data = null, method = null) => {
  const formData = new FormData();
  if (method) formData.append("_method", method);

  if (data) {
    formData.append("data", JSON.stringify(data));
  }

  return formData;
};

// array = subShiftEvents
//(format: {week_day:{employee_id:[events]}})
export const createDayMatrix = (array = []) => {
  let matrix = { 0: {}, 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {} };

  array.forEach((item) => {
    if (item.employee === null) return;
    if (getSafe(() => matrix[item.week_day][item.employee.id])) {
      matrix[item.week_day][item.employee.id] = [
        ...matrix[item.week_day][item.employee.id],
        item,
      ];
    } else {
      matrix[item.week_day][item.employee.id] = [item];
    }
  });

  return matrix;
};

export const notPlacedMatrix = (notPlaced) => {
  var matrix = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };

  for (let i = 0; i < notPlaced.length; i++) {
    matrix[notPlaced[i].week_day].push(notPlaced[i]);
  }

  return matrix;
};

//create matrix of submissions (format: {employee id:{shift id:[days]}})
export const createSubmissionsMatrix = (array = [], type = 1) => {
  let matrix = {};

  array.forEach((item) => {
    const kind_id = getSafe(
      () =>
        type === SHIFT_REQUEST_TYPE_BY_SHIFT ? item.shift.id : item.role.id,
      null
    );

    if (!item.status || kind_id === null) return;
    if (isUndefined(matrix[item.employee.id])) {
      matrix[item.employee.id] = {};
      matrix[item.employee.id][kind_id] = [item.week_day];
      matrix[item.employee.id]["counter"] = 1;
      matrix[item.employee.id][kind_id]["ids_by_week_day"] = {};
      matrix[item.employee.id][kind_id]["ids_by_week_day"][item.week_day] =
        item.id;
    } else if (isUndefined(matrix[item.employee.id][kind_id])) {
      matrix[item.employee.id][kind_id] = [item.week_day];
      matrix[item.employee.id]["counter"]++;
      matrix[item.employee.id][kind_id]["ids_by_week_day"] = {};
      matrix[item.employee.id][kind_id]["ids_by_week_day"][item.week_day] =
        item.id;
    } else if (!matrix[item.employee.id][kind_id].includes(item.week_day)) {
      matrix[item.employee.id][kind_id].push(item.week_day);
      matrix[item.employee.id]["counter"]++;
      matrix[item.employee.id][kind_id]["ids_by_week_day"][item.week_day] =
        item.id;
    }
  });
  return matrix;
};

//create matrix of submissions (format: {employee id:{shift id:[days]}})
export const createNotesMatrix = (array = []) => {
  let matrix = {};
  array.forEach((item) => {
    if (isUndefined(matrix[item.employee.id])) {
      matrix[item.employee.id] = {};
      matrix[item.employee.id][item.week_day] = item;
    } else if (isUndefined(matrix[item.employee.id][item.week_day])) {
      matrix[item.employee.id][item.week_day] = item;
    }
  });

  return matrix;
};

export const executeScroll = (ref, timeOut = 100, block = "center") => {
  setTimeout(() => {
    if (ref) {
      if (ref.current)
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: block,
          inline: "center",
        });
    }
  }, timeOut);
};
export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

export const scrollToBottom = () =>
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: "smooth",
  });
