import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from "../ErrorMessage";
import { FormHelperText } from "@mui/material";

export default function SelectComponent({
  name,
  label,
  rules,
  options = [],
  defaultValue,
  handleChange = () => {},
  type = null,
  size = "large",
  fullWidth = false,
  labelStyle = {},
  ...props
}) {
  const { control } = useFormContext();

  return (
    <Controller
      {...{ name, control, rules, ...(!!defaultValue && { defaultValue }) }}
      render={({
        field: { name, onBlur, onChange, ref: inputRef, value },
        fieldState: { error },
      }) => (
        <FormControl
          variant="outlined"
          size={size}
          error={!!error}
          required={!!rules?.required}
        >
          <InputLabel
            sx={labelStyle}
            variant="outlined"
            shrink={Boolean(value) || value === 0} // Ensure label shrinks if there is a value or zero
          >
            {label}
          </InputLabel>
          <Select
            label={label}
            // variant="outlined"
            // IconComponent={EmptyIcon}

            onChange={(e, value) => {
              onChange(e.target.value);
              handleChange({ name, value: e.target.value });
            }}
            {...{
              name,
              label,
              onBlur,
              inputRef,
              value,
              ...props,
            }}
          >
            {options &&
              options.map((option) => {
                return option.id !== undefined ? (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label ?? option.title}
                  </MenuItem>
                ) : (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            <ErrorMessage>{error?.message}</ErrorMessage>
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
