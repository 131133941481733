export const EXPORT_TYPE_MICPAL = 1;
export const EXPORT_TYPE_HILANET = 4;
export const EXPORT_TYPE_HARGAL = 5;

export const attendanceExportTypes = {
    [EXPORT_TYPE_MICPAL]: "ייצא למיכפל נוכחות",
    [EXPORT_TYPE_HILANET]: "ייצא לחילנט נוכחות",
    [EXPORT_TYPE_HARGAL]: "ייצא להר-גל נוכחות",
};

export const attendanceExportHeader = {
    [EXPORT_TYPE_MICPAL]: "ייצא למיכפל",
    [EXPORT_TYPE_HILANET]: "ייצא לחילנט",
    [EXPORT_TYPE_HARGAL]: "ייצא להר-גל",
}