import { default as Grid } from "@mui/material/Unstable_Grid2";
import { useFormContext } from "react-hook-form";
import Form from "../../../../../components/Forms/Form";
import FormTextField from "../../../../../components/Forms/inputs/FormTextField";
import { useScreenSize } from "../../../../../Hooks";
import FindCoordinatesExplanation from "../FindCoordinatesExplanation";

const defaultValues = {
  latitude: "",
  longitude: "",
  radius: "",
};

const RestigoAttendanceAppCreateForm = ({ onSubmit, token }) => {
  const { isMobile } = useScreenSize()
  return (
    <Form {...{ onSubmit, defaultValues, formId:'form' }}>
      <FormContext {...{ token, isMobile }} />
    </Form>
  );
};

const FormContext = ({ token, isMobile }) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  return (
    <Grid
      style={isMobile ? { marginTop: "1rem" } : {}}
      container
      spacing={isMobile ? 2 : 5}
    >
      {isMobile && <div style={{paddingRight:"1rem"}}><FindCoordinatesExplanation {...{ isMobile }} /></div>}
      <Grid xs={12} md={4}>
        <FormTextField
          fullWidth
          {...{ control }}
          name="latitude"
          label="קו רוחב"
          type="number"
          error={errors.latitude}
          rules={{
            required: "שדה חובה",
          }}
        />
      </Grid>
      <Grid xs={12} md={4}>
        <FormTextField
          fullWidth
          {...{ control }}
          name="longitude"
          label="קו אורך"
          type="number"
          error={errors.longitude}
          rules={{
            required: "שדה חובה",
          }}
        />
      </Grid>
      {!isMobile && (
        <Grid xs={1} style={{ paddingTop: "35px" }}>
          <FindCoordinatesExplanation {...{ isMobile }} />
        </Grid>
      )}
      <Grid xs={12} md={3}>
        <FormTextField
          fullWidth
          {...{ control }}
          name="radius"
          label="רדיוס"
          type="number"
          error={errors.radius}
          rules={{
            required: "שדה חובה",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RestigoAttendanceAppCreateForm;
