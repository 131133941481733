import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { fetchEmployeesBySendingId } from "../../../redux/actions/scheduleAction/index";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import { createElement, useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BiShekel } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { useScreenSize } from "../../../Hooks";
import { TYPE_SUMMONS_FORM, TYPE_SUMMONS_FORM_NEW } from "../consts";
import { Context } from "../Show";
import { PART_TYPE_SALARY } from "../WorkAgreement/consts";
import {
  PARTS,
  PART_EMPLOYEE_NAME,
  INPUT,
  PART_WHEN_SUMMONS_DATE,
  PART_HOUR_SUMMON,
  PART_TYPE_IDENTIFER,
  PART_DATE,
  PART_MAIN_CONTENT,
  PART_PLACE_TAKEN,
  PART_OTHER_PARTICIPANTS,
  PART_TYPE_EMPLOYEE_TYPE_TITLE,
} from "./const";
import React from "react";
import { dateReqFormat } from "../../../helpers";

const SummonsForm = () => {
  const { parts, user, selectedBranch, procedure } = useContext(Context);
  const { control, setValue, watch, getValues } = useFormContext();
  const [emails, setEmails] = useState([]);
  const { replace } = useFieldArray({ control, name: "parts" });
  const signature = useWatch({ control, name: "signature" });
  const branchEmployees = useSelector((state) => state.employees);
  const currentBranch = useSelector((state) => state.branches.selected_branch);
  const { branches } = useSelector((state) => state);
  const { procedures } = useSelector((state) => state.manageProcedures);
  const dispatch = useDispatch();
  const [participants, setParticipants] = useState("");
  const [mainContent, setMainContent] = useState("");

  const [department, setDepartment] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [sendingDate, setSendingDate] = useState("");
  const [summonDate, setSummonDate] = useState("");
  const [address, setAddress] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [employee_name, date] = watch([
    `parts.${PART_EMPLOYEE_NAME}.value`,
    `parts.${PART_DATE}.value`,
    `parts.${PART_WHEN_SUMMONS_DATE}.value`,
    `parts.${PART_TYPE_EMPLOYEE_TYPE_TITLE}.value`,
  ]);
  const [employeeCompanyName, setEmployeeCompanyName] = useState(" ");
  const [selectedTime, setSelectedTime] = useState(null);
  const [procedureBranches, setProcedureBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState(null);
  const handleTimeChange = () => {};
  function getDayInHebrew(dateString) {
    const weekdaysInHebrew = [
      "יום ראשון",
      "יום שני",
      "יום שלישי",
      "יום רביעי",
      "יום חמישי",
      "יום שישי",
      "שבת ",
    ];

    const date = new Date(dateString);
    const options = { weekday: "long" };
    const dayOfWeek = date.toLocaleString("he-IL", options);
    return weekdaysInHebrew.includes(dayOfWeek) ? dayOfWeek : null;
  }
  function formatWithNewlines(inputString) {
    const formattedSections = inputString
      .split("\n")
      .filter((section) => section.trim() !== "") // Remove empty sections
      .map((section, index) => {
        const number = index + 1;
        const hebrewCharacters = section.replace(/^\d+\./, "").trim(); // Remove the number and period from the beginning
        return `${number}. ${hebrewCharacters}`;
      });

    return formattedSections.map((section, index) => (
      <p key={index}>{section}</p>
    ));
  }

  function getTimeStringFromDate(date) {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    return date.toLocaleTimeString("en-US", options);
  }

  useEffect(() => {
    const adressId = parts.find((item) => item.type === PART_PLACE_TAKEN);
    const mainContentId = parts.find((item) => item.type === PART_MAIN_CONTENT);
    if (mainContentId) {
      setValue(`parts.${PART_MAIN_CONTENT}`, {
        id: mainContentId.id,
        value:
          procedure.procedure_type_id.id === TYPE_SUMMONS_FORM_NEW
            ? "1.\n2.\n3.\n"
            : "",
      });
    }
    const updatedBranches = branches.branches.map((branch) => ({
      id: branch.id,
      label: branch.name,
    }));
    setProcedureBranches(updatedBranches);
  }, []);

  const handleEmployeeChange = (e, id, index) => {
    const identifierId = parts.find(
      (item) => item.type === PART_TYPE_IDENTIFER
    );
    const departmentId = parts.find(
      (item) => item.type === PART_TYPE_EMPLOYEE_TYPE_TITLE
    );
    setValue(`parts.${PART_TYPE_EMPLOYEE_TYPE_TITLE}`, {
      id: departmentId.id,
      value: e.department.label,
    });
    setValue(`parts.${index}`, { id, value: e.label });
    setValue("employee_id", e.id);
    setEmployeeCompanyName(e.company_name);
    if (identifierId) {
      setValue(`parts.${PART_TYPE_IDENTIFER}`, {
        id: identifierId.id,
        value: e.identifier,
      });
      setIdentifier(e.identifier);
    }
  };

  const debouncedData = debounce(
    (e, id, index) => {
      if (index == PART_HOUR_SUMMON) {
        setValue(`parts.${index}`, { id, value: e });
      } else {
        setValue(`parts.${index}`, { id, value: e.value });
        switch (e.name) {
          case `parts.${PART_MAIN_CONTENT}.value`:
            setMainContent(e.value);
            break;
          case `parts.${PART_TYPE_IDENTIFER}.value`:
            setIdentifier(e.value);
            break;
          case `parts.${PART_DATE}.value`:
            setSendingDate(e.value);
            break;
          case `parts.${PART_WHEN_SUMMONS_DATE}.value`:
            setSummonDate(e.value);
            break;
          case `parts.${PART_PLACE_TAKEN}.value`:
            setAddress(e.value);
            break;
          case `parts.${PART_OTHER_PARTICIPANTS}.value`:
            setParticipants(e.value);
            break;
          default:
            return;
        }
      }
    },
    [100]
  );
  const validateWordCount = (value, maxChars) => {
    const trimmedValue = value ? value.trim() : "";

    return (
      trimmedValue.length <= maxChars ||
      `אפשר עד מקסימום ${maxChars} תווים בשדה זה.`
    );
  };

  const memoEmployess = useMemo(() => {
    return Object.entries(branchEmployees).map(([key, value]) => {
      return {
        id: key,
        label: value.full_name + ", " + value.department_name,
        name: value.full_name,
        identifier: value.employee_ID_Number,
        department: { id: value.department_id, label: value.department_name },
        salary: value.employee_base_sal,
        company_name: value.company_name,
      };
    });
  }, [branchEmployees]);
  const handleBranchChange = (e, id, index) => {
    setBranchSelected(e.label);
    dispatch(fetchEmployeesBySendingId(e.id));
    const selectedBranch = branches.branches.find(
      (eachBranch) => eachBranch.name === e.label
    );
    if (selectedBranch) {
      const adressId = parts.find((item) => item.type === PART_PLACE_TAKEN);
      if (adressId) {
        setValue(`parts.${PART_PLACE_TAKEN}`, {
          id: adressId.id,
          value: `${selectedBranch.address}, ${selectedBranch.city}`,
        });
        setAddress(`${selectedBranch.address}, ${selectedBranch.city}`);
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: isTablet ? 0 : 50,
        justifyContent: "center",
        gap: "3rem",
      }}
    >
      <div
        style={{
          margin: isTablet ? 0 : 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "3rem",
          fontSize: isMobile ? "1.8rem" : "2rem",
          maxWidth: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography>שם סניף</Typography>
            <ControlAutoComplete
              {...{
                fullWidth: true,
                name: `branch`,
                rules: { required: REQUIRED_MESSAGE },
                options: procedureBranches,
                handleInputChange: (value) => {
                  handleBranchChange(value);
                },
              }}
            />
          </Grid>
          {parts?.map(({ label, value, type, ...part }, index) => {
            switch (type) {
              case PART_EMPLOYEE_NAME:
                return (
                  <Grid
                    item
                    xs={6}
                    // style={{
                    //   width: "300px",
                    //   marginBottom: "10px",
                    //   marginLeft: "20px",
                    // }}
                  >
                    <Typography>שם העובד</Typography>
                    <ControlAutoComplete
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        options: memoEmployess,
                        handleInputChange: (value) =>
                          handleEmployeeChange(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_TYPE_IDENTIFER:
                return (
                  <Grid item xs={6}>
                    <Typography>תעודת זהות</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        id: part.id,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography> תאריך שליחת המסמך</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                      }}
                    />
                  </Grid>
                );
              case PART_MAIN_CONTENT:
                return (
                  <Grid item xs={12}>
                    <Typography> הסיבה לשקילת פיטורין</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 4,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        inputProps: {
                          style: {
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                          },
                        },
                        rules: {
                          validate: (value) => validateWordCount(value, 2000),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_WHEN_SUMMONS_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography>
                      {" "}
                      תאריך שימוע ( ניתן לזמן לטווח זמן של 48 שעות קדימה )
                    </Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        min: dateReqFormat(
                          new Date(new Date().setDate(new Date().getDate() + 2)) // Set minimum date 2 days forward
                        ),
                      }}
                    />
                  </Grid>
                );
              case PART_HOUR_SUMMON:
                return (
                  <Grid item xs={6}>
                    <Typography>שעת השימוע</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        format="HH:MM"
                        ampm={false}
                        value={selectedTime}
                        onChange={(value) => {
                          setSelectedTime(value);
                          debouncedData(value, part.id, type);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                );
              case PART_TYPE_EMPLOYEE_TYPE_TITLE:
                return (
                  <Grid item xs={6}>
                    <Typography>מחלקה</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, index),
                      }}
                    />
                  </Grid>
                );
              case PART_OTHER_PARTICIPANTS:
                return (
                  <Grid item xs={6}>
                    <Typography>השימוע יערך בפני</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 1,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_PLACE_TAKEN:
                return (
                  <Grid item xs={6}>
                    <Typography>מיקום השימוע</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
            }
          })}
        </Grid>

        <Divider style={{ width: "100%" }} />

        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              לכבוד <br></br>
              <span>
                שם מלא: {employee_name ? employee_name.split(",")[0] : ""}
              </span>
              <span> ת.ז {identifier}</span>
              <br></br>
              <br></br>
            </p>
            <p>
              תאריך : {moment(new Date()).format("DD.MM.YYYY")} <span> </span>{" "}
            </p>
          </div>
          <h2
            style={{
              textAlign: "center",
              fontSize: isTablet ? "2rem" : "",
            }}
          >
            הנדון: הזמנה לשימוע
          </h2>
          <br></br>
          <br></br>
          <p>
            א. אנו מבקשים להביא לידיעתך כי החברה שוקלת להפסיק את העסקתך בחברה מן
            הסיבות הבאות:
          </p>
          <br></br>
          <div>{formatWithNewlines(mainContent)}</div>
          <br></br>
          <p>
            ב. בהתאם לכך הנך מוזמן לשימוע, במסגרתו תהיה רשאי להעלות את טענותיך
            בעניין.
          </p>
          <br></br>
          <p>
            ג. השימוע יתקיים ב{getDayInHebrew(summonDate)} בתאריך {summonDate}{" "}
            בשעה {getTimeStringFromDate(new Date(selectedTime))}
          </p>
          <br></br>
          <p>
            ד. בפני {participants} בכתובת {address}
          </p>
          <br></br>
          <p>
            ה. הנך רשאי להיות מיוצג בשימוע על ידי עורך דין כן להיות מלווה באדם
            קרוב לפי בחירתך.
          </p>
          <br></br>
          <p>
            ו. הנך רשאי להעביר את טיעונך בכתב, במקום השתתפות בשימוע בעל פה.
            במידה וכך אתה בוחר, עליך להעביר אלינו את התייחסותך בכתב עד למועד
            השימוע
          </p>
          <br></br>
          <p>
            <strong>
              <u>
                ז. תשומת ליבך כי במידה ולא תתייצב לשימוע, תיחשב למי שוויתר על
                הזכות להשמיע את טעונתיך והחברה תהיה רשאית לקבל את החלטתה בעניין
                מבלי לשמוע את דבריך.
              </u>
            </strong>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <div>
              <p>בברכה,</p>
            </div>
            <div>
              <p> {user.full_name}</p>
            </div>
            <div>
              <p>מחלקה: {user.type_info?.title ?? "מנהל מערכת"}</p>
            </div>
            <div>{employeeCompanyName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummonsForm;
