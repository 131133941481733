import { START_LOADING, STOP_LOADING } from "../actions/loaderAction";

const INITIAL_STATE = {
  isLoading: false,
  text: undefined,
};

export default (state = INITIAL_STATE, action) => {
  const { type, text } = action;
  switch (type) {
    case START_LOADING:
      return {
        isLoading: true,
        text,
      };
    case STOP_LOADING:
      return INITIAL_STATE;
    default:
      return state;
  }
};
