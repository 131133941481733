import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "react-responsive-modal";
import Button from "../../../components/buttons/Button";
import { FaFileExcel, FaPrint, FaBook } from "react-icons/fa";
import { Grid } from "@mui/material";
import Axios from "axios";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";

// ======== ICONS ========
import NewButton from "../../../components/buttons/NewButton";
import { Printer } from "react-feather";

// ======== CONSTANTS ========
import { attendanceExportTypes, EXPORT_TYPE_HARGAL } from "../constants";

// ======== COMPONENTS ========

export const ExportToExcelFiles = ({
  imporToExcel,
  closeExcelModal,
  openUntitleExcelModalAnomalies,
  openAllExcelModalAnomalies,
  isHaveAnomaliesInReport,
  exportMicpal,
  openMicpalAnomaliesNotification,
  setPrintAll,
  micpalWorking,
  downloadZipFile,
}) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <div>
      <Modal
        animationDuration={200}
        styles={
          isMobile
            ? {
                modal: {
                  // Styles for mobile view
                  padding: "1.5rem", // Example padding for mobile
                  width: "90%", // Example width for mobile
                  height: "25%",
                },
                overlay: {
                  zIndex: 100,
                },
              }
            : {
                modal: {
                  // Styles for non-mobile view
                  padding: "3.5rem",
                  maxWidth: "fit-content",
                  minWidth: "50rem",
                },
                overlay: {
                  zIndex: 100,
                },
              }
        }
        open={imporToExcel}
        center
        onClose={closeExcelModal}
      >
        <Grid
          container
          flexDirection={isMobile ? "row" : "column"}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <h2>בחירת אופן יצוא נתונים</h2>
          </Grid>
          <Grid item container>
            <Grid
              item
              xs={6}
              lg={3}
              style={{
                paddingTop: isMobile ? "10px" : "",
              }}
            >
              <Button
                onClick={openAllExcelModalAnomalies}
                icon={FaFileExcel}
                textValue="ייצא הכל"
                style={{ backGroundColor: "#FFFFFF", padding: "0.5rem 0.5rem" }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              lg={3}
              style={{
                paddingTop: isMobile ? "10px" : "",
                paddingRight: "2s0px",
              }}
            >
              <Button
                onClick={openUntitleExcelModalAnomalies}
                icon={FaFileExcel}
                textValue="ייצא ללא כותרות"
                style={{ backGroundColor: "#FFFFFF", padding: "0.5rem 0.5rem" }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              lg={3}
              style={{
                paddingTop: isMobile ? "10px" : "",
              }}
            >
              <Button
                icon={FaPrint}
                textValue="הדפס הכל"
                onClick={() => setPrintAll(true)}
                className={"btn"}
                style={{ backGroundColor: "#FFFFFF", padding: "0.5rem 0.5rem" }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              lg={3}
              style={{
                paddingTop: isMobile ? "10px" : "",
                paddingRight: "20px",
              }}
            >
              <Button
                disabled={
                  attendanceExportTypes[micpalWorking] &&
                  micpalWorking !== EXPORT_TYPE_HARGAL
                    ? false
                    : true
                }
                onClick={
                  isHaveAnomaliesInReport
                    ? openMicpalAnomaliesNotification
                    : exportMicpal
                }
                icon={FaBook}
                textValue={
                  attendanceExportTypes[micpalWorking] ?? "ייצוא להנהלת חשבונות"
                }
                style={{ backGroundColor: "#FFFFFF", padding: "0.5rem 0.5rem" }}
              />
            </Grid>

            <Grid
              item
              xs={6}
              lg={2}
              style={{
                paddingTop: isMobile ? "10px" : "",
              }}
            >
              <Button
                onClick={downloadZipFile}
                icon={FaFileExcel}
                textValue="ייצוא אישורי מחלה / מילואים"
                style={{
                  backGroundColor: "#FFFFFF",
                  padding: "0.5rem 0.5rem",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default ExportToExcelFiles;
