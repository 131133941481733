import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  redReset: {
    "& .muirtl-bac4tg-MuiSvgIcon-root": {
      color: "white",
    },
    "& .muirtl-hxdimv-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator": {
      background: "#E33437"
    }
  },
}));
