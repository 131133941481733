import React from "react";
import DateRangePicker from "./date-range-picker/DateRangePicker";
import he from "date-fns/locale/he";
import { hebStaticRanges } from "./const";
import { fixDate } from "../../helpers";

const DateRangePickerRestigo = ({
  setSelectedRange,
  selectedRange,
  setShow,
  maxDate,
  futureDisable=false
}) => {
  const handleRangeChange = (selected) => {
    if(futureDisable && selected[0].endDate>new Date())
      selected[0].endDate=new Date();
    if (selected[0].label === null) {
      selected[0].label = `${fixDate(selected[0].startDate)} ~ ${fixDate(
        selected[0].endDate
      )}`;
    }

    // setRange(selected);
    setSelectedRange(selected[0]);
  };

  return (
    <DateRangePicker
      onChange={(item) => handleRangeChange([item.selection])}
      showSelectionPreview={false}
      moveRangeOnFirstSelection={false}
      months={2}
      disabledDates={[]}
      ranges={[selectedRange]}
      direction="horizontal"
      locale={he}
      staticRanges={hebStaticRanges}
      onClose={() => {
        setShow(false);
      }}
      maxDate={maxDate}
    />
  );
};
export default DateRangePickerRestigo;
