import React from "react";
import { I18n } from "react-redux-i18n";
import { fixDate } from "../../../../helpers";
import SingleExcelFile from "../../../../components/excel/SingleExcelFile";

const ExcelUntitledExport = (props) => {
  const {
    type,
    sortedData,
    showAllBranches,
    selected_branch,
    date,
    isMobile,
    columnsToExcel,
    checkedColumns,
    hiddenColumns,
    aggregates,
  } = props;

  return (
    <>
      {type === "events" || type === "events_summary" || type === "tips" ? (
        <SingleExcelFile
          buttonText="ייצא ללא כותרות"
          multi={true}
          data={[].concat.apply(
            [],
            sortedData.map((item) => item.body)
          )}
          fileName={`${I18n.t(`pageNav.${type}`)} ${
            showAllBranches ? "כולל" : selected_branch.name.replaceAll(".", "")
          } ${fixDate(date.start)} - ${fixDate(date.end)}`}
          style={isMobile ? { width: "40%" } : {}}
          columns={
            columnsToExcel
              ? columnsToExcel
                  .map((column) => {
                    if (
                      checkedColumns.includes(column.accessor) ||
                      !hiddenColumns.includes(column.accessor) ||
                      column.accessor === "employee_name"
                    ) {
                      return column;
                    }
                    return null;
                  })
                  .filter((el) => el)
              : []
          }
          aggregates={aggregates}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ExcelUntitledExport;
