// GENERAL
export const SET_TAB = "warehouse/SET_TAB";
export const CLOSE_FORM = "warehouse/CLOSE_FORM";
// FILTERS
export const SET_FILTER = "warehouse/SET_FILTER";
export const RESET_FILTER = "warehouse/RESET_FILTER";

export const SET_CATALOG_FILTER = "warehouse/SET_CATALOG_FILTER";

export const SET_PRODUCTS_FILTER = "warehouse/SET_PRODUCTS_FILTER";

// LOADING
export const START_LOADING_FREE_SEARCH =
  "ingredientsWarehouse/START_LOADING_FREE_SEARCH";
export const END_LOADING_FREE_SEARCH =
  "ingredientsWarehouse/END_LOADING_FREE_SEARCH";

export const START_LOADING_MASTER_WAREHOUSES =
  "ingredientsWarehouse/START_LOADING_MASTER_WAREHOUSES";
export const END_LOADING_MASTER_WAREHOUSES =
  "ingredientsWarehouse/END_LOADING_MASTER_WAREHOUSES";

export const START_LOADING_WAREHOUSE_DETAILS =
  "ingredientsWarehouse/START_LOADING_WAREHOUSE_DETAILS";
export const END_LOADING_WAREHOUSE_DETAILS =
  "ingredientsWarehouse/END_LOADING_WAREHOUSE_DETAILS";

export const START_LOADING_MASTER_PRODUCTS_WAREHOUSES =
  "productsWarehouse/START_LOADING_MASTER_PRODUCTS_WAREHOUSES";
export const END_LOADING_MASTER_PRODUCTS_WAREHOUSES =
  "productsWarehouse/END_LOADING_MASTER_PRODUCTS_WAREHOUSES";

export const START_LOADING_ALL_TYPE_ITEMS_FOR_WAREHOUSES =
  "warehouse/START_LOADING_ALL_TYPE_ITEMS_FOR_WAREHOUSES";
export const END_LOADING_ALL_TYPE_ITEMS_FOR_WAREHOUSES =
  "warehouse/END_LOADING_MASTER_PRODUCTS_WAREHOUSES";

export const START_LOADING_WAREHOUSE_ITEMS_INFO =
  "warehouse/START_LOADING_WAREHOUSE_ITEMS_INFO";
export const END_LOADING_WAREHOUSE_ITEMS_INFO =
  "warehouse/END_LOADING_WAREHOUSE_ITEMS_INFO";

export const START_LOADING_CATALOG_ITEMS =
  "warehouse/START_LOADING_CATALOG_ITEMS";
export const END_LOADING_CATALOG_ITEMS = "warehouse/END_LOADING_CATALOG_ITEMS";
export const START_LOADING_WAREHOUSE_CATEGORIES =
  "ingredientsWarehouse/START_LOADING_WAREHOUSE_CATEGORIES";

export const START_LOADING_WAREHOUSE_LOGS = "ingredientsWarehouse/START_LOADING_WAREHOUSE_LOGS";
export const END_LOADING_WAREHOUSE_LOGS = "ingredientsWarehouse/END_LOADING_WAREHOUSE_LOGS";

// FETCH
export const FETCH_WAREHOUSES = "ingredientsWarehouse/FETCH_WAREHOUSES";
export const FETCH_WAREHOUSE_CATEGORIES = "ingredientsWarehouse/FETCH_WAREHOUSE_CATEGORIES";
export const FETCH_WAREHOUSES_FREE_SEARCH =
  "ingredientsWarehouse/FETCH_WAREHOUSES_FREE_SEARCH";
export const FETCH_CATEGORIES = "warehouse/FETCH_CATEGORIES";
export const FETCH_ITEMS = "warehouse/FETCH_ITEMS";
export const FETCH_PRODUCTS = "warehouse/FETCH_PRODUCTS";
export const FETCH_ITEMS_FREE_SEARCH =
  "ingredientsWarehouse/FETCH_ITEMS_FREE_SEARCH";
export const FETCH_PRODUCTS_FREE_SEARCH =
  "ingredientsWarehouse/FETCH_PRODUCTS_FREE_SEARCH";
export const FETCH_PRODUCTS_CATEGORIES =
  "ingredientsWarehouse/FETCH_PRODUCTS_CATEGORIES";

export const FETCH_PRODUCTS_WAREHOUSES =
  "productsWarehouse/FETCH_PRODUCTS_WAREHOUSES";


export const FETCH_WAREHOUSES_LOGS = "ingredientsWarehouse/FETCH_WAREHOUSES_LOGS";

// CREATE NEW
export const CREATE_NEW_WAREHOUSE = "ingredientsWarehouse/CREATE_NEW_WAREHOUSE";
export const CREATE_NEW_PRODUCTS_WAREHOUSE =
  "productsWarehouse/CREATE_NEW_PRODUCTS_WAREHOUSE";

// STORE
export const STORE_WAREHOUSE = "ingredientsWarehouse/STORE_WAREHOUSE";

// EDIT
export const EDIT_WAREHOUSE = "ingredientsWarehouse/EDIT_WAREHOUSE";

// EDIT WAREHOUSE ITEMS
export const SET_WAREHOUSE_CART = "ingredientsWarehouse/SET_WAREHOUSE_CART";
export const SET_WAREHOUSE_CATALOG_CART =
  "ingredientsWarehouse/SET_WAREHOUSE_CATALOG_CART";
export const SET_WAREHOUSE_PRODUCTS_FROM_PAGINATION =
  "ingredientsWarehouse/SET_WAREHOUSE_PRODUCTS_FROM_PAGINATION";
export const SET_WAREHOUSE_CATALOG_FROM_PAGINATION =
  "ingredientsWarehouse/SET_WAREHOUSE_CATALOG_FROM_PAGINATION";

export const SET_WAREHOUSE_INFO = "ingredientsWarehouse/SET_WAREHOUSE_INFO";

export const UPDATE_WAREHOUSE_TOTAL_COUNT =
  "ingredientsWarehouse/UPDATE_WAREHOUSE_TOTAL_COUNT";

// SET
export const SET_SUPPLIERS_OPTIONS = "warehouse/SET_SUPPLIERS_OPTIONS";
export const SET_CATALOG_ITEMS_PAGE =
  "ingredientsWarehouse/SET_CATALOG_ITEMS_PAGE";
export const SET_WAREHOUSE_CATALOG_ITEMS_PAGE =
  "ingredientsWarehouse/SET_WAREHOUSE_CATALOG_ITEMS_PAGE";
export const SET_WAREHOUSE_PRODUCT_ITEMS_PAGE =
  "ingredientsWarehouse/SET_WAREHOUSE_PRODUCT_ITEMS_PAGE";
export const SET_SNACKBAR_OPEN_AFTER_CLOSING_CART =
  "ingredientsWarehouse/SET_SNACKBAR_OPEN_AFTER_CLOSING_CART";
export const SET_SNACKBAR_CLOSED_AFTER_CLOSING_CART =
  "ingredientsWarehouse/SET_SNACKBAR_CLOSED_AFTER_CLOSING_CART";
export const SET_WAREHOUSES_PAGE = "ingredientsWarehouse/SET_WAREHOUSES_PAGE";

export const SET_PRODUCTS_PAGE = "ingredientsWarehouse/SET_PRODUCTS_PAGE";
export const SET_WAREHOUSE_LOGS_PAGE =
  "ingredientsWarehouse/SET_WAREHOUSE_LOGS_PAGE";

// ADD|REMOVE TO|FROM WAREHOUSE ITEMS FROM CATALOG
export const ADD_ITEMS_TO_WAREHOUSE_FROM_CATALOG =
  "ingredientsWarehouse/ADD_ITEMS_TO_WAREHOUSE_FROM_CATALOG";
export const REMOVE_CATALOG_ITEMS_FROM_WAREHOUSE =
  "ingredientsWarehouse/REMOVE_CATALOG_ITEMS_FROM_WAREHOUSE";
export const ADD_MULTI_ITEMS_TO_WAREHOUSE_FROM_CATALOG =
  "ingredientsWarehouse/ADD_MULTI_ITEMS_TO_WAREHOUSE_FROM_CATALOG";

// ADD||REMOVE PRODUCTS FROM WAREHOUSE CART
export const ADD_ITEMS_TO_WAREHOUSE_FROM_PRODUCTS =
  "ingredientsWarehouse/ADD_ITEMS_TO_WAREHOUSE_FROM_PRODUCTS";
export const REMOVE_PRODUCT_ITEMS_FROM_WAREHOUSE =
  "ingredientsWarehouse/REMOVE_PRODUCT_ITEMS_FROM_WAREHOUSE";
export const ADD_MULTI_ITEMS_TO_WAREHOUSE_FROM_PRODUCTS =
  "ingredientsWarehouse/ADD_MULTI_ITEMS_TO_WAREHOUSE_FROM_PRODUCTS";

export const CLOSE_WAREHOUSE_ITEMS =
  "ingredientsWarehouse/CLOSE_WAREHOUSE_ITEMS";

export const CLOSE_WAREHOUSE_INFO = "ingredientsWarehouse/CLOSE_WAREHOUSE_INFO";
