import { Button, IconButton, Typography } from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { renderToString } from "react-dom/server";
import React, { useMemo, useState } from "react";
import {
  PriceCell,
  PriceFooter,
  CountFooter,
  CountFooterNum,
} from "../../components/tables/components/PriceFooter";
import {
  fixDate,
  fixPrice,
  getHebDayByDate,
  thousandSeparator,
  twoZeroAfterDot,
  getSafeDivision,
} from "../../helpers";
import DisplayTable from "./modals/DisplayTable";
import BtnTooltip from "../../components/tooltips/BtnTooltip";
import {
  dateBreakdownColumns,
  dateHiddenColumns,
  dateDefaultVisibleColumns,
} from "./columnsDateByUser";
import { sumFooter } from "../../components/tables/helpers";

export const PopupProvider = ({ children }) => {
  const [value, setValue] = useState(false);
  const handleClick = (e) => {
    setValue(true);
  };

  return (
    <DisplayTable
      {...{
        data: [...children],
        title: "שעות כניסה/יציאה",
        columns: [
          {
            Header: "תאריך",

            accessor: "working_date", //SORTTYPE
            Cell: (propsx) =>
              fixDate(propsx.row?.original?.working_date) +
              " " +
              getHebDayByDate(propsx.row?.original?.working_date),
          },
          {
            Header: "שעת כניסה",

            accessor: "planned_start", //SORTTYPE
          },
          {
            Header: "שעת יציאה",

            accessor: "planned_end", //SORTTYPE
          },
        ],
        open: value,
        message: "",
        onClose: () => setValue(false),
      }}
    />
  );
};
const getRealHours = (uval) => {
  //console.log(uval);

  let start,
    end = 0;
  if (uval?.planned_start) start = uval.planned_start;
  if (uval?.clock_start) start = uval.clock_start;
  if (uval?.trim_start) start = uval.trim_start;
  if (uval?.manual_start) start = uval.manual_start;

  if (uval?.planned_end) end = uval.planned_end;
  if (uval?.clock_end) end = uval.clock_end;
  if (uval?.trim_end) end = uval.trim_end;
  if (uval?.manual_end) end = uval.manual_end;

  //console.log(start,end);
  if (start == 0) start = "00:00:00";
  if (end == 0) end = "00:00:00";
  return [start, end];
};

export const userColumnsByDepartment = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Footer: "סה״כ",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <IconButton
          {...row.getToggleRowExpandedProps({
            // style: {
            //   // We can even use the row.depth property
            //   // and paddingLeft to indicate the depth
            //   // of the row
            //   paddingRight: `${row.depth * 2}rem`,
            // },
          })}
          aria-label="expand row"
          size="small"
          style={{ color: "inherit" }}
        >
          {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </IconButton>
      ) : null,
  },
  {
    Header: "שם העובד",
    accessor: "full_name", //SORTTYPE
    Cell: (props) => {
      //console.log("here2");
      const { oDisplayTableMemo } = props;
      const note = props.row.original?.destination_branch_name
        ? "( הועבר לסניף " + props.row.original.destination_branch_name + ")"
        : props.row.original?.original_branch_name
        ? "( הועבר מסניף " + props.row.original.original_branch_name + ")"
        : "";

      let transferNote = props.row.original.transfer_branch_id > 0 ? note : "";
      if (!("hours" in props.row.original) && !oDisplayTableMemo)
        return (
          <span>{props.row.original.full_name + "\n" + transferNote}</span>
        );
      else if (!("hours" in props.row.original) && oDisplayTableMemo) {
        return (
          <Button
            onClick={() => {
              //console.log(props.row.original?.sub_rows);
              const rows = props.row.original?.subDubRows.map((x) => {
                const hourFilter = {
                  hebrewDate:
                    fixDate(props.row?.original?.date) +
                    " " +
                    getHebDayByDate(props.row?.original?.date),
                  date: props.row?.original?.date,
                };
                //console.log(hourFilter);
                x.hours = hourFilter; //[...Object.values(props.row.original.hours)];
                return x;
              });
              //console.log(rows);
              oDisplayTableMemo(
                [...rows],
                " פירוט שעות חודשי עבור " + props.row.original?.full_name,
                dateBreakdownColumns,
                false,
                props.row.original.full_name
              );
            }}
            style={{
              color: `#00a2ea`,
              textDecoration: "underline",
              fontSize: "100%",
            }}
          >
            {props.row.original.full_name + "\n" + transferNote}
          </Button>
        );
      } else {
        const hourStr = (
          <table>
            <tr>
              <th>תאריך</th>
              <th>שעת התחלה</th>
              <th>שעת סיום</th>
            </tr>
            {Object.values(props.row.original?.hours).map((x) => {
              x.start = getRealHours(x)[0];
              x.end = getRealHours(x)[1];
              return (
                <tr>
                  <td>
                    {fixDate(x.working_date) +
                      " " +
                      getHebDayByDate(x.working_date)}
                  </td>
                  <td>{getRealHours(x)[0]}</td>
                  <td>{getRealHours(x)[1]}</td>
                </tr>
              );
            })}
          </table>
        );

        return (
          <Button
            onClick={() => {
              //console.log(props.row.original?.sub_rows);
              //console.log(props.row.original);
              const rows = props.row.original?.subDubRows.filter((x) => {
                //console.log(props.row.original.cost_category,x.cost_category);
                // if(x.cost_category!==props.row.original.cost_category)
                //     return;
                const hourFilter = Object.values(
                  props.row.original.hours
                ).filter((y) => {
                  //console.log(x.date,y.working_date);
                  x.hebrewDate = fixDate(props.row?.original?.date); //+" "+getHebDayByDate(props.row?.original?.date);;
                  return x.date === y.working_date;
                });
                //console.log(hourFilter);
                x.hours = props.row.original.hours; //[...Object.values(props.row.original.hours)];
                return x?.cost_category == props.row.original?.cost_category;
              });
              oDisplayTableMemo(
                [...rows],
                " פירוט שעות חודשי עבור " + props.row.original?.full_name,
                dateBreakdownColumns,
                false,
                props.row.original.full_name
              );
            }}
            style={{
              color: `#00a2ea`,
              textDecoration: "underline",
              fontSize: "100%",
            }}
          >
            {props.row.original.full_name + "\n" + transferNote}
          </Button>
        );
      }
    },
  },
  {
    Header: "תעריף",
    accessor: "salary",

    Cell: (props) => {
      const salary = props.row.original?.salary ? props.row.original.salary : 0;
      return (
        <div className="money">
          {`₪${thousandSeparator(salary.toFixed(2))}`}
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "סוג שכר",
    accessor: "salary_type",
  },
  {
    Header: "סה״כ שעות",
    accessor: "hours_total",
    Cell: (props) => {
      const hours = props.row.original?.hours_total
        ? props.row.original.hours_total
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    //Footer: CountFooter,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.hours_total + sum, 0),
        [self.rows]
      );

      return <>{total.toFixed(2)}</>;
    },
  },
  {
    Header: "עלות בונוס",
    accessor: "bonus_cost",
    Cell: (props) => {
      const cost = props.row.original?.bonus_cost
        ? props.row.original.bonus_cost
        : 0;
      const monthly_bonus_amount = props.row.original?.monthly_dashboard_bonus
        ? props.row.original.monthly_dashboard_bonus
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${thousandSeparator(twoZeroAfterDot(cost + monthly_bonus_amount))}`}
        </div>
      );
    },
    //Cell: (props) => (props.row?.original?.travel_cost) ?  "₪"+Number(props.row.original.travel_cost).toFixed(2):"₪"+0,
    Footer: (self) => {
      const totalBonus = useMemo(
        () => sumFooter(self.rows, "bonus_cost"),
        [self.rows]
      );
      const totalDashboardBonus = useMemo(
        () => sumFooter(self.rows, "monthly_dashboard_bonus"),
        [self.rows]
      );
      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(totalBonus + totalDashboardBonus))}
        </div>
      );
    },
  },
  {
    Header: () => {
      //return <span><AiFillInfoCircle title="שכר עובד ללא נסיעות ללא בונוס ללא השלמה לטיפ מינימום" alt="שכר עובד ללא נסיעות ללא בונוס ללא השלמה לטיפ מינימום"></AiFillInfoCircle> <label>שכר לפני ע״מ</label></span>;
      return (
        <BtnTooltip
          placement="top"
          text={"שכר עובד ללא נסיעות ללא בונוס ללא השלמה לטיפ מינימום"}
        >
          <div>
            <label>שכר לפני ע״מ</label>
          </div>
        </BtnTooltip>
      );
    },
    cleanHeader: "שכר לפני ע״מ",
    accessor: "total_cost_before_employer_cost",
    Cell: (props) => {
      const { total_cost_before_employer_cost } = props.row.original;
      const cost = props.row.original?.bonus_cost
        ? props.row.original.bonus_cost
        : 0;
      const monthly_bonus_amount = props.row.original?.monthly_bonus_amount
        ? props.row.original.monthly_bonus_amount
        : 0;
      return fixPrice(
        total_cost_before_employer_cost + monthly_bonus_amount + cost
      );
    },
    Footer: PriceFooter,
  },

  {
    Header: "עלות מעביד",
    accessor: "employer_cost",
    Cell: (props) => {
      let tp = Number(
        ((props.row.original?.total_cost_after_employer_cost -
          props.row.original?.total_cost_before_employer_cost) /
          props.row.original.total_cost_before_employer_cost) *
          100
      );
      if (!props.row?.original?.employer_cost)
        props.row.original.employer_cost = Number(
          props.row.original.total_cost_after_employer_cost -
            props.row.original.total_cost_before_employer_cost +
            getSafeDivision(tp, 100) *
              (props.row?.original?.bonus_cost ||
                0 + props.row?.original?.monthly_bonus_amount ||
                0)
        ).toFixed(2);
      return (
        <div>
          <div className="money">
            {props.row.original?.employer_cost
              ? fixPrice(
                  twoZeroAfterDot(
                    props.row.original.total_cost_after_employer_cost -
                      props.row.original.total_cost_before_employer_cost +
                      getSafeDivision(tp, 100) *
                        (props.row?.original?.bonus_cost ||
                          0 + props.row?.original?.monthly_bonus_amount ||
                          0)
                  )
                )
              : "₪" + 0}
          </div>
          <div style={{ margin: "auto" }}>
            ({!isNaN(tp) && isFinite(tp) ? tp.toFixed(2) + "%" : 0 + "%"})
          </div>
        </div>
      );
    },
    Footer: (props) => {
      //console.log(props.data);
      return (
        <div className="money">
          {" "}
          ₪
          {props.data
            .reduce(
              (sum, row) =>
                ("employer_cost" in row
                  ? Number(row["employer_cost"])
                  : row["total_cost_after_employer_cost"] -
                    row["total_cost_before_employer_cost"]) + sum,
              0
            )
            .toFixed(2)}
        </div>
      );
    },
  },
  {
    Header: "שכר כולל ע״מ",
    accessor: "total_cost_after_employer_cost",
    Cell: (props) => {
      const { total_cost_after_employer_cost } = props.row.original;
      const cost = props.row.original?.bonus_cost
        ? props.row.original.bonus_cost
        : 0;
      const monthly_bonus_amount = props.row.original?.monthly_bonus_amount
        ? props.row.original.monthly_bonus_amount
        : 0;
      return fixPrice(
        total_cost_after_employer_cost + monthly_bonus_amount + cost
      );
    },
    Footer: PriceFooter,
    cleanHeader: "שכר כולל ע״מ",
    Header: () => {
      //return <span><AiFillInfoCircle  title="שכר עובד+נסיעות+בונוס+השלמה לטיפ מינימום" alt="שכר עובד+נסיעות+בונוס+השלמה לטיפ מינימום"></AiFillInfoCircle> <label>שכר כולל ע״מ</label></span>;
      return (
        <BtnTooltip
          placement="top"
          text={"שכר עובד+נסיעות+בונוס+השלמה לטיפ מינימום"}
        >
          <div>
            <label>שכר כולל ע״מ</label>
          </div>
        </BtnTooltip>
      );
    },
  },
  {
    Header: "אחוז מההכנסה",
    accessor: "total_precent",
    Cell: (props) =>
      props.row.original?.total_precent > 0 &&
      isFinite(props.row.original?.total_precent)
        ? props.row.original?.total_precent + "%"
        : 0 + "%",
  },
  {
    Header: "100%",
    accessor: "hours_100",
    Cell: (props) => {
      const hours = props.row.original?.hours_100
        ? props.row.original.hours_100
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "125%",
    accessor: "hours_125",
    //Cell: (props) => Number(props.row.original.hours_125).toFixed(2),
    Cell: (props) => {
      const hours = props.row.original?.hours_125
        ? props.row.original.hours_125
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "150%",
    accessor: "hours_150",
    Cell: (props) => {
      const hours = props.row.original?.hours_150
        ? props.row.original.hours_150
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "שבת + חג",
    accessor: "hours_150_shabat",
    //Cell: (props) => Number(props.row.original.hours_150_shabat).toFixed(2)
    Cell: (props) => {
      const hours = props.row.original?.hours_150_shabat
        ? props.row.original.hours_150_shabat
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "175%",
    accessor: "hours_175",
    Cell: (props) => {
      const hours = props.row.original?.hours_175
        ? props.row.original.hours_175
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "200%",
    accessor: "hours_200",
    Cell: (props) => {
      const hours = props.row.original?.hours_200
        ? props.row.original.hours_200
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "הפסקה",
    accessor: "break",
    Footer: CountFooterNum,
  },

  {
    Header: "עלות נסיעות",
    accessor: "travel_cost",
    Cell: (props) => {
      const cost = props.row.original?.travel_cost
        ? props.row.original.travel_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    //Cell: (props) => (props.row?.original?.travel_cost) ?  "₪"+Number(props.row.original.travel_cost).toFixed(2):"₪"+0,
    Footer: PriceFooter,
  },
  {
    Header: "עלות אוכל",
    accessor: "meal_cost",
    Cell: (props) =>
      props.row?.original.meal_cost
        ? "₪" + Number(props.row.original.meal_cost).toFixed(2)
        : "₪" + 0,
    Footer: PriceFooter,
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"דמי הבראה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        דמי הבראה
      </>
    ),
    accessor: "health_cost",
    Cell: (props) => {
      const cost = props.row.original?.health_cost
        ? props.row.original.health_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },

    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "health_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"תעריף חופשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תעריף חופשה
      </>
    ),
    accessor: "holiday_cost",
    Cell: (props) => {
      const cost = props.row.original?.holiday_cost
        ? props.row.original.holiday_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "holiday_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },

  {
    Header: () => (
      <>
        <BtnTooltip text={"תעריף שעות נוספות גלובלית"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תעריף שעות נוספות גלובלית
      </>
    ),
    accessor: "global_cost",
    Cell: (props) => {
      const cost = props.row.original?.global_cost
        ? props.row.original.global_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "global_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "השלמה לטיפ",
    accessor: "tip_complete_cost",

    Cell: (props) => {
      const cost = props.row.original?.tip_complete_cost
        ? props.row.original.tip_complete_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "טיפ מזומן",
    accessor: "cash_tip",
    Cell: (props) => {
      const cash_tip = props.row.original?.sum[0]
        ? props.row.original.sum[0].cash_tip
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cash_tip)}`}
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "טיפ אשראי",
    accessor: "credit_tip",
    Cell: (props) => {
      const credit_tip = props.row.original?.sum[0]
        ? props.row.original.sum[0].credit_tip
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(credit_tip)}`}
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "טיפ מזומן לפני הפרשה",
    accessor: "cash_tip_before_allowance",
    Cell: (props) => {
      const cash_tip_before_allowance = props.row.original?.sum[0]
        ? props.row.original.sum[0].cash_tip_before_allowance
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cash_tip_before_allowance)}`}
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "טיפ אשראי לפני הפרשה",
    accessor: "credit_tip_before_allowance",
    Cell: (props) => {
      const credit_tip_before_allowance = props.row.original?.sum[0]
        ? props.row.original.sum[0].credit_tip_before_allowance
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(credit_tip_before_allowance)}`}
        </div>
      );
    },
    Footer: PriceFooter,
  },
  // {
  //   Header: "צפי סוף חודש",
  //   accessor: "diners",
  // },
  // {
  //   Header: "יעד ב₪",
  //   accessor: "diners_avg",
  //   Cell: PriceCell,
  // },
  // {
  //   Header: "יעד ב%",
  //   accessor: "avg",
  //   Cell: (props) => (props.row.original.avg) ? props.row.original.avg+"%" : 0+"%",
  // },
];
export const excellAggregates = [
  { field: "hours_100", aggregate: "sum" },
  { field: "hours_125", aggregate: "sum" },
  { field: "hours_150", aggregate: "sum" },
  { field: "hours_150_shabat", aggregate: "sum" },
  { field: "hours_175", aggregate: "sum" },
  { field: "hours_200", aggregate: "sum" },
  { field: "hours_total", aggregate: "sum" },
  { field: "cost_raw", aggregate: "sum" },
  { field: "cost_total", aggregate: "sum" },
  { field: "total_with_vat_after_fees", aggregate: "sum" },
];

export const userBreakdownSubColumns = [
  {
    Header: "סוג",
    accessor: "type",
  },
  {
    Header: "סה״כ ללא מע״מ",
    accessor: "cost_raw",
    Cell: PriceCell,
  },
  {
    Header: "סה״כ כולל מע״מ",
    accessor: "cost_total",
    Cell: PriceCell,
  },
  {
    Header: "עמלות",
    accessor: "travel_cost",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "סה״כ אחרי עמלות",
    accessor: "total_including_tips",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  // {
  //   Header: "סועדים",
  //   accessor: "diners",
  // },
  // {
  //   Header: "ממוצע לסועד",
  //   accessor: "diners_avg",
  // },
  // {
  //   Header: "חלוקה %",
  //   accessor: "percent",
  //   Cell: ({ value }) => `${value.toFixed(1)}%`,
  // },
];

export const mobileDateBreakdownColumns = (setDialogModal) => [
  {
    Header: "",
    accessor: "id",
    Cell: ({ row }) => {
      const {
        hebrew_create_date,
        department_name,
        cost_raw,
        cost_total,
        z_with_vat,
        diners,
        diners_avg,
        z_numbers,
        transaction_date,
        date,
      } = row.original;
      return (
        <div>
          <Typography align="center" component="div">
            <h2 style={{ display: "inline" }}>{`${hebrew_create_date} ${fixDate(
              date,
              "DD/MM"
            )}`}</h2>
            {/* <h2 style={{ display: "inline" }}>{`${day} ${fixDate(hebrew_date,'MM/DD')}`}</h2> */}
            {/* <IconButton
              color="secondary"
              onClick={() =>
                setDialogModal({
                  iconJSX: () => IoMdPricetag({ size: "7em" }),
                  title: `נתונים נוספים לתאריך ${hebrew_date}`,
                  showConfirmButton: false,
                  showAbortButton: false,
                  text: (
                    <SimpleTable
                      columns={[
                        {
                          title: "פירוט",
                          name: "key",
                          render: (data, row) => {
                            return data;
                          },
                        },
                        {
                          title: "ערך",
                          name: "value",
                          render: (data, row) => {
                            return data;
                          },
                        },
                      ]}
                      data={[
                        { key: `Z כולל מע״מ`, value: fixPrice(z_with_vat) },
                        { key: `מס' סועדים`, value: diners },
                        { key: `ממוצע לסועד`, value: fixPrice(diners_avg) },
                        { key: `מס' Z`, value: z_numbers },
                      ]}
                    />
                  ),
                })
              }
            >
              <IoMdPricetag />
            </IconButton> */}
          </Typography>
          <div className="flex-between">
            <div>
              <strong>{fixPrice(cost_raw)}</strong>
              <small style={{ marginRight: "1rem" }}>ללא מע״מ</small>
            </div>
            <div>
              <strong>{fixPrice(cost_raw)}</strong>
              <small style={{ marginRight: "1rem" }}>כולל מע״מ</small>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    accessor: "day",
    header: "",
    hide: true,
  },
];
