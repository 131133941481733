import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import {
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SummeryBox as CardSummeryBox2 } from "../../components/card/SummeryBox";

import { SummeryBox as CardSummeryBox } from "../income/components/SummeryBox";
import { ClipLoader } from "react-spinners";
import {
  fixDate,
  fixPercentage,
  fixPrice,
  getHebDayByDate,
  thousandSeparator,
} from "../../helpers";
import { VAT_COEFFICIENT } from "../products/const";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const DataAnlyzeBullets = (props) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.dataAnalyze.isLoading);
  const selectedDates = useSelector(
    (state) => state.dataAnalyze.filters.selectedDates
  );

  const bullets_data = useSelector((state) => state.dataAnalyze.bullets_data);

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <>
      <Grid item xs={12 / 7}>
        <CardSummeryBox
          className="summeryBox__long"
          height={isMobile ? "120px" : "150px"}
          width={isMobile ? "100%" : "100%"}
        >
          {loading ? (
            <div style={{ height: "100%" }} className="flex-center">
              <ClipLoader size={60} color="#00a2ea" loading={true} />
            </div>
          ) : (
            <>
              <div
                className="flex-between-center  margin-y-05"
                style={{ justifyContent: "center" }}
              >
                <h2 className="summeryBoxByDate">
                  {`${getHebDayByDate(selectedDates.endDate)} - ${fixDate(
                    selectedDates.endDate
                  )}`}
                </h2>
              </div>
              <div
                className="summeryText"
                style={{
                  width: "300px",
                  justifyContent: "space-between",
                  textAlign: "center",
                  marginRight: "30px",
                }}
              >
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    <Typography style={{ color: "white" }}>
                      סה״כ ללא מע״מ
                    </Typography>
                  }
                >
                  <p className="summeryText--1">
                    {fixPrice(
                      (bullets_data.last_date_income?.amount ?? 0) /
                        VAT_COEFFICIENT,
                      0
                    )}
                    {isMobile && (
                      <>
                        <br />
                        <small className="summeryText--small">ללא מע״מ</small>
                      </>
                    )}
                  </p>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    <Typography style={{ color: "white" }}>
                      סה״כ כולל מע״מ
                    </Typography>
                  }
                >
                  <p className="summeryText--1">
                    {fixPrice(bullets_data.last_date_income?.amount, 0)}
                    {isMobile && (
                      <>
                        <br />
                        <small className="summeryText--small">כולל מע״מ</small>
                      </>
                    )}
                  </p>
                </Tooltip>
              </div>
            </>
          )}
        </CardSummeryBox>
      </Grid>
      <Grid item xs={12 / 7}>
        <CardSummeryBox2
          {...{
            text1: fixPrice(bullets_data.total_combined_food_cost),
            text2: fixPercentage(
              bullets_data.total_combined_food_cost_percentage ?? 0,
              0
            ),
            text1Tooltip: `סה"כ בלי מע"מ`,
            text2Tooltip: `אחוז מהכנסה`,
            title: `Food Cost`,
            height: isMobile ? "120px" : "150px",
            width: "100%",
            fontSize: "14px",
            index: 2,
            isLoading: loading,
            flexColumnClass: false,
          }}
        />
      </Grid>
      <Grid item xs={12 / 7}>
        <CardSummeryBox2
          {...{
            text1: fixPrice(bullets_data.total_employee_cost, 0),
            text2: fixPercentage(
              bullets_data.total_employee_cost_percentage ?? 0,
              0
            ),
            text1Tooltip: `סה"כ בלי מע"מ`,
            text2Tooltip: `אחוז מהכנסה`,
            title: `עובדים`,
            height: isMobile ? "120px" : "150px",
            width: "100%",
            fontSize: "14px",
            index: 9,
            isLoading: loading,
            flexColumnClass: false,
          }}
        />
      </Grid>
      <Grid item xs={12 / 7}>
        <CardSummeryBox2
          {...{
            text1: fixPrice(bullets_data.operational_cost_received, 2),
            text2: fixPercentage(
              bullets_data.operational_cost_received_percentage ?? 0,
              0
            ),
            text1Tooltip: `סה"כ בלי מע"מ`,
            text2Tooltip: `אחוז מהכנסה`,
            title: `הוצאות תפעול`,
            height: isMobile ? "120px" : "150px",
            width: "100%",
            fontSize: "14px",
            index: 4,
            isLoading: loading,
            flexColumnClass: false,
          }}
        />
      </Grid>
      <Grid item xs={12 / 7}>
        <CardSummeryBox2
          {...{
            text1: fixPrice(bullets_data.funding_cost_received, 2),
            text2: fixPercentage(
              bullets_data.funding_cost_received_percentage ?? 0,
              0
            ),
            text1Tooltip: `סה"כ בלי מע"מ`,
            text2Tooltip: `אחוז מהכנסה`,
            title: `הוצאות מימון`,
            height: isMobile ? "120px" : "150px",
            width: "100%",
            fontSize: "14px",
            index: 1,
            isLoading: loading,
            flexColumnClass: false,
          }}
        />
      </Grid>
      <Grid item xs={12 / 7}>
        <CardSummeryBox2
          {...{
            text1: fixPrice(bullets_data.management_cost_received, 2),
            text2: fixPercentage(
              bullets_data.management_cost_received_percentage ?? 0,
              0
            ),
            text1Tooltip: `סה"כ בלי מע"מ`,
            text2Tooltip: `אחוז מהכנסה`,
            title: `הוצאות הנהלה וכלליות`,
            height: isMobile ? "120px" : "150px",
            width: "100%",
            fontSize: "14px",
            index: 3,
            isLoading: loading,
            flexColumnClass: false,
          }}
        />
      </Grid>
      <Grid item xs={12 / 7}>
        <CardSummeryBox2
          {...{
            text1: fixPrice(bullets_data.profit),
            text2: fixPercentage(bullets_data.profit_percent),
            text1Tooltip: `סה"כ בלי מע"מ`,
            text2Tooltip: `אחוז מהכנסה`,
            title: `רווח`,
            height: isMobile ? "120px" : "150px",
            width: "100%",
            fontSize: "14px",
            index: 6,
            isLoading: loading,
            flexColumnClass: false,
          }}
        />
      </Grid>
    </>
  );
};

export default DataAnlyzeBullets;
