import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import rememberReducer from "./rememberReducer";
import { i18nReducer } from "react-redux-i18n";
import publicReducer from "./publicReducer";
import scheduleReducer from "./scheduleReducer";
import employeeReducer from "./employeeReducer";
import roleReducer from "./roleReducer";
import submissionReducer from "./submissionReducer";
import errorReducer from "./errorReducer";
import confirmReducer from "./confirmReducer";
import authAdminReducer from "./authAdminReducer";
import adminReducer from "./adminReducer";
import confirmBarReducer from "./confirmBarReducer";
import snackBarReducer from "./snackBarReducer";
import branchReducer from "./branchReducer";
import dialogReducer from "./dialogReducer";
import UserPrivilegesReducer from "./UserPrivilegesReducer";
import orderReducer from "./orderReducer";
import networkReducer from "./networkReducer";
import loaderReducer from "./loaderReducer";
import messageReducer from "./messageReducer";
import generalDataReducer from "./generalDataReducer";
import permissionsReducer from "./permissionsReducer";
import productsReducer from "./productsReducer";
import itemReducer from "./itemReducer";
import manageProceduresReducer from "./manageProceduresReducer";
import rolesMapReducer from "./rolesMapReducer";
import userManageLogsReducer from "./userManageLogsReducer";
import form101Reducer from "./form101Reducer";
import warehouseReducer from "./warehouseReducer";
import { managePositionReducer } from "./managePositionSlice";
import newRequisitionReducer from "./newRequisitionSlice";
import manageTasksReducer from "./manageTasksReducer";
import adminRequisitionReducer from "./requisitionAdminSlice";
import { homePageReducer } from "./homePageSlice";
import { expenseSummaryReducer } from "./expenseSummarySlice";
import { incomeCashReducer } from "./IncomeCash";
import { pettyCashReducer } from "./PettyCash";
import { purchaseSummaryReducer } from "./purchaseSummarySlice";
import workersSlice from "../../pages/users/workers_management/workersSlice";
import workersFilterSlice from "../../pages/users/workers_management/filters/workersFilterSlice";
import newWorkersSlice from "../../pages/users/workers_management/workers_from_pos/newWorkersSlice";
import { dataAnalyzeReducer } from "./DataAnalyzeSlice";
import { ticketReducer } from "./ticketReducer";
export default combineReducers({
  form: formReducer,
  auth: authReducer,
  i18n: i18nReducer,
  remember: rememberReducer,
  publicValues: publicReducer,
  schedule: scheduleReducer,
  employees: employeeReducer,
  submissions: submissionReducer,
  roles: roleReducer,
  rolesMap: rolesMapReducer,
  error: errorReducer,
  confirm: confirmReducer,
  dialog: dialogReducer,
  authAdmin: authAdminReducer,
  admin: adminReducer,
  confirmBar: confirmBarReducer,
  snackBar: snackBarReducer,
  branches: branchReducer,
  user_privileges: UserPrivilegesReducer,
  orders: orderReducer,
  products: productsReducer,
  network: networkReducer,
  loader: loaderReducer,
  messages: messageReducer,
  generalData: generalDataReducer,
  permissions: permissionsReducer,
  goods: itemReducer,
  manageProcedures: manageProceduresReducer,
  manageTasks: manageTasksReducer,
  UserLogs: userManageLogsReducer,
  form101: form101Reducer,
  warehouse: warehouseReducer,
  managePositions: managePositionReducer,
  newRequisition: newRequisitionReducer,
  adminRequisition: adminRequisitionReducer,
  expenseSummary: expenseSummaryReducer,
  homePage: homePageReducer,
  incomeCash: incomeCashReducer,
  pettyCash: pettyCashReducer,
  purchaseSummary: purchaseSummaryReducer,
  workers: workersSlice,
  workersFilter: workersFilterSlice,
  newPOSWorkers: newWorkersSlice,
  dataAnalyze: dataAnalyzeReducer,
  ticketReducer: ticketReducer,
});
