import {
  MAKOR_PROVIDER,
  RESTIGO_ATTENDANCE_APP_PROVIDER,
} from "../pages/admin/interfaces/attendanceClocks/AttendanceClocks";
import {
  AREA_MANAGER,
  BRANCH_MANAGER,
  KITCHEN_MANAGER,
  NETWORK_MANAGER,
  PROCUREMENT_AREA_MANAGER,
  PROCUREMENT_MANAGER,
  SEND_ORDERS,
  SHIFT_MANAGER,
} from "../privilegesIndexes";

const translations = {
  en: {
    IN_ORDER: "in order",
    IN_CATALOG: "in catalog",
    TEMP: "temporary",
    form_101: "Form 101",
    procedures: "Procedures",
    work_agreement: "Work Agreement",
    employee_notification: "Employee Notification",
    sexual_harassment: "Sexual Harassment",
    pageNav: {
      events: "Events",
      salary: "Salary",
      tips: "Tips",
      anomalies: "Anomalies",
      pages: "Pages",
      tabs: "Tabs",
      base: "Base",
      other: "Other",
      general: "General",
    },
    home: {
      headline: "Home Page",
    },
    orders_manage: {
      headline: "Orders",
    },
    employees: {
      headline: "Work Schedule",
    },
    reports: { headline: "reports" },
    loginAdmin: { headline: "Login Admin" },
    orders_analysis: { headline: "Orders Analysis" },
    personalArea: { headline: "Personal Area" },
    login: {
      headline: "Login",
      details: "Login account by user email address and password",
      email: "Email Address",
      password: "Password",
      errors: {
        email_format: "You must type in an email format",
        email_missing: "You must enter a email",
        password_format: "Password must be at least 6 characters",
        password_missing: "You must enter a password",
      },
    },
    button: {
      confirm: "Confirm",
      cancel: "Cancel",
      submit: "Submit",
      login: "Login",
      save: "Save",
      passRecovery: "Recover Password",
    },
    nav: {
      dashboard: "Dashboard",
      logout: "LogOut",
      orders: "Orders",
      orders_new: "New Orders",
      orders_manage: "Orders Management",
      finance: "Finance Management",
      franchise_fees: "Franchise Fees",
      finance_manage: "Manage Income & Expanse",
      snapshot: "Snapshot",
      income: "Income",
      income_monthly: "Monthly Income",
      income_history: "Income History",
      expense: "Expenses",
      expense_monthly: "Monthly Expenses",
      expense_history: "Expenditure History",
      cost: "Costs",
      cost_monthly: "Monthly Cost",
      cost_history: "Cost History",
      accounting: "Accounting",
      accounting_reports: "Accounting reports",
      inventory: "Inventory",
      inventory_gaps: "Inventory Gaps",
      inventory_manage: "Manage Inventory",
      stocktaking: "Stocktaking",
      preparations: "Preparations",
      inventory_dump: "Dump",
      inventory_transfer: "Transfers",
      business: "Business",
      business_settings: "Business Settings",
      business_employees: "Mange Employees",
      business_suppliers: "Manage Suppliers",
      items: "Items",
      items_manage: "Manage Items",
      items_tree: "Items Tree",
      items_comparison: "Items Comparison",
      items_cost: "Items Cost",
      items_category: "Manage categories",
      data: "Data",
      data_analysis: "Data Analysis",
      data_orders: "Orders Analysis",
      reports: "Export Reports",
      reports_employees: "Employees Reports",
      reports_inventory: "Inventory Reports",
      reports_suppliers: "Suppliers Reports",
      employees: "Placement Of Employees",
      employees_schedule: "Work Schedule",
      home: "Home",
    },
    excel: {
      employeeId: "Employee ID",
      employeeName: "Name",
      employee_first_name: "First Name",
      employee_last_name: "Last Name",
      eventRoleId: "Role ID",
      eventRole: "Role",
      eventDate: "Date",
      dayStr: "Day",
      actualClockIn: "Clock Enter",
      actualClockOut: "Clock Exit",
      actualHours: "Total hours",
      tipHours: "Tip Hours",
      hourlyTip: "Tip for Hour",
      totalBeforeAllowance: "Total before allowance",
      totalAllowance: "Total allowance",
      totalCashTipForEvent: "Total tip in cash",
      totalCardTipsForEvent: "Total tip in Credit",
      minimumGap: "Minimum Gap",
      totalTipForEvent: "Total tip",
      relatedEventId: "Event Num",
      break: "Break",
      cost: "Cost",
      manual_start: "Enter time",
      manual_end: "Exit time",
      hours_100: "100% rate",
      hours_125: "125% rate",
      hours_150: "150% rate",
      hours_175: "175% rate",
      hours_200: "200% rate",
      hours_total: "Total hours",
      role_name: "Role",
      week_day: "Day",
      working_date: "Date",
      cost_raw: "salary Before Tax",
      meal_cost: "Meal Cost",
      travel_cost: "Travel Cost",
      working_days: "Working Days",
      global_salary: "Global Salary",
      current_salary_type: "Current sSalary Type",
      day: {
        0: "sunday",
        1: "monday",
        2: "tuesday",
        3: "wednesday",
        4: "thursday",
        5: "friday",
        6: "saturday",
      },
      salary: "Salary",
      employee_accountancy_number: "Accountancy Number",
    },
  },
  heb: {
    days: {
      sunday: "יום ראשון",
      monday: "יום שני",
      tuesday: "יום שלישי",
      wednesday: "יום רביעי",
      thursday: "יום חמישי",
      friday: "יום שישי",
      saturday: "יום שבת",
    },
    IN_ORDER: "בהזמנה",
    IN_CATALOG: "בקטלוג",
    TEMP: "זמני",
    form_101: "טופס 101",
    procedures: "נהלים",
    work_agreement: "הסכם עבודה",
    employee_notification: "הסכם נגישות",
    sexual_harassment: "תקנון הטרדה מינית",
    pageNav: {
      events: "דוח נוכחות",
      futureAssignsReport: "שיבוצים עתידיים",
      salary: "דוח שכר",
      tips: "דוח טיפים",
      daily_tips: "דוח טיפים חודשי",
      salary_by_identifier: "דוח שכר רשתי",
      anomalies: "דוח חריגים",
      pages: "דפים",
      tabs: "חוצצים",
      base: "פעולות בסיס",
      other: "פעולות אחר",
      general: "הרשאות ברמת רשת",
      salary_position: "דוח שכר תפקיד",
      salary_department: "דוח שכר מחלקה",
      transfers: "דוח העברות",
      work_force: "דוח מצבת כח אדם",
      summary_tips: "דוח טיפים מסכם",
      refunds: "הזמנות זיכוי פתוחות",
      refundItemsReport: "פירוט זיכוים לפי מוצר",
      waiter: "דוח שכר מלצור",
      events_summary: "דוח נוכחות מצומצם",
    },
    home: {
      headline: "דף הבית",
    },
    data: {
      headline: "ניתוח נתונים",
    },
    orders: {
      headline: "הזמנות",
    },
    recipes: {
      headline: "ספר מתכונים",
    },
    finance: {
      headline: "ניהול הכנסות והוצאות",
    },
    items: {
      headline: "מוצרים",
    },
    orders_analysis: { headline: "ניתוח הזמנות" },
    SpecialRequest: {
      headline: "בקשות מיוחדות",
    },
    employees: {
      headline: "סידור עבודה",
    },
    income: {
      headline: "הכנסות",
    },
    employeesDetails: {
      headline: "פרטי עובדים",
    },
    products: {
      headline: "עץ מוצר חדש",
    },
    reports: { headline: "הפקת דוחות" },
    inventory: { headline: "מלאי" },
    accounting: { headline: "הנהלת חשבונות" },
    personalArea: { headline: "אזור אישי" },
    loginAdmin: { headline: "התחברות אדמין" },
    restoreAdmin: { headline: "שחזור סיסמא אדמין" },
    login: {
      headline: "התחברות",
      details: "התחברו למערכת באמצעות כתובת מייל וסיסמא",
      email: "כתובת מייל",
      password: "סיסמא",
      errors: {
        email_format: "כתובת המייל בפורמט לא תקין",
        email_missing: "נדרש להכניס מייל משתמש",
        password_format: "סיסמא חייבת להכיל לפחות 6 תוים",
        password_missing: "נדרש להכניס סיסמא",
      },
    },
    button: {
      confirm: "אישור",
      cancel: "ביטול",
      submit: "שלח",
      save: "שמור",
      login: "התחבר/י",
      passRecovery: " שחזור סיסמא",
    },
    nav: {
      dashboard: "דשבורד",
      logout: "התנתק/י",
      orders: "הזמנות",
      orders_new: "הזמנות חדשות",
      orders_manage: "ניהול הזמנות",
      finance: "ניהול הכנסות והוצאות",
      finance_manage: "הזנת הכנסות והוצאות",
      franchise_fees: "דמי זיכיון והוצאות מטה",
      branchfees: "ניהול עמלות",
      snapshot: "תמונת מצב",
      income: "הכנסות",
      income_monthly: "ניתוח הכנסות חודשי",
      income_history: "ניתוח היסטורי",
      expense: "הוצאות",
      expense_monthly: "ניתוח הוצאות חודשי",
      expense_history: "ניתוח היסטורי",
      cost: "עלויות",
      cost_monthly: "ניתוח עלויות חודשי",
      cost_history: "ניתוח עלויות היסטורי",
      accounting: "הנהלת חשבונות",
      accounting_reports: "דוחות הנהלת חשבונות",
      inventory: "מלאי",
      inventory_gaps: "פערי מלאי",
      inventory_manage: "ניהול מלאי",
      stocktaking: "ספירת מלאי",
      preparations: "ספירת בסיס והכנות",
      inventory_dump: "זריקות",
      inventory_transfer: "העברות",
      business: "הגדרות העסק",
      business_settings: "הגדרות עסקיות",
      business_employees: "ניהול עובדים",
      business_suppliers: "ניהול ספקים",
      items: "מוצרים",
      items_manage: "ניהול מוצרים",
      items_tree: "עץ מוצר",
      items_comparison: "השוואת מחירים",
      items_cost: "עלות מכר",
      items_category: "ניהול קטגוריות",
      data: "נתונים",
      data_analysis: "ניתוח נתונים",
      data_orders: "ניתוח הזמנות",
      reports: "הפקת דוחות",
      reports_employees: "דוחות עובדים",
      reports_inventory: "דוחות מלאי",
      reports_suppliers: "דוחות ספקים והזמנות",
      employees: "שיבוץ עובדים",
      employees_schedule: "שיבוץ משמרות",
      home: "דף הבית",
      recipes: "ספר מתכונים",
      employeesDetails: "פרטי עובדים",
    },
    micpal: {
      exportToMicpal: "ייצא למיכפל",
      exportToOketz: "ייצא לעוקץ",
      exportToShiklulit: "ייצא שיקלולית",
      exportToHargal: "ייצא להר-גל",
      IN: "כניסה",
      OT: "יציאה",
    },
    excel: {
      address: "כתובת",
      start_time: "שעת התחלה",
      end_time: "שעת סיום",
      date: "תאריך",
      cash: "טיפ מזומן",
      credit: "טיפ אשראי",
      allowance: "הפרשה למסעדה",
      totalAllowanceFromCredit: "הפרשה מאשראי",
      totalAllowanceFromCash: "הפרשה ממזומן",
      total_allowance_from_credit: "הפרשה מאשראי",
      total_allowance_from_cash: "הפרשה ממזומן",
      tip_hours: 'סה"כ שעות טיפ',
      complete: "השלמה לבסיס",
      employeeId: "מספר עובד",
      employee_id: "מזהה עובד",
      employee_number: "מספר עובד",
      employeeName: "שם עובד",
      enable: "סטטוס",
      employee_name: "שם עובד",
      shift_name: "משמרת",
      department_name: "מחלקה",
      eventRoleId: "מספר תפקיד",
      eventRole: "תפקיד",
      eventDate: "תאריך",
      dayStr: "יום",
      actualClockIn: "כניסה",
      actualClockOut: "יציאה",
      actualHours: 'סה"כ שעות',
      tipHours: "סה״כ",
      hourlyTip: "טיפ אחרי",
      total_tip: "טיפ כולל",
      totalBeforeAllowance: "לפני הפרשה",
      allowance_method_amount: "הפרשה",
      totalAllowance: "הפרשה",
      totalCashTipForEvent: "סה״כ מזומן",
      totalCardTipsForEvent: "סה״כ אשראי",
      minimumGap: "השלמה",
      totalTipForEvent: 'סה"כ טיפ',
      hourlyTipBruto: "טיפ לפני",
      relatedEventId: "מספר שיבוץ",
      break: "הפסקה",
      cost: "עלות",
      manual_start: "התחלה - מנהל",
      manual_end: "סיום - מנהל",
      actual_start: "שעת התחלה",
      actual_end: "שעת סיום",
      hourly_tip_before_allowance: "טיפ ברוטו לשעה",
      hourly_tip_after_allowance: "טיפ נטו לשעה ",
      hours_150_without_night: "שעות 150 ללא לילה",
      clock_start: "התחלה - שעון",
      clock_end: "סיום - שעון",
      planned_start: "התחלה - תכנון",
      planned_end: "סיום - תכנון",
      trim_start: "התחלה - חיתוך",
      trim_end: "סיום - חיתוך",
      hours_100: "100%",
      hours_125: "125%",
      hours_150: "150%",
      hours_week_100: "100% א-ה",
      hours_week_125: "125% א-ה",
      hours_week_150: "150% א-ה",
      hours_150_shabat: "שעות שבת",
      hours_in_holiday: "שעות חג",
      hours_in_holiday_hours_150_shabat: "שעות שבת + חג",
      total_base_salary: "סה״כ שכר בסיס",
      total_base_travel: "סה״כ נסיעות",
      total_base_holiday: "חופשה",
      total_base_health: "הבראה",
      base_travel_cost: "נסיעות",
      hours_175: "175%",
      hours_200: "200%",
      hours_total: 'סה"כ שעות',
      hours_100_waiter: "100% מלצור",
      hours_125_waiter: "125% מלצור",
      hours_150_waiter: "150% מלצור",
      hours_150_shabat_waiter: "שעות שבת מלצור",
      hours_in_holiday_waiter: "שעות חג מלצור",
      hours_175_waiter: "175% מלצור",
      hours_200_waiter: "200% מלצור",
      hours_total_waiter: 'סה"כ שעות מלצור',
      hours_total_both: 'סה"כ שעות עבודה',
      hours_100_no_waiter: "100%",
      hours_125_no_waiter: "125%",
      hours_150_no_waiter: "150%",
      hours_150_shabat_no_waiter: "שעות שבת",
      hours_in_holiday_no_waiter: "שעות חג",
      hours_175_no_waiter: "175%",
      hours_200_no_waiter: "200%",
      hours_total_no_waiter: 'סה"כ שעות',
      bonus_note: "הערות בונוס/מפרעה",
      hours_night: "שעות לילה",
      hours_150_night: "שעות לילה ",
      bonus_amount: "בונוס",
      bonus: "בונוס",
      on_account: "מפרעה",
      role_name: "תפקיד",
      week_day: "יום",
      working_date: "תאריך",
      anomalies: "סיבות חריגה",
      cost_raw: "שכר",
      cost_raw_no_travel: "שכר ללא נסיעות",
      raw_cost_tip_min: "שכר לפי טיפ מינימום",
      is_neto_salary: "סוג שכר",
      meal_cost: "אוכל עובדים",
      health_cost: "דמי הבראה",
      holiday_cost: "תעריף חופשה ",
      global_cost: "תעריף שעות נוספות גלובלית ",
      travel_cost: "עלות נסיעות",
      working_days: "ימי עבודה",
      actual_working_days: "ימי עבודה בפועל",
      actual_working_days_waiter: "ימי עבודה מלצור",
      global_salary: "משכורת גלובלית",
      salary: "משכורת",
      name: "שם",
      category: "קטגוריה",
      type_string: "סוג",
      employee_accountancy_number: "מס' עובד במע' שכר",
      current_salary_type: "סוג עלות",
      tip_bruto_and_bonus: 'סה"כ טיפ כשכר',
      tip_bonus: "יתרת טיפ בונוס",
      tips_allowance: "הפרשה למסעדה",
      tips_cash: "טיפ מזומן",
      tips_complete: "השלמה לבסיס",
      tips_credit: "טיפ באשראי",
      tips_total: 'סה"כ טיפ',
      employee_first_name: "שם פרטי",
      employee_last_name: "שם משפחה",
      branch_name: "סניף",
      status: "סטטוס",
      total_incentive_hours: 'תמריץ אמצ"ש',
      total_without_vat: 'סכום ללא מע"מ',
      total_with_vat: "סכום",
      employer_cost_factor_only: "עלות מעביד",
      employer_cost_and_salary: "שכר כולל עלות מעביד",
      hebDay: "יום בשבוע",
      total_doc_without_vat: 'סה"כ',
      total_with_vat_hidden: 'סה"כ כולל מע"מ',
      vacation: "ימי חופש",
      vacation_for_paid: "ימי חופשה לקיזוז",
      sick: "ימי מחלה",
      sick_for_paid: "ימי מחלה לתשלום",
      lack_of_standard_days: "ימי חוסר תקן",
      reserve: "ימי מילואים",
      bank_details: "פרטי בנק",
      notes: "מידע נוסף מכרטיס עובד",
      total_items_without_vat: 'סה"כ מוצרים',
      received_by_user_name: 'נקלט ע"י',
      received_id: "מס' קליטה",
      document_number: "מס' מסמך",
      document_type: "סוג מסמך",
      document_date: "תאריך מסמך",
      created_at: "תאריך קליטה",
      supplier_name: "ספק",
      total_including_tips: "שכר כולל טיפים",
      employer_pension: "פנסיה מעביד",
      employer_compensation: "פיצויים מעביד",
      employer_social_security: "ביטוח לאומי מעביד",
      employee_pension: "פנסיה עובד",
      employee_social_security: "ביטוח לאומי עובד",
      employee_irs: "מס הכנסה",
      bruto_salary: "שכר ברוטו",
      due_date: "תאריך אספקה מבוקש",
      sent_at: "תאריך הזמנה",
      neto_for_payment: "נטו לתשלום",
      cost_raw_tips_credit: "שכר + טיפ באשראי",
      hours_125_hours_175: "125% + 175%",
      hours_150_hours_200: "150% + 200%",
      hours_shabat_holiday_175_200: "שעות שבת + חג + נוספת",
      hours_total_minus_break: "סה״כ שעות בניכוי הפסקות",
      total_including_tips_minus_travel: "שכר כולל טיפים בניכוי נסיעות",
      social_hours: "שעות סוציאליות",
      irregular_hours: "ש.חוסר תקן",
      regular_hours: "שעות תקן",
      no_offset_hours: "ש.חוסר לקיזוז",
      social_hours_waiter: "שעות סוציאליות מלצור",
      social_hours_no_waiter: "שעות סוציאליות",
      destination_branch_name: "סניף קולט",
      source_branch_name: "סניף מעביר",
      notes: "הערות",
      daily_notes: "הערות יומיות",
      is_salary: "מוגדרת שכר",
      is_travel_cost: "מוגדרת עלות נסיעות",
      order_id: "מספר הזמנה",
      salary_type: "סוג עלות",
      send_by_user_name: 'נשלח ע"י',
      tip_per_hour_bruto: "טיפ לשעה (ברוטו)",
      total_tips: "סה״כ טיפ נטו",
      origin_department_name: "מחלקה",
      tip_per_hour_neto: "טיפ לשעה (נטו)",
      total_tip: 'סה"כ טיפ נטו',
      total_tip_before_allowance: 'סה"כ טיפ ברוטו',
      waiting_minutes: 'סה"כ דקות המתנה',
      waiting_salary: "תשלום המתנה",
      allowance_percentage: "% הפרשה",
      base_salary: "תעריף שכר יסוד",
      extra_shabat_salary: "תעריף שעות נוספות בשבת",
      credit_before_allowance: "טיפ אשראי לפני הפרשה",
      cash_before_allowance: "טיפ מזומן לפני הפרשה",
      credit_tip_before_allowance: "טיפ אשראי לפני הפרשה",
      cash_tip_before_allowance: "טיפ מזומן לפני הפרשה",
      tips_total_before_allowance: 'סה"כ טיפ ברוטו',
      total_tip_calc: "חישוב טיפים",
      tip_days: "ימי טיפ",
      amount_without_tax: 'Z ללא מע"מ',
      amount_with_tax: 'Z עם מע"מ',
      percent: "אחוזים",
      type: "סוג הכנסה",
      z_number: "מספר Z",
      dayIdx: "יום",
      diners: "סועדים",
      diners_avg: "ממוצע לסועד",
      extra_with_vat: "נוספות כולל מע״מ",
      extra_without_vat: "נוספות ללא מע״מ",
      transaction_date: "תאריך סגירת Z",
      expected_end_period: "צפי סוף תקופה",
      cumulative_last_year: "מצטבר שנה שעברה",
      income_precent: "אחוז מהכנסה",
      income_precent_last_year: "אחוז מהכנסה שנה שעברה",
      income_percent: "אחוז מהכנסה",
      goal: "יעד",
      part_number: "מק״ט",
      total_purchase: "רכ״ש",
      total_amount: "כמות",
      item_scale: "יחידת מידה",
      item_name: "שם מוצר",
      ordered_amount: "כמות שהוזמנה",
      received_amount: "כמות שנקלטה",
      received_amount_sum: "כמות שנקלטה",
      total_ordered_without_vat: " מחיר בהזמנה ללא מע״מ",
      day: {
        0: "ראשון",
        1: "שני",
        2: "שלישי",
        3: "רביעי",
        4: "חמישי",
        5: "שישי",
        6: "שבת",
      },
      full_name: "שם עובד",
      job_title: "תפקיד",
      privilege_title: "הרשאה",
      identifier: "ת.ז / דרכון",
      employee_identifier: "תעודת זהות",
      hebrew_date_of_birth: "תאריך לידה",
      age: "גיל",
      mobileNumber: "טלפון",
      email: "כתובת מייל",
      hebrew_create_date: "תאריך הקמה",
      number: "מספר עובד",
      last_submission: "תאריך הגשה אחרון",
      form_101_date_time: "טופס 101",
      work_agreement_date_time: "הסכם עבודה",
      sexual_harassment_date_time: "תקנון למניעת הטרות מיניות",
      employee_notification_date_time: "אישור נגישות",
      new_received_order_id: "מספר קליטה",
      new_order_id: "מספר הזמנה",
      refund_type: "סוג",
      amount: "כמות",
      price: "מחיר",
      total: "סה''כ",
      firstName: "שם פרטי",
      lastName: "שם משפחה",
      accountancyTable: {
        supplier_name: "ספק",
        count_open_orders: "כמות הזמנות פתוחות",
        count_shipping_refunds_not_concentrator: "כמות ת.משלוח והחזרה פתוחות",
        total_invoice_count: "כמות חשבוניות פתוחות",
        vat: 'מע"מ',
        monthly_obligo: "אובליגו",
        total_with_vat: ' רכש עם מע"מ',
        total_without_vat: ' רכש ללא מע"מ',
        total_invoice_with_vat: ' חשבוניות עם מע"מ',
        total_invoice_without_vat: ' חשבוניות ללא מע"מ',
        total_open_refunds_with_vat: ' זיכויים פתוחים עם מע"מ',
        total_open_refunds_without_vat: ' זיכויים פתוחים ללא מע"מ',
        total_close_refunds_with_vat: ' זיכויים שנקלטו עם מע"מ',
        total_close_refunds_without_vat: ' זיכויים שנקלטו ללא מע"מ',
        total_paid_with_vat: ' חשבוניות כולל מע"מ בסטטוס שולם',
        total_paid_without_vat: ' חשבוניות ללא מע"מ בסטטוס שולם',
        total_redeemed_with_vat: ' חשבוניות כולל מע"מ בסטטוס נפרע',
        total_redeemed_without_vat: ' חשבוניות ללא מע"מ בסטטוס נפרע',
        total_shipping_refunds_not_concentrator_with_vat:
          ' ת.משלוח + ת.החזרה בסטטוס פתוח עם מע"מ ',
        total_shipping_refunds_not_concentrator_without_vat:
          ' ת.משלוח + ת.החזרה בסטטוס פתוח בלי מע"מ',
        total_open_orders_with_vat: ` הזמנות פתוחות ללא מע"מ`,
        total_open_orders_without_vat: ` הזמנות פתוחות עם מע"מ`,
        category: "קטגוריה",
        payment_condition: "תנאי תשלום",
        balance: "יתרת זכות",
        total_paid_and_refunds_with_vat:
          "סה״כ כולל מע״מ בניכוי זיכויים וחשבוניות ששולמו/נפרעו",
      },
      print_checks: {
        date: "תאריך תשלום",
        number_certificate: "מס' צ'ק",
        in_honor_of: "לכבוד",
        total_with_vat: "סכום תשלום",
        status_string: "סטטוס",
      },
      NewReceivedOrdersPreview: {
        branch_name: "סניף",
        supplier_name: "ספק",
        document_type_string: "סוג מסמך",
        document_number: "מס' מסמך",
        document_date_string: "תאריך מסמך",
        total_without_vat: 'סה"כ',
      },
      incomeExtra: {
        id: "מספר הכנסה",
        date: "תאריך",
        branch_name: "סניף",
        total_without_vat: 'סה"כ ללא מע"מ',
        total_with_vat: 'סה"כ כולל מע"מ',
        type_name: "סוג הכנסה",
        diners: "סועדים",
        diners_average: "ממוצע לסועד",
        updater: 'עודכן ע"י',
      },
      newIncomes: {
        hebrew_date: "תאריך",
        day: "יום",
        z_numbers: "מס' Z",
        z_without_vat: "Z ללא מע״מ",
        extra_without_vat: "נוספות ללא מע״מ",
        total_without_vat: "סה״כ ללא מע״מ",
        z_with_vat: "Z כולל מע״מ",
        extra_with_vat: "נוספות כולל מע״מ",
        total_with_vat: "סה״כ כולל מע״מ",
        diners: "סועדים",
        diners_avg: "ממוצע לסועד",
        total_with_vat_after_fees: "סה״כ אחרי עמלות",
        fees: "עמלות",
        goal: "יעד",
      },
      NewOrdersPreview: {
        branch_name: "סניף",
        supplier_name: "ספק",
        total_without_vat: "סכום",
        sent_at: "תאריך שליחה",
        id: "מס' הזמנה",
        refund_price: "סכום זיכוי",
        created_at: "תאריך שליחה",
      },
      constExpenses: {
        name: "שם הוצאה",
        total: "סכום",
        income_percent: "אחוז מהכנסה",
      },
      foodCostTable: {
        item_name: "שם מוצר",
        part_number: "מק״ט",
        supplier_name: "ספק",
        total_without_vat: "רכש",
        total_amount: "כמות",
        item_scale: "יחידת מידה",
        income_percent: "אחוז מהכנסה",
        category_name: "קטגוריה",
      },
      purchaseSuppliers: {
        supplier_name: "שם ספק",
        branch_name: "שם סניף",
        total_sent_without_vat: "סך הכל הזמנות שנשלחו ללא מע״מ",
        total_received_without_vat: "סך הכל הזמנות שנקלטו ללא מע״מ",
        total_received_with_vat: "סך הכל הזמנות עם מע״מ",
        total_sent_with_vat: "סך הכל הזמנות עם מע״מ",
        markup_sent: "מארקפ נשלחו",
        markup_received: "מארקפ נקלטו",
        income_precent_sent: "אחוז מהכנסה נשלחו",
        income_precent_received: "אחוז מהכנסה נקלטו",
        order_id: "מספר הזמנה",
        item_amount: "כמות מוצרים",
        purchase_precent_received: "אחוז מרכש",
        purchase_precent_sent: "אחוז מרכש",
        purchase_precent: "אחוז מרכש",
        item_name: "שם מוצר",
        part_number: "מק״ט",
        file_number: "מספר הזמנה",
      },

      dataAnalyze: {
        branch_name: "סניף",
        total_income: "הכנסות ללא מע״מ",
        total_income_vat: "הכנסות כולל מע״מ",
        total_combined_food_cost: "פודקוסט",
        operational_cost_received: "הוצאות תפעול",
        management_cost_received: "הנהלה וכלליות",
        employee_cost: "הוצאות עובדים",
        funding_cost_received: "מימון",
        profit: "רווח",
      },

      ordersByDate: {
        date: "תאריך",
        week_day: "יום בשבוע",
        total_without_vat: "סכום",
        income_percent: "אחוז מהכנסה כוללת",
        document_type: "סוג מסמך",
        document_number: "מספר מסמך",
        supplier_name: "ספק",
      },
      foodCostByItemTable: {
        name: "שם מוצר",
        catalog_number: "מק״ט",
        supplier: "ספק",
        ordered_amount: "כמות בהזמנה",
        received_amount: "כמות שנקלטה",
        received_amount_sum: "כמות שנקלטה",
        ordered_price: "מחיר בהזמנה",
        total_ordered_without_vat: "סך הכל מחיר בהזמנה",
        received_price: "מחיר בקליטה",
        total_without_vat: "סה״כ מחיר בקליטה",
        document_date: "תאריך",
        document_number: "מס מסמך",
      },
      accountancyReceivedTable: {
        branch_name: "סניף",
        supplier_name: "ספק",
        open_refunds_orders_total: "זיכויים בסטטוס פתוח",

        payment_condition: "תנאי לתשלום",
        payment_type: "אמצעי תשלום",
        document_type_string: "סוג מסמך",
        document_number: "מס' מסמך",
        notes_payment_status_string: "הערות",
        document_date_string: "תאריך מסמך",
        total_without_vat: 'סה"כ',
        total_with_vat: 'סה"כ כולל מע"מ',
        price_gap: "פערי מחיר",
        payment_status_string: "סטטוס מסמך",
        category: "קטגוריה",
        dateToPay: "תאריך לתשלום",
        vat: 'מע"מ',
        balance: "יתרת זכות",
        total_paid_and_refunds_with_vat:
          "סה״כ כולל מע״מ בניכוי זיכויים וחשבוניות ששולמו/נפרעו",
      },
      pull_transfer: {
        worth: "שווי",
        branch_name: "מסניף",
        to_branch_name: "לסניף",
        type_string: "סוג",
        id: "מספר העברה",
        amount: "כמות",
        category_name: "קטגוריה",
        manufacturer_number: "ברקוד יצרן",
        date: "תאריך הזנה",
        part_number: "מק״ט",
        item_name: "שם מוצר",
      },
      incomeComparison: {
        dayIdx: "יום בשבוע",
        name: "יום בשבוע",
        branch_name: "סניף",
        total_without_vat: 'ללא מע"מ',
        total_with_vat: 'כולל מע"מ',
        daily_avg_without_vat: 'ללא מע"מ',
        daily_avg_with_vat: 'כולל מע"מ',
        diners: 'סה"כ סועדים',
        diners_avg_without_vat: 'ממוצע ללא מע"מ',
        diners_avg_with_vat: 'ממוצע כולל מע"מ',
        percent_from_total: "% מהכנסה",
      },
      newItemsTable: {
        name: "שם",
        part_number: "מק״ט",
        supplier_name: "ספק",
        category_name: "קטגוריה",
        count_type: "צורת ספירת מלאי",
        internal_catalog_number: "מק״ט פנימי",
        manufacturer_number: "ברקוד יצרן",
        discount: "הנחה",
        price: "מחיר",
        deposit: "פיקדון",
        tax: "מיסים",
        credit: "חשבון חובה",
        debit: "חשבון זכות",
        has_tax: "מע״מ",
        updater_full_name: 'עודכן ע"י',
        posCodeLabel: "קישור למוצר",
        sell_price_vat: "מחיר מכירה",
      },
      priceGapsTable: {
        item_name: "שם",
        part_number: "מק״ט",
        supplier_name: "ספק",
        category_name: "קטגוריה",
        old_value: "מחיר לפני שינוי",
        new_value: "מחיר לאחר שינוי",
        gap: "פער",
        document: "מסמך",
        price_change_reason: "סיבת השינוי",
        updater_name: "גורם מעדכן",
        date: "תאריך",
        time: "שעה",
      },
      productsTable: {
        name: "שם",
        category_name: "קטגוריה",
        quantity: "כמות",
        food_cost: "פוד קוסט",
        sale_price: "מחיר מכירה",
        unit_cost_with_VAT: "עלות ליחידה",
        unit_cost_without_VAT: "עלות ליחידה ללא מע״מ",
        sold_dish_code_label: "מוצר מקושר",
        updater: 'עודכן ע"י',
        price_without: "מ. מכירה ללא מע״מ",
      },
      productsIngredientsTable: {
        type: "סוג",
        name: "שם",
        quantity: "כמות",
        lost: "פחת",
        worth_without_vat: "עלות ללא מע״מ",
        worth_with_VAT: "עלות כולל מע״מ",
      },
      unifiedTable: {
        name: "שם",
        inventory: "כמות",
        price: "מחיר",
        category_name: "קטגוריה",
        box_type: "צורת מארז",
        updater: 'עודכן ע"י',
      },
      unifiedIngredientsTable: {
        lead: "מוביל",
        name: "שם",
        inventory: "צורת מארז",
        product_tree_lost_percent: "פחת",
        price: "מחיר למארז",
        unit_cost: "מחיר ליחידה",
      },
      notOrderedItemsTable: {
        name: "שם",
        description: "צורת מארז",
        part_number: "מק״ט",
        price: "מחיר",
        category_name: "קטגורייה",
        manufacturer_number: "ברקוד",
        amount_sent_by_units: "כמות שנשלחה ביחידות",
        amount_received_by_units: "כמות שנקלטה ביחידות",
        supplier_name: "ספק",
        lastSentDate: "תאריך שליחת הזמנה אחרון",
      },
      itemsTable: {
        name: "מוצר",
        category: "קטגוריה",
        category_name: "קטגורייה",
        manufacturer_number: "ברקוד",
        amount_sent_by_units: "כמות שנשלחה ביחידות",
        amount_received_by_units: "כמות שנקלטה ביחידות",
        network_markup_not_recieved: "מארקפ",
        catalog_number: 'מק"ט',
        description: "תיאור מארז",
        supplier_name: "ספק",
        received_amount: "כמות שנקלטה",
        received_amount_sum: "כמות שנקלטה",
        ordered_amount: "כמות שהוזמנה",
        amount: "כמות שהוזמנה",
        avg_price: "מחיר ממוצע",
        ordered_percentage_of_income: "אחוז מהכנסה",
        received_percentage_of_income: "אחוז מהכנסה",
        payment_condition: "תנאי תשלום",
        total_received_without_vat_percentage: "אחוז מרכש",
        total_ordered_without_vat_percentage: "אחוז מרכש",
        current_price: "מחיר נוכחי",
        current_price_with_vat: 'מחיר נוכחי כולל מע"מ',
        total_received_without_vat: 'סה"כ נקלטו ללא מע"מ',
        total_price_gap: 'סה"כ פערי מחיר',
        per_unit_price: "מחיר ליח׳ מידה",
        price_gap: 'סה"כ פערי מחיר',
        total_received_refunds: 'סה"כ זיכויים',
        deposit: "פיקדון",
        total_deposit: 'סה"כ פיקדון',
        total_ordered_without_vat: 'סה"כ הוזמנו ללא מע"מ',
        total_ordered_with_vat: 'סה"כ הוזמנו עם מע"מ',
        total_without_vat: 'סה"כ הוזמנו ללא מע"מ',
        total_with_vat: 'סה"כ הוזמנו עם מע"מ',
        total_received_with_vat: 'סה"כ נקלטו עם מע"מ',
        branch_name: "סניף",
        document_date: "תאריך",
        sent_at: "תאריך",
        due_date: "תאריך אספקה",
        payment_type: "תנאי תשלום",
        document_number: "מס' מסמך",
        week_day: "יום בשבוע",
        sent_date: 'סה"כ נקלטו עם מע"מ',
        received_price: "מחיר בקליטה",
        ordered_price: "מחיר בהזמנה",
        order_price: "מחיר בהזמנה",
        received_refunds: "זיכויים",
        new_received_order_id: "מס' קליטה",
        new_order_id: "מס' הזמנה",
        discount: "הנחה %",
        network_markup: "מרקאפ",
        markup: "מרקאפ",
        total_network_price: "מחיר רשת",
        network_price: "מחיר רשת",
        catalog_price: "מחיר קטלוג",
        month_1_received_price: "מחיר נטו ינואר",
        month_2_received_price: "מחיר נטו פברואר",
        month_3_received_price: "מחיר נטו מרץ",
        month_4_received_price: "מחיר נטו אפריל",
        month_5_received_price: "מחיר נטו מאי",
        month_6_received_price: "מחיר נטו יוני",
        month_7_received_price: "מחיר נטו יולי",
        month_8_received_price: "מחיר נטו אוגוסט",
        month_9_received_price: "מחיר נטו ספטמבר",
        month_10_received_price: "מחיר נטו אוקטובר",
        month_11_received_price: "מחיר נטו נובמבר",
        month_12_received_price: "מחיר נטו דצמבר",
        month_1_network_price: "מחיר רשת ינואר",
        month_2_network_price: "מחיר רשת פברואר",
        month_3_network_price: "מחיר רשת מרץ",
        month_4_network_price: "מחיר רשת אפריל",
        month_5_network_price: "מחיר רשת מאי",
        month_6_network_price: "מחיר רשת יוני",
        month_7_network_price: "מחיר רשת יולי",
        month_8_network_price: "מחיר רשת אוגוסט",
        month_9_network_price: "מחיר רשת ספטמבר",
        month_10_network_price: "מחיר רשת אוקטובר",
        month_11_network_price: "מחיר רשת נובמבר",
        month_12_network_price: "מחיר רשת דצמבר",
        month_12_avg_price: "מחיר ממוצע דצמבר",
        month_11_avg_price: "מחיר ממוצע נובמבר",
        month_10_avg_price: "מחיר ממוצע אוקטובר",
        month_9_avg_price: "מחיר ממוצע ספטמבר",
        month_8_avg_price: "מחיר ממוצע אוגוסט",
        month_7_avg_price: "מחיר ממוצע יולי",
        month_6_avg_price: "מחיר ממוצע יוני",
        month_5_avg_price: "מחיר ממוצע מאי",
        month_4_avg_price: "מחיר ממוצע אפריל",
        month_3_avg_price: "מחיר ממוצע מרץ",
        month_2_avg_price: "מחיר ממוצע פברואר",
        month_1_avg_price: "מחיר ממוצע ינואר",
        month_12_amount_for_avg: "כמות דצמבר",
        month_11_amount_for_avg: "כמות נובמבר",
        month_10_amount_for_avg: "כמות אוקטובר",
        month_9_amount_for_avg: "כמות ספטמבר",
        month_8_amount_for_avg: "כמות אוגוסט",
        month_7_amount_for_avg: "כמות יולי",
        month_6_amount_for_avg: "כמות יוני",
        month_5_amount_for_avg: "כמות מאי",
        month_4_amount_for_avg: "כמות אפריל",
        month_3_amount_for_avg: "כמות מרץ",
        month_2_amount_for_avg: "כמות פברואר",
        month_1_amount_for_avg: "כמות ינואר",
      },

      accountancyReceivedItemsTable: {
        name: "מוצר",
        catalog_number: 'מק"ט',
        amount: "כמות שנקלטה",
        ordered_amount: "כמות שהוזמנה",
        total_received_without_vat: 'סה"כ נקלטו ללא מע"מ',
        price_gap: "פערי מחיר",
        total_ordered_without_vat: 'סה"כ הוזמנו ללא מע"מ',
        document_date: "תאריך",
        document_number: "מס' מסמך",
        document_type_string: "סוג מסמך",
        price: "מחיר בקליטה",
        ordered_price: "מחיר בהזמנה",
        notes: "הערות",
      },
      transferItemsTable: {
        name: "מוצר",
        catalog_number: 'מק"ט',
        supplier_name: "ספק",
        branch_name: "מסניף",
        to_branch_name: "לסניף",
        date: "תאריך",
        worth: "שווי",
        amount: "כמות",
        created_by: 'בוצע ע"י',
        updated_by: 'עודכן ע"י',
        type_name: "סוג מלאי",
        new_inventory_id: "מספר תנועה",
        package_amount: "כמות מארז",
        unit_amount: "כמות יחידה",
        weight_amount: "כמות שקיל",
        scale: "מארז",
      },
      expenseDashboardDatesDepartments: {
        date: "תאריך",
        hebrewDate: "תאריך",
        hebrewDateName: "יום",
        hours_100: "שעות 100",
        hours_125: "שעות 125",
        hours_150: "שעות 150",
        hours_150_shabat: "שעות שבת/חג",
        department_name: "שם מחלקה",
        hours_175: "שעות 175",
        hours_200: "שעות 200",
        hours_total: "סה״כ שעות",
        travel_cost: "עלות נסיעות",
        meal_cost: "עלות אוכל",
        tip_complete_cost: "עלות טיפ",
        bonus_cost: "עלות בונוס",
        total_cost_before_employer_cost: "עלות לפני עלות מעביד",
        employer_cost: "עלות מעביד",
        total_cost_after_employer_cost: "עלות אחרי עלות מעביד",
        employee_name: "שם העובד/ת",
        total_precent: "אחוז מהכנסה",
        navg: "יעד ב₪",
        avg: "יעד ב%",
        health_cost: "דמי הבראה",
        holiday_cost: "תעריף חופשה",
        global_cost: "תעריף שעות נוספות גלובלית",
        salary: "תעריף ",
        salary_type: "סוג שכר ",
        full_name: "שם מלא ",
      },
      smsTable: {
        created_at: "תאריך שליחה",
        sent_by_name: "גורם שולח",
        employee_name: "גורם מקבל",
        content: "פירוט",
        branch_name: "סניף",
      },
      smsRequest: {
        branch_id: "מס' סניף",
        amount: "כמות מבוקשת",
        branch_name: "סניף",
        created_at: "תאריך בקשה",
        requested_by_user: "מבקש",
        status_name: "סטטוס בקשה",
        updated_by_user: 'עודכן ע"י',
      },
      inventoriesTable: {
        id: "מספר",
        type_string: "סוג מלאי",
        branch_name: "מסניף",
        to_branch_name: "לסניף",
        date: "תאריך הזנה",
        month: "חודש",
        worth: "שווי",
        creator: 'בוצע ע"י',
        updater: 'עודכן ע"י',
      },

      newInventoryItemsTable: {
        item_name: "מוצר",
        part_number: "מקט",
        category: "קטגוריה",
        supplier_name: "ספק",
        current_column: "מלאי נוכחי",
        count_difference: "הפרש מלאי",
        opening_column: "פתיחה",
        shopping_column: "רכישות",
        transfers_in: "העברות פנימה",
        closing_column: "סגירה",
        used_column: "נצרך בתקופה",
        transfers_out: "העברות החוצה",
        actual_use_column: "שימוש בפועל",
        expenditure_column: "הוצאה מהמלאי",
        difference_percent_column: "הפרש באחוזים",
        throw_column: "זריקות/פחת",
        expected_closing_column: "מ.סגירה צפוי",
        difference_column: "הפרש",
        price_after_deduction: "מחיר",
        inventory_worth: "שווי מלאי",
      },
      newInventoryItemsCustomExcel: {
        item_name: "מוצר",
        part_number: "מקט",
        category: "קטגוריה",
        supplier_name: "ספק",
        current_column: "מלאי נוכחי - שווי",
        count_difference: "הפרש מלאי - שווי",
        opening_column: "פתיחה - שווי",
        shopping_column: "רכישות - שווי",
        transfers_in: "העברות פנימה - שווי",
        closing_column: "סגירה - שווי",
        used_column: "נצרך בתקופה - שווי",
        transfers_out: "העברות החוצה - שווי",
        actual_use_column: "שימוש בפועל - שווי",
        expenditure_column: "הוצאה מהמלאי - שווי",
        difference_percent_column: "הפרש באחוזים",
        throw_column: "זריקות/פחת - שווי",
        expected_closing_column: "מ.סגירה צפוי - שווי",
        difference_column: "הפרש - שווי",
        current_column_amount: "מלאי נוכחי - כמות",
        opening_column_amount: "פתיחה - כמות",
        shopping_column_amount: "רכישות - כמות",
        transfers_in_amount: "העברות פנימה - כמות",
        closing_column_amount: "סגירה - כמות",
        used_column_amount: "נצרך בתקופה - כמות",
        transfers_out_amount: "העברות החוצה - כמות",
        actual_use_column_amount: "שימוש בפועל - כמות",
        expenditure_column_amount: "הוצאה מהמלאי - כמות",
        difference_column_amount: "הפרש - כמות",
        throw_column_amount: "זריקות/פחת - כמות",
        expected_closing_column_amount: "מ.סגירה צפוי - כמות",
      },
      newInventoryExcel: {
        inventory_type: "סוג תנועה מלאי",
        to_branch_name: "סניף קולט",
        from_branch_name: "סניף מעביר",
        worth: "שווי",
        scale: "צורת ספירה",
        branch_name: "משוייך לסניף",
        date: "תאריך",
        item_name: "מוצר",
        part_number: "מקט",
        category: "קטגוריה",
        supplier_name: "ספק",
        current_column: "מלאי נוכחי",
        count_difference: "הפרש מלאי",
        opening_column: "פתיחה",
        shopping_column: "רכישות",
        transfers_in: "העברות פנימה",
        closing_column: "סגירה",
        used_column: "נצרך בתקופה",
        transfers_out: "העברות החוצה",
        actual_use_column: "שימוש בפועל",
        expenditure_column: "הוצאה מהמלאי",
        difference_percent_column: "הפרש באחוזים",
        throw_column: "זריקות/פחת",
        expected_closing_column: "מ.סגירה צפוי",
        difference_column: "הפרש",
        amount: "כמות",
      },

      inventoryDashboardItemsTable: {
        name: "מוצר",
        catalog_number: "מקט",
        category_name: "קטגוריה",
        supplier_name: "ספק",
        current_count: "מלאי נוכחי",
        count_difference: "הפרש מלאי",
        open: "פתיחה",
        shopping: "רכישות",
        transfers_in: "העברות פנימה",
        close: "סגירה",
        consumed: "נצרך בתקופה",
        transfers_out: "העברות החוצה",
        sales: "שימוש בפועל",
        expenditures: "הוצאה מהמלאי",
        lost_gap_percent: "הפרש באחוזים",
        throws: "זריקות/פחת",
        expected_close_worth: "מ.סגירה צפוי",
        close_worth_gap: "הפרש מ.סגירה",
        quantitative_difference: "הפרש",
        precent_diff: "הפרש ב%",
        count_scales: "צורת ספירת מלאי",
      },
      SalesCostTables: {
        product_code: "קוד קופה",
        name: "שם",
        link: "מוצר מקושר",
        link_name: "מוצר מקושר",
        link_sale_price: "מחיר מכירה מוצר מקושר",
        link_price: "מחיר מוצר מקושר",
        link_supplier_name: "ספק",
        link_part_number: "מק״ט",
        manufacturer_number: "ברקוד יצרן",
        amount: "כמות שנמכרה",
        total_with_vat: "מכירות כולל מע״מ",
        avg_price: "מחיר מנה",
        worth: "עלות רכיבים",
        avg_foodcost: "foodcost",
        branch_name: "סניף",
        worth_with_vat: "עלות רכיבים כולל מע״מ",
        sale_predict: "צפי סוף תקופה",
        foodcost_goal: "יעד foodcost",
        date: "תאריך",
        day: "יום",
        part_number: "מק״ט",
        manufacturer_number: "ברקוד יצרן",
        price_with_vat: "מחיר כולל מע״מ",
        supplier_name: "ספק",
        scale_amount: "כמות",
        package_amount: "יחידות מידה במארז",
        price_with_vat: "מחיר כולל מע״מ",
        actual_use: "כמות שנצרכה",
        thousand_use: "צריכה ל-1000",
        four_thousand_use: "צריכה ל-4000",
      },
      manageSuppliersTable: {
        name: "שם",
        contact: "איש קשר",
        order_method: "אופן שליחת הזמנות",
        mobileNumber: "טלפון",
        email: "דוא״ל",
        supplier_arrival: "ימי אספקה",
        doc_type: "סוג מסמך",
        credit: "חשבון זכות",
        debit: "חשבון חובה",
        license: "ח.פ",
        max_open_orders: "מקסימום הזמנות פתוחות",
        min_order: "מינימום להזמנה",
        monthly_obligo: "אובליגו",
      },
      warehouses: {
        name: "שם מחסן",
        branch_name: "סניף",
        total_count: "מצורים משוייכים",
        category_name: "סיווג מחסנים",
      },
      warehouseCatalog: {
        name: "שם מוצר",
        supplier_name: "ספק",
        count_type: "צורת ספירת מלאי",
        enable: "פעיל",
      },
      warehouseProduct: {
        name: "שם מוצר",
        scale: "סוג",
        category_name: "קטגוריה",
        enable: "פעיל",
      },
      requisition: {
        name: "שם מוצר",
        supplier_name: "ספק",
        count_type: "צורת ספירת מלאי",
        enable: "פעיל",
        sunday: "א'",
        monday: "ב'",
        tuesday: "ג'",
        wednesday: "ד'",
        thursday: "ה'",
        friday: "ו'",
        saturday: "ש'",
        totalPrice: "שווי",
        totalCounter: "מוצרים",
        percent: "אחוז מהכנסה",
      },
      requisitionManagement: {
        id: "מס' דרישה",
        branch_name: "סניף",
        start_date: "טווח תאריכים",
        user: "נשלח ע''י",
        created_at: "נוצר ב",
        items_count: "מס' מוצרים",
        worth: "סכום",
        status: "סטטוס",
      },
      requisitionByItem: {
        item_name: "שם מוצר",
        description: "תצורת מארז",
        total_branches: "כמות סניפים",
        total_items: "כמות",
      },
      difference: "הפרש",
      amount_deposit: "סכום הפקדה",
      amount_with_vat: "מזומן כולל מע״מ",
      details: "פרטים",
      newUsersTable: {
        firstName: "שם העובד",
        lastName: "שם משפחה",
        identifier: "ת.ז/דרכון",
        number: "מספר עובד",
        address: "כתובת",
        mobileNumber: "טלפון",
        email: "מייל",
        date_of_birth: "תאריך לידה",
        travel_cost: "נסיעות",
        employer_cost: "ע.מעביד",
        branch_name: "סניף",
        job_title: "מחלקה",
        privilege_name: "הרשאה",
        micpal_number: "מספר עובד במערכת שכר",
        img: "תמונה",
        salary_type: "שכר",
        salary_type_info: "סוג שכר",
      },
      pettyCashTable: {
        shift_name: "משמרת",
        branch_name: "סניף",
        date: "תאריך",
        difference: "הפרש",
        received_orders_sum: "חשבוניות כולל מע״מ",
        plot: "פריטה בקופה",
        cash: "שטרות קופה קטנה",
        coins: "מטבעות קופה קטנה",
        non_accepted_from_deposit: "חשבוניות שלא אושרי",
        non_accepted_received_orders_sum: "חשבוניות לאישור",
        branch_amount: "קרן קופה קטנה",
        current_balance: "יתרה למשיכה",
        sum: "סכום",
        updated_by: "עודכן ע״י",
      },

      tip_logs: {
        updated_at: "תאריך עדכון",
        cash_old_value: "ערך ישן מזומן",
        cash_new_value: "ערך חדש מזומן",
        credit_old_value: "ערך ישן אשראי",
        credit_new_value: "ערך חדש אשראי",
        updater_user_name: "גורם מעדכן",
      },
      CRMtable: {
        updated_at: "עודכן בתאריך",
        admin_name: "נציג",
        ticket_type: "סיווג",
        network_name: "שם רשת",
        branch_name: "שם סניף",
        details: "פרטים",
        ticket_status: "סטטוס",
        user: {
          mobileNumber: "מספר טלפון",
          email: "אימייל",
          firstName: "שם לקוח",
        },
        subject: "נושא",
        comment: "הערות",
        created_admin: {
          name: "פותח פניה",
        },
        updater_admin: {
          name: "מעדכן פניה",
        },
      },
      addition_info: {
        update_by: "נוצר ע״י",
        update_type: "פעולה",
        created_at: "תאריך ושעה",
        old_value: "ערך ישן",
        new_value: "ערך חדש",
      },
    },
    pointOfSales: {
      income: "הכנסות",
      products: "פריטי מכירה",
      employees: "עובדים",
      real_time: "זמן אמת",
      get_employees: "שיתוף עובדים",
      restigoIncomeObject: {
        z_number: "מספר Z",
        start_date: "מתאריך",
        end_date: "עד תאריך",
        transaction_date: "תאריך ערך",
        channels: "פירוק לערוצי מכירה",
        elements: "פירוק לאמצעי תשלום",
        shifts: "פירוק למשמרות",
      },
    },

    attendanceClocks: {
      [`title_${MAKOR_PROVIDER}`]: "שעוני מקור-טק",
      [`title_${RESTIGO_ATTENDANCE_APP_PROVIDER}`]: "שעוני אפליקציית נוכחות",
    },
    privileges: {
      [NETWORK_MANAGER]: "מנהל רשת",
      [BRANCH_MANAGER]: "מנהל סניף",
      [AREA_MANAGER]: "מנהל איזור",
      [PROCUREMENT_AREA_MANAGER]: "מנהל רכש איזורי",
      [SEND_ORDERS]: "ביצוע הזמנות",
      [SHIFT_MANAGER]: "מנהל משמרת",
      [KITCHEN_MANAGER]: "מנהל מטבח",
      [PROCUREMENT_MANAGER]: "מנהל רכש",
    },
  },
};
export default translations;
