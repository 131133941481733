export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
export const niceBytes = (value) => {
  let unitIndex = 0,
    result = parseInt(value, 10) || 0;
  while (result >= 1024 && ++unitIndex) {
    result /= 1024;
  }
  return (
    result.toFixed(result < 10 && unitIndex > 0 ? 1 : 0) +
    " " +
    units[unitIndex]
  );
};
