import { createElement, useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Context } from "../Show";
import {
  EXTRA_QUESTION,
  INPUT,
  PARTS,
  PART_TYPE_SIGNATURE,
  USER_FILE_UPLOAD,
  USER_QUESTION,
} from "./consts";
import {
  CardHeader,
  Divider,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import { COLOR_RESTIGO_PRIMARY } from "../../../const/colors";
import { BASE_URL } from "../../../config/auth";
import Axios from "axios";
// import { useStyles } from "./WorkAgreement.styles";

const WorkAgreement = () => {
  const {
    parts,
    logo,
    mainSection,
    user,
    procedure,
    DNDIndex,
    setDNDIndex,
    uploadFile,
  } = useContext(Context);

  const { control } = useFormContext();
  const [printableMainSection, setPrintableMainSection] = useState("");
  const { fields, replace } = useFieldArray({ control, name: "parts" });
  const [userFileDisable, setUserFileDisable] = useState(false);
  const watchFields = useWatch({ control, name: "parts" });

  const userFile = useWatch({ control, name: `parts.${DNDIndex}.value` });

  // const classes = useStyles();
  useEffect(() => {
    replace(
      parts?.map(({ value, ...part }) => {
        const newValue = value;
        const splitedValue = newValue?.split(".");
        const fetchedValue = splitedValue?.reduce((newVal, val) => {
          return (newVal += `?.['${val}']`);
        }, "user");
        if (user?.employee_info?.salary_type == 1) {
          // if the salary_type is 1 it is by department. we will take the first!
          const userSalary = Object.values(
            user.employee_info.salary_by_subtype
          )[0];
          if (userSalary.salary_type == 3) {
            // if it is bases + additions we need to adjust it to be generic
            user.salary = userSalary.salary.salary;
            user.employee_info.global_cost = userSalary.salary.base_global;
            user.employee_info.health_cost = userSalary.salary.base_health;
            user.employee_info.extra_shabat =
              userSalary.salary.base_extra_shabat;
            user.employee_info.travel_cost = userSalary.salary.base_travel;
            user.employee_info.forced_work_stimulant =
              userSalary.salary.base_forced_work_stimulant;
          }
        }

        return {
          ...part,
          value:
            Object.hasOwn(user, splitedValue?.[0]) &&
            splitedValue?.[0] === "address" &&
            user.city &&
            fetchedValue.includes(user.city)
              ? eval(fetchedValue) + ", " + user.city
              : Object.hasOwn(user, splitedValue?.[0])
              ? eval(fetchedValue)
              : value,
        };
      }) ?? []
    );
  }, [parts]);

  useEffect(() => {
    setUserFileDisable(
      watchFields?.find((field) => field.type === USER_QUESTION)?.value !== "כן"
    );
  }, [watchFields]);

  useEffect(() => {
    let newSection = mainSection ?? "";
    watchFields?.forEach(({ value, index, type }) => {
      const regex = new RegExp(
        `<span data-type="${INPUT}" data-index="${index}"(.*?)</span>`,
        "g"
      );
      let newVal = value;
      if (type === PART_TYPE_SIGNATURE && value) {
        newVal = `<img src="${value}" />`;
      }
      newSection = newSection.replace(
        regex,
        `<span style="color: ${COLOR_RESTIGO_PRIMARY};font-weight: bold">${
          newVal ?? ""
        }</span>`
      );
    });
    setPrintableMainSection(newSection);
  }, [watchFields, mainSection]);

  useEffect(() => {
    if (userFile instanceof Object && userFile.length > 0) {
      uploadFile(userFile[0]);
    }
  }, [userFile]);

  return (
    <>
      <img
        style={{
          float: "left",
          position: "absolute",
          left: "50px",
          width: "80px",
          top: "8%",
        }}
        className="LogoAgreement"
        src={logo}
      />
      <CardHeader subheader="שדות למילוי" />
      {console.log("fields", fields)}
      <Grid container sx={{ marginTop: "2rem" }}>
        {fields.map((field, index) => {
          const { component, rules, ...props } = PARTS[field.type].show ?? {};

          // FIXME to CONST
          if (field.type === USER_FILE_UPLOAD) {
            setDNDIndex(index);
          }
          console.log("userFileDisable", userFileDisable);
          // if (field.type === 27) {
          //   <Grid item container xs={2}>
          //     {/* <Grid item xs={2}>
          //         {createElement(component, {
          //           name: `parts.${index}.value`,
          //           rules: {
          //             ...(field.required && { required: REQUIRED_MESSAGE }),
          //             ...rules,
          //           },
          //           label: field.label,
          //           disabled: userFileDisable,
          //           control,
          //           ...props,
          //           size: "small",
          //         })}
          //     </Grid> */}
          //   </Grid>;
          // }
          return ![18, 19].includes(field.type) ? (
            field.type === EXTRA_QUESTION ? (
              !userFileDisable && (
                <Grid item container xs={12}>
                  {field.type === USER_FILE_UPLOAD && (
                    <Grid item xs={12}>
                      <Typography>{field.label}</Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography>
                      {createElement(component, {
                        name: `parts.${index}.value`,
                        rules: {
                          ...(field.required && { required: REQUIRED_MESSAGE }),
                          ...rules,
                        },
                        label: field.label,
                        control,
                        ...props,
                        size: "small",
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              )
            ) : field.type === USER_QUESTION ? (
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  {createElement(component, {
                    name: `parts.${index}.value`,
                    rules: {
                      ...(field.required && { required: REQUIRED_MESSAGE }),
                      ...rules,
                    },
                    label: field.label,
                    option: ["כן", "לא"],
                    ...props,
                    size: "small",
                  })}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} md={4} key={field.id}>
                {createElement(component, {
                  name: `parts.${index}.value`,
                  rules: {
                    ...(field.required && { required: REQUIRED_MESSAGE }),
                    ...rules,
                  },
                  label: field.label,
                  control,
                  ...props,
                  size: "small",
                })}
              </Grid>
            )
          ) : (
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems="center"
              xs={12}
              style={{ borderBottom: "1px solid black" }}
            >
              <Grid item xs={12} md={12}>
                <Typography style={{ textTransform: "capitalize" }}>
                  {field.label}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                {createElement(PARTS[field.type].show, {
                  file: {
                    ...field.value,
                    preview: `${BASE_URL}${
                      field.value?.preview ?? field.value
                    }`,
                  },
                  src: field.value,
                })}
              </Grid>
            </Grid>
          );
        })}
        <Grid style={{ borderTop: "1px solid black" }} item xs={12}>
          <CardHeader style={{ border: "none" }} title={procedure?.name} />
          <img
            style={{
              width: "auto",
              height: "auto",
              left: "50px",
              width: "80px",
            }}
            src={logo}
          />
          {/* <Divider sx={{ my: 5 }} /> */}
          <div dangerouslySetInnerHTML={{ __html: printableMainSection }} />
        </Grid>
      </Grid>
    </>
  );
};
export default WorkAgreement;
