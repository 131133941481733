import { Grid, TablePagination } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import ReactTable from "../../../components/tables/ReactTable";

// ======== HOOKS ========
import { useScreenSize } from "../../../Hooks";
import { getAllLogs } from "../../../redux/actions/userManageLogsAction";
import { SET_USER_LOGS_FILTERS } from "../../../redux/actions/userManageLogsAction/types";
import { generateUserLogsColumns } from "./columns";
import UserLogsFilters from "./UserLogsFilters";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const UserLogHistory = (props) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();
  const dispatch = useDispatch();

  const logs = useSelector((state) => state.UserLogs.logs);
  const filter = useSelector((state) => state.UserLogs.filters);
  const endDate = useSelector(
    (state) => state.UserLogs.filters.selectedDates.endDate
  );
  const memoColumns = useMemo(() => {
    return generateUserLogsColumns();
  }, []);

  const memoData = useMemo(() => {
    return logs;
  }, [logs]);
  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========
  useEffect(() => {
    dispatch(getAllLogs());
  }, [
    filter.current_page,
    filter.per_page,
    endDate,
    filter.selectedAdmins,
    filter.selectedNetworks,
    filter.userFirstName,
    filter.userLastName,
    filter.branchName,
  ]);

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Grid container>
      <Grid item xs={12}>
        <UserLogsFilters />
      </Grid>
      <Grid item xs={12}>
        <ReactTable
          styles={{
            wrapper: { maxHeight: "calc(100vh - 10rem)" },
            container: { maxHeight: "calc(100vh - 50rem)" },
          }}
          alignToCenter
          data={memoData}
          columns={memoColumns}
        />
      </Grid>
      <Grid item xs={12}>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          count={filter.total}
          page={filter.current_page}
          rowsPerPage={
            filter.per_page > filter.total ? filter.total : filter.per_page
          }
          onPageChange={(e, page) => {
            dispatch({
              type: SET_USER_LOGS_FILTERS,
              payload: {
                name: "current_page",
                value: page,
              },
            });
          }}
          onRowsPerPageChange={(e) => {
            dispatch({
              type: SET_USER_LOGS_FILTERS,
              payload: {
                name: "per_page",
                value: +e.target.value,
              },
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogHistory);
