import { IconButton } from "@mui/material";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import { DateHouerCell } from "../../../components/tables/components/PriceFooter";
import { COLOR_ABORT } from "../../../const/colors";

export const columns = [
  {
    Header: "סוג",
    accessor: "type_string",
  },
  {
    Header: "שם",
    accessor: "name",
  },
  {
    Header: "תאריך התחלה",
    accessor: "start_date_string",
  },
  {
    Header: "יום התחלה",
    accessor: "start_day",
  },
  {
    Header: "תאריך סיום",
    accessor: "end_date_string",
  },
  {
    Header: "יום סיום",
    accessor: "end_day",
  },
  {
    Header: "עודכן בתאריך",
    accessor: "updated_at",
    Cell: DateHouerCell,
  },
  {
    Header: 'עודכן ע"י',
    accessor: "updated_by_name",
  },
  {
    Header: "",
    accessor: "action",
    Cell: ({ row, handleHolidayToEdit, handleDeleteHoliday }) => {
      const { id, name } = row.original;

      return (
        <div className="flex-end">
          <IconButton
            color="error"
            onClick={() => handleDeleteHoliday(id, name)}
          >
            <FaRegTrashAlt style={{ color: COLOR_ABORT }} />
          </IconButton>
          <IconButton
            color="success"
            onClick={() => handleHolidayToEdit(row.original)}
          >
            <FaPencilAlt />
          </IconButton>
        </div>
      );
    },
  },
];
