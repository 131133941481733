import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Axios from "axios";
import { startOfWeek, endOfWeek } from "date-fns";
import { fixDate, dateReqFormat } from "../../helpers";
import { setError } from "../actions/errorAction";
import { startLoading, stopLoading } from "../actions/loaderAction";
import { setSnackBar } from "../../redux/actions/confirmAction";

import {
  suppliersURI,
  htmlSpecialCharacters,
} from "../../pages/purchaseRequisition/newRequisition/const";
import {
  TAB_REQUIREMENTS_MANAGEMENT,
  TAB_PATH_MANAGEMENT,
  TAB_OPTIONS,
} from "../../pages/purchaseRequisition/adminRequisition/const";
import { isEmpty, uniqBy } from "lodash";

const initialState = {
  tab: {
    id: TAB_REQUIREMENTS_MANAGEMENT,
    label: TAB_OPTIONS[TAB_REQUIREMENTS_MANAGEMENT].label,
    path: TAB_PATH_MANAGEMENT,
  },
  management: {
    filter: {
      branchId: {},
      branchIds: [],
      selectedDates: {
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
        key: "selection",
        label: "השבוע",
      },
      status: {},
    },
    pagination: {
      current_page: 0,
      per_page: 50,
      total: 1,
    },
    data: {
      master: {
        isLoading: false,
        thunkStatus: "idle",
        data: [],
      },
      details: {
        isLoading: false,
        isEditMode: false,
        data: {},
        freeSearch: {
          isLoading: false,
          data: [],
        },
        errors: [],
      },
    },
    selectedItemForApproving: [],
    approvedItemsAddedToOrderDraft: [],
    isApproveSucceeded: false,
  },
  byItem: {
    filter: {
      branchIds: [],
      suppliersId: [],
      selectedDates: {
        startDate: startOfWeek(new Date()),
        endDate: endOfWeek(new Date()),
        key: "selection",
        label: "השבוע",
      },
    },
    pagination: {
      current_page: 0,
      per_page: 50,
      total: 1,
    },
    data: {
      master: {
        isLoading: false,
        thunkStatus: "idle",
        data: [],
      },
      details: {
        isLoading: false,
        data: {},
        errors: [],
      },
    },
    selectedItemForApproving: [],
    approvedItemsAddedToOrderDraft: [],
    isApproveSucceeded: false,
  },
  suppliers: [],
  error: null,
};

export const requisitionManagementSlice = createSlice({
  name: "requisitionManagement",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setFilter: (state, action) => {
      const { key, value } = action.payload;
      state[[state.tab.path]].filter[key] = value;
    },
    setPaginationPage: (state, action) => {
      const { value, name } = action.payload;
      state[[state.tab.path]].pagination[[name]] = value;
    },
    showDetails: (state, action) => {
      state.management.data.details.isEditMode = false;
      const selected = state.management.data.master.data.find(
        (requisition) => requisition.id === action?.payload
      );
      state.management.data.details.data = selected;
    },
    editRequisition: (state, action) => {
      state.management.data.details.isEditMode = true;
      const selected = state.management.data.master.data.find(
        (requisition) => requisition.id === action?.payload
      );
      state.management.data.details.data = selected;
    },
    incrementItemInRequisition: (state, action) => {
      const { itemId, day } = action.payload;
      const existingItem = state.management.data.details.data?.items?.find(
        (item) => item.item_id === itemId
      );
      if (existingItem) {
        existingItem.amount[day]++;
      }
    },
    decrementItemInRequisition: (state, action) => {
      const { itemId, day } = action.payload;
      const existingItem = state.management.data.details.data?.items?.find(
        (item) => item.item_id === itemId
      );
      if (existingItem && existingItem.amount[day] > 0) {
        existingItem.amount[day]--;
      }
    },
    setNumOfItemInRequisition: (state, action) => {
      const { itemId, day, incNumber } = action.payload;
      if (Number(incNumber) < 0) {
        return;
      }
      const existingItem = state.management.data.details.data?.items?.find(
        (item) => item.item_id === itemId
      );
      if (existingItem) {
        existingItem.amount[day] = Number(incNumber);
      }
    },
    addItemsFromSearchingToTable: (state, action) => {
      const sourceArray = action.payload;
      const destinationArray = state.management.data.details.data?.items;
      for (const obj of sourceArray) {
        const existsInDestination = destinationArray.find(
          (item) => item.item_id === obj.id
        );

        if (!existsInDestination) {
          const newRequisitionItem = {
            item_id: obj.id,
            price: obj.price,
            part_number: obj?.part_number,
            catalog_item: {
              id: obj.id,
              name: obj.name,
              supplier_name: obj.supplier_name,
              part_number: obj?.part_number,
              description: obj.description,
            },
            amount: {
              sunday: 0,
              monday: 0,
              tuesday: 0,
              wednesday: 0,
              thursday: 0,
              friday: 0,
              saturday: 0,
            },
          };
          destinationArray.unshift(newRequisitionItem);
        }
      }
    },
    removeItem: (state, action) => {
      const itemId = action.payload;
      state.management.data.details.data.items =
        state.management.data.details.data.items?.filter(
          (item) => item.item_id !== itemId
        );
    },
    setCheckedRequisitionItem: (state, action) => {
      state[[state.tab.path]].selectedItemForApproving = action.payload;
    },
    changeFlagForApprovedItems: (state, action) => {
      state[[state.tab.path]].isApproveSucceeded = false;
      state[[state.tab.path]].approvedItemsAddedToOrderDraft =
        initialState[[state.tab.path]].approvedItemsAddedToOrderDraft;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRequisitions.pending, (state, action) => {
        state.management.data.details = initialState.management.data.details;
        state.management.data.master.isLoading = true;
        state.management.data.master.thunkStatus = "loading";
      })
      .addCase(fetchRequisitions.rejected, (state, action) => {
        state.management.data.master.isLoading = false;
        state.management.data.master.thunkStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRequisitions.fulfilled, (state, action) => {
        if (!action.payload?.data) {
          state.management.data.master.isLoading = false;
          state.management.data.master.thunkStatus = "failed";
          return;
        }
        state.management.data.master.isLoading = false;
        state.management.data.master.thunkStatus = "succeeded";
        const { total, current_page, per_page, data } = action.payload;
        state.management.pagination = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
        state.management.data.master.data = data;
      })
      .addCase(fetchSuppliers.fulfilled, (state, action) => {
        state.suppliers = action.payload.map(
          ({ supplier_id, name, has_department }) => ({
            value: supplier_id,
            label: htmlSpecialCharacters(name),
            hasDepartment: has_department,
          })
        );
      })
      .addCase(fetchRequisitionsByItem.pending, (state, action) => {
        state.byItem.data.master.isLoading = true;
        state.byItem.data.master.thunkStatus = "loading";
      })
      .addCase(fetchRequisitionsByItem.rejected, (state, action) => {
        state.byItem.data.master.isLoading = false;
        state.byItem.data.master.thunkStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchRequisitionsByItem.fulfilled, (state, action) => {
        state.byItem.data.master.isLoading = false;
        if (!action.payload || !action.payload?.data) {
          state.byItem.data.master.thunkStatus = "failed";
          state.error = action.payload;
          return;
        }
        state.byItem.data.master.thunkStatus = "succeeded";
        const { total, current_page, per_page, data } = action.payload;
        state.byItem.data.master.data = Object.values(data);
        state.byItem.pagination = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
      })
      .addCase(approveRequisitionItems.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(approveRequisitionItems.fulfilled, (state, action) => {
        state[[state.tab.path]].isApproveSucceeded = true;
        state[[state.tab.path]].approvedItemsAddedToOrderDraft =
          action.payload.inNewOrderDraft;
        state[[state.tab.path]].selectedItemForApproving =
          initialState[[state.tab.path]].selectedItemForApproving;
        state.management.data.details = initialState.management.data.details;
      })
      .addCase(fetchItemsFreeSearch.pending, (state, action) => {
        state.management.data.details.freeSearch.isLoading = true;
      })
      .addCase(fetchItemsFreeSearch.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.management.data.details.freeSearch.data = uniqBy(
          action.payload,
          "id"
        );
        state.management.data.details.freeSearch.isLoading = false;
      })
      .addCase(fetchItemsFreeSearch.rejected, (state, action) => {
        state.management.data.details.freeSearch.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateRequisition.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(updateRequisition.fulfilled, (state, action) => {
        state.management.data.details = initialState.management.data.details;
      });
  },
});

//=========== Async Thunks =======================

export const fetchRequisitions = createAsyncThunk(
  "requisitionManagement/fetchRequisitions",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const { branchIds, selectedDates, status } =
      getState().adminRequisition.management.filter;

    const startDate = dateReqFormat(selectedDates.startDate);
    const endDate = dateReqFormat(selectedDates.endDate);

    const { current_page, per_page } =
      getState().adminRequisition.management.pagination;

    const params = {
      branchIds,
      startDate,
      endDate,
      page: current_page + 1,
      per_page,
      status: isEmpty(status) ? null : status.id,
    };

    try {
      const { data } = await Axios.get("purchaseRequisition", {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const fetchSuppliers = createAsyncThunk(
  "requisitionManagement/fetchSuppliers",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    try {
      const res = await Axios.get(suppliersURI, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRequisitionsByItem = createAsyncThunk(
  "requisitionManagement/fetchRequisitionsByItem",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const { branchIds, selectedDates, suppliersId } =
      getState().adminRequisition.byItem.filter;

    const startDate = dateReqFormat(selectedDates.startDate);
    const endDate = dateReqFormat(selectedDates.endDate);

    const { current_page, per_page } =
      getState().adminRequisition.byItem.pagination;

    const params = {
      branchIds,
      suppliersId,
      startDate,
      endDate,
      page: current_page + 1,
      per_page,
    };

    try {
      const res = await Axios.get("purchaseRequisition/getRequisitionByItem", {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      console.log("error", error);
      return error;
    }
  }
);

export const approveRequisitionItems = createAsyncThunk(
  "requisitionManagement/approveRequisition",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    dispatch(startLoading());
    const token = getState().auth.token;

    // const requisitionItems =
    //   getState().adminRequisition.byItem.selectedItemForApproving;
    // const itemsForApprove = requisitionItems.flatMap((obj) => obj.subRows);
    const body = {
      itemsForApprove: payload,
    };

    try {
      const { data } = await Axios.post(
        "purchaseRequisition/approveRequisitionItems",
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      console.log("error", error);
      return rejectWithValue(error);
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const fetchItemsFreeSearch = createAsyncThunk(
  "requisitionManagement/fetchItemsFreeSearch",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const params = {
      freeSearch: payload,
      status: [1],
    };

    try {
      const { data } = await Axios.get("v3/items", {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const updateRequisition = createAsyncThunk(
  "requisitionManagement/updateRequisition",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    dispatch(startLoading());
    const token = getState().auth.token;

    const requisitionDetails =
      getState().adminRequisition.management.data.details.data;

    const { id, items } = requisitionDetails;
    const body = {
      items,
    };

    try {
      const { data } = await Axios.put(
        `purchaseRequisition/${id}/updateRequisition`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      console.log("error", error);
      return rejectWithValue(error);
    } finally {
      dispatch(stopLoading());
      dispatch(setSnackBar("עודכן בהצלחה!"));
    }
  }
);

export default requisitionManagementSlice.reducer;
export const {
  setTab,
  setFilter,
  setPaginationPage,
  showDetails,
  editRequisition,
  incrementItemInRequisition,
  decrementItemInRequisition,
  setNumOfItemInRequisition,
  addItemsFromSearchingToTable,
  removeItem,
  setCheckedRequisitionItem,
  changeFlagForApprovedItems,
} = requisitionManagementSlice.actions;
