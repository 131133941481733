import { TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { ControlSignaturePad } from "../../../components/Forms/inputs/SignaturPad";
import EmailTagInput from "../../../components/inputs/EmailsTagTextField";
import SimpleReactTable from "../../../components/tables/SimpleReactTable";
import { fixPrice } from "../../../helpers";
import { useScreenSize } from "../../../Hooks";
import { Context } from "../Show";
import "./style.css";

const SexualHarrasment = () => {
  const { user, selectedBranch } = useContext(Context);
  const { isMobile } = useScreenSize();
  const { control, setValue, watch } = useFormContext();
  const [emails, setEmails] = useState([]);
  const signature = useWatch({ control, name: "signature" });
  const identifier = watch("identifier");

  useEffect(() => {
    setValue("in_charge", "in_charge_sexual");
    setValue("branch_name", selectedBranch.name);
    setValue("company_name", selectedBranch.company_name);
    if (user.identifier) {
      setValue("identifier", user.identifier);
    }
  }, []);

  useEffect(() => {
    setValue("send_emails", emails);
  }, [emails]);

  const dibounceFunc = debounce((e) => {
    setValue("identifier", e.target.value);
  }, 500);

  return (
    <div
      style={{
        margin: isMobile ? 0 : 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "3rem",
        fontSize: isMobile ? "1.8rem" : "2rem",
        maxWidth: isMobile ? "100%" : "50%",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          fontSize: isMobile ? "2rem" : "",
        }}
      >
        תקנון מניעת הטרדה מינית –{selectedBranch.name}
      </h2>
      <p>
        כידוע, רוב המקרים המפורסמים בתקשורת ועוסקים בהטרדה מינית אשר בוצעה על
        ידי גבר כלפי אישה , יחד עם זאת הטרדה מינית יכולה להיעשות הן על ידי גבר
        והן על ידי אישה; כלפי גבר או אישה. על פי חוק למניעת הטרדה מינית
        (התשנ&quot;ח), 1998
      </p>

      <p>הטרדה מינית היא אחת מחמש צורות התנהגות אסורות:</p>
      <ol style={{ marginRight: "50px" }}>
        <li>סחיטת אדם לביצוע מעשה בעל אופי מיני.</li>
        <li>מעשה מגונה.</li>
        <li>
          הצעות חוזרות בעלות אופי מיני אף שהאדם שאליו מופנות ההצעות הראה שאינו
          מעוניין בהן.
        </li>
        <li>
          התייחסויות חוזרות למיניותו של אדם, אף שהאדם שאליו מופנות ההתייחסויות
          הראה שאינו מעוניין בהן
        </li>
        <li>
          התייחסות מבזה או משפילה למינו או לנטייתו המינית של אדם בין אם הוא הראה
          שהדבר מפריע לו ובין אם לאו.
        </li>
      </ol>
      <p>
        ככלל אדם צריך להראות שהוא אינו מסכים למעשה של הטרדה מינית. אי הסכמה
        מראים בין במילים ובין בהתנהגות שאינן משתמעות לשתי פנים. למרות ניסיון
        המחוקק להגדיר בצורה מפורטת מהי הטרדה מינית, מטבען של ההגדרות שתמיד
        ייוותר תחום אפור. לדוגמה, קשה להגדיר מראש כל מה שייחשב להתייחסות "מבזה"
        או "משפילה" ביחס למינו או למיניותו של אדם. יחד עם זאת, האיסור על הטרדה
        מינית איננו איסור על חיזורים הנעשים ברוח טובה ובהסכמה הדדית.
      </p>
      <p>
        הטרדה מינית והתנכלות מהוות עבירות משמעת, שעליה יכול המטריד או המתנכל
        להתחייב בעונש משמעתי.
      </p>
      <strong>צעדי מנע</strong>
      <ol style={{ marginRight: "50px" }}>
        <li>
          המעביד דורש מכל ממונה מטעמו ומכל עובד, להימנע ממעשים של הטרדה מינית
          והתנכלות במסגרת יחסי עבודה ולעשות כל שביכולתו כדי למנוע מעשים כאמור,
          והכל כדי ליצור, יחד עם המעביד, סביבת עבודה בלא הטרדה מינית והתנכלות.
        </li>
        <li>
          המעביד דורש מכל ממונה מטעמו ליטול חלק פעיל ומוביל במניעת הטרדה מינית
          והתנכלות במסגרת יחסי עבודה.
        </li>
        <li>
          פעולות הסברה והדרכה: המעביד דורש מכל ממונה ומכל עובד להשתתף בפעולות
          הדרכה והסברה הנעשות מטעמו
        </li>
        <li>
          בדבר איסור הטרדה מינית והתנכלות ומניעתן; לחלופין המעביד מאפשר לעובדיו
          להשתתף במשך שעות העבודה בפעולות כאמור, המאורגנות, בפר קי זמן סבירים,
          על ידי גורמים אחרים, כמו ארגון עובדים יציג או ארגוני נשים, ובלבד שאין
          בכך כדי לפגוע במהלך התקין של העבודה.
        </li>
      </ol>

      <p>
        אם אדם סבור שהטרידו אותו מינית או שהתנכלו לו, פתוחות לפניו שלוש אפשרויות
        לפי החוק:
      </p>
      <p>
        טיפול באחריות המעביד: אם ההטרדה או ההתנכלות התבצעה "במסגרת יחסי עבודה",
        הנפגע יכול:
      </p>
      <ol style={{ marginRight: "50px" }}>
        <li>
          להגיש תלונה במקום העבודה. יש לפנות לממונה או אחראי/ת הטרדה מינית
        </li>
        <li>הליך פלילי: הנפגע יכול להגיש תלונה במשטרה.</li>
        <li>
          הליך אזרחי: הנפגע יכול להגיש, בתוך שלוש שנים, תביעה בבית המשפט (בדרך
          כלל – בבית הדין האזורי לעבודה) נגד: המטריד או המתנכל בעצמו; ואם הוא
          טוען שהמעביד אחראי, גם המעביד.
        </li>
      </ol>
      <p>
        הליך תלונה אצל מעביד וטיפול באחריות מעביד מי יכול להגיש תלונה, ובאילו
        נסיבות? התלונה יכולה להיות מוגשת על ידי אחד מאלה:{" "}
      </p>
      <ol style={{ marginRight: "50px" }}>
        <li>
          עובד שטוען כי מעביד, ממונה או עובד אחר הטריד אותו מינית או התנכל לו,
          במסגרת יחסי עבודה
        </li>
        <li>
          אדם אחר שטוען כי עובדו של המעביד או ממונה מטעמו הטריד אותו מינית או
          התנכל לו, במסגרת יחסי עבודה
        </li>
      </ol>
      <div>
        <p>בפני מי מתלוננים?</p>
        <p>א. אחראי/ת הטרדה-:</p>
        <ul style={{ marginRight: "50px" }}>
          <li>שם:{selectedBranch.in_charge_sexual_name}</li>
          <li>טלפון:{selectedBranch.in_charge_sexual_phone}</li>
          <li>מייל:{selectedBranch.in_charge_sexual_mail}</li>
        </ul>
        <br></br>
        <p>ב. כל אדם ברמה ממונה (מנהל מסעדה, שף, סו שף, מנהלי משמרת )</p>
        <br></br>
        <ul>
          <li>
            אם האחראי הוא האדם שמתלוננים עליו ("הנידון") או שהוא בעל נגיעה אישית
            לנושא התלונה או למעורבים בה, תוגש התלונה לממלא מקומו של האחראי,
            אחראי אחר, (ובהעדרם – למעביד. )אם הגיש את התלונה למעביד כאמור,
            המעביד יפעל על פי הוראות חלק זה כפי שאמור לפעול אחראי.
          </li>
          <li>
            אם הנידון היה עובד של קבלן כוח אדם המועסק בפועל אצל המעביד - תוגש
            התלונה לאחראי מטעם הקבלן או לאחראי מטעם המעביד; אם הוגשה התלונה
            לאחראי מטעם הקבלן, אותו אחראי רשאי להעביר את הטיפול בבירור התלונה
            לאחראי מטעם המעביד, ואם הועבר הטיפול כאמור – האחראי מטעם הקבלן יודיע
            כך למתלונן . תוכן התלונה התלונה תכלול את תיאור המקרה, לרבות: - פירוט
            זהות המעורבים במקרה ועדים, אם ישנם. - מקום האירוע.
          </li>
        </ul>
      </div>
      <p>במקרה שנטען כי בוצע מעשה של הטרדה מינית – אחד מאלה:</p>
      <ul style={{ marginRight: "50px" }} className="hebrew-list">
        <li>האם המוטרד הראה למטריד שההתנהגות מפריעה לו.</li>
        <li>האם יש בין המטריד למוטרד יחסי תלות, מרות וכד'.</li>
      </ul>

      <strong>אופן הגשת התלונה:</strong>
      <ul style={{ marginRight: "50px" }} className="hebrew-list">
        <li>ניתן להגיש תלונה בכתב או בעל פה.</li>
        <li>
          הוגשה תלונה בעל פה-
          <ol style={{ marginRight: "50px" }}>
            <li>ירשום האחראי את תוכן התלונה.</li>
            <li>
              המתלונן או מי שמגיש את התלונה מטעמו יחתום על הרישום של האחראי כדי
              לאשר את תוכן הדברים.
            </li>
            <li>האחראי ימסור למתלונן עותק מהרישום החתום.</li>
          </ol>
        </li>
      </ul>

      <strong>בירור התלונה:</strong>
      <ul style={{ marginRight: "50px" }} className="hebrew-list">
        <li>
          התקבלה תלונה, האחראי -
          <ol style={{ marginRight: "50px" }}>
            <li>
              יידע את המתלונן באשר לדרכי הטיפול בהטרדה מינית או התנכלות לפי
              החוק.
            </li>
            <li>
              יפעל לבירור התלונה ולצורך כך, בין היתר, ישמע את המתלונן, את הנילון
              ועדים , אם ישנם, ויבדוק כל מידע שהגיע אליו בעניין התלונה
            </li>
          </ol>
        </li>
        <li>
          {" "}
          אחראי לא יטפל בבירור תלונה אם הוא בעל נגיעה אישית לנושא התלונה או
          למעורבים בה.
        </li>
        <li>
          אחראי שהוא בעל נגיעה אישית כאמור יעביר את הבירור לאחראי או למי שהתמנה
          על ידי המעביד.
        </li>
        <li>
          לממלא מקומו, ובהיעדר אחד מאלה – למעביד; אם האחראי העביר את הטיפול
          למעביד כאמור, יפעל המעביד כפי שאמור לפעול אחראי בבירור תלונה, על פי
          סעיף זה.
        </li>
        <li>בירור התלונה ייעשה ביעילות ובלא דיחוי.</li>
        <li>
          בירור תלונה ייעשה תוך הגנה מרבית על כבודם ופרטיותם של המתלונן, הנילון
          ועדים אחרים, ובין היתר - לא יגלה אחר אי מידע שהגיע אליו במהלך בירור
          התלונה אלא אם כן הוא חייב לעשות כן לשם הבירור עצמו או על פי דין; לא
          ישאל אחראי שאלות בקשר לעבר המיני של מתלונן שאינו קשור לנילון, ולא
          יתייחס למידע על עברו המיני של המתלונן כאמור; האמור בפסקה זו לא יחול אם
          האחראי סבור שאם לא ישאל שאלות או יתייחס כאמור, ייגרם עוול בלתי ניתן
          לתיקון לנילון. מעביד יגן על המתלונן, במהלך בירור התלונה; מפני פגיעה
          בענייני עבודה כתוצאה מהגשת התלונה או מפני פגיעה אחרת במסגרת יחסי עבודה
          שיש בה כדי לשבש את בירור התלונה; בין היתר יפעל המעביד להרחקת הנילון
          מהמתלונן, ככל שניתן, וככל שנראה לו נכון בנסיבות העניין. בתום בירור
          התלונה יגיש האחראי למעביד, ללא דיחוי, סיכום בכתב של בירור התלונה
          בליווי המלצותיו המנומקות לגבי המשך הטיפול בה, לרבות לעניין כל אחד
          מהעניינים המפורטים בסעיף 81 במידה ונודע למעביד על מקרה של הטרדה מינית
          או התנכלות במסגרת יחסי עבודה, ולא הוגשה תלונה או שהמתלונן חזר בו
          מתלונתו, יעביר את המקרה לבירור של אחראי; הועבר מקרה כאמור לבירור של
          אחראי או נודע לאחראי על מקרה כאמור, יקיים האחראי, ככל שניתן, בירור על
          אודות המקרה לפי סעיף זה, בשינויים המחויבים, ואם המתלונן חזר בו
          מתלונתו, יברר גם את סיבת החזרה מהתלונה
        </li>
      </ul>

      <strong>טיפול המעביד במקרה של הטרדה מינית או התנכלות</strong>
      <ul className="hebrew-list">
        <li>
          קיבל מעביד את סיכומו והמלצותיו של האחראי לפי סעיף 81 (ז), יחליט , בלא
          דיחוי ובתוך תקופה שלא תעלה על 1 ימי עבודה , על הפעלת הסמכויות שבידיו
          לגבי כל אחד מאלהץ.
        </li>
        <li>
          מתן הוראות לעובדים המעורבים במקרה, לרבות בדבר כללי התנהגות ראויים
          במסגרת יחסי עבודה והרחקת הנילון מהמתלונן, וכן נקיטת צעדים בענייני
          עבודה, והכול כדי למנוע את הישנות המעשה של הטרדה מינית או התנכלות או
          כדי לתקן את הפגיעה שנגרמה למתלונן עקב ההטרדה או ההתנכלות
        </li>
        <li>
          פתיחה בהליכים משמעתיים לפי הוראות הסדר המשמעת החלות אצל המעביד לעניין
          הטרדה מינית או התנכלות
        </li>
      </ul>

      <p>
        {selectedBranch.company_name} הידועה גם כ {selectedBranch.name},
        {selectedBranch.network_name} תכבד את פרטיותם של מתלוננים,
        הקרבנות,החשודים/הנאשמים ככל האפשר ובשים לב לדרישות החוק ולצורך להגן על
        אינטרסים אחרים הנוגעים לעניין כגון : חובת המסעדה לחקור מקרי הטרדה מינית
        והתנכלות ולנקוט אמצעים כנגד האחראים בגינן , החובה להעפיל אמצעים
        קולקטיביים ומניעתיים על מנת לבער את תופעת ההטרדה המינית.
      </p>

      <p>על החתום : </p>
      <p>
        שם:
        <span style={{ marginRight: "20px", textDecoration: "underline" }}>
          {user.name}
        </span>
      </p>
      <p>
        ת.ז:
        <span style={{ marginRight: "20px", textDecoration: "underline" }}>
          {!user.identifier ? identifier : user.identifier}
        </span>
      </p>
      <div className="signature-container">
        <p>חתימה</p>
        <img src={signature ? signature : ""} className="signature-image" />
        <span className="signature-underline"></span>
      </div>

      <div>
        <EmailTagInput
          emails={emails}
          setEmails={setEmails}
          label="מיילים לשליחה"
        />
      </div>
      <div>
        {!user.identifier && (
          <div>
            <TextField
              fullWidth
              variant="outlined"
              label={"תעודת זהות"}
              placeholder={"תעודת זהות"}
              onChange={dibounceFunc}
              style={{ width: 300 }}
            />
          </div>
        )}
        <Typography fontSize={16}>חתימה על הטופס</Typography>
        <ControlSignaturePad
          {...{
            name: "signature",
            rules: { required: REQUIRED_MESSAGE },
          }}
        />
      </div>
    </div>
  );
};

export default SexualHarrasment;
