import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  useFilters,
  useGlobalFilter,
  useSortBy,
  useTable,
  useExpanded,
} from "react-table";

import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { default as Grid } from "@mui/material/Unstable_Grid2";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { createContext, useMemo, useContext, useEffect, useState } from "react";
import { GlobalFilter, TableSearchGlobalFilter, TextFilter } from "./Filters";
import { connect } from "react-redux";
import MuiSkeletonTable from "../skeletons/MuiSkeletonTable";
import { AutoComplete } from "../Forms/inputs/AutoComplete";
import { useScreenSize } from "../../Hooks";
import PopoverEllipsisButton from "../popover/PopoverEllipsisButton";
import ButtonIcon from "../buttons/ButtonIcon";

const Context = createContext();

const SimpleReactTable = ({
  data,
  columns,
  withGlobalFilter,
  emptyTable = "לא נמצאו תוצאות",
  rowAction = () => {},
  style,
  loading,
  endRowButtons,
  endRowButtonsHeader = "פעולות נוספות",
}) => {
  const { isMobile } = useScreenSize();
  const defaultColumn = useMemo(() => ({ Filter: TextFilter }), []);
  const expandAble = useMemo(
    () => data.some((row) => row.subRows?.length),
    [data]
  );

  const [topFilters, setTopFilters] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    preGlobalFilterRows,
    state: { globalFilter },
    visibleColumns,
    columns: tableColumns,
    setSortBy,
    getToggleAllRowsExpandedProps,
    isAllRowsExpanded,
  } = useTable(
    { columns, data, defaultColumn },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    setTopFilters(tableColumns.filter(({ filterAt }) => filterAt === "top"));
  }, [tableColumns]);
  return (
    <>
      <Grid container>
        {/*This is the new  way*/}
        {!!withGlobalFilter && !isMobile && (
          <Grid item lg={12}>
            <TableSearchGlobalFilter
              {...{
                globalFilter,
                setGlobalFilter,
                preGlobalFilterRows,
              }}
            />
            <div style={{ marginTop: "2rem", display: "flex", gap: 6 }}>
              {!!topFilters?.length &&
                topFilters.map((column, index) => (
                  <span key={index}>{column.render("Filter")}</span>
                ))}
            </div>
          </Grid>
        )}
        <Grid item lg={12}>
          <TableContainer
            style={{
              maxHeight: "70vh",
              ...style,
            }}
          >
            {loading ? (
              <MuiSkeletonTable />
            ) : (
              <Table {...getTableProps()} stickyHeader>
                <Context.Provider
                  value={{
                    withGlobalFilter,
                    visibleColumns,
                    globalFilter,
                    setGlobalFilter,
                    preGlobalFilterRows,
                    headerGroups,
                    getTableBodyProps,
                    rows,
                    prepareRow,
                    columns,
                    tableColumns,
                    setSortBy,
                    emptyTable,
                    rowAction,
                    expandAble,
                    getToggleAllRowsExpandedProps,
                    isAllRowsExpanded,
                    endRowButtons,
                    endRowButtonsHeader,
                  }}
                >
                  {isMobile ? <Mobile /> : <Desktop />}
                </Context.Provider>
              </Table>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

const Mobile = () => {
  const {
    withGlobalFilter,
    globalFilter,
    setGlobalFilter,
    preGlobalFilterRows,
    getTableBodyProps,
    rows,
    prepareRow,
    columns,
    tableColumns,
    setSortBy,
    emptyTable,
    rowAction,
    endRowButtons,
  } = useContext(Context);
  const [openFilters, setOpenFilters] = useState(false);
  const [sortValue, setSortValue] = useState(null);
  useEffect(() => {
    const column = tableColumns.find(
      ({ Header }) => Header === sortValue?.name
    );
    if (!column) {
      return;
    }
    setSortBy([{ id: column.id, desc: sortValue.desc }]);
  }, [sortValue, tableColumns, setSortBy]);

  const mobileSortOptions = useMemo(() => {
    const options = new Set();
    columns.forEach((column) => {
      if (!column.sortMobile) {
        return;
      }
      options.add({
        id: `${column.Header}.asc`,
        label: `${column.Header} סדר עולה`,
        name: column.Header,
        desc: false,
      });
      options.add({
        id: `${column.Header}.desc`,
        label: `${column.Header} סדר יורד`,
        name: column.Header,
        desc: true,
      });
    });
    return [...options];
  }, [columns]);
  return (
    <>
      <TableHead>
        {!!withGlobalFilter && (
          <TableRow>
            <TableCell colSpan={1 + Number(!!endRowButtons?.length)}>
              <Grid xs={12} lg={1} className="flex-center">
                <GlobalFilter
                  {...{
                    globalFilter,
                    setGlobalFilter,
                    preGlobalFilterRows,
                  }}
                />
                <ButtonIcon
                  icon={setOpenFilters ? FaSearchMinus : FaSearchPlus}
                  className="btn--normal"
                  onClick={() => setOpenFilters(!openFilters)}
                  alt="חיפוש מתקדם"
                />
              </Grid>
            </TableCell>
          </TableRow>
        )}
        {!!mobileSortOptions.length && (
          <TableRow>
            <TableCell colSpan={1 + Number(!!endRowButtons?.length)}>
              <AutoComplete
                inputProps={{ label: "מיון" }}
                value={sortValue}
                options={mobileSortOptions}
                onChange={(e, value) => {
                  setSortValue(value);
                }}
                filterSelectedOptions
              />
            </TableCell>
          </TableRow>
        )}
        {tableColumns
          ?.filter(({ filter }) => filter)
          ?.map((filter, index) => {
            return (
              //<Collapse in={openFilters}>
              openFilters && (
                <TableRow key={index}>
                  <TableCell colSpan={1 + Number(!!endRowButtons?.length)}>
                    {filter.render("Filter")}
                  </TableCell>
                </TableRow>
              )
              //</Collapse>
            );
          })}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={(e) => {
                  rowAction(e, row);
                }}
              >
                <TableCell>
                  <div>
                    {row.cells.map((cell, index) => {
                      if (
                        typeof cell.column.displayMobile === "undefined" ||
                        cell.column.displayMobile
                      )
                        return (
                          //<div className="flex-between" key={index}>
                          <div key={index}>
                            <span>{`${cell.column.Header}:\t`}</span>
                            <span style={{ textAlign: "end" }}>
                              {cell.render("Cell")}
                            </span>
                          </div>
                        );
                    })}
                  </div>
                </TableCell>
                <EndRowButtons {...{ row }} />
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell style={{ textAlign: "center" }}>{emptyTable}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

const Desktop = () => {
  const {
    // withGlobalFilter,
    // visibleColumns,
    // globalFilter,
    // setGlobalFilter,
    // preGlobalFilterRows,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    columns,
    emptyTable,
    rowAction,
    expandAble,
    getToggleAllRowsExpandedProps,
    isAllRowsExpanded,
    endRowButtons,
    endRowButtonsHeader,
    tableColumns,
  } = useContext(Context);

  return (
    <>
      <TableHead>
        {/*
      old way of search bar 
      {!!withGlobalFilter && (
          <TableRow >
            <TableCell
              colSpan={visibleColumns.length + (endRowButtons?.length || 0)}
            >
              <GlobalFilter
                {...{
                  globalFilter,
                  setGlobalFilter,
                  preGlobalFilterRows,
                }}
              />
              <div style={{ marginTop: "2rem", display: "flex", gap: 6 }}>
                {!!topFilters?.length &&
                  topFilters.map((column, index) => (
                    <span key={index}>{column.render("Filter")}</span>
                  ))}
              </div>
            </TableCell>
          </TableRow>
        )} */}
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {expandAble && (
              <TableCell>
                <span {...getToggleAllRowsExpandedProps()}>
                  {isAllRowsExpanded ? <MdExpandLess /> : <MdExpandMore />}
                </span>
              </TableCell>
            )}
            {headerGroup.headers.map((column) => (
              <TableCell
                {...column.getHeaderProps(
                  column.sort ? column.getSortByToggleProps() : undefined
                )}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {!!column.sort &&
                      (column.isSorted ? (
                        column.isSortedDesc ? (
                          <TiArrowSortedDown />
                        ) : (
                          <TiArrowSortedUp />
                        )
                      ) : (
                        <TiArrowUnsorted />
                      ))}
                  </span>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {!!(column.filter && column.filterAt !== "top") &&
                      column.render("Filter")}
                   
                  </div>
                </div>
              </TableCell>
            ))}
            {!!endRowButtons?.length && (
              <TableCell>{endRowButtonsHeader}</TableCell>
            )}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row, index, self) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={(e) => rowAction(e, row)}
              >
                {expandAble && (
                  <TableCell>
                    {row.canExpand ? (
                      <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? <MdExpandLess /> : <MdExpandMore />}
                      </span>
                    ) : null}
                  </TableCell>
                )}
                {row.cells.map((cell) => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                ))}
                <EndRowButtons {...{ row }} />
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              style={{ textAlign: "center" }}
              colSpan={columns.length + Number(!!endRowButtons?.length)}
            >
              {emptyTable}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

const EndRowButtons = ({ row }) => {
  const { endRowButtons } = useContext(Context);
  const { isMobile } = useScreenSize();
  const Container = isMobile ? PopoverEllipsisButton : "span";
  return (
    <>
      {!!endRowButtons?.length && (
        <TableCell>
          <Container>
            {endRowButtons?.map(
              (
                { icon, onClick, tooltip, disabled = () => false, ...props },
                index
              ) => (
                <Tooltip
                  key={index}
                  title={tooltip ?? ""}
                  {...(!tooltip && { open: false })}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick && onClick(e, row);
                    }}
                    disabled={disabled(row)}
                    {...props}
                  >
                    {icon}
                  </IconButton>
                </Tooltip>
              )
            )}
          </Container>
        </TableCell>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(SimpleReactTable);
