import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import FormCheckBox from "../../../components/Forms/inputs/FormCheckBox";
import FormTextField from "../../../components/Forms/inputs/FormTextField";

import { ControlSignaturePad } from "../../../components/Forms/inputs/SignaturPad";
import { BiShekel } from "react-icons/bi";
import {
  ControlRichTextEditor,
  getAsHtml,
  getFromHtml,
} from "../../../components/Forms/inputs/RichTextEditor2";
import { ControlDnd } from "../../../components/DND/FileDropZone";
import PreviewFile from "../../../components/DND/PreviewFile";
import { TextField, Typography } from "@mui/material";
import FormTextFieldGroup from "../../../components/Forms/inputs/FormTextFieldGroup";
import SelectComponent from "../../../components/Forms/inputs/Select";
import moment from "moment";

const PART_TYPE_TEXT = 0;
const PART_TYPE_NUMBER = 1;
const PART_TYPE_DATE = 2;
const PART_TYPE_START_DATE = 3;
const PART_TYPE_TEL = 4;
const PART_TYPE_EMAIL = 5;
const PART_TYPE_SIGNATURE = 6;
const PART_TYPE_IDENTIFER = 7;
const PART_TYPE_SALARY = 8;
const PART_TYPE_EMPLOYEE_TYPE_TITLE = 9;
const PART_TYPE_FULL_NAME = 10;
const PART_TYPE_FIRST_NAME = 11;
const PART_TYPE_LAST_NAME = 12;
const PART_TYPE_STREET = 13;
const PART_TYPE_BANK_NAME = 14;
const PART_TYPE_BANK_BRANCH_NUMBER = 15;
const PART_TYPE_BANK_BRANCH_NAME = 16;
const PART_TYPE_ACCONUT_NUMBER = 17;
const PART_TYPE_FILE = 18;
const PART_TYPE_LINK = 19;
export const USER_FILE_UPLOAD = 20;
const PART_CLOTH = 21;
const PART_TYPE_TRAVEL = 22;
const PART_TYPE_CONST_TRAVEL = 23;
const PART_TYPE_HEALTH = 24;
const PART_TYPE_HOLIDAY = 25;
const PART_TYPE_GLOBALBASE = 26;
const PART_TYPE_EXTRA_SHABAT = 29;
const PART_TYPE_FORCED_WORK = 30;
export const USER_QUESTION = 27;
export const EXTRA_QUESTION = 28;
const PART_TYPE_COMPANY_NAME = 31;
const PART_TYPE_COMPANY_ADDRESS = 32;
const PART_TYPE_COMPANY_H_P = 33;
const WORK_AGREEMENT_DEFAULT_VALUES_OPTIONS = {
  text: [
    { id: "first_name", label: "שם פרטי" },
    { id: "last_name", label: "שם משפחה" },
    { id: "email", label: "מייל" },
    { id: "date_of_birth", label: "תאריך לידה" },
  ],
};

const REQUIRED_CHECKBOX = {
  name: "required",
  component: FormCheckBox,
  label: "שדה חובה",
  defaultValue: true,
};
const INPUT_LABEL_FIELD = (defaultValue = "") => ({
  name: "label",
  component: FormTextField,
  label: "כותרת השדה",
  defaultValue,
  rules: { required: REQUIRED_MESSAGE },
});

const PARTS = {
  [PART_TYPE_TEXT]: {
    label: "טקסט",
    saveAs: (value) => {
      return !value ? "" : typeof value === "string" ? value : value.id;
    },
    defaultValue: (value) =>
      WORK_AGREEMENT_DEFAULT_VALUES_OPTIONS.text.find(
        ({ id }) => id === value
      ) ?? value,
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("טקסט"),
      {
        name: "value",
        component: ControlAutoComplete,
        options: WORK_AGREEMENT_DEFAULT_VALUES_OPTIONS.text,
        freeSolo: true,
        autoSelect: true,
        blurOnSelect: true,
        inputProps: { label: "ערך ברירת מחדל" },
      },
    ],
    show: { component: FormTextField, fullWidth: true },
  },
  [PART_TYPE_NUMBER]: {
    label: "מספר",
    saveAs: () => "",
    defaultValue: () => "מספר",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("מספר"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "מספר",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "number", fullWidth: true },
  },
  [USER_QUESTION]: {
    label: "שאלת עובד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שאלת עובד"),
      {
        name: "value",
        component: FormTextField,
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: SelectComponent,
      size: "small",
      options: ["כן", "לא"],
    },
  },
  [PART_TYPE_DATE]: {
    label: "תאריך",
    saveAs: () => moment().format("YYYY-MM-DD"),
    defaultValue: () => "תאריך",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        placeholder: "תאריך",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_TYPE_START_DATE]: {
    // id: PART_TYPE_START_DATE,
    label: "תאריך תחילת עבודה",
    saveAs: () => "",
    defaultValue: () => "תאריך תחילת עבודה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך תחילת עבודה"),
      {
        name: "value",
        component: FormTextField,

        placeholder: "תאריך תחילת עבודה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,
      type: "date",
      rules: {
        validate: (v) => {
          const today = new Date();
          const date = new Date(v);
          return today > date || "תאריך תחילת עבודה לא יכול להיות עתידי";
        },
      },
    },
  },

  [PART_TYPE_BANK_NAME]: {
    label: "שם הבנק",
    saveAs: () => "employee_info.bank_name",
    defaultValue: () => "שם הבנק",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם בנק"),
      {
        name: "value",
        component: FormTextField,

        placeholder: "שם הבנק",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,
    },
  },
  [PART_TYPE_BANK_BRANCH_NUMBER]: {
    label: "מס סניף",
    saveAs: () => "employee_info.bank_branch_number",
    defaultValue: () => "מס סניף",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("מס סניף"),
      {
        name: "value",
        component: FormTextField,

        placeholder: "מס סניף",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,

      type: "number",
    },
  },
  [PART_TYPE_BANK_BRANCH_NAME]: {
    label: "שם סניף בנק",
    saveAs: () => "employee_info.bank_branch_name",
    defaultValue: () => "שם סניף בנק",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם סניף בנק"),
      {
        name: "value",
        component: FormTextField,

        placeholder: "שם סניף בנק",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,

      type: "text",
    },
  },
  [PART_TYPE_ACCONUT_NUMBER]: {
    label: "מס חשבון",
    saveAs: () => "employee_info.account_number",
    defaultValue: () => "מס חשבון",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("מס חשבון"),
      {
        name: "value",
        component: FormTextField,

        placeholder: "מס חשבון",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,

      type: "number",
    },
  },

  [PART_TYPE_TEL]: {
    label: "טלפון",
    saveAs: () => "",
    defaultValue: () => "טלפון",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("טלפון"),
      {
        name: "value",
        component: FormTextField,

        placeholder: "טלפון",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,
      type: "tel",
      rules: {
        pattern: {
          value: new RegExp(/^\d{10}$/),
          message: "מספר טלפון לא תקין יש לנסות שוב",
        },
      },
    },
  },
  [PART_TYPE_EMAIL]: {
    label: "מייל",
    saveAs: () => "email",
    defaultValue: () => "מייל",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("מייל"),
      {
        name: "value",
        component: FormTextField,

        placeholder: "מייל",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,
      type: "email",
    },
  },
  [PART_TYPE_SIGNATURE]: {
    label: "חתימה",
    saveAs: () => "",
    defaultValue: () => "חתימה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("חתימה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "חתימה",
        disabled: true,
      },
    ],
    show: { component: ControlSignaturePad },
  },
  [PART_TYPE_IDENTIFER]: {
    label: "ת.ז / דרכון",
    saveAs: () => "identifier",
    defaultValue: () => "ת.ז / דרכון",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("ת.ז / דרכון"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "ת.ז / דרכון",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,

      rules: {
        pattern: {
          value: new RegExp(/^\d{0,9}$/),
          message: "מספר ת.ז. לא תקין יש לנסות שוב",
        },
      },
    },
  },
  [PART_TYPE_SALARY]: {
    label: "שכר",
    saveAs: () => "salary",
    defaultValue: () => "שכר",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שכר"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "שכר",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,
      type: "number",
      disabled: true,
      InputProps: {
        endAdornment: <BiShekel />,
      },
    },
  },
  [PART_TYPE_TRAVEL]: {
    label: "נסיעות",
    saveAs: () => "employee_info.travel_cost",
    defaultValue: () => "נסיעות",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("נסיעות"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "נסיעות",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,
      type: "number",
      disabled: true,
      InputProps: {
        endAdornment: <BiShekel />,
      },
    },
  },
  [PART_TYPE_GLOBALBASE]: {
    label: "תוספת שעות נוספות גלובאלית",
    saveAs: () => "employee_info.global_cost",
    defaultValue: () => "תוספת שעות נוספות גלובאלית",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תוספת שעות נוספות גלובאלית"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "תוספת שעות נוספות גלובאלית",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "number",
      fullWidth: true,
      disabled: true,
      InputProps: {
        endAdornment: <BiShekel />,
      },
    },
  },
  [PART_TYPE_EXTRA_SHABAT]: {
    label: "תוספת שעות נוספות שבת",
    saveAs: () => "employee_info.extra_shabat",
    defaultValue: () => "תוספת שעות נוספות שבת",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תוספת שעות נוספות שבת"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "תוספת שעות נוספות שבת",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "number",
      fullWidth: true,
      disabled: true,
      InputProps: {
        endAdornment: <BiShekel />,
      },
    },
  },
  [PART_TYPE_FORCED_WORK]: {
    label: "תמריץ עבודה מאומצת",
    saveAs: () => "employee_info.forced_work_stimulant",
    defaultValue: () => "תמריץ עבודה מאומצת",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תמריץ עבודה מאומצת"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "תמריץ עבודה מאומצת",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "number",
      fullWidth: true,
      disabled: true,
      InputProps: {
        endAdornment: <BiShekel />,
      },
    },
  },

  [PART_TYPE_HEALTH]: {
    label: "הבראה",
    saveAs: () => "employee_info.health_cost",
    defaultValue: () => "הבראה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("הבראה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "הבראה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "number",
      fullWidth: true,

      disabled: true,
      InputProps: {
        endAdornment: <BiShekel />,
      },
    },
  },
  [PART_TYPE_HOLIDAY]: {
    label: "חופשה",
    saveAs: () => "employee_info.holiday_cost",
    defaultValue: () => "חופשה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("חופשה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "הבחופשהראה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "number",
      fullWidth: true,

      disabled: true,
      InputProps: {
        endAdornment: <BiShekel />,
      },
    },
  },
  [PART_TYPE_CONST_TRAVEL]: {
    label: "נסיעות קבוע",
    saveAs: () => "employee_info.const_travel_cost",
    defaultValue: () => "נסיעות קבוע",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("נסיעות קבוע"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "נסיעות קבוע",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "number",
      disabled: true,
      fullWidth: true,

      InputProps: {
        endAdornment: <BiShekel />,
      },
    },
  },
  [PART_TYPE_EMPLOYEE_TYPE_TITLE]: {
    label: "תפקיד",
    saveAs: () => "type_info.title",
    defaultValue: () => "תפקיד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תפקיד"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "תפקיד",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      disabled: true,
    },
  },
  [PART_TYPE_FULL_NAME]: {
    label: "שם מלא",
    saveAs: () => "full_name",
    defaultValue: () => "שם מלא",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם מלא"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "שם מלא",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,

      type: "text",
      disabled: true,
    },
  },
  [PART_CLOTH]: {
    label: "מחיר פריט",
    extraData: [
      { name: "label", component: FormTextField, label: "שם פריט" },
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        label: "מחיר",
        rules: { required: REQUIRED_MESSAGE },
        type: "number",
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,
      type: "text",
      disabled: true,
    },
  },
  [PART_TYPE_FIRST_NAME]: {
    label: "שם פרטי",
    saveAs: () => "first_name",
    defaultValue: () => "שם פרטי",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם פרטי"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "שם פרטי",
        disabled: true,
      },
    ],
    show: {
      fullWidth: true,

      component: FormTextField,
      type: "text",
      disabled: true,
    },
  },
  [PART_TYPE_LAST_NAME]: {
    label: "שם משפחה",
    saveAs: () => "last_name",
    defaultValue: () => "שם משפחה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("משפחה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "שם משפחה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,

      disabled: true,
    },
  },
  [PART_TYPE_STREET]: {
    label: "כתובת",
    saveAs: () => "address",
    defaultValue: () => "כתובת",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("כתובת"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "כתובת",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,

      type: "text",
      // disabled: true,
    },
  },
  //This 3 below is new .

  [PART_TYPE_FILE]: {
    // id: PART_TYPE_FILE,
    label: "קובץ",
    saveAs: ([value]) => {
      if (!(value instanceof File)) {
        return;
      }
      return value;
    },
    defaultValue: (value) => (value ? [value] : []),
    extraData: [
      { name: "label", component: FormTextField, label: "כותרת מקטע" },
      {
        name: "value",
        component: ControlDnd,
        label: "קובץ",
        rules: { required: REQUIRED_MESSAGE },
      },
    ],
    show: PreviewFile,
  },
  [PART_TYPE_LINK]: {
    // id: PART_TYPE_LINK,
    label: "קישור ליוטיוב",
    saveAs: (value) => {
      const videoId = new URL(value).searchParams.get("v");
      if (!videoId) {
        return value;
      } else {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    },
    extraData: [
      { name: "label", component: FormTextField, label: "כותרת מקטע" },
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        label: "קישור ליוטיוב",
        rules: {
          required: REQUIRED_MESSAGE,
          pattern: {
            value: new RegExp(
              "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
            ),
            message: "שדה זה חייב להיות קישור",
          },
        },
      },
    ],
    show: "iframe",
  },
  [EXTRA_QUESTION]: {
    label: "שאלה נלווית",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שאלה נלווית"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "שאלה נלווית",
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: FormTextField,
      fullWidth: true,
    },
  },
  [USER_FILE_UPLOAD]: {
    label: "העלאת קובץ על ידי המשתמש",
    saveAs: ([value]) => {
      if (!(value instanceof File)) {
        return;
      }
      return value;
    },
    defaultValue: (value) => (value ? [value] : []),

    extraData: [
      { name: "label", component: FormTextField, label: "כותרת הקובץ" },
      {
        name: "value",
        component: ControlDnd,
        label: "קובץ",
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: ControlDnd,
      accept: ["image/jpg", "image/jpeg", "image/png"],
    },
  },

  [PART_TYPE_COMPANY_NAME]: {
    // id: PART_TYPE_START_DATE,
    label: "שם החברה",
    saveAs: () => "branch.company_name",
    defaultValue: () => "שם החברה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם החברה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "שם החברה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      fullWidth: true,
      disabled: true,
    },
  },
  [PART_TYPE_COMPANY_ADDRESS]: {
    label: "כתובת החברה",
    saveAs: () => "branch.company_address",
    defaultValue: () => "כתובת החברה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("כתובת החברה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "כתובת החברה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,
      disabled: true,
    },
  },

  [PART_TYPE_COMPANY_H_P]: {
    label: "ח.פ",
    saveAs: () => "branch.company_h_p",
    defaultValue: () => "ח.פ",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("ח.פ"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "ח.פ",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,
      disabled: true,
    },
  },
};

const INPUT = "שדה";

export {
  PARTS,
  INPUT,
  PART_TYPE_SIGNATURE,
  PART_TYPE_TEXT,
  PART_TYPE_NUMBER,
  PART_TYPE_DATE,
  PART_TYPE_START_DATE,
  PART_TYPE_TEL,
  PART_TYPE_EMAIL,
  PART_TYPE_IDENTIFER,
  PART_TYPE_SALARY,
  PART_TYPE_EMPLOYEE_TYPE_TITLE,
  PART_TYPE_FULL_NAME,
  PART_TYPE_FIRST_NAME,
  PART_TYPE_LAST_NAME,
  PART_TYPE_STREET,
  PART_TYPE_ACCONUT_NUMBER,
  PART_TYPE_BANK_BRANCH_NAME,
  PART_TYPE_BANK_BRANCH_NUMBER,
  PART_TYPE_BANK_NAME,
  PART_TYPE_TRAVEL,
  PART_TYPE_CONST_TRAVEL,
  PART_TYPE_GLOBALBASE,
  PART_TYPE_HEALTH,
  PART_TYPE_HOLIDAY,
  PART_TYPE_COMPANY_NAME,
  PART_TYPE_COMPANY_ADDRESS,
  PART_TYPE_COMPANY_H_P,
};
