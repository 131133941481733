import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ReactTable from "../../components/tables/ReactTable";
import { dataAnalyzeColumns } from "./consts";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const DataAnalyzeTable = (props) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const dataAnalyzeData = useSelector(
    (state) => state.dataAnalyze.summary_table
  );
  const isLoading = useSelector((state) => state.dataAnalyze.isLoading);
  const branches = useSelector((state) => state.branches.branches);


  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Grid item xs={12}>
      <ReactTable
        {...{
          columns: dataAnalyzeColumns,
          isExportedToExcel: isMobile ? false : true,
          title: "ניתוח לפי סניף",
          cellsPropsById: (id) => {
            return { branches };
          },
          //   aggregates: category_tables_aggregates,
          collapsedSearch: isMobile ? true : false,
          data: dataAnalyzeData,
          isPaginate: true,
          translation_endpoint: "dataAnalyze",

          alignToCenter: true,
          loading: isLoading,
          showFooter: true,
          sortBy: [{ id: "total_income", desc: true }],
        }}
      />
    </Grid>
  );
};

export default DataAnalyzeTable;
