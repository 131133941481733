import React, { useMemo } from "react";
import { getSafe, thousandSeparator, twoZeroAfterDot } from "../../../helpers";
import { fixPrice } from "../../../helpers";

const getSingular = (word) => {
  var plural_to_single_dict = {
    בקבוקים: "בקבוק",
    יחידות: "יחידה",
    בלוקים: "בלוק",
    שקיות: "שקית",
    קופסאות: "קופסא",
    חבילות: "חבילה",
    פחיות: "פחית",
  };
  if (plural_to_single_dict[word]) {
    return plural_to_single_dict[word];
  } else {
    return word;
  }
};

export const items_columns = [
  {
    Header: 'מק"ט',
    accessor: "catalog_number",
    style: { textAlign: "center" },
    Footer: ({ data }) => {
      return (
        <p style={{ textAlign: "center" }}>
          <span>{`סה״כ: ${data.length}`}</span>
        </p>
      );
    },
  },
  {
    Header: "מוצר",
    accessor: "name",
  },
  {
    Header: ({ show_price }) => (show_price ? "מחיר" : ""),
    accessor: "price",
    Cell: (props) => {
      const { show_price } = props;

      const { price, deposit } = props.row.original;
      // cant do base amount is_package (by_scale) is not saved in order item
      return (
        show_price && (
          <span className="is-number">{(price + deposit).toFixed(2)}</span>
        )
      );
    },
  },
  {
    Header: "כמות",
    accessor: "amount",
    style: { textAlign: "center" },
    Cell: ({ row }) => <p className="left-to-right">{row.original.amount}</p>,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.amount + sum, 0),
        [self.rows]
      );

      return <p className="left-to-right">{twoZeroAfterDot(total)}</p>;
    },
  },
  {
    Header: ({ show_price, isMobile }) => (show_price ? "עלות" : ""),
    accessor: "total_price",
    style: { textAlign: "center" },
    Cell: (props) => {
      const { row, show_price } = props;
      // const { amount, price_after_discount } = row.original;
      const { amount, price, deposit } = row.original;

      return (
        show_price && (
          <span className="is-number">
            {(amount * (price + deposit)).toFixed(2)}
          </span>
        )
      );
    },
    Footer: (self) => {
      const { rows, show_price } = self;
      const total = useMemo(
        () =>
          rows.reduce((sum, row) => {
            // const { amount, price_after_discount } = row.original;
            const { amount, price, deposit } = row.original;
            return Number(amount) * (Number(price) + Number(deposit)) + sum;
          }, 0),
        [rows]
      );

      return show_price ? (
        <p className="left-to-right">
          <span>₪</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </p>
      ) : (
        " - "
      );
    },
  },
];
