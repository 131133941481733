import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  tableCell: {
    "& .muirtl-1u4xsr6-MuiTableCell-root": {
      padding: "4px",
    },
  },
}));
