import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import { createElement, useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BiShekel } from "react-icons/bi";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { fetchEmployeesBySendingId } from "../../../redux/actions/scheduleAction/index";
import { Context } from "../Show";
import React from "react";
import { dateReqFormat } from "../../../helpers";
import { useStyles } from "./terminationForm";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========

// ======== ICONS ========

// ======== CONSTANTS ========
import { TYPE_TERMINATION_FORM } from "../consts";
import {
  PART_WHEN_HEARING_DATE,
  PART_WHEN_TERMINATION_DATE,
  PART_TYPE_IDENTIFER,
  PART_WORKER_QUESTION,
  PART_END_NOTICE_PERIOD,
  PART_EMPLOYEE_NAME,
  PARTS,
} from "./consts";

// ======== COMPONENTS ========
import WorkTerminatinoShow from "./WorkTerminatinoShow";

export const Show = (props) => {
  // ======== HOOKS ========
  const { parts, user, selectedBranch, procedure } = useContext(Context);
  const { control, setValue, watch, getValues } = useFormContext();
  const { replace } = useFieldArray({ control, name: "parts" });
  const signature = useWatch({ control, name: "signature" });
  const branchEmployees = useSelector((state) => state.employees);
  const currentBranch = useSelector((state) => state.branches.selected_branch);
  const branchAddress = useSelector(
    (state) => state.auth.user.branch.company_address
  );
  const [employeeCompanyName, setEmployeeCompanyName] = useState(" ");
  const { branches } = useSelector((state) => state);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("s"));
  const dispatch = useDispatch();
  const [employee_name, date] = watch([`parts.${PART_EMPLOYEE_NAME}.value`]);

  // ======== STATES ========
  const [identifier, setIdentifier] = useState("");
  const [emails, setEmails] = useState([]);
  const [terminationDate, setTerminationDate] = useState("");
  const [hearingDate, setHearingDate] = useState("");
  const [endNoticeDate, setEndNoticeDate] = useState("");
  const [needWorkPeriod, setNeedWorkPeriod] = useState("");
  const [procedureBranches, setProcedureBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState(null);

  const classes = useStyles();

  // ======== HANDLERS ========
  const handleBranchChange = (e, id, index) => {
    setBranchSelected(e.label);
    dispatch(fetchEmployeesBySendingId(e.id));
  };
  const handleEmployeeChange = (e, id, index) => {
    const identifierId = parts.find(
      (item) => item.type === PART_TYPE_IDENTIFER
    );
    setValue(`parts.${index}`, { id, value: e.label });
    setValue("employee_id", e.id);
    setEmployeeCompanyName(e.company_name);
    if (identifierId) {
      setValue(`parts.${PART_TYPE_IDENTIFER}`, {
        id: identifierId.id,
        value: e.identifier,
      });
      setIdentifier(e.identifier);
    }
  };
  const debouncedData = debounce(
    (e, id, index) => {
      if (index == PART_WORKER_QUESTION) {
        setValue(`parts.${index}`, { id, value: e });
      } else {
        setValue(`parts.${index}`, { id, value: e.value });
        switch (e.name) {
          case `parts.${PART_TYPE_IDENTIFER}.value`:
            setIdentifier(e.value);
            break;
          case `parts.${PART_WHEN_HEARING_DATE}.value`:
            setHearingDate(e.value);
            break;
          case `parts.${PART_WHEN_TERMINATION_DATE}.value`:
            setTerminationDate(e.value);
            break;
          case `parts.${PART_END_NOTICE_PERIOD}.value`:
            setEndNoticeDate(e.value);
            break;
          default:
            return;
        }
      }
    },
    [100]
  );
  const validateWordCount = (value, maxChars) => {
    const trimmedValue = value ? value.trim() : "";

    return (
      trimmedValue.length <= maxChars ||
      `אפשר עד מקסימום ${maxChars} תווים בשדה זה.`
    );
  };
  const memoEmployess = useMemo(() => {
    return Object.entries(branchEmployees).map(([key, value]) => {
      return {
        id: key,
        label: value.full_name + ", " + value.department_name,
        name: value.full_name,
        identifier: value.employee_ID_Number,
        department: { id: value.department_id, label: value.department_name },
        salary: value.employee_base_sal,
        company_name: value.company_name,
      };
    });
  }, [branchEmployees]);

  // ======== CONSTANTS ========

  // ======== EFFECTS ========
  useEffect(() => {
    const updatedBranches = branches.branches.map((branch) => ({
      id: branch.id,
      label: branch.name,
    }));
    setProcedureBranches(updatedBranches);
  }, []);

  // ======== UI ========

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: isTablet ? 0 : 50,
        justifyContent: "center",
        gap: "3rem",
      }}
    >
      <div
        style={{
          margin: isTablet ? 0 : 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "3rem",
          fontSize: isMobile ? "1.8rem" : "2rem",
          maxWidth: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography>שם סניף</Typography>
            <ControlAutoComplete
              {...{
                fullWidth: true,
                name: `branch`,
                rules: { required: REQUIRED_MESSAGE },
                options: procedureBranches,
                handleInputChange: (value) => {
                  handleBranchChange(value);
                },
              }}
            />
          </Grid>
          {parts?.map(({ label, value, type, ...part }, index) => {
            switch (type) {
              case PART_EMPLOYEE_NAME:
                return (
                  <Grid item xs={6}>
                    <Typography>שם העובד</Typography>
                    <ControlAutoComplete
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        options: memoEmployess,
                        handleInputChange: (value) =>
                          handleEmployeeChange(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_TYPE_IDENTIFER:
                return (
                  <Grid item xs={6}>
                    <Typography>תעודת זהות</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        id: part.id,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );

              case PART_WHEN_HEARING_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography> תאריך שימוע</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        max: dateReqFormat(
                          new Date() // Maxium is today
                        ),
                      }}
                    />
                  </Grid>
                );

              case PART_WHEN_TERMINATION_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography>תאריך סיום עבודה</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        min: dateReqFormat(
                          new Date() // minimum is today (can be only in future )
                        ),
                      }}
                    />
                  </Grid>
                );
              case PART_END_NOTICE_PERIOD:
                return (
                  <Grid item xs={6}>
                    <Typography>תאריך תום הודעה מוקדמת</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        min: dateReqFormat(
                          new Date() // minimum is today (can be only in future )
                        ),
                      }}
                    />
                  </Grid>
                );

              case PART_WORKER_QUESTION:
                return (
                  <Grid item xs={6}>
                    <Typography>
                      האם העובד מתבקש לעבוד את תקופת ימי הודעה מקודמת
                    </Typography>
                    <input
                      type="checkbox"
                      checked={needWorkPeriod === true}
                      onChange={(value) => {
                        setNeedWorkPeriod(true);

                        debouncedData("אתה מתבקש", part.id, type);
                      }}
                    />
                      <label>כן</label>
                    <br></br>
                    <input
                      type="checkbox"
                      checked={needWorkPeriod === false}
                      onChange={(value) => {
                        setNeedWorkPeriod(false);
                        debouncedData("אינך מתבקש", part.id, type);
                      }}
                    />
                      <label>לא</label>
                  </Grid>
                );
            }
          })}
        </Grid>
        <Divider style={{ width: "100%" }} />
        <WorkTerminatinoShow
          employee_name={employee_name}
          hearingDate={hearingDate}
          terminationDate={terminationDate}
          needWorkPeriod={needWorkPeriod}
          endNoticeDate={endNoticeDate}
          company_name={employeeCompanyName}
        />
      </div>
    </div>
  );
};

export default Show;
