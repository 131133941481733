import React, { useState, useRef, useEffect } from "react";
import { isEmpty } from "lodash";
import { isNumeric } from "validator";
import Button from "../buttons/Button";

const HoursInput = ({
  errors = {},
  setErrors,
  getClock,
  defaultHour = null,
  disabled = false,
  resetDisabled = false,
  className = "",
}) => {
  const [hour, setHour] = useState("--");
  const [min, setMin] = useState("--");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (defaultHour) {
      let parse = defaultHour.split(":");
      setHour(parse[0]);
      setMin(parse[1]);
      if (!disabled) {
        getClock(`${parse[0]}:${parse[1]}`);
      }
    } else if (!disabled) getClock(null);
  }, [defaultHour]);

  const input1 = useRef("input1");

  const handleMinChange = (e, save) => {
    e.preventDefault();
    let { value } = e.target;
    if (value.startsWith("--")) {
      value = value.substring(2); // Remove the first two characters
    }
    if (!isNumeric(value)) return;

    if (value.length === 1) {
      value = `0${value}`;
    } else if (value.length === 2) {
      if (value >= 0 && value < 24) {
      } else if (value > 59) value = "00";
      else value = 59;
    } else {
      if (value >= 0 && value < 60) {
        value = value.substr(1);
      } else if (value > 59) {
        value = "00";
      } else save(59);
    }
    save(value);
    if (hour === "--") setHour("00");
    getClock(`${hour === "--" ? "00" : hour}:${value}`);
  };

  const handleHourChange = (e, save, ref) => {
    e.preventDefault();
    let { value } = e.target;
    if (value.startsWith("--")) {
      value = value.substring(2); // Remove the first two characters
    }
    if (!isNumeric(value)) return;
    if (value.length === 1) {
      value = `0${value}`;
    } else if (value.length === 2) {
      if (value >= 0 && value < 24) {
      } else if (value > 23) value = "00";
      else value = 23;
      ref.current.focus();
    } else {
      if (value >= 0 && value < 24) {
        value = value.substr(1);
      } else if (value > 23) {
        value = "00";
      } else value = 23;
      ref.current.focus();
    }
    save(value);
    if (min === "--") setMin("00");
    getClock(`${value}:${min === "--" ? "00" : min}`);
  };

  const handleFocus = (e) => {
    e.target.select();
    setIsFocused(true);
    if (!isEmpty(errors)) {
      if (errors.hours) {
        setErrors({});
      }
    }
  };

  const reset = () => {
    setMin("--");
    setHour("--");
    getClock(null);
  };

  return (
    <div
      className={`flex-column-center ${className}`}
      style={{ lineHeight: "1.4" }}
    >
      <div
        className={`hours-input ${disabled ? "hours-input--disabled" : ""} ${
          isFocused ? "hours-input--focused" : ""
        }`}
      >
        <input
          disabled={disabled}
          ref={input1}
          type="text"
          name="hour"
          value={hour}
          onChange={(e) => handleHourChange(e, setHour, input1)}
          onFocus={(e) => handleFocus(e)}
          onBlur={() => setIsFocused(false)}
        />
        <div>:</div>
        <input
          disabled={disabled}
          ref={input1}
          type="text"
          name="min"
          value={min}
          onChange={(e) => handleMinChange(e, setMin)}
          onFocus={(e) => handleFocus(e)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      {disabled || (min === "--" && hour === "--") || resetDisabled ? (
        ""
      ) : (
        <Button
          onClick={() => reset()}
          style={{ fontSize: "1rem" }}
          className="btn--hyperlink"
          textValue="איפוס"
        />
      )}
    </div>
  );
};

export default HoursInput;
