export const BY_PERMANENT_PASS = 0;
export const BY_OTP = 1;

// TABS PATHS
export const TAB_PATH_BY_OTP = "personalData";
export const TAB_PATH_BY_PERMANENT_PASS = "byEmail";
export const TAB_OPTIONS = [
  {
    id: BY_PERMANENT_PASS,
    label: "באמצעות סיסמה",
    path: TAB_PATH_BY_PERMANENT_PASS,
  },
  {
    id: BY_OTP,
    label: "באמצעות קוד חד פעמי",
    path: TAB_PATH_BY_OTP,
  },
];
