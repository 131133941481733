import React, { useEffect, useState } from "react";
import { aggregateBy,process } from "@progress/kendo-data-query";
import DownloadExcel from "./DownloadExcel";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useScreenSize } from "../../Hooks";

const MultiExcelFile = ({
  fileName,
  aggregates,
  data,
  columns,
  buttonStyle = null,
  style = {},
}) => {
  const {isMobile} = useScreenSize()
  const [arrangedData, setArrangedData] = useState({});
  const group = [{ field: "file_title" },{ field: "employee_name",aggregates }];
  useEffect(() => {
    const arrangeData = async (data) => {
      const arrangedData = await [].concat.apply(
        [],
        data.map((table) =>
          table.body.map((columns) => {
            let temp = {};
            aggregates.forEach((x) => {
              if (x.aggregate !== "count") {
                if( columns[x.field]){
                  temp[x.field] = Number(columns[x.field].toFixed(2));
                }
              }

            });
            return {
              ...columns,
              ...temp,
              week_day: I18n.t(`excel.day.${columns.week_day}`),
            };
          }).sort((a,b)=>  Date.parse(a.date) - Date.parse(b.date))
        )
      );
      setArrangedData({
        data:  process([].concat.apply([],arrangedData), { group: group }).data,
        aggregates: aggregateBy(arrangedData, aggregates),
      });
    };
    arrangeData(data);
  }, [data, aggregates, columns]);

  return (
    <DownloadExcel
      isMobile={isMobile}
      fileName={fileName.replace(".","")}
      data={arrangedData.data}
      columns={columns}
      aggregates={arrangedData.aggregates}
      style={style}
      buttonStyle={buttonStyle}
      multi
      group={group}
    />
  );
};
const mapStateToProps = (state) => {
  const { isMobile } = state.publicValues;
  return { isMobile };
};
export default connect(mapStateToProps)(MultiExcelFile);
