import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from "date-fns";

// GENERAL - TABS
export const TAB_REQUIREMENTS_MANAGEMENT = 0;
export const TAB_REQUIREMENTS_BY_ITEM = 1;
// export const TAB_REQUIREMENTS_BY_BRANCH = 2;

// TABS PATHS
export const TAB_PATH_MANAGEMENT = "management";
export const TAB_PATH_BY_ITEM = "byItem";
// export const TAB_PATH_BY_BRANCH = "byBranch";

export const TAB_OPTIONS = [
  {
    id: TAB_REQUIREMENTS_MANAGEMENT,
    label: "ניהול דרישות",
    path: TAB_PATH_MANAGEMENT,
  },
  {
    id: TAB_REQUIREMENTS_BY_ITEM,
    label: "לפי מוצר",
    path: TAB_PATH_BY_ITEM,
  },
  // {
  //   id: TAB_REQUIREMENTS_BY_BRANCH,
  //   label: "לפי סניף",
  //   path: TAB_PATH_BY_BRANCH,
  // },
];

export const REQUISITION_IS_PENDING = 0;
export const REQUISITION_IS_APPROVED = 1;
export const REQUISITION_IS_PARTIALLY_APPROVED = 2;
export const REQUISITION_IS_IGNORED = 3;

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfNextWeek: startOfWeek(addDays(new Date(), 7)),
  endOfNextWeek: endOfWeek(addDays(new Date(), 7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfTomorrow: startOfDay(addDays(new Date(), 1)),
  endtOfTomorrow: endOfDay(addDays(new Date(), 1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

const createStaticRanges = (ranges) => {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
};

export const hebStaticRanges = createStaticRanges([
  {
    label: "היום",
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "מחר",
    range: () => ({
      startDate: defineds.startOfTomorrow,
      endDate: defineds.endtOfTomorrow,
    }),
  },

  {
    label: "השבוע",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "שבוע הבא",
    range: () => ({
      startDate: defineds.startOfNextWeek,
      endDate: defineds.endOfNextWeek,
    }),
  },
  {
    label: "החודש",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
]);
