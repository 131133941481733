export const IDENTIFIER = 1;
export const PASSPORT = 2;
export const AGREE_TYPE = 1;
export const DISAGREE_TYPE = 2;
export const GENDER_MALE_TYPE = 1;
export const GENDER_FEMALE_TYPE = 2;
export const TYPE_SEPERATED = 5;
//section4
export const TYPE_ANOTHER_INCOME = 2;
//Section5
export const TYPE_OTHER_INCOME = 2;
export const TYPE_NO_OTHER_INCOME = 1;
export const TYPE_CREDIT = 1;
export const salaryOptions = [
  { name: "otherIncomeExtraMonth", value: "משכורת חודש" },
  { name: "otherIncomeExtraAnother", value: "משכורת בעד משרה נוספת" },
  { name: "otherIncomeExtraPartial", value: "משכורת חלקית" },
  { name: "otherIncomeExtraDaily", value: "שכר עבודה(עובד יומי)" },
  { name: "otherIncomeExtraAllowance", value: "קצבה" },
  { name: "otherIncomeExtraSP", value: "מלגה" },
];
export const allowanceOptions = [
  {
    name: "allowanceEdfund",
    value:
      "אין מפרישים עבורי לקרן השתלמות בגין הכנסתי האחרת, או שכל הפרשות המעביד לקרן השתלמות בגין הכנסתי האחרת מצורפות להכנסתי האחרת.",
  },
  {
    name: "allowancePension",
    value:
      "אין מפרישים עבורי לקצבה/לביטוח אובדן כושר עבודה/פיצויים בגין הכנסתי האחרת, או שכל הפרשות המעביד לקצבה/לביטוח אובדן כושר עבודה/פיצויים בגין הכנסתי האחרת מצורפות להכנסתי האחרת",
  },
];

//Section6
export const TYPE_MARRIED = 2;
export const TYPE_SPOUSE_INCOME = 1;
export const spouseIncomeOptions = [
  { name: "spouseIncomeAllowance", value: "עבודה/קיצבה/עסק" },
  { name: "spouseIncomeOther", value: "הכנסה אחרת" },
];

//Section8

export const checkBoxData = [
  {
    name: "isCitizen",
    disabled: true,
    label: "אני תושב/ת ישראל",
    isToolTip: true,
    tooltipText:
      "מסומן אוטומטית אם סימנת שהינך תושב/ת ישראל.״ יש להוסיף קישור לאתר ״כל זכות \n <a target='_blanc' href='https://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%AA%D7%95%D7%A9%D7%91_%D7%99%D7%A9%D7%A8%D7%90%D7%9C'>קרא על זיכוי זה</a>",
  },
  {
    name: "isDisability",
    disabled: false,
    label: "אני נכה 100% / עיוור/ת לצמיתות",
    listen: "otherIncome",
  },
  {
    name: "isQualifySettlement",
    disabled: false,
    label:
      "אני תושב/ת קבוע/ה בישוב מזכה. אני ובני משפחתי מדרגה ראשונה, מתגוררים ביישוב ואין לי 'מרכז חיים' נוסף",
    isToolTip: true,
    tooltipText:
      "<strong>ישוב מזכה</strong>  שחל עליו סעיף 11 לפקודת מס הכנסה או סעיף 11 לחוק אס״ח, לפי העניין. <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%AA%D7%95%D7%A9%D7%91%D7%99%D7%9D_%D7%91%D7%A4%D7%A8%D7%99%D7%A4%D7%A8%D7%99%D7%94'>קרא על זיכוי באתר כל זכות</a>  ",
  },
  {
    name: "isNewcomer",
    disabled: false,
    label: "אני עולה חדש/ה",
    isToolTip: true,
    tooltipText:
      "קרא על זיכוי זה באתר <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%A2%D7%95%D7%9C%D7%94_%D7%97%D7%93%D7%A9'>כל זכות</a>",
  },
  {
    name: "isSpouse",
    disabled: false,
    label: "בגין בן/בת זוגי המתגורר/ת עימי ואין לו/לה הכנסות בשנת המס",
    subLabel:
      "רק אם העובד/ת או בן/בת הזוג הגיע/ה לגיל פרישה או שהוא/היא נכה או עיוור/ת עפ״י סעיף 9(5) לפקודה",
  },
  {
    name: "isSingleParent",
    disabled: false,
    label: "אני הורה במשפחה חד הורית החי בנפרד ",
    subLabel:
      'ימולא רק ע"י הורה כאמור החי בנפרד ומבקש נקודות זיכוי עבור ילדיו, הנמצאים בחזקתו ובגינם מקבל קצבת ילדים מהמוסד לביטוח לאומי (בהתאם לסעיף 7 להלן) ואינו מנהל משק בית משותף עם יחיד/ה אחר/ת',
    isToolTip: true,
    tooltipText:
      "<strong>הורה במשפחה חד הורית הוא אחד מאלה</strong>:רווק,גרוש,אלמן,פרוד (עפ״י אישור פקיד שומה בלבד).",
  },
  {
    name: "isMyCustody",
    disabled: false,
    label: "בגין ילדי שבחזקתי המפורטים בחלק ג ",
    subLabel:
      'ימולא רק ע"י הורה במשפחה חד הורית שמקבל את קצבת הילדים בגינם, או ע"י אשה נשואה או ע"י הורה יחיד.',
    isToolTip: true,
    tooltipText:
      "<strong>הורה יחיד - </strong>הורה במשפחה חד הורית שהיה לו ילד שבשנת המס טרם מלאו לו 19 שנים ושההורה השני של הילד נפטר או שהילד רשום במרשם האוכלוסין בלא פרטי ההורה השני. קרא על זיכוי זה באתר כל זכות : <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%94%D7%95%D7%A8%D7%94_%D7%9C%D7%99%D7%9C%D7%93_%D7%A2%D7%93_%D7%92%D7%99%D7%9C_5'>נקודות זיכוי להורדה לילד עד גיל 5</a> <a href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%94%D7%95%D7%A8%D7%94_%D7%9C%D7%99%D7%9C%D7%93_%D7%A2%D7%93_%D7%92%D7%99%D7%9C_18' target='_blank'>נקודות זיכוי להורדה לילד עד גיל 18</a> ",
  },
  {
    name: "isMyKids",
    disabled: false,
    label: "בגין ילדי",
    subLabel:
      'ימולא ע"י הורה (למעט הורה אשר סימן בפסקה 7 לעיל), אשה לא נשואה שילדיה אינם בחזקתה וכן הורה יחיד ',
    isToolTip: true,
    tooltipText:
      "קרא על זיכוי זה באתר <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%94%D7%95%D7%A8%D7%94_%D7%92%D7%A8%D7%95%D7%A9_%D7%90%D7%95_%D7%A4%D7%A8%D7%95%D7%93_%D7%A2%D7%91%D7%95%D7%A8_%D7%92%D7%99%D7%93%D7%95%D7%9C_%D7%99%D7%9C%D7%93%D7%99%D7%95'>כל זכות</a>",
  },
  {
    name: "isSingleParentCustody",
    disabled: false,
    label: "אני הורה יחיד לילדי שבחזקתי (המפורטים בסעיף 7 ו-8 לעיל)",
  },
  {
    name: "isNotCustody",
    disabled: false,
    label: "בגין ילדי שאינם בחזקתי המפורטים בחלק ג ואני משתתף/ת בכלכלתם",
    subLabel:
      'ימולא ע"י הורה החי בנפרד, שאינו זכאי לנקודות זיכוי בגין ילדיו, אשר המציא פס"ד המחייב אותו בתשלום מזונות.    ',
    isToolTip: true,
    tooltipText:
      "קרא על זיכוי זה באתר <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%94%D7%95%D7%A8%D7%94_%D7%92%D7%A8%D7%95%D7%A9_%D7%90%D7%95_%D7%A4%D7%A8%D7%95%D7%93_%D7%A2%D7%91%D7%95%D7%A8_%D7%92%D7%99%D7%93%D7%95%D7%9C_%D7%99%D7%9C%D7%93%D7%99%D7%95'>כל זכות</a>",
  },
  {
    name: "isDisabilityKids",
    disabled: false,
    label:
      "אני הורה לילדים עם מוגבלות שטרם מלאו להם 19 שנים, בגינם אני מקבל/ת גמלת ילד נכה מהמוסד לביטוח לאומי",
    subLabel:
      "בן זוגי אינו מקבל נקודות זיכוי אלה. לילדי, בגינו אני מבקש את נקודות הזיכוי, אין הכנסות בשנה הנוכחית",
    isToolTip: true,
    tooltipText:
      "קרא על זיכוי זה באתר <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%91%D7%A2%D7%93_%D7%99%D7%9C%D7%93_%D7%90%D7%95_%D7%91%D7%95%D7%92%D7%A8_%D7%A2%D7%9D_%D7%9E%D7%95%D7%92%D7%91%D7%9C%D7%95%D7%AA'>כל זכות</a>",
  },
  {
    name: "isAlimony",
    disabled: false,
    label: "בגין מזונות לבן/בת זוגי לשעבר",
    subLabel: 'ימולא ע"י מי שנישא בשנית.',
    isToolTip: true,
    tooltipText:
      "קרא על זיכוי זה באתר <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%91%D7%92%D7%99%D7%9F_%D7%AA%D7%A9%D7%9C%D7%95%D7%9D_%D7%9E%D7%96%D7%95%D7%A0%D7%95%D7%AA_%D7%9C%D7%91%D7%9F_%D7%96%D7%95%D7%92_%D7%9C%D7%A9%D7%A2%D7%91%D7%A8'>כל זכות</a>",
  },
  {
    name: "isSixteen",
    disabled: false,
    label:
      "מלאו לי או לבן/בת זוגי 16 שנים וטרם מלאו לי או לבן/בת זוגי 18 שנים בשנת המס ",
    isToolTip: true,
    tooltipText:
      "קרא על זיכוי זה באתר <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%A0%D7%95%D7%A2%D7%A8_%D7%A2%D7%95%D7%91%D7%93'>כל זכות</a>",
  },
  {
    name: "isDischargedSoldier",
    disabled: false,
    label: "אני חייל/ת משוחרר/ת / שרתתי בשירות לאומי",
    isToolTip: true,
    tooltipText:
      "קרא על זיכוי זה באתר <a target='_blank' href='http://www.kolzchut.org.il/he/%D7%A0%D7%A7%D7%95%D7%93%D7%95%D7%AA_%D7%96%D7%99%D7%9B%D7%95%D7%99_%D7%9E%D7%9E%D7%A1_%D7%94%D7%9B%D7%A0%D7%A1%D7%94_%D7%9C%D7%97%D7%99%D7%99%D7%9C%D7%99%D7%9D_%D7%9E%D7%A9%D7%95%D7%97%D7%A8%D7%A8%D7%99%D7%9D_%D7%95%D7%9E%D7%A1%D7%99%D7%99%D7%9E%D7%99_%D7%A9%D7%99%D7%A8%D7%95%D7%AA_%D7%9C%D7%90%D7%95%D7%9E%D7%99-%D7%90%D7%96%D7%A8%D7%97%D7%99'>כל זכות</a>",
  },
  {
    name: "isFinishDegree",
    disabled: false,
    label: "בגין סיום לימודים לתואר אקדמי, סיום התמחות או סיום לימודי מקצוע.",
  },
];

//Section9
export const NO_INCOME_TYPE = 0;
export const EXTRA_INCOME_TYPE = 1;
export const OK_ASSENSSOR_TYPE = 2;

//BANKDetails
export const BANK_DETAILS = "bank_details";
export const DRIVE_LICENCE = "drive_licence";
export const BANK_DETAILS_EXISTS = 2;
