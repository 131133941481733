import { RootStore } from './RootStore';

import MainStore from './MainStore';
import DummyFormStore from './DummyFormStore'
import InventoryDashboardStore from './InventoryDashboardStore';
import DateRangePickerStore from './DateRangePickerStore';

export const createStore = (externalStores) => {
  return new RootStore({
    main: new MainStore(),
    dummyForm: new DummyFormStore(),
    inventoryDashboard: new InventoryDashboardStore(),
    dateRangePicker: new DateRangePickerStore(),
  });
};
