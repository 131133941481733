import ButtonIcon from "../../../components/buttons/ButtonIcon";
import { FaTrash } from "react-icons/fa";
import { TextField } from "@mui/material";

export const departmentColumns = [
  {
    Header: "מס' מחלקה",
    accessor: "department_name",
  },
  {
    Header: "ערך",
    accessor: "value",
    Cell: ({ row, handleChangeDepartmentValue }) => {
      const { original } = row;
      return (
        <TextField
          value={original.value}
          onChange={(e) => handleChangeDepartmentValue(e, original)}
          variant="outlined"
          size="small"
          type="number"
        />
      );
    },
  },
  {
    Header: "פעולות",
    accessor: "action",
    Filter: () => "",
    filter: "",
    width: 1,
    Cell: ({ row, handleDelete }) => {
      const { original } = row;
      return (
        <div style={{ width: "fit-content" }} className="flex-even">
          <ButtonIcon
            onClick={() => handleDelete(original)}
            className="btn--hover__confirm"
            icon={FaTrash}
            alt="מחיקה"
          />
        </div>
      );
    },
  },
];
