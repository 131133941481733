export const HIDE_PRICE_PRIVILEGE = 9;
export const EDIT_SCHEDULE_PRIVILEGE = 8;
export const DISABLE_NOTES_PRIVILEGE = 10;
export const MUST_PICK_DATE_PRIVILEGE = 38;
export const EDIT_INSIDE_REPORTS_PRIVILEGE = 49;
export const LIMIT_CHANGE_SCHEDULE_PRIVILEGE = 23;
export const CALC_TIPS_ONLY_PRIVILEGE = 25;
export const BLOCK_TO_SEE_OTHERS_PRIVILEGE = 20;
export const CAN_LOCK_DAYS_PRIVILEGE = 48;
export const CANT_EDIT_SELF_PRIVILEGE = 34;

export const SHOW_WAITER_REPORT_PRIVILEGE = 59;
export const NETWORK_SALARY_REPORT = 74;

export const NETWORK_MARKUP = "network_markup";

/// Do not use this file please !!!!!
/// use privilegesIndexes.js file instad !!!!!!!!!!!
// I copied all of te exports above to privilegesIndexes.js file but did not handle all imports of the constants above
//TODO: search all usages of above constants, change the import to the correct file (privilegesIndexes.js) and delete this file
