import {
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  CardHeader,
  IconButton,
  DialogTitle,
  Paper,
  Box,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import Form from "./Form";
import {
  TYPE_EXTRA_ACTIONS_POSITION_BOTTOM,
  TYPE_EXTRA_ACTIONS_POSITION_END,
  TYPE_EXTRA_ACTIONS_POSITION_TOP,
} from "./constants";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FormDialog = ({
  open,
  setOpen,
  title,
  avatar,
  subheader,
  close = () => {
    setOpen(false);
  },
  onSubmit = close,
  navigate,
  extraActionsPosition,
  children,
  fullWidth = false,
  fullScreen = false,
  maxWidth = "md",
  submitButtonText = "אישור",
  abortButtonText = "ביטול",
  defaultValues,
  formId, // required!
  noValidate,
}) => {
  const methods = useForm({ ...(!!defaultValues && { defaultValues }) });
  const navigateTo = useNavigate();
  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const submit = (data) => {
    onSubmit(data, methods);
  };
  const handleOnCloseWithNavigate = () => {
    navigateTo(navigate);
    close();
  };
  return (
    <Dialog {...{ open, fullWidth, maxWidth, fullScreen }}>
      <FormProvider {...methods}>
        <DialogTitle>
          <CardHeader
            {...{
              title,
              avatar,
              subheader,
            }}
            action={
              <IconButton onClick={close}>
                <AiOutlineClose />
              </IconButton>
            }
          />
        </DialogTitle>

        <DialogContent>
          <Box
            {...{ noValidate }}
            component="form"
            onSubmit={methods.handleSubmit(submit)}
            id={formId}
          >
            {children?.fields}
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent:
              extraActionsPosition === TYPE_EXTRA_ACTIONS_POSITION_END
                ? "space-between"
                : "",
            flexDirection:
              extraActionsPosition === TYPE_EXTRA_ACTIONS_POSITION_TOP
                ? "column"
                : extraActionsPosition === TYPE_EXTRA_ACTIONS_POSITION_BOTTOM
                ? "column-reverse"
                : "",
          }}
        >
          <div>{children?.extraActions}</div>
          <div
            style={{
              display: "flex",
              gap: 4,
            }}
          >
            <Button
              variant="contained"
              onClick={navigate ? handleOnCloseWithNavigate : close}
            >
              {abortButtonText}
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              form={formId}
            >
              {submitButtonText}
            </Button>
          </div>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default FormDialog;
