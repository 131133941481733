import {
  Card,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { BiShekel } from "react-icons/bi";
import {
  FaChevronDown,
  FaChevronUp,
  FaEye,
  FaPencilAlt,
  FaTrash,
  FaTrashRestore,
} from "react-icons/fa";
import { FiClock, FiDelete } from "react-icons/fi";
import { RiCoinFill, RiCoinsLine } from "react-icons/ri";
import ButtonIcon from "../../components/buttons/ButtonIcon";
import { ControlAutoComplete } from "../../components/Forms/inputs/AutoComplete";
import FormTextField from "../../components/Forms/inputs/FormTextField";
import SelectComponent from "../../components/Forms/inputs/Select";
import { useStyles } from "../../styles/ManagePositions";
import SimpleTable from "../orders/manageOrders/receiveOrder/SimpleTable";
import DaysTable from "./employeeType/DaysTable";
import MobileSalarySubType from "./employeeType/MobileSalarySubType";
import { FaCalendarAlt } from "react-icons/fa";

export const RED_COLOR = "#ee3131";
export const GREEN_COLOR = "#07980a";

export const TYPE_HOURLY = 2;
export const TYPE_DEPARTMENT = 3;

export const BASE_URL = "v2/manage_positions";

//Tips type

export const HOURLY_TIP = 1;
export const CONST_TIP = 3;
export const TIP_PER_SHIFT = 4;

//Salary types
export const SALARY_TYPE_HOURLY = 0;
export const SALARY_TYPE_POSITION = 1;
export const SALARY_TYPE_ADJUSTED = 2;
export const SALARY_TYPE_BASEEXTRA = 3;

//dayTypes
const TYPE_CONSIST = 1;
export const TYPE_PER_DAY = 2;

//night shifts types
const TYPE_BY_DEPARTMENT = 1;
const TYPE_BY_BRANCH = 2;
//Sub positions consts
const getTipsType = (type) => {
  switch (type) {
    case 0:
      return "לא משתתף בטיפ";
    case 1:
      return "לפי שעה";
    case 2:
      return "% מטיפ כולל";
    case 3:
      return "סכום קבוע לשעה";
    case 4:
      return "סכום קבוע למשמרת";
    case 5:
      return "לפי מחלקה בלבד";
    default:
      return "";
  }
};

export const FILTERS_STATUS = [
  {
    id: 1,
    label: "פעילים",
  },
  {
    id: 0,
    label: "לא פעילים",
  },
];

export const columns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <div className="test">
          <IconButton
            {...row.getToggleRowExpandedProps({})}
            aria-label="expand row"
            size="small"
            style={{ color: "inherit" }}
            className={row.isExpanded ? "subRowButton" : ""}
          >
            {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </IconButton>
        </div>
      ) : null,
  },
  {
    Header: "מחלקה",
    accessor: "title",
    displayMobile: true,
    Cell: ({ value }) => <>{value}</>,
  },
  {
    Header: "תפקידים",
    accessor: "shiftPositionsCount",
    displayMobile: true,
    Cell: ({ value }) =>
      value ? (
        <span style={{ color: "#00A2EA" }}>{value} תפקידים</span>
      ) : (
        <span style={{ color: "#00A2EA" }}>אין תפקידים</span>
      ),
  },
  {
    Header: "תבניות פעילות",
    accessor: "templates",
    displayMobile: true,
    Cell: ({ value }) => {
      return (
        <div>
          {value.salaryTemplate && (
            <Tooltip
              arrow
              style={{ marginLeft: "10px", fontSize: "18px" }}
              title="קיים תבנית שכר"
            >
              <span>
                <RiCoinsLine size={25} />
              </span>
            </Tooltip>
          )}
          {value.hoursTemplate && (
            <Tooltip
              arrow
              style={{ marginLeft: "10px", fontSize: "18px" }}
              title="קיים תבנית שעות"
            >
              <span>
                <FiClock size={25} />
              </span>
            </Tooltip>
          )}
          {value.shiftTemplate && (
            <Tooltip
              arrow
              style={{ marginLeft: "10px", fontSize: "18px" }}
              title="קיים הרשאות צפייה "
            >
              <span>
                <FaEye size={25} />
              </span>
            </Tooltip>
          )}
          {value.minimumShift && (
            <Tooltip
              arrow
              style={{ marginLeft: "10px", fontSize: "18px" }}
              title="קיים מינימום הגשת משמרות "
            >
              <span>
                <FaCalendarAlt size={25} />
              </span>
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    Header: "פעולות נוספות",
    accessor: "extra",
    displayMobile: true,
    Cell: ({ row, value, handleSinglePosition }) => (
      <ButtonIcon
        // loading={isLoading}
        onClick={() =>
          handleSinglePosition({
            position_id: row.original.id,
            main_position: true,
          })
        }
        className="btn--hover__confirm"
        icon={FaPencilAlt}
        alt={"עריכת תפקיד"}
      />
    ),
  },
];

function decodeHtmlEntities(input) {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = input;
  return textarea.value;
}

export const subPositionsColumns = [
  {
    Header: "תת תפקיד",
    accessor: "title",
    displayMobile: true,
    Cell: ({ row }) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHtmlEntities(row.original.title),
          }}
        />
      );
    },
  },
  {
    Header: "סוג טיפ",
    accessor: "tips_type",
    displayMobile: true,
    Cell: ({ value }) => getTipsType(value),
  },
  {
    Header: "סניף",
    accessor: "branch_id",
    displayMobile: true,
    Cell: ({ value }) => value.name,
  },
  {
    Header: "אחוז השתתפות בטיפ",
    accessor: "tip_participation",
    Cell: ({ row }) => {
      return (
        <span>
          {row.original.tip_participation ? row.original.tip_participation : 0}{" "}
          {row.original.tips_type == CONST_TIP ||
          row.original.tips_type == TIP_PER_SHIFT
            ? "₪"
            : "%"}
        </span>
      );
    },
  },
  {
    Header: "השלמה",
    accessor: "min_tips",
    Cell: ({ value }) => <span>{value ? value : 0} ₪</span>,
  },
  {
    Header: "הפרשה",
    accessor: "tips_allowance_by_position",
    Cell: ({ value }) => <Typography>{value === 0 ? "לא" : "כן"}</Typography>,
  },
  {
    Header: "פעולות",
    accessor: "actions",
    Cell: ({ row, handleSinglePosition, updateSinglePositionStatus }) => {
      return (
        <Typography component="div" align="center">
          <ButtonIcon
            // loading={isLoading}
            onClick={() =>
              handleSinglePosition({ position_id: row.original.id })
            }
            className="btn--hover__confirm"
            icon={FaPencilAlt}
            alt={"עריכת תת תפקיד"}
          />
          <ButtonIcon
            style={{ color: !row.original.enable ? GREEN_COLOR : RED_COLOR }}
            onClick={() =>
              updateSinglePositionStatus({
                enable: !row.original.enable,
                position_id: row.original.id,
              })
            }
            className={
              !row.original.enable
                ? " btn--hover__confirm"
                : "btn--hover__abort"
            }
            icon={row.original.enable ? FaTrash : FaTrashRestore}
            alt={row.original.enable ? "מחק" : "שחזר"}
          />
        </Typography>
      );
    },
  },
];

export const allowance_types = [
  { id: 1, label: "לפי סכום קבוע" },
  { id: 2, label: "לפי אחוז מטיפ לשעה" },
  { id: 3, label: "אחוז מסכום X" },
];
export const tip_types = [
  { id: 0, label: "לא משתתף בטיפ" },
  { id: 1, label: "לפי שעה" },
  { id: 2, label: "% טיפ כולל" },
  { id: 3, label: "סכום קבוע לשעה" },
  { id: 4, label: "סכום קבוע למשמרת" },
  { id: 6, label: "% מסכום X" },
];

export const min_tips_type_array = [
  { id: 1, label: "לפי סניף" },
  { id: 2, label: "לפי תפקיד - שעתי" },
  { id: 3, label: "לפי תפקיד - משמרת" },
  // { id: 4, label: "לפי תפקיד - יומי" },
];

export const auto_schedule = [
  { id: 0, label: "רגיל" },
  { id: 1, label: "קדימות" },
  { id: 2, label: "ללא שיבוץ כלל" },
];
export const ScrollToCard = React.forwardRef((props, ref) => {
  return <Card ref={ref} {...props} />;
});

export const getLabelByTip = (type) => {
  switch (type) {
    case 2:
      return "אחוז מטיפ כולל";
    case 3:
      return "סכום קבוע לשעה";
    case 4:
      return "סכום קבוע למשמרת";
    default:
      return "אחוז השתתפות בטיפ";
  }
};
//

//EmployeeType consts
export const salary_type = [
  { id: 0, label: "שעתי" },
  { id: 1, label: "לפי תפקיד" },
  { id: 2, label: "שכר משתנה" },
  // { id: 3, label: "שכר בסיס+ תוספת" },
];

const salary_by_sub_types = [
  { id: 0, label: "לפי שעה" },
  { id: 1, label: "לפי משמרת" },
  { id: 2, label: "שעתי משתנה" },
  { id: 3, label: "שכר בסיס + תוספת" },
];
const adjust_salary_fields = [
  {
    name: "salary_structure.adjusted_salary.100",
    fullWidth: true,
    label: "100%",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
  {
    name: "salary_structure.adjusted_salary.125",
    fullWidth: true,
    label: "125%",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
  {
    name: "salary_structure.adjusted_salary.150",
    fullWidth: true,
    label: "150%",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
  {
    name: "salary_structure.adjusted_salary.175",
    fullWidth: true,
    label: "175%",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
  {
    name: "salary_structure.adjusted_salary.200",
    fullWidth: true,
    label: "200%",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
];
const base_extra_salary_fields = [
  {
    name: "salary_structure.base_extra.salary",
    fullWidth: true,
    label: "שכר בסיס",
    defaultValue: "0",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
  {
    name: "salary_structure.base_extra.travel",
    fullWidth: true,
    label: "נסיעות",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
  {
    name: "salary_structure.base_extra.global",
    fullWidth: true,
    label: "תוספת שעות נוספות",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
  {
    name: "salary_structure.base_extra.health",
    fullWidth: true,
    label: "הבראה",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
  {
    name: "salary_structure.base_extra.holiday",
    fullWidth: true,
    label: "חופשה",
    InputProps: {
      endAdornment: <BiShekel />,
    },
  },
];

const sub_type_columns = [
  {
    title: "שם תת תפקיד",
    name: "sub_type_name",
    render: (
      data,
      row,
      index,
      { shift_positions, handlesSubSalaryTypeChange }
    ) =>
      data && !row.editMode ? (
        <span>{data}</span>
      ) : (
        <SelectComponent
          {...{
            search: true,
            size: "small",
            name: `salary_structure.salary_by_subtype.${index}.subtype`,
            label: "שם תת תפקיד",
            options: shift_positions.filter((position) => position.enable),
            handleChange: (e) =>
              handlesSubSalaryTypeChange(e, index, "subtype"),
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "300px", // Set max height for the dropdown
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        />
      ),
  },
  {
    title: "סוג שכר",
    name: "salary_type",
    render: (data, row, index, { handlesSubSalaryTypeChange }) => {
      return (
        <SelectComponent
          {...{
            size: "small",
            name: `salary_structure.salary_by_subtype.${index}.salary_type`,
            label: "סוג שכר",
            options: salary_by_sub_types,
            handleChange: (e) =>
              handlesSubSalaryTypeChange(e, index, "salary_type"),
          }}
        />
      );
    },
  },
  {
    title: "שכר",
    name: "salary",
    render: (data, row, index, { watch }) => {
      return row.salary_type === SALARY_TYPE_ADJUSTED ? (
        <Grid container>
          <Grid item xs={2} md={6} lg={2.4}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.100`,
                label: "100%",
              }}
            />
          </Grid>
          <Grid item xs={2} md={6} lg={2.4}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.125`,
                label: "125%",
              }}
            />
          </Grid>
          <Grid item xs={2} md={6} lg={2.4}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.150`,
                label: "150%",
              }}
            />
          </Grid>
          <Grid item xs={2} md={6} lg={2.4}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.175`,
                label: "175%",
              }}
            />
          </Grid>
          <Grid item xs={2} md={6} lg={2.4}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.200`,
                label: "200%",
              }}
            />
          </Grid>
        </Grid>
      ) : row.salary_type === SALARY_TYPE_BASEEXTRA ? (
        <Grid container>
          <Grid item xs={6} md={12/7}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.salary`,
                label: "שכר בסיס",
              }}
            />
          </Grid>
          <Grid item xs={6} md={12/7}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.base_travel`,
                label: "נסיעות",
              }}
            />
          </Grid>
          <Grid item xs={6} md={12/7}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.base_global`,
                label: "תוספת שעות גלובאליות",
              }}
            />
          </Grid>
          <Grid item xs={6} md={12/7}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.base_health`,
                label: "הבראה",
              }}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={12/7}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.base_holiday`,
                label: "חופשה",
              }}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={12/7}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.base_shabat_extra`,
                label: "תוספת שעות נוספות שבת",
              }}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={12/7}>
            <FormTextField
              {...{
                size: "small",
                name: `salary_structure.salary_by_subtype[${index}].salary.base_forced_work_stimulant`,
                label: "תמריץ עבודה מאומצת",
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <FormTextField
          {...{
            size: "small",
            name: `salary_structure.salary_by_subtype.${index}.salary`,
            label: "שכר",
          }}
        />
      );
    },
  },
  {
    name: "action",
    render: (data, row, index, { remove }) => {
      return (
        <>
          <ButtonIcon
            style={{
              margin: "0",
              backgroundColor: "#ffffff",
            }}
            alt="מחק תבנית שכר"
            onClick={() => {
              remove(index);
            }}
            icon={FaTrash}
          />
        </>
      );
    },
  },
];

export const hour_structure_cb = [
  { name: "extra_hours", label: "125%" },
  { name: "extra_hours", label: "150%" },
  { name: "shabat_extra_hours", label: "175%" },
  { name: "shabat_extra_hours", label: "200%" },
  { name: "night_shift", label: "משמרת לילה" },
  { name: "shabat_hours", label: "שעות שבת" },
  { name: "shabat_hours_break", label: "הפרש שעות שישי שבת" },
];
export const renderSalaryTypeFields = (
  salary_type,
  append,
  update,
  remove,
  watch,
  fields,
  shift_positions,
  handlesSubSalaryTypeChange,
  isMobile,
  salaryTableMobile
) => {
  switch (salary_type) {
    case SALARY_TYPE_HOURLY:
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <FormTextField
            {...{
              fullWidth: isMobile ? true : false,
              name: "salary_structure.hourly_salary",
              label: "שכר",
              InputProps: {
                endAdornment: <BiShekel />,
              },
            }}
          />
        </Grid>
      );
    case SALARY_TYPE_POSITION:
      return !salaryTableMobile ? (
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <SimpleTable
            columns={sub_type_columns}
            data={fields}
            direction="rtl"
            styles={{
              wrapper: { maxHeight: "calc(100vh - 10rem)" },
              container: { maxHeight: "calc(100vh - 23.4rem)" },
            }}
            propsToCell={() => {
              return {
                update,
                remove,
                append,
                shift_positions,
                watch,
                handlesSubSalaryTypeChange,
              };
            }}
            alignCenter={true}
            newLineButton={true}
            newLineFunc={() => {
              append({
                subtype: null,
                salary_type: null,
                salary: null,
              });
            }}
            newLineAlt="הוספת תפקיד"
          />
        </Grid>
      ) : (
        <Grid item container lg={8} sm={12} md={12} xs={12}>
          <MobileSalarySubType
            handlesSubSalaryTypeChange={handlesSubSalaryTypeChange}
            append={append}
            salary_by_sub_types={salary_by_sub_types}
            shift_positions={shift_positions}
            fields={fields}
          />
        </Grid>
      );
    case SALARY_TYPE_ADJUSTED:
      return (
        <>
          {adjust_salary_fields.map((adjust_salary) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={2.4}>
                <FormTextField
                  fullWidth={isMobile ? true : false}
                  key={adjust_salary.name}
                  {...adjust_salary}
                />
              </Grid>
            );
          })}
        </>
      );
    case SALARY_TYPE_BASEEXTRA:
      return (
        <>
          {base_extra_salary_fields.map((base_extra) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={2.4}>
                <FormTextField
                  fullWidth={isMobile ? true : false}
                  {...base_extra}
                />
              </Grid>
            );
          })}
        </>
      );
    default:
      return;
  }
};

export const renderDayType = (day_type) => {
  switch (day_type) {
    case TYPE_CONSIST:
      return (
        <Grid item xs={6} lg={6} md={6}>
          <FormTextField
            {...{
              name: "hours_structure.day_length",
              label: "אורך יום עבודה קבוע",
              rules: {
                validate: (value) =>
                  value >= 7 || "ערך לא יכול להיות קטן מ7 שעות",
              },
            }}
          />
        </Grid>
      );
    case TYPE_PER_DAY:
      return (
        <Grid item container xs={12}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2}>
              <DaysTable />
            </Paper>
          </Grid>
        </Grid>
      );
  }
};

export const validateNightHour = (value) => {
  if (value < 6) {
    return "ערך לא יכול להיות קטן מ6 שעות";
  } else if (value > 9) {
    return "ערך לא יכול להיות גדול מ9 שעות";
  }
};
