import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import { setError } from "../../../redux/actions/errorAction";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========
import { Button, Grid, Typography } from "@mui/material";
import Form from "../../../components/Forms/Form";
import OtpInputsForm from "./OtpInputsForm";
import { getOtpCode, loginByOtp } from "../services/api";
import OtpNumberInputForm from "./OtpNumberInputForm";

const OtpForm = () => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========
  const [showOtpLogin, setShowOtpLogin] = useState(true);
  const [message, setMessage] = useState("");
  const [loginUserWebId, setLoginUserWebId] = useState(null);
  const [lastSuccessfullCredentials, setLastSuccessfullCredentials] =
    useState();

  // ======== HANDLERS ========
  const handleSubmit = async (credentials, methods) => {
    try {
      const response = await getOtpCode(credentials);

      if (response?.success) {
        const { login_user_web_id } = response?.data;
        setLoginUserWebId(login_user_web_id);
        const mobileNumber = credentials?.mobileNumber;
        const otpMessage = `הקוד חד פעמי נשלח למספר הטלפון הנייד ${mobileNumber}`;
        setMessage(otpMessage);
        setShowOtpLogin(false);
        setLastSuccessfullCredentials(credentials);
      }
    } catch (error) {
      const errorMessage = error?.message || "שגיאה";
      dispatch(setError(errorMessage, "קיימת בעיה בהתחברות"));
    }
  };

  const handleSubmitOtpNumber = async (otpCredentials, methods) => {
    const credentials = {
      login_user_web_id: loginUserWebId,
      code: otpCredentials.otpNumber,
    };

    try {
      dispatch(loginByOtp(credentials));
    } catch (error) {
      console.error(error);
    }
  };
  // ======== CONSTANTS ========
  const defaultValues = useMemo(
    () => ({
      mobileNumber: "",
      identifier: "",
    }),
    []
  );

  const defaultValueOtpNumber = useMemo(
    () => ({
      otpNumber: "",
    }),
    []
  );

  return showOtpLogin ? (
    <Grid container direction="column">
      <Grid item>
        <Form
          formId="login-by-otp"
          onSubmit={handleSubmit}
          mode="onBlur"
          defaultValues={defaultValues}
        >
          <OtpInputsForm />
        </Form>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Button
            type="submit"
            form="login-by-otp"
            variant="contained"
            color="primary"
            size="large"
            sx={isMobile ? { width: "100%" } : {}}
          >
            שלח קוד
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="column" sx={{ alignItems: "center" }}>
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {message}
        </Typography>
      </Grid>
      <Grid item>
        <Form
          formId="login-by-otp-number"
          onSubmit={handleSubmitOtpNumber}
          mode="onBlur"
          defaultValues={defaultValueOtpNumber}
        >
          <OtpNumberInputForm {...{ lastSuccessfullCredentials }} />
        </Form>
      </Grid>
      <Grid
        item
        container
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Button
            type="submit"
            form="login-by-otp-number"
            variant="contained"
            color="primary"
            size="large"
            sx={isMobile ? { width: "100%" } : {}}
          >
            התחבר\י
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OtpForm;
