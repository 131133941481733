import React from "react";
import { fixDate, fixDateHours, fixHour } from "../../../../../helpers";
import SimpleTable from "../../../../orders/manageOrders/receiveOrder/SimpleTable";
const columns = [
  {
    title: "סוג עדכון",
    name: "action_description",
    render: (data, row) => {
      return data;
    },
  },
  {
    title: "ערך ישן",
    name: "old_value",
    render: (data, row) => {
      return fixHour(data);
    },
  },
  {
    title: "ערך חדש",
    name: "new_value",
    render: (data, row) => {
      return fixHour(data);
    },
  },
  {
    title: "עודכן ע״י",
    name: "updater_info",
    render: (data, row) => {
      return (
        <>
          <p>{data}</p>
          <p>{`בתאריך ${fixDate(row.updated_at)}`}</p>
          {/* <p>{`בשעה ${fixHour(row.updated_at.split("T")[1])}`}</p> */}
          <p>{`בשעה ${fixHour(row.updated_at.split(" ")[1])}`}</p>
        </>
      );
    },
  },
];
const Logs = ({ logs }) => {
  return (
    <div className=" bonus-modal" style={{ width: "auto" }}>
      <SimpleTable data={logs} columns={columns} />
    </div>
  );
};

export default Logs;
