import moment from "moment";

const INCOME_ELEMENT_TYPE_NOT_CALC = [
  "discount",
  "CARD",
  "refund",
  "restigo-refund",
  "total",
];
const INCOME_ELEMENT_TYPE_SUBTRACT = ["tip"];

export const arrangeData = (
  incomes,
  extras,
  fees,
  goals,
  selectedDates,
  incomeSettings
) => {
  let total = 0;
  let totalDiners = 0;
  const dateBreakdown = {};
  const elementsBreakdown = {};
  const channelsBreakdown = {};
  const shfitsBreakdown = {};
  // init dateBreakdown - each date on period gets empty object
  const start = moment(selectedDates.startDate);
  const end = moment(selectedDates.endDate).add(1, "days");
  for (var m = moment(start); m.diff(end, "days") < 0; m.add(1, "days")) {
    const currentDate = m.format("YYYY-MM-DD");
    dateBreakdown[currentDate] = {
      date: currentDate,
      day: m.day(),
      zNumbers: [],
      income_with_vat: 0,
      extra_with_vat: 0,
      diners: 0,
      diners_avg: 0,
      elements: {},
      channels: {},
      shifts: {},
      fees: 0,
    };
  }

  incomes.forEach((income) => {
    const incomeDate = income.transaction_date;
    const incomeElements = income.income_elements;
    let incomeTotal = 0;
    const getDinersFromElements = income.diners === 1;
    let incomeDiners = getDinersFromElements ? 0 : income.diners;

    incomeElements.forEach((incomeElement) => {
      const type = incomeElement.type;
      const calcInTotal =
        incomeSettings.paymentTypes[type]?.calc_in_total ?? true;

      if (!calcInTotal || INCOME_ELEMENT_TYPE_NOT_CALC.includes(type)) return;

      const name = incomeSettings.paymentTypes[type]?.name || type;
      let elementTotal = incomeElement.amount;
      let elementDiners = incomeElement.count;

      if (INCOME_ELEMENT_TYPE_SUBTRACT.includes(type)) {
        if (elementTotal > 0) {
          elementTotal *= -1;
        }
        incomeTotal += elementTotal;
        return;
      }

      if (!(type in elementsBreakdown)) {
        elementsBreakdown[type] = {
          name: name,
          total: 0,
          diners: 0,
        };
      }
      if (!(type in dateBreakdown[incomeDate].elements)) {
        dateBreakdown[incomeDate].elements[type] = {
          name: name,
          total: 0,
          diners: 0,
          fees: 0,
        };
      }

      incomeTotal += elementTotal;
      if (getDinersFromElements) {
        incomeDiners += elementDiners;
      }

      elementsBreakdown[type].total += elementTotal;
      elementsBreakdown[type].diners += elementDiners;

      dateBreakdown[incomeDate].elements[type].total += elementTotal;
      dateBreakdown[incomeDate].elements[type].diners += elementDiners;
    });

    dateBreakdown[incomeDate].zNumbers.push(income.z_number);
    dateBreakdown[incomeDate].income_with_vat += incomeTotal;
    dateBreakdown[incomeDate].diners += incomeDiners;

    total += incomeTotal;
    totalDiners += incomeDiners;

    income.income_channels.forEach((incomechannel) => {
      const type = incomechannel.type;
      const name = incomeSettings.channelTypes[type]?.name || type; //TODO
      if (!(type in channelsBreakdown)) {
        channelsBreakdown[type] = {
          name: name,
          total: 0,
          diners: 0,
        };
      }
      if (!(type in dateBreakdown[incomeDate].channels)) {
        dateBreakdown[incomeDate].channels[type] = {
          name: name,
          total: 0,
          diners: 0,
        };
      }
      let channelTotal = incomechannel.amount;
      let channelDiners = incomechannel.count;
      channelsBreakdown[type].total += channelTotal;
      channelsBreakdown[type].diners += channelDiners;
      dateBreakdown[incomeDate].channels[type].total += channelTotal;
      dateBreakdown[incomeDate].channels[type].diners += channelDiners;
    });

    income.income_shifts.forEach((incomeShift) => {
      const type = incomeShift.type;
      const name = incomeSettings.shiftTypes[type]?.name || type;
      if (!(type in shfitsBreakdown)) {
        shfitsBreakdown[type] = {
          name: name,
          total: 0,
          diners: 0,
        };
      }
      if (!(type in dateBreakdown[incomeDate].shifts)) {
        dateBreakdown[incomeDate].shifts[type] = {
          name: name,
          total: 0,
          diners: 0,
        };
      }
      let shiftTotal = incomeShift.amount;
      let shiftDiners = incomeShift.count;
      shfitsBreakdown[type].total += shiftTotal;
      shfitsBreakdown[type].diners += shiftDiners;
      dateBreakdown[incomeDate].shifts[type].total += shiftTotal;
      dateBreakdown[incomeDate].shifts[type].diners += shiftDiners;
    });
  });

  const filteredData = extras.filter((income, index, self) => {
    if (income.sp_order_id === null) {
      return true;
    }
    return (
      index ===
      self.findIndex(
        (selfElement) => selfElement.sp_order_id === income.sp_order_id
      )
    );
  });

  filteredData.forEach((incomeExtra) => {
    const incomeDate = incomeExtra.date;
    const typeKey = "incomeExtra";
    const name = "הכנסות נוספות";
    const extraTotal = incomeExtra.total_with_vat;
    const extraDiners = incomeExtra.diners;
    if (!(typeKey in elementsBreakdown)) {
      elementsBreakdown[typeKey] = {
        name: name,
        total: 0,
        diners: 0,
      };
    }
    if (!(typeKey in channelsBreakdown)) {
      channelsBreakdown[typeKey] = {
        name: name,
        total: 0,
        diners: 0,
      };
    }
    if (!(typeKey in shfitsBreakdown)) {
      shfitsBreakdown[typeKey] = {
        name: name,
        total: 0,
        diners: 0,
      };
    }
    if (!(typeKey in dateBreakdown[incomeDate].elements)) {
      dateBreakdown[incomeDate].elements[typeKey] = {
        name: name,
        total: 0,
        diners: 0,
      };
    }
    if (!(typeKey in dateBreakdown[incomeDate].channels)) {
      dateBreakdown[incomeDate].channels[typeKey] = {
        name: name,
        total: 0,
        diners: 0,
      };
    }
    if (!(typeKey in dateBreakdown[incomeDate].shifts)) {
      dateBreakdown[incomeDate].shifts[typeKey] = {
        name: name,
        total: 0,
        diners: 0,
      };
    }

    elementsBreakdown[typeKey].total += extraTotal;
    elementsBreakdown[typeKey].diners += extraDiners;

    channelsBreakdown[typeKey].total += extraTotal;
    channelsBreakdown[typeKey].diners += extraDiners;

    shfitsBreakdown[typeKey].total += extraTotal;
    shfitsBreakdown[typeKey].diners += extraDiners;

    dateBreakdown[incomeDate].elements[typeKey].total += extraTotal;
    dateBreakdown[incomeDate].elements[typeKey].diners += extraDiners;

    dateBreakdown[incomeDate].channels[typeKey].total += extraTotal;
    dateBreakdown[incomeDate].channels[typeKey].diners += extraDiners;

    dateBreakdown[incomeDate].shifts[typeKey].total += extraTotal;
    dateBreakdown[incomeDate].shifts[typeKey].diners += extraDiners;

    dateBreakdown[incomeDate].extra_with_vat += extraTotal;
    dateBreakdown[incomeDate].diners += extraDiners;
    total += extraTotal;
    totalDiners += extraDiners;
  });

  fees.forEach((fee) => {
    const feeDate = fee.date;
    const incomeElementType = fee.income_element_type;
    if (incomeElementType in dateBreakdown[feeDate].elements) {
      dateBreakdown[feeDate].elements[incomeElementType].fees += fee.total;
    }
    dateBreakdown[feeDate].fees += fee.total;
  });
  return {
    total,
    totalDiners,
    dateBreakdown,
    elementsBreakdown,
    channelsBreakdown,
    shfitsBreakdown,
    goals,
  };
};
