import { createTheme } from "@mui/material";
import { heIL } from "@mui/material/locale";
import { Link as RouterLink } from "react-router-dom";
import { COLOR_RESTIGO_PRIMARY, COLOR_RESTIGO_SECONDARY } from "./const/colors";

const theme = createTheme(
  {
    direction: "rtl",
    typography: {
      fontFamily: "Heebo",
      fontSize: 18,
      allVariants: {
        color: "#585858",
      },
    },
    palette: {
      primary: {
        main: COLOR_RESTIGO_PRIMARY,
        contrastText: "#fff",
      },
      secondary: {
        // main: COLOR_RESTIGO_SECONDARY,
        main: "#22b7f7c5",
        contrastText: "#fff",
      },
      error: {
        main: "#db1e1e",
        contrastText: "#fff",
      },
      neutral: {
        main: "#999",
        contrastText: "#fff",
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          variant: "outlined",
          InputLabelProps: { shrink: true },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          fullWidth: true,
        },
      },
      MuiFormControl: {
        defaultProps: {
          fullWidth: true,
          variant: "outlined",
        },
      },
      MuiGrid: {
        defaultProps: {
          spacing: 5,
        },
      },
      MuiGrid2: {
        defaultProps: {
          spacing: 5,
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: "wave",
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: 15,
          },
          outlined: {
            "&.Mui-required": {
              color: "#22b7f7c5",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          "&.Mui-disabled": {
            backgroundColor: "#eceeef",
            color: "#5a5a5a",
            fontSize: 13,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: "neutral",
        },
        styleOverrides: {
          contained: {
            "&:hover": {
              backgroundColor: "#888",
              color: "#fff",
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                backgroundColor: "#999",
                color: "#fff",
              },
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Heebo",
            color: "black",
            "&.Mui-error": {
              fontWeight: "bold",
              color: "black",
            },
          },
          contained: {
            marginTop: 2,
            marginLeft: 5,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: "1.6rem",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: "2rem 0",
            margin: "auto",
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            // paddingBottom: 0,
            marginBottom: "16px",
            borderBottom: "1px solid #585858",
          },
          title: {
            fontWeight: 700,
            fontSize: "1.8rem",
            color: "#585858",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingRight: 30,
            paddingLeft: 30,
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            borderTop: [[1, "solid", "#eee"]],
            paddingTop: 15,
            marginTop: 15,
            display: "flex",
            justifyContent: "flex-end",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: ({ ownerState }) => {
            if (ownerState.variant === "allowmenu") {
              return { zIndex: theme.zIndex.drawer - 1 };
            }
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            borderTop: [[1, "solid", "#eee"]],
            paddingTop: 15,
            marginTop: 15,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: {
            color: "unset",
            fontSize: "1.2857142857142858rem",
          },
          head: { padding: "8px 16px" },
          stickyHeader: { zIndex: 3 },
          footer: {
            left: 0,
            fontWeight: 500,
            bottom: 0, // <-- KEY
            zIndex: 2,
            position: "sticky",
            backgroundColor: "white",
            borderTop: [[1, "solid", "black"]],
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            fontSize: "1.3rem",
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            flexWrap: null,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid #eee",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-error": {
              borderColor: "#f44336",
            },
          },
          // notchedOutline: {
          //   borderColor: "#f44336",
          // },
        },
      },
      MuiLink: {
        defaultProps: {
          component: RouterLink,
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid black",
          },
        },
      },
    },
  },
  heIL
);

// theme.overrides = {
//   //--- Table
//   MUIDataTableHeadCell: {
//     root: {
//       borderBottom: [[1, "solid", "black"]],
//     },
//   },
//   MUIDataTable: {
//     responsiveScroll: {
//       maxHeight: "800px",
//     },
//   },

//   paper: {
//     width: "100%",
//     overflowX: "auto",
//   },
// };

export default theme;
