import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { TextField, useMediaQuery, useTheme } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import he from "date-fns/locale/he";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const MobileDatePickerComponent = ({
  selectedDates,
  label,
  handleDateRangeChange = () => {},
}) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  console.log(selectedDates, label);
  return (
    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
      {label}
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={he}
        label=""
      >
        <MobileDatePicker
          onChange={(newValue) => handleDateRangeChange(newValue)}
          defaultCalendarMonth={new Date()}
          value={selectedDates}
          maxDate={new Date()}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField size="small" {...params} />}
          showToolbar={false}
        />
      </LocalizationProvider>
    </label>
  );
};

export default MobileDatePickerComponent;
