import React, { useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { connect } from "react-redux";
import BtnTooltip from "../../../../components/tooltips/BtnTooltip";
import { isEmpty, mapKeys } from "lodash";
import Button from "../../../../components/buttons/Button";
import { fixDate, getSafe } from "../../../../helpers";
import {
  FaLock,
  FaLockOpen,
  FaPencilAlt,
  FaRegTrashAlt,
  FaStarOfDavid,
} from "react-icons/fa";
import { MdSpeakerNotes } from "react-icons/md";
import Popover from "react-popover";
import EditHoursPerDay from "./models/EditHoursPerDay";
import {
  addHoursToEvent,
  eraseDateEvents,
  lockDay,
  updateSchedule,
} from "../../../../redux/actions/scheduleAction";
import { setDialogModal } from "../../../../redux/actions/DialogAction";
import { CAN_LOCK_DAYS_PRIVILEGE } from "../../../../const/privilegesButtons";
import { format } from "date-fns";
import {
  COLOR_SUBMIT,
  COLOR_WARNINGS,
  COLOR_CONFIRM,
} from "../../../../const/colors";
import { setWeek } from "../../../../redux/actions/publicAction";
import { BounceLoader } from "react-spinners";
import { Modal } from "react-responsive-modal";
import SmsByDay from "../modals/SmsByDay";
import LockDayErrorEmployeeDialog from "./LockDayErrorEmployeeDialog";
import { useScreenSize } from "../../../../Hooks";
import ObligateByDay from "../modals/ObligateByDay";
import moment from "moment";
import { AiFillLock, AiFillUnlock, AiOutlineStar } from "react-icons/ai";
import {
  TYPE_ELECTION,
  TYPE_HOLIDAY,
  TYPE_SPECIAL_EVENT,
} from "../../../admin/holidays/HolidayView";
import { BiNote } from "react-icons/bi";

export const HOLIDAYS_ICONS = {
  [TYPE_HOLIDAY]: () => (
    <FaStarOfDavid style={{ color: COLOR_SUBMIT }} size={15} />
  ),
  [TYPE_SPECIAL_EVENT]: () => (
    <AiOutlineStar style={{ color: COLOR_SUBMIT }} size={"2em"} />
  ),
  [TYPE_ELECTION]: () => (
    <BiNote style={{ color: COLOR_SUBMIT }} size={"2em"} />
  ),
};

const EditDailyNotes = ({ defaultValue = "", updateSchedule }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(defaultValue);

  return (
    <>
      <Modal
        classNames={{ modal: "dialog-modal" }}
        styles={{
          modal: {
            maxWidth: "50rem",
            textAlign: "center",
            padding: "2.5rem",
          },
        }}
        center
        open={open}
        onClose={() => setOpen(false)}
        animationDuration={200}
      >
        <MdSpeakerNotes size="7em" />
        <h2>הערות יומיות</h2>
        <textarea
          maxLength={70}
          style={{ border: "1px solid #ccc" }}
          className="textarea-white"
          defaultValue={defaultValue}
          onBlur={(e) => {
            setText(e.target.value);
          }}
        ></textarea>
        <Button
          disabled={defaultValue === text && defaultValue !== ""}
          style={{ margin: "1rem 0" }}
          onClick={() => {
            updateSchedule(text);
            setOpen(false);
          }}
          className={`btn--submit fluid`}
          textValue="שמירה"
        />
      </Modal>
      <button
        type="button"
        style={{ margin: "0 1rem" }}
        className={`btn btn--none padding-0 fit-content`}
        onClick={() => setOpen((prev) => !prev)}
      >
        {defaultValue ? (
          <MdSpeakerNotes color={COLOR_CONFIRM} size="1.4em" />
        ) : (
          <FaPencilAlt size="1.4em" />
        )}
      </button>
    </>
  );
};

const Header = ({
  daysToShow,
  week,
  tips,
  holidays,
  shifts,
  is_submission = false,
  handleSubmissionAbort,
  shifts_and_days,
  isAutoAssign,
  user_id = null,
  notes = null,
  firstTouched,
  can_lock,
  locked_dates,
  daily_notes,
  user,
  updateSchedule,
  setDialogModal,
  eraseDateEvents,
  editMode = false,
  lockDay,
  addHoursToEvent,
  setWeek,
}) => {
  const { isMobile } = useScreenSize();
  const today = fixDate(new Date(), " DD/MM");
  const [datesOfTips, setDatesOfTips] = useState([]);
  const [datesTextOfTips, setDatesTextOfTips] = useState({});
  const [dayLoader, setDayLoader] = useState(null);
  const [lockDayErrorEmployees, setLockDayErrorEmployees] = useState({});
  const [lockDayErrorDate, setlockDayErrorDate] = useState(null);
  const [showLockErrorModal, setShowLockErrorModal] = useState(false);

  const handleStateOpenEditRoleHours = (index) => {
    const updatedWeek = [...week];
    updatedWeek[index].isOpen = !updatedWeek[index].isOpen; // Toggle the state
    setWeek({ week: updatedWeek }); // Update the state with the modified array
  };

  useEffect(() => {
    setShowLockErrorModal(Object.values(lockDayErrorEmployees).length > 0);
  }, [lockDayErrorEmployees]);

  const setMobileView = (day_idx) => {
    if (isMobile) {
      if (daysToShow) return daysToShow.includes(day_idx) ? "" : "none";
    }
  };

  const tipAlt = useMemo(
    () => (tips, shifts) => {
      let obj = {};
      tips.forEach((element) => {
        if (element.schedule_shift_id) {
          if (!obj[element.date]) {
            obj[element.date] = `קיימים טיפים:\r\n`;
          }
          obj[element.date] += `משמרת ${
            shifts[element.schedule_shift_id]?.name
          }.\r\n`;
        } else {
          obj[element.date] = obj[element.date] ?? `קיימים טיפים:\r\n`;
          if (element.additional_shifts) {
            const stringShifts = Array.isArray(element.additional_shifts)
              ? element.additional_shifts
                  .map((addShift) => shifts[addShift].name)
                  .join("+")
              : "";
            obj[element.date] += `משמרות ${stringShifts}.\r\n`;
          } else {
            obj[element.date] += "קיים טיפ יומי ";
          }
        }
      });

      setDatesTextOfTips(obj);
    },
    []
  );

  useEffect(() => {
    if (tips && tips.length && !is_submission && !isEmpty(shifts)) {
      const dates = [...new Set(tips.map((x) => x.date))];
      tipAlt(tips, shifts);
      setDatesOfTips(dates);
    }
    if (tips?.length === 0) {
      setDatesOfTips([]);
    }
  }, [tips, is_submission, tipAlt, shifts]);

  const renderLock = useMemo(
    () => (col_date, date) => {
      const isDateLocked = locked_dates && locked_dates[col_date];
      return (
        <BtnTooltip
          hide={!isDateLocked}
          text={
            isDateLocked ? `ננעל ע"י ${locked_dates[col_date].locked_by}` : ""
          }
          isClickable={true}
        >
          <button
            style={{
              fontSize: "1.6rem",
              position: "absolute",
              bottom: "2px",
              right: "-2rem",
              width: "unset",
              height: "unset",
              margin: 0,
              backgroundColor: "transparent",
            }}
            className={`btn padding-0 ${
              isDateLocked ? "btn--outline__abort " : ""
            }`}
            onClick={() => {
              if (can_lock) {
                if (isDateLocked) {
                  setDialogModal({
                    title: "שחרור נעילה",
                    text: `האם ברצונך לפתוח נעילה לתאריך ${date} ?`,
                    onConfirm: () => {
                      delete locked_dates[col_date];
                      updateSchedule({
                        locked_dates: Object.values(locked_dates),
                      });
                    },
                    iconJSX: () => AiFillUnlock({ size: "7em" }),
                  });
                } else {
                  setDialogModal({
                    title: "נעילת יום",
                    text: `האם ברצונך לנעול יום לתאריך ${date} ?`,
                    onConfirm: async () => {
                      const errorEmployees = await lockDay(col_date);

                      if (errorEmployees) {
                        setLockDayErrorEmployees(errorEmployees);
                        setlockDayErrorDate(col_date);
                      }
                    },
                    iconJSX: () => AiFillLock({ size: "7em" }),
                  });
                }
              }
            }}
          >
            {isDateLocked ? (
              // <FaLock color={""} />
              <AiFillLock />
            ) : can_lock ? (
              // <FaLockOpen />
              <AiFillUnlock />
            ) : (
              ""
            )}
          </button>
        </BtnTooltip>
      );
    },
    [locked_dates, can_lock, user, updateSchedule]
  );

  const renderDailyNotes = useMemo(
    () => (text) => {
      if (text && !editMode) {
        if (isMobile) {
          return (
            <div>
              <BtnTooltip text={text} isClickable={true}>
                <MdSpeakerNotes color={COLOR_SUBMIT} size="1.7em" />
              </BtnTooltip>
            </div>
          );
        } else {
          return <div style={{ fontWeight: "bold" }}>{text}</div>;
        }
      }
    },
    [editMode, isMobile]
  );

  return (
    <>
      <LockDayErrorEmployeeDialog
        open={showLockErrorModal}
        setOpen={setShowLockErrorModal}
        errorEmployees={lockDayErrorEmployees}
        setErrorEmployees={setLockDayErrorEmployees}
        lockDay={lockDay}
        date={lockDayErrorDate}
        setDate={setlockDayErrorDate}
        isMobile={isMobile}
      />
      <Row id="days_header" between="xs" className="work-schedule--day">
        {week.map((day, index) => {
          if (
            !is_submission ||
            getSafe(() => shifts_and_days[day.day_num], false)
          ) {
            return (
              <Col
                className={setMobileView(day.day_num)}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                }}
                xs
                key={index}
              >
                <div style={{ zIndex: 2 }} className="absCenter">
                  <BounceLoader
                    size={isMobile ? 50 : 60}
                    color="#00a2ea"
                    loading={dayLoader === day.day_num}
                  />
                </div>

                <div
                  className={` ${
                    !editMode && today === day.date
                      ? "work-schedule--day__selected"
                      : ""
                  }`}
                >
                  {holidays[day.actual_date] ? (
                    <BtnTooltip text={holidays[day.actual_date].name}>
                      {HOLIDAYS_ICONS[holidays[day.actual_date].type]()}
                    </BtnTooltip>
                  ) : (
                    <></>
                  )}
                  <h3 className="flex-center">
                    <div style={{ width: "fit-content", position: "relative" }}>
                      {day.day}{" "}
                      {user_id
                        ? ""
                        : moment(day.actual_date).isSameOrBefore(
                            moment().format("YYYY-MM-D")
                          ) && renderLock(day.actual_date, day.date)}
                    </div>
                  </h3>
                  {day.date}{" "}
                  {datesOfTips.includes(day.actual_date) && !user_id ? (
                    <span style={{ color: "#00c851" }}>
                      <BtnTooltip
                        placement="bottom"
                        text={datesTextOfTips[day.actual_date]}
                      >
                        <i className="fa fa-money-bill-wave" />
                      </BtnTooltip>
                    </span>
                  ) : (
                    ""
                  )}
                  {renderDailyNotes(daily_notes[day.actual_date]?.text)}
                  {editMode ? (
                    <div style={{ marginTop: ".5rem" }} className="flex-center">
                      <BtnTooltip text="אפס יום" isClickable={true}>
                        <button
                          type="button"
                          style={{ margin: "0 1rem" }}
                          className={`btn btn--none padding-0 fit-content`}
                          onClick={() => {
                            setDialogModal({
                              title: "איפוס יום",
                              text: `האם ברצונך לאפס יום לתאריך ${day.date} ?`,
                              onConfirm: () => {
                                setDayLoader(day.day_num);
                                eraseDateEvents({
                                  date: day.actual_date,
                                  setLoader: () => setDayLoader(null),
                                });
                              },
                              iconJSX: () => FaRegTrashAlt({ size: "7em" }),
                            });
                          }}
                        >
                          <FaRegTrashAlt color={COLOR_WARNINGS} size="1.4em" />
                        </button>
                      </BtnTooltip>
                      <EditHoursPerDay
                        handleStateOpenEditRoleHours={
                          handleStateOpenEditRoleHours
                        }
                        isOpen={day.isOpen}
                        index={index}
                        day={day}
                      />
                      <BtnTooltip
                        text={
                          daily_notes[day.actual_date]
                            ? daily_notes[day.actual_date].text
                            : "הוסף הערות"
                        }
                        isClickable={true}
                      >
                        <EditDailyNotes
                          {...{
                            defaultValue: daily_notes[day.actual_date]?.text,
                            updateSchedule: (text) =>
                              updateSchedule({
                                daily_notes: [
                                  ...Object.values(daily_notes),
                                  {
                                    date: day.actual_date,
                                    user: user.name,
                                    text,
                                  },
                                ],
                              }),
                          }}
                        />
                      </BtnTooltip>
                    </div>
                  ) : (
                    <div style={{ marginTop: ".5rem" }} className="flex-center">
                      {/* {user_id ? <></> : <SmsByDay day={day} />} */}
                      {user_id ? (
                        <></>
                      ) : (
                        moment(day.actual_date).isSameOrAfter(moment()) && (
                          <ObligateByDay day={day} />
                        )
                      )}
                    </div>
                  )}
                  <div
                    style={{ color: COLOR_SUBMIT, marginTop: "2px" }}
                    className="right-to-left strong"
                  >
                    {dayLoader === day.day_num ? "מאפס יום.." : ""}
                  </div>
                </div>
              </Col>
            );
          }
          return "";
        })}
        {!isMobile && (
          <Col xs className="col-margin-right">
            {user_id && (
              <div className="flex-column-center right-to-left">
                <h3 style={{ textDecoration: "underline", fontSize: "1.4rem" }}>
                  הערות מנהל לשבוע{" "}
                </h3>
                <strong style={{ fontSize: "1.4rem" }}>
                  {notes ? `*${notes}` : "לא קיימות הערות לשבוע זה."}
                </strong>
              </div>
            )}
            {is_submission && firstTouched ? (
              <div className="flex-start">
                <Button
                  type="submit"
                  className="btn--submit"
                  onClick={() => {}}
                  textValue="שמור"
                />
                <Button
                  className="btn--neutral"
                  onClick={() => handleSubmissionAbort()}
                  textValue="ביטול"
                />
              </div>
            ) : (
              isAutoAssign && (
                <Col xs>
                  <div>
                    <h2 style={{ color: "rgb(219, 30, 30)" }}>
                      טיוטת שיבוץ אוטומטי
                    </h2>
                  </div>
                </Col>
              )
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  const tips = Object.values(state.schedule.tips);
  const { user } = state.auth;
  const { privileges_buttons } = state.user_privileges;
  const can_lock = privileges_buttons.includes(CAN_LOCK_DAYS_PRIVILEGE);
  let { shifts, schedule } = state.schedule;
  let { locked_dates, daily_notes, holidays } = schedule;
  holidays = holidays ? mapKeys(holidays, "date") : {};
  locked_dates = locked_dates ? mapKeys(locked_dates, "date") : {};
  daily_notes = daily_notes ? mapKeys(daily_notes, "date") : {};
  shifts = mapKeys(shifts, "id");
  return { tips, shifts, can_lock, locked_dates, holidays, daily_notes, user };
};

export default connect(mapStateToProps, {
  updateSchedule,
  setDialogModal,
  eraseDateEvents,
  lockDay,
  addHoursToEvent,
  setWeek,
})(Header);
