import { Link, Tooltip } from "@mui/material";

//server paths
export const SOLD_DISH_PATH = "soldDish2";
export const GROUPED_SOLD_DISHES = `${SOLD_DISH_PATH}/grouped`;
export const UNLINKED_ITEMS = `${SOLD_DISH_PATH}/unlinkedItems`;
export const LINK_ITEM = `${SOLD_DISH_PATH}/linkItem`;
export const LINK_PRODUCT = `${SOLD_DISH_PATH}/linkProduct`;
export const BRANCH_GOALS = "expenseGoals/getGoals";
export const THROW_EVENTS = "newInventory";
export const SALE_EVENTS = `${SOLD_DISH_PATH}/inventorySaleEvents`;

//dish types
export const SOLD_DISH_TYPE_UNLINKED = -1;
export const SOLD_DISH_TYPE_DISH = 0;
export const SOLD_DISH_TYPE_MEAL = 1;
export const SOLD_DISH_TYPE_RAW = 2;

//extra data fetch filters
export const EXTRA_FILTER_NONE = 0;
export const EXTRA_FILTER_LINKED = 1;
export const EXTRA_FILTER_UNLINKED = 2;

export const EXTRA_FILTER_OPTIONS = [
  { name: "ללא פילטור נוסף", id: EXTRA_FILTER_NONE },
  { name: "מוצרים מקושרים בלבד", id: EXTRA_FILTER_LINKED },
  { name: "מוצרים לא מקשורים בלבד", id: EXTRA_FILTER_UNLINKED },
];

//main table tabs
export const DEFAULT_MAX_FC = 30;

export const BRANCHES_TABLE_TAB = 0;
export const MEALS_TABLE_TAB = 1;
export const DISHES_TABLE_TAB = 2;
export const RAWS_TABLE_TAB = 3;
export const UNLINKED_TABLE_TAB = 4;
export const THOUSAND_USE_TAB = 5;

export const TABLE_TAB_OPTIONS = [
  {
    index: BRANCHES_TABLE_TAB,
    name: "סניפים",
  },
  {
    index: MEALS_TABLE_TAB,
    name: "ארוחות",
  },
  {
    index: DISHES_TABLE_TAB,
    name: "מנות",
  },
  {
    index: RAWS_TABLE_TAB,
    name: "חומרי גלם",
  },
  {
    index: UNLINKED_TABLE_TAB,
    name: "לא מקושרים",
  },
  {
    index: THOUSAND_USE_TAB,
    name: "צריכה ל-1000",
  },
];

//excel translation object prop
export const EXCEL_TRANSLATIONS = "SalesCostTables";

//link modal types
export const LINK_MODAL_TYPE_ITEMS = 0;
export const LINK_MODAL_TYPE_PRODUCTS = 1;

export const LINK_MODAL_TYPE_OPTIONS = [
  {
    name: "מוצרים",
    id: LINK_MODAL_TYPE_ITEMS,
  },
  { name: "מנות", id: LINK_MODAL_TYPE_PRODUCTS },
];

//max dishes to show in the pie graphs
export const GRAPHS_MAX_RECORDS = 5;

export const FcHeader = () => (
  <Tooltip
    title={
      <span style={{ fontSize: "1.5rem" }}>
        סך הרכיבים לחלק לסך המכירות כולל
      </span>
    }
    placement="top-start"
  >
    <Link
      component="span"
      sx={{
        fontSize: "1.6rem",
      }}
    >
      F.C
    </Link>
  </Tooltip>
);
