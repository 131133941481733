import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";
import PageNav from "../../../components/PageNav";
import {
  TABLE_TAB_OPTIONS,
  BRANCHES_TABLE_TAB,
  DISHES_TABLE_TAB,
  RAWS_TABLE_TAB,
  MEALS_TABLE_TAB,
  UNLINKED_TABLE_TAB,
  GROUPED_SOLD_DISHES,
  THOUSAND_USE_TAB,
} from "../const";
import * as branchesTableConfigs from "./branches.table";
import * as dishesTableConfigs from "./dishes.table";
import * as rawsTableConfigs from "./raws.table";
import * as mealsTableConfigs from "./meals.table";
import * as unlinkedTableConfigs from "./unlinked.table";
import * as thousandUseTableConfigs from "./thousandUse.table";

import { LinkModal } from "../LinkModal";
import SalesCostTable from "./SalesCostTable";
import Axios from "axios";
import * as branchByDateTableConfigs from "./branchByDate.table";
import { fixDate } from "../../../helpers";
import * as branchByCodeTableConfigs from "./branchByCode.table";
import { SOLD_DISH_PATH } from "../const";
import * as byLinkTableColumns from "./byLink.table";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

/**
 * This is a semi generic component for all the main tables in this page
 * the data and column changes dynamically when pressing the tabs
 */
export const TablesSection = ({ data, setData }) => {
  // ======== HOOKS ========
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========
  const [selectedTab, setSelectedTab] = useState(TABLE_TAB_OPTIONS[0]);
  const [tablesData, setTablesData] = useState([]);
  const [linkModalState, setLinkModalState] = useState({
    open: false,
    soldDish: null,
  });

  const { reducedData, soldDishes, saleEvents, branchesFoodCostGoal } = data;
  const { by_code, by_branch } = soldDishes;
  const { comulative, minDate, maxDate } = reducedData;

  const { branches, token } = useSelector((state) => {
    const { branches, auth } = state;
    return {
      branches: branches.branches,
      token: auth.token,
    };
  });

  // ======== HANDLERS ========
  const handleTableDataChange = () => {
    const table = {
      title: selectedTab.name,
    };

    switch (selectedTab.index) {
      case BRANCHES_TABLE_TAB:
        table.data = branchesTableConfigs.getData(
          by_branch,
          branchesFoodCostGoal,
          branches,
          [minDate, maxDate]
        );
        table.columns = branchesTableConfigs.columns;
        table.excelRowConfig = branchesTableConfigs.excelRowConfig;
        table.aggregates = branchesTableConfigs.aggregates;
        table.sortBy = branchesTableConfigs.sortBy;
        break;
      case MEALS_TABLE_TAB:
        table.data = mealsTableConfigs.getData(by_code, branches);
        table.columns = mealsTableConfigs.columns;
        table.excelRowConfig = mealsTableConfigs.excelRowConfig;
        table.aggregates = mealsTableConfigs.aggregates;
        table.sortBy = mealsTableConfigs.sortBy;
        break;
      case DISHES_TABLE_TAB:
        table.data = dishesTableConfigs.getData(by_code, branches);
        table.columns = dishesTableConfigs.columns;
        table.excelRowConfig = dishesTableConfigs.excelRowConfig;
        table.aggregates = dishesTableConfigs.aggregates;
        table.sortBy = dishesTableConfigs.sortBy;
        break;
      case RAWS_TABLE_TAB:
        table.data = rawsTableConfigs.getData(by_code, branches);
        table.columns = rawsTableConfigs.columns;
        table.excelRowConfig = rawsTableConfigs.excelRowConfig;
        table.excelColConfig = rawsTableConfigs.excelColConfig;
        table.aggregates = rawsTableConfigs.aggregates;
        table.sortBy = rawsTableConfigs.sortBy;
        break;
      case UNLINKED_TABLE_TAB:
        table.data = unlinkedTableConfigs.getData(by_code, branches);
        table.columns = unlinkedTableConfigs.columns;
        table.excelRowConfig = unlinkedTableConfigs.excelRowConfig;
        table.aggregates = unlinkedTableConfigs.aggregates;
        table.sortBy = unlinkedTableConfigs.sortBy;
        break;
      case THOUSAND_USE_TAB:
        table.data = thousandUseTableConfigs.getData(saleEvents, comulative);
        table.columns = thousandUseTableConfigs.columns;
        table.excelRowConfig = thousandUseTableConfigs.excelRowConfig;
        table.aggregates = thousandUseTableConfigs.aggregates;
        break;
    }

    setTablesData([table]);
  };

  const handleLink = (soldDish) => {
    setLinkModalState((prev) => ({ ...prev, open: true, soldDish }));
  };

  /**
   * source: clicking branch name in branches table
   * action: open sub table with sales grouped by date for that branch
   */
  const getGroupedSoldDishesByBranch = async (row) => {
    const params = {
      branch_ids: [row.branch_id],
      start_date: minDate.format("YYYY-MM-DD"),
      end_date: maxDate.format("YYYY-MM-DD"),
    };

    const { getData, columns, excelRowConfig, aggregates, sortBy } =
      branchByDateTableConfigs;

    const { data } = await Axios.get(GROUPED_SOLD_DISHES, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });

    setTablesData((tablesData) => [
      tablesData[0],
      {
        title: `מכירות לפי יום לסניף ${row.branch_name}`,
        data: getData(data.by_date),
        columns,
        excelRowConfig,
        parentRow: row,
        aggregates,
        sortBy,
      },
    ]);
  };

  /**
   * source: clicking items count in branchByDate sub table
   * action: open third table with sales grouped by code for that branch and date
   */
  const getGroupedSoldDishesByBranchAndDate = async (row) => {
    const { branch_id, branch_name } = tablesData[1].parentRow;
    const { getData, columns, excelRowConfig, aggregates, sortBy } =
      branchByCodeTableConfigs;

    const params = {
      branch_ids: [branch_id],
      start_date: row.date,
      end_date: row.date,
    };

    const { data } = await Axios.get(GROUPED_SOLD_DISHES, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });

    setTablesData((tablesData) => [
      tablesData[0],
      tablesData[1],
      {
        title: `מכירות לפי מוצר לסניף ${branch_name} בתאריך ${fixDate(
          row.date
        )}`,
        data: getData(data.by_code),
        columns,
        excelRowConfig,
        aggregates,
        sortBy,
      },
    ]);
  };

  /**
   * source: clicking linked item in meals/dishes/raws tables
   * action: open sub table with linked sales
   */
  const getSoldDishesByNameAndCode = async (row) => {
    const { getData, columns, excelRowConfig, aggregates, sortBy } =
      byLinkTableColumns;

    const params = {
      branch_ids: by_branch.map(({ branch_id }) => branch_id),
      start_date: minDate.format("YYYY-MM-DD"),
      end_date: maxDate.format("YYYY-MM-DD"),
      product_code: row.product_code,
      name: row.name,
    };

    const { data } = await Axios.get(SOLD_DISH_PATH, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });

    setTablesData((tablesData) => [
      tablesData[0],
      {
        title: `מכירות המקושרות ל${row.link.name}`,
        data: getData(data, branches),
        columns,
        excelRowConfig,
        aggregates,
        sortBy,
      },
    ]);
  };

  // ======== CONSTANTS ========
  //shared props for all tables
  const cellsPropsById = () => ({
    dispatch,
    handleLink,
    getGroupedSoldDishesByBranch,
    getGroupedSoldDishesByBranchAndDate,
    getSoldDishesByNameAndCode,
  });

  // ======== EFFECTS ========
  //reset sub tables when either tab is changed or deta fetched form server
  useEffect(() => {
    handleTableDataChange();
  }, [data, selectedTab]);

  // ======== UI ========

  return (
    <div>
      <PageNav
        isMobile={isMobile}
        options={TABLE_TAB_OPTIONS}
        onClick={(tab) => setSelectedTab(tab)}
      />

      {tablesData.map((tableData, i) => (
        <SalesCostTable
          {...{ cellsPropsById, tableData }}
          key={i}
          scroll={i > 0}
        />
      ))}

      {linkModalState.open && (
        <LinkModal
          {...{ linkModalState, setData, data }}
          onClose={() =>
            setLinkModalState((prev) => ({ ...prev, open: false }))
          }
        />
      )}
    </div>
  );
};

export default TablesSection;
