import { connect } from "react-redux";
 //import { BASE_URL } from "../../config/auth";

const BASE_URL = process.env.REACT_APP_URL;

const Iframe = ({ src, token }) => (
  <div  className="holds-the-iframe">
    <iframe
      src={`${BASE_URL}/${src}?react=true&token=${token}`}
      referrerPolicy="no-referrer-when-downgrade"
      className="legacy-app"
      sandbox="allow-forms
    allow-pointer-lock
    allow-popups
    allow-same-origin
    allow-scripts
    allow-top-navigation
    allow-downloads
    allow-modals"
    />
  </div>

);

const mapStateToProps = (state) => {
  const { token } = state.auth;
  return { token };
};

export default connect(mapStateToProps)(Iframe);
