import { isEmpty } from "lodash";

export const resetDaysOfWeek = (obj) => {
  obj.sunday = 0;
  obj.monday = 0;
  obj.tuesday = 0;
  obj.wednesday = 0;
  obj.thursday = 0;
  obj.friday = 0;
  obj.saturday = 0;
};

export const mapCatalogItemWithWeekDays = (catalogItem, cartItems, asocSuppliers) => {
  const weekdays = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const mappedItem = { ...catalogItem };
  mappedItem["hasArrival"] = !isEmpty(
    asocSuppliers[mappedItem.supplier_id]?.supplier_arrival
  );

  weekdays.forEach((day) => {
    mappedItem[day] = cartItems[catalogItem.id]?.weekDays[day] || 0;
  });

  return mappedItem;
};
