import { FETCH_USER_PERMISSIONS } from "../actions/permissions/types";

const INITIAL_STATE = {
  pages: [],
  fetched: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_PERMISSIONS:
      return { ...payload, fetched: true };

    default:
      return state;
  }
};

export default reducer;
