import SimpleTable from "../../../orders/manageOrders/receiveOrder/SimpleTable";

const columns = [
  {
    title: "שם עובד",
    name: "employee_name",
    render: (data, row) => {
      return data;
    },
  },
  {
    title: "מספר עובד",
    name: "employee_clock_number",
    render: (data, row) => {
      return data;
    },
  },
  {
    title: "ת.ז",
    name: "employee_identifier",
    render: (data, row) => {
      return data;
    },
  },
  {
    title: "תאריך",
    name: "date",
    render: (data, row) => {
      return data.replaceAll("-", "/");
    },
  },
  {
    title: "שעה",
    name: "time",
    render: (data, row) => {
        let split = data.split('');
        split.splice(2,0,':');
        return split.join('');
    },
  },
  {
    title: "קוד החתמה",
    name: "type",
    render: (data, row) => {
      return data === "E" ? "יציאה" : "כניסה";
    },
  },
];

const HilanetPreviewTable = ({ data }) => {
  return <SimpleTable {...{ data, columns, cellSize: "medium" }} />;
};

export default HilanetPreviewTable;
