import { isFunction } from "lodash";
import React, { useState, cloneElement } from "react";
import { connect } from "react-redux";
import OutsideAlerter, { useScreenSize } from "../../Hooks";

// expect a list of buttons
const Dropdown = ({
  style,
  contentStyle,
  headerStyle,
  children,
  dropShow = true,
  text = "",
  disabled = false,
  isDate = false,
  onClose,
  OutsideAlerterFluid = false,
}) => {
  const [show, setShow] = useState(false);
  const { isMobile } = useScreenSize();

  const handleShow = (showAction) => {
    if (showAction === show) return;
    setShow(showAction);
    if (isFunction(onClose) && !showAction) {
      onClose();
    }
  };

  return (
    <OutsideAlerter
      style={OutsideAlerterFluid ? { width: "100%" } : {}}
      onOuterClick={() => handleShow(false)}
    >
      <div
        style={style}
        className={`dropdown ${dropShow ? "dropdown__show" : ""}`}
      >
        <button
          style={headerStyle}
          disabled={disabled}
          type="button"
          className={`btn--none fluid dropdown--header ${
            disabled ? "disabled" : ""
          }`}
          onClick={() => handleShow(!show)}
        >
          {text}
          <i className="fa fa-caret-down dropdown__icon"></i>
        </button>
        <div
          style={contentStyle}
          className={`dropdown dropdown--content ${
            show ? "dropdown--content__show" : ""
          } ${isDate && isMobile ? "absCenter__fixed" : ""}`}
        >
          {cloneElement(children, { setShow: handleShow })}
        </div>
      </div>
    </OutsideAlerter>
  );
};
const mapStateToProps = (state) => {
  const { isMobile } = state.publicValues;
  return { isMobile };
};
export default connect(mapStateToProps)(Dropdown);
