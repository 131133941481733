import React, { useEffect, useMemo } from "react";
import { useScreenSize } from "../../../../Hooks";
import { useDispatch, useSelector } from "react-redux";
import MultiSelectCheckbox from "../../../../components/dropdown/MultiSelectCheckBox";
import { fetchEmployees } from "../../../../redux/actions/scheduleAction";

const SalaryByIdentifierFilter = ({ checkedBranches, setCheckedBranches }) => {
  const dispatch = useDispatch();
  const branches = Object.values(
    useSelector((state) => state.branches.branches)
  ).map((x) => ({
    label: x.name,
    value: x.id,
  }));
  const branchOptions = useMemo(() => [...branches], [branches]);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    dispatch(fetchEmployees(checkedBranches));
  }, [checkedBranches]);
  return (
    <>
      <div style={isMobile ? {} : { marginLeft: "2rem" }}>
        <label>
          סניף
          <MultiSelectCheckbox
            title="בחר סניף"
            checked={checkedBranches}
            setChecked={setCheckedBranches}
            options={branchOptions}
          />
        </label>
      </div>
    </>
  );
};

export default SalaryByIdentifierFilter;
