// import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const MuiTabs = ({
  isMobile = false,
  options,
  onChange,
  defaultValue = 0,
  style = { direction: "ltr" },
}) => {
  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = React.useMemo(
    () => (event, newValue) => {
      setValue(newValue);
      onChange(options[newValue]);
    },
    [options]
  );

  return (
    <Box
      className="mui-tabs"
      style={style}
      sx={{
        flexGrow: 1,
        width: "fit-content",
        maxWidth: "100%",
        bgcolor: "background.paper",
      }}
    >
      <Tabs
        style={{ fontSize: "2rem" }}
        value={value}
        onChange={handleChange}
        variant={isMobile ? "fullWidth" : "scrollable"}
        // scrollButtons
        scrollButtons="auto"
        aria-label="visible arrows tabs"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        {options.map((option, index) => (
          <Tab
            style={{ fontSize: isMobile ? "1.5rem" : "2rem" }}
            key={option.id}
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`}
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default MuiTabs;
