import jwtAxios from "../../../axios/jwtAxios";
import { SET_ERROR } from "../errorAction/types";
import { LOGOUT } from "../loginAction/types";
import { SET_DATA_LOADING } from "../proceduresActions/types";
import {
  GET_ADMINS_LIST,
  GET_ALL_BRANCHES,
  GET_ALL_LOGS,
  GET_ALL_NETWORKS,
  SET_USER_LOGS_PAGINATION,
} from "./types";

const error = (e) => async (dispatch, getState) => {
  if (e.response)
    if (e.response.status === 401) {
      dispatch({ type: LOGOUT });
    } else if (e.response.status === 500) {
      dispatch({
        type: SET_ERROR,
        errorTitle: "ארעה שגיאה בשרת",
        error: getState().publicValues.isMobile
          ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
          : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
      });
    }
};

export const getAllLogs = () => async (dispatch, getState) => {
  try {
    const filters = getState().UserLogs.filters;
    // dispatch({ type: SET_DATA_LOADING, payload: true });
    const { data } = await jwtAxios.get("/admin/user-logs", {
      params: {
        page: filters.current_page,
        per_page: filters.per_page,
        start_date: filters.selectedDates.startDate,
        end_date: filters.selectedDates.endDate,
        selectedAdmins: filters.selectedAdmins,
        selectedNetworks: filters.selectedNetworks,
        firstName: filters.userFirstName,
        lastName: filters.userLastName,
        branchName: filters.branchName,
      },
    });
    dispatch({ type: GET_ALL_LOGS, payload: data.data });
    dispatch({
      type: SET_USER_LOGS_PAGINATION,
      payload: {
        current_page: data.current_page,
        per_page: data.per_page,
        total: data.total,
        last_page: data.last_page,
      },
    });
  } catch (err) {}
};

export const getAdmins = () => async (dispatch, getState) => {
  const { data } = await jwtAxios.get("/admin/admins");

  if (data) {
    let adminFullNames = [];
    adminFullNames = data.map((admin, index) => {
      return {
        id: admin.login_admin_web_id,
        label: `${admin.first_name} ${admin.last_name}`,
      };
    });
    dispatch({ type: GET_ADMINS_LIST, payload: adminFullNames });
  }
};

export const getNetworks = () => async (dispatch, getState) => {
  const { data } = await jwtAxios.get("admin/network");

  if (data) {
    let networks = [];
    networks = Object.values(data).map((network) => {
      return {
        id: network.value,
        label: network.label,
      };
    });

    dispatch({ type: GET_ALL_NETWORKS, payload: networks });
  }
};

export const getBranches = () => async (dispatch, getState) => {
  const { data } = await jwtAxios.get("/admin/branches");
  if (data) {
    let branches = data.map((branch) => {
      return {
        id: branch.value,
        label: branch.label,
      };
    });
    dispatch({ type: GET_ALL_BRANCHES, payload: branches });
  }
};
