import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { forwardRef } from "react";
import ErrorMessage from "../ErrorMessage";

const FormCB = forwardRef(function (
  {
    control,
    name,
    label,
    disabled,
    rules,
    style = { marginRight: "1rem", whiteSpace: "nowrap" },
    defaultValue,
    handleChange = () => {},
    ...props
  },
  toolTipRef
) {
  return (
    <Controller
      {...{
        control,
        name,
        defaultValue,
        rules,
        render: ({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error },
        }) => {
          return (
            <>
              <FormControl error={!!error}>
                <FormControlLabel
                  style={style}
                  ref={toolTipRef}
                  // {...props}
                  control={
                    <Checkbox
                      {...{
                        // value,
                        checked: !!value,
                        name,
                        onBlur,
                        inputRef: ref,
                        disabled,
                      }}
                      onSelect={(e) => {
                        onChange(e);
                        handleChange(e, name);
                      }}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e, name);
                      }}
                    />
                  }
                  label={label}
                />

                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            </>
          );
        },
      }}
    />
  );
});

export default FormCB;
