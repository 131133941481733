import { Link, Tooltip } from "@mui/material";
import {
  CountFooter,
  PercentageCell,
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";

import {
  dynamicToFixed,
  fixPercentage,
  fixPrice,
  getSafeDivision,
  submitWrapper,
  thousandSeparator,
} from "../../../helpers";
import { DEFAULT_MAX_FC, FcHeader, SOLD_DISH_TYPE_RAW } from "../const";
import { GREEN_COLOR, RED_COLOR } from "../../products/const";

export const getData = (
  by_code, //raw data
  branches // redux state
) => {
  return by_code
    .filter((soldDish) => soldDish.type === SOLD_DISH_TYPE_RAW)
    .map((dish) => {
      const { product_code, name, link, branch_ids, amount, total, worth } =
        dish;

      const branch_names = branch_ids.map(
        (branch_id) => branches.find((branch) => branch.id === branch_id).name
      );

      const avg_price = getSafeDivision(total, amount);
      const avg_foodcost = getSafeDivision(worth, total) * 100;

      return {
        product_code,
        name,
        link,
        branch_names,
        amount,
        total_with_vat: total,
        avg_price,
        worth,
        avg_foodcost,
      };
    });
};

export const excelRowConfig = (row) => {
  row.amount = dynamicToFixed(row.amount);
  row.total_with_vat = dynamicToFixed(row.total_with_vat);
  row.worth = dynamicToFixed(row.worth);
  row.avg_price = dynamicToFixed(row.avg_price);
  row.avg_foodcost = dynamicToFixed(row.avg_foodcost);

  //excel link columns only
  const {
    name,
    sale_price_with_vat,
    price_with_vat,
    supplier_name,
    part_number,
    manufacturer_number,
  } = row.link;

  row.link_name = name;
  row.link_sale_price = fixPrice(sale_price_with_vat, 1, false);
  row.link_price = fixPrice(price_with_vat, 1, false);
  row.link_supplier_name = supplier_name;
  row.link_part_number = part_number;
  row.manufacturer_number = manufacturer_number;

  return row;
};

export const aggregates = [
  { field: "amount", aggregate: "sum" },
  { field: "total_with_vat", aggregate: "sum" },
  { field: "worth", aggregate: "sum" },
  { field: "avg_price", aggregate: "sum" },
];

export const excelColConfig = (defaultColumns) => {
  const excelCols = [...defaultColumns];
  excelCols.splice(
    2,
    1,
    { Header: "שם מוצר מקושר", id: "link_name" },
    { Header: "מחיר מכירה מוצר מקושר", id: "link_sale_price" },
    { Header: "מחיר מחירון מוצר מקושר", id: "link_price" },
    { Header: "ספק מוצר מקושר", id: "link_supplier_name" },
    { Header: "מק״ט מוצר מקושר", id: "link_part_number" },
    { Header: "ברקוד יצרן מוצר מקושר", id: "manufacturer_number" }
  );
  return excelCols;
};

export const sortBy = [{ id: "avg_foodcost", desc: true }];

export const columns = [
  {
    Header: "קוד קופה",
    accessor: "product_code",
    Footer: () => "סה״כ",
  },
  {
    Header: "שם",
    accessor: "name",
  },
  {
    Header: "מוצר מקושר",
    accessor: "link",
    Cell: ({ value, row, getSoldDishesByNameAndCode, dispatch }) => {
      const {
        name,
        supplier_name,
        category_name,
        price_with_vat,
        sale_price_with_vat,
        part_number,
        manufacturer_number,
      } = value;

      const toolTipRows = [
        `מחיר מכירה: ${fixPrice(sale_price_with_vat, 1, false)}`,
        `קטגוריה: ${category_name}`,
      ];

      if (price_with_vat)
        toolTipRows.push(`מחיר מחירון: ${fixPrice(price_with_vat, 1, false)}`);
      if (supplier_name) toolTipRows.push(`ספק: ${supplier_name}`);
      if (part_number) toolTipRows.push(`מק״ט: ${part_number}`);
      if (manufacturer_number)
        toolTipRows.push(`ברקוד יצרן: ${manufacturer_number}`);

      return (
        <Tooltip
          title={
            <div className="flex-column-right" style={{ fontSize: "1.5rem" }}>
              {toolTipRows.map((row, key) => (
                <span {...{ key }}>{row}</span>
              ))}
            </div>
          }
          placement="top-start"
        >
          <Link
            component="button"
            sx={{
              fontSize: "1.6rem",
            }}
            onClick={() =>
              submitWrapper(getSoldDishesByNameAndCode, dispatch, [
                row.original,
              ])
            }
          >
            {name}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    Header: "סניפים",
    accessor: "branch_names",
    disable_export_excel: true,
    Cell: ({ value: branch_names }) => {
      const amount = branch_names.length;

      return (
        <Tooltip
          title={
            <span style={{ fontSize: "1.5rem" }}>
              {branch_names.join(", ")}
            </span>
          }
          placement="top-start"
        >
          <Link
            component="span"
            sx={{
              fontSize: "1.6rem",
            }}
          >
            {`${amount} סניפים`}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    Header: "כמות שנמכרה",
    accessor: "amount",
    Cell: ({ value }) => (
      <span className="is-number">{thousandSeparator(value)}</span>
    ),
    Footer: CountFooter,
  },
  {
    Header: "מכירות כולל מע״מ",
    accessor: "total_with_vat",
    Cell: PriceCell,
    Footer: (props) => PriceFooter(props, 1, false),
  },
  {
    Header: "עלות רכיבים",
    accessor: "worth",
    Cell: PriceCell,
    Footer: (props) => PriceFooter(props, 1, false),
  },
  {
    Header: "מחיר מנה",
    accessor: "avg_price",
    Cell: PriceCell,
    Footer: (props) => PriceFooter(props, 1, false),
  },
  {
    Header: FcHeader,
    accessor: "avg_foodcost",
    Cell: (props) => (
      <span
        style={{
          color: props.value <= DEFAULT_MAX_FC ? GREEN_COLOR : RED_COLOR,
        }}
      >
        {PercentageCell(props)}
      </span>
    ),
  },
];
