import React, { useEffect } from "react";
import { connect } from "react-redux";
import { hideConfirm, initConfirm } from "../../redux/actions/confirmAction";
import { Modal } from "react-responsive-modal";

const ConfirmModal = ({
  isOpen,
  confirmMsg,
  confirmTitle,
  hideConfirm,
  initConfirm,
  timeout = 1000,
}) => {

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        hideConfirm();
      }, timeout);
    }
  }, [isOpen, hideConfirm]);

  return (
    <Modal
      styles={{
        modal: { maxWidth: "50rem", textAlign: "center", padding: "3.5rem" },
      }}
      center
      open={isOpen}
      showCloseIcon={false}
      onClose={hideConfirm}
      animationDuration={0}
      onAnimationEnd={()=>{if(!isOpen)initConfirm()}}
    >
      <div style={{ position: "relative", width: "14rem", margin: "0 auto" }}>
        <div className="sa">
          <div className="sa-success">
            <div className="sa-success-tip"></div>
            <div className="sa-success-long"></div>
            <div className="sa-success-placeholder"></div>
            <div className="sa-success-fix"></div>
          </div>
        </div>
      </div>

      <h2>{confirmTitle}</h2>
      <p className="paragraph">{confirmMsg ? confirmMsg : ""}</p>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { isOpen, confirmMsg, confirmTitle, timeout } = state.confirm;
  return { isOpen, confirmMsg, confirmTitle, timeout };
};

export default connect(mapStateToProps, { hideConfirm, initConfirm })(
  ConfirmModal
);
