//style
import "react-medium-image-zoom/dist/styles.css";
import "rc-tooltip/assets/bootstrap_white.css";
import "react-day-picker/lib/style.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./sass/main.scss";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";

import Axios from "axios";

import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { useIsMobileMobx } from "./Hooks";
import Router from "./route/Router";
import ErrorModal from "./components/modals/ErrorModal";
import ConfirmModal from "./components/modals/ConfirmModal";
import DialogModal from "./components/modals/DialogModal";
import LoaderModal from "./components/modals/LoaderModal";
import ConfirmBar from "./components/modals/ConfirmBar";
import SnackBar from "./components/snakbar";
import Message from "./components/Message";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./pages/Fallback/Fallback";
import "regenerator-runtime";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

Axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const App = () => {
  useIsMobileMobx();
  const token = useSelector(({ auth }) => auth.token);

  window.onReceiveFCMToken = async function (fcm_token) {
    if (token) {
      await Axios.post(
        "fcmTokenSave",
        { fcm_token: fcm_token },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    }
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={process.env.REACT_APP_ENV !== "local" && Fallback}
        >
          <Router />
          {/* modals  */}
          <ErrorModal />
          <ConfirmModal />
          <DialogModal />
          <LoaderModal />
          <ConfirmBar />
          <SnackBar />
          {token && <Message />}
        </ErrorBoundary>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
