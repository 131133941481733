// LOADING
export const PRODUCTS_LOADING = "PRODUCTS_LOADING"
export const MEALS_LOADING = "MEALS_LOADING"
export const DISHES_LOADING = "DISHES_LOADING"
export const PRODUCT_DETAILS_LOADING = "PRODUCT_DETAILS_LOADING"
export const SEARCH_INGREDIENTS_LOADING = "SEARCH_INGREDIENTS_LOADING"

export const UNIFIED_LOADING = "UNIFIED_LOADING"
export const LOADING_FREESEARCH = "LOADING_FREESEARCH"
export const UNIFIED_LOADING_DETAILS = "UNIFIED_LOADING_DETAILS"
export const UNIFIED_LOADING_INGREDIENTS = "UNIFIED_LOADING_INGREDIENTS"

export const CATEGORIES_LOADING = "CATEGORIES_LOADING"
export const ALLERGENS_LOADING = "ALLERGENS_LOADING"
export const POS_CODES_LOADING = "POS_CODES_LOADING"
export const FETCH_FEES_LOADING = "FETCH_FEES_LOADING"
export const CREATE_FEE_LOADING = "CREATE_FEE_LOADING"

// FETCH
export const FETCH_PRODUCTS = "FETCH_PRODUCTS"
export const FETCH_MEALS = "FETCH_MEALS"
export const FETCH_DISHES = "FETCH_DISHES"
export const FETCH_PRODUCT = "FETCH_PRODUCT"
export const FETCH_SEARCH_INGREDIENTS = "FETCH_SEARCH_INGREDIENTS"

export const FETCH_UNIFIED = "FETCH_UNIFIED"

export const FETCH_FREESEARCH = "FETCH_FREESEARCH"
export const FETCH_MEALS_FREESEARCH = "FETCH_MEALS_FREESEARCH"
export const FETCH_DISHES_FREESEARCH = "FETCH_DISHES_FREESEARCH"
export const FETCH_UNIFIED_FREESEARCH = "FETCH_UNIFIED_FREESEARCH"


export const FETCH_UNIFIED_DETAILS = "FETCH_UNIFIED_DETAILS"
export const FETCH_UNIFIED_INGREDIENTS = "FETCH_UNIFIED_INGREDIENTS"

export const FETCH_CATEGORIES = "FETCH_CATEGORIES"
export const FETCH_ALLERGENS = "FETCH_ALLERGENS"
export const FETCH_POS_CODES = "FETCH_POS_CODES"
export const FETCH_FEES = "FETCH_FEES"
export const FETCH_ITEMS = "FETCH_ITEMS"

// ADD
export const ADD_PRODUCT = "ADD_PRODUCT"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const ADD_ALLERGEN = "ADD_ALLERGEN"
export const ADD_PRODUCT_INGREDIENT = "ADD_PRODUCT_INGREDIENT"
export const ADD_UNIFIED_INGREDIENT = "ADD_UNIFIED_INGREDIENT"

// DELETE
export const DELETE_PRODUCT_INGREDIENT = "DELETE_PRODUCT_INGREDIENT"
export const DELETE_UNIFIED_INGREDIENT = "DELETE_UNIFIED_INGREDIENT"

// UPDATE
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_MEAL = "UPDATE_MEAL"
export const UPDATE_DISH = "UPDATE_DISH"
export const UPDATE_UNIFIED = "UPDATE_UNIFIED"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_ALLERGEN = "UPDATE_ALLERGEN"
export const UPDATE_FEE = "UPDATE_FEE"

// CREATE
export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const CREATE_UNIFIED = "CREATE_UNIFIED"
export const CREATE_FEE = "CREATE_FEE"

// EDIT
export const EDIT_PRODUCT_INGREDIENT = "EDIT_PRODUCT_INGREDIENT"
export const EDIT_UNIFIED_INGREDIENT = "EDIT_UNIFIED_INGREDIENT"

// SET
export const SELECTED_TAB = "SELECTED_TAB"

export const SET_FREE_SEARCH = "SET_FREE_SEARCH"
export const SET_MEALS_FREE_SEARCH = "SET_MEALS_FREE_SEARCH"
export const SET_DISHES_FREE_SEARCH = "SET_DISHES_FREE_SEARCH"
export const SET_UNIFIED_FREE_SEARCH = "SET_UNIFIED_FREE_SEARCH"


export const SET_CATEGORY_FIELD = "SET_CATEGORY_FIELD"
export const SET_INGREDIENT = "SET_INGREDIENT"
export const SET_FEE = "SET_FEE"

export const SET_PAGINATION = "SET_PAGINATION"
export const SET_PAGE = "SET_PAGE"

export const SET_MEALS_PAGINATION = "SET_MEALS_PAGINATION"
export const SET_MEALS_PAGE = "SET_MEALS_PAGE"
export const SET_DISHES_PAGINATION = "SET_DISHES_PAGINATION"
export const SET_DISHES_PAGE = "SET_DISHES_PAGE"
export const SET_UNIFIED_PAGINATION = "SET_UNIFIED_PAGINATION"
export const SET_UNIFIED_PAGE = "SET_UNIFIED_PAGE"

export const SET_FILTER = "SET_FILTER"
export const RESET_FILTER = "RESET_FILTER"

// OTHER
export const CLOSE_FORM = "CLOSE_FORM"
export const SELECTED_FILTERS = "SELECTED_FILTERS"
export const DUPLICATE_PRODUCT = "DUPLICATE_PRODUCT"
export const DUPLICATE_DISH = "DUPLICATE_DISH"
export const DUPLICATE_MEAL = "DUPLICATE_MEAL"
export const CLREAR_FREE_SERACH = "CLREAR_FREE_SERACH"

