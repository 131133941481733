import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import {
  Box,
  Button,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Axios from "axios";
import { fixPrice } from "../../../helpers";
import { startLoading, stopLoading } from "../../../redux/actions/loaderAction";
import { setConfirm } from "../../../redux/actions/confirmAction";
import { IoReload } from "react-icons/io5";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const AgeSalaryModal = ({ open, onClose }) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authAdmin.token);
  const [ageSalary, setAgeSalary] = useState(null);

  const age_salary_labels = {
    16: "שכר מינימום 16-17",
    17: "שכר מינימום 17-18",
    18: "שכר מינימום 18 +",
  };
  // ======== STATES ========

  // ======== HANDLERS ========
  const getSalaryStructure = async () => {
    const { data } = await Axios.get(`/admin/age_salary`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setAgeSalary(data);
  };

  const updateAgeSalary = (value, age) => {
    setAgeSalary((prev) => ({ ...prev, [age]: +value }));
  };

  const sendAgeSalary = async () => {
    dispatch(startLoading("שומר משכורות מינימום"));
    const { data, status } = await Axios.post(
      `/admin/update_age_salary`,
      ageSalary,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (status === 200) {
      dispatch(setConfirm(data));
    }
    dispatch(stopLoading());
  };

  const updateRetroSalary = async () => {
    dispatch(startLoading("מעדכן משכורות מינימום"));
    const { data, status } = await Axios.post(
      `/admin/update_retro_salary`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (status === 200) {
      dispatch(setConfirm(data));
    }
    dispatch(stopLoading());
  };
  // ======== CONSTANTS ========

  // ======== EFFECTS ========
  useEffect(() => {
    getSalaryStructure();
  }, []);

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="customized-dialog-title" onClose={() => {}}>
        <div className="flex-between-center">
          <span>ניהול תעריף מינימום לפי גיל</span>
        </div>
      </DialogTitle>
      <DialogContent>
        {ageSalary === null ? (
          // Display the spinner when ageSalary is null
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="150px"
          >
            <CircularProgress />
            <Typography variant="h6" style={{ marginLeft: "30px" }}>
              טוען מידע
            </Typography>
          </Box>
        ) : (
          // Render the ageSalary content when it's not null
          <Grid container justifyContent={"center"} padding={1}>
            <Grid item container xs={6}>
              {Object.entries(ageSalary).map(([key, value]) => (
                <Grid item spacing={0} container key={key} xs={12}>
                  <Grid item xs={12}>
                    <Typography component={"label"}>
                      {age_salary_labels[key]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => updateAgeSalary(e.target.value, key)}
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">₪</InputAdornment>
                        ),
                      }}
                      value={value}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={1}>
              <Divider
                orientation="vertical"
                sx={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              />
            </Grid>
            <Grid item container xs={4}>
              <Grid item xs={12}>
                <IconButton
                  title="hihihihi"
                  aria-label="Open Modal"
                  color="primary"
                  onClick={updateRetroSalary}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    whiteSpace={"nowrap"}
                    style={{ marginLeft: "20px" }}
                  >
                    עדכן שכר
                  </Typography>
                  <IoReload size={isMobile ? 17 : 20} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogActions style={{ gap: 10 }}>
          <Button autoFocus onClick={onClose} variant="contained" size="large">
            ביטול
          </Button>
        </DialogActions>
        <DialogActions style={{ gap: 10 }}>
          <Button
            color={"primary"}
            autoFocus
            onClick={() => {
              sendAgeSalary();
            }}
            variant="contained"
            size="large"
          >
            שמור
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default AgeSalaryModal;
