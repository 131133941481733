import React, { useMemo } from "react";
import ReactTable from "../../../../components/tables/ReactTable";
import { FaPencilAlt, FaUserTimes } from "react-icons/fa";
import ButtonIcon from "../../../../components/buttons/ButtonIcon";
import EditShiftHours from "../../../schedule/manager/table/models/EditShiftHours";
import Dialog from "../../../../components/modals/Dialog";
import { cloneDeep } from "lodash";

import { getHebDay, fixHour, fixDate } from "../../../../helpers";

const ErrorAttendanceTable = ({
  attendanceData,
  cellsPropsById = () => ({}),
  eventToEdit,
  setEventToEdit,
  updateTable,
}) => {
  const data = useMemo(() => attendanceData, [attendanceData]);

  const columns = useMemo(
    () => [
      {
        Header: "עובד",
        accessor: "employee_name",
      },
      {
        Header: "תאריך",
        accessor: "working_date",
        Cell: ({ row }) => fixDate(row.original.working_date),
      },
      {
        Header: "יום",
        accessor: "week_day",
        Cell: (props) => getHebDay(props.row.original.week_day),
      },
      {
        Header: "משמרת",
        accessor: "shift_name",
      },
      {
        Header: "תפקיד",
        accessor: "role_name",
      },
      {
        Header: "שעת התחלה",
        accessor: "actual_start",
        Cell: (props) => `${fixHour(props.row.original.actual_start)}`,
      },
      {
        Header: "שעת סיום",
        accessor: "actual_end",
        Cell: (props) => `${fixHour(props.row.original.actual_end)}`,
      },
      {
        Header: "פעולות",
        accessor: "updatebuttons",
        Cell: (props) => {
          const {
            actual_end,
            actual_start,
            manual_start,
            manual_end,
            clock_start,
            clock_end,
            planned_start,
            planned_end,
            trim_start,
            trim_end,
            role_name,
            employee_id,
            employee_name,
            working_date,
          } = props.row.original;
          const event = props.row.original;
          const {
            handleEventToDelete,
            deleteEvent,
            row,
            setDataState,
            setEventToEdit,
          } = props;
          let data = cloneDeep(props.data);
          const { isManager, blocked_from_date, isAllowedToEdit } = props;
          const is_blocked_by_date = blocked_from_date
            ? working_date < blocked_from_date
            : false;

          const disabled = !isManager || is_blocked_by_date || !isAllowedToEdit;

          return false ? (
            ""
          ) : (
            <div>
              <div className="flex-center">
                <ButtonIcon
                  style={{
                    width: "unset",
                    height: "unset",
                    marginLeft: "0.5rem",
                    color: "#FFCE00",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid black",
                    borderRadius: "0",
                    lineHeight: "0.8",
                  }}
                  alt="ערוך שעות בפועל ידני"
                  icon={FaPencilAlt}
                  iconSize="1.4em"
                  onClick={() => {
                    setEventToEdit({
                      ...event,
                      hours: {
                        manual: {
                          start: manual_start,
                          end: manual_end,
                        },
                        trim: {
                          start: trim_start,
                          end: trim_end,
                        },
                        planned: {
                          start: planned_start,
                          end: planned_end,
                        },
                        clock: {
                          start: clock_start,
                          end: clock_end,
                        },
                      },
                      role: { name: role_name },
                      employee: { name: employee_name, id: employee_id },
                      openHoursEdit: true,
                    });
                  }}
                />
                <ButtonIcon
                  style={{
                    width: "unset",
                    height: "unset",
                    color: " rgb(219, 30, 30)",
                    backgroundColor: "transparent",
                    lineHeight: "0.8",
                  }}
                  alt="מחק משמרת"
                  icon={FaUserTimes}
                  iconSize="1.4em"
                  onClick={() => {
                    if (actual_start || actual_end) {
                      handleEventToDelete({ event, index: row.index });
                    } else {
                      deleteEvent({
                        event,
                        isReport: true,
                        onSuccess: () => {
                          data.splice(row.index, 1);
                          setDataState(data);
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          );
        },
      },
    ],
    [data]
  );

  return (
    <>
      <Dialog
        setOpen={() => {
          setEventToEdit(null);
        }}
        open={!!(eventToEdit != null)}
        sx={{ padding: "2rem" }}
        transitionDuration={0}
      >
        {!!eventToEdit && (
          <EditShiftHours
            style={{ boxShadow: "none" }}
            saveToReport={true}
            handleOpen={setEventToEdit}
            event={eventToEdit}
            updateTable={updateTable}
            validateChanges={true}
          />
        )}
      </Dialog>
      <ReactTable
        columns={columns}
        data={data}
        cellsPropsById={cellsPropsById}
      />
    </>
  );
};

export default ErrorAttendanceTable;
