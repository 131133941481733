import { isObject } from "lodash";
import { useMemo } from "react";
import { sumFooter } from "../../../../components/tables/helpers";
import BtnTooltip from "../../../../components/tooltips/BtnTooltip";
import {
  fixPrice,
  getSafe,
  thousandSeparator,
  twoZeroAfterDot,
} from "../../../../helpers";

const renderIfNotDepartment = ({ isDepartment, value, depth, row }) => {
  if (isDepartment && depth === 0 && row.original.subRows) {
    return "";
  } else return value;
};

export const salary_hidden = [
  "enable",
  "working_days",
  "const_travel_cost",
  "cost_tip",
  "salary",
  "break",
  "global_salary",
  "current_salary_structure",
  "current_salary_type",
  "employee_accountancy_number",
  "employee_identifier",
  "free_monthly",
  "hours_week_100",
  "hours_week_125",
  "hours_week_150",
  "hours_125",
  "hours_100",
  "hours_150",
  "hours_150_shabat",
  "hours_in_holiday",
  "hours_150_night",
  "hours_in_holiday_hours_150_shabat",
  "hours_150_without_night",
  "hours_175",
  "hours_200",
  "tips_allowance",
  "tips_cash",
  "tips_complete",
  "tips_credit",
  "tips_total",
  "hourly_tip_before_allowance",
  "hourly_tip_after_allowance",
  "waiting_minutes",
  "waiting_salary",
  "base_salary",
  "extra_shabat_salary",
  "cash_tip_before_allowance",
  "credit_tip_before_allowance",
  "tips_total_before_allowance",
  "allowance_percentage",
  "bonus",
  "on_account",
  "hebrew_create_date",
  "neto_for_payment",
  "cost_raw_tips_credit",
  "social_hours",
  "travel_cost",
  "meal_cost",
  "health_cost",
  "holiday_cost",
  "global_cost",
  "total_including_tips",
  "total_including_tips_minus_travel",
  "tip_bruto_and_bonus",
  "tip_bonus",
  "vacation",
  "vacation_for_paid",
  "sick",
  "sick_for_paid",
  "reserve",
  "cost_raw",
  "cost_raw_no_travel",
  "raw_cost_tip_min",
  "working_date",
  "employee_number",
  "origin_department_name",
  "role_name",
  "employee_last_name",
  "employee_first_name",
  "actual_working_days",
  "bank_details",
  "notes",
  "hours_125_hours_175",
  "hours_150_hours_200",
  "hours_shabat_holiday_175_200",
  "is_neto_salary",
  "total_allowance_from_credit",
  "total_allowance_from_cash",
  "hebDay",
  "employer_cost_and_salary",
  "employer_cost_factor_only",
  "irregular_hours",
  "no_offset_hours",
  "lack_of_standard_days",
  "base_travel_cost",
  "total_base_salary",
  "total_base_travel",
  "total_base_holiday",
  "total_base_health",
  "total_base_extra_shabat",
  "total_base_forced_work_stimulant",
];
export const salary_aggregates = [
  { field: "tips_allowance", aggregate: "sum" },
  { field: "tips_cash", aggregate: "sum" },
  { field: "tips_complete", aggregate: "sum" },
  { field: "tips_credit", aggregate: "sum" },
  { field: "tips_total", aggregate: "sum" },
  { field: "cost_raw", aggregate: "sum" },
  { field: "cost_raw_no_travel", aggregate: "sum" },
  { field: "raw_cost_tip_min", aggregate: "sum" },
  { field: "meal_cost", aggregate: "sum" },
  { field: "health_cost", aggregate: "sum" },
  { field: "holiday_cost", aggregate: "sum" },
  { field: "global_cost", aggregate: "sum" },
  { field: "hours_100", aggregate: "sum" },
  { field: "hours_125", aggregate: "sum" },
  { field: "hours_150", aggregate: "sum" },
  { field: "hours_150_shabat", aggregate: "sum" },
  { field: "hours_in_holiday", aggregate: "sum" },
  { field: "hours_in_holiday_hours_150_shabat", aggregate: "sum" },
  { field: "hours_175", aggregate: "sum" },
  { field: "hours_200", aggregate: "sum" },
  { field: "travel_cost", aggregate: "sum" },
  { field: "hours_total", aggregate: "sum" },
  { field: "irregular_hours", aggregate: "sum" },
  { field: "no_offset_hours", aggregate: "sum" },
  { field: "working_days", aggregate: "sum" },
  { field: "cash_tip_before_allowance", aggregate: "sum" },
  { field: "credit_tip_before_allowance", aggregate: "sum" },
  { field: "tips_total_before_allowance", aggregate: "sum" },
  { field: "allowance_percentage", aggregate: "sum" },
  { field: "bonus", aggregate: "sum" },
  { field: "neto_for_payment", aggregate: "sum" },
  { field: "cost_raw_tips_credit", aggregate: "sum" },
  { field: "social_hours", aggregate: "sum" },
  { field: "total_including_tips", aggregate: "sum" },
  { field: "total_including_tips_minus_travel", aggregate: "sum" },
  { field: "tip_bruto_and_bonus", aggregate: "sum" },
  { field: "tip_bonus", aggregate: "sum" },
  { field: "vacation", aggregate: "sum" },
  { field: "vacation_for_paid", aggregate: "sum" },
  { field: "sick", aggregate: "sum" },
  { field: "sick_for_paid", aggregate: "sum" },
  { field: "reserve", aggregate: "sum" },
  { field: "hours_week_100", aggregate: "sum" },
  { field: "hours_week_125", aggregate: "sum" },
  { field: "hours_week_150", aggregate: "sum" },
  { field: "hours_125_hours_175", aggregate: "sum" },
  { field: "hours_150_hours_200", aggregate: "sum" },
  { field: "hours_shabat_holiday_175_200", aggregate: "sum" },
  { field: "total_allowance_from_credit", aggregate: "sum" },
  { field: "total_allowance_from_cash", aggregate: "sum" },
  { field: "hours_150_without_night", aggregate: "sum" },
  { field: "employer_cost_and_salary", aggregate: "sum" },
  { field: "employer_cost_factor_only", aggregate: "sum" },
];
export const salary_excel_config = {
  cost_raw_tips_credit: (val, row) => Number(row.cost_raw + row.tips_credit),
  hours_125_hours_175: (val, row) => Number(row.hours_125 + row.hours_175),
  hours_150_hours_200: (val, row) => Number(row.hours_150 + row.hours_200),
  hours_shabat_holiday_175_200: (val, row) =>
    Number(
      row.hours_in_holiday_hours_150_shabat + row.hours_175 + row.hours_200
    ),
};

export const salary_columns = (isDepartment = false) => [
  {
    Header: () => (
      <>
        <BtnTooltip text={"תפקיד בו שובץ העובד בסידור עבודה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תפקיד
      </>
    ),
    accessor: "role_name",
    Cell: ({ value, row }) => {
      return renderIfNotDepartment({
        isDepartment,
        depth: row.depth,
        value,
        row,
      });
    },
    Footer: 'סה"כ',
  },
  {
    Header: "סטטוס",
    accessor: "enable",
    Cell: ({ value, row }) => {
      return value ? <span>{value === 1 ? "פעיל" : "לא פעיל"}</span> : "";
    },
  },
  {
    Header: "שם פרטי",
    accessor: "employee_first_name",
    Cell: ({ value, row }) => {
      return renderIfNotDepartment({
        isDepartment,
        depth: row.depth,
        value,
        row,
      });
    },
  },
  {
    Header: "שם משפחה",
    accessor: "employee_last_name",
    Cell: ({ value, row }) => {
      return renderIfNotDepartment({
        isDepartment,
        depth: row.depth,
        value,
        row,
      });
    },
  },
  {
    Header: "מחלקה",
    accessor: "origin_department_name",
  },
  {
    Header: "ת.ז / דרכון",
    accessor: "employee_identifier",
    Cell: ({ value, row }) => {
      return renderIfNotDepartment({
        isDepartment,
        depth: row.depth,
        value,
        row,
      });
    },
  },
  {
    Header: "מ. עובד",
    accessor: "employee_number",
    Cell: ({ value, row }) => {
      return renderIfNotDepartment({
        isDepartment,
        depth: row.depth,
        value,
        row,
      });
    },
    Footer: ({ rows }) =>
      rows.reduce((sum, row) => (row.depth ? sum : 1 + sum), 0),
  },
  {
    Header: "תאריך",
    accessor: "working_date",
    Cell: ({ value, row }) => {
      return renderIfNotDepartment({
        isDepartment,
        depth: row.depth,
        value,
        row,
      });
    },
  },
  {
    Header: "יום בשבוע",
    accessor: "hebDay",
    Cell: ({ value, row }) => {
      return renderIfNotDepartment({
        isDepartment,
        depth: row.depth,
        value,
        row,
      });
    },
  },

  {
    Header: "תאריך הקמה",
    accessor: "hebrew_create_date",
  },
  {
    Header: "מס' עובד במע' שכר ",
    accessor: "employee_accountancy_number",
  },

  {
    Header: "סוג עלות",
    accessor: "current_salary_type",
    // Cell: ({ row }) =>
    //   row.original.global_salary ? "גלובלי" : row.original.current_salary_type,
  },
  {
    Header: "תעריף",
    accessor: "salary",
    Cell: ({ row }) => {
      return (
        <div className="money money__footer">
          {row.depth === 1 || row.depth === 0
            ? !isNaN(Number(row.original.salary))
              ? `₪${thousandSeparator(twoZeroAfterDot(row.original.salary))}`
              : isObject(row.original.salary)
              ? Object.values(row.original.salary).join(", ")
              : row.original.salary
            : ""}
        </div>
      );
    },
  },
  {
    Header: "ימי עבודה בפועל",
    accessor: "actual_working_days",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "actual_working_days"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי עבודה",
    accessor: "working_days",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "working_days"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי חוסר תקן",
    accessor: "lack_of_standard_days",
    Cell: (props) => {
      if (props.row.original.lack_of_standard_days) {
        return (
          <span style={{ unicodeBidi: "plaintext" }}>
            {props.row.original.lack_of_standard_days}
          </span>
        );
      }
    },
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "lack_of_standard_days"),
        [self.rows]
      );

      return (
        <span style={{ unicodeBidi: "plaintext" }}>
          {twoZeroAfterDot(total)}
        </span>
      );
    },
  },
  ...(isDepartment
    ? [
        {
          Header: "100% א-ה",
          accessor: "hours_week_100",
          Cell: (props) =>
            `${twoZeroAfterDot(props.row.original.hours_week_100)}`,
          width: 1,
          Footer: (self) => {
            const total = useMemo(
              () => sumFooter(self.rows, "hours_week_100"),
              [self.rows]
            );

            return <>{twoZeroAfterDot(total)}</>;
          },
        },
        {
          Header: "125% א-ה",
          accessor: "hours_week_125",
          Cell: (props) =>
            `${twoZeroAfterDot(props.row.original.hours_week_125)}`,
          width: 1,
          Footer: (self) => {
            const total = useMemo(
              () => sumFooter(self.rows, "hours_week_125"),
              [self.rows]
            );

            return <>{twoZeroAfterDot(total)}</>;
          },
        },
        {
          Header: "150% א-ה",
          accessor: "hours_week_150",
          Cell: (props) =>
            `${twoZeroAfterDot(props.row.original.hours_week_150)}`,
          width: 1,
          Footer: (self) => {
            const total = useMemo(
              () => sumFooter(self.rows, "hours_week_150"),
              [self.rows]
            );

            return <>{twoZeroAfterDot(total)}</>;
          },
        },
      ]
    : []),
  {
    Header: "100%",
    accessor: "hours_100",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_100)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_100"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "125%",
    accessor: "hours_125",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_125)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_125"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "150%",
    accessor: "hours_150",
    Cell: (props) => {
      const h150 =
        props.row.original.hours_150 > 0 ? props.row.original.hours_150 : 0;
      return twoZeroAfterDot(h150);
    },
    width: 1,
    Footer: (self) => {
      const total = useMemo(() => {
        const h150 = sumFooter(self.rows, "hours_150");
        return h150 > 0 ? h150 : 0;
      }, [self.rows]);

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות 150 ללא לילה",
    accessor: "hours_150_without_night",
    Cell: (props) => (
      <div>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.hours_150_without_night)
        ) < 0
          ? 0
          : thousandSeparator(
              twoZeroAfterDot(props.row.original.hours_150_without_night)
            )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_150_without_night"),

        [self.rows]
      );

      return <div>{thousandSeparator(twoZeroAfterDot(total))}</div>;
    },
  },
  {
    Header: "שעות לילה",
    accessor: "hours_150_night",
    Cell: (props) => (
      <div>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.hours_150_night)
        ) < 0
          ? 0
          : thousandSeparator(
              twoZeroAfterDot(props.row.original.hours_150_night)
            )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_150_night"),

        [self.rows]
      );

      return <div>{thousandSeparator(twoZeroAfterDot(total))}</div>;
    },
  },

  {
    Header: "הפסקות",
    accessor: "break",
    Cell: (props) => (
      <div>
        {thousandSeparator(twoZeroAfterDot(props.row.original.break)) < 0
          ? 0
          : thousandSeparator(twoZeroAfterDot(props.row.original.break))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "break"),

        [self.rows]
      );

      return <div>{thousandSeparator(twoZeroAfterDot(total))}</div>;
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"העמודה מאחדת את שעות 125% + 175%"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        125% + 175%
      </>
    ),
    accessor: "hours_125_hours_175",
    Cell: (props) =>
      `${twoZeroAfterDot(
        props.row.original.hours_125 + props.row.original.hours_175
      )}`,
    width: 1,
    Footer: (self) => {
      const total = self.rows.reduce(
        (sum, row) =>
          row.depth
            ? sum
            : row.original.hours_125 + row.original.hours_175 + sum,
        0
      );
      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"העמודה מאחדת את שעות 150% + 200%"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        150% + 200%
      </>
    ),
    accessor: "hours_150_hours_200",
    Cell: (props) =>
      `${twoZeroAfterDot(
        props.row.original.hours_150 + props.row.original.hours_200
      )}`,
    width: 1,
    Footer: (self) => {
      const total = self.rows.reduce(
        (sum, row) =>
          row.depth
            ? sum
            : row.original.hours_150 + row.original.hours_200 + sum,
        0
      );
      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות שבת",
    accessor: "hours_150_shabat",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_150_shabat)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_150_shabat"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"העמודה מאחדת את שעות שבת + חג + 175% + 200%"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שעות שבת + חג + נוספות
      </>
    ),
    accessor: "hours_shabat_holiday_175_200",
    Cell: (props) =>
      `${twoZeroAfterDot(
        props.row.original.hours_in_holiday_hours_150_shabat +
          props.row.original.hours_175 +
          props.row.original.hours_200
      )}`,
    width: 1,
    Footer: (self) => {
      const total = self.rows.reduce(
        (sum, row) =>
          row.depth
            ? sum
            : row.original.hours_in_holiday_hours_150_shabat +
              row.original.hours_175 +
              row.original.hours_200 +
              sum,
        0
      );
      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות חג",
    accessor: "hours_in_holiday",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_in_holiday)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_in_holiday"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "שעות שבת + חג",
    accessor: "hours_in_holiday_hours_150_shabat",
    Cell: (props) =>
      `${twoZeroAfterDot(
        props.row.original.hours_in_holiday_hours_150_shabat
      )}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_in_holiday_hours_150_shabat"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "175%",
    accessor: "hours_175",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_175)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_175"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "200%",
    accessor: "hours_200",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_200)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_200"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={'סה"כ שעות 100% + שעות 100% בשבת'}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שעות סוציאליות
      </>
    ),
    accessor: "social_hours",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.social_hours)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "social_hours"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: 'סה"כ שעות',
    accessor: "hours_total",
    Cell: (props) => `${twoZeroAfterDot(props.row.original.hours_total)}`,
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hours_total"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={'העמודה מחשבת את תעריף שכר בסיס * סה"כ שעות עבודה בפועל'}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        סה״כ שכר בסיס
      </>
    ),
    accessor: "total_base_salary",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.total_base_salary)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_base_salary"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={'העמודה מחשבת את תעריף נסיעות * סה"כ שעות עבודה בפועל'}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        סה״כ תוספת נסיעות
      </>
    ),
    accessor: "total_base_travel",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.total_base_travel)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_base_travel"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={'העמודה מחשבת את תעריף חופשה * סה"כ שעות עבודה בפועל'}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        חופשה
      </>
    ),
    accessor: "total_base_holiday",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.total_base_holiday)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_base_holiday"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={'העמודה מחשבת את תעריף הבראה * סה"כ שעות עבודה בפועל'}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        הבראה
      </>
    ),
    accessor: "total_base_health",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.total_base_health)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_base_health"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={'הפרש בין סה"כ שעות עבודה בפועל ברמת כל יום לבין שעות תקן'}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        ש.חוסר תקן
      </>
    ),
    accessor: "irregular_hours",
    Cell: (props) => {
      if (props.row.original.irregular_hours) {
        return (
          <span style={{ unicodeBidi: "plaintext" }}>
            {twoZeroAfterDot(props.row.original.irregular_hours)}
          </span>
        );
      }
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"ש.חוסר תקן פחות 10"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        ש.חוסר לקיזוז
      </>
    ),
    accessor: "no_offset_hours",
    Cell: (props) => {
      if (props.row.original.no_offset_hours) {
        return (
          <span>{twoZeroAfterDot(props.row.original.no_offset_hours)}</span>
        );
      }
    },
  },
  {
    Header: "נסיעות חודשי",
    accessor: "travel_cost",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${twoZeroAfterDot(props.row.original.travel_cost)}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "travel_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "נסיעות",
    accessor: "base_travel_cost",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${twoZeroAfterDot(props.row.original.base_travel_cost)}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "base_travel_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },

  {
    Header: "בונוס",
    accessor: "bonus",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${twoZeroAfterDot(props.row.original.bonus)}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(() => sumFooter(self.rows, "bonus"), [self.rows]);

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "מפרעה",
    accessor: "on_account",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${twoZeroAfterDot(props.row.original.on_account)}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "on_account"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  // {
  //   Header: () => (
  //     <>
  //       <BtnTooltip text={"דמי הבראה"}>
  //         <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
  //       </BtnTooltip>
  //       דמי הבראה
  //     </>
  //   ),
  //   accessor: "health_cost",
  //   Cell: (props) => {
  //     const cost = props.row.original?.health_cost
  //       ? props.row.original.health_cost
  //       : 0;
  //     return (
  //       <div className="money">
  //         <span>&#8362;</span>
  //         {`${twoZeroAfterDot(cost)}`}
  //       </div>
  //     );
  //   },

  //   width: 1,
  //   Footer: (self) => {
  //     const total = useMemo(
  //       () => sumFooter(self.rows, "health_cost"),
  //       [self.rows]
  //     );

  //     return (
  //       <div className="money money__footer">
  //         <span>&#8362;</span>
  //         {thousandSeparator(twoZeroAfterDot(total))}
  //       </div>
  //     );
  //   },
  // },
  {
    Header: () => (
      <>
        <BtnTooltip text={"תעריף חופשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תעריף חופשה
      </>
    ),
    accessor: "holiday_cost",
    Cell: (props) => {
      const cost = props.row.original?.holiday_cost
        ? props.row.original.holiday_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "holiday_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },

  {
    Header: () => (
      <>
        <BtnTooltip text={"תעריף שעות נוספות גלובלית"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תעריף שעות נוספות גלובלית
      </>
    ),
    accessor: "global_cost",
    Cell: (props) => {
      const cost = props.row.original?.global_cost
        ? props.row.original.global_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "global_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"שכר בסיס * סך כל השעות"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תעריף שכר יסוד
      </>
    ),
    accessor: "base_salary",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(twoZeroAfterDot(props.row.original.base_salary))}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "base_salary"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "תעריף שעות נוספות בשבת",
    accessor: "extra_shabat_salary",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.extra_shabat_salary)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "extra_shabat_salary"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "תעריף בסיס  שעות נוספות בשבת",
    accessor: "total_base_extra_shabat",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.total_base_extra_shabat)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_base_extra_shabat"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "תעריף בסיס עבודה מאומצת",
    accessor: "total_base_forced_work_stimulant",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.total_base_forced_work_stimulant)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_base_forced_work_stimulant"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={" שכר עובד + נסיעות + בונוס + השלמה לטיפ מינימום"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שכר
      </>
    ),
    accessor: "cost_raw",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(twoZeroAfterDot(props.row.original.cost_raw))}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "cost_raw"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "עלות מעביד",
    accessor: "employer_cost_factor_only",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(
          twoZeroAfterDot(props.row.original.employer_cost_factor_only)
        )}`}
      </div>
    ),
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "employer_cost_factor_only"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "שכר כולל עלות מעביד",
    accessor: "employer_cost_and_salary",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(
          twoZeroAfterDot(props.row.original.employer_cost_and_salary)
        )}`}
      </div>
    ),
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "employer_cost_and_salary"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={" שכר עובד +  בונוס + השלמה לטיפ מינימום"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שכר ללא נסיעות
      </>
    ),
    accessor: "cost_raw_no_travel",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(
          twoZeroAfterDot(props.row.original.cost_raw_no_travel)
        )}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "cost_raw_no_travel"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },

  {
    Header: "סוג שכר",
    accessor: "is_neto_salary",
  },
  {
    Header: "טיפ מזומן לפני הפרשה",
    accessor: "cash_tip_before_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.cash_tip_before_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "cash_tip_before_allowance"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: "טיפ אשראי לפני הפרשה",
    accessor: "credit_tip_before_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.credit_tip_before_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "credit_tip_before_allowance"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ טיפ ברוטו',
    accessor: "tips_total_before_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.tips_total_before_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_total_before_allowance"),

        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ טיפ ברוטו לשעה',
    accessor: "hourly_tip_before_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.hourly_tip_before_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hourly_tip_before_allowance"),

        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ טיפ נטו לשעה',
    accessor: "hourly_tip_after_allowance",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.hourly_tip_after_allowance)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "hourly_tip_after_allowance"),

        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={
            '(שעות 100% × 30.61 ) + (שעות שבת × 30.61 × 1.5) + (סה"כ שעות × 3)'
          }
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        סה"כ טיפ כשכר
      </>
    ),
    accessor: "tip_bruto_and_bonus",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.tip_bruto_and_bonus)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tip_bruto_and_bonus"),

        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={'סה"כ טיפ ברוטו פחות סה"כ טיפ כשכר'}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        יתרת טיפ בונוס
      </>
    ),
    accessor: "tip_bonus",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${twoZeroAfterDot(props.row.original.tip_bonus)}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tip_bonus"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={"% הפרשה שמופרש מטיפ ברוטו לטובת תשלום תנאים סוציאליים"}
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        % הפרשה
      </>
    ),
    accessor: "allowance_percentage",
    Cell: (props) => (
      <div className="money">
        {Math.round(Number(props.row.original.allowance_percentage))}%
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total_allowance = sumFooter(self.rows, "tips_allowance");

      const total_tips_total_before_allowance = sumFooter(
        self.rows,
        "tips_total_before_allowance"
      );

      return (
        <div className="money money__footer">
          {Number(
            total_tips_total_before_allowance
              ? twoZeroAfterDot(
                  (100 * total_allowance) / total_tips_total_before_allowance
                )
              : 0
          )}
          %
        </div>
      );
    },
  },
  {
    Header: "הפרשה מאשראי",
    accessor: "total_allowance_from_credit",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.total_allowance_from_credit)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_allowance_from_credit"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "הפרשה ממזומן",
    accessor: "total_allowance_from_cash",
    Cell: (props) => (
      <div className="money">
        {" "}
        <span>&#8362;</span>
        {thousandSeparator(
          twoZeroAfterDot(props.row.original.total_allowance_from_cash)
        )}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_allowance_from_cash"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {twoZeroAfterDot(total)}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"סכום הפרשה מהטיפים למסעדה עבור תנאים סוציאליים"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        הפרשה למסעדה
      </>
    ),
    accessor: "tips_allowance",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_allowance)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_allowance"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"טיפ מזומן אחרי הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        טיפ מזומן
      </>
    ),
    accessor: "tips_cash",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_cash)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_cash"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "טיפ באשראי",
    accessor: "tips_credit",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_credit)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_credit"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={" טיפ מזומן אחרי הפרשה + טיפ אשראי אחרי הפרשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        סה"כ טיפ נטו
      </>
    ),
    accessor: "tips_total",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_total)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_total"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip
          text={
            "שעות 100 * טיפ מינימלי + שעות 125  * טיפ מינימלי + שעות 150 * טיפ מינימלי"
          }
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שכר לפי טיפ מינימום
      </>
    ),
    accessor: "raw_cost_tip_min",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(
          twoZeroAfterDot(props.row.original.raw_cost_tip_min)
        )}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "raw_cost_tip_min"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={'סה"כ השלמה לטיפ מינימום'}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        השלמה למינימום{" "}
      </>
    ),
    accessor: "tips_complete",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.tips_complete)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "tips_complete"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },

  {
    Header: () => (
      <>
        <BtnTooltip text={"טיפ ברוטו אחראי הפרשה + השלמה למינימום"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        נטו לתשלום
      </>
    ),
    accessor: "neto_for_payment",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.neto_for_payment)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "neto_for_payment"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"העמודה מחשבת את עמודת שכר + טיפ באשראי"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שכר + טיפ באשראי
      </>
    ),
    accessor: "cost_raw_tips_credit",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(
          twoZeroAfterDot(
            props.row.original.cost_raw + props.row.original.tips_credit
          )
        )}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = self.rows.reduce(
        (sum, row) =>
          row.depth
            ? sum
            : row.original.cost_raw + row.original.tips_credit + sum,
        0
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ דקות המתנה',
    accessor: "waiting_minutes",
    Cell: (props) => (
      <div className="money">
        {twoZeroAfterDot(props.row.original.waiting_minutes)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "waiting_minutes"),
        [self.rows]
      );

      return (
        <div className="money money__footer">{twoZeroAfterDot(total)}</div>
      );
    },
  },
  {
    Header: "תשלום המתנה",
    accessor: "waiting_salary",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {twoZeroAfterDot(props.row.original.waiting_salary)}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "waiting_salary"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "שכר כולל טיפים",
    accessor: "total_including_tips",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(
          twoZeroAfterDot(props.row.original.total_including_tips)
        )}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_including_tips"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },

  {
    Header: () => (
      <>
        <BtnTooltip
          text={
            "העמודה מחשבת את שכר העובד + טיפים - נסיעות (עבור עובד שמרוויח טיפים"
          }
        >
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        שכר כולל טיפים בניכוי נסיעות
      </>
    ),
    accessor: "total_including_tips_minus_travel",
    Cell: (props) => (
      <div className="money">
        <span>&#8362;</span>
        {`${thousandSeparator(
          twoZeroAfterDot(props.row.original.total_including_tips_minus_travel)
        )}`}
      </div>
    ),
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_including_tips_minus_travel"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "ימי חופש",
    accessor: "vacation",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "vacation"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי חופשה לקיזוז",
    accessor: "vacation_for_paid",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "vacation_for_paid"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי מחלה",
    accessor: "sick",
    width: 1,
    Footer: (self) => {
      const total = useMemo(() => sumFooter(self.rows, "sick"), [self.rows]);

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי מחלה לתשלום",
    accessor: "sick_for_paid",
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "sick_for_paid"),
        [self.rows]
      );

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "ימי מילואים",
    accessor: "reserve",
    width: 1,
    Footer: (self) => {
      const total = useMemo(() => sumFooter(self.rows, "reserve"), [self.rows]);

      return <>{twoZeroAfterDot(total)}</>;
    },
  },
  {
    Header: "פרטי בנק",
    accessor: "bank_details",
  },
  {
    Header: "מידע נוסף מכרטיס עובד",
    accessor: "notes",
  },
];

export const salary_position_columns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <i className="fa fa-minus" />
        ) : (
          <i className="fa fa-plus" />
        )}
      </span>
    ),

    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row

              paddingRight: `${row.depth * 2}rem`,
            },
          })}
        >
          {row.isExpanded ? (
            <i className="fa fa-minus" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </span>
      ) : null,
  },
  ...salary_columns(),
];

export const salary_by_identifier_columns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <i className="fa fa-minus" />
        ) : (
          <i className="fa fa-plus" />
        )}
      </span>
    ),
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row

              paddingRight: `${row.depth * 2}rem`,
            },
          })}
        >
          {row.isExpanded ? (
            <i className="fa fa-minus" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </span>
      ) : null,
  },
  {
    Header: "שם סניף",
    accessor: "employee_branch_name",
  },
  ...salary_columns(),
];

export const hidden_salery_deprtment = [""];

export const salary_department_columns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <i className="fa fa-minus" />
        ) : (
          <i className="fa fa-plus" />
        )}
      </span>
    ),

    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row

              paddingRight: `${row.depth * 2}rem`,
            },
          })}
        >
          {row.isExpanded ? (
            <i className="fa fa-minus" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </span>
      ) : null,
  },
  {
    Header: "מחלקה",
    accessor: "origin_department_name",
  },
  ...salary_columns(true).filter(
    (x) => x.accessor !== "origin_department_name"
  ),
];
