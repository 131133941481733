import {
  SIDEBAR_COLLAPSED,
  SET_DATE,
  SET_IS_MOBILE,
  SET_ACTIVE,
  EDIT_MODE_SCHEDULE_CHANGE,
  SET_ADMIN_ACTIVE,
  SET_IS_FRAME,
  DRAG_CELL_END,
  DRAG_CELL_START,
  SET_IS_TABLET,
  SET_TITLE,
  SET_CURRENT_VERSION_DATE,
  SET_SHOW_VERSION_MODAL,
  SET_WEEK,
} from "./types";
import { LOGOUT } from "../loginAction/types";
import { endOfWeek, startOfWeek } from "date-fns";
import { dateReqFormat, fixDate } from "../../../helpers";

const generateWeek = (date = new Date()) => {
  const daysCharacters = ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"];

  const daysNames = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];
  const list_of_dates = [];
  for (let i = 6; i >= 0; i--) {
    date.setDate(date.getDate() - date.getDay() + i);
    list_of_dates.push({
      date: fixDate(date, " DD/MM"),
      actual_date: dateReqFormat(date),
      day: daysCharacters[i],
      day_name: daysNames[i],
      day_num: i,
      isOpen: false,
    });
  }
  return list_of_dates;
};

//sideBarActive
export const sideBarCollapsed = (active) => async (dispatch) => {
  dispatch({ type: SIDEBAR_COLLAPSED, payload: active });
};

export const getBranchById = (branch_id) => async (dispatch, getState) => {
  try {
    // const response = await Axios.get(`/${branch_id}`,{
    //   params:{
    //     token:getState().auth.token
    //   }
    // })
    // console.log(response.data)
    // dispatch({type: SET_BRANCH, payload: response.data})
  } catch (e) {
    if (e.response)
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      }
    console.error(e);
  }
};

// set the date for schedule
export const setScheduleDate = (date, option) => async (dispatch) => {
  let week_start = startOfWeek(date);
  let week_end = endOfWeek(date);
  // await week_end.setDate(week_end.getDate() + 6);
  week_start = dateReqFormat(week_start);
  week_end = dateReqFormat(week_end);
  const week = await generateWeek(date);

  dispatch({ type: SET_DATE, payload: { week, week_start, week_end, option } });
};

export const setWeek = (week) => (dispatch) => {
  dispatch({ type: SET_WEEK, payload: week.week });
};
export const setActive = (active) => (dispatch) => {
  dispatch({ type: SET_ACTIVE, payload: active });
};
export const setTitle = (name) => (dispatch) => {
  dispatch({ type: SET_TITLE, payload: name });
};
export const setIsFrame = (isFrame) => (dispatch) => {
  // dispatch({ type: SET_IS_FRAME, payload: isFrame });
};
export const setAdminActive = (active) => (dispatch) => {
  dispatch({ type: SET_ADMIN_ACTIVE, payload: active });
};

export const setIsMobile = (isMobile) => (dispatch) => {
  dispatch({ type: SET_IS_MOBILE, payload: isMobile });
};

export const setIsTablet = (isTablet) => (dispatch) => {
  dispatch({ type: SET_IS_TABLET, payload: isTablet });
};

export const setScheduleHasChange = (has_changed) => (dispatch) => {
  dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: has_changed });
};

export const setDragCellStart = (cell_id) => (dispatch) => {
  dispatch({ type: DRAG_CELL_START, cell_id });
};

export const setDragCellEnd = () => (dispatch) => {
  dispatch({ type: DRAG_CELL_END });
};

export const setCurrentVersionDate =
  (currentVersionDate, showVersionModal) => (dispatch) => {
    dispatch({
      type: SET_CURRENT_VERSION_DATE,
      currentVersionDate,
      showVersionModal,
    });
  };

export const setShowVersionModal = (showVersionModal) => (dispatch) => {
  dispatch({ type: SET_SHOW_VERSION_MODAL, showVersionModal });
};

// export const sendEmail = async (id) => {
//   try {
//     const response = await Axios.get(`branchFees/${id}`, {
//       headers: { Authorization: `Bearer ${token}` },
//     });
//     setConfirm("", "קובץ נשלח בהצלחה");
//   } catch (e) {
//     if (e.response)
//       if (e.response.status === 401) {
//         logout();
//       } else {
//         setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
//       }
//   }
// };
