import StoreBase from "./StoreBase";
import { action, computed, observable, makeObservable } from "mobx";
import { isEmpty } from "lodash-es";
import { dateReqFormat, fixDate } from "../helpers";
import { addMonths } from "date-fns";

class DateRangePickerStore extends StoreBase {
  dateRange = {
    startDate: addMonths(new Date(), -1),
    endDate: addMonths(new Date(), 1),
    key: "selection",
    label: `${fixDate(addMonths(new Date(), -1))} ~ ${fixDate(
      addMonths(new Date(), 1)
    )}`,
  };

  constructor() {
    super();
    makeObservable(this, {
      // Observables
      dateRange: observable,
      // Computed
      dateRangeFormatted: computed,
      labelFormatted: computed,
      // Actions
      handleChange: action,
      initDefaultValues: action,
    });
  }

  get dateRangeFormatted() {
    if (isEmpty(this.dateRange)) {
      return null;
    }
    return [
      fixDate(this.dateRange.startDate, "YYYY-MM-DD 00:00:00"),
      fixDate(this.dateRange.endDate, "YYYY-MM-DD 23:59:59"),
    ];
  }

  get labelFormatted() {
    if (isEmpty(this.dateRange)) {
      return null;
    }
    return `${fixDate(this.dateRange.startDate)} ~ ${fixDate(
      this.dateRange.endDate
    )}`;
  }

  handleChange = (value) => {    
    if (value[0].label === null) {
      value[0].label = `${fixDate(value[0].startDate)} ~ ${fixDate(
        value[0].endDate
      )}`;
    }
    this.dateRange = value[0];
  };

  initDefaultValues = (defaultValue) => {
    if (defaultValue) {
      this.dateRange = defaultValue;
    }
  };
}

export default DateRangePickerStore;
