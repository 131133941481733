export const COLOR_SUBMIT="#00a2ea";
export const COLOR_ABORT="#db1e1e";
export const COLOR_CONFIRM="#47CC00";
// export const COLOR_WARNINGS="#f8ac07";
export const COLOR_WARNINGS="#FFCE00";
export const COLOR_GRAY_DARK="#EAEAEA";
export const COLOR_GRAY_LIGHT="#F7F7F7";
export const COLOR_WHITE="#fff";
export const COLOR_RESTIGO_PRIMARY="#00a2ea";
export const COLOR_RESTIGO_SECONDARY="#22b7f7c5";

