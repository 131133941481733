import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";

// ======== ICONS ========
import logo from "../../images/Logo_Resigo_pat.png";

// ======== CONSTANTS ========
import { getClassDirection } from "../../helpers";
import {
  BY_PERMANENT_PASS,
  TAB_PATH_BY_PERMANENT_PASS,
  TAB_OPTIONS,
  BY_OTP,
} from "./const";

// ======== COMPONENTS ========
import Grid from "@mui/material/Grid";
import MuiTabs from "../../components/menu/MuiTabs";
import PermanentPassword from "./permanent-password/PermanentPassword";
import { auto } from "@popperjs/core";
import OtpForm from "./one-time-password/OtpForm";

const Login = (props) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========
  const [tab, setTab] = useState({
    id: BY_PERMANENT_PASS,
    label: TAB_OPTIONS[BY_PERMANENT_PASS].label,
    path: TAB_PATH_BY_PERMANENT_PASS,
  });

  const locale = useSelector((state) => state.i18n.locale);

  // ======== HANDLERS ========

  const tabsOnChangeHandler = useCallback(
    (tab) => {
      setTab(tab);
    },
    [tab]
  );

  const TabContent = ({ tabId }) => {
    switch (tabId) {
      case BY_PERMANENT_PASS:
        return <PermanentPassword />;
      case BY_OTP:
        return <OtpForm />;
      default:
        return null;
    }
  };

  return (
    <div className="login">
      <div
        className={`login__card ${getClassDirection(
          locale
        )} flex-column-center`}
      >
        <img
          style={{ width: "50%", marginBottom: "20px" }}
          src={logo}
          alt="restigo logo"
        />

        <MuiTabs
          style={{ direction: "rtl", marginBottom: "40px" }}
          isMobile={isMobile}
          defaultValue={tab?.id}
          options={TAB_OPTIONS}
          onChange={tabsOnChangeHandler}
        />
        <div style={{ maxWidth: "300px", width: "100%" }}>
          <TabContent tabId={tab?.id} />
        </div>
      </div>
    </div>
  );
};

export default Login;
