import { IconButton, Popover } from "@mui/material";
import Axios from "axios";
import { isEmpty } from "lodash";
import { isFunction, mapKeys, omit } from "lodash-es";
import React, { useEffect, useMemo, useState } from "react";
import { BiColumns } from "react-icons/bi";
import { connect } from "react-redux";
// import Popover from "react-popover";
import { I18n } from "react-redux-i18n";
import { setError } from "../../redux/actions/errorAction";
import { logout } from "../../redux/actions/loginAction";
import SearchBar from "../inputs/SearchBar";
import Model, { Header } from "../Model";
import BtnTooltip from "../tooltips/BtnTooltip";

const HideColumnsOptions = ({
  allColumns,
  hiddenColumns = [],
  setModalHiddenColumns,
  containerRef = {},
  branch_id,
  serverSaveTitle = null,
  token,
  logout,
  setError,
  defaultVisibleColumns = {},
  translation_endpoint = "",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [savedColumns, setSavedColumns] = useState(defaultVisibleColumns);
  const [columns, setColumns] = useState(allColumns);

  useEffect(() => {
    setSavedColumns(defaultVisibleColumns);
  }, [defaultVisibleColumns]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (serverSaveTitle) {
      saveViewOptions(
        hiddenColumns.map((x) => ({
          title: x,
          active: savedColumns[x] ? 1 : 0,
        }))
      );
    }
  };

  const saveViewOptions = useMemo(
    () => async (columns) => {
      try {
        if (!branch_id) return;
        const res = await Axios.post(
          `branch/${branch_id}/reportsOptions`,
          {
            report_title: serverSaveTitle,
            columns,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } catch (e) {
        console.error(e);
        if (e.response.status === 401) {
          logout();
        } else if (e.response.status === 500) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת");
        }
      }
    },
    [branch_id, serverSaveTitle]
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const hiddenColumns_obj = useMemo(
    () => mapKeys(hiddenColumns),
    [hiddenColumns]
  );

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollLeft = 1000000;
      }
    }, 10);
  });

  return (
    <>
      <BtnTooltip placement="top" text="הסתר/הצג עמודות">
        <IconButton style={{ color: "black" }} onClick={handleClick}>
          {/* <FaCog /> */}
          <BiColumns size="1.3em" />
        </IconButton>
      </BtnTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Model style={{ width: "25rem" }}>
          <Header style={{ height: "3rem", paddingTop: 0 }}>
            <h3>תצוגת עמודות</h3>
          </Header>
          <SearchBar
            className="search-container__btn-transparent"
            style={{ width: "100%", marginBottom: "1rem" }}
            isCollapse={false}
            handleSearchChange={(searchValue) => {
              if (!searchValue) {
                return setColumns(allColumns);
              }
              const filtered = columns.filter((column) => {
                if (hiddenColumns_obj[column.id]) {
                  return isFunction(column.Header)
                    ? `${I18n.t(
                        `excel${
                          translation_endpoint
                            ? `.${translation_endpoint}.`
                            : "."
                        }${column.id}`.includes(searchValue)
                      )}`
                    : column.Header.includes(searchValue);
                }
                return false;
              });
              setColumns(filtered);
            }}
          />
          <div className="checkbox niceScrollBar">
            {columns.length ? (
              columns.map((column) => {
                if (hiddenColumns_obj[column.id]) {
                  return (
                    <div key={column.id}>
                      <label className="checkbox--container">
                        {isFunction(column.Header) && !column?.cleanHeader
                          ? `${I18n.t(
                              `excel${
                                translation_endpoint
                                  ? `.${translation_endpoint}.`
                                  : "."
                              }${column.id}`
                            )}`
                          : column?.cleanHeader
                          ? column.cleanHeader
                          : column.Header}
                        <input
                          type="checkbox"
                          checked={savedColumns[column.id]}
                          onClick={(e) => {
                            setTimeout(() => {
                              if (containerRef.current)
                                containerRef.current.scrollLeft = 1000000;
                            }, 10);
                          }}
                          onChange={(e) => {
                            if (savedColumns[column.id])
                              setSavedColumns(omit(savedColumns, column.id));
                            else
                              setSavedColumns({
                                ...savedColumns,
                                [column.id]: column.id,
                              });

                            const unchecked = [];
                            if (!e.target.checked) unchecked.push(column.id);
                            setModalHiddenColumns(
                              Object.values({
                                ...savedColumns,
                                [column.id]: column.id,
                              }),
                              unchecked
                            );

                            column.toggleHidden(savedColumns[column.id]);
                          }}
                        />
                        <span className="checkbox--checkmark"></span>
                      </label>
                    </div>
                  );
                } else return "";
              })
            ) : (
              <div className="u-center-text">לא נמצאו תוצאות</div>
            )}
          </div>
        </Model>
      </Popover>
    </>
  );
};
const mapStateToProps = (state) => {
  const { token } = state.auth;
  const { id } = state.branches.selected_branch;

  return { token, branch_id: id };
};
export default connect(mapStateToProps, {
  logout,
  setError,
})(HideColumnsOptions);
