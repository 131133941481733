export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES";
export const SAVE_EVENT = "SAVE_EVENT";
export const CHANGE_ROLE_SEQUENCE = "CHANGE_ROLE_SEQUENCE";
export const FETCH_EVENTS = "FETCH_EVENTS";
export const PUBLISH_SCHEDULE = "PUBLISH_SCHEDULE";
export const SET_HOLIDAYS = "SET_HOLIDAYS";
export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_ROLES_MAP = "FETCH_ROLES_MAP";
export const ADD_SHIFT = "ADD_SHIFT";
export const DUPLICATE_CELL = "DUPLICATE_CELL";
export const DELETE_EVENT = "DELETE_EVENT";
export const FETCH_SUBMISSIONS = "FETCH_SUBMISSIONS";
export const ADD_SUBMISSIONS = "ADD_SUBMISSIONS";
export const REMOVE_SHIFT = "REMOVE_SHIFT";
export const ADD_NOTES_SCHEDULE = "ADD_NOTES_SCHEDULE";
// export const SET_IS_PINNED_SCHEDULE = "SET_IS_PINNED_SCHEDULE";
export const SAVE_PLANNED_HOURS = "SAVE_PLANNED_HOURS";
export const SWITCH_EVENTS = "SWITCH_EVENTS";
export const ADD_NOTE_TO_EVENT = "ADD_NOTE_TO_EVENT";
export const REMOVE_NOT_PLACED = "REMOVE_NOT_PLACED";
export const SAVE_NOT_PLACED = "SAVE_NOT_PLACED";
export const REMOVE_EVENT_MATRIX = "REMOVE_EVENT_MATRIX";
export const EDIT_LAST_SUBMISSION = "EDIT_LAST_SUBMISSION";
export const INIT_SCHEDULE = "INIT_SCHEDULE";
export const SET_SCHEDULE_TIP = "SET_SCHEDULE_TIP";
export const REMOVE_SCHEDULE_TIP = "REMOVE_SCHEDULE_TIP";
export const ADD_PERMANENT_TO_ROLE = "ADD_PERMANENT_TO_ROLE";
export const SAVE_SHIFT = "SAVE_SHIFT";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const EDIT_VACATION_STATUS = "EDIT_VACATION_STATUS";
export const SET_SPECIAL_REQUEST_COUNT = "SET_SPECIAL_REQUEST_COUNT";

export const SET_EXISTING_ROLES = "SET_EXISTING_ROLES";
export const SET_NEW_EVENTS = "SET_NEW_EVENTS";
