import { FormControlLabel, Switch } from "@mui/material";
import { Controller } from "react-hook-form";
import { forwardRef } from "react";

const FormSwitch = forwardRef(function (
  {
    control,
    name,
    label,
    marginRight = "1rem",
    whiteSpace = "normal",
    disabled,
    defaultValue,
    ...props
  },
  toolTipRef
) {
  return (
    <Controller
      {...{
        control,
        name,
        defaultValue,
        render: ({ field: { name, onBlur, onChange, ref, value } }) => {
          return (
            <FormControlLabel
              style={{ marginRight: marginRight, whiteSpace: whiteSpace }}
              ref={toolTipRef}
              {...props}
              control={
                <Switch
                  value={!!value}
                  {...{
                    checked: !!value,
                    name,
                    onBlur,
                    inputRef: ref,
                    onSelect: onChange,
                    onChange,
                    disabled,
                    defaultValue: false,
                  }}
                />
              }
              label={label}
            />
          );
        },
      }}
    />
  );
});

export default FormSwitch;
