import { Chip, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FormTextField from '../Forms/inputs/FormTextField';


const  EmailTagInput=({label,emails,setEmails})=> {
    const [inputValue, setInputValue] = useState('');
    const [inputWidth, setInputWidth] = useState(300);


    const handleKeyDown = (event) => {
      if (['Enter', 'Tab', ','].includes(event.key)) {
        event.preventDefault();
        const newEmail = inputValue.trim();
        if (newEmail && !emails.includes(newEmail)) {
          setEmails([...emails, newEmail]);
          setInputWidth(inputWidth + (newEmail.length + 1) * 6);
        }
        setInputValue('');
      }
    };
  
    const handleDelete = (email) => {
      setEmails(emails.filter(e => e !== email));
      setInputWidth(inputWidth - (email.length + 1) * 6);

    };
    const handleBlur = (event) => {
        const newEmail = event.target.value.trim();
        if (newEmail && !emails.includes(newEmail)) {
          setEmails([...emails, newEmail]);
          setInputWidth(inputWidth + (newEmail.length + 1) * 8);
        }
        setInputValue('');
      };
    const handleChange = (event) => {
      setInputValue(event.target.value);
    };
  
    const tags = emails.map(email => (
      <Chip key={email} label={email} onDelete={() => handleDelete(email)} />
    ));
  
    return (
      <TextField
        variant="outlined"
        label={label}
        placeholder="מיילים לשליחה"
        value={inputValue}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        InputProps={{ startAdornment: tags }}
        style={{ width: inputWidth }}

      />
    );
}
export default EmailTagInput