import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { newPosWorkersURI } from "../../services/constants";

const initialState = {
  workers: {
    isLoading: false,
    thunkStatus: "idle",
    data: [],
    pagination: {
      current_page: 0,
      per_page: 5,
      total: 1,
    },
  },
  error: null,
};

export const newWorkersSlice = createSlice({
  name: "newPOSWorkers",
  initialState,
  reducers: {
    setWorkersPage: (state, action) => {
      const { value, name } = action.payload;
      state.workers.pagination[[name]] = value;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWorkersCreatedInPos.pending, (state, action) => {
        state.workers.isLoading = true;
        state.workers.thunkStatus = "loading";
      })
      .addCase(fetchWorkersCreatedInPos.fulfilled, (state, action) => {
        if (!action.payload?.data) {
          state.workers.isLoading = false;
          state.workers.thunkStatus = "failed";
          return;
        }
        state.workers.thunkStatus = "succeeded";
        const { total, current_page, per_page, data } = action.payload;
        state.workers.data = data;
        state.workers.pagination = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
        state.workers.isLoading = false;
      })
      .addCase(fetchWorkersCreatedInPos.rejected, (state, action) => {
        state.workers.isLoading = false;
        state.workers.thunkStatus = "failed";
        state.error = action.payload;
      });
  },
});

export const fetchWorkersCreatedInPos = createAsyncThunk(
  "newPOSWorkers/fetchWorkersCreatedInPos",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    const { current_page, per_page } =
      getState().newPOSWorkers.workers.pagination;

    const params = {
      page: current_page + 1,
      per_page,
    };

    try {
      const res = await Axios.get(newPosWorkersURI, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const selectPagination = (state) =>
  state.newPOSWorkers.workers.pagination;

export const selectLoading = (state) => state.newPOSWorkers.workers.isLoading;

export const selectNewWorkers = (state) => state.newPOSWorkers.workers.data;

export default newWorkersSlice.reducer;

export const { setWorkersPage } = newWorkersSlice.actions;
