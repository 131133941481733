import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import DataAnlyzeBullets from "./DataAnalyzeBullets";
import { getDataAnalyze } from "../../redux/reducers/DataAnalyzeSlice";
import DataAnalyzeFilters from "./DataAnalyzeFilters";
import DataAnalyzeTable from "./DataAnalyzeTable";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const DataAnlyzeDashboard = (props) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Grid container>
      <Grid item container xs={12}>
        <DataAnalyzeFilters />
      </Grid>
      <Grid item container xs={12}>
        <DataAnlyzeBullets />
      </Grid>
      <Grid item container xs={12}>
        <DataAnalyzeTable />
      </Grid>
    </Grid>
  );
};

export default DataAnlyzeDashboard;
