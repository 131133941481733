import { useMemo } from "react";
import Button from "../../components/buttons/Button";
import {
  PriceCell,
  PriceFooter,
} from "../../components/tables/components/PriceFooter";
import {
  fixPercentage,
  fixPrice,
  fixPricePrecent,
  getHebDay,
  numberFormat,
  thousandSeparator,
} from "../../helpers";
import { sumFooter } from "../../components/tables/helpers";
import { TYPE_SHEKEL } from "./modals/CategoryGoalsConsts";
import { HEBREW_DAYS, PRECENT_SIGN, SHEKEL_SIGN } from "../../const";
import { FaChevronDown, FaChevronUp, FaTag } from "react-icons/fa";
import BtnTooltip from "../../components/tooltips/BtnTooltip";
import { COLOR_SUBMIT } from "../../const/colors";
import OnClickTooltip from "../../components/OnClickTooltip";
import { IconButton, Typography } from "@mui/material";
import { DOCUMENT_TYPE_HEBREW } from "../orders/manageOrders/constants";
import moment from "moment";

//Food cost URLS
export const BASE_URL = "v2/expenseSummary";
export const GET_OPEN_ORDERS = "getOpenOrders";
export const GET_PULLS_TRANSFERS = "getPullsAndTransfers";
export const GET_REFUND_ORDERS = "getRefundOrders";
export const GET_ITEMS_BY_CATEGORY = "getItemsByCategory";
export const GET_ITEMS_BY_SUPPLIER = "getItemsBySupplier";
export const GET_ITEM_ORDER_DETAILS = "getItemsOrderDetails";
export const CATEGORIES_URL = "v3/categories";
export const GET_SUPPLIERS = "getSuppliers";
export const GET_CATEGORY_GOALS = "getCategoryGoals";
export const UPDATE_CATEGORY_GOAL = "updateOrCreateCategoryGoal";
export const DELETE_CATEGORY_GOAL = "deleteCategoryGoal";
export const GET_ORDERS_BY_DATE = "getOrdersByDate";
export const GET_ITEMS_NO_FILTER = "getItemsNoFilter";

//Operational URLS

export const getOperationalTablesData = "getOperationalTablesData";
export const getOperationalExpenses = "operational";
export const getOperationalPieData = "getOperationalPieData";
export const GET_FEE_EXPENSE_DETAILS = "getFeeExpenseDetails";
export const GET_CONST_EXPENSE_DETAILS = "getConstExpenseDetails";
export const GET_OPERATIONAL_ORDERS_BY_CATEGORY = "getOrderItemsByCategory";

export const CONST_EXPENSE = 2;
export const FEE_EXPENSE = 3;
//expense types

export const SUPPLIER_TABLE = 0;
export const EXPENSE_TYPE = {
  OPERTAIONAL: 2,
};

//table types

export const CASE_BY_CATEGORY = 1;
export const CASE_BY_SUPPLIER = 0;

export const TAB_FC = 0;
export const TAB_OE = 1;
export const TAB_AGE = 2;
export const TAB_FE = 3;

export const OPERATIONAL_EXPENSES = 2;
export const MANAGMENT_EXPENSES = 3;
export const FUNDING_EXPENSES = 4;

const TYPE_TRANSFER = 3;
const TYPE_PULL = 4;
const PULLS_TRANSFERS_MAP = {
  [TYPE_TRANSFER]: "העברה",
  [TYPE_PULL]: "משיכה",
};
export const TAB_PATH_FC = "foodCost";
export const TAB_PATH_OE = "operationalExpenses";
export const TAB_PATH_AGE = "שdministrativeAndGeneralExpenses";
export const TAB_PATH_FE = "financialExpenses";
export const TAB_OPTIONS = [
  {
    id: TAB_FC,
    privilege_id: 5,
    label: "פוד קוסט",
    path: TAB_PATH_FC,
  },
  {
    id: TAB_OE,
    privilege_id: 7,

    label: "הוצאות תפעול",
    path: TAB_PATH_OE,
  },
  {
    id: TAB_AGE,
    privilege_id: 8,

    label: "הוצאות הנהלה וכלליות",
    path: TAB_PATH_AGE,
  },
  {
    id: TAB_FE,
    privilege_id: 9,

    label: "הוצאות מימון",
    path: TAB_PATH_FE,
  },
];
export const openOrdersColumns = [
  {
    Header: "סניף",
    accessor: "branch_name",
    Footer: () => "סה״כ",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "מס' הזמנה",
    accessor: "id",
    Cell: ({ value, row, setSelectedOrder }) => {
      return (
        <Button
          className="btn--hyperlink"
          onClick={() => setSelectedOrder({ new_order_id: value })}
          textValue={value}
        />
      );
    },
  },
  {
    Header: "תאריך שליחה",
    accessor: "sent_at",
    Footer: ({ rows }) => {
      return rows.length;
    },
  },
  {
    Header: "סכום",
    accessor: "total_without_vat",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
];
export const ordersByDateColumns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <div className="test">
          <IconButton
            {...row.getToggleRowExpandedProps({})}
            aria-label="expand row"
            size="small"
            style={{ color: "inherit" }}
            className={row.isExpanded ? "subRowButton" : ""}
          >
            {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </IconButton>
        </div>
      ) : null,
    Footer: () => "סה״כ",
  },
  {
    Header: "תאריך",
    accessor: "date",
  },
  {
    Header: "יום בשבוע",
    accessor: "week_day",
    // Cell: ({ value }) => {
    //   return getHebDay(value);
    // },
  },
  {
    Header: "סכום",
    accessor: "total_without_vat",
    Cell: ({ value, row, setSelectedOrder }) => {
      return <span>{fixPrice(value, 1)}</span>;
    },
    Footer: PriceFooter,
  },
  {
    Header: "אחוז מהכנסה מצטברת",
    accessor: "income_percent",
    Cell: ({ value, row }) => {
      return <span>{value > 0 ? fixPercentage(value, 1) : 0}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total_without_vat");
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
];
export const mobileOrdersByDateColumns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <div className="test">
          <IconButton
            {...row.getToggleRowExpandedProps({})}
            aria-label="expand row"
            size="small"
            style={{ color: "inherit" }}
            className={row.isExpanded ? "subRowButton" : ""}
          >
            {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </IconButton>
        </div>
      ) : null,
    Footer: () => "סה״כ",
  },
  {
    Header: "תאריך",
    accessor: "date",
  },
  {
    Header: "סכום",
    accessor: "total_without_vat",
    Cell: ({ value, row, setSelectedOrder }) => {
      return <span>{fixPrice(value, 1)}</span>;
    },
    Footer: PriceFooter,
  },
  {
    Header: "אחוז מהכנסה",
    accessor: "income_percent",
    Cell: ({ value, row }) => {
      return <span>{fixPercentage(value, 1)}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total_without_vat");
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
];

export const ordersByDateSubColumns = [
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "מספר מסמך",
    accessor: "document_number",
    Cell: ({ value, row, setSelectedOrder }) => {
      return (
        <Button
          className="btn--hyperlink"
          onClick={() =>
            setSelectedOrder({
              ...(row.original.order_id
                ? { new_received_order_id: row.original.order_id }
                : { new_order_id: row.original.new_order_id }),
            })
          }
          textValue={value}
        />
      );
    },
  },
  {
    Header: "סוג מסמך",
    accessor: "document_type",
    Cell: ({ value }) => {
      return DOCUMENT_TYPE_HEBREW[value];
    },
  },
  {
    Header: "תאריך",
    accessor: "date",
  },
  {
    Header: "יום בשבוע",
    accessor: "week_day",
  },
  {
    Header: "סכום ללא מע״מ",
    accessor: "total_without_vat",
    Cell: ({ row, value }) => {
      return <span className="is-number">{fixPrice(value)}</span>;
    },
  },
];
export const mobileOrdersByDateSubColumns = [
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "מספר מסמך",
    accessor: "document_number",
    Cell: ({ value, row, setSelectedOrder }) => {
      return (
        <Button
          className="btn--hyperlink"
          onClick={() =>
            setSelectedOrder({
              ...(row.original.order_id
                ? { new_received_order_id: row.original.order_id }
                : { new_order_id: row.original.new_order_id }),
            })
          }
          textValue={value}
        />
      );
    },
  },
  {
    Header: "סכום ללא מע״מ",
    accessor: "total_without_vat",
    Cell: PriceCell,
  },
];
export const constExpenseColumns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <div className="test">
          <IconButton
            {...row.getToggleRowExpandedProps({})}
            aria-label="expand row"
            size="small"
            style={{ color: "inherit" }}
            className={row.isExpanded ? "subRowButton" : ""}
          >
            {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </IconButton>
        </div>
      ) : null,
    Footer: () => "סה״כ",
  },
  {
    Header: "שם הוצאה",
    accessor: "name",
    Footer: ({ rows }) => {
      return rows.length;
    },
  },
  {
    Header: "סכום",
    accessor: "total",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "אחוז מהכנסה",
    accessor: "income_percent",
    Cell: ({ value, row }) => {
      return <span>{fixPercentage(value, 1)}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total");
      const total_income_percent = numberFormat(
        sumFooter(rows, "income_percent"),
        0,
        1,
        "decimal"
      );
      return total_fees > 0 ? total_income_percent + "%" : 0;
    },
  },
];
export const constExpenseSubColumns = [
  {
    Header: "תאריך",
    accessor: "date",
    Footer: () => "סה״כ",
  },
  {
    Header: "יום בשבוע",
    accessor: "day",
    Cell: ({ row }) => {
      const date = new Date(row.original.date);

      const dayIndex = date.getDay();

      return HEBREW_DAYS[dayIndex];
    },
    Footer: ({ rows }) => {
      return rows.length;
    },
  },
  {
    Header: "שם הוצאה",
    accessor: "name",
  },
  {
    Header: "סכום",
    accessor: "total",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
];
export const pullsAndTransferColumns = [
  {
    Header: "תאריך הזנה",
    accessor: "date",
  },
  {
    Header: "מספר העברה",
    accessor: "id",
    Cell: ({ value, setInventoryDetail, setOpenInventoryDetail }) => {
      return (
        <Button
          className="btn--hyperlink"
          onClick={() => {
            setInventoryDetail(value);
            setOpenInventoryDetail(true);
          }}
          textValue={value}
        />
      );
    },
  },
  {
    Header: "סוג",
    accessor: "type_string",
  },
  {
    Header: "מסניף",
    accessor: "branch_name",
  },
  {
    Header: "לסניף",
    accessor: "to_branch_name",
  },
  {
    Header: "סכום",
    accessor: "adjusted_worth",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "עודכן ע״י",
    accessor: "updater",
    Cell: ({ value, row }) => {
      const { updated_at } = row.original;
      const updatedAt = moment(updated_at).format("DD-MM-YYYY, HH:mm");
      return <span>{`${value.full_name} ${updatedAt}`}</span>;
    },
  },
];
export const pullTransferDetailsColumns = [
  {
    Header: "מוצר",
    accessor: "item_name",
  },
  {
    Header: "מק״ט",
    accessor: "item",
    Cell: ({ value }) => {
      return value.part_number;
    },
  },
  {
    Header: "ברקוד יצרן",
    accessor: "manufacturer_number",
  },
  {
    Header: "קטגוריה",
    accessor: "category_name",
    filter: "fuzzyText",
  },
  {
    Header: "מחיר",
    Cell: (props) => {
      if (props.row.original.package_amount > 0) {
        return fixPrice(
          props.row.original.worth / props.row.original.package_amount,
          2
        );
      } else if (props.row.original.unit_amount > 0) {
        return fixPrice(
          props.row.original.worth / props.row.original.unit_amount,
          2
        );
      } else if (props.row.original.weight_amount > 0) {
        return fixPrice(
          props.row.original.worth / props.row.original.weight_amount,
          2
        );
      }
    },
  },
  {
    Header: "כמות",
    accessor: "amounts",
    noSort: true,
    Cell: (props) => {
      const { row } = props;
      const item = row.original;
      return (
        <>
          {item.package_amount > 0 && (
            <Typography>{`${item.package_amount} ${item.package_scale}`}</Typography>
          )}
          {item.unit_amount > 0 && (
            <Typography>{`${item.unit_amount} ${item.unit_scale}`}</Typography>
          )}
          {item.weight_amount > 0 && (
            <Typography>{`${item.weight_amount} ${item.weight_scale}`}</Typography>
          )}
        </>
      );
    },
  },
  {
    Header: "שווי",
    accessor: "worth",
    Cell: (props) => {
      const { worth } = props.row.original;
      return (
        <span className="is-number">
          &#8362;
          {thousandSeparator(worth.toFixed(2))}
        </span>
      );
    },
  },
];
export const refundOrdersColumns = [
  {
    Header: "סניף",
    accessor: "branch_name",
    Footer: () => "סה״כ",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "מס' הזמנה",
    accessor: "id",
  },
  {
    Header: "תאריך שליחה",
    accessor: "created_at",
    Footer: ({ rows }) => {
      return rows.length;
    },
  },
  {
    Header: "סכום זיכוי",
    accessor: "refund_price",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
];
export const ordersByCategoryColumns = [
  {
    Header: "שם קטגוריה",
    accessor: "category_name",
    Cell: ({ value, row, openCategoryItemsTable }) => {
      const { category_id } = row.original;
      return (
        <Button
          style={{ fontSize: "1.5rem" }}
          className="btn--hyperlink"
          onClick={() => {
            openCategoryItemsTable({
              order_type: "category",
              id: category_id,
            });
          }}
          textValue={value}
        />
      );
    },
    Footer: () => "סה״כ",
  },
  {
    Header: "רכש",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  // {
  //   Header: "צפי סוף תקופה",
  //   accessor: "expected_end_period",
  //   Cell: ({ value, row }) => {
  //     return <span>{fixPrice(value)}</span>;
  //   },
  // },
  {
    Header: "מצטבר שנה שעברה",
    accessor: "cumulative_last_year",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "cumulative_last_year"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "אחוז הכנסה",
    accessor: "income_precent",
    Cell: ({ value, row }) => {
      return <span>{value > 0 ? fixPercentage(value, 1) : 0}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total_without_vat");
      if (current_incomes <= 0) {
        return 0;
      }
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
  {
    Header: "אחוז הכנסה שנה שעברה",
    accessor: "income_precent_last_year",
    Cell: ({ value, row }) => {
      return <span>{value > 0 ? fixPercentage(value, 1) : 0}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "cumulative_last_year");
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
  {
    Header: "יעד",
    accessor: "goal",
    Cell: ({ value, row }) => {
      if (value === 0) {
        return <span>{value}</span>;
      }
      return (
        <span>
          {row.original.goal_type === TYPE_SHEKEL
            ? `${fixPrice(value)}`
            : `${fixPricePrecent(value)}`}
        </span>
      );
    },
  },
];

export const operationalCategoriesTable = [
  {
    Header: "שם קטגוריה",
    accessor: "category_name",
    Cell: ({
      value,
      row,
      openFeeOrConstExpenses,
      openOperationalOrders,
      setIsConstExpenses,
    }) => {
      const { id } = row.original;
      return (
        <Button
          className="btn--hyperlink"
          onClick={() => {
            if (id === CONST_EXPENSE) {
              openFeeOrConstExpenses(CONST_EXPENSE);
              setIsConstExpenses(true);
            } else {
              openOperationalOrders();
              setIsConstExpenses(false);
            }
          }}
          textValue={value}
        />
      );
    },
    Footer: () => "סה״כ",
  },
  {
    Header: "רכש",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money"> {fixPrice(total)}</div>;
    },
  },
  {
    Header: "אחוז הכנסה",
    accessor: "income_precent",
    Cell: ({ value, row }) => {
      return <span>{fixPercentage(value, 1)}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total_without_vat");
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
];
export const mobileOrdersByCategoryColumns = [
  {
    Header: "שם קטגוריה",
    accessor: "category_name",
    Cell: ({ value, row, openCategoryItemsTable }) => {
      const { category_id } = row.original;
      return (
        <Button
          className="btn--hyperlink"
          onClick={() => {
            openCategoryItemsTable({ order_type: "category", id: category_id });
          }}
          textValue={value}
        />
      );
    },
    Footer: () => "סה״כ",
  },
  {
    Header: "רכש",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ paddingLeft: "10px" }}>{fixPrice(value, 2)}</span>
          <OnClickTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <span
                  style={{ fontWeight: "bold" }}
                >{`צפי סוף תקופה : ${row.original.expected_end_period}`}</span> */}
                <span
                  style={{ fontWeight: "bold" }}
                >{`מצטבר שנה שעברה: ${fixPrice(
                  row.original.cumulative_last_year,
                  2
                )}`}</span>
              </div>
            }
            IconComponent={<FaTag size={10} />}
          />
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "אחוז הכנסה",
    accessor: "income_precent",
    Cell: ({ value, row }) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{fixPercentage(value, 1)}</span>
          <OnClickTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>{`יעד: ${
                  row.original.goal_type === TYPE_SHEKEL
                    ? `${row.original.goal}${SHEKEL_SIGN}`
                    : `${row.original.goal}${PRECENT_SIGN}`
                }`}</span>
              </div>
            }
            IconComponent={<FaTag size={10} />}
          />
        </div>
      );
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total_without_vat");
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
];
export const mobileOperationalOrdersByCategoryColumns = [
  {
    Header: "שם קטגוריה",
    accessor: "category_name",
    Cell: ({
      value,
      row,
      openFeeOrConstExpenses,
      openOperationalOrders,
      setIsConstExpenses,
      openCategoryItemsTable,
    }) => {
      const { id } = row.original;
      return (
        <Button
          className="btn--hyperlink"
          onClick={() => {
            if (id === CONST_EXPENSE) {
              openFeeOrConstExpenses(CONST_EXPENSE);
              setIsConstExpenses(true);
            } else {
              openOperationalOrders();
              setIsConstExpenses(false);
            }
          }}
          textValue={value}
        />
      );
    },
    Footer: () => "סה״כ",
  },
  {
    Header: "רכש",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ paddingLeft: "10px" }}>{fixPrice(value)}</span>
          <OnClickTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <span
                  style={{ fontWeight: "bold" }}
                >{`צפי סוף תקופה : ${row.original.expected_end_period}`}</span> */}
                <span
                  style={{ fontWeight: "bold" }}
                >{`מצטבר שנה שעברה: ${row.original.cumulative_last_year}`}</span>
              </div>
            }
            IconComponent={<FaTag size={10} />}
          />
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "אחוז הכנסה",
    accessor: "income_precent",
    Cell: ({ value, row }) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{fixPercentage(value, 1)}</span>
          <OnClickTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>{`יעד: ${
                  row.original.goal_type === TYPE_SHEKEL
                    ? `${row.original.goal}${SHEKEL_SIGN}`
                    : `${row.original.goal}${PRECENT_SIGN}`
                }`}</span>
              </div>
            }
            IconComponent={<FaTag size={10} />}
          />
        </div>
      );
    },
  },
];
export const ordersBySupplierColumns = [
  {
    Header: "שם ספק",
    accessor: "supplier_name",
    Cell: ({ value, row, openCategoryItemsTable }) => {
      const { supplier_id } = row.original;

      //edge case - supplier name is number, react table auto converts to number type
      const splitString = String(value).split(".");
      let newString = "";

      splitString?.forEach((string) => (newString += string));
      return (
        <Button
          className="btn--hyperlink"
          style={{ fontSize: "1.5rem" }}
          onClick={() =>
            openCategoryItemsTable({ order_type: "supplier", id: supplier_id })
          }
          textValue={newString}
        />
      );
    },
    Footer: () => "סה״כ",
  },
  {
    Header: "רכש",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },

  {
    Header: "אחוז הכנסה",
    accessor: "income_precent",
    Cell: ({ value, row }) => {
      return <span>{fixPercentage(value, 1)}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total_without_vat");
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
];
export const constExpenseBySupplierColumns = [
  {
    Header: "שם ספק",
    accessor: "supplier_name",
    Footer: () => "סה״כ",
  },
  {
    Header: "תשלום",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money"> {fixPrice(total)}</div>;
    },
  },
  {
    Header: "אחוז הכנסה",
    accessor: "income_precent",
    Cell: ({ value, row }) => {
      return <span>{fixPercentage(value, 1)}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total_without_vat");
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
];
export const itemsByCategoryColumns = () => [
  {
    Header: "שם מוצר",
    accessor: "item_name",
    Footer: () => "סה״כ",
  },
  {
    Header: "מק״ט",
    accessor: "part_number",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "קטגוריה",
    accessor: "category_name",
  },
  {
    Header: "רכש",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value, 2)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "כמות",
    accessor: "total_amount",
    Cell: ({ value, row }) => {
      return <span>{Number(value).toFixed(2)}</span>;
    },
  },
  {
    Header: "יחידת מידה",
    accessor: "item_scale",
  },
  {
    Header: "אחוז מהכנסה",
    accessor: "income_percent",
    Cell: ({ value, row }) => {
      return <span>{fixPercentage(value, 1)}</span>;
    },
  },
];
export const mobileItemsByCategoryColumns = () => [
  {
    Header: "שם מוצר",
    accessor: "item_name",
    Cell: ({ value, row, openItemOrdersTable }) => {
      const { item_id } = row.original;
      const splitString = value.split(".");
      let newString = "";

      splitString.forEach((string) => (newString += string));
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="btn--hyperlink"
            onClick={() => openItemOrdersTable(item_id)}
            textValue={newString}
            style={{ fontSize: "1.5rem" }}
          />
          <OnClickTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{ fontWeight: "bold" }}
                >{`מק״ט: ${row.original.part_number}`}</span>
                <span
                  style={{ fontWeight: "bold" }}
                >{`ספק: ${row.original.supplier_name}`}</span>
              </div>
            }
            IconComponent={<FaTag size={10} />}
          />
        </div>
      );
    },
    Footer: () => "סה״כ",
  },
  {
    Header: "רכש",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{fixPrice(value, 2)}</span>
          <OnClickTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{ fontWeight: "bold" }}
                >{`כמות: ${row.original.total_amount}`}</span>
                <span
                  style={{ fontWeight: "bold" }}
                >{`יחידת מידה: ${row.original.item_scale}`}</span>
              </div>
            }
            IconComponent={<FaTag size={10} />}
          />
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "אחוז מהכנסה",
    accessor: "income_percent",
    Cell: ({ value, row }) => {
      return <span>{fixPercentage(value, 1)}</span>;
    },
    Footer: ({ data, rows, current_incomes }) => {
      const total_fees = sumFooter(rows, "total_without_vat");
      return total_fees > 0
        ? fixPercentage((total_fees / current_incomes) * 100, 1)
        : 0;
    },
  },
];
export const ordersByItemsColumns = () => [
  {
    Header: "שם מוצר",
    accessor: "name",
    Footer: () => "סה״כ",
  },
  {
    Header: "מק״ט",
    accessor: "catalog_number",
  },
  {
    Header: "ספק",
    accessor: "supplier",
  },
  {
    Header: "כמות בהזמנה",
    accessor: "ordered_amount",
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "ordered_amount"),
        [self.rows]
      );

      return <div>{fixPrice(total)}</div>;
    },
  },
  {
    Header: "כמות שנקלטה",
    accessor: "received_amount",
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "received_amount"),
        [self.rows]
      );

      return <div>{fixPrice(total)}</div>;
    },
  },
  {
    Header: "מחיר בהזמנה",
    accessor: "ordered_price",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value, 2)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "ordered_price"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "סך הכל מחיר בהזמנה",
    accessor: "total_ordered_without_vat",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value, 2)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_ordered_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "מחיר בקליטה",
    accessor: "received_price",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value, 2)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "received_price"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "סה״כ מחיר בקליטה",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return <span>{fixPrice(value, 2)}</span>;
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
  {
    Header: "תאריך",
    accessor: "document_date",
  },
  {
    Header: "מס מסמך",
    accessor: "document_number",
    Cell: ({ value, row, setSelectedOrder }) => {
      const { order_id, new_order_id } = row.original;
      return (
        <>
          <BtnTooltip
            text={
              new_order_id
                ? `מס' הזמנה: ${new_order_id}`
                : "קבלת סחורה ללא הזמנה"
            }
          >
            {new_order_id ? (
              <FaTag
                style={{ margin: " 0 .5rem", color: COLOR_SUBMIT }}
                onClick={() => setSelectedOrder({ new_order_id })}
              />
            ) : (
              <FaTag style={{ margin: " 0 .5rem" }} />
            )}
          </BtnTooltip>

          <span>
            <Button
              className="btn--hyperlink"
              onClick={() =>
                setSelectedOrder({
                  ...(value
                    ? { new_received_order_id: order_id }
                    : { new_order_id }),
                })
              }
              textValue={value ?? new_order_id}
            />
          </span>
        </>
      );
    },
  },
];
export const mobileOrdersByItemsColumns = () => [
  {
    Header: "שם מוצר",
    accessor: "name",
    Cell: ({ row, value, setSelectedOrder }) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>{value}</span>
            <OnClickTooltip
              title={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`מק״ט: ${row.original.catalog_number}`}</span>
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`ספק: ${row.original.supplier}`}</span>
                </div>
              }
              IconComponent={<FaTag size={10} />}
            />
          </div>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className="btn--hyperlink"
              style={{
                color: row.original.new_order_id ? COLOR_SUBMIT : "black",
              }}
              onClick={() =>
                setSelectedOrder({
                  new_received_order_id: row.original.new_order_id,
                })
              }
              textValue={row.original.document_number}
            />
            <span>
              {row.original.new_order_id
                ? `(מס' הזמנה: ${row.original.new_order_id})`
                : "(קבלת סחורה ללא הזמנה)"}
            </span>
          </span>
        </div>
      );
    },
    Footer: () => "סה״כ",
  },
  {
    Header: "כמות",
    accessor: "received_amount",
    Cell: ({ row, value }) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{value}</span>
          <OnClickTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{ fontWeight: "bold" }}
                >{`כמות שהוזמנה: ${row.original.ordered_amount}`}</span>
              </div>
            }
            IconComponent={<FaTag size={10} />}
          />
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "received_amount"),
        [self.rows]
      );

      return <div>{fixPrice(total)}</div>;
    },
  },
  {
    Header: "סה״כ",
    accessor: "total_without_vat",
    Cell: ({ value, row }) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{fixPrice(value, 2)}</span>
          <OnClickTooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontWeight: "bold" }}>{`מחיר בהזמנה: ${fixPrice(
                  row.original.ordered_price,
                  2
                )}`}</span>
                <span
                  style={{ fontWeight: "bold" }}
                >{`סך הכל מחיר בהזמנה: ${fixPrice(
                  row.original.total_ordered_without_vat,
                  2
                )}`}</span>
                <span
                  style={{ fontWeight: "bold" }}
                >{`סך הכל מחיר בקליטה: ${fixPrice(
                  row.original.total_without_vat,
                  2
                )}`}</span>
              </div>
            }
            IconComponent={<FaTag size={10} />}
          />
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return <div className="money">{fixPrice(total)}</div>;
    },
  },
];

export const openOrderAggregates = [
  { field: "total_without_vat", aggregate: "sum" },
  { field: "sent_at", aggregate: "count" },
];

export const constExpenseAggregates = [
  { field: "total", aggregate: "sum" },
  { field: "date", aggregate: "count" },
  { field: "income_percent", aggregate: "sum" },
];

export const refundOrderAggregates = [
  { field: "refund_price", aggregate: "sum" },
  { field: "created_at", aggregate: "count" },
];

export const expenseTablesOptions = [
  { text: "ניתוח לפי ספק" },

  {
    text: "ניתוח לפי קטגוריה",
  },
];

export const category_tables_aggregates = [
  { field: "total_without_vat", aggregate: "sum" },
];

export const purchase_suppliers_aggregates = [
  { field: "total_sent_without_vat", aggregate: "sum" },
  { field: "total_received_without_vat", aggregate: "sum" },
  { field: "total_received_with_vat", aggregate: "sum" },
  { field: "total_sent_with_vat", aggregate: "sum" },
  { field: "markup_sent", aggregate: "sum" },
  { field: "markup_received", aggregate: "sum" },
  { field: "income_precent_sent", aggregate: "average" },
  { field: "income_precent_received", aggregate: "average" },
  { field: "items_count", aggregate: "sum" },
];

// export const supplier_summary_aggregates = [
//   { field: "total_sent_without_vat", aggregate: "sum" },
//   { field: "total_received_without_vat", aggregate: "sum" },
//   { field: "markup_sent", aggregate: "sum" },
//   { field: "markup_received", aggregate: "sum" },
//   { field: "income_precent_sent", aggregate: "average" },
//   { field: "income_precent_received", aggregate: "average" },
// ];
export const item_table_aggregates = [
  { field: "total_without_vat", aggregate: "sum" },
];
export const items_aggregates = [
  { field: "total_without_vat", aggregate: "sum" },
  { field: "ordered_amount", aggregate: "sum" },
  { field: "received_amount", aggregate: "sum" },
  { field: "total_ordered_without_vat", aggregate: "sum" },
  { field: "ordered_price", aggregate: "sum" },
  { field: "received_price", aggregate: "sum" },
];
export const pull_transfer_aggregates = [{ field: "worth", aggregate: "sum" }];
