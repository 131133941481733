// publicReducer.js

import {
  SIDEBAR_COLLAPSED,
  SET_DATE,
  SET_IS_MOBILE,
  SET_ACTIVE,
  EDIT_MODE_SCHEDULE_CHANGE,
  SET_ADMIN_ACTIVE,
  SET_IS_FRAME,
  DRAG_CELL_START,
  DRAG_CELL_END,
  SET_IS_TABLET,
  SET_TITLE,
  SET_CURRENT_VERSION_DATE,
  SET_SHOW_VERSION_MODAL,
  SET_WEEK,
} from "../actions/publicAction/types";
import { LOGOUT } from "../actions/loginAction/types";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";

const INITIAL_STATE = {
  collapsed: false,
  scheduleWeek: { week_start: null, week_end: null, week: [] },
  branches: [],
  selected_branch: {},
  isMobile: false,
  isTablet: false,
  isFrame: false,
  active: null,
  admin_active: null,
  schedule_has_change: false,
  dragged_cell: null,
  title: null,
  currentVersionDate: null,
  showVersionModal: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDIT_MODE_SCHEDULE_CHANGE:
      return { ...state, schedule_has_change: action.payload };
    case SIDEBAR_COLLAPSED:
      return { ...state, collapsed: action.payload };
    case SET_WEEK:
      return {
        ...state,
        scheduleWeek: { ...state.scheduleWeek, week: action.payload },
      };
    case SET_ACTIVE:
      return { ...state, active: action.payload };
    case SET_TITLE:
      return { ...state, title: action.payload };
    case SET_IS_FRAME:
      return { ...state, isFrame: action.payload };
    case SET_IS_TABLET:
      return { ...state, isTablet: action.payload };
    case SET_ADMIN_ACTIVE:
      return { ...state, admin_active: action.payload };
    case SET_DATE:
      return { ...state, scheduleWeek: action.payload };
    case SET_IS_MOBILE:
      return { ...state, isMobile: action.payload };
    case DRAG_CELL_START:
      return { ...state, dragged_cell: action.cell_id };
    case DRAG_CELL_END:
      return { ...state, dragged_cell: null };
    case SET_CURRENT_VERSION_DATE: {
      const { currentVersionDate, showVersionModal } = action;
      return {
        ...state,
        currentVersionDate,
        showVersionModal,
      };
    }
    case SET_SHOW_VERSION_MODAL: {
      const { showVersionModal } = action;
      return {
        ...state,
        showVersionModal,
      };
    }
    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
