import React, {  useEffect, useState } from "react";
import {
  useExpanded,
  useFilters,
  useGroupBy,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useScreenSize } from "../../Hooks";
import SkeletonReports from "../skeletons/SkeletonReports";
import { Pagination } from "@mui/lab";



export default ({
  columns,
  data,
  fetchData,
  pagination,
  setPagination,
  loading=false,
  setLogUser,
  setResetPass,
  pageDefaultIndex,
  pageCount: controlledPageCount,
}) => {
  const { isMobile } = useScreenSize()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    footerGroups,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: pageDefaultIndex,
        pageSize: 10,
        sortBy: [
          {
            id: "branch_name",
            desc: false,
          },
          { id: "lastName", desc: false },
        ],
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );
  const [activeRow, setActiveRow] = useState(null);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
  
      fetchData({ pageIndex:pagination.current_page });
    
  }, [fetchData, pagination.current_page]);

  return data && data.length ?  (
    <div className={isMobile ? "table frame" : "table"}>
      <table style={isMobile ? { width: "1570px" } : {}} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th

                  className={column.id === "buttons" ? "not-print" : ""}
                  {...(column.id === "buttons"
                    ? { ...column.getHeaderProps() }
                    : {
                        ...column.getHeaderProps(column.getSortByToggleProps()),
                      })}
                      
                  style={column.id === "buttons" ? { width: "1px" } : { width: "fit-content"}}
                >
                  <span>
                    {column.id === "buttons" ? (
                      <></>
                    ) : column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fa fa-sort-down" />
                      ) : (
                        <i className="fa fa-sort-up" />
                      )
                    ) : (
                      <i className="fa fa-sort" />
                    )}
                  </span>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="routine" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                onClick={() => setActiveRow(i)}
                className={activeRow === i ? "table--active-row" : ""}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                  
                      className={
                        cell.column.id === "buttons" ? "not-print" : ""
                      }
                      {...cell.getCellProps()}
                      style={cell.column.width === 1 ? { width: "1px", padding:"0.5rem" } : {}}
                    >
                      {cell.render("Cell", { setLogUser, setResetPass })}
                      
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>

      <Pagination
        style={{ marginTop: "1rem" }}
        color="primary"
        count={pagination.last_page}
        page={pagination.current_page}
        onChange={setPagination}
        />
    </div>
  </div>
  ):<SkeletonReports loader={loading}/>;
};
