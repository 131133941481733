import React, { useRef, useState } from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import Filters from "./Filters";
import SummaryBoxes from "./SummaryBoxes";
import Graphs from "./Graphs";
import TablesSection from "./tables/TablesSection";

// ======== COMPONENTS ========

export const SalesCost = (props) => {
  // ======== CONSTANTS ========

  // ======== HOOKS ========

  const pageRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  // ======== STATES ========
  const [data, setData] = useState();

  // ======== EFFECTS ========

  // ======== UI ========

  return (
    <div
      style={{
        margin: "40px",
      }}
    >
      <h1>עלות המכר</h1>
      <Filters {...{ setData }} />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxWidth="2000px"
      >
        {data && (
          <>
            <SummaryBoxes data={data} />
            <Graphs data={data.soldDishes} />
            <TablesSection {...{ data, setData }} />
          </>
        )}
      </Box>
    </div>
  );
};

export default SalesCost;
