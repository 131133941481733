import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import SelectComponent from "../../../components/Forms/inputs/Select";
import { SALARY_TYPE_ADJUSTED, SALARY_TYPE_BASEEXTRA } from "../consts";
import FormTextField from "../../../components/Forms/inputs/FormTextField";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const SalarySubTypeRow = ({
  field,
  index,
  handlesSubSalaryTypeChange,
  shift_positions,
  salary_by_sub_types,
}) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  // ======== STATES ========

  // ======== HANDLERS ========

  const handleRenderFields = (type, index) => {
    switch (type) {
      case SALARY_TYPE_ADJUSTED:
        return (
          <>
            <Grid item xs={6} sm={4}>
              <FormTextField
                {...{
                  size: "small",
                  name: `salary_structure.salary_by_subtype[${index}].salary.100`,
                  label: "100%",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormTextField
                {...{
                  size: "small",
                  name: `salary_structure.salary_by_subtype[${index}].salary.125`,
                  label: "125%",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormTextField
                {...{
                  size: "small",
                  name: `salary_structure.salary_by_subtype[${index}].salary.150`,
                  label: "150%",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormTextField
                {...{
                  size: "small",
                  name: `salary_structure.salary_by_subtype[${index}].salary.175`,
                  label: "175%",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormTextField
                {...{
                  size: "small",
                  name: `salary_structure.salary_by_subtype[${index}].salary.200`,
                  label: "200%",
                }}
              />
            </Grid>
          </>
        );
      default:
        return (
          <Grid item xs={12}>
            <FormTextField
              {...{
                fullWidth: true,
                size: "small",
                name: `salary_structure.salary_by_subtype.${index}.salary.salary`,
                label: "שכר",
              }}
            />
          </Grid>
        );
    }
  };
  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <>
      <Grid item xs={12}>
        <SelectComponent
          {...{
            size: "small",
            name: `salary_structure.salary_by_subtype.${index}.subtype`,
            label: "שם תת תפקיד",
            options: shift_positions,
            handleChange: (e) =>
              handlesSubSalaryTypeChange(e, index, "subtype"),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectComponent
          {...{
            size: "small",
            name: `salary_structure.salary_by_subtype.${index}.salary_type`,
            label: "סוג שכר",
            options: salary_by_sub_types,
            handleChange: (e) =>
              handlesSubSalaryTypeChange(e, index, "salary_type"),
          }}
        />
      </Grid>
      <Grid item container spacing={1} xs={12}>
        {handleRenderFields(field.salary_type, index)}
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ marginTop: "20px", color: "black" }} />
      </Grid>
    </>
  );
};

export default SalarySubTypeRow;
