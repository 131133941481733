import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-responsive-modal";
import { ClockLoader } from "react-spinners";

const LoaderModal = ({ isLoading, text="טוען..." }) => {
  return (
    <Modal
    classNames={{modal:"flex-center"}}
      styles={{
        modal: { width:"300px"},
      }}
      center
      open={isLoading}
      showCloseIcon={false}
      animationDuration={200}
      closeOnOverlayClick={false}
    >
        <div className="flex-column-center">
        <ClockLoader
        css={`
          display: block;
        `}
        size={100}
        color={"#00b1ff"}
      />
      <h2 className="u-center-text margin-y"> {text}</h2>
        </div>

    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { isLoading, text } = state.loader;
  return { isLoading, text };
};

export default connect(mapStateToProps)(LoaderModal);
