import ButtonIcon from "../../components/buttons/ButtonIcon";
import { FaPencilAlt, FaSave, FaTrash } from "react-icons/fa";
import { RED_COLOR } from "../products/const";
import { Input } from "@mui/material";
import {
  PriceCell,
  PercentageCell,
} from "../../components/tables/components/PriceFooter";

export const GOAL_TYPE_PRICE = 0;
export const GOAL_TYPE_PERCENTAGE = 1;

export default [
  {
    Header: "שם היעד",
    accessor: "name",
  },
  {
    Header: "יעד",
    accessor: "goal",
    Cell: ({ row, column, handleGoalChange }) => {
      return row.original.editMode ? (
        <Input
          dir="ltr"
          color="neutral"
          size="sm"
          variant="plain"
          id={`${row.original.id}`}
          value={row.original.goal}
          onChange={handleGoalChange}
          type="number"
          sx={{
            minWidth: 50,
            maxWidth: `${(row.original.goal.toString().length + 1) * 8}px`,
          }}
          inputProps={{ style: { textAlign: "left" } }}
        />
      ) : row.original.display_type == 1 ? (
        PercentageCell({ row, column })
      ) : (
        PriceCell({ row, column })
      );
    },
  },
  {
    Header: "תצוגה",
    accessor: "displayType",
    Cell: ({ row, handleSelectChange }) => {
      return row.original.editMode ? (
        <select
          id={row.original.id}
          value={row.original.display_type}
          name="precent"
          onChange={handleSelectChange}
          style={{ width: "44px" }}
        >
          <option key={row.original.id} value={0}>
            {"₪"}
          </option>
          <option key={row.original.id} value={1}>
            {"%"}
          </option>
        </select>
      ) : (
        <select
          id={row.original.id}
          value={row.original.display_type}
          name="precent"
          onChange={handleSelectChange}
          style={{ width: "44px" }}
          disabled
        >
          <option key={row.original.id} value={0}>
            {"₪"}
          </option>
          <option key={row.original.id} value={1}>
            {"%"}
          </option>
        </select>
      );
    },
  },

  {
    Header: "פעולות",
    accessor: "actions",
    Cell: ({ row, handleGoalRemove, handleGoalEdit }) => (
      <span className="flex-around-center">
        <ButtonIcon
          onClick={() => handleGoalEdit(row.original.id)}
          className="btn--normal"
          icon={row.original.editMode ? FaSave : FaPencilAlt}
        />
        <ButtonIcon
          onClick={() => handleGoalRemove(row.original.id)}
          className="btn--normal"
          style={{ color: RED_COLOR }}
          icon={FaTrash}
        />
      </span>
    ),
  },
];
