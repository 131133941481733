export default () => {
  /**
   * worker has no access to local library, it must be fetched according to docs:
   * https://docs.sheetjs.com/docs/demos/bigdata/worker/
   **/
  importScripts(
    "https://cdn.sheetjs.com/xlsx-0.20.0/package/dist/xlsx.full.min.js"
  );

  // splitting the data to chunks and building a CSV file using xlsx library
  const dataToCsv = ({ data, translations, columns, translation_endpoint }) => {
    //filtering the data based on columns properties
    const filteredData = data.map((row) => {
      const filteredRow = {};
      columns.forEach((col) => {
        filteredRow[col.id] = row[col.id];
      });
      return filteredRow;
    });

    const translate = translations.heb.excel;

    const headers = Object.keys(filteredData[0]);

    //bulding translated headers
    const translatedHeaders = headers.map(
      (header) =>
        translate[translation_endpoint]?.[header] ||
        translate[header] ||
        (header === "expander" && " ") ||
        header
    );

    const dataRows = filteredData.map((obj) => Object.values(obj));

    const CHUNK_SIZE = 10000;
    const excelBuffers = [];

    for (let i = 0; i < filteredData.length; i += CHUNK_SIZE) {
      //bulding sheet with headers and data as seperated variables

      const dataSlice = dataRows.slice(i, i + CHUNK_SIZE);
      const dataWithHeaders = [translatedHeaders].concat(dataSlice);
      const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);

      // const worksheet = XLSX.utils.aoa_to_sheet([
      //   translatedHeaders,
      //   ...dataRows.slice(i, i + CHUNK_SIZE),
      // ]);

      //appending the sheet to a new worbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "csv",
        type: "array",
      });

      excelBuffers.push(excelBuffer);
    }

    //Blob automatically merges the csvs into 1 file
    return new Blob(excelBuffers, {
      type: "text/csv;charset=utf-8",
    });
  };

  // Listen for messages from the main thread
  self.onmessage = (event) => {
    const { data } = event;

    // Invoke the heavy function and post the result back to the main thread
    const result = dataToCsv(data);
    self.postMessage(result);
  };
};
