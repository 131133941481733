import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import {
  ControlRichTextEditor,
  getAsHtml,
  getFromHtml,
} from "../../../components/Forms/inputs/RichTextEditor2";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { ControlDnd } from "../../../components/DND/FileDropZone";
import PreviewFile from "../../../components/DND/PreviewFile";

const DangerouslySetInnerHTML = ({ src }) => (
  <div dangerouslySetInnerHTML={{ __html: src }}></div>
);

const PART_TYPE_FREE_TEXT = 0;
const PART_TYPE_FILE = 1;
const PART_TYPE_LINK = 2;

const PARTS = {
  [PART_TYPE_FREE_TEXT]: {
    // id: PART_TYPE_FREE_TEXT,
    label: "טקסט",
    saveAs: getAsHtml,
    defaultValue: getFromHtml,
    extraData: [
      { name: "label", component: FormTextField, label: "כותרת מקטע" },
      {
        name: "value",
        component: ControlRichTextEditor,
        label: "טקסט",
        required: true,
        colorize: true,
        inlineStyle: true,
        fontSize: true,
      },
    ],
    show: DangerouslySetInnerHTML,
  },
  [PART_TYPE_FILE]: {
    // id: PART_TYPE_FILE,
    label: "קובץ",
    saveAs: ([value]) => {
      if (!(value instanceof File)) {
        return;
      }
      return value;
    },
    defaultValue: (value) => (value ? [value] : []),
    extraData: [
      { name: "label", component: FormTextField, label: "כותרת מקטע" },
      {
        name: "value",
        component: ControlDnd,
        label: "קובץ",
        rules: { required: REQUIRED_MESSAGE },
      },
    ],
    show: PreviewFile,
  },
  [PART_TYPE_LINK]: {
    // id: PART_TYPE_LINK,
    label: "קישור ליוטיוב",
    saveAs: (value) => {
      const videoId = new URL(value).searchParams.get("v");
      if (!videoId) {
        return value;
      } else {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    },
    extraData: [
      { name: "label", component: FormTextField, label: "כותרת מקטע" },
      {
        name: "value",
        component: FormTextField,
        label: "קישור ליוטיוב",
        rules: {
          required: REQUIRED_MESSAGE,
          pattern: {
            value: new RegExp(
              "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
            ),
            message: "שדה זה חייב להיות קישור",
          },
        },
      },
    ],
    show: "iframe",
  },
};
export { PARTS };
