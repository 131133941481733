import produce, { current } from "immer";
import { uniqBy } from "lodash";

import {
  // FILTERS_STATUS_ENABLE,
  // NEW_ITEM_ID,
  TAB_INGREDIENT_WARHOUSE,
  TAB_OPTIONS,
  TAB_PATH_ITEMS_INGREDIENT_WARHOUSE,
  FILTERS_STATUS_ENABLE,
  FILTERS_TYPE_OF_ITEMS,
} from "../../pages/warehouse/const";
import { htmlSpecialCharacters } from "../../pages/warehouse/const";
import {
  ADD_ITEMS_TO_WAREHOUSE_FROM_CATALOG,
  ADD_MULTI_ITEMS_TO_WAREHOUSE_FROM_CATALOG,
  REMOVE_CATALOG_ITEMS_FROM_WAREHOUSE,
  ADD_ITEMS_TO_WAREHOUSE_FROM_PRODUCTS,
  REMOVE_PRODUCT_ITEMS_FROM_WAREHOUSE,
  ADD_MULTI_ITEMS_TO_WAREHOUSE_FROM_PRODUCTS,
  SET_TAB,
  CLOSE_FORM,
  SET_FILTER,
  SET_CATALOG_FILTER,
  SET_PRODUCTS_FILTER,
  SET_CATALOG_ITEMS_PAGE,
  SET_WAREHOUSES_PAGE,
  SET_WAREHOUSE_CATALOG_ITEMS_PAGE,
  SET_WAREHOUSE_PRODUCT_ITEMS_PAGE,
  SET_PRODUCTS_PAGE,
  START_LOADING_FREE_SEARCH,
  END_LOADING_FREE_SEARCH,
  FETCH_WAREHOUSES_FREE_SEARCH,
  FETCH_WAREHOUSES,
  FETCH_PRODUCTS_WAREHOUSES,
  FETCH_CATEGORIES,
  FETCH_PRODUCTS_CATEGORIES,
  FETCH_ITEMS,
  FETCH_PRODUCTS,
  FETCH_ITEMS_FREE_SEARCH,
  FETCH_PRODUCTS_FREE_SEARCH,
  EDIT_WAREHOUSE,
  SET_WAREHOUSE_CART,
  SET_WAREHOUSE_CATALOG_CART,
  SET_WAREHOUSE_PRODUCTS_FROM_PAGINATION,
  SET_WAREHOUSE_CATALOG_FROM_PAGINATION,
  SET_WAREHOUSE_INFO,
  CLOSE_WAREHOUSE_ITEMS,
  CLOSE_WAREHOUSE_INFO,
  CREATE_NEW_WAREHOUSE,
  CREATE_NEW_PRODUCTS_WAREHOUSE,
  START_LOADING_WAREHOUSE_DETAILS,
  END_LOADING_WAREHOUSE_DETAILS,
  START_LOADING_MASTER_WAREHOUSES,
  START_LOADING_MASTER_PRODUCTS_WAREHOUSES,
  END_LOADING_MASTER_PRODUCTS_WAREHOUSES,
  END_LOADING_MASTER_WAREHOUSES,
  START_LOADING_ALL_TYPE_ITEMS_FOR_WAREHOUSES,
  END_LOADING_ALL_TYPE_ITEMS_FOR_WAREHOUSES,
  START_LOADING_WAREHOUSE_ITEMS_INFO,
  END_LOADING_WAREHOUSE_ITEMS_INFO,
  START_LOADING_CATALOG_ITEMS,
  END_LOADING_CATALOG_ITEMS,
  SET_SNACKBAR_OPEN_AFTER_CLOSING_CART,
  SET_SNACKBAR_CLOSED_AFTER_CLOSING_CART,
  STORE_WAREHOUSE,
  SET_SUPPLIERS_OPTIONS,
  UPDATE_WAREHOUSE_TOTAL_COUNT,
  FETCH_WAREHOUSE_CATEGORIES,
  FETCH_WAREHOUSES_LOGS,
  SET_WAREHOUSE_LOGS_PAGE,
  START_LOADING_WAREHOUSE_LOGS,
  END_LOADING_WAREHOUSE_LOGS,
} from "../actions/warehouseAction/type";

const INITIAL_STATE = {
  // tab: {
  //   id: 1,
  //   label: TAB_OPTIONS[1].label,
  //   path: "productsWarehouse",
  // },
  tab: {
    id: TAB_INGREDIENT_WARHOUSE,
    label: TAB_OPTIONS[TAB_INGREDIENT_WARHOUSE].label,
    path: TAB_PATH_ITEMS_INGREDIENT_WARHOUSE,
  },
  ingredientsWarehouse: {
    filter: {
      status: [FILTERS_STATUS_ENABLE + 1],
      branchId: {},
      other: [],
    },
    data: {
      master: {
        isLoading: false,
        isOpenSnackbar: false,
        data: [],
        freeSearch: {
          isLoading: false,
          data: [],
        },
        pagination: {
          current_page: 0,
          per_page: 100,
          total: 1,
        },
      },
      details: {
        isLoading: false,
        data: {},
        warehouseCategories: [],
        warehouseLogs: {
          pagination: {
            current_page: 0,
            per_page: 5,
            total: 1,
          },
          data: { isLoading: false, logs: [] },
        }
      },
      warehouseItems: {
        isLoading: false,
        data: {},
        itemIds: [],
        productItemIds: [],
        pagination_catalog: {
          current_page: 0,
          per_page: 100,
          total: 1,
        },
        pagination_product: {
          current_page_product: 0,
          per_page_product: 100,
          total: 1,
        },
      },
      warehouseInfo: {
        isLoading: false,
        data: {},
        itemIds: [],
        productItemIds: [],
        pagination: {
          current_page: 0,
          per_page: 100,
          total: 1,
        },
      },
      catalog: {
        isLoading: false,
        data: [],
        filter: {
          status: [FILTERS_STATUS_ENABLE + 1],
          branchId: {},
          categoryId: [],
          productCategoryId: [],
          suppliersId: [],
          other: [],
          itemsType: FILTERS_TYPE_OF_ITEMS[0],
        },
        options: {
          categories: {
            isLoading: false,
            data: [],
          },
          suppliers: {
            isLoading: false,
            data: [],
          },
        },
        freeSearch: {
          isLoading: false,
          data: [],
        },
        pagination: {
          current_page: 0,
          per_page: 100,
          total: 1,
        },
      },
      products: {
        isLoading: false,
        data: [],
        filter: {
          status: [FILTERS_STATUS_ENABLE + 1],
          branchId: {},
          categoryId: [],
          other: [],
          itemsType: FILTERS_TYPE_OF_ITEMS[0],
        },
        options: {
          categories: {
            isLoading: false,
            data: [],
          },
        },
        freeSearch: {
          isLoading: false,
          data: [],
        },
        pagination: {
          current_page: 0,
          per_page: 100,
          total: 1,
        },
      },
    },
  },
  //   PRODUCTS TREE WAREHOUSE
  productsWarehouse: {
    filter: {
      status: [FILTERS_STATUS_ENABLE + 1],
      branchId: {},
      other: [],
    },
    data: {
      master: {
        isLoading: false,
        data: [],
        freeSearch: {
          isLoading: false,
          data: [],
        },
        pagination: {
          current_page: 0,
          per_page: 100,
          total: 1,
        },
      },
      details: {
        isLoading: false,
        data: {},
      },
    },
    freeSearch: {
      isLoading: false,
      data: [],
    },
    pagination: {
      current_page: 0,
      per_page: 100,
      total: 1,
    },
  },
};

export default produce((draft, action, state) => {
  const { type, payload } = action;
  switch (type) {
    // =========== GENERAL START ===========
    case SET_TAB:
      draft.tab = payload;
      draft[[draft.tab.path]].data.details =
        INITIAL_STATE[[draft.tab.path]].data.details;
      break;

    case CLOSE_FORM:
      draft[[draft.tab.path]].data.details.data =
        INITIAL_STATE[[draft.tab.path]].data.details.data;
      break;
    // =========== FILTERS START ===========
    case SET_FILTER:
      const { filterType, value } = payload;
      draft[[draft.tab.path]].filter[[filterType]] = value;
      break;

    case START_LOADING_FREE_SEARCH:
    case END_LOADING_FREE_SEARCH:
      draft.ingredientsWarehouse.data.master.freeSearch.isLoading = payload;
      break;
    case FETCH_WAREHOUSES_FREE_SEARCH:
      draft.ingredientsWarehouse.data.master.freeSearch.data = uniqBy(
        payload,
        "id"
      );
      break;

    //  =========== ITEMS CRUD SECTION START ===========

    // ITEMS MASTER LOADING
    case START_LOADING_MASTER_WAREHOUSES:
    case END_LOADING_MASTER_WAREHOUSES:
      draft.ingredientsWarehouse.data.master.isLoading = payload;
      break;

    // WAREHOUSE MASTER
    case FETCH_WAREHOUSES:
      let warehousesArray;
      let paginationData;
      if (Array.isArray(payload)) {
        warehousesArray = payload;
        paginationData = {
          total: warehousesArray.length,
          current_page: 0,
          per_page: 10,
        };
      } else {
        const { total, current_page, per_page, data } = payload;
        warehousesArray = data;
        paginationData = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
      }

      draft.ingredientsWarehouse.data.master.data = warehousesArray;

      draft[[draft.tab.path]].data.master.pagination = paginationData;

      break;

    case SET_WAREHOUSES_PAGE:
      draft[[draft.tab.path]].data.master.pagination[[payload.name]] =
        payload.value;
      break;

    // WAREHOUSE DETAILS LOADING
    case START_LOADING_WAREHOUSE_DETAILS:
    case END_LOADING_WAREHOUSE_DETAILS:
      draft.ingredientsWarehouse.data.details.isLoading = payload;
      break;

    // WAREHOUSE DETAILS LOADING
    case START_LOADING_WAREHOUSE_ITEMS_INFO:
    case END_LOADING_WAREHOUSE_ITEMS_INFO:
      draft.ingredientsWarehouse.data.warehouseInfo.isLoading = payload;
      break;

    // WAREHOUSE DETAILS
    // WAREHOUSE CREATE (BUTTON)
    case EDIT_WAREHOUSE:
    case CREATE_NEW_WAREHOUSE:
      draft.ingredientsWarehouse.data.master.freeSearch.data = [];
      draft.ingredientsWarehouse.data.details.data = payload;
      draft[[draft.tab.path]].data.warehouseItems =
        INITIAL_STATE[[draft.tab.path]].data.warehouseItems;
      break;

    case FETCH_WAREHOUSE_CATEGORIES:
      draft.ingredientsWarehouse.data.details.warehouseCategories = payload;
      break;


    // WAREHOUSE LOGS
    case FETCH_WAREHOUSES_LOGS:
      let warehousesLogsArray;
      let paginationLogs;
      if (Array.isArray(payload)) {
        warehousesLogsArray = payload;
        paginationLogs = {
          total: warehousesLogsArray.length,
          current_page: 0,
          per_page: 10,
        };
      } else {
        const { total, current_page, per_page, data } = payload;
        warehousesLogsArray = data;
        paginationLogs = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
      }

      draft.ingredientsWarehouse.data.details.warehouseLogs.data.logs = warehousesLogsArray;

      draft[[draft.tab.path]].data.details.warehouseLogs.pagination = paginationLogs;

      break;

      case SET_WAREHOUSE_LOGS_PAGE:
        draft[[draft.tab.path]].data.details.warehouseLogs.pagination[[payload.name]] =
          payload.value;
        break;

      // ITEMS MASTER LOADING
    case START_LOADING_WAREHOUSE_LOGS:
      case END_LOADING_WAREHOUSE_LOGS:
        draft.ingredientsWarehouse.data.details.warehouseLogs.data.isLoading = payload;
        break;

    // CREATE WAREHOUSE
    case STORE_WAREHOUSE:
      // Add created warehouse to the list
      draft[[draft.tab.path]].data.master.data.unshift(payload);
      // Close details form
      draft[[draft.tab.path]].data.details.data =
        INITIAL_STATE[[draft.tab.path]].data.details.data;
      break;

    // WAREHOUSE DETAILS AND ITEMS
    case SET_WAREHOUSE_CART:
      const { warehouseDetails, warehouseItems, warehouseProductItems } =
        payload;
      draft.ingredientsWarehouse.data.master.freeSearch.data = [];
      draft.ingredientsWarehouse.data.warehouseItems.data = warehouseDetails;
      draft.ingredientsWarehouse.data.warehouseItems.itemIds = warehouseItems;
      draft.ingredientsWarehouse.data.warehouseItems.productItemIds =
        warehouseProductItems;
      break;

    // WAREHOUSE DETAILS AND ITEMS
    case SET_WAREHOUSE_CATALOG_CART:
      const {
        warehouseGeneralDetails,
        warehouseCatalogItems,
        paginationWarehouseCatalogData,
        warehouseCartProductItems,
        paginationWarehouseProductData,
      } = payload;
      draft.ingredientsWarehouse.data.details.data =
        INITIAL_STATE[[draft.tab.path]].data.details.data;
      draft.ingredientsWarehouse.data.master.freeSearch.data = [];
      draft.ingredientsWarehouse.data.warehouseItems.data =
        warehouseGeneralDetails;
      draft.ingredientsWarehouse.data.warehouseItems.itemIds =
        warehouseCatalogItems;
      draft.ingredientsWarehouse.data.warehouseItems.pagination_catalog =
        paginationWarehouseCatalogData;
      draft.ingredientsWarehouse.data.warehouseItems.productItemIds =
        warehouseCartProductItems;
      draft.ingredientsWarehouse.data.warehouseItems.pagination_product =
        paginationWarehouseProductData;
      break;

    case SET_WAREHOUSE_PRODUCTS_FROM_PAGINATION:
      draft.ingredientsWarehouse.data.warehouseItems.productItemIds =
        payload.warehouseCartProductItems;
      draft.ingredientsWarehouse.data.warehouseItems.pagination_product =
        payload.paginationWarehouseProductData;
      break;

    case SET_WAREHOUSE_CATALOG_FROM_PAGINATION:
      draft.ingredientsWarehouse.data.warehouseItems.itemIds =
        payload.warehouseCatalogItems;
      draft.ingredientsWarehouse.data.warehouseItems.pagination_catalog =
        payload.paginationWarehouseCatalogData;
      break;

    case SET_WAREHOUSE_INFO:
      const {
        warehouseInfoDetails,
        warehouseInfoItems,
        warehouseInfoProductItems,
      } = payload;
      draft.ingredientsWarehouse.data.master.freeSearch.data = [];
      draft.ingredientsWarehouse.data.warehouseInfo.data = warehouseInfoDetails;
      draft.ingredientsWarehouse.data.warehouseInfo.itemIds =
        warehouseInfoItems;
      draft.ingredientsWarehouse.data.warehouseInfo.productItemIds =
        warehouseInfoProductItems;
      break;

    // =========== CATALOG FILTERS START ===========
    case SET_CATALOG_FILTER:
      const { catalogFilterType, catalogValue } = payload;
      draft[[draft.tab.path]].data.catalog.filter[[catalogFilterType]] =
        catalogValue;
      break;

    // =========== PRODUCT FILTERS START ===========
    case SET_PRODUCTS_FILTER:
      // const { catalogFilterType, catalogValue } = payload;
      // draft[[draft.tab.path]].data.catalog.filter[[catalogFilterType]] =
      //   catalogValue;
      break;

    // CATEGORY
    case FETCH_CATEGORIES:
      draft.ingredientsWarehouse.data.catalog.options.categories.data = payload
        .filter(({ enable }) => enable)
        .map(({ id, name }) => ({
          value: parseInt(id),
          label: htmlSpecialCharacters(name),
        }));
      break;

    // SUPPLIERS
    // case START_LOADING_SUPPLIERS:
    //   case END_LOADING_SUPPLIERS:
    //     draft.items.options.suppliers.isLoading = payload;
    //     break;

    case SET_SUPPLIERS_OPTIONS:
      draft.ingredientsWarehouse.data.catalog.options.suppliers.data =
        payload.map(({ supplier_id, name, has_department }) => ({
          value: supplier_id,
          label: htmlSpecialCharacters(name),
          hasDepartment: has_department,
        }));
      break;

    // ITEMS MASTER
    case FETCH_ITEMS:
      let itemsArray;
      let paginationCatalogData;
      if (Array.isArray(payload)) {
        itemsArray = payload;
        paginationCatalogData = {
          total: itemsArray.length,
          current_page: 0,
          per_page: 10,
        };
      } else {
        const { total, current_page, per_page, data } = payload;
        itemsArray = data;
        paginationCatalogData = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
      }

      draft.ingredientsWarehouse.data.catalog.data = itemsArray;

      draft[[draft.tab.path]].data.catalog.pagination = paginationCatalogData;

      break;

    case FETCH_ITEMS_FREE_SEARCH:
      draft.ingredientsWarehouse.data.catalog.freeSearch.data = uniqBy(
        payload,
        "id"
      );
      break;

    case START_LOADING_CATALOG_ITEMS:
    case END_LOADING_CATALOG_ITEMS:
      draft.ingredientsWarehouse.data.catalog.isLoading = payload;
      break;

    case START_LOADING_ALL_TYPE_ITEMS_FOR_WAREHOUSES:
    case END_LOADING_ALL_TYPE_ITEMS_FOR_WAREHOUSES:
      draft.ingredientsWarehouse.data.warehouseItems.isLoading = payload;
      break;

    case SET_SNACKBAR_OPEN_AFTER_CLOSING_CART:
    case SET_SNACKBAR_CLOSED_AFTER_CLOSING_CART:
      draft.ingredientsWarehouse.data.master.isOpenSnackbar = payload;
      break;

    case SET_CATALOG_ITEMS_PAGE:
      draft[[draft.tab.path]].data.catalog.pagination[[payload.name]] =
        payload.value;
      break;

    case SET_WAREHOUSE_CATALOG_ITEMS_PAGE:
      draft[[draft.tab.path]].data.warehouseItems.pagination_catalog[
        [payload.name]
      ] = payload.value;
      break;

    case SET_WAREHOUSE_PRODUCT_ITEMS_PAGE:
      draft[[draft.tab.path]].data.warehouseItems.pagination_product[
        [payload.name]
      ] = payload.value;
      break;

    case ADD_ITEMS_TO_WAREHOUSE_FROM_CATALOG:
      draft.ingredientsWarehouse.data.warehouseItems.itemIds.push(payload);
      break;

    case ADD_MULTI_ITEMS_TO_WAREHOUSE_FROM_CATALOG:
      draft.ingredientsWarehouse.data.warehouseItems.itemIds.push(...payload);
      break;

    case REMOVE_CATALOG_ITEMS_FROM_WAREHOUSE:
      const index =
        draft.ingredientsWarehouse.data.warehouseItems.itemIds.findIndex(
          (item) => item.id === payload
        );
      if (index !== -1)
        draft.ingredientsWarehouse.data.warehouseItems.itemIds.splice(index, 1);

      break;

    // PRODUCTS MASTER
    case FETCH_PRODUCTS:
      let productsArray;
      let paginationProductData;
      if (Array.isArray(payload)) {
        productsArray = payload;
        paginationProductData = {
          total: productsArray.length,
          current_page: 0,
          per_page: 10,
        };
      } else {
        const { total, current_page, per_page, data } = payload;
        productsArray = data;
        paginationProductData = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
      }

      draft.ingredientsWarehouse.data.products.data = productsArray;

      draft[[draft.tab.path]].data.products.pagination = paginationProductData;

      break;
    case FETCH_PRODUCTS_FREE_SEARCH:
      draft.ingredientsWarehouse.data.products.freeSearch.data = uniqBy(
        payload,
        "id"
      );
      break;

    // PRODUCT CATEGORY
    case FETCH_PRODUCTS_CATEGORIES:
      draft.ingredientsWarehouse.data.products.options.categories.data = payload
        .filter(({ enable }) => enable)
        .map(({ id, name }) => ({
          value: parseInt(id),
          label: htmlSpecialCharacters(name),
        }));
      break;

    case SET_PRODUCTS_PAGE:
      draft[[draft.tab.path]].data.products.pagination[[payload.name]] =
        payload.value;
      break;

    case ADD_ITEMS_TO_WAREHOUSE_FROM_PRODUCTS:
      draft.ingredientsWarehouse.data.warehouseItems.productItemIds.push(
        payload
      );
      break;

    case REMOVE_PRODUCT_ITEMS_FROM_WAREHOUSE:
      const productIndex =
        draft.ingredientsWarehouse.data.warehouseItems.productItemIds.findIndex(
          (item) => item.id === payload
        );
      if (productIndex !== -1)
        draft.ingredientsWarehouse.data.warehouseItems.productItemIds.splice(
          productIndex,
          1
        );
      break;

    case ADD_MULTI_ITEMS_TO_WAREHOUSE_FROM_PRODUCTS:
      draft.ingredientsWarehouse.data.warehouseItems.productItemIds.push(
        ...payload
      );
      break;

    case UPDATE_WAREHOUSE_TOTAL_COUNT:
      const warehouseIndex =
        draft.ingredientsWarehouse.data.master.data.findIndex(
          (warehouse) => warehouse.id === payload.id
        );
      if (warehouseIndex !== -1) {
        draft.ingredientsWarehouse.data.master.data[
          warehouseIndex
        ].total_count = payload.totalWarehouseCount;
      }

      break;

    case CLOSE_WAREHOUSE_ITEMS:
      draft[[draft.tab.path]].data.warehouseItems =
        INITIAL_STATE[[draft.tab.path]].data.warehouseItems;
      draft[[draft.tab.path]].data.catalog =
        INITIAL_STATE[[draft.tab.path]].data.catalog;
      break;

    case CLOSE_WAREHOUSE_INFO:
      draft[[draft.tab.path]].data.warehouseInfo =
        INITIAL_STATE[[draft.tab.path]].data.warehouseInfo;
      break;

    //  =========== PRODUCTS WAREHOUSE CRUD SECTION START ===========

    // PRODUCTS WAREHOUSES MASTER LOADING
    case START_LOADING_MASTER_PRODUCTS_WAREHOUSES:
    case END_LOADING_MASTER_PRODUCTS_WAREHOUSES:
      draft.productsWarehouse.data.master.isLoading = payload;
      break;

    // WAREHOUSE MASTER
    case FETCH_PRODUCTS_WAREHOUSES:
      let productWarehousesArray;
      let productWarehousesPaginationData;
      if (Array.isArray(payload)) {
        productWarehousesArray = payload;
        productWarehousesPaginationData = {
          total: productWarehousesArray.length,
          current_page: 0,
          per_page: 10,
        };
      } else {
        const { total, current_page, per_page, data } = payload;
        productWarehousesArray = data;
        productWarehousesPaginationData = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
      }

      draft.productsWarehouse.data.master.data = productWarehousesArray;

      draft[[draft.tab.path]].pagination = productWarehousesPaginationData;

      break;

    // WAREHOUSE DETAILS
    // WAREHOUSE CREATE (BUTTON)
    // case EDIT_WAREHOUSE:
    case CREATE_NEW_PRODUCTS_WAREHOUSE:
      draft.productsWarehouse.data.master.freeSearch.data = [];
      draft.productsWarehouse.data.details.data = payload;
      break;

    default:
      break;
  }
}, INITIAL_STATE);
