import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Axios from "axios";
import { fetchEmployeesBySendingId } from "../../../redux/actions/scheduleAction/index";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import { createElement, useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { Context } from "../Show";
import {
  PARTS,
  PART_EMPLOYEE_NAME,
  PART_WHEN_SUMMONS_DATE,
  PART_HOUR_SUMMON,
  PART_TYPE_IDENTIFER,
  PART_OTHER_PARTICIPANTS,
  PART_MANAGER_WORDS,
  PART_WORKERS_WORDS,
  PART_OTHER_WORDS,
  PART_SUM_WORDS,
  PART_DESICSION_DATE,
  PART_SUM_FORM,
  PART_CONCLUSIONS,
  PART_TERMINATION_DESICSION,
} from "./consts";
import React from "react";
import { dateReqFormat } from "../../../helpers";
import { ControlSignaturePad } from "../../../components/Forms/inputs/SignaturPad";
const SummonsForm = () => {
  const { parts, user, selectedBranch, procedure } = useContext(Context);
  const { control, setValue, watch, getValues } = useFormContext();
  const [emails, setEmails] = useState([]);
  const { replace } = useFieldArray({ control, name: "parts" });
  const worker_signature = useWatch({ control, name: "worker_signature" });
  const manager_signature = useWatch({ control, name: "manager_signature" });
  const branchEmployees = useSelector((state) => state.employees);
  const currentBranch = useSelector((state) => state.branches.selected_branch);
  const { branches } = useSelector((state) => state);
  const { procedures } = useSelector((state) => state.manageProcedures);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [participants, setParticipants] = useState("");
  const [managerWords, setManagerWords] = useState("");
  const [workerWords, setWorkerWords] = useState("");
  const [otherWords, setOtherWords] = useState("");
  const [sumWords, setSumWords] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [summonDate, setSummonDate] = useState("");
  const [desicionDate, setDesicionDate] = useState("");
  const [department, setDepartment] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [employee_name, date] = watch([
    `parts.${PART_EMPLOYEE_NAME}.value`,
    `parts.${PART_WHEN_SUMMONS_DATE}.value`,
  ]);
  const [employeeCompanyName, setEmployeeCompanyName] = useState(" ");

  const [selectedTime, setSelectedTime] = useState(null);
  const [procedureBranches, setProcedureBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState(null);

  const sumConstOptions = [
    { id: 1, label: "פיטורים עם ימי הודעה מוקדמת" },
    { id: 2, label: "פיטורים מידיים" },
    { id: 3, label: "ניסיון נוסף" },
  ];

  function getDayInHebrew(dateString) {
    const weekdaysInHebrew = [
      "יום ראשון",
      "יום שני",
      "יום שלישי",
      "יום רביעי",
      "יום חמישי",
      "יום שישי",
      "שבת ",
    ];

    const date = new Date(dateString);
    const options = { weekday: "long" };
    const dayOfWeek = date.toLocaleString("he-IL", options);

    return weekdaysInHebrew.includes(dayOfWeek) ? dayOfWeek : null;
  }

  function formatWithNewlines(inputString) {
    const formattedSections = inputString
      .split("\n")
      .filter((section) => section.trim() !== "") // Remove empty sections
      .map((section, index) => {
        const number = index + 1;
        const hebrewCharacters = section.replace(/^\d+\./, "").trim(); // Remove the number and period from the beginning
        return `${number}. ${hebrewCharacters}`;
      });

    return formattedSections.map((section, index) => (
      <p key={index}>{section}</p>
    ));
  }

  function getTimeStringFromDate(date) {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    return date.toLocaleTimeString("en-US", options);
  }

  useEffect(() => {
    const updatedBranches = branches.branches.map((branch) => ({
      id: branch.id,
      label: branch.name,
    }));
    setProcedureBranches(updatedBranches);
  }, []);

  const handleEmployeeChange = async (e, id, index) => {
    const identifierId = parts.find(
      (item) => item.type === PART_TYPE_IDENTIFER
    );
    setValue(`parts.${index}`, { id, value: e.label });
    setValue("employee_id", e.id);

    if (identifierId) {
      setValue(`parts.${PART_TYPE_IDENTIFER}`, {
        id: identifierId.id,
        value: e.identifier,
      });
      setIdentifier(e.identifier);
      setDepartment(e.department.label);
      setEmployeeCompanyName(e.company_name);

      const myData = await fetchSummonsForm(e);
      if (myData.data) {
        const whenSummonId = parts.find(
          (item) => item.type === PART_WHEN_SUMMONS_DATE
        );
        const participantsId = parts.find(
          (item) => item.type === PART_OTHER_PARTICIPANTS
        );

        const whenHourId = parts.find((item) => item.type === PART_HOUR_SUMMON);

        const summonReason = parts.find(
          (item) => item.type === PART_MANAGER_WORDS
        );

        setValue(`parts.${PART_WHEN_SUMMONS_DATE}`, {
          id: whenSummonId.id,
          value: myData.data.summon_date,
        });

        setValue(`parts.${PART_OTHER_PARTICIPANTS}`, {
          id: participantsId.id,
          value: myData.data.summon_participants,
        });

        setValue(`parts.${PART_HOUR_SUMMON}`, {
          id: whenHourId.id,
          value: myData.data.summon_hour,
        });

        setValue(`parts.${PART_MANAGER_WORDS}`, {
          id: summonReason.id,
          value: myData.data.summon_reason,
        });
        setManagerWords(myData.data.summon_reason);
        setSummonDate(myData.data.summon_date);
        setParticipants(myData.data.summon_participants);
        setSelectedTime(myData.data.summon_hour);
      }
    }
  };
  const fetchSummonsForm = async (user) => {
    const proceduresURI = "procedures";
    const getEmployeeSumonsForm = "employeeSummonForm";
    try {
      const { data } = await Axios.get(
        `${proceduresURI}/${getEmployeeSumonsForm}?userId=${user.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return data;
    } catch (err) {
      // dispatch(error(err));
    }
  };

  const debouncedData = debounce(
    (e, id, index) => {
      if (index == PART_HOUR_SUMMON) {
        setValue(`parts.${index}`, { id, value: e });
      } else if (index == PART_TERMINATION_DESICSION) {
        setValue(`parts.${index}`, { id, value: e.label });
      } else {
        setValue(`parts.${index}`, { id, value: e.value });
        switch (e.name) {
          case `parts.${PART_OTHER_PARTICIPANTS}.value`:
            setParticipants(e.value);
            break;
          case `parts.${PART_TYPE_IDENTIFER}.value`:
            setIdentifier(e.value);
            break;
          case `parts.${PART_WHEN_SUMMONS_DATE}.value`:
            setSummonDate(e.value);
            break;
          case `parts.${PART_MANAGER_WORDS}.value`:
            setManagerWords(e.value);
            break;
          case `parts.${PART_WORKERS_WORDS}.value`:
            setWorkerWords(e.value);
            break;
          case `parts.${PART_OTHER_WORDS}.value`:
            setOtherWords(e.value);
            break;
          case `parts.${PART_SUM_WORDS}.value`:
            setSumWords(e.value);
            break;
          case `parts.${PART_DESICSION_DATE}.value`:
            setDesicionDate(e.value);
            break;
          default:
            return;
        }
      }
    },
    [100]
  );
  const validateWordCount = (value, maxChars) => {
    const trimmedValue = value ? value.trim() : "";

    return (
      trimmedValue.length <= maxChars ||
      `אפשר עד מקסימום ${maxChars} תווים בשדה זה.`
    );
  };

  const memoEmployess = useMemo(() => {
    return Object.entries(branchEmployees).map(([key, value]) => {
      return {
        id: key,
        label: value.full_name + ", " + value.department_name,
        name: value.full_name,
        identifier: value.employee_ID_Number,
        department: { id: value.department_id, label: value.department_name },
        salary: value.employee_base_sal,
        company_name: value.company_name,
      };
    });
  }, [branchEmployees]);
  const handleBranchChange = (e, id, index) => {
    setBranchSelected(e.label);
    dispatch(fetchEmployeesBySendingId(e.id));
    const selectedBranch = branches.branches.find(
      (eachBranch) => eachBranch.name === e.label
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: isTablet ? 0 : 50,
        justifyContent: "center",
        gap: "3rem",
      }}
    >
      <div
        style={{
          margin: isTablet ? 0 : 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "3rem",
          fontSize: isMobile ? "1.8rem" : "2rem",
          maxWidth: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography>שם סניף</Typography>
            <ControlAutoComplete
              {...{
                fullWidth: true,
                name: `branch`,
                rules: { required: REQUIRED_MESSAGE },
                options: procedureBranches,
                handleInputChange: (value) => {
                  handleBranchChange(value);
                },
              }}
            />
          </Grid>
          {parts?.map(({ label, value, type, ...part }, index) => {
            switch (type) {
              case PART_EMPLOYEE_NAME:
                return (
                  <Grid item xs={6}>
                    <Typography>שם העובד</Typography>
                    <ControlAutoComplete
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        options: memoEmployess,
                        handleInputChange: (value) =>
                          handleEmployeeChange(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_TYPE_IDENTIFER:
                return (
                  <Grid item xs={6}>
                    <Typography>תעודת זהות</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        id: part.id,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_WHEN_SUMMONS_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography> תאריך שימוע</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        min: dateReqFormat(
                          new Date(new Date().setDate(new Date().getDate()))
                        ),
                      }}
                    />
                  </Grid>
                );
              case PART_HOUR_SUMMON:
                return (
                  <Grid item xs={6}>
                    <Typography>שעת השימוע</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        format="HH:MM"
                        ampm={false}
                        value={selectedTime}
                        onChange={(value) => {
                          setSelectedTime(value);
                          debouncedData(value, part.id, type);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                );

              case PART_OTHER_PARTICIPANTS:
                return (
                  <Grid item xs={6}>
                    <Typography>נוכחים בשימוע</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 1,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_MANAGER_WORDS:
                return (
                  <Grid item xs={6}>
                    <Typography>
                      דברי המנהל- החלטנו לזמן אותך לשימוע בעקבות (סיבת הזימון
                      לשימוע, הנימוקים לפיטורים):{" "}
                    </Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 4,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_WORKERS_WORDS:
                return (
                  <Grid item xs={6}>
                    <Typography>טענת העובד\ת בתגובה:</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 4,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_OTHER_WORDS:
                return (
                  <Grid item xs={6}>
                    <Typography>תמצית שיחה חופשית:</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 4,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );

              case PART_SUM_WORDS:
                return (
                  <Grid item xs={6}>
                    <Typography>
                      דברי סיכום- יש להודיע לעובד/ת כי דבריו/ה יישקלו וכי החלטה
                      סופית תימסר לו/לה בהמשך:
                    </Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 4,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_DESICSION_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography> תאריך קבלת החלטה</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        min: dateReqFormat(
                          new Date(new Date().setDate(new Date().getDate()))
                        ),
                      }}
                    />
                  </Grid>
                );
            }
          })}
          <Grid item xs={6.1}>
            <Typography>חתימת עובד</Typography>
            <ControlSignaturePad
              {...{
                name: "worker_signature",
                rules: { required: REQUIRED_MESSAGE },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>חתימת מנהל</Typography>
            <ControlSignaturePad
              {...{
                name: "manager_signature",
                rules: { required: REQUIRED_MESSAGE },
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />

        <div>
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <p>
              תאריך : {moment(new Date()).format("DD.MM.YYYY")} <span> </span>{" "}
            </p>
          </div>
          <h3>פרוטוקול שימוע לעובד לפני פיטורין</h3>
          <p>
            {" "}
            <strong>שם העובד</strong>:{" "}
            {employee_name ? employee_name.split(",")[0] : ""}
          </p>
          <p>
            <strong>ת.ז</strong>: {identifier}
          </p>
          <p>
            <strong>בתאריך: </strong>
            {summonDate
              ? new Date(summonDate).toLocaleDateString("en-GB")
              : ""}{" "}
            {getDayInHebrew(summonDate)} בשעה:{" "}
            {getTimeStringFromDate(new Date(selectedTime))}
          </p>
          <p>
            <strong>נוכחים: </strong>
            {participants}
          </p>
        </div>
        <p>
          {" "}
          <strong>דברי מנהל: </strong> {managerWords}
        </p>
        <p>
          <strong>טענות העובד/ת בתגובה: </strong> {workerWords}
        </p>
        <p>
          <strong>תמצית שיחה חופשית: </strong> {otherWords}
        </p>
        <p>
          <strong>דברי סיכום: </strong> {sumWords}
        </p>
        <p>
          <strong> הודעה לעובד/ת על ההחלטה תימסר ביום: </strong>
          {desicionDate
            ? new Date(desicionDate).toLocaleDateString("en-GB")
            : ""}{" "}
        </p>
        <br></br>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div class="signature-container">
            <p>חתימת העובד</p>
            <img
              src={worker_signature ? worker_signature : ""}
              class="signature-image"
            />
            <span class="signature-underline"></span>
          </div>

          <div class="signature-container">
            <p>חתימת מנהל</p>
            <img
              src={manager_signature ? manager_signature : ""}
              class="signature-image"
            />
            <span class="signature-underline"></span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <div>
            <p>בברכה,</p>
          </div>
          <div>
            <p> {user.full_name}</p>
          </div>
          <div>
            {employeeCompanyName}, {branchSelected}
          </div>
        </div>
        <Divider style={{ width: "100%" }} />

        <h3>
          דיון פנימי - {employee_name ? employee_name.split(",")[0] : ""}, תז:{" "}
          {identifier}, מחלקת {department}, {currentBranch.company_name},{" "}
          {branchSelected}
        </h3>
        {parts?.map(({ label, value, type, ...part }, index) => {
          switch (type) {
            case PART_SUM_FORM:
              return (
                <Grid item xs={6}>
                  <Typography>סיכום</Typography>
                  <FormTextField
                    {...{
                      fullWidth: true,
                      name: `parts.${type}.value`,
                      multiline: true,
                      rows: 4,
                      handleChange: (value) =>
                        debouncedData(value, part.id, type),
                      rules: {
                        validate: (value) => validateWordCount(value, 100),
                        required: REQUIRED_MESSAGE,
                      },
                    }}
                  />
                </Grid>
              );
            case PART_CONCLUSIONS:
              return (
                <Grid item xs={6}>
                  <Typography>מסקנות:</Typography>
                  <FormTextField
                    {...{
                      fullWidth: true,
                      name: `parts.${type}.value`,
                      multiline: true,
                      rows: 4,
                      handleChange: (value) =>
                        debouncedData(value, part.id, type),
                      rules: {
                        validate: (value) => validateWordCount(value, 100),
                        required: REQUIRED_MESSAGE,
                      },
                    }}
                  />
                </Grid>
              );
            case PART_TERMINATION_DESICSION:
              return (
                <Grid item xs={6}>
                  <Typography>החלטה</Typography>
                  <ControlAutoComplete
                    {...{
                      fullWidth: true,
                      name: `decision`,
                      rules: { required: REQUIRED_MESSAGE },
                      options: sumConstOptions,
                      handleInputChange: (value) => {
                        debouncedData(value, part.id, type);
                      },
                    }}
                  />
                </Grid>
              );
          }
        })}
        <p>
          <strong>שם מלא מקבל ההחלטה:</strong> {user.full_name}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <div>
            <p>בברכה,</p>
          </div>
          <div>
            <p> {user.full_name}</p>
          </div>
          <div>
            {employeeCompanyName}, {branchSelected}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummonsForm;
