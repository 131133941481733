import React from "react";
import { Translate } from "react-redux-i18n";

const LoginButton = ({ className }) => {
  return (
    <button className={`btn ${className}`} type="submit" >
      <Translate value="button.login" />
    </button>
  );
};

export default LoginButton;
