import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { useStyles } from "../../../styles/DaysTable";

const DaysTable = () => {
  const daysOfWeek = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={{ overflowX: "auto" }}>
          <Table>
            <TableBody>
              <TableRow>
                {daysOfWeek.map((day) => (
                  <TableCell size={"medium"} align="center" key={day}>
                    {day}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow className={classes.tableCell}>
                {daysOfWeek.map((day, index) => (
                  <TableCell size="medium" key={day}>
                    <FormTextField
                      {...{
                        size: "small",
                        fullWidth: true,
                        variant: "outlined",
                        name: `hours_structure.day_length_by_day.${index}`,
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Grid>
    </Grid>
  );
};

export default DaysTable;
