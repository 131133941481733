import Axios from "axios";
import { omit } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../redux/actions/errorAction";
import { startLoading, stopLoading } from "../redux/actions/loaderAction";
import { logout } from "../redux/actions/loginAction";

const sets = {
  high: {
    hd: 12,
    md: 6,
    ld: 3,
  },
  mid: {
    hd: 6,
    md: 3,
    ld: 2,
  },
  low: {
    hd: 4,
    md: 2,
    ld: 1,
  },
};

const formatDateToMoment = (date) =>
  moment([date.getFullYear(), date.getMonth(), date.getDate()]);

export const useDateFetcher = (
  path,
  selectedDates,
  setData,
  setParam = "low"
) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const startDate = formatDateToMoment(selectedDates.startDate);
  const endDate = formatDateToMoment(selectedDates.endDate);

  //   const [data, setData] = useState([]);
  const [isQuerying, setIsQuerying] = useState(false);

  const getDaysArray = (scale, delimit) => {
    const dates = [];
    const tuples = [];

    for (let m = startDate; m.isSameOrBefore(endDate); m.add(delimit, scale))
      dates.push(m.format("YYYY-MM-DD"));
    dates[dates.length - 1] = endDate.format("YYYY-MM-DD");
    for (let date = 0; date < dates.length - 1; date++) {
      if (date == 0)
        tuples[0] = [
          moment(dates[date]).format("YYYY-MM-DD"),
          moment(dates[date + 1]).format("YYYY-MM-DD"),
        ];
      else {
        const nd = moment(dates[date]).add(1, "days").format("YYYY-MM-DD");
        tuples[date] = [nd, moment(dates[date + 1]).format("YYYY-MM-DD")];
      }
    }

    return tuples;
  };

  const datesDiff = () => {
    const days = startDate.diff(endDate, "days", true);
    const months = startDate.diff(endDate, "months", true);
    const years = startDate.diff(endDate, "years", true);

    let del = 1;
    if (years < -1) {
      del = Math.floor((months * -1) / sets[setParam].hd);
      return getDaysArray("months", del);
    } else if (months < -2) {
      del = Math.floor((months * -1) / sets[setParam].md);
      return getDaysArray("months", del);
    } else if (days < -1) {
      del =
        days < -12
          ? Math.floor((days * -1) / sets[setParam].hd)
          : days < -5
          ? sets[setParam].md
          : sets[setParam].ld;

      return getDaysArray("days", del);
    }
  };

  const queryCreator = (date, params) => {
    const [startDate, endDate] = date;
    const isFixDate = params?.fixDate !== undefined && params.fixDate;

    const indSd = isFixDate ? "start_date" : "startDate";
    const indEd = isFixDate ? "end_date" : "endDate";
    const fixedSigSd = isFixDate ? " 00:00:00" : "";
    const fixedSiged = isFixDate ? " 23:59:59" : "";

    return (
      path +
      "?" +
      indSd +
      "=" +
      startDate +
      fixedSigSd +
      "&" +
      indEd +
      "=" +
      endDate +
      fixedSiged
    );
  };

  const fetch = async (query, paramsData) => {
    const params = omit(paramsData, "fixDate");
    const { data } = await Axios.get(query, {
      params: {
        ...params,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  };

  /**
   * @pparamsData requires branches array
   * @arrangeFn sums all the small pieces of data according to the business logic.
   * the server capable of fetching multiple branches in one request but if the requested data is too large (many branches, wide date range) it timesout
   * therefor this function let's you send small requests per branch instead of a big one.
   */
  const getDataSplittedByBranch = async (
    paramsData,
    arrangeFn,
    branchesParam = "branches",
    branchesPerCall = 1
  ) => {
    setData([]);

    try {
      if (!Array.isArray(paramsData[branchesParam]))
        throw new Error("branches array must be provided");

      setIsQuerying(true);

      const branchChunks = [];
      for (
        let i = 0;
        i < paramsData[branchesParam].length;
        i += branchesPerCall
      ) {
        branchChunks.push(
          paramsData[branchesParam].slice(i, i + branchesPerCall)
        );
      }

      const requestsByBranch = branchChunks.map((branchIds) => {
        const filteredParamsData = {
          ...paramsData,
          [branchesParam]: branchIds,
        };

        const query = queryCreator(
          [
            moment(startDate).format("YYYY-MM-DD"),
            moment(endDate).format("YYYY-MM-DD"),
          ],
          filteredParamsData
        );

        return Axios.get(query, {
          params: omit(filteredParamsData, "fixDate"),
          headers: { Authorization: `Bearer ${token}` },
        });
      });

      const responses = await Axios.all(requestsByBranch);

      setData(arrangeFn(responses));
    } catch (e) {
      console.error(e);
      if (e.response)
        switch (e.response.status) {
          case 401:
            logout();
            break;
          case 500:
            dispatch(setError("", "ארעה שגיאה בשרת"));
            break;
          default:
            dispatch(setError(e.response.data?.message, "ארעה שגיאה"));
            break;
        }
    } finally {
      setIsQuerying(false);
    }
  };

  const getSingleDateData = async (paramsData) => {
    setData([]);
    try {
      setIsQuerying(true);

      dispatch(startLoading("טוען נתונים מהשרת, עלול לקחת עד דקה..."));

      const query = queryCreator(
        [
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD"),
        ],
        paramsData
      );

      const data = Object.values(await fetch(query, paramsData));

      setData(data);

      setIsQuerying(false);
    } catch (e) {
      setIsQuerying(false);
      console.error(e);
      if (e.response)
        switch (e.response.status) {
          case 401:
            logout();
            break;
          case 500:
            dispatch(setError("", "ארעה שגיאה בשרת"));
            break;
          default:
            dispatch(setError(e.response.data?.message, "ארעה שגיאה"));
            break;
        }
    }
    dispatch(stopLoading());
  };

  const getMultiDateData = async (paramsData) => {
    setData([]);

    try {
      setIsQuerying(true);
      const tuples = datesDiff();
      for (const date of tuples) {
        const query = queryCreator(date, paramsData);
        const data = await fetch(query, paramsData);
        setData((oldArray) => [...oldArray, ...Object.values(data)]);
      }
      setIsQuerying(false);
    } catch (e) {
      setIsQuerying(false);
      console.error(e);
      if (e.response)
        switch (e.response.status) {
          case 401:
            logout();
            break;
          case 500:
            dispatch(setError("", "ארעה שגיאה בשרת"));
            break;
          default:
            dispatch(setError(e.response.data?.message, "ארעה שגיאה"));
            break;
        }
    }
  };

  const api = {
    getSingleDateData,
    getMultiDateData,
    getDataSplittedByBranch,
  };

  return [isQuerying, api];
};
