import React, { useEffect } from "react";
import he from "date-fns/locale/he";
// import { hebStaticRanges } from "./const";
import { observer } from "mobx-react";
import { useStore } from "../../../stores/setupContext";
import DefinedRange from "../date-range-picker/DefinedRange";
import { hebStaticRanges } from "../const";
import DateRangePicker from "../date-range-picker/DateRangePicker";
import Dropdown from "../../dropdown/Dropdown";
import { useScreenSize } from "../../../Hooks";

const MobxDateRangePicker = ({
  dateRange,
  handleChange,
  setShow,
  maxDate,
}) => {
  const {isMobile} = useScreenSize()
  return (
    <Dropdown
      style={
        isMobile
          ? {
              minWidth: "100%",
              fontSize: "1.4rem",
              display: "block",
              margin: "0",
            }
          : {
              maxWidth: "16rem",
              fontSize: "1.4rem",
              display: "block",
            }
      }
      contentStyle={{ width: "fit-content" }}
      text={dateRange.label}
    >
      {isMobile ? (
        <DefinedRange
          ranges={[dateRange]}
          staticRanges={hebStaticRanges}
          local={he}
          onChange={(item) => handleChange(item.selection)}
        />
      ) : (
        <DateRangePicker
          onChange={(item) => handleChange([item.selection])}
          showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          months={2}
          disabledDates={[]}
          ranges={[dateRange]}
          direction="horizontal"
          locale={he}
          staticRanges={hebStaticRanges}
          maxDate={maxDate}
        />
      )}
    </Dropdown>
  );
};

const Observed = observer(MobxDateRangePicker);

const WithStoreConnection = ({ defaultValue, setShow, maxDate }) => {
  const { dateRangePicker, main } = useStore();

  const { dateRange, handleChange, initDefaultValues } = dateRangePicker;
  const { isMobile } = main;

  useEffect(() => {
    initDefaultValues(defaultValue);
  }, [initDefaultValues]);

  return (
    <Observed
      {...{
        dateRange,
        handleChange,
        setShow,
        maxDate,
        isMobile,
      }}
    />
  );
};

export default observer((props) => WithStoreConnection(props));
