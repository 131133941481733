import { isEmpty, isObject, mapKeys, isUndefined } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-responsive-modal";
import DayPickerControlled from "../../../components/datepicker/DayPicker";
import Dropdown from "../../../components/dropdown/Dropdown";
import { dateReqFormat, fixDate, getSafe } from "../../../helpers";
import Button from "../../../components/buttons/Button";
import DropdownSelect from "../../../components/dropdown/DropdownSelect";
import { TYPES } from "./HolidayView";

const INITIAL_FORM_STATE = {
  name: "",
  type: 1,
};
const EditHolidayModal = ({
  open,
  defaultFormValues = INITIAL_FORM_STATE,
  hideModal,
  createHoliday,
  updateHoliday,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues(defaultFormValues);
  }, [defaultFormValues, open]);

  const validate = ({ start_date, end_date, name }) => {
    const errors = {};
    if (!name) errors.name = "הזן שם חג";

    if (!start_date) errors.start_date = "חובה לבחור תאריך התחלה";
    if (!end_date) errors.end_date = "חובה לבחור תאריך סיום";

    setFormErrors(errors);
    if (!isEmpty(errors)) return false;
    else return true;
  };
  const handleConfirm = () => {
    if (validate(formValues)) {
      if (formValues.id) {
        updateHoliday(formValues.id, formValues);
      } else {
        createHoliday(formValues);
      }
      hideModal();
    }
  };

  const renderError = (name) => {
    if (!isUndefined(formErrors[name]))
      return (
        <p style={{ width: "100%", textAlign: "center" }}>
          <span className="error-msg">{formErrors[name]}</span>{" "}
        </p>
      );
  };

  const handleFormChange = useMemo(
    () => (value) => {
      if (isObject(value)) {
        const key = Object.keys(value)[0];
        if (formErrors[key]) {
          setFormErrors((prev) => ({ ...prev, [key]: undefined }));
        }
      }
      setFormValues((prev) => ({ ...prev, ...{ ...value } }));
    },
    [formErrors]
  );

  return (
    <Modal
      open={open}
      closeOnOverlayClick={false}
      classNames={{ modal: "dialog-modal" }}
      styles={{
        modal: {
          maxHeight: "unset",
          textAlign: "center",
          padding: "2.5rem",
        },
      }}
      center
      onClose={() => {
        hideModal();
        setFormValues(INITIAL_FORM_STATE);
      }}
      animationDuration={200}
    >
      <h2>{formValues.id ? "ערוך חג" : "צור חג חדש"}</h2>
      <div className="u-right-text">
        <label>סוג</label>
        <DropdownSelect
          className="full-width"
          setSelected={(selected) => handleFormChange({ type: selected.id })}
          selected={TYPES[formValues.type - 1] ?? TYPES[0]}
          options={TYPES}
        />
        <label>שם</label>
        <input
          type="text"
          value={formValues.name}
          onChange={(e) => handleFormChange({ name: e.target.value })}
        />
        {renderError("name")}
        <label>תאריך התחלה</label>
        <Dropdown
          isDate={true}
          style={{ margin: 0 }}
          text={`${
            formValues.start_date ? fixDate(formValues.start_date) : "בחר תאריך"
          }`}
        >
          <DayPickerControlled
            setDate={(start_date) =>
              handleFormChange({ start_date: dateReqFormat(start_date) })
            }
            afterDate={
              formValues.end_date ? new Date(formValues.end_date) : undefined
            }
          />
        </Dropdown>
        {renderError("start_date")}
        <label>תאריך סיום</label>
        <Dropdown
          isDate={true}
          style={{ margin: 0 }}
          disabled={!formValues.start_date}
          text={`${
            formValues.end_date ? fixDate(formValues.end_date) : "בחר תאריך"
          }`}
        >
          <DayPickerControlled
            initialMonth={
              formValues.start_date
                ? new Date(formValues.start_date)
                : undefined
            }
            setDate={(end_date) =>
              handleFormChange({ end_date: dateReqFormat(end_date) })
            }
            beforeDate={new Date(formValues.start_date)}
          />
        </Dropdown>
        {renderError("end_date")}
      </div>

      <div
        style={{ width: "fit-content", margin: "0 auto", marginTop: "2rem" }}
        className="flex"
      >
        <Button
          onClick={() => {
            hideModal();
            setFormValues(INITIAL_FORM_STATE);
          }}
          className={`btn--neutral`}
          textValue="ביטול"
        />
        <Button
          onClick={handleConfirm}
          className={`btn--submit `}
          textValue={formValues.id ? "שמור" : "שלח"}
        />
      </div>
    </Modal>
  );
};

export default EditHolidayModal;
