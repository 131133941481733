// GENERAL
export const SET_TAB = "goods/SET_TAB";
export const CLOSE_FORM = "goods/CLOSE_FORM";
export const SET_DID_CATEGORY_CHANGE = "items/DID_CATEGORY_CHANGE";

// FILTERS
export const SET_FILTER = "goods/SET_FILTER";
export const RESET_FILTER = "goods/RESET_FILTER";

// LOADING
export const START_LOADING_POS_CODES = "items/START_LOADING_POS_CODES";
export const END_LOADING_POS_CODES = "items/END_LOADING_POS_CODES";

export const START_LOADING_ALLERGENS = "items/START_LOADING_ALLERGENS";
export const END_LOADING_ALLERGENS = "items/END_LOADING_ALLERGENS";

export const START_LOADING_UNIFIED_PRODUCTS =
  "unified/START_LOADING_UNIFIED_PRODUCTS";
export const END_LOADING_UNIFIED_PRODUCTS =
  "unified/END_LOADING_UNIFIED_PRODUCTS";

export const START_LOADING_MASTER_ITEMS = "items/START_LOADING_MASTER_ITEMS";
export const END_LOADING_MASTER_ITEMS = "items/END_LOADING_MASTER_ITEMS";

export const START_LOADING_ITEM_DETAILS = "items/START_LOADING_ITEM_DETAILS";
export const END_LOADING_ITEM_DETAILS = "items/END_LOADING_ITEM_DETAILS";

export const START_LOADING_DETAILS_CATEGORY =
  "categories/START_LOADING_DETAILS_CATEGORY";
export const END_LOADING_DETAILS_CATEGORY =
  "categories/END_LOADING_DETAILS_CATEGORY";

export const START_LOADING_CATALOG = "items/START_LOADING_CATALOG";
export const END_LOADING_CATALOG = "items/END_LOADING_CATALOG";

export const START_LOADING_MASTER_CATEGORY =
  "categories/START_LOADING_MASTER_CATEGORY";
export const END_LOADING_MASTER_CATEGORY =
  "categories/END_LOADING_MASTER_CATEGORY";

export const START_LOADING_SUPPLIERS = "items/START_LOADING_SUPPLIERS";
export const END_LOADING_SUPPLIERS = "items/END_LOADING_SUPPLIERS";

export const START_LOADING_FREE_SEARCH = "items/START_LOADING_FREE_SEARCH";
export const END_LOADING_FREE_SEARCH = "items/END_LOADING_FREE_SEARCH";

export const START_LOADING_PRODUCTS = "items/START_LOADING_PRODUCTS";
export const END_LOADING_PRODUCTS = "items/END_LOADING_PRODUCTS";

export const START_LOADING_SWAP_INGREDIENT =
  "items/START_LOADING_SWAP_INGREDIENT";
export const END_LOADING_SWAP_INGREDIENT = "items/END_LOADING_SWAP_INGREDIENT";

export const START_LOADING_PRICE_GAPS = "price_gaps/START_LOADING_PRICE_GAPS";
export const END_LOADING_PRICE_GAPS = "price_gaps/END_LOADING_PRICE_GAPS";

// FETCH
export const FETCH_POS_CODES = "items/FETCH_POS_CODES";
export const FETCH_ALLERGENS = "items/FETCH_ALLERGENS";
export const FETCH_UNIFIED_PRODUCTS = "unified/FETCH_UNIFIED_PRODUCTS";
export const FETCH_ITEMS = "items/FETCH_ITEMS";
export const FETCH_CATALOG = "items/FETCH_CATALOG";
export const FETCH_CATEGORIES = "items/FETCH_CATEGORIES";
export const FETCH_SUPPLIERS = "items/FETCH_SUPPLIERS";
export const FETCH_ITEMS_FREE_SEARCH = "items/FETCH_ITEMS_FREE_SEARCH";
export const FETCH_PRICE_GAPS = "price_gaps/FETCH_PRICE_GAPS";

// CREATE NEW
export const CREATE_NEW_ITEM = "items/CREATE_NEW_ITEM";
export const CREATE_NEW_CATEGORY = "categories/CREATE_NEW_CATEGORY";

// STORE
export const STORE_ITEM = "items/STORE_ITEM";
export const STORE_CATEGORY = "categories/STORE_CATEGORY";

// EDIT
export const EDIT_ITEM = "items/EDIT_ITEM";
export const EDIT_CATEGORY = "categories/EDIT_CATEGORY";

// TOGGLE
export const TOGGLE_ITEM = "items/TOGGLE_ITEM";
export const TOGGLE_CATEGORY = "categories/TOGGLE_CATEGORY";

// UPDATE
export const UPDATE_ITEM = "items/UPDATE_ITEM";
export const UPDATE_CATEGORY = "categories/UPDATE_CATEGORY";

// PAGINATION
export const SET_PAGE = "items/SET_PAGE";

// SET
export const SET_ITEMS_PAGINATION = "items/SET_ITEMS_PAGINATION";
export const SET_SUPPLIERS_OPTIONS = "items/SET_SUPPLIERS_OPTIONS";
export const SET_ITEMS_PAGE = "items/SET_ITEMS_PAGE";
export const SET_CATALOG = "items/SET_CATALOG";
export const SET_PRODUCT = "items/SET_PRODUCT";
export const SET_FREESEARCH_ID = "items/SET_FREESEARCH_ID";
export const SET_MULTI_UPLOAD_BRANCHES = "items/SET_MULTI_UPLOAD_BRANCHES";
export const SET_ITEM_ERROR = "items/SET_ITEM_ERROR";

// RESET
export const RESET_STATE = "items/RESET_STATE";
export const RESET_CATALOG = "items/RESET_CATALOG";
