import React, {
  useState,
  useEffect,
  useMemo,
  Fragment,
  useCallback,
} from "react";
import SegmentedControl from "../../components/SegmentedControl";
import { connect } from "react-redux";
import { FiClock } from "react-icons/fi";

import {
  setActive,
  setIsFrame,
  setScheduleDate,
  setTitle,
} from "../../redux/actions/publicAction";
import {
  fetchSchedule,
  fetchSubmissions,
  addSubmissionsEmployeeNew,
} from "../../redux/actions/scheduleAction";
import { debounce, isEmpty, mapKeys, omit, isEqual } from "lodash";
import {
  dateReqFormat,
  deepCompare,
  fixDate,
  fixHour,
  getDatesDiff,
  getSafe,
} from "../../helpers";
import Button from "../../components/buttons/Button";
import SubmissionsNotes from "./SubmissionsNotes";
import Skeleton from "react-loading-skeleton";
import { setError } from "../../redux/actions/errorAction";
import { addMonths, addWeeks, endOfWeek, startOfWeek } from "date-fns";
import Axios from "axios";
import OutsideAlerter, { useScreenSize } from "../../Hooks";
import { setDialogModal } from "../../redux/actions/DialogAction";
import { FaExclamationTriangle } from "react-icons/fa";
import BtnTooltip from "../../components/tooltips/BtnTooltip";
import {
  COLOR_ABORT,
  COLOR_CONFIRM,
  COLOR_RESTIGO_PRIMARY,
} from "../../const/colors";
import {
  APPROVED,
  CANCELED,
  NOT_REQUESTED,
  PENDING,
  SHIFT_REQUEST_TYPE_BY_ROLE,
  SHIFT_REQUEST_TYPE_BY_SHIFT,
} from "../../const";
import { ButtonGroup } from "../../components/buttons/ButtonGroup";
import Dropdown from "../../components/dropdown/Dropdown";
import WeekPicker from "../../components/datepicker/WeekPicker";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdSpeakerNotes,
} from "react-icons/md";
import Model from "../../components/Model";
import PopoverEllipsisButton from "../../components/popover/PopoverEllipsisButton";
import Popover from "react-popover";
import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popper,
  Select,
} from "@mui/material";
import { logout } from "../../redux/actions/loginAction";
import SimpleTable from "../orders/manageOrders/receiveOrder/SimpleTable";
import ScheduleNotAvailabel from "./ScheduleNotAvailabel";
import MultiSelect from "../../components/dropdown/MultiSelect";
import { SHIFT_REQUEST_DEFAULT_CLOSE } from "./constants";
// import { Prompt } from "react-router-dom";

const SubmissionsEmployee = ({
  setScheduleDate,
  daily_notes,
  schedule,
  option,
  week_start,
  week_end,
  week,
  shifts,
  fetchSchedule,
  user,
  fetchSubmissions,
  addSubmissionsEmployeeNew,
  selected_branch,
  name,
  setActive,
  setIsFrame,
  setError,
  submissions,
  notes,
  shifts_and_days,
  title,
  setTitle,
  token,
  setDialogModal,
  is_error_modal_open,
  shift_request_type,
  roles_and_days,
  roles,
  rolesWithDepartmentId,
  submission_required_dates,
}) => {
  const { isMobile } = useScreenSize();
  const [loader, setLoader] = useState(false);
  const [selectedSubmissions, setSelectedSubmissions] = useState({});
  const [selectedNotes, setSelectedNotes] = useState({});
  const [disableForm, setDisableForm] = useState(true);
  const [firstTouched, setFirstTouched] = useState(false);
  const [textForTooltipDays, settextForTooltipDays] = useState("");
  const [textForTooltipShifts, setTextForTooltipShifts] = useState("");
  const [employeeMinSubmissions, setEmployeeMinSubmissions] = useState({
    min_shifts: 0,
    min_weekend: 0,
  });
  const [requiredForUserFromManager, setRequiredForUserFromManager] = useState(
    {}
  );

  useMemo(async () => {
    try {
      const res = await Axios.post(`users/${user.id}/getEmployeeMinShift`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEmployeeMinSubmissions(res.data);
    } catch (e) {
      console.error(e);
    }
  }, [user, token]);

  useEffect(() => {
    setActive(name);
    setTitle(title);
    setIsFrame(false);
    // getEmployeeMinSubmissions();
  }, []);

  const addSelectedNotes = (day_num, newSelected) => {
    setSelectedNotes({
      ...selectedNotes,
      [day_num]: {
        ...getSafe(() => selectedNotes[day_num], {}),
        ...newSelected,
      },
    });
  };

  // const removeSelectedNotes = async (day_num) => {
  //   const afterRemove = await omit(selectedNotes, day_num);
  //   setSelectedNotes(afterRemove);
  // };

  const addSelectedSubmissions = (day_num, newSelected, kind_id) => {
    setSelectedSubmissions({
      ...selectedSubmissions,
      [`${newSelected.employee_id}${day_num}${kind_id}`]: {
        ...newSelected,
        is_manager: 0,
      },
    });
  };

  const removeSelectedSubmissions = async (day_num, kind_id) => {
    const afterRemove = omit(
      selectedSubmissions,
      `${user.id}${day_num}${kind_id}`
    );
    setSelectedSubmissions(afterRemove);
  };

  const handleAbort = () => {
    setFirstTouched(false);
    setSelectedNotes(init_notes_obj);
    setSelectedSubmissions(init_submissions_obj);
  };

  const disableDates = {
    beforeDate: startOfWeek(addWeeks(new Date(), 1)),
    afterDate: endOfWeek(addWeeks(new Date(), 12)),
  };

  const options = useMemo(
    () => [
      {
        text: "הקודם",
        jsx: (
          <MdOutlineArrowBackIos
            size="2em"
            color={
              new Date(week_start) < disableDates.beforeDate
                ? ""
                : COLOR_RESTIGO_PRIMARY
            }
          />
        ),
        disabled: new Date(week_start) < disableDates.beforeDate,
        onClick: () => {
          setSetScheduleDate(addWeeks(new Date(week_start), -1));
        },
      },
      {
        text: "שבוע הבא",
        onClick: () => {
          setSetScheduleDate(startOfWeek(addWeeks(new Date(), 1)));
        },
      },
      {
        text: "עוד שבועיים",
        onClick: () => {
          setSetScheduleDate(startOfWeek(addWeeks(new Date(), 2)));
        },
      },
      {
        text: "הבא",
        disabled: addWeeks(new Date(week_start), 1) >= disableDates.afterDate,
        jsx: (
          <MdOutlineArrowForwardIos
            size="2em"
            color={
              addWeeks(new Date(week_start), 1) >= disableDates.afterDate
                ? ""
                : COLOR_RESTIGO_PRIMARY
            }
          />
        ),
        onClick: () => {
          setSetScheduleDate(addWeeks(new Date(week_start), 1));
        },
      },
    ],
    [week_start, disableDates]
  );

  const beforeLeaving = () => {
    setDialogModal({
      title: "בוצעו שינויים",
      text: `נראה שלא סיימת להגיש משמרות , האם להגיש ${options[option].text} ?`,
      onConfirm: () => () => {},
      iconJSX: () => FaExclamationTriangle({ color: "#FFCE00", size: "7em" }),
      onAbort: () => handleAbort(),
      closeOnOverlayClick: false,
      showCloseIcon: false,
      btnAbortText: "אפס",
      btnConfirmText: "המשך",
      thirdButtonText: "אישור",
      btnAbortClass: "color__danger--background",
      btnConfirmClass: "color__white",
      showThirdButton: true,
      thirdButtonAction: () => handleSubmit(),
    });
  };

  const setSetScheduleDate = (date) => {
    let week = -1;
    let nextWeek = startOfWeek(addWeeks(new Date(), 1));
    let nextNextWeek = startOfWeek(addWeeks(new Date(), 2));
    // check if chosen week is the next or next next week
    if (getDatesDiff(date, nextWeek) === 0) week = 1;
    else if (getDatesDiff(date, nextNextWeek) === 0) week = 2;
    else if (getDatesDiff(date, nextNextWeek) > 1) week = 4;
    else week = -1;

    if (firstTouched && !is_error_modal_open) beforeLeaving();
    else setScheduleDate(date, week);
  };

  const init_submissions_obj = useMemo(
    () =>
      mapKeys(
        Object.values(submissions).map((x) => ({
          id: `${x.employee.id}${x.week_day}${
            shift_request_type === SHIFT_REQUEST_TYPE_BY_SHIFT
              ? getSafe(() => x.shift.id, 0)
              : x.role_id
          }`,
          employee_id: x.employee.id,
          date: x.date,
          status: x.status,
          [shift_request_type === SHIFT_REQUEST_TYPE_BY_SHIFT
            ? "shift_id"
            : "role_id"]:
            shift_request_type === SHIFT_REQUEST_TYPE_BY_SHIFT
              ? getSafe(() => x.shift.id, 0)
              : x.role_id,
          is_manager: x.is_manager,
        })),
        "id"
      ),
    [submissions, shift_request_type]
  );

  const createSchedule = (week, shifts, user) => {
    const schedule = {};

    week.forEach((day) => {
      shifts.forEach((shift) => {
        const key = `${user.id}${day.day_num}${shift.id}`;
        schedule[key] = {
          id: key,
          employee_id: user.id,
          shift_id: shift.id,
          date: day.actual_date,
          status: 1,
          is_manager: 0,
        };
      });
    });

    return schedule;
  };

  const init_notes_obj = useMemo(
    () => mapKeys(Object.values(notes), "week_day"),
    [notes]
  );

  useEffect(() => {
    if (
      isEmpty(submissions) &&
      shift_request_type === SHIFT_REQUEST_TYPE_BY_SHIFT &&
      selected_branch.shift_request_default === SHIFT_REQUEST_DEFAULT_CLOSE
    ) {
      const schedule = createSchedule(week, shifts, user);
      setSelectedSubmissions(schedule);
    } else {
      setSelectedSubmissions(init_submissions_obj);
    }
  }, [init_submissions_obj]);

  useEffect(() => {
    setSelectedNotes(init_notes_obj);
  }, [init_notes_obj]);

  const [loadOnce, setLoadOnce] = useState(true);

  useEffect(() => {
    setSetScheduleDate(startOfWeek(addWeeks(new Date(), 1)));
  }, []);

  useEffect(() => {
    if (!isEmpty(selected_branch)) {
      if (week_start) {
        if (
          week_start !== dateReqFormat(startOfWeek(addWeeks(new Date(), 1))) &&
          loadOnce
        )
          return;
        fetchScheduleDebounce(
          week_start,
          setLoader,
          (schedule_id, cloom, setLoader) =>
            fetchSubmissions(schedule_id, user.id, setLoader)
        );
      }
    }
  }, [week_start, selected_branch.id, fetchSchedule]);

  const fetchScheduleDebounce = useCallback(
    debounce((week_start, setLoader, fetchSubmissions) => {
      setLoader(true);
      fetchSchedule(week_start, 1, true, setLoader, fetchSubmissions);
      setLoadOnce(false);
    }, 500),
    []
  );

  //true = block
  const isPastLastSubmissionDateTime = useMemo(() => {
    if (schedule.last_submission) {
      return (
        new Date(
          `${schedule.last_submission.date} ${schedule.last_submission.time}`
        ) < new Date()
      );
    }
    return false;
  }, [schedule]);

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const notes = Object.values(selectedNotes);
    const submissions = Object.values(selectedSubmissions);

    if (!validateMinSubmissions(submissions)) {
      return false;
    }

    addSubmissionsEmployeeNew(submissions, notes, user.id, schedule.id);
    setFirstTouched(false);
  };

  const validateMinSubmissions = (submissions) => {
    let employeeSubmissionsByDay = getEmployeeSubmissionsByDay(submissions);
    let validate = true;

    let midWeekSubmissionsCount = getEmployeeSubmissionsMidWeekCount(
      employeeSubmissionsByDay
    );
    let weekendSubmissionsCount = getEmployeeSubmissionsWeekendCount(
      employeeSubmissionsByDay
    );

    let errorTitle = " לא ניתן להגיש. לא עברת את מינימום ההגשות הנדרש";
    let errorText = [];

    if (
      employeeMinSubmissions.min_shifts > 0 &&
      employeeMinSubmissions.min_shifts > midWeekSubmissionsCount
    ) {
      validate = false;

      if (selected_branch.network_id == "489" || selected_branch.id == "7556") {
        errorText.push(
          <p key="min_shifts">{`מינימום הגשות בימים א' - ד': ${employeeMinSubmissions.min_shifts} הגשת: ${midWeekSubmissionsCount}. `}</p>
        );
      } else {
        errorText.push(
          <p key="min_shifts">{`מינימום הגשות בימים א' - ה': ${employeeMinSubmissions.min_shifts} הגשת: ${midWeekSubmissionsCount}. `}</p>
        );
      }
    }
    if (
      employeeMinSubmissions.min_weekend > 0 &&
      employeeMinSubmissions.min_weekend > weekendSubmissionsCount
    ) {
      validate = false;
      errorText.push(
        <p key="min_weekend">{`מינימום הגשות בסוף השבוע: ${employeeMinSubmissions.min_weekend} הגשת: ${weekendSubmissionsCount}.`}</p>
      );
    }

    const allStatusZero = Object.keys(selectedNotes).every(
      // checking if some day added vacation
      (key) => selectedNotes[key].status === 0
    );
    if (submissions.length === 0 && allStatusZero) {
      // only if 0 submissions we send error
      validate = false;
      errorText.push(
        <p key="min_shifts">{`לא הוגשו משמרות כלל, יש להגיש לפחות משמרת 1 `}</p>
      );
    }

    if (!validate) {
      setError(errorText, errorTitle, false, true);
    }

    return validate;
  };

  const getEmployeeSubmissionsByDay = (submissions) => {
    let employeeSubmissionsByDay = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    };
    submissions.map((submissionEvent) => {
      let weekDay = new Date(submissionEvent.date).getDay();
      employeeSubmissionsByDay[weekDay]++;
    });
    return employeeSubmissionsByDay;
  };

  const getEmployeeSubmissionsMidWeekCount = (employeeSubmissionsByDay) => {
    let midWeekSubmissionsCount = 0;
    let midWeekDays = [];
    if (selected_branch.network_id == "489" || selected_branch.id == "7556") {
      midWeekDays = ["0", "1", "2", "3"];
    } else {
      midWeekDays = ["0", "1", "2", "3", "4"];
    }

    for (let weekDay in employeeSubmissionsByDay) {
      if (
        midWeekDays.includes(weekDay) &&
        employeeSubmissionsByDay[weekDay] > 0
      ) {
        midWeekSubmissionsCount++;
      }
    }
    return midWeekSubmissionsCount;
  };
  const getEmployeeSubmissionsWeekendCount = (employeeSubmissionsByDay) => {
    let weekendSubmissionsCount = 0;
    let weekendDays = [];

    if (selected_branch.network_id == "489" || selected_branch.id == "7556") {
      weekendDays = ["4", "5", "6"];
    } else {
      weekendDays = ["5", "6"];
    }

    for (let weekDay in employeeSubmissionsByDay) {
      if (
        weekendDays.includes(weekDay) &&
        employeeSubmissionsByDay[weekDay] > 0
      ) {
        weekendSubmissionsCount++;
      }
    }
    return weekendSubmissionsCount;
  };

  const [employeeShiftPositions, setEmployeeShiftPositions] = useState([]);
  // const getEmployeeShiftPositions =
  useMemo(async () => {
    try {
      if (shift_request_type === 1) return;
      const res = await Axios.post(
        `users/${user.id}/getEmployeeShiftPositions`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!res.data.length) {
        setError(
          "לא קיימים תפקידים, יש לפנות למנהל לצורך שיוך תפקידים מתאימים בכרטיס העובד",
          "שים לב",
          false,
          true
        );
      }
      setEmployeeShiftPositions(res.data);
    } catch (e) {
      console.error(e);
    }
  }, [user, token, shift_request_type]);

  // useEffect(() => {
  //   getEmployeeShiftPositions();
  // }, [getEmployeeShiftPositions]);

  useEffect(() => {
    if (isPastLastSubmissionDateTime) {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
  }, [schedule.last_submission, isPastLastSubmissionDateTime]);

  const printLastSubDate = useMemo(
    () => () => {
      try {
        return `תאריך אחרון להגשה: ${fixDate(
          schedule.last_submission.date
        )} בשעה ${fixHour(schedule.last_submission.time)}`;
      } catch (e) {
        return "ללא תאריך אחרון הגשה";
      }
    },
    [schedule]
  );

  const printEmployeeMinSubmissions = () => {
    let min_shifts = "";
    let min_weekend = "";

    if (employeeMinSubmissions.min_shifts > 0) {
      if (selected_branch.network_id == "489" || selected_branch.id == "7556") {
        min_shifts = `מינימום הגשות בימים א'-ד' :  ${employeeMinSubmissions.min_shifts}`;
      } else {
        min_shifts = `מינימום הגשות בימים א'-ה' :  ${employeeMinSubmissions.min_shifts}`;
      }
    }
    if (employeeMinSubmissions.min_weekend > 0) {
      min_weekend = `מינימום הגשות בסוף השבוע:  ${employeeMinSubmissions.min_weekend}`;
    }
    return (
      <div style={{ marginTop: "5px" }}>
        <p>{min_shifts}</p>
        <p>{min_weekend}</p>
      </div>
    );
  };

  useEffect(() => {
    let requiredForUser = {};
    Object.entries(submission_required_dates || {}).forEach(
      ([date, dateRequiredData]) => {
        Object.entries(dateRequiredData.shift_ids).forEach(
          ([shiftId, departmentIds]) => {
            if (departmentIds.includes(user.type_info?.id)) {
              if (!(date in requiredForUser)) {
                requiredForUser[date] = [];
              }
              requiredForUser[date].push(parseInt(shiftId));
            }
          }
        );
      }
    );
    setRequiredForUserFromManager(requiredForUser);

    const daysForWorkText = week
      .map((day) => {
        if (
          Object.keys(requiredForUser)
            .map((el) => el)
            .includes(day.actual_date)
        ) {
          return day;
        }
      })
      .filter((day) => day != undefined)
      .sort((a, b) => a.day_num - b.day_num)
      .map((day) => day.day_name)
      .join(" ,");

    const shiftsForWorkText = shifts
      .map((shift) => {
        let [requiredShifts] = Object.values(requiredForUser);
        if (requiredShifts?.includes(shift.id)) {
          return shift;
        }
      })
      .filter((shift) => shift != undefined)
      .map((shift) => shift.name)
      .join(" ,");

    settextForTooltipDays(daysForWorkText);
    setTextForTooltipShifts(shiftsForWorkText);

    if (!deepCompare(init_submissions_obj, selectedSubmissions)) {
      setFirstTouched(true);
    } else if (!deepCompare(init_notes_obj, selectedNotes)) {
      setFirstTouched(true);
    } else {
      setFirstTouched(false);
    }
  }, [
    init_submissions_obj,
    selectedSubmissions,
    init_notes_obj,
    selectedNotes,
  ]);

  const memoizedBlock = useMemo(() => {
    let block = false;

    for (let [key, value] of Object.entries(requiredForUserFromManager)) {
      if (!(key in mapKeys(selectedSubmissions, "date"))) {
        block = true;
        break;
      }

      let selecteShiftIds = Object.values(selectedSubmissions).map(
        (selected) => {
          return {
            date: selected.date,
            shift_id: selected.shift_id,
            all_shift_ids: [],
          };
        }
      );

      selecteShiftIds = selecteShiftIds.map((shift) => {
        Object.values(selectedSubmissions).map((selected) => {
          if (selected.date === shift.date) {
            shift.all_shift_ids.push(selected.shift_id);
          }
        });
        return shift;
      });
      let target = value.map((el) => parseInt(el));

      if (
        !target.every((r) =>
          mapKeys(selecteShiftIds, "date")[key]?.all_shift_ids.includes(r)
        )
      ) {
        block = true;
        break;
      }
    }
    return block;
  }, [requiredForUserFromManager, selectedSubmissions]);

  const peekStatus = (type) => {
    switch (type) {
      case NOT_REQUESTED:
        return PENDING;
      case PENDING:
        return NOT_REQUESTED;
      case APPROVED:
        return CANCELED;
      case CANCELED:
        return APPROVED;
      default:
        return PENDING;
    }
  };

  const renderTimes = useMemo(
    () => () =>
      isPastLastSubmissionDateTime ? <></> : <i className="fas fa-times" />,
    [isPastLastSubmissionDateTime]
  );

  const renderStatusButton = (type) => {
    switch (type) {
      case NOT_REQUESTED:
        return (
          <i style={{ fontSize: "16px" }} className="fas fa-umbrella-beach" />
        );
      case CANCELED:
        return (
          <>
            <i
              style={{ fontSize: "16px", color: COLOR_ABORT }}
              className="  fas fa-times"
            />
            <i
              style={{ fontSize: "16px", color: COLOR_CONFIRM }}
              className="fas fa-umbrella-beach"
            />
          </>
        );
      case PENDING:
        return (
          <>
            <i
              style={{ fontSize: "16px", color: COLOR_ABORT }}
              className="  fas fa-times"
            />
            <i style={{ fontSize: "16px" }} className="fas fa-umbrella-beach" />
          </>
        );
      case APPROVED:
        return (
          <>
            <i
              style={{ fontSize: "16px", color: COLOR_ABORT }}
              className="  fas fa-times"
            />
            <i
              style={{ fontSize: "16px", color: COLOR_ABORT }}
              className="fas fa-umbrella-beach"
            />
          </>
        );
      default:
        return (
          <i style={{ fontSize: "16px" }} className="fas fa-umbrella-beach" />
        );
    }
  };

  const renderButtonGroup = useMemo(
    () => (day, day_idx) => {
      if (shift_request_type === 1) {
        return (
          <ButtonGroup padding={12}>
            <>
              {shifts.map((shift, index) => {
                return shifts_and_days[day.day_num].includes(shift.id) ? (
                  <ButtonOption
                    requiredBtnAction={
                      submission_required_dates?.[day.actual_date]?.shift_ids[
                        shift.id
                      ]
                    }
                    key={`${day_idx}${index}`}
                    type={shift_request_type}
                    kind={shift}
                    employee={user}
                    default_selected={getSafe(
                      () =>
                        selectedSubmissions[
                          `${user.id}${day.day_num}${shift.id}`
                        ],
                      null
                    )}
                    addSelectedSubmissions={(newSubmission, kind_id) => {
                      addSelectedSubmissions(
                        day.day_num,
                        newSubmission,
                        kind_id
                      );
                    }}
                    day={day}
                    week_start={week_start}
                    abort={selectedSubmissions.length}
                    removeSelectedSubmissions={(kind_id) =>
                      removeSelectedSubmissions(day.day_num, kind_id)
                    }
                  />
                ) : (
                  <Fragment key={`${day_idx}${index}`}></Fragment>
                );
              })}
            </>
          </ButtonGroup>
        );
      } else {
        return (
          <ButtonGroup padding={12}>
            <>
              {roles.map((role, index) => {
                return roles_and_days[day.day_num].includes(role.id) &&
                  employeeShiftPositions.includes(role.id) ? (
                  <ButtonOption
                    requiredBtnActionForRolesOnly={false}
                    requiredBtnAction={
                      Object.values(
                        submission_required_dates?.[day.actual_date]
                          ?.shift_ids || {}
                      )[0]
                    }
                    key={`${day_idx}${index}`}
                    type={shift_request_type}
                    kind={role}
                    employee={user}
                    default_selected={getSafe(
                      () =>
                        selectedSubmissions[
                          `${user.id}${day.day_num}${role.id}`
                        ],
                      null
                    )}
                    addSelectedSubmissions={(newSubmission, kind_id) =>
                      addSelectedSubmissions(
                        day.day_num,
                        newSubmission,
                        kind_id
                      )
                    }
                    day={day}
                    week_start={week_start}
                    abort={selectedSubmissions.length}
                    removeSelectedSubmissions={(kind_id) =>
                      removeSelectedSubmissions(day.day_num, kind_id)
                    }
                  />
                ) : (
                  <Fragment key={`${day_idx}${index}`}></Fragment>
                );
              })}
            </>
          </ButtonGroup>
        );
      }
    },
    [
      shifts,
      shift_request_type,
      selectedSubmissions,
      user,
      week_start,
      shifts_and_days,
      roles_and_days,
      roles,
    ]
  );

  return (
    <div className="submission">
      {/* <Prompt
        message={() => {
          beforeLeaving();
          return false;
        }}
        when={firstTouched && !is_error_modal_open}
      /> */}
      <div className="flex-center">
        <h2 className="mobile--content__title">הגשת משמרות</h2>
        <BtnTooltip
          text={`נשמר לאחרונה בתאריך: ${getSafe(
            () => fixDate(submissions[0].updated_at),
            "לא נשמר"
          )}`}
        >
          {firstTouched ? (
            <i
              style={{
                marginRight: "1rem",
                fontSize: "1.5rem",
              }}
              className="fas fa-edit"
            />
          ) : (
            <i
              style={{
                marginRight: "1rem",
                fontSize: "1.5rem",
                color: "#47cc00",
              }}
              className="fas fa-check-double"
            />
          )}
        </BtnTooltip>
      </div>
      <div
        className="u-center-text"
        style={{
          lineHeight: "1.2",
          border: "1px solid black",
          marginBottom: ".5rem",
          padding: ".6rem",
          borderRadius: "2px",
        }}
      >
        <p>{printLastSubDate()}</p>
        {printEmployeeMinSubmissions()}
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div>
          <SegmentedControl
            loading={loader}
            options={options}
            selected={option}
          />
        </div>
        <Dropdown
          style={{ margin: "0.2rem 0" }}
          text={`${fixDate(week_end)} - ${fixDate(week_start)}`}
        >
          <WeekPicker
            isLoading={loader}
            selected={new Date(week_start)}
            setDate={setSetScheduleDate}
            week_end={week_end}
            beforeDate={disableDates.beforeDate}
            afterDate={disableDates.afterDate}
          />
        </Dropdown>
        <div
          style={{
            textAlign: "center",
            margin: "0.6rem 0",
            lineHeight: "1.2",
          }}
        >
          <h3 style={{ textDecoration: "underline" }}>הערות מנהל לשבוע </h3>
          {loader ? (
            <Skeleton width={200} />
          ) : (
            <strong style={{ fontSize: "1.4rem" }}>
              {schedule.notes
                ? `*${schedule.notes}`
                : "לא קיימות הערות לשבוע זה."}
            </strong>
          )}
        </div>
        {loader ? (
          week.map((day, day_idx) => (
            <Skeleton
              key={day_idx}
              height={70}
              style={{ borderRadius: "1rem", marginBottom: "1rem" }}
            />
          ))
        ) : isPastLastSubmissionDateTime ? (
          <ScheduleNotAvailabel sendedSubmissions={submissions} />
        ) : (
          <div className="submission--mobile__tab-container">
            <div
              className={
                disableForm && !isPastLastSubmissionDateTime
                  ? "page-disable"
                  : ""
              }
            ></div>
            {week.map((day, day_idx) => {
              const status = getSafe(
                () =>
                  selectedNotes[day.day_num].status
                    ? selectedNotes[day.day_num].status
                    : 0,
                0
              );
              const original_status = getSafe(
                () =>
                  init_notes_obj[day.day_num].status
                    ? init_notes_obj[day.day_num].status
                    : 0,
                0
              );

              if (!shifts_and_days[day.day_num]) {
                return <Fragment key={day_idx}></Fragment>;
              }
              const text = daily_notes[day.actual_date]?.text;
              const isDateRequired =
                submission_required_dates &&
                submission_required_dates[day.actual_date];
              const isDateRequiredForDepartment =
                submission_required_dates &&
                submission_required_dates[day.actual_date]?.shift_ids;
              const isRequired = shifts
                .map((shift) =>
                  isDateRequiredForDepartment?.[shift?.id]?.includes(
                    user.type_info?.id
                  )
                )
                .includes(true);
              return (
                <div
                  key={day_idx}
                  className={`submission--mobile__tab ${
                    (
                      shift_request_type == SHIFT_REQUEST_TYPE_BY_ROLE
                        ? false
                        : isRequired
                    )
                      ? "submission--disabled"
                      : ""
                  }`}
                >
                  <div
                    style={{ margin: "0 4px" }}
                    className="left-to-right flex-column-center fit content"
                  >
                    <h3>{day.day}</h3>
                    {day.date}
                    {text ? (
                      <BtnTooltip text={text} isClickable={true}>
                        <MdSpeakerNotes color={COLOR_CONFIRM} size="1.2em" />
                      </BtnTooltip>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="requiredForWork">
                    <h4>
                      {(
                        shift_request_type == SHIFT_REQUEST_TYPE_BY_ROLE
                          ? false
                          : isRequired
                      )
                        ? "ישנה חובת הגשה"
                        : ""}
                    </h4>
                  </div>
                  {status && status !== NOT_REQUESTED ? (
                    original_status === PENDING || status === PENDING ? (
                      <div
                        className={`submission--mobile--vacation submission--mobile--vacation__pending`}
                      >
                        {/* <button
                          type="button"
                          onClick={() =>
                            addSelectedNotes(day.day_num, {
                              date: day.actual_date,
                              status: peekStatus(status),
                            })
                          }
                          className="remove-icon btn btn--icon"
                        >

                        </button> */}
                        <i
                          style={{ fontSize: "16px" }}
                          className="fas fa-umbrella-beach"
                        />
                        {original_status === PENDING ? (
                          <span>ממתין לאישור מנהל</span>
                        ) : (
                          <span>ממתין לשליחת הגשות </span>
                        )}
                      </div>
                    ) : original_status === APPROVED ? (
                      <div
                        className={`${
                          status !== original_status ? "disabled" : ""
                        } submission--mobile--vacation submission--mobile--vacation__approved`}
                      >
                        {/* <button
                          type="button"
                          onClick={() =>
                            addSelectedNotes(day.day_num, {
                              date: day.actual_date,
                              status: peekStatus(status),
                            })
                          }
                          className="remove-icon btn btn--icon"
                        >
                          {renderTimes()}
                        </button> */}
                        <i
                          style={{ fontSize: "16px" }}
                          className="fas fa-umbrella-beach"
                        />
                        {status !== original_status ? (
                          <span>ביטול לאחר שליחה</span>
                        ) : (
                          <span>חופשה אושרה</span>
                        )}
                      </div>
                    ) : (
                      <div
                        className={`${
                          status !== original_status ? "disabled" : ""
                        } submission--mobile--vacation submission--mobile--vacation__cancel`}
                      >
                        {/* <button
                          type="button"
                          onClick={() =>
                            addSelectedNotes(day.day_num, {
                              date: day.actual_date,
                              status: peekStatus(status),
                            })
                          }
                          className="remove-icon btn btn--icon"
                        >
                          {renderTimes()}
                        </button> */}
                        <i
                          style={{ fontSize: "16px" }}
                          className="fas fa-umbrella-beach"
                        />
                        <span>ממתין לביטול</span>
                      </div>
                    )
                  ) : (
                    <div
                      style={{
                        padding: "0 8px",
                        width: "calc(100% - 150px",
                        textAlign: "center",
                      }}
                    >
                      {renderButtonGroup(day, day_idx)}
                    </div>
                  )}

                  <div style={{ padding: "0.8rem 0" }}>
                    <ActionsButtons
                      {...{
                        day,
                        status,
                        token,
                        schedule,
                        employeeShiftPositions,
                        selectedNotes,
                        addSelectedNotes,
                        peekStatus,
                        shift_request_type,
                        daily_notes,
                        isMobile,
                        init_notes_obj,
                        renderStatusButton,
                        isDateRequired,
                        isRequired,
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {!isPastLastSubmissionDateTime && (
              <div className="flex">
                <Button
                  style={{ margin: "0 0.2rem 0.5rem 0.2rem" }}
                  className="btn--neutral fluid"
                  onClick={() => handleAbort()}
                  textValue="ביטול"
                />

                <Button
                  type={
                    (
                      shift_request_type == SHIFT_REQUEST_TYPE_BY_ROLE
                        ? false
                        : memoizedBlock
                    )
                      ? "button"
                      : "submit"
                  }
                  style={{ margin: "0 0.2rem 0.5rem 0.2rem" }}
                  className={
                    (
                      shift_request_type == SHIFT_REQUEST_TYPE_BY_ROLE
                        ? false
                        : memoizedBlock
                    )
                      ? "fluid btn--disabled-pale "
                      : "fluid btn--submit "
                  }
                  onClick={() => {}}
                  textValue="שלח בקשות"
                  tooltip={
                    shift_request_type == SHIFT_REQUEST_TYPE_BY_ROLE
                      ? false
                      : memoizedBlock
                  }
                  tooltipText={` קיימת חובת הגשת משמרות ליום ${textForTooltipDays} משמרת ${textForTooltipShifts}`}
                />
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { schedule } = state.schedule;
  const { employees } = state;
  const { user, token } = state.auth;
  const is_error_modal_open = state.error.isOpen;
  let rolesWithDepartmentId = state.roles;
  const shifts = state.schedule.existing_shifts;
  const roles = state.schedule.existing_roles;
  const { week_start, week_end, week, option } =
    state.publicValues.scheduleWeek;
  const { notes, submissions, shifts_and_days, roles_and_days } =
    state.submissions;
  const { selected_branch } = state.branches;
  const { shift_request_type } = selected_branch;
  let { submission_required_dates } = schedule;
  const daily_notes = schedule.daily_notes
    ? mapKeys(schedule.daily_notes, "date")
    : {};
  const { isMobile } = state.publicValues;
  return {
    user,
    daily_notes,
    shifts,
    schedule,
    option,
    week_start,
    week_end,
    week,
    selected_branch,
    notes,
    submissions,
    shifts_and_days,
    token,
    is_error_modal_open,
    shift_request_type,
    roles_and_days,
    roles,
    submission_required_dates,
    employees,
    rolesWithDepartmentId,
  };
};

export default connect(mapStateToProps, {
  setScheduleDate,
  fetchSchedule,
  fetchSubmissions,
  addSubmissionsEmployeeNew,
  setActive,
  setIsFrame,
  setError,
  setTitle,
  setDialogModal,
  logout,
})(SubmissionsEmployee);

export const ButtonOption = ({
  kind,
  type,
  employee,
  default_selected,
  addSelectedSubmissions,
  removeSelectedSubmissions,
  day,
  is_desktop = false,
  requiredBtnAction,
  requiredBtnActionForRolesOnly = true,
  width = "",
}) => {
  const isRequiredToWork = requiredBtnAction?.includes(employee.type_info?.id);
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(default_selected);
  }, [default_selected]);

  const handleSelected = () => {
    let newSelected = {
      employee_id: employee.id,
      [type === SHIFT_REQUEST_TYPE_BY_SHIFT ? "shift_id" : "role_id"]: kind.id,
      date: day.actual_date,
      status: 1,
    };
    if (!selected) {
      addSelectedSubmissions(newSelected, kind.id);
    } else {
      removeSelectedSubmissions(kind.id);
    }
    setSelected(!selected);
  };

  return (
    <button
      type="button"
      onClick={() => handleSelected()}
      className={
        is_desktop
          ? `group-btn--option  ${
              selected ? "group-btn--option--selected" : ""
            } ${
              isRequiredToWork && requiredBtnActionForRolesOnly
                ? "submission--required"
                : ""
            }`
          : `group-btn--option group-btn--option__sub ${
              selected ? "group-btn--option--selected--sub" : ""
            } ${
              isRequiredToWork && requiredBtnActionForRolesOnly
                ? "submission--required"
                : ""
            }`
      }
      style={{ width: width }}
    >
      {kind.name}
    </button>
  );
};

const ActionsButtons = ({
  day,
  status,
  token,
  schedule,
  employeeShiftPositions,
  selectedNotes,
  addSelectedNotes,
  peekStatus,
  shift_request_type,
  daily_notes,
  isMobile,
  init_notes_obj,
  renderStatusButton,
  isDateRequired,
  isRequired,
}) => {
  const Buttons = () => {
    const [shiftEventHours, setShiftEventHours] = useState([]);
    const [openShiftEventHours, setOpenShiftEventHours] = useState(false);
    const [loadingHours, setLoadingHours] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const fetchShiftHours = () => {
      if (shiftEventHours.length) return;
      setLoadingHours(true);
      Axios.get("newSubShiftEvent/ShiftEventHours", {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          newShiftId: schedule.id,
          positionIds: employeeShiftPositions,
          date: day.actual_date,
          whenPlannedHoursIsNull: 1,
        },
      })
        .then(({ data }) => {
          // filter duplicate shifts
          // shift for same role and same hours will show ocne
          setShiftEventHours(
            data.filter(
              (shift, index, self) =>
                self.findIndex(
                  (comparisonShift) =>
                    comparisonShift.title === shift.title &&
                    comparisonShift.planned_start === shift.planned_start &&
                    comparisonShift.planned_end === shift.planned_end
                ) === index
            )
          );
          setLoadingHours(false);
        })
        .catch((err) => {
          switch (err?.response?.status) {
            case 401:
              logout();
              break;

            default:
              setLoadingHours(false);
          }
          console.error(err);
        });
    };

    return (
      <div
        // className={`${isDateRequired ? "submission--disabled" : ""}`}
        style={{
          display: "flex",
          flexWrap: isMobile ? "wrap" : "",
          justifyContent: "center",
          maxWidth: isMobile ? "22rem" : "",
        }}
      >
        {!isMobile ? (
          <ClickAwayListener onClickAway={() => setOpenNotes(false)}>
            <div>
              <button
                type="button"
                onClick={(e) => {
                  setOpenNotes(!openNotes);
                  setAnchorEl(e.currentTarget);
                }}
                className="btn btn--round"
              >
                {getSafe(() => selectedNotes[day.day_num].notes) ? (
                  getSafe(
                    () =>
                      selectedNotes[day.day_num].notes ===
                      init_notes_obj[day.day_num].notes
                  ) ? (
                    <i
                      style={{ fontSize: "16px", color: "#47CC00" }}
                      className="fa fa-file-alt"
                    />
                  ) : (
                    <i
                      style={{ fontSize: "16px" }}
                      className="fa fa-file-alt"
                    />
                  )
                ) : (
                  <i style={{ fontSize: "16px" }} className="fa fa-pen" />
                )}
              </button>
              <Popper anchorEl={anchorEl} open={openNotes} placement="right">
                <SubmissionsNotes
                  text={getSafe(() => selectedNotes[day.day_num].notes, "")}
                  onSave={(notes) => {
                    addSelectedNotes(day.day_num, {
                      date: day.actual_date,
                      notes,
                    });
                    setOpenNotes(false);
                  }}
                  onAbort={() => {
                    setOpenNotes(false);
                  }}
                />
              </Popper>
            </div>
          </ClickAwayListener>
        ) : (
          <Collapse in={openNotes} timeout="auto" unmountOnExit>
            <SubmissionsNotes
              text={getSafe(() => selectedNotes[day.day_num].notes, "")}
              onSave={(notes) => {
                addSelectedNotes(day.day_num, {
                  date: day.actual_date,
                  notes,
                });
                setOpenNotes(false);
              }}
              onAbort={() => {
                setOpenNotes(false);
              }}
            />
          </Collapse>
        )}
        {isMobile && (
          <button
            type="button"
            onClick={(e) => {
              setOpenNotes(!openNotes);
            }}
            className="btn btn--round"
          >
            {getSafe(() => selectedNotes[day.day_num].notes) ? (
              getSafe(
                () =>
                  selectedNotes[day.day_num].notes ===
                  init_notes_obj[day.day_num].notes
              ) ? (
                <i
                  style={{ fontSize: "16px", color: "#47CC00" }}
                  className="fa fa-file-alt"
                />
              ) : (
                <i style={{ fontSize: "16px" }} className="fa fa-file-alt" />
              )
            ) : (
              <i style={{ fontSize: "16px" }} className="fa fa-pen" />
            )}
          </button>
        )}
        {status && status !== NOT_REQUESTED ? (
          ""
        ) : (
          <button
            type="button"
            disabled={
              shift_request_type == SHIFT_REQUEST_TYPE_BY_ROLE
                ? false
                : isRequired
            }
            onClick={() =>
              addSelectedNotes(day.day_num, {
                date: day.actual_date,
                status: peekStatus(status),
              })
            }
            className="btn btn--round"
          >
            {status === CANCELED ? "" : renderStatusButton(status)}
          </button>
        )}
        {shift_request_type == SHIFT_REQUEST_TYPE_BY_ROLE ? (
          <Popover
            place="below"
            enterExitTransitionDurationMs={300}
            isOpen={openShiftEventHours}
            body={
              <Model className="flex-column-center">
                {loadingHours ? (
                  <CircularProgress />
                ) : (
                  <SimpleTable
                    data={shiftEventHours}
                    columns={[
                      {
                        title: "תפקיד",
                        name: "title",
                        render: (data, row) => data,
                      },
                      {
                        title: "שעת התחלה",
                        name: "planned_start",
                        render: (data, row) => fixHour(data),
                      },
                      {
                        title: "שעת סיום",
                        name: "planned_end",
                        render: (data, row) => fixHour(data),
                      },
                    ]}
                    footerCells={
                      daily_notes[day.actual_date]?.text
                        ? [
                            {
                              data: daily_notes[day.actual_date]?.text,
                              colSpan: 3,
                              name: "ss",
                            },
                          ]
                        : []
                    }
                  />
                )}
              </Model>
            }
            onOuterAction={() => setOpenShiftEventHours(false)}
          >
            <Button
              className="btn btn--round"
              icon={FiClock}
              textValue=""
              onClick={() => {
                setOpenShiftEventHours(!openShiftEventHours);
                fetchShiftHours();
              }}
            ></Button>
          </Popover>
        ) : (
          ""
        )}
      </div>
    );
  };

  return isMobile ? (
    <PopoverEllipsisButton ellipsisButtonBackgroundColor="white">
      <Buttons />
    </PopoverEllipsisButton>
  ) : (
    <Buttons />
  );
};
