import Axios from "axios";
import { isArray, mapKeys } from "lodash";
import { getSafe } from "../../../helpers";
import { LOGOUT_ADMIN } from "../AdminLoginAction/types";
import { SET_ERROR } from "../errorAction/types";
import { createFormJson } from "../helpers";
import { LOGOUT } from "../loginAction/types";
import {
  ACKNOWLEDGED_MESSAGES,
  ADD_MESSAGE,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  FETCH_MESSAGES,
  FETCH_MESSAGES_TO_SHOW,
} from "./types";

export const getMessages =
  ({ network_id, privilege_id, branch_id, admin = false }) =>
  async (dispatch, getState) => {
    try {
      const response = await Axios.get(`${admin ? "admin/" : ""}messages`, {
        params: { network_id, privilege_id, branch_id },
        headers: {
          Authorization: `Bearer ${
            admin ? getState().authAdmin.token : getState().auth.token
          }`,
        },
      });
      const { data } = response;
      dispatch({
        type: admin ? FETCH_MESSAGES : FETCH_MESSAGES_TO_SHOW,
        payload: data,
      });
    } catch (e) {
      console.error(e);
      if (e.response?.status === 401) {
        dispatch({ type: admin ? LOGOUT_ADMIN : LOGOUT });
      } else if (e.response?.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
      }
    }
  };

export const addMessage = (formValues) => async (dispatch, getState) => {
  try {
    const response = await Axios.post(`admin/messages`, formValues, {
      headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
    });

    const { data } = response;
    dispatch({
      type: ADD_MESSAGE,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    if (e.response?.status === 401) {
      dispatch({ type: LOGOUT_ADMIN });
    } else if (e.response?.status === 500) {
      dispatch({
        type: SET_ERROR,
        errorTitle: "ארעה שגיאה בשרת",
        error: getState().publicValues.isMobile
          ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
          : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
      });
    }
  }
};

export const editMessage =
  (ids_list, formValues) => async (dispatch, getState) => {
    try {
      const formData = createFormJson(
        { ids_list: isArray(ids_list) ? ids_list : [ids_list], ...formValues },
        "PUT"
      );
      const response = await Axios.post(`admin/messages`, formData, {
        headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
      });

      const { data } = response;
      dispatch({
        type: EDIT_MESSAGE,
        payload: mapKeys(data, "id"),
      });
    } catch (e) {
      console.error(e);
      if (e.response?.status === 401) {
        dispatch({ type: LOGOUT_ADMIN });
      } else if (e.response?.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
      }
    }
  };

export const setAcknowledgedMessages =
  (isAcknowledged, acknowledgedMessages) => async (dispatch) => {
    dispatch({
      type: ACKNOWLEDGED_MESSAGES,
      payload: isAcknowledged ? acknowledgedMessages : {},
      isAcknowledged,
    });
  };

export const deleteMessage = (ids_list) => async (dispatch, getState) => {
  try {
    const formData = createFormJson(
      { ids_list: isArray(ids_list) ? ids_list : [ids_list] },
      "DELETE"
    );
    const response = await Axios.post(`admin/messages`, formData, {
      headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
    });

    const { data } = response;
    dispatch({
      type: DELETE_MESSAGE,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    if (e.response?.status === 401) {
      dispatch({ type: LOGOUT_ADMIN });
    } else if (e.response?.status === 500) {
      dispatch({
        type: SET_ERROR,
        errorTitle: "ארעה שגיאה בשרת",
        error: getState().publicValues.isMobile
          ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
          : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
      });
    }
  }
};
