// authReducer.js
import {
    FETCH_EMPLOYEES,
  } from "../actions/scheduleAction/types";
  import { mapKeys } from "lodash";
import { LOGOUT } from "../actions/loginAction/types";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
  
  export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_EMPLOYEES:
          return { ...mapKeys(action.payload, "id") };
          case LOGOUT_ADMIN:
          case LOGOUT:
            return { };
      default:
        return state;
    }
  };
  