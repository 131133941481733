// GENERAL - TABS
export const TAB_INGREDIENT_WARHOUSE = 0;
export const TAB_PRODUCT_WAREHOUSE = 1;

//
export const WAREHOUSE_CATALOG_TYPE = 52;
export const WAREHOUSE_PRODUCT_TYPE = 84;

// TABS PATHS
export const TAB_PATH_ITEMS_INGREDIENT_WARHOUSE = "ingredientsWarehouse";
export const TAB_PATH_PRODUCT_WAREHOUSE = "productsWarehouse";

export const TAB_OPTIONS = [
  {
    id: TAB_INGREDIENT_WARHOUSE,
    label: "לפי חומרי גלם",
    path: TAB_PATH_ITEMS_INGREDIENT_WARHOUSE,
  },
  // {
  //   id: TAB_PRODUCT_WAREHOUSE,
  //   label: "לפי עץ מוצר",
  //   path: TAB_PATH_PRODUCT_WAREHOUSE,
  // },
];

// GENERAL - FILTERS
export const FILTERS_STATUS_ENABLE = 1;
export const FILTERS_STATUS_DISABLED = 0;

export const FILTERS_STATUS_OPTIONS = [
  {
    id: FILTERS_STATUS_ENABLE,
    label: "מחסנים פעילים",
  },
  {
    id: FILTERS_STATUS_DISABLED,
    label: "מחסנים לא פעילים",
  },
];

// CATALOG FILTERS
export const FILTERS_CATALOG_STATUS_OPTIONS = [
  {
    id: FILTERS_STATUS_ENABLE,
    label: "מוצרים פעילים",
  },
  {
    id: FILTERS_STATUS_DISABLED,
    label: "מוצרים לא פעילים",
  },
];

export const FILTERS_TYPE_CATALOG = 1;
export const FILTERS_TYPE_PRODUCT = 2;

export const FILTERS_TYPE_OF_ITEMS = [
  {
    id: FILTERS_TYPE_CATALOG,
    name: "מוצרים",
  },
  {
    id: FILTERS_TYPE_PRODUCT,
    name: "עץ מוצר",
  },
];

export const COUNT_TYPE_NONE = 0;
export const COUNT_TYPE_ITEM = 1;
export const COUNT_TYPE_WEIGHT = 2;
export const COUNT_TYPE_BOX_TYPE = 3;
export const COUNT_TYPE_BOX_SCALE = 4;
export const COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE = 5;
export const COUNT_NO_MANAGE_INVENTORY = 6;
export const COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE_AND_WEIGHT = 7;

export const COUNT_TYPES_HEBREW = {
  [COUNT_TYPE_NONE]: "ללא",
  [COUNT_TYPE_ITEM]: "מוצר",
  [COUNT_TYPE_WEIGHT]: "שקיל - יחידת מידה",
  [COUNT_TYPE_BOX_TYPE]: "צורת מארז",
  [COUNT_TYPE_BOX_SCALE]: "יחידת מידה במארז",
  [COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE]: "מארז + יחידת מידה במארז",
  [COUNT_NO_MANAGE_INVENTORY]: "לא מנוהל מלאי",
  [COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE_AND_WEIGHT]:
    "מארז + יחידת מידה במארז + שקיל",
};

export const htmlSpecialCharacters = (string) =>
  string
    .replace("&amp;", "")
    .replace("&quot;", "''")
    .replace("quot;", "''")
    .replace("&quot;", "''")
    .replace("&#039;", "'");
