import Axios from "axios";
import { LOGOUT } from "../loginAction/types";
import { FETCH_BRANCHES, SET_BRANCH } from "./types";

// get chain branches
export const getBranches = () => async (dispatch, getState) => {
  try {
    const { data } = await Axios.get("/branches", {
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });

    dispatch({
      type: FETCH_BRANCHES,
      payload: data,
    });
  } catch (e) {
    if (e.response)
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      }
    console.error(e);
  }
};

export const setBranch = (branch) => (dispatch) => {
  dispatch({ type: SET_BRANCH, payload: branch });
};
