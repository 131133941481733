import { IconButton, Typography } from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoMdPricetag } from "react-icons/io";
import React, {  useMemo } from "react";
import {
  PriceCell,
  PriceFooter,
  CountFooter,
} from "../../components/tables/components/PriceFooter";
import { fixDate, fixPrice, twoZeroAfterDot } from "../../helpers";

import SimpleTable from "../orders/manageOrders/receiveOrder/SimpleTable";

export const userColumnsMobile = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <IconButton
          {...row.getToggleRowExpandedProps({
            // style: {
            //   // We can even use the row.depth property
            //   // and paddingLeft to indicate the depth
            //   // of the row
            //   paddingRight: `${row.depth * 2}rem`,
            // },
          })}
          aria-label="expand row"
          size="small"
          style={{ color: "inherit" }}
        >
          {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </IconButton>
      ) : null,
  },
  {
    Header: "שם העובד",
    accessor: "full_name", //SORTTYPE
  },
  
  {
    Header: "סה״כ שעות",
    accessor: "hours_total",
    Cell: (props) => {
      const hours = (props.row.original?.hours_total) ? props.row.original.hours_total:0;
      return (<div className="money">
        
        {`${twoZeroAfterDot(hours)}`}
      </div>);
    },
    //Footer: CountFooter,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.hours_total + sum, 0),
        [self.rows]
      );

      return <>{total.toFixed(2)}</>;
    }
  },
  {
    Header: "שכר לפני ע״מ",
    accessor: "total_cost_before_employer_cost",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "שכר כולל ע״מ",
    accessor: "total_cost_after_employer_cost",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  
];
export const excellAggregates = [
  { field: "hours_100", aggregate: "sum" },
  { field: "hours_125", aggregate: "sum" },
  { field: "hours_150", aggregate: "sum" },
  { field: "hours_150_shabat", aggregate: "sum" },
  { field: "hours_175", aggregate: "sum" },
  { field: "hours_200", aggregate: "sum" },
  { field: "hours_total", aggregate: "sum" },
  { field: "cost_raw", aggregate: "sum" },
  { field: "cost_total", aggregate: "sum" },
  { field: "total_with_vat_after_fees", aggregate: "sum" },
];

export const userBreakdownSubColumns = [
  {
    Header: "סוג",
    accessor: "type",
  },
  {
    Header: "סה״כ ללא מע״מ",
    accessor: "cost_raw",
    Cell: PriceCell,
  },
  {
    Header: "סה״כ כולל מע״מ",
    accessor: "cost_total",
    Cell: PriceCell,
  },
  {
    Header: "עמלות",
    accessor: "travel_cost",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: 'סה״כ אחרי עמלות',
    accessor: "total_including_tips",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  // {
  //   Header: "סועדים",
  //   accessor: "diners",
  // },
  // {
  //   Header: "ממוצע לסועד",
  //   accessor: "diners_avg",
  // },
  {
    Header: "חלוקה %",
    accessor: "percent",
    Cell: ({ value }) => `${value.toFixed(1)}%`,
  },
];

export const mobileDateBreakdownColumns = (setDialogModal) => [
  {
    Header: "",
    accessor: "id",
    Cell: ({ row }) => {
      const {
        hebrew_create_date,
        department_name,
        cost_raw,
        cost_total,
        z_with_vat,
        diners,
        diners_avg,
        z_numbers,
        transaction_date,
        date
      } = row.original;
      return (
        <div>
          <Typography align="center" component="div">
            <h2 style={{ display: "inline" }}>{`${hebrew_create_date} ${fixDate(date,'DD/MM')}`}</h2>
            {/* <h2 style={{ display: "inline" }}>{`${day} ${fixDate(hebrew_date,'MM/DD')}`}</h2> */}
            {/* <IconButton
              color="secondary"
              onClick={() =>
                setDialogModal({
                  iconJSX: () => IoMdPricetag({ size: "7em" }),
                  title: `נתונים נוספים לתאריך ${hebrew_date}`,
                  showConfirmButton: false,
                  showAbortButton: false,
                  text: (
                    <SimpleTable
                      columns={[
                        {
                          title: "פירוט",
                          name: "key",
                          render: (data, row) => {
                            return data;
                          },
                        },
                        {
                          title: "ערך",
                          name: "value",
                          render: (data, row) => {
                            return data;
                          },
                        },
                      ]}
                      data={[
                        { key: `Z כולל מע״מ`, value: fixPrice(z_with_vat) },
                        { key: `מס' סועדים`, value: diners },
                        { key: `ממוצע לסועד`, value: fixPrice(diners_avg) },
                        { key: `מס' Z`, value: z_numbers },
                      ]}
                    />
                  ),
                })
              }
            >
              <IoMdPricetag />
            </IconButton> */}
          </Typography>
          <div className="flex-between">
            <div>
              <strong>{fixPrice(cost_raw)}</strong>
              <small style={{ marginRight: "1rem" }}>ללא מע״מ</small>
            </div>
            <div>
              <strong>{fixPrice(cost_raw)}</strong>
              <small style={{ marginRight: "1rem" }}>כולל מע״מ</small>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    accessor:'day',
    header: '',
    hide: true,
  }
];
