// ======== REDUX ========
import { connect, useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Collapse,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Modal from "react-responsive-modal";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactTable from "../../components/tables/ReactTable";
import {
  LINK_ITEM,
  LINK_MODAL_TYPE_ITEMS,
  LINK_MODAL_TYPE_OPTIONS,
  LINK_MODAL_TYPE_PRODUCTS,
  LINK_PRODUCT,
} from "./const";
import DropdownSelect from "../../components/dropdown/DropdownSelect";
import MultiSelectCheckbox from "../../components/dropdown/MultiSelectCheckBox";
import { submitWrapper } from "../../helpers";
import { setConfirm } from "../../redux/actions/confirmAction";
import moment from "moment";
import {
  getLinkModalItemsData,
  getLinkModalProductsData,
  linkModaProductsTableColumns,
  linkModaItemsTableColumns,
} from "./tables/linkModal.table";

// ======== COMPONENTS ========

export const LinkModal = ({ linkModalState, onClose, data, setData }) => {
  // ======== HOOKS ========

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========
  const { reducedData, unlinkedItems } = data;
  const { open, soldDish } = linkModalState;
  const { products, items } = unlinkedItems;
  const { minDate } = reducedData;

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    minDate.format("YYYY-MM-DD")
  );
  const [selectedLink, setSelectedLink] = useState(null);
  const [selectedLinkType, setSelectedLinkType] = useState(
    LINK_MODAL_TYPE_OPTIONS[1]
  );

  const { productCategories, itemCategories } = useMemo(() => {
    const uniqueItemCategoriesIds = new Set();

    const itemCategories = items.reduce((categories, item) => {
      const { category_name, category_id } = item;

      if (!uniqueItemCategoriesIds.has(category_id)) {
        uniqueItemCategoriesIds.add(category_id);

        categories.push({
          label: category_name,
          value: category_id,
        });
      }

      return categories;
    }, []);

    const uniqueProductCategoriesIds = new Set();

    const productCategories = products.reduce((categories, product) => {
      const { category_name, category_id } = product;

      if (!uniqueProductCategoriesIds.has(category_id)) {
        uniqueProductCategoriesIds.add(category_id);

        categories.push({
          label: category_name,
          value: category_id,
        });
      }

      return categories;
    }, []);

    return {
      itemCategories,
      productCategories,
    };
  }, [data]);

  const { token } = useSelector((state) => ({
    token: state.auth.token,
  }));

  // ======== CONSTANTS ========
  const title = `קישור מוצר ${soldDish.name}, ${soldDish.product_code}`;

  //used to dynamically assign data in the table, category filters and submit function since most of the logic is the same.
  const typeDict = {
    [LINK_MODAL_TYPE_ITEMS]: {
      type: "items",
      key: "item_id",
      categories: itemCategories,
      data: getLinkModalItemsData(items, selectedCategories), //CONTINUE HERE ADD CATEGORIES
      path: LINK_ITEM,
      columns: linkModaItemsTableColumns,
    },
    [LINK_MODAL_TYPE_PRODUCTS]: {
      type: "products",
      key: "product_id",
      categories: productCategories,
      data: getLinkModalProductsData(products, selectedCategories),
      path: LINK_PRODUCT,
      columns: linkModaProductsTableColumns,
    },
  };

  // ======== HANDLERS ========
  const handleSubmit = async () => {
    const dynamicPath = typeDict[selectedLinkType.id].path;
    const dynamicProp = typeDict[selectedLinkType.id].type;
    const dynamicKey = typeDict[selectedLinkType.id].key;

    soldDish.product_code = `${soldDish.product_code}`;

    const payload = {
      product_code: soldDish.product_code,
      name: soldDish.name,
      start_date: selectedDate,
      end_date: moment().format("YYYY-MM-DD"),
      [dynamicKey]: selectedLink.id,
    };

    const { data } = await Axios.patch(dynamicPath, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    data.category_name = selectedLink.category_name;
    data[dynamicKey] = selectedLink.id;

    setData((prevData) => {
      const updatedSoldDishes = prevData.soldDishes.by_code.map((sd) =>
        sd.product_code === soldDish.product_code && sd.name === soldDish.name
          ? { ...sd, ...data }
          : sd
      );

      const updatedUnlinked = {
        ...prevData.unlinkedItems,
        [dynamicProp]: prevData.unlinkedItems[dynamicProp].filter(
          (item) => item.id !== selectedLink.id
        ),
      };

      return {
        ...prevData,
        soldDishes: { ...prevData.soldDishes, by_code: updatedSoldDishes },
        unlinkedItems: updatedUnlinked,
      };
    });

    dispatch(setConfirm("קושר בהצלחה!"));
    onClose();
  };

  // EFFECTS
  useEffect(() => {
    setSelectedCategories([]);
  }, [selectedLinkType]);

  // ======== UI ========

  return (
    <Modal
      styles={{
        modal: {
          overflow: "auto",
          ...(!isMobile
            ? {
                padding: "2.5rem",
                minWidth: "100rem",
                maxHeight: "80vh",
              }
            : {}),
        },
        overlay: {
          zIndex: 100,
        },
      }}
      center
      {...{ onClose, open }}
    >
      <div className="flex-column-center">
        <h2 style={{ textAlign: "center", margin: "16px" }}>{title}</h2>
        <Grid container sx={{ maxWidth: "600px" }}>
          <Grid xs={12} md={6}>
            <DropdownSelect
              selected={selectedLinkType}
              setSelected={(val) => setSelectedLinkType(val)}
              options={LINK_MODAL_TYPE_OPTIONS}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <MultiSelectCheckbox
              title="קטגוריות"
              checked={selectedCategories}
              setChecked={setSelectedCategories}
              options={typeDict[selectedLinkType.id].categories}
            />
          </Grid>

          <Grid xs={12}>
            <Collapse in={selectedLink}>
              <div className="flex-column-center">
                <Typography
                  sx={{ fontSize: "1.6rem", marginBottom: "1rem" }}
                  variant="body1"
                >
                  נא לבחור תאריך ממנו יקושרו המכירות רטרו אקטיבית
                </Typography>

                <div className="flex" style={{ width: "400px" }}>
                  <TextField
                    type="date"
                    size="small"
                    label="תאריך"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    inputProps={{
                      style: { textAlign: "center" },
                      max: moment().format("YYYY-MM-DD"),
                    }}
                    sx={{ maxWidth: "300px" }}
                    required
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => submitWrapper(handleSubmit, dispatch, [])}
                  >
                    בצע
                  </Button>
                </div>
              </div>
            </Collapse>
          </Grid>
        </Grid>
        <ReactTable
          {...{
            styles: {
              wrapper: {
                width: "80%",
              },
            },
            columns: typeDict[selectedLinkType.id].columns,
          }}
          title="יש לבחור מוצר מתוך הטבלה אותו תרצו לקשר"
          data={typeDict[selectedLinkType.id].data}
          cellsPropsById={() => ({
            setSelectedLink,
          })}
          isPaginate
          initialRowsPerPage={10}
        />
      </div>
    </Modal>
  );
};

export default LinkModal;
