import Axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
// import { Printer } from "react-feather";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { BounceLoader } from "react-spinners";
// import NewButton from "../../../components/buttons/NewButton";
import ReactTable from "../../../components/tables/ReactTable";
import { SHEKEL_SIGN } from "../../../const";
import { COLOR_ABORT } from "../../../const/colors";
import {
  fixDate,
  fixDateHours,
  // printView,
  thousandSeparator,
} from "../../../helpers";
import { setError } from "../../../redux/actions/errorAction";
import { logout } from "../../../redux/actions/loginAction";
import { view_received_columns } from "../columns";

const ViewReceivedOrder = ({
  new_received_order_id,
  token,
  logout,
  setError,
  onClose,
}) => {
  const [receivedOrder, setReceivedOrder] = useState({});
  const [loader, setLoader] = useState(true);

  const memo_columns = useMemo(() => view_received_columns, []);
  const fetchOrder = useMemo(
    () => async (new_received_order_id) => {
      try {
        setLoader(true);
        const res = await Axios.get(
          `newReceivedOrder/${new_received_order_id}/watch`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const { data } = res;
        setReceivedOrder(data);
        setLoader(false);
      } catch (e) {
        setLoader(false);
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logout();
          } else if (e.response.status === 500) {
            setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
          }
      }
    },
    [logout, token, setError]
  );

  useEffect(() => {
    if (new_received_order_id) {
      fetchOrder(new_received_order_id);
    }
  }, [new_received_order_id, fetchOrder]);

//   const printOrder = async () => {
//     try {
//       const res = await Axios.post(
//         `newOrder/${receivedOrder.legacy_new_received_order_id}/printOrder`,
//         { new_received_order_id: receivedOrder.id },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       printView(res.data);
//     } catch (e) {
//       console.error(e);
//       if (e.response)
//         if (e.response.status === 401) {
//           logout();
//         } else if (e.response.status === 500) {
//           setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
//         }
//     }
//   };

  return (
    <Modal
      animationDuration={200}
      styles={{
        modal: {
          padding: "3.5rem",
          maxWidth: "fit-content",
          minWidth: "60rem",
        },
      }}
      open={new_received_order_id}
      center
      onClose={onClose}
    >
      {receivedOrder && !loader ? (
        <div className="flex-column-center">
          <div className="fluid">
            <h2>{`צפייה בחשבונית מס': ${receivedOrder.document_number}`}</h2>
            <p className="paragraph fluid u-right-text">{`שם הספק: ${receivedOrder.supplier_name}`}</p>
            <p className="paragraph fluid u-right-text">{`סניף שולח: ${receivedOrder.branch_name}`}</p>{" "}
          </div>

          <p className="paragraph fluid u-right-text">{`תאריך קליטה: ${fixDate(
            receivedOrder.document_date
          )}`}</p>
          <p className="paragraph fluid u-right-text">
            סכום חשבונית:{" "}
            <span className="is-number">
              {SHEKEL_SIGN} {thousandSeparator(receivedOrder.total_without_vat)}
            </span>
          </p>
          {receivedOrder.total_without_vat !== receivedOrder.actual_total ? (
            <p
              style={{ color: COLOR_ABORT }}
              className="paragraph fluid u-right-text"
            >
              שים לב, קיים פער בין הסכום שהוזן בחשבונית לבין שווי המוצרים שנקלטו בפועל
            </p>
          ) : (
            <></>
          )}
          <ReactTable
            {...{
              styles: {
                wrapper: { maxHeight: "calc(100vh - 10rem)" },
                container: { maxHeight: "calc(100vh - 23.4rem)" },
              },
              translation_endpoint: "itemsTable",
              title: `רשימת מוצרים חשבונית ${receivedOrder.document_number}`,
              columns: memo_columns,
              data: receivedOrder.items,
              isExportedToExcel: true,
              showFooter: true,
              showCheckbox: false,
              sortBy: [{ id: "name" }],
            }}
          />

          <p className="paragraph">{`עודכן ע"י: ${
            receivedOrder.user
          } ב-${fixDateHours(receivedOrder.updated_at)}`}</p>

          {/* <NewButton
            icon={<Printer size={16} />}
            textValue={"הדפסת קליטה"}
            onClick={() => printOrder()}
          /> */}
        </div>
      ) : (
        <div style={{ minHeight: "20rem" }} className="flex-center">
          <BounceLoader color="#00a2ea" loading={loader} />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { token } = state.auth;
  return { token };
};

export default connect(mapStateToProps, { logout, setError })(
  ViewReceivedOrder
);
