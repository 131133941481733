import React from "react";

export const Header = ({ children, className = "", style }) => {
  return (
    <div style={style} className={`model--header ${className}`}>
      {children}
    </div>
  );
};

export const Content = ({ children, className = "", style }) => {
  return (
    <div style={style} className={`model--content ${className}`}>
      {" "}
      {children}
    </div>
  );
};

export const Footer = ({ children, className = "", style }) => {
  return (
    <div style={style} className={`model--footer ${className}`}>
      {" "}
      {children}
    </div>
  );
};

const Model = ({ style, children, className = "" }) => {
  return (
    <div style={style} className={`model ${className}`}>
      {children}
    </div>
  );
};

export default Model;
