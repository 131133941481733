import React, { useState } from "react";
import { useScreenSize } from "../Hooks";

const SegmentedControl = ({
  className = null,
  style = null,
  options,
  selected,
  setSelected = null,
  loading = false,
  additionalWidth = 0,
}) => {
  const [optionWidth, setOptionWidth] = useState(0);
  const { isMobile } = useScreenSize();
  return (
    <div
      className={`ios-segmented-control ${className}`}
      style={{ ...style, direction: "ltr" }}
    >
      <span
        className="selection"
        style={{ transform: `translateX(${optionWidth * selected}px)` }}
      ></span>

      {options.map((option, index) => {
        return (
          <div className="option" key={index}>
            <input
              ref={(el) => {
                if (!el) return;
                setOptionWidth(
                  el.getBoundingClientRect().width +
                    (isMobile ? 0 : additionalWidth)
                );
              }}
              disabled={loading || option.disabled}
              type="radio"
              id={`${option.text}${index}`}
              name={option.text}
              checked={index == selected}
              onChange={() => {
                if (option.onClick) option.onClick(option);
                if (setSelected) {
                  setSelected(index);
                }
              }}
            />
            {option.jsx ? (
              <label
                htmlFor={`${option.text}${index}`}
                style={{ cursor: "pointer" }}
              >
                <span>{option.jsx}</span>
              </label>
            ) : option.icon ? (
              <label
                htmlFor={`${option.text}${index}`}
                style={{ cursor: "pointer" }}
              >
                <span>
                  <i className={option.icon} />
                </span>
              </label>
            ) : (
              <label
                htmlFor={`${option.text}${index}`}
                style={{ cursor: "pointer" }}
              >
                <span>{option.text}</span>
              </label>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SegmentedControl;
