import { Link, Tooltip } from "@mui/material";
import {
  CountFooter,
  PercentageCell,
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";

import {
  dynamicToFixed,
  fixPercentage,
  fixPrice,
  getSafeDivision,
  submitWrapper,
  thousandSeparator,
} from "../../../helpers";
import { VAT } from "../../orders/manageOrders/constants";
import { DEFAULT_MAX_FC, FcHeader, SOLD_DISH_TYPE_MEAL } from "../const";
import { GREEN_COLOR, RED_COLOR } from "../../products/const";

export const getData = (
  by_code, //raw data
  branches // redux state
) => {
  return by_code
    .filter((soldDish) => soldDish.type === SOLD_DISH_TYPE_MEAL)
    .map((dish) => {
      const { product_code, name, link, branch_ids, amount, total, worth } =
        dish;

      const branch_names = branch_ids.map(
        (branch_id) => branches.find((branch) => branch.id === branch_id).name
      );

      const avg_price = getSafeDivision(total, amount);
      const avg_foodcost = getSafeDivision(worth, total) * 100;

      return {
        product_code,
        name,
        link,
        branch_names,
        amount,
        total_with_vat: total,
        avg_price,
        worth,
        avg_foodcost,
      };
    });
};

export const excelRowConfig = (row) => {
  row.link = row.link.name;
  row.amount = dynamicToFixed(row.amount);
  row.total_with_vat = dynamicToFixed(row.total_with_vat);
  row.worth = dynamicToFixed(row.worth);
  row.avg_price = dynamicToFixed(row.avg_price);
  row.avg_foodcost = dynamicToFixed(row.avg_foodcost);

  return row;
};

export const aggregates = [
  { field: "amount", aggregate: "sum" },
  { field: "total_with_vat", aggregate: "sum" },
  { field: "worth", aggregate: "sum" },
  { field: "avg_price", aggregate: "sum" },
];

export const sortBy = [{ id: "avg_foodcost", desc: true }];

export const columns = [
  {
    Header: "קוד קופה",
    accessor: "product_code",
    Footer: () => "סה״כ",
  },
  {
    Header: "שם",
    accessor: "name",
  },
  {
    Header: "מוצר מקושר",
    accessor: "link",
    Cell: ({ value, row, getSoldDishesByNameAndCode, dispatch }) => {
      const { name, category_name, sale_price_with_vat } = value;

      const toolTipRows = [
        `מחיר מכירה: ${fixPrice(sale_price_with_vat, 1, false)}`,
        `קטגוריה: ${category_name}`,
      ];

      return (
        <Tooltip
          title={
            <div className="flex-column-right" style={{ fontSize: "1.5rem" }}>
              {toolTipRows.map((row, key) => (
                <span {...{ key }}>{row}</span>
              ))}
            </div>
          }
          placement="top-start"
        >
          <Link
            component="button"
            sx={{
              fontSize: "1.6rem",
            }}
            onClick={() =>
              submitWrapper(getSoldDishesByNameAndCode, dispatch, [
                row.original,
              ])
            }
          >
            {name}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    Header: "סניפים",
    accessor: "branch_names",
    disable_export_excel: true,
    Cell: ({ value: branch_names }) => {
      const amount = branch_names.length;

      return (
        <Tooltip
          title={
            <span style={{ fontSize: "1.5rem" }}>
              {branch_names.join(", ")}
            </span>
          }
          placement="top-start"
        >
          <Link
            component="span"
            sx={{
              fontSize: "1.6rem",
            }}
          >
            {`${amount} סניפים`}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    Header: "כמות שנמכרה",
    accessor: "amount",
    Cell: ({ value }) => (
      <span className="is-number">{thousandSeparator(value)}</span>
    ),
    Footer: CountFooter,
  },
  {
    Header: "מכירות כולל מע״מ",
    accessor: "total_with_vat",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "עלות רכיבים",
    accessor: "worth",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "מחיר מנה",
    accessor: "avg_price",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: FcHeader,
    accessor: "avg_foodcost",
    Cell: (props) => (
      <span
        style={{
          color: props.value <= DEFAULT_MAX_FC ? GREEN_COLOR : RED_COLOR,
        }}
      >
        {PercentageCell(props)}
      </span>
    ),
  },
];
