import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { logConnectedUser } from "../../redux/actions/loginAction";

const ConnectedUsers = ({ connected_users, logConnectedUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        משתמשים נוספים
      </MenuItem>
      <Menu
        keepMounted
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={(e) => {
          e.preventDefault();
          setAnchorEl(null);
        }}
      >
        {connected_users.map(({ full_name, id, network_name }, key) => {
          return (
            <MenuItem
              {...{ key }}
              onClick={() => {
                logConnectedUser(id);
              }}
            >
              {`${full_name}, ${network_name}`}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {
  logConnectedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers);
