//GET
export const GET_TASKS_TYPE = "GET_TASKS_TYPES";

// SET
export const SET_PAGINATION_FILTERS = "SET_PAGINATION_FILTERS";
export const SET_DATA_LOADING = "SET_DATA_LOADING";
export const SET_TASKS = "SET_TASKS";
export const SET_TASK_FILTER = "SET_TASK_FILTER";
export const SET_TASK_TYPE = "SET_TASK_TYPE";
export const SET_TASK_DAY = "SET_TASK_DAY";
//
export const RESET_PAGINATION_DATA = "RESET_PAGINATION_DATA";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const ADD_EMAIL = "ADD_EMAIL";
export const GET_TASKS_EMAILS = "GET_TASKS_EMAILS";
export const RESET_TASKS_FILTERS = "RESET_TASKS_FILTERS";
export const SET_REPORTS_FILTER = "SET_REPORTS_FILTER";
export const SET_BRANCH_EMPLOYEE = "SET_BRANCH_EMPLOYEE";
export const SET_HISTORY_TASKS = "SET_HISTORY_TASKS";
export const SET_DELETED_AT = "SET_DELETED_AT";
export const SET_CURRENT_TASK = "SET_CURRENT_TASK";
export const SET_NEW_TASKS_TYPE = "SET_NEW_TASKS_TYPE";
export const SET_CURR_TASK_DATA = "SET_CURR_TASK_DATA";
export const UPDATE_SELECTED_PARTS = "UPDATE_SELECTED_PARTS";
export const UPDATE_AMOUNT_OF_DONE_PARTS = "UPDATE_AMOUNT_OF_DONE_PARTS";
export const UPDATE_EDIT_FIELD = "UPDATE_EDIT_FIELD";
export const UPDATE_NEW_COMMENT = "UPDATE_NEW_COMMENT";
