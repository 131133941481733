import React from "react";
import { I18n } from "react-redux-i18n";
import Tooltip from "@mui/material/Tooltip";

const Button = ({
  disabled = false,
  className = "",
  onClick,
  textValue,
  style,
  icon,
  type = "button",
  iconStyle = {},
  iconSize = "1.2em",
  iconColor,
  tooltip = false,
  tooltipText,
}) => {
  return (
    // tooltip not work with disabled buttons!!!
    <Tooltip
      title={
        tooltip ? <h2 style={{ color: "lightblue" }}>{tooltipText}</h2> : ""
      }
      placement="top"
    >
      <button
        disabled={disabled}
        type={type}
        style={style}
        className={`btn ${className}`}
        onClick={onClick}
      >
        <span>
          {icon
            ? icon({
                style: {
                  verticalAlign: "middle",
                  marginLeft: "0.6rem",
                  ...iconStyle,
                },
                size: iconSize,
                color: iconColor,
              })
            : ""}
        </span>
        <span>
          {I18n.t(`button.${textValue}`)
            ? I18n.t(`button.${textValue}`)
            : textValue}
        </span>
      </button>
    </Tooltip>
  );
};

export default Button;
