import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========

// ======== HOOKS ========
import BtnTooltip from "./tooltips/BtnTooltip";
import { IoInformationCircleOutline } from "react-icons/io5";

// ======== ICONS ========

// ======== CONSTANTS ========
const defaultIconStyles = {
  cursor: "pointer",
  fontSize: 17,
  verticalAlign: "middle",
};

// ======== COMPONENTS ========

export const InfoTooltip = ({ text, tooltipText, iconStyles = null }) => {
  return (
    <>
      {text + " "}
      <BtnTooltip placement="top" text={<h4>{tooltipText}</h4>}>
        <IoInformationCircleOutline style={iconStyles || defaultIconStyles} />
      </BtnTooltip>
    </>
  );
};

export default InfoTooltip;
