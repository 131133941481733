import { FaExclamationTriangle } from "react-icons/fa";

const ErrorMessage = ({ children, displayOnEmpty }) =>
  displayOnEmpty || children ? (
    <strong>
      {FaExclamationTriangle({
        size: "1.4rem",
        color: "#f44336",
      })}
      <span style={{ margin: 5 }}>{children}</span>
    </strong>
  ) : null;
export default ErrorMessage;
