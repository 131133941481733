import { Grid, TextField } from "@mui/material";
import { addMonths } from "date-fns";
import { debounce } from "lodash";
import he from "date-fns/locale/he";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import { hebStaticRanges } from "../../../components/datepicker/const";
import DateRangePicker from "../../../components/datepicker/DateRangePicker";
import DefinedRange from "../../../components/datepicker/date-range-picker/DefinedRange";
import Dropdown from "../../../components/dropdown/Dropdown";
import { fixDate } from "../../../helpers";

// ======== HOOKS ========
import { useScreenSize } from "../../../Hooks";
import MultiSelectCheckbox from "../../../components/dropdown/MultiSelectCheckBox";
import { SET_USER_LOGS_FILTERS } from "../../../redux/actions/userManageLogsAction/types";
import {
  getAdmins,
  getBranches,
  getNetworks,
} from "../../../redux/actions/userManageLogsAction";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const UserLogsFilters = (props) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();
  const dispatch = useDispatch();

  // ======== STATES ========
  const selectedDates = useSelector(
    (state) => state.UserLogs.filters.selectedDates
  );
  const selectedAdmin = useSelector(
    (state) => state.UserLogs.filters.selectedAdmins
  );
  const selectedNetworks = useSelector(
    (state) => state.UserLogs.filters.selectedNetworks
  );
  const selectedBranches = useSelector(
    (state) => state.UserLogs.filters.selectedBranches
  );
  const admins = useSelector((state) => state.UserLogs.adminList);
  const networks = useSelector((state) => state.UserLogs.networks);
  const branches = useSelector((state) => state.UserLogs.branches);

  const debounced = debounce(
    (e) => {
      dispatch({
        type: SET_USER_LOGS_FILTERS,
        payload: {
          name: e.target.id,
          value: e.target.value,
        },
      });
    },
    [500]
  );
  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  useEffect(() => {
    dispatch(getAdmins());
    dispatch(getNetworks());
    // dispatch(getBranches());
  }, []);
  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Grid container>
      <Grid item xs={2}>
        <Dropdown
          style={
            isMobile
              ? {
                  width: "50%",
                  fontSize: "1.4rem",
                  display: "block",
                  margin: "0",
                }
              : {
                  maxWidth: "16rem",
                  fontSize: "1.4rem",
                  display: "block",
                }
          }
          contentStyle={{ width: "fit-content" }}
          text={selectedDates.label}
        >
          {isMobile ? (
            <DefinedRange
              ranges={[selectedDates]}
              staticRanges={hebStaticRanges}
              local={he}
              onChange={(item) =>
                dispatch({
                  type: SET_USER_LOGS_FILTERS,
                  payload: { name: "selectedDates", value: item.selection },
                })
              }
            />
          ) : (
            <DateRangePicker
              selectedRange={selectedDates}
              setSelectedRange={(e) => {
                dispatch({
                  type: SET_USER_LOGS_FILTERS,
                  payload: { name: "selectedDates", value: e },
                });
              }}
            />
          )}
        </Dropdown>
      </Grid>
      <Grid item xs={2}>
        <MultiSelectCheckbox
          style={{ minWidth: "unset" }}
          title="נציג"
          checked={selectedAdmin}
          setChecked={(value) =>
            dispatch({
              type: SET_USER_LOGS_FILTERS,
              payload: { name: "selectedAdmins", value: value },
            })
          }
          options={admins.map(({ label, id }) => ({
            label,
            value: id,
          }))}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          placeholder="שם פרטי"
          id="userFirstName"
          size="small"
          onChange={(e) => debounced(e)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          placeholder="שם משפחה"
          id="userLastName"
          size="small"
          onChange={(e) => debounced(e)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          placeholder="סניף"
          id="branchName"
          size="small"
          onChange={(e) => debounced(e)}
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelectCheckbox
          style={{ minWidth: "unset" }}
          title="רשת"
          checked={selectedNetworks}
          setChecked={(value) =>
            dispatch({
              type: SET_USER_LOGS_FILTERS,
              payload: { name: "selectedNetworks", value: value },
            })
          }
          options={networks.map(({ label, id }) => ({
            label,
            value: id,
          }))}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogsFilters);
