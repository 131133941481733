import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { forwardRef } from "react";
import ErrorMessage from "../ErrorMessage";

const FormCheckBox = forwardRef(function (
  { name, label, disabled, defaultValue, rules, ...props },
  toolTipRef
) {
  const { control } = useFormContext();
  return (
    <Controller
      {...{
        control,
        name,
        ...(!!defaultValue && { defaultValue }),
        rules,
        render: ({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error },
        }) => (
          <>
            <FormControlLabel
              style={{ marginRight: "1rem" }}
              ref={toolTipRef}
              {...props}
              control={
                <Checkbox
                  {...{
                    value,
                    checked: !!value,
                    name,
                    onBlur,
                    inputRef: ref,
                    onSelect: onChange,
                    onChange,
                    disabled,
                  }}
                />
              }
              label={label}
            />
            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        ),
      }}
    />
  );
});

export default FormCheckBox;
