
import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-responsive-modal";
//import Button from "../../../components/buttons/Button";
import ReactTable from "../../../components/tables/ReactTable";
import { IoClose } from "react-icons/io5";
import {

  fixDate,
  thousandSeparator,
} from "../../../helpers";
import { ClipLoader } from "react-spinners";
import {

  MenuItem,
  Select ,

} from "@mui/material";
import {
  FaCheck,
  FaPencilAlt,
  FaSave,

} from "react-icons/fa";
import { useScreenSize } from "../../../Hooks";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import Axios from "axios";
import DropdownSelect from "../../../components/dropdown/DropdownSelect";
const CostsModal = ( {open, data, onClose, setViewToShow, message=true,title,token,loading,setLoading, selected_branch,setEmployerCosts,employerCosts={},fetchData}) => {
  const [selectedMonth, setSelectedMonth] = useState({
    id: -99,
    name: "בחר/י",
  });
  //console.log(employerCosts);
  const createEmployerCosts = useMemo(
    () => async (data,action) => {
      //console.log("create Goal");
      setLoading(true);
      if(action=="update")
        data.id = employerCosts.id;
      const res = await Axios.post(`/employerCostsByDate/${selected_branch.id}/${action}`, {  
          params: data,
          headers: { Authorization: `Bearer ${token}` },
        });
        //console.log(fetchData);
        fetchData();
        setLoading(false);
      },[token,
        setEmployerCosts,selected_branch,employerCosts]
  );
  const validDate = (dateStr)=>{
    if(!dateStr)
      return false;
    if((/[\u0590-\u05FF]/).test(dateStr))
      return false;
    if(dateStr.includes("/"))
      if(!isNaN(dateStr.split("/")[0]) && !isNaN(dateStr.split("/")[1]))
        return true;
    return false
  };
  const [modalVisible, setModalVisible] = useState(true);
  const { isMobile } = useScreenSize();
  const months = useMemo(
    () => {
      

      let createdDate = new Date();
      createdDate.setFullYear(createdDate.getFullYear() - 1);
      createdDate.setDate(1);
      let currentDate = new Date();
      let dateAndYearList = [fixDate(createdDate)];
      const monthsOb = {};
      while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
          dateAndYearList.unshift(fixDate(createdDate));
      }
      
      for(let d in dateAndYearList){
       
        monthsOb[fixDate(dateAndYearList[d])]=dateAndYearList[d].split("/")[1]+"/"+dateAndYearList[d].split("/")[2];
      }
      //console.log(monthsOb);
      return monthsOb;
    },
    []
  );
  return <Modal
  animationDuration={200}
  styles={{
    modal: {
      padding: "3.5rem",
      maxWidth: "fit-content",
     
    },
  }}
  
  onRequestClose={() => setModalVisible(false)}
  center={true}
  open={modalVisible}
  onClose={() => onClose()}
>
{loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ):(
  <div className="flex-column-center">
    
    <div className="fluid">
      <h2>עדכון עלות מעביד עבור</h2>
      <DropdownSelect
              selected={selectedMonth}
              setSelected={setSelectedMonth}
              style={{ width: "100%", margin: "auto",background:"white" } }
              onChange={e => 
                {
                  console.log(e);

                }  
              }
              options={Object.keys(months).map(
                (x) => {
                  return{id:x,name:months[x]};
                  }
                )}
             
            />
    </div>

    <div className="flex-column">
      <table>
        <tr>
          <td>
            <span>עדכון עלות מעביד לחודש {selectedMonth.name} </span>
          </td>
          <td>
            <span>פעולות</span>
          </td>
        </tr>
        <tr>
                <td>
                <div className="flex-column" style={{display: "inline-flex"}}>
                 <input id={"costlVal-"} 
                    //ref={(input) => { nameInput = input; }} 
                    autoFocus
                    style={{ width: "35%" }}
                    type="text"
                    onChange={

                      e=>{
                        const cloneEc = {...employerCosts}; 
                        cloneEc[selectedMonth.name] =  {
                          value:parseFloat(e.target.value),
                          precent:(cloneEc[selectedMonth.name]?.precent)
                        };
                        setEmployerCosts({...cloneEc});
                        
                      }
                    }
                    disabled={(validDate(selectedMonth?.name)) ? false:true}
                      name="goal" value={ (selectedMonth.name in employerCosts) ? employerCosts[selectedMonth.name].value:0} />
                              <select
                        disabled={(validDate(selectedMonth?.name)) ? false:true}
                        id={"ecostSel"} 
                        size="small"
                        value={ (selectedMonth.name in employerCosts) ? employerCosts[selectedMonth.name]?.precent:0}
                        name="precent"
                        onChange={(e) => {
                          const cloneEc = {...employerCosts};
                          cloneEc[selectedMonth.name] =  {value:cloneEc[selectedMonth.name]?.value,precent:e.target.value};
                          setEmployerCosts({...cloneEc});
                        }
                      }
                        style={{ width: "44px" }}>
                      
                        <option   key={"s"} value={0}>
                          {"₪"}
                        </option>
                        <option  key="p" value={1} >
                          {"%"}
                        </option>
                    </select>
                    </div>
                </td>
                <td>
                <div className="flex-column">
    <ButtonIcon              
                  onClick={() => {
                      //loading={isLoading}
                      const action = (employerCosts?.id) ? "update":"create";

                      createEmployerCosts(
                        {
                          branch_id:selected_branch.id,
                          costs:employerCosts
                        },
                        action
                      );
                    }
                  }
                  disabled={(validDate(selectedMonth?.name)) ? false:true}
                  className="btn--hover__confirm"
                  icon={FaSave}
                  alt="שמירה"
                />
                <ButtonIcon              
                  onClick={() => {
                      //loading={isLoading}
                      delete employerCosts[selectedMonth.name];
                      const action = (employerCosts?.id) ? "update":"create";

                      createEmployerCosts(
                        {
                          branch_id:selected_branch.id,
                          costs:employerCosts
                        },
                        action
                      );
                    }
                  }
                  disabled={(validDate(selectedMonth?.name)) ? false:true}
                  className="btn--hover__confirm"
                  icon={IoClose}
                  alt="מחיקה"
                />
     
      </div>     
                </td>
        </tr>
      </table>
       

    </div>     
    
  </div>
  
        )}
</Modal>;
}

export default CostsModal;
