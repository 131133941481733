import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { format } from "date-fns";
import {
  BASE_URL,
  DELETE_SUPPLIER_FILES,
  DELETE_SUPPLIER_GOAL,
  DOWNLOAD_ZIP_FILE,
  GET_SUPPLIER_FILES,
  GET_SUPPLIER_GOALS,
  GET_SUPPLIER_ITEM_ORDERS_SUMMARY,
  GET_SUPPLIER_ORDER_SUMMARY,
  UPDATE_SUPPLIER_GOAL,
  UPLOAD_SUPPLIER_FILES,
} from "../../pages/purchaseSummary/consts";
import { GET_SUPPLIERS } from "../../pages/expenseSummary/consts";
import { uniqueId } from "lodash";
import { CategoryGoalsStatus } from "../../pages/expenseSummary/modals/CategoryGoalsConsts";
import { setSnackBar } from "../actions/confirmAction";

const currentDate = new Date();

const INITIAL_STATE = {
  bulletsData: {},

  goals: {
    openSupplierGoalsModal: false,
    saveGoal: false,
    supplierGoals: [],
  },
  purchaseSummaryTables: {
    mainTable: [],
    subTable: [],
    subReceivedTable: [],
    subSentTable: [],

    isLoading: false,
    isLoadingSubTable: false,
    isLoadingItemOrdersTable: false,
    openSubTable: false,
    openItemOrdersTable: false,
    selectedSupplierFile: {
      files: [],
      id: null,
      name: null,
      fileIndex: null,
      openSupplierFileModal: false,
    },
  },
  filters: {
    suppliers: [],
    branches: [],
    selectedDates: {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 1
      ),
      key: "selection",
      label: "החודש",
    },
    branch_id: null,
    suppliers_ids: [],
  },
  selectedTab: 0,
  isLoading: false,
};
const purchaseSummarySlice = createSlice({
  name: "purchaseSummary",
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },

    resetState: (state, action) => {
      state.bulletsData = INITIAL_STATE.bulletsData;
      state.expenseTables = INITIAL_STATE.expenseTables;
      state.isLoading = INITIAL_STATE.isLoading;
    },
    setGoalEdit: (state, action) => {
      const supplier = action.payload.supplier;
      supplier.show = action.payload.show;
      const supplierIndex = state.goals.supplierGoals.findIndex(
        (supplier) =>
          supplier.supplier_id === action.payload.supplier.supplier_id
      );

      state.goals.supplierGoals[supplierIndex] = supplier;
    },
    updateGoalsField: (state, action) => {
      const { index, supplier } = action.payload;
      state.goals.supplierGoals[index] = supplier;
    },
    openGoalsModal: (state, action) => {
      state.goals.openSupplierGoalsModal = action.payload;
    },
    deleteSupplierGoal: (state, action) => {
      state.goals.supplierGoals = state.goals.supplierGoals.filter(
        (supplier) => supplier.supplier_id !== action.payload
      );
    },
    addSupplierGoal: (state, action) => {
      state.goals.supplierGoals.push({
        id: uniqueId("new_goal"),
        supplier_name: null,
        goal: null,
        status: CategoryGoalsStatus.TYPE_PENDING,
        show: true,
        is_new: true,
      });
    },
    setSupplierFileModal: (state, action) => {
      state.purchaseSummaryTables.selectedSupplierFile.openSupplierFileModal =
        action.payload.openModal;
      state.purchaseSummaryTables.selectedSupplierFile.id = action.payload.id;
      state.purchaseSummaryTables.selectedSupplierFile.name =
        action.payload.name;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPurchaseSummaryData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPurchaseSummaryData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bulletsData = action.payload.bullets_data;
      state.purchaseSummaryTables.mainTable = action.payload.supplier_table;
      //   state.purchaseSummaryTables.mainSuppliersTable =
      //     action.payload.order_by_suppliers;
    });
    builder.addCase(fetchPurchaseSummaryData.rejected, (state, action) => {
      state.isLoading = false;
      state.bulletsData = {};
      //   state.purchaseSummaryTables.mainTable = [];
    });
    builder.addCase(getSuppliers.fulfilled, (state, action) => {
      state.filters.suppliers = action.payload.map(({ id, name }) => ({
        label: name,
        value: parseInt(id),
      }));
    });
    builder.addCase(getSuppliers.rejected, (state, action) => {
      state.filters.suppliers = [];
    });

    builder.addCase(getSupplierOrderSummary.pending, (state, action) => {
      state.purchaseSummaryTables.isLoadingSubTable = true;
      state.purchaseSummaryTables.openSubTable = true;
      state.purchaseSummaryTables.subTable = [];
    });
    builder.addCase(getSupplierOrderSummary.fulfilled, (state, action) => {
      state.purchaseSummaryTables.isLoadingSubTable = false;

      action.payload.forEach((supplier, index) => {
        if (!action.payload[index]["purchase_precent"]) {
          action.payload[index]["purchase_precent"] = 0;
        }
        action.payload[index]["purchase_precent"] =
          (supplier.total_received_without_vat /
            state.bulletsData.food_cost_no_vat) *
          100;
      });
      state.purchaseSummaryTables.subTable = action.payload;
    });
    builder.addCase(getSupplierOrderSummary.rejected, (state, action) => {
      state.purchaseSummaryTables.isLoadingSubTable = false;
      state.purchaseSummaryTables.openSubTable = false;

      state.purchaseSummaryTables.subTable = [];
    });
    builder.addCase(getSupplierItemOrdersSummary.pending, (state, action) => {
      state.purchaseSummaryTables.isLoadingItemOrdersTable = true;
      state.purchaseSummaryTables.openItemOrdersTable = true;
      state.purchaseSummaryTables.subReceivedTable = [];
      state.purchaseSummaryTables.subSentTable = [];
    });
    builder.addCase(getSupplierItemOrdersSummary.fulfilled, (state, action) => {
      state.purchaseSummaryTables.isLoadingItemOrdersTable = false;

      if (action.payload.received_orders) {
        action.payload.received_orders.forEach((received, index) => {
          action.payload.received_orders[index]["purchase_precent_received"] =
            (received.total_received_without_vat /
              state.bulletsData.food_cost_no_vat) *
            100;
        });
        state.purchaseSummaryTables.subReceivedTable =
          action.payload.received_orders;
      }
      if (action.payload.sent_orders) {
        action.payload.sent_orders.forEach((sent, index) => {
          action.payload.sent_orders[index]["purchase_precent_sent"] =
            (sent.total_sent_without_vat / state.bulletsData.food_cost_no_vat) *
            100;
        });
        state.purchaseSummaryTables.subSentTable = action.payload?.sent_orders;
      }
    });
    builder.addCase(getSupplierItemOrdersSummary.rejected, (state, action) => {
      state.purchaseSummaryTables.isLoadingItemOrdersTable = false;
      state.purchaseSummaryTables.openItemOrdersTable = false;

      state.purchaseSummaryTables.subReceivedTable = [];
      state.purchaseSummaryTables.subSentTable = [];
    });
    builder.addCase(getSupplierGoals.fulfilled, (state, action) => {
      state.goals.supplierGoals = action.payload;
    });
    builder.addCase(updateSupplierGoal.pending, (state, action) => {
      state.goals.saveGoal = true;
    });
    builder.addCase(updateSupplierGoal.fulfilled, (state, action) => {
      const { supplierIndex, updatedSupplier } = action.payload;

      state.goals.supplierGoals[supplierIndex] = updatedSupplier;
      const mainTableSupIndex = state.purchaseSummaryTables.mainTable.findIndex(
        (item) => item.supplier_id === updatedSupplier.supplier_id
      );

      if (state.purchaseSummaryTables.mainTable[mainTableSupIndex]) {
        state.purchaseSummaryTables.mainTable[mainTableSupIndex].goal =
          updatedSupplier.goal;
      }

      state.goals.saveGoal = false;
    });
    builder.addCase(updateSupplierGoal.rejected, (state, action) => {
      state.goals.saveGoal = false;
    });
    builder.addCase(deleteSupplierGoal.fulfilled, (state, action) => {
      state.goals.supplierGoals = state.goals.supplierGoals.filter(
        (goal) => goal.id !== action.payload
      );
    });
    builder.addCase(getSupplierFiles.pending, (state, action) => {});
    builder.addCase(getSupplierFiles.fulfilled, (state, action) => {
      state.purchaseSummaryTables.selectedSupplierFile.files = action.payload;
    });
    builder.addCase(getSupplierFiles.rejected, (state, action) => {
      state.purchaseSummaryTables.selectedSupplierFile.files = [];
    });
  },
});

export const fetchPurchaseSummaryData = createAsyncThunk(
  "purchaseSummary/fetchPurchaseSummaryData",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().purchaseSummary.filters;

    const res = await Axios.get(BASE_URL, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branches,
        suppliers_ids: filters.suppliers_ids,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);
export const getSuppliers = createAsyncThunk(
  "purchaseSummary/getSuppliers",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().purchaseSummary.filters;

    const res = await Axios.get(`networks/${GET_SUPPLIERS}`, {
      params: {
        branch_id: filters.branches,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const getSupplierOrderSummary = createAsyncThunk(
  "purchaseSummary/getSupplierOrderSummary",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().purchaseSummary.filters;

    const res = await Axios.get(`${BASE_URL}/${GET_SUPPLIER_ORDER_SUMMARY}`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branches,
        supplier_id: [payload],
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const getSupplierItemOrdersSummary = createAsyncThunk(
  "purchaseSummary/getSupplierItemOrdersSummary",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().purchaseSummary.filters;

    const res = await Axios.get(
      `${BASE_URL}/${GET_SUPPLIER_ITEM_ORDERS_SUMMARY}`,
      {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branches,
          item_id: payload,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return data;
  }
);
export const getSupplierGoals = createAsyncThunk(
  "purchaseSummary/getSupplierGoals",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const branch_id = getState().branches.selected_branch.id;

    const res = await Axios.get(`${BASE_URL}/${GET_SUPPLIER_GOALS}`, {
      params: {
        branch_id: branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const goals = await res.data;

    const modedGoals = goals?.map((goal) => ({ ...goal, show: false }));

    return modedGoals;
  }
);
export const updateSupplierGoal = createAsyncThunk(
  "purchaseSummary/updateSupplierGoal",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().expenseSummary.filters;
    const branch_id = getState().branches.selected_branch.id;

    delete payload.supplier.show;
    if (
      typeof payload.supplier.id === "string" &&
      payload.supplier.id.includes("new_goal")
    ) {
      delete payload.supplier.id;
    }
    const res = await Axios.post(
      `${BASE_URL}/${UPDATE_SUPPLIER_GOAL}`,
      { payload: payload.supplier },
      {
        params: {
          branch_id: branch_id,
          supplier_id: payload.supplier.supplier_id,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.status === 200 || res.status === 201) {
      const updatedSupplier = res.data;
      return { updatedSupplier, supplierIndex: payload.supplierIndex };
    }
  }
);

export const deleteSupplierGoal = createAsyncThunk(
  "expenseSummary/deleteSupplierGoal",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    try {
      const res = await Axios.delete(`${BASE_URL}/${DELETE_SUPPLIER_GOAL}`, {
        params: {
          supplier_id: payload,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.status === 200) {
        return payload;
      }
    } catch (err) {}
  }
);
export const uploadSupplierFiles = createAsyncThunk(
  "purchaseSummary/uploadSupplierFiles",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const supplier_id =
      getState().purchaseSummary.purchaseSummaryTables.selectedSupplierFile.id;

    const res = await Axios.post(
      `${BASE_URL}/${UPLOAD_SUPPLIER_FILES}`,
      payload,
      {
        params: {
          supplier_id: supplier_id,
        },

        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return data;
  }
);

export const getSupplierFiles = createAsyncThunk(
  "purchaseSummary/getSupplierFiles",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const res = await Axios.get(`${BASE_URL}/${GET_SUPPLIER_FILES}`, {
      params: {
        supplier_id: payload,
      },

      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const deleteSupplierFiles = createAsyncThunk(
  "purchaseSummary/deleteSupplierFiles",
  async (payload, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await Axios.delete(`${BASE_URL}/${DELETE_SUPPLIER_FILES}`, {
        params: {
          file_id: payload,
        },

        headers: { Authorization: `Bearer ${token}` },
      });

      const data = await res.data;
      dispatch(setSnackBar(data.message));

      return data;
    } catch (error) {
      dispatch(setSnackBar(error.message, false));

      console.log(error.message);
    }
  }
);
export const downloadZipFile = createAsyncThunk(
  "purchaseSummary/downloadZipFile",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const res = await Axios.get(`${BASE_URL}/${DOWNLOAD_ZIP_FILE}`, {
      params: {
        supplier_id: payload,
      },

      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    const link = document.createElement("a");
    link.href = data;
    link.click();
    return data;
  }
);
export const {
  reducer: purchaseSummaryReducer,
  actions: purchaseSummaryActions,
} = purchaseSummarySlice;
