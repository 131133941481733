import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { mapKeys } from "lodash";
import DropdownSelect from "../../../components/dropdown/DropdownSelect";

import { changeKeyObjects } from "../../../helpers";
import Axios from "axios";
import { objectToFormData } from "../../../components/Forms/helpers";
import { setConfirm } from "../../../redux/actions/confirmAction";
import { startLoading, stopLoading } from "../../../redux/actions/loaderAction";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import {
  useMediaQuery,
  useTheme,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";

// ======== ICONS ========
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import ExcelButton from "../../../components/buttons/Button";
import {
  FaBook,
  FaExclamationTriangle,
  FaRegPaperPlane,
  FaEnvelope,
  FaFileExcel,
  FaSms,
} from "react-icons/fa";
import { styled } from "@mui/material/styles";
import { departmentColumns } from "./departmentColumns";
import { FaPlus } from "react-icons/fa";

// ======== CONSTANTS ========
import {
  EMPLOYEE_CARD_SETTINGS_URL,
  BREAK_CODE_DEFAULT,
  SUM_NUMBER_DEFAULT,
  EXCEL_DOWNLOAD_URL,
  ConfirmButton,
  CancelButton,
  formatDate,
  COLOR_SUBMIT,
} from "./consts";

// ======== COMPONENTS ========
import ReactTable from "../../../components/tables/ReactTable";
import { setError } from "../../../redux/actions/errorAction";

export const Excel101Settings = ({
  openExcel101Settings,
  setOpenExcel101Settings,
  checkedBranches,
  date,
  checkedDepartments,
  checkedEmployees,
}) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  const { roles } = useSelector((state) => state);
  const { token } = useSelector((state) => ({
    token: state.auth.token,
  }));

  // ======== STATES ========
  const [options, setOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [employeeCardSettings, setEmployeeCardSettings] = useState({
    id: null,
    branch_id: selectedBranch.id,
    department_settings: [],
    break_code: BREAK_CODE_DEFAULT,
    sum_number: SUM_NUMBER_DEFAULT,
    isLoading: false,
  });
  const [currentBranch, setCurrentBranch] = useState({});
  const DEFAULT_SETTINGS = {
    id: null,
    department_settings: [],
    break_code: BREAK_CODE_DEFAULT,
    sum_number: SUM_NUMBER_DEFAULT,
    isLoading: false,
  };

  // ======== HANDLERS ========
  const handleCloseItemList = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpenExcel101Settings(false);
    setAnchorEl(null);
  };
  const downloadExcelData = async () => {
    await download101ExcelReport(checkedBranches);
    setOpenExcel101Settings(false);
  };

  const handleSave = async () => {
    await saveEmployeeFormSettings();
    setOpenExcel101Settings(false);
    fetchEmployeeCardFormBranchSetting(checkedBranches); // After save insert fetch again the data and set the first item
  };

  const handleDelete = (row) => {
    const departmentId = row.department_id;

    setCurrentBranch((prevSettings) => ({
      ...prevSettings,
      department_settings: prevSettings.department_settings.filter(
        (setting) => setting.department_id !== departmentId
      ),
    }));
  };

  const handleMenuItemClick = (option) => {
    const newDepartment = {
      department_id: option.value,
      department_name: option.label,
      employee_card_form_settings_id: employeeCardSettings.id,
      value: 0,
    };
    setCurrentBranch((prevSettings) => ({
      ...prevSettings,
      department_settings: [...prevSettings.department_settings, newDepartment],
    }));
  };

  const handleInputChange = (name, value) => {
    if (value == "") {
      value = name == "break_code" ? BREAK_CODE_DEFAULT : SUM_NUMBER_DEFAULT;
    }
    setCurrentBranch((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const moreButtons = (
    <>
      <Tooltip title="הוספת מחלקה" arrow>
        <IconButton
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }}
        >
          <FaPlus size="2.5rem" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseItemList}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleMenuItemClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  const memoColumns = useMemo(() => departmentColumns, []);

  const setCurrentBranchSettings = (id) => {
    let branch = employeeCardSettings.find(
      (branchSettings) => branchSettings.branch_id === id
    );
    if (branch) {
      setCurrentBranch(branch);
    } else {
      setCurrentBranch({ ...DEFAULT_SETTINGS, branch_id: id });
    }
  };

  const handleBranchChange = (branch) => {
    setSelectedBranch(branch);
    setCurrentBranchSettings(branch.id);
  };

  const handleChangeDepartmentValue = (event, row) => {
    let { value } = event.target;
    if (value == "") {
      value = 0;
    }
    const departmentId = row.department_id;

    setCurrentBranch((prevSettings) => ({
      ...prevSettings,
      department_settings: (prevSettings.department_settings || []).map(
        (setting) =>
          setting.department_id == departmentId
            ? { ...setting, value }
            : setting
      ),
    }));
  };

  // EFFECTS
  useEffect(() => {
    const existingDepartmentIds = currentBranch?.department_settings?.map(
      (dept) => dept.department_id
    );
    let departmentsOptions = mapKeys(roles, "department_id");
    departmentsOptions = Object.values(departmentsOptions);
    departmentsOptions = changeKeyObjects(departmentsOptions, {
      department_name: "label",
      department_id: "value",
    });

    departmentsOptions = departmentsOptions.filter(
      (option) => !existingDepartmentIds?.includes(option.value)
    );

    setOptions(departmentsOptions);
  }, [roles, currentBranch]);

  useEffect(() => {
    if (checkedBranches.length > 0) {
      setSelectedBranch(checkedBranches[0]);
    }
  }, [checkedBranches]);

  useEffect(() => {
    fetchEmployeeCardFormBranchSetting(checkedBranches);
  }, []);

  // ======== API calls ========

  const fetchEmployeeCardFormBranchSetting = async (branches) => {
    try {
      const branchIds = branches.map((branch) => branch.id);
      const response = await Axios.get(`${EMPLOYEE_CARD_SETTINGS_URL}`, {
        params: { branch_ids: branchIds },
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.length === 0) {
        const defaultSettings = branches.map((branch) => ({
          branch_id: branch.id,
          break_code: BREAK_CODE_DEFAULT,
          sum_number: SUM_NUMBER_DEFAULT,
          department_settings: [],
          isLoading: false,
        }));
        setEmployeeCardSettings([defaultSettings]);
        setCurrentBranch(defaultSettings[0]);
      } else {
        setEmployeeCardSettings(response.data);
        setCurrentBranch(response.data[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle 404 error by setting default values for all branches
        const defaultSettings = branches.map((branch) => ({
          branch_id: branch.id,
          break_code: BREAK_CODE_DEFAULT,
          sum_number: SUM_NUMBER_DEFAULT,
          department_settings: [],
          isLoading: false,
        }));
        setEmployeeCardSettings(defaultSettings);
        setCurrentBranch(defaultSettings);
      }
    }
  };

  const saveEmployeeFormSettings = async () => {
    const formData = objectToFormData(currentBranch);
    if (!currentBranch.id) {
      try {
        dispatch(startLoading("טוען..."));

        const response = await Axios.post(
          `${EMPLOYEE_CARD_SETTINGS_URL}`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          dispatch(setConfirm("נוצר בהצלחה!"));
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
      dispatch(stopLoading());
    } else {
      try {
        dispatch(startLoading("טוען..."));

        const response = await Axios.post(
          `${EMPLOYEE_CARD_SETTINGS_URL}/${currentBranch.id}`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          dispatch(setConfirm("עודכן בהצלחה!"));
        }
      } catch (error) {
        console.error("Error updating data:", error);
      }
      dispatch(stopLoading());
    }
  };

  const download101ExcelReport = async (branches) => {
    const startDate = formatDate(date.start);
    const endDate = formatDate(date.end);

    try {
      dispatch(startLoading("טוען..."));

      const branchIds = branches.map((branch) => branch.id);
      const response = await Axios.get(
        `${EMPLOYEE_CARD_SETTINGS_URL}/${EXCEL_DOWNLOAD_URL}`,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            branch_ids: branchIds,
            checked_departments: checkedDepartments,
            checked_employees: checkedEmployees,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const { data } = response;
      const downloadLink = document.createElement("a");
      // downloadLink.href = data.path;
      downloadLink.href = data.fileData;
      downloadLink.download = data.fileName;
      // downloadLink.target = "_blank";
      downloadLink.click();
    } catch (error) {
      if (error) {
        dispatch(setError("התרחשה תקלה"));
      }
    }
    dispatch(stopLoading());
  };

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Dialog
      open={openExcel101Settings}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="settings-requisition-dialog-title">
        <div className="flex-between-center">
          <Box fontSize="20px">ייצוא</Box>
        </div>
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogContent>
        <ExcelButton
          onClick={() => downloadExcelData()}
          className="btn--submit"
          icon={FaFileExcel}
          textValue="ייצוא נתוני 101 לאקסל"
        />
      </DialogContent>
      <Divider sx={{ backgroundColor: "black" }} />
      <DialogContent>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <h2>הגדרות למערכת שכר</h2>
            </Grid>
            <Grid item xs={5}>
              <label>
                בחר סניף לעדכון
                <DropdownSelect
                  style={{ maxWidth: "100%" }}
                  selected={selectedBranch}
                  setSelected={(selected) => handleBranchChange(selected)}
                  options={checkedBranches}
                  isSearchable={false}
                />
              </label>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={10} lg={4}>
              <TextField
                required
                name="break_code"
                value={currentBranch?.break_code}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  handleInputChange("break_code", e.target.value)
                }
                type="number"
                label="קוד הפסקה"
                size="small"
              />
            </Grid>
            <Grid item xs={10} lg={4}>
              <TextField
                required
                name="sum_number"
                value={currentBranch?.sum_number}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  handleInputChange("sum_number", e.target.value)
                }
                type="number"
                label="מס מצטבר"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <ReactTable
                // styles={styles}
                data={currentBranch?.department_settings ?? []}
                columns={memoColumns}
                cellsPropsById={(id) => {
                  return {
                    handleChangeDepartmentValue,
                    handleDelete,
                  };
                }}
                moreButtons={moreButtons}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <CancelButton onClick={handleClose}>{"ביטול"}</CancelButton>
        </Grid>
        <Grid item>
          <ConfirmButton onClick={handleSave}>{"אישור"}</ConfirmButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Excel101Settings;
