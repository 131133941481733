// Restore

import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validator from "validator";
import { I18n } from "react-redux-i18n";
import Button from "../components/buttons/Button";
import { getClassDirection } from "../helpers";
import PasswordRestore from "./PasswordRestore";
import { restorePassword } from "../redux/actions/loginAction";
import logo from "../images/Logo_Resigo_pat.png";
import ErrorModal from "../components/modals/ErrorModal";
import BarLoader from "react-spinners/BarLoader";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { SET_ERROR } from "../redux/actions/errorAction/types";

class Restore extends React.Component {
  constructor(props) {
    super(props);
    this.state = { OTPModal: null, loader: false, OTPModalError: null };
  }

  renderError = ({ error, touched }) => {
    if (touched && error)
      return (
        <p>
          * <span className="error-msg">{error}</span>{" "}
        </p>
      );
  };

  //called in <Field>(redux-form tag) and render the input by the props
  //input is a field in the <Field> tag that handle the input behind the scenes
  renderInput = ({ type, placeholder, input, meta, className, ...rest }) => {
    return (
      <div className={className}>
        <input type={type} placeholder={placeholder} {...input} {...rest} />
        {this.renderError(meta)}
      </div>
    );
  };

  setOTPModal = (OTPModal) => {
    this.setState({ OTPModal });
  };
  setOTPModalError = (OTPModalError) => {
    console.log(OTPModalError);
    this.setState({ OTPModalError });
  };
  setLoader = (loader) => {
    this.setState({ loader });
  };

  onSubmit = (formValues) => {
    if (
      (!this.state.OTPModal || this.state.OTPModal?.attemps <= 5) &&
      this.state.OTPModalError?.error_code !== 429
    ) {
      this.setLoader(true);
      this.props.restorePassword(
        formValues,
        this.setOTPModal,
        this.setLoader,
        this.setOTPModalError
      );
    } else {
      this.props.dispatch({
        type: SET_ERROR,
        errorTitle: `שגיאת אבטחה`,
        error:
          "חשבונך נחסם עקב ריבוי נסיונות נסה שוב מאוחר יותר. \r\n ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
      });
    }
  };

  render() {
    const { locale } = this.props;
    return (
      <div className="login">
        <ErrorModal />
        <div
          style={{ height: "70%" }}
          className={`login__card ${getClassDirection(locale)}`}
        >
          <div className="flex-center">
            <img style={{ width: "50%" }} src={logo} alt="restigo logo" />
          </div>
          <h1>שחזור סיסמא </h1>
          {/* <p className="details-primary">הזן מייל משתמש לצורך וידוא</p> */}
          {!this.state.OTPModal && (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              {/* <Field
              name="identifier"
              type="text"
              icon="user"
              className="login__card--input"
              placeholder="מספר תעודת זהות"
              component={this.renderInput}
              />
              <Field
              name="phone_number"
              type="text"
              icon="user"
              className="login__card--input"
              placeholder="טלפון"
              component={this.renderInput}
            /> */}
              <label>
                הזן אימייל משתמש לצורך וידוא
                <Field
                  name="email"
                  type="email"
                  icon="user"
                  className="login__card--input"
                  placeholder="אימייל"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                  component={this.renderInput}
                />
              </label>

              <Button
                type="submit"
                className="btn--submit-login"
                textValue="שלח"
                // disabled={this.state.OTPModalError?.error_code === 429}
              />
              <p className="details-primary u-center-text">
                באם אינך זוכר את כתובת האימייל ניתן לפנות לתמיכת Restigo במייל:
                <a href="mailto:recipient@example.com?subject=%D7%A9%D7%99%D7%A0%D7%95%D7%99%20%D7%9E%D7%99%D7%99%D7%9C&body=%D7%94%D7%99%D7%99%20%D7%90%D7%99%D7%9F%20%D7%91%D7%90%D7%A4%D7%A9%D7%A8%D7%95%D7%AA%D7%99%20%D7%9C%D7%A9%D7%97%D7%96%D7%A8%20%D7%90%D7%AA%20%D7%94%D7%9E%D7%99%D7%99%D7%9C%20%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A2%D7%96%D7%A8%D7%AA%D7%9B%D7%9D">
                  support@restigo.co.il
                </a>
                <br />
                לחילופין ניתן לפנות לגורם האחראי על המערכת במקום העבודה
              </p>
              <div className=" u-center-text">
                <Link className="btn" to="/users/login">
                  <Button
                    className="btn btn--normal"
                    onClick={() => {}}
                    textValue="חזרה לכניסה"
                    icon={FaArrowRight}
                  />
                </Link>
              </div>
              <BarLoader
                color="#0090d0"
                height={8}
                width={"100%"}
                loading={this.state.loader}
              />
            </form>
          )}

          <div
            className={`slide ${
              this.state.OTPModal !== null ? "slide--expand" : ""
            }`}
          >
            <PasswordRestore
              setOTPModal={this.setOTPModal}
              setLoader={this.setLoader}
              dispatch={this.props.dispatch}
              restorePassword={this.props.restorePassword}
              onClose={() => this.setState({ OTPModal: null })}
              open={this.state.OTPModal !== null}
              {...this.state.OTPModal}
            />
          </div>
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) errors.email = I18n.t("login.errors.email_missing");
  else if (!validator.isEmail(formValues.email))
    errors.email = I18n.t("login.errors.email_format");
  //   if (!formValues.phone_number) errors.phone_number = "הזן מספר טלפון";
  //   else if (!validator.isNumeric(formValues.phone_number))
  //     errors.phone_number = "שדה זה מקבל רק מספרים";
  //   else if (formValues.phone_number.length !== 10)
  //     errors.phone_number = "נדרש מספר באורך 10 ספרות";
  //   if (!formValues.identifier) errors.identifier = "הזן מספר ת.ז.";
  //   else if (!validator.isNumeric(formValues.identifier))
  //     errors.identifier = "שדה זה מקבל רק מספרים";
  //   else if (formValues.identifier.length !== 9)
  //     errors.identifier = "נדרש מספר ת.ז. באורך 9 ספרות";

  return errors;
};

const mapStateToProps = (state) => {
  return { locale: state.i18n.locale };
};

const formWrapped = reduxForm({
  form: "restore_user",
  enableReinitialize: true,
  validate,
})(Restore);

export default connect(mapStateToProps, { restorePassword })(formWrapped);
