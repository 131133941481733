import React, { useEffect } from "react";
import { useState } from "react";
import Switch from "react-switch";

const SwitchInput = ({
  checked_default = false,
  onChange,
  label = "",
  no_color = false,
  className = "",
  disabled = false,
  strong = false,
  extraButton,
  style = {},
  size = null,
  secondary = false,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(checked_default);
  }, [checked_default]);

  const handleChange = (value) => {
    setChecked(value);
    onChange(value);
  };
  return (
    <div className={`switch ${className}`} style={{...style}}>
      <label className={`${strong ? "strong" : ""} switch__label${secondary ? "--secondary" : ""} `}>
        {label}
      </label>
      <div className="flex-end" style={{ width: "fit-content" }}>
        {extraButton}
        <Switch
          disabled={disabled}
          height={size ? size / 2 : 18}
          width={size ? size : 36}
          onChange={handleChange}
          checked={checked}
          offColor={no_color ? undefined : "#db1e1e"}
          onColor={no_color ? "#00b1ff" : "#47cc00"}
        />
      </div>
    </div>
  );
};

export default SwitchInput;
