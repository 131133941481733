import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  startOfYear,
} from "date-fns";

const thisYear = startOfYear(new Date());
export const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  medianOne: {
    startDate: thisYear,
    endDate: endOfMonth(addMonths(thisYear, 5)),
  },
  medianTwo: {
    startDate: addMonths(thisYear, 5),
    endDate: endOfMonth(addMonths(thisYear, 11)),
  },
  quarterlyOne: {
    startDate: thisYear,
    endDate: endOfMonth(addMonths(thisYear, 2)),
  },
  quarterlyTwo: {
    startDate: addMonths(thisYear, 2),
    endDate: endOfMonth(addMonths(thisYear, 5)),
  },
  quarterlyThree: {
    startDate: addMonths(thisYear, 5),
    endDate: endOfMonth(addMonths(thisYear, 8)),
  },
  quarterlyFour: {
    startDate: addMonths(thisYear, 8),
    endDate: endOfMonth(addMonths(thisYear, 11)),
  },
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

const createStaticRanges = (ranges) => {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
};

export const hebStaticRanges = createStaticRanges([
  {
    label: "היום",
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: "אתמול",
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: "השבוע",
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: "שבוע שעבר",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: "החודש",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: "חודש שעבר",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: "רבעון 1",
    range: () => defineds.quarterlyOne,
  },
  {
    label: "רבעון 2",
    range: () => defineds.quarterlyTwo,
  },
  {
    label: "רבעון 3",
    range: () => defineds.quarterlyThree,
  },
  {
    label: "רבעון 4",
    range: () => defineds.quarterlyFour,
  },
  {
    label: "חציון 1",
    range: () => defineds.medianOne,
  },
  {
    label: "חציון 2",
    range: () => defineds.medianTwo,
  },
]);
