import Axios from "axios";
import { FaExclamationTriangle } from "react-icons/fa";
import { COLOR_WARNINGS } from "../../../const/colors";
import { SET_CONFIRM_BAR } from "../confirmAction/types";
import { SET_DIALOG } from "../DialogAction/types";
import { SET_ERROR } from "../errorAction/types";
import { LOGOUT } from "../loginAction/types";

export const sendSmsToUser =
  ({ employee_name, employee_id, text }) =>
  async (dispatch, getState) => {
    try {
      const response = await Axios.post(
        `smsMessage`,
        { employee_id, text },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      dispatch({
        type: SET_CONFIRM_BAR,
        confirmMsg: "SMS נשלח",
      });
    } catch (e) {
      console.error(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 409) {
          if (e.response.data.code === 2) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "מספר נייד לא תקין",
              warning: true,
              error: `SMS לא נשלח ל${employee_name}, יש לתקן מספר טלפון בעמוד ניהול עובדים.`,
            });
          } else {
            dispatch({
              type: SET_ERROR,
              errorTitle: "נדרש לחדש חבילת SMS",
              warning: true,
              error: `SMS לא נשלח ל${employee_name}. לצורך שליחת SMS יש לרכוש בנק SMS חדש , לפרטים נוספים ניתן לפנות לצוות רסטיגו.`,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      }
      console.error(e);
    }
  };

export const sendMultipleSmsToDepartments =
  ({ department_ids, text, branch_id }) =>
  async (dispatch, getState) => {
    try {
      const { data } = await Axios.post(
        `smsMessage/multi`,
        { department_ids, text,branch_id },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      if (data?.error) {
        dispatch({
          type: SET_DIALOG,
          title: "SMS נשלחו",
          text: (
            <div className="flex-column-center">
              <div className="strong">
                <FaExclamationTriangle color={COLOR_WARNINGS} />
                <span style={{ margin: ".5rem" }}>
                  נדרש עדכון למספרי הנייד של העובדים הבאים:
                </span>
              </div>
              <ol className="fit-content u-right-text">
                {data.error.data.map((x) => (
                  <li className="fit-content" key={x.id}>{`${x.firstName} ${
                    x.lastName
                  }, מס' נייד עדכני: ${
                    x.phoneNumber ? x.phoneNumber : "לא קיים"
                  }`}</li>
                ))}
              </ol>
            </div>
          ),
          iconJSX: () =>
            FaExclamationTriangle({ size: "7.2em", color: COLOR_WARNINGS }),
          showAbortButton: false,
          showConfirmButton: false,
        });
      } else {
        dispatch({
          type: SET_CONFIRM_BAR,
          confirmMsg: "SMS נשלחו",
        });
      }
    } catch (e) {
      console.error(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 409) {
          
            dispatch({
              type: SET_ERROR,
              errorTitle: "נדרש לחדש חבילת SMS",
              warning: true,
              error: `SMS לא נשלחו. לצורך שליחת SMS יש לרכוש בנק SMS חדש , לפרטים נוספים ניתן לפנות לצוות רסטיגו.`,
            });
          
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      }
      console.error(e);
    }
  };
  
export const sendDaySmsToDepartments =
  ({  text, employee_ids }) =>
  async (dispatch, getState) => {
    try {
      const { data } = await Axios.post(
        `smsMessage/multi/byEmployeeIds`,
        {  text,employee_ids },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      if (data?.error) {
        dispatch({
          type: SET_DIALOG,
          title: "SMS נשלחו",
          text: (
            <div className="flex-column-center">
              <div className="strong">
                <FaExclamationTriangle color={COLOR_WARNINGS} />
                <span style={{ margin: ".5rem" }}>
                  נדרש עדכון למספרי הנייד של העובדים הבאים:
                </span>
              </div>
              <ol className="fit-content u-right-text">
                {data.error.data.map((x) => (
                  <li className="fit-content" key={x.id}>{`${x.firstName} ${
                    x.lastName
                  }, מס' נייד עדכני: ${
                    x.phoneNumber ? x.phoneNumber : "לא קיים"
                  }`}</li>
                ))}
              </ol>
            </div>
          ),
          iconJSX: () =>
            FaExclamationTriangle({ size: "7.2em", color: COLOR_WARNINGS }),
          showAbortButton: false,
          showConfirmButton: false,
        });
      } else {
        dispatch({
          type: SET_CONFIRM_BAR,
          confirmMsg: "SMS נשלחו",
        });
      }
    } catch (e) {
      console.error(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 409) {
          
            dispatch({
              type: SET_ERROR,
              errorTitle: "נדרש לחדש חבילת SMS",
              warning: true,
              error: `SMS לא נשלחו. לצורך שליחת SMS יש לרכוש בנק SMS חדש , לפרטים נוספים ניתן לפנות לצוות רסטיגו.`,
            });
          
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      }
      console.error(e);
    }
  };

export const addSmsBank =
  ({  amount, branch_id }) =>
  async (dispatch, getState) => {
    try {
      const { data } = await Axios.post(
        `smsRequests`,
        { amount,branch_id },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
    
        dispatch({
          type: SET_CONFIRM_BAR,
          confirmMsg: "בקשה נשלחה בהצלחה",
        });
      
    } catch (e) {
      console.error(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 409) {
          
            dispatch({
              type: SET_ERROR,
              errorTitle: "קיימת בקשה ממתינה",
              warning: true,
              error: `קיימת בקשה להוספת SMS, לפרטים נוספים ניתן לפנות לצוות רסטיגו.`,
            });
          
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      }
      console.error(e);
    }
  };
