import {
  END_LOADING_ALLERGENS,
  END_LOADING_CATALOG,
  END_LOADING_DETAILS_CATEGORY,
  END_LOADING_FREE_SEARCH,
  END_LOADING_ITEM_DETAILS,
  END_LOADING_MASTER_CATEGORY,
  END_LOADING_MASTER_ITEMS,
  END_LOADING_POS_CODES,
  END_LOADING_PRICE_GAPS,
  END_LOADING_PRODUCTS,
  END_LOADING_SUPPLIERS,
  END_LOADING_SWAP_INGREDIENT,
  END_LOADING_UNIFIED_PRODUCTS,
  START_LOADING_ALLERGENS,
  START_LOADING_CATALOG,
  START_LOADING_DETAILS_CATEGORY,
  START_LOADING_FREE_SEARCH,
  START_LOADING_ITEM_DETAILS,
  START_LOADING_MASTER_CATEGORY,
  START_LOADING_MASTER_ITEMS,
  START_LOADING_POS_CODES,
  START_LOADING_PRICE_GAPS,
  START_LOADING_PRODUCTS,
  START_LOADING_SUPPLIERS,
  START_LOADING_SWAP_INGREDIENT,
  START_LOADING_UNIFIED_PRODUCTS,
} from "./type";

export const itemsURI = "v3/items";
export const categoriesURI = "v3/categories";
export const priceGapsURI = "priceGaps";
export const allergensURI = "productAllergen";
export const posCodesURI = `${itemsURI}/posCodes`;
export const unifiedProductsURI = `${itemsURI}/unifiedProducts`;
export const suppliersURI = `${itemsURI}/suppliers`;
export const productsURI = `${itemsURI}/products`;
export const sweepingURI = `${itemsURI}/sweeping`;
export const toggleURI = `${itemsURI}/toggle`;
export const uploadMultiURI = `${itemsURI}/uploadMulti`;
export const isExistsURI = `${itemsURI}/isItemExists`;
export const supplierCatalog = `${itemsURI}/supplierCatalog`;
export const toggleCategoriesURI = `${categoriesURI}/toggle`;
export const logsURI = `${itemsURI}/logs`;

// LOADING ACTIONS
export const startLoadingMasterItems = {
  type: START_LOADING_MASTER_ITEMS,
  payload: true,
};
export const endLoadingMasterItems = {
  type: END_LOADING_MASTER_ITEMS,
  payload: false,
};

export const startLoadingDetailsItem = {
  type: START_LOADING_ITEM_DETAILS,
  payload: true,
};
export const endLoadingDetailsItem = {
  type: END_LOADING_ITEM_DETAILS,
  payload: false,
};

export const startLodingDetailsCategory = {
  type: START_LOADING_DETAILS_CATEGORY,
  payload: true,
};
export const endLoadingDetailsCategory = {
  type: END_LOADING_DETAILS_CATEGORY,
  payload: false,
};

export const startLoadingCatalog = {
  type: START_LOADING_CATALOG,
  payload: true,
};

export const endLoadingCatalog = { type: END_LOADING_CATALOG, payload: false };

export const startLoadingMasterCategory = {
  type: START_LOADING_MASTER_CATEGORY,
  payload: true,
};
export const endLoadingMasterCategory = {
  type: END_LOADING_MASTER_CATEGORY,
  payload: false,
};

export const startLoadingSuppliers = {
  type: START_LOADING_SUPPLIERS,
  payload: true,
};
export const endLoadingSuppliers = {
  type: END_LOADING_SUPPLIERS,
  payload: false,
};

export const startLoadingFreeSearch = {
  type: START_LOADING_FREE_SEARCH,
  payload: true,
};
export const endLoadingFreeSearch = {
  type: END_LOADING_FREE_SEARCH,
  payload: false,
};

export const startLoadingProducts = {
  type: START_LOADING_PRODUCTS,
  payload: true,
};
export const endLoadingProducts = {
  type: END_LOADING_PRODUCTS,
  payload: false,
};

export const startLoadingPosCodes = {
  type: START_LOADING_POS_CODES,
  payload: true,
};
export const endLoadingPosCodes = {
  type: END_LOADING_POS_CODES,
  payload: false,
};

export const startLoadingAllergens = {
  type: START_LOADING_ALLERGENS,
  payload: true,
};
export const endLoadingAllergens = {
  type: END_LOADING_ALLERGENS,
  payload: false,
};

export const startLoadingUnifiedProducts = {
  type: START_LOADING_UNIFIED_PRODUCTS,
  payload: true,
};
export const endLoadingUnifiedProducts = {
  type: END_LOADING_UNIFIED_PRODUCTS,
  payload: false,
};

export const startLoadingSwapIngredient = {
  type: START_LOADING_SWAP_INGREDIENT,
  payload: true,
};
export const endLoadingSwapIngredient = {
  type: END_LOADING_SWAP_INGREDIENT,
  payload: false,
};

export const startLoadingPriceGaps = {
  type: START_LOADING_PRICE_GAPS,
  payload: true,
};
export const endLoadingPriceGaps = {
  type: END_LOADING_PRICE_GAPS,
  payload: false,
};
