import React from 'react';
import { getHebDay } from '../../helpers';
import moment from 'moment';

const ScheduleNotAvailabel = ({ sendedSubmissions }) => {


  return (
    <div className="scheduleEmployeerClosed">
      <h2 className="mainTytleClosed">
        תאריך אחרון להגשת משמרות עבר, אנא פנה למנהל.
      </h2>
      <h1>ההגשות שלי</h1>
      <div className="flexDirectionGap">
        {Object.values(sendedSubmissions)
          .sort((a, b) => a.week_day - b.week_day)
          .map((card) => (
            <div className="cardScheduleClosed" key={card.id}>
              <h3 className="cardTitleClosed">
                יום {getHebDay(card.week_day)} -{" "}
                {moment(new Date(card.date)).format("DD/MM/YY")}
              </h3>
              <h3 style={{ fontWeight: "700" }}>
                <strong>
                  {card.role ? card?.role?.name : card.shift?.name}
                </strong>
              </h3>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ScheduleNotAvailabel