import {
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import redWarning from "../images/metro-warning.svg";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TYPES_LABELS } from "../pages/BranchForms/consts";

import { fetchRequiredProcedures } from "../redux/actions/generalAction";
import Loader from "../route/Loader";

const RequiredProcedures = ({
  children,
  fetchRequiredProcedures,
  requiredProcedures,
  requiredProceduresTypes,
}) => {
  const [fetched, setFetched] = useState(false);
  useEffect(() => {
    const fetch = async () => {
      // await fetchRequiredProcedures();
      setFetched(true);
    };
    fetch();
  }, [fetchRequiredProcedures]);

  return !fetched ? (
    <Loader />
  ) : requiredProcedures?.length ? (
    <Dialog open variant="allowmenu">
      <DialogTitle>
        <div className="flex-center">
          <img alt="" style={{ width: "84px" }} src={redWarning} />
        </div>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <h4>לתשומת ליבך</h4>
            <p>לפני הגשת משמרות עליך למלא את הטפסים הבאים: </p>
            {requiredProceduresTypes
              ?.map((type) => TYPES_LABELS[type])
              .join(", ")}
            <p>קישור למילוי הטפסים נמצא בעמוד הראשי</p>
          </div>
        </DialogContent>
      </DialogTitle>
    </Dialog>
  ) : (
    children
  );
};

const mapStateToProps = (state) => {
  const { requiredProcedures, requiredProceduresTypes } = state.generalData;
  return { requiredProcedures, requiredProceduresTypes };
};
const mapDispatchToProps = {
  fetchRequiredProcedures,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequiredProcedures);
