import {
  DateCell,
  PercentageCell,
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";
import {
  dynamicToFixed,
  fixDate,
  fixPercentage,
  getHebDayByDate,
  getSafeDivision,
  submitWrapper,
} from "../../../helpers";
import { fixPrice } from "../../../helpers";
import { Link } from "@mui/material";
import { GREEN_COLOR, RED_COLOR } from "../../products/const";
import { DEFAULT_MAX_FC } from "../const";

export const getData = (
  by_date // raw data
) => {
  return by_date.map((SoldDishByDate) => {
    const { date, amount, total, worth } = SoldDishByDate;

    const avg_price = getSafeDivision(total, amount);
    const avg_foodcost = getSafeDivision(worth, total) * 100;
    const day = getHebDayByDate(date);

    return {
      date,
      day,
      amount,
      total_with_vat: total,
      worth,
      avg_price,
      avg_foodcost,
    };
  });
};

export const excelRowConfig = (row) => {
  row.date = fixDate(row.date);
  row.total_with_vat = dynamicToFixed(row.total_with_vat);
  row.worth = dynamicToFixed(row.worth);
  row.avg_price = dynamicToFixed(row.avg_price);
  row.avg_foodcost = fixPercentage(row.avg_foodcost, 2);

  return row;
};

export const aggregates = [
  { field: "total_with_vat", aggregate: "sum" },
  { field: "worth", aggregate: "sum" },
  { field: "avg_price", aggregate: "sum" },
];

export const sortBy = [{ id: "avg_foodcost", desc: true }];

export const columns = [
  {
    Header: "תאריך",
    accessor: "date",
    Cell: DateCell,
    Footer: () => "סה״כ",
  },
  {
    Header: "יום",
    accessor: "day",
  },
  {
    Header: "סה״כ מוצרים",
    accessor: "amount",
    Cell: ({ value, row, getGroupedSoldDishesByBranchAndDate, dispatch }) => (
      <Link
        component="button"
        sx={{
          fontSize: "1.6rem",
        }}
        onClick={() =>
          submitWrapper(getGroupedSoldDishesByBranchAndDate, dispatch, [
            row.original,
          ])
        }
      >
        {value}
      </Link>
    ),
  },
  {
    Header: "סה״כ מכירות כולל מע״מ",
    accessor: "total_with_vat",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "עלות רכיבים",
    accessor: "worth",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "מחיר מנה",
    accessor: "avg_price",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "foodcost",
    accessor: "avg_foodcost",
    Cell: (props) => (
      <span
        style={{
          color: props.value <= DEFAULT_MAX_FC ? GREEN_COLOR : RED_COLOR,
        }}
      >
        {PercentageCell(props)}
      </span>
    ),
  },
];
