import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import MobileDatePickerComponent from "../../components/datepicker/mui-datepicker-x/MobileDatePicker";
import Dropdown from "../../components/dropdown/Dropdown";
import DefinedRange from "../../components/datepicker/date-range-picker/DefinedRange";
import DateRangePicker from "../../components/datepicker/DateRangePicker";
import { FaCalendarAlt } from "react-icons/fa";
import {
  dataAnalyzeActions,
  getDataAnalyze,
} from "../../redux/reducers/DataAnalyzeSlice";
import { hebStaticRanges } from "../../components/datepicker/const";
import { he } from "date-fns/locale";
import MultiSelectCheckbox from "../../components/dropdown/MultiSelectCheckBox";
// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const DataAnalyzeFilters = (props) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const selectedDates = useSelector(
    (state) => state.dataAnalyze.filters.selectedDates
  );
  const selectedBranches = useSelector(
    (state) => state.dataAnalyze.filters.branches
  );

  const branches = useSelector(({ branches }) => branches.branches);

  // ======== STATES ========
  const [mobileRangePicker, setMobileRangePicker] = useState(false);

  // ======== HANDLERS ========
  const setSelectedDatesToFilter = (selectedRange) => {
    dispatch(
      dataAnalyzeActions.setFilters({
        name: "selectedDates",
        value: selectedRange,
      })
    );
  };

  const handleStartDateRangeChange = (newValue) => {
    const formattedRange = {
      startDate: newValue,
      endDate: selectedDates.endDate,
      key: "selection",
      label: `${newValue.toLocaleDateString()} ~ ${selectedDates.endDate.toLocaleDateString()}`,
    };
    dispatch(
      dataAnalyzeActions.setFilters({
        name: "selectedDates",
        value: formattedRange,
      })
    );
  };

  const handleEndDateRangeChange = (newValue) => {
    const formattedRange = {
      startDate: selectedDates.startDate,
      endDate: newValue,
      key: "selection",
      label: `${selectedDates.startDate.toLocaleDateString()} ~ ${newValue.toLocaleDateString()}`,
    };
    dispatch(
      dataAnalyzeActions.setFilters({
        name: "selectedDates",
        value: formattedRange,
      })
    );
  };

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        item
        xs={12}
        md={2.4}
        sm={2.4}
        lg={2}
        xl={2}
      >
        <Grid item spacing={0} container xs={11}>
          {mobileRangePicker ? (
            <>
              <Grid item xs={12}>
                <MobileDatePickerComponent
                  selectedDates={selectedDates.startDate}
                  label={"תאריך התחלה"}
                  handleDateRangeChange={handleStartDateRangeChange}
                />
              </Grid>
              <Grid item xs={12}>
                <MobileDatePickerComponent
                  selectedDates={selectedDates.endDate}
                  label={"תאריך סיום"}
                  handleDateRangeChange={handleEndDateRangeChange}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Dropdown text={selectedDates.label}>
                {isMobile ? (
                  <DefinedRange
                    ranges={[selectedDates]}
                    staticRanges={hebStaticRanges}
                    local={he}
                    onChange={(item) =>
                      setSelectedDatesToFilter(item.selection)
                    }
                  />
                ) : (
                  <DateRangePicker
                    selectedRange={selectedDates}
                    setSelectedRange={setSelectedDatesToFilter}
                  />
                )}
              </Dropdown>
            </Grid>
          )}
        </Grid>
        {isMobile && (
          <Grid item xs={1}>
            <FaCalendarAlt
              size={35}
              onClick={() => setMobileRangePicker((prev) => !prev)}
              style={{ color: "#00a2ea" }}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={2.4} sm={2.4} lg={2} xl={2}>
        <MultiSelectCheckbox
          title="סניפים"
          // style={{ maxWidth: "unset" }}
          checked={selectedBranches}
          setChecked={(value) =>
            dispatch(dataAnalyzeActions.setFilters({ name: "branches", value }))
          }
          options={branches.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
        />
      </Grid>
      <Grid item xs={12} md={2.4} sm={2.4} lg={2}>
        <Button
          style={{ textAlign: "center" }}
          variant="contained"
          fullWidth={isMobile ? true : false}
          color="primary"
          size="large"
          onClick={() => {
            dispatch(getDataAnalyze());
          }}
        >
          בצע
        </Button>
      </Grid>
    </>
  );
};

export default DataAnalyzeFilters;
