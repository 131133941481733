import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import SelectComponent from "../../../components/Forms/inputs/Select";
import FormCheckBox from "../../../components/Forms/inputs/FormCheckBox";
import moment from "moment";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import { ControlSignaturePad } from "../../../components/Forms/inputs/SignaturPad";

const PART_EMPLOYEE_NAME = 0;
const PART_TYPE_IDENTIFER = 1;
const PART_WHEN_SUMMONS_DATE = 2;
const PART_HOUR_SUMMON = 3;
const PART_OTHER_PARTICIPANTS = 4;
const PART_MANAGER_WORDS = 5;
const PART_WORKERS_WORDS = 6;
const PART_OTHER_WORDS = 7;
const PART_SUM_WORDS = 8;
const PART_DESICSION_DATE = 9;
const PART_SUM_FORM = 10;
const PART_CONCLUSIONS = 11;
const PART_TERMINATION_DESICSION = 12;

const REQUIRED_CHECKBOX = {
  name: "required",
  component: FormCheckBox,
  label: "שדה חובה",
  defaultValue: true,
};

const INPUT_LABEL_FIELD = (defaultValue = "") => ({
  name: "label",
  component: FormTextField,
  label: "כותרת השדה",
  defaultValue,
  rules: { required: REQUIRED_MESSAGE },
});

const PARTS = {
  [PART_EMPLOYEE_NAME]: {
    label: "שם העובד",
    defaultValue: () => "שם העובד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם העובד"),
      {
        name: "value",
        component: FormTextField,
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: ControlAutoComplete,
      size: "small",
      inputProps: { label: "שם העובד" },
    },
  },
  [PART_TYPE_IDENTIFER]: {
    label: "ת.ז.",
    defaultValue: () => "ת.ז.",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("ת.ז."),
      {
        name: "value",
        component: FormTextField,
        placeholder: "ת.ז.",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,

      rules: {
        pattern: {
          value: new RegExp(/^\d{0,9}$/),
          message: "מספר ת.ז. לא תקין יש לנסות שוב",
        },
      },
    },
  },
  [PART_WHEN_SUMMONS_DATE]: {
    label: "תאריך השימוע",
    defaultValue: () => "תאריך השימוע",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך השימוע"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        placeholder: "תאריך השימוע",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_HOUR_SUMMON]: {
    label: "שעת השימוע ",
    defaultValue: () => "שעת השימוע",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD(" שעת השימוע"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,
        placeholder: " שעת השימוע",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_OTHER_PARTICIPANTS]: {
    label: "נוכחים בשימוע",
    defaultValue: () => "נוכחים בשימוע",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("נוכחים בשימוע"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "נוכחים בשימוע",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },

  [PART_MANAGER_WORDS]: {
    label: "דברי המנהל- החלטנו לזמן אותך לשימוע בעקבות",
    defaultValue: () => "דברי המנהל- החלטנו לזמן אותך לשימוע בעקבות",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("דברי המנהל- החלטנו לזמן אותך לשימוע בעקבות"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "דברי המנהל- החלטנו לזמן אותך לשימוע בעקבות",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_WORKERS_WORDS]: {
    label: "טענות העובד/ת בתגובה",
    defaultValue: () => "טענות העובד/ת בתגובה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("טענות העובד/ת בתגובה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "טענות העובד/ת בתגובה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },

  [PART_OTHER_WORDS]: {
    label: "תמצית שיחה חופשית",
    defaultValue: () => "תמצית שיחה חופשית",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תמצית שיחה חופשית"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "תמצית שיחה חופשית",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },

  [PART_SUM_WORDS]: {
    label: "דברי סיכום",
    defaultValue: () => "דברי סיכום",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("דברי סיכום"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "דברי סיכום",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_DESICSION_DATE]: {
    label: "תאריך קבלת החלטה",
    defaultValue: () => "תאריך קבלת החלטה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך קבלת החלטה"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        placeholder: "תאריך קבלת החלטה",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_SUM_FORM]: {
    label: "סיכום",
    defaultValue: () => "סיכום",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("סיכום"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "סיכום",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_CONCLUSIONS]: {
    label: "מסקנות",
    defaultValue: () => "מסקנות",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("מסקנות"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "מסקנות",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_TERMINATION_DESICSION]: {
    label: "החלטה",
    defaultValue: () => "החלטה",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("החלטה"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "החלטה",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
};

export {
  PARTS,
  PART_EMPLOYEE_NAME,
  PART_WHEN_SUMMONS_DATE,
  PART_HOUR_SUMMON,
  PART_TYPE_IDENTIFER,
  PART_OTHER_PARTICIPANTS,
  PART_MANAGER_WORDS,
  PART_WORKERS_WORDS,
  PART_OTHER_WORDS,
  PART_SUM_WORDS,
  PART_DESICSION_DATE,
  PART_SUM_FORM,
  PART_CONCLUSIONS,
  PART_TERMINATION_DESICSION,
};
