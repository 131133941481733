import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import {
  Button,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  // Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { default as Grid } from "@mui/material/Unstable_Grid2";
import { AiOutlineClose, AiOutlinePlusSquare } from "react-icons/ai";
import Axios from "axios";
import { setError } from "../../../../../redux/actions/errorAction";
import {
  MAKOR_PROVIDER,
  RESTIGO_ATTENDANCE_APP_PROVIDER,
} from "../AttendanceClocks";
import MakorCreateForm from "./MakorCreateForm";
import {
  startLoading,
  stopLoading,
} from "../../../../../redux/actions/loaderAction";
import { setConfirm } from "../../../../../redux/actions/confirmAction";
import RestigoAttendanceAppCreateForm from "./RestigoAttendanceAppCreateForm";
import { useScreenSize } from "../../../../../Hooks";

const CreateFormModel = ({
  open,
  setOpen,
  setError,
  token,
  adminId,
  startLoading,
  stopLoading,
  fetchAttendanceClocks,
}) => {
  const { isMobile } = useScreenSize();
  // ============= state ===========
  const [selectedProvider, setSelectedProvider] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [fetchingBranchOptions, setFetchingBranchOptions] = useState(false);
  const [possibleBranches, setPossibleBranches] = useState([]);
  const [autoCompleteKey, setAutoCompleteKey] = useState(true); // for re render autocomplete component

  // =============== handlers & functions ==============
  const handleClose = () => {
    setSelectedProvider(0);
    setOpen(false);
  };

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item.name} ${item.network_name} `,
  });

  const onSubmit = (formValues) => {
    store(formValues);
  };

  const renderForm = () => {
    if (branchId === 0) return <></>;
    switch (selectedProvider) {
      case MAKOR_PROVIDER:
        return <MakorCreateForm {...{ onSubmit, token, isMobile }} />;
      case RESTIGO_ATTENDANCE_APP_PROVIDER:
        return (
          <RestigoAttendanceAppCreateForm {...{ onSubmit, token, isMobile }} />
        );
      default:
        return <></>;
    }
  };

  //================= server calls ===================

  const fetchPossibleBranches = async () => {
    setFetchingBranchOptions(true);
    try {
      const response = await Axios.get(
        "admin/attendanceClocks/getPossibleBranches",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const { data } = response;
      setPossibleBranches(data);
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message, "ארעה שגיאה בשרת");
    }
    setFetchingBranchOptions(false);
  };

  const store = async (formData) => {
    startLoading("יוצר חיבור לשעון...");
    try {
      const response = await Axios.post(
        `admin/attendanceClocks/${selectedProvider}`,
        {
          ...formData,
          branch_id: branchId,
          updated_by: adminId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { data } = response;
      fetchAttendanceClocks();
      handleClose();
      setConfirm("חיבור לשעון נוצר בהצלחה");
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message, "ארעה שגיאה בשרת");
    }
    stopLoading();
  };

  //================= effects ===================

  useEffect(() => {
    fetchPossibleBranches();
  }, []);

  useEffect(() => {
    setBranchId(0);
    setAutoCompleteKey((prev) => !prev);
  }, [selectedProvider]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <CardHeader
        action={
          <IconButton onClick={handleClose}>
            <AiOutlineClose />
          </IconButton>
        }
        title="יצירת חיבור לשעון נוכחות"
      />
      <DialogContent>
        <Grid container>
          <Grid xs={12} md={6}>
            <FormControl>
              <InputLabel>סוג שעון</InputLabel>
              <Select
                label="סוג שעון"
                name="selecetedProvider"
                value={selectedProvider}
                onChange={(e) => setSelectedProvider(e.target.value)}
              >
                <MenuItem value={0}>בחר סוג שעון</MenuItem>
                <MenuItem value={MAKOR_PROVIDER}>מקור</MenuItem>
                <MenuItem value={RESTIGO_ATTENDANCE_APP_PROVIDER}>
                  אפליקציית נוכחות רסטיגו
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {selectedProvider !== 0 && (
          <Grid container>
            <Grid xs={12} md={6}>
              <Autocomplete
                key={autoCompleteKey}
                disabled={fetchingBranchOptions}
                onChange={(option, value) => setBranchId(value ? value.id : 0)}
                filterOptions={filterOptions}
                options={possibleBranches}
                noOptionsText={"לא נמצא סניף"}
                getOptionLabel={(option) =>
                  `${option.name} (${option.network_name})`
                }
                renderOption={(props, option, { selected }) => {
                  props.key = option.id;
                  return (
                    <div {...props}>
                      {`${option.name} (${option.network_name})`}
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={fetchingBranchOptions}
                    label="קשר לסניף"
                    placeholder="חפש סניף"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {fetchingBranchOptions ? (
                            <CircularProgress color="primary" size={20} />
                          ) : (
                            params.InputProps.endAdornment
                          )}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        {renderForm()}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          ביטול
        </Button>
        <Button color="primary" type="submit" form="form" variant="contained">
          אישור
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { token, admin } = state.authAdmin;
  const { isMobile } = state.publicValues;
  return { token, isMobile, adminId: admin.id };
};

export default connect(mapStateToProps, {
  setError,
  startLoading,
  stopLoading,
})(CreateFormModel);
