import Axios from "axios";
import axios from "axios";
import { setError } from "../errorAction";
import { SET_ERROR } from "../errorAction/types";
import { LOGOUT } from "../loginAction/types";
import { FETCH_EMPLOYEE_TYPES, FETCH_REQUIRED_PROCEDURES } from "./types";

const fetchEmployeeTypes = () => async (dispatch, getState) => {
  axios
    .get("employee-type", {
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    })
    .then(({ data }) => {
      dispatch({ type: FETCH_EMPLOYEE_TYPES, payload: data });
    })
    .catch((e) => {
      console.error(e);
      switch (e.response?.status) {
        case 401:
          dispatch({ type: LOGOUT });
          break;
        case 500:
          dispatch({
            type: SET_ERROR,
            errorTitle: "אירעה שגיאה בשרת",
            error:
              "אנא נסה לרענן או להתנתק ולהתחבר מחדש, תמיד אפשר לפנות אלינו לתמיכה",
          });
          break;

        default:
          break;
      }
    });
};

const fetchRequiredProcedures = () => async (dispatch, getState) => {
  try {
    const { data: requiredProcedures } = await Axios.get("procedures", {
      params: {
        required: true,
        signed: false,
      },
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });
    const requiredProceduresTypes = [
      ...new Set(requiredProcedures.map(({ type }) => type)),
    ];
    dispatch({
      type: FETCH_REQUIRED_PROCEDURES,
      payload: { requiredProcedures, requiredProceduresTypes },
    });
  } catch (error) {
    dispatch(setError());
  }
};
export { fetchEmployeeTypes, fetchRequiredProcedures };
