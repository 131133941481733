import Axios from "axios";
import { FETCH_USER_PERMISSIONS } from "./types";
import { setError } from "../errorAction";

export const fetchUserPermissions = () => async (dispatch, getState) => {
  const userId = getState().auth?.user?.id;
  const token = getState().auth?.token;
  if (!(userId && token)) {
    dispatch({ type: FETCH_USER_PERMISSIONS });
    return;
  }
  try {
    const { data } = await Axios.get(`users/${userId}/permissions`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: FETCH_USER_PERMISSIONS, payload: data });
  } catch (error) {
    dispatch(setError());
  }
};
