import React, { useEffect, useMemo, useRef, useState } from "react";
import SubmitButton from "../../../../../components/buttons/SubmitButton";
import { Collapse } from "@mui/material";
import { Modal } from "react-responsive-modal";
import { HEBREW_DAYS } from "../../../../../const";
import { fixDate, fixHour } from "../../../../../helpers";
import { sendSmsToUser } from "../../../../../redux/actions/smsAction";
import { connect } from "react-redux";
import { sendSmsToUserFromAdmin } from "../../../../../redux/actions/adminAction";
import { isAfter, isBefore } from "date-fns";
import Axios from "axios";

const SendSms = ({
  branch_name,
  event = {},
  open,
  setOpen,
  sendSmsToUser,
  sendSmsToUserFromAdmin,
  fromEmployeeModal = false,
  fromAdmin = false,
  token,
  branch_id,
}) => {
  const [text, setText] = useState(
    fromAdmin ? "עדכון מRESTIGO - " : `עדכון מ${branch_name} `
  );
  const [type, setType] = useState(1);
  const [smsRemainCount, setSmsRemainCount] = useState(0);

  const fetchCurrentRemainCount = useMemo(
    () => async () => {
      if (!branch_id || !token) {
        return;
      }
      try {
        if (!token) return;
        const { data } = await Axios.get(
          `branches/${branch_id}/getBranchSmsBank`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSmsRemainCount(data.remain_count);
      } catch (e) {
        console.error(e);
      }
    },
    [branch_id, token]
  );

  useEffect(() => {
    if (open) {
      fetchCurrentRemainCount();
    }
  }, [fetchCurrentRemainCount, open]);

  const fixedMessageDisabled = useMemo(() => {
    if (fromEmployeeModal || fromAdmin) {
      return true;
    }
    if (
      isBefore(
        new Date(`${event?.working_date} ${event?.hours?.planned?.start}`),
        new Date()
      )
    ) {
      return true;
    }
    return false;
  }, [fromEmployeeModal, fromAdmin, event]);

  const handleSubmit = () => {
    setOpen(false);
    let textToSend = text;
    if (type === 1 && !fixedMessageDisabled) {
      const { planned } = event.hours;
      textToSend = ` תזכורת למשמרת ${event.shift.name} בתפקיד ${
        event.role.name
      } ביום ${HEBREW_DAYS[event.week_day]}, בתאריך ${fixDate(
        event.week_date,
        "DD/MM"
      )}, בין השעות ${fixHour(planned.end)} - ${fixHour(
        planned.start
      )}. \r\n משמרת נעימה. `;
    }
    if (fromAdmin) {
      sendSmsToUserFromAdmin({
        employee_id: event.employee.id,
        text: textToSend,
      });
    } else {
      sendSmsToUser({
        employee_name: event.employee.name,
        employee_id: event.employee.id,
        text: textToSend,
      });
    }
  };

  const textAreaRef = useRef();
  return (
    <Modal
      styles={{
        modal: { maxWidth: "50rem", textAlign: "center", padding: "3.5rem" },
      }}
      center
      open={open}
      onClose={() => setOpen(false)}
      animationDuration={0}
    >
      <h2>שליחת SMS</h2>
      <form onSubmit={handleSubmit}>
        {fixedMessageDisabled ? (
          <>
            {" "}
            <h3>תוכן ההודעה</h3>
            <textarea
              ref={textAreaRef}
              onFocus={(e) => {
                e.preventDefault();
                const { selectionStart, selectionEnd } = e.target;
                textAreaRef.current.selectionStart = selectionEnd + text.length;
              }}
              maxLength={160}
              style={{ border: "1px solid #ccc" }}
              className="textarea-white"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            ></textarea>
            <p>{`${text.length}/160 תווים`}</p>
          </>
        ) : (
          <>
            <h2>בחר הודעה לשליחה: </h2>
            <div style={{ width: "fit-content", margin: "0 2rem" }}>
              <label style={{ border: "none" }} className="checkbox--container">
                שלח תזכורת על משמרת{" "}
                <input
                  type="checkbox"
                  checked={type === 1}
                  onChange={(e) => {
                    setType(1);
                  }}
                />
                <span className="checkbox--checkmark"></span>
              </label>
            </div>
            <div style={{ width: "fit-content", margin: "0 2rem" }}>
              <label style={{ border: "none" }} className="checkbox--container">
                שלח טקסט חופשי
                <input
                  type="checkbox"
                  checked={type === 2}
                  onChange={(e) => {
                    setType(2);
                  }}
                />
                <span className="checkbox--checkmark"></span>
              </label>
            </div>
            <Collapse in={type === 2} timeout="auto" unmountOnExit>
              <h3>תוכן ההודעה</h3>
              <textarea
                maxLength={160}
                style={{ border: "1px solid #ccc" }}
                className="textarea-white"
                defaultValue={""}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              ></textarea>
            </Collapse>
          </>
        )}
        <div style={{ fontSize: "1.5rem" }} className="strong">
          כמות SMS עדכנית לשימוש: {smsRemainCount}
        </div>

        <SubmitButton style={{ marginTop: "1rem" }} />
      </form>
    </Modal>
  );
};

export default connect(
  (state) => {
    const { selected_branch } = state.branches;
    const { token } = state.auth;
    return {
      branch_name: selected_branch?.name,
      branch_id: selected_branch?.id,
      token,
    };
  },
  { sendSmsToUser, sendSmsToUserFromAdmin }
)(SendSms);
