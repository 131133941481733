import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import BtnTooltip from "../../../../../components/tooltips/BtnTooltip";
import Model, { Footer } from "../../../../../components/Model";
import Button from "../../../../../components/buttons/Button";
import Popover from "react-popover";
import MultiSelectCheckbox from "../../../../../components/dropdown/MultiSelectCheckBox";
import OutsideAlerter from "../../../../../Hooks";
import HoursInput from "../../../../../components/inputs/HoursInput";
import { isEmpty, isUndefined } from "lodash";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import {
  getAllShiftRolesByShift,
  changeDayHoursSchedule,
  saveTemplate,
} from "../../../../../redux/actions/scheduleAction/index";
// ======== HOOKS ========
import { useMediaQuery, useTheme, Grid, Box } from "@mui/material";
import { setHours } from "date-fns";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

const HoursPerDay = ({ handleStateOpenEditRoleHours, index, day }) => {
  const dispatch = useDispatch();
  const [checkedRolesOptions, setCheckedRolesOptions] = useState([]);
  const [checkedShiftsOptions, setCheckedShiftsOptions] = useState([]);
  const [hoursError, setHoursError] = useState(false);

  const [plannedStart, setPlannedStart] = useState("00:00");
  const [plannedEnd, setPlannedEnd] = useState("00:00");
  const schedule = useSelector((state) => state.schedule);

  const [formErrors, setFormErrors] = useState({});

  const updatedExistingRoles = schedule.existing_roles.map((role) => ({
    ...role,
    value: role.id,
    label: role.name, // Rename "name" to "label"
  }));

  const updatedExisting_shifts = schedule.existing_shifts.map((shift) => ({
    ...shift,
    value: shift.id,
    label: shift.name, // Rename "name" to "label"
  }));
  const validate = () => {
    const errors = {};

    if (checkedShiftsOptions.length === 0)
      errors.shifts = "חובה לבחור לפחות משמרת אחת";
    if (plannedEnd === plannedStart) errors.hours = "שעת התחלה וסיום זהות";

    setFormErrors(errors);
    if (!isEmpty(errors)) return false;
    else return true;
  };

  const handleOutsideChange = (checkedShitfs) => {
    dispatch(
      getAllShiftRolesByShift({
        shiftIds: checkedShitfs,
        day: day.actual_date,
      })
    );
  };
  const handleSubmit = () => {
    if (validate()) {
      dispatch(
        changeDayHoursSchedule({
          plannedStart,
          plannedEnd,
          checkedRolesOptions,
          checkedShiftsOptions,
          day: day.actual_date,
        })
      );
      handleStateOpenEditRoleHours(index);
      dispatch(saveTemplate());
    }
  };

  const renderError = (name) => {
    if (!isUndefined(formErrors[name]))
      return (
        <p style={{ width: "100%", textAlign: "center" }}>
          <span className="error-msg">{formErrors[name]}</span>{" "}
        </p>
      );
  };

  useEffect(() => {
    dispatch(
      getAllShiftRolesByShift({
        shiftIds: [],
        day: day.actual_date,
      })
    );
  }, []);

  return (
    <OutsideAlerter
      onOuterClick={() => {
        setTimeout(() => handleStateOpenEditRoleHours(index), 200);
      }}
    >
      <Model className="addShift">
        <Grid container>
          <Grid item xs={12}>
            {console.log(day.day)}
            <h2>שינוי שעות ליום {day.day}</h2>
          </Grid>
          <Grid item container xs={12}>
            {" "}
            <Grid item xs={12}>
              <label>
                שם משמרת
                <MultiSelectCheckbox
                  title="בחר משמרת"
                  checked={checkedShiftsOptions}
                  setChecked={setCheckedShiftsOptions}
                  options={updatedExisting_shifts}
                  handleOutsideChangeProp={true}
                  handleOutsideChange={handleOutsideChange}
                />
              </label>
              {renderError("shifts")}
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <label>
                תפקיד
                <MultiSelectCheckbox
                  title="כל התפקידים"
                  checked={checkedRolesOptions}
                  setChecked={setCheckedRolesOptions}
                  options={updatedExistingRoles}
                />
              </label>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className="flex margin-y">
                <div>
                  <label>סיום</label>
                  <HoursInput getClock={setPlannedEnd} />
                </div>
                <div>
                  <label>התחלה</label>
                  <HoursInput getClock={setPlannedStart} />
                </div>
              </div>
              {renderError("hours")}
            </Grid>
          </Grid>
        </Grid>
        <Footer>
          <Button
            className="btn--neutral"
            onClick={() => handleStateOpenEditRoleHours(index)}
            textValue="ביטול"
          />
          <Button
            className="btn--submit"
            onClick={() => handleSubmit()}
            textValue="שמור"
          />
        </Footer>
      </Model>
    </OutsideAlerter>
  );
};

export default HoursPerDay;
