import React from "react";
import { Translate } from "react-redux-i18n";

const SubmitButton = ({ className,style={} }) => {
  return (
    <button {...{style}} className={`btn btn--submit ${className}`} type="submit" >
      <Translate value="button.submit" />
    </button>
  );
};

export default SubmitButton;
