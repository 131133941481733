import React from "react";

export const SummeryBox = ({
  selected,
  children,
  onClick = () => {},
  height,
  width,
  className = "",
}) => {
  return (
    <button
      style={{ height, width }}
      onClick={onClick}
      className={`btn btn--none  summeryBox ${
        selected ? "summeryBox__selected" : ""
      } ${className}`}
    >
      {children}
    </button>
  );
};
