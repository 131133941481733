import React, { useRef, useState } from "react";
import OutsideAlerter from "../../Hooks";

const SearchBar = ({
  className = "",
  classNameWorker="",
  style,
  fixedInputWidth = false,
  handleSearchChange,
  isCollapse = false,
  placeholder = "חיפוש",
  parentSearchValue = null,
}) => {
  const [searchValue, setSearchValue] = useState(parentSearchValue ?? "");
  const [collapsed, setCollapsed] = useState(true);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    handleSearchChange(e.target.value);
  };

  const search = useRef();

  return (
    <OutsideAlerter onOuterClick={() => setCollapsed(true)}>
      <div
        style={style}
        className={`search-container${classNameWorker} ${className}  ${
          fixedInputWidth ? "search-container__fixedInputWidth" : ""
        }`}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (collapsed) search.current.focus();
            else search.current.blur();
            setCollapsed(!collapsed);
          }}
        >
          <i className="fa fa-search"></i>
        </button>
        <input
          ref={search}
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          name="search"
          value={searchValue}
          onChange={(e) => handleChange(e)}
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => {
            e.target.select();
          }}
        />
      </div>
    </OutsideAlerter>
  );
};
export default SearchBar;
