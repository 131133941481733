import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { default as Grid } from "@mui/material/Unstable_Grid2";

// ======== REDUX ========

// ======== HOOKS ========
import { Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SummeryBox } from "../income/incomeDashboard/summery/SummeryBox";
import {
  fixDate,
  fixPercentage,
  fixPrice,
  getHebDayByDate,
  getSafeDivision,
} from "../../helpers";
import { COLOR_SUBMIT } from "../../const/colors";
import moment from "moment";
import { VAT } from "../orders/manageOrders/constants";
import {
  SOLD_DISH_TYPE_DISH,
  SOLD_DISH_TYPE_MEAL,
  SOLD_DISH_TYPE_RAW,
  SOLD_DISH_TYPE_UNLINKED,
} from "./const";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const SummaryBoxes = ({ data }) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { reducedData, soldDishes, throwEvents } = data;

  const { by_date, by_code } = soldDishes;
  const {
    comulative,
    mealsWorth,
    unlinkedDishes,
    amount,
    worth,
    minDate,
    maxDate,
    throws,
  } = reducedData;

  //get end date with today as upper bound
  const getEndDate = () => {
    const today = moment();

    return maxDate.isAfter(today, "day") ? today : maxDate;
  };

  // ======== STATES ========
  const [selectedDate, setSelectedDate] = useState(() => getEndDate());

  const getByDateBoxData = () => {
    return by_date
      .filter(({ date }) => date === selectedDate.format("YYYY-MM-DD"))
      .reduce((sum, { total }) => sum + total, 0);
  };

  // ======== HANDLERS ========

  // ======== CONSTANTS ========
  const boxesAmount = 6;

  // ======== EFFECTS ========
  //auto reset selected date when fetching to show relevant data
  useEffect(() => {
    setSelectedDate(getEndDate());
  }, [data]);

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  const VatToolTip = ({ withVat = false, children }) => (
    <Tooltip
      arrow
      placement="left"
      title={
        <Typography style={{ color: "white" }}>{`סה״כ ${
          withVat ? "כולל" : "ללא"
        } מע״מ`}</Typography>
      }
    >
      {children}
    </Tooltip>
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{
        margin: "20px",
      }}
    >
      <Grid sm={12} md={4} lg={12 / boxesAmount}>
        <SummeryBox>
          <div className="flex-between-center  margin-y">
            <i
              onClick={() => {
                setSelectedDate(selectedDate.clone().subtract(1, "days"));
              }}
              style={{ color: COLOR_SUBMIT, fontSize: "1.8rem" }}
              className={
                selectedDate.isSame(minDate) ? "" : "fa fa-chevron-right"
              }
            />
            <h2 className="summeryBoxByDate">
              {`${getHebDayByDate(selectedDate)} - ${fixDate(selectedDate)}`}
            </h2>
            <i
              onClick={() => {
                setSelectedDate(selectedDate.clone().add(1, "days"));
              }}
              style={{ color: COLOR_SUBMIT, fontSize: "1.8rem" }}
              className={
                selectedDate.isSame(getEndDate()) ? "" : "fa fa-chevron-left"
              }
            />
          </div>
          <div className="summeryText">
            <VatToolTip>
              <p className="summeryText--1">
                {fixPrice(getByDateBoxData() / VAT)}
              </p>
            </VatToolTip>
            <VatToolTip withVat>
              <p className="summeryText--1">{fixPrice(getByDateBoxData())}</p>
            </VatToolTip>
          </div>
          <p>מצטבר יומי</p>
        </SummeryBox>
      </Grid>

      <Grid sm={12} md={4} lg={12 / boxesAmount}>
        <SummeryBox>
          <h2 className="margin-y">מצטבר</h2>
          <div className="summeryText">
            <VatToolTip>
              <p className="summeryText--2">{fixPrice(comulative / VAT)}</p>
            </VatToolTip>
            <VatToolTip withVat>
              <p className="summeryText--2">{fixPrice(comulative)}</p>
            </VatToolTip>
          </div>
        </SummeryBox>
      </Grid>

      <Grid sm={12} md={4} lg={12 / boxesAmount}>
        <SummeryBox>
          <h2 className="margin-y">שווי ארוחות</h2>
          <div className="summeryText">
            <VatToolTip>
              <p className="summeryText--4">{fixPrice(mealsWorth / VAT)}</p>
            </VatToolTip>
            <VatToolTip withVat>
              <p className="summeryText--4">{fixPrice(mealsWorth)}</p>
            </VatToolTip>
          </div>
        </SummeryBox>
      </Grid>

      <Grid sm={12} md={4} lg={12 / boxesAmount}>
        <SummeryBox>
          <h2 className="margin-y">Food Cost</h2>
          <VatToolTip>
            <p style={{ fontSize: "3rem" }} className="summeryText--5">
              {fixPercentage(getSafeDivision(worth, comulative) * 100)}
            </p>
          </VatToolTip>
        </SummeryBox>
      </Grid>

      <Grid sm={12} md={4} lg={12 / boxesAmount}>
        <SummeryBox>
          <h2 className="margin-y">זריקות</h2>
          <div className="summeryText">
            <VatToolTip>
              <p className="summeryText--8">{fixPrice(throws / VAT)}</p>
            </VatToolTip>
            <VatToolTip withVat>
              <p className="summeryText--8">{fixPrice(throws)}</p>
            </VatToolTip>
          </div>
        </SummeryBox>
      </Grid>

      <Grid sm={12} md={4} lg={12 / boxesAmount}>
        <SummeryBox>
          <h2 className="margin-y">מוצרים לא מקושרים</h2>
          <div className="summeryText">
            <VatToolTip>
              <p className="summeryText--3">{fixPrice(unlinkedDishes / VAT)}</p>
            </VatToolTip>
            <VatToolTip withVat>
              <p className="summeryText--3">{fixPrice(unlinkedDishes)}</p>
            </VatToolTip>
          </div>
        </SummeryBox>
      </Grid>
    </Grid>
  );
};

export default SummaryBoxes;
