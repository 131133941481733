import React, { useEffect, useState } from "react";
import { aggregateBy, process } from "@progress/kendo-data-query";
import DownloadExcel from "./DownloadExcel";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getSafe } from "../../helpers";
import { useScreenSize } from "../../Hooks";

const SingleExcelFile = ({
  fileName,
  aggregates = [],
  data,
  type,
  columns,
  expanded = false,
  buttonIcon = false,
  multi = false,
  buttonText,
  cellsConfig = null,
}) => {
  const { isMobile } = useScreenSize();
  const [arrangedData, setArrangedData] = useState({});
  const arrayToString = (array) => {
    let str = "";
    array.forEach((x) => {
      str += `${x?.heb_message}\n`;
    });

    return str;
  };

  const group = [{ field: "file_title" }];

  useEffect(() => {
    const arrangeData = async (data) => {
      let arrangedData = [];
      if (getSafe(() => data[0].anomalies)) {
        arrangedData = await data.map((columns) => ({
          ...columns,
          week_day: I18n.t(`excel.day.${columns.week_day}`),
          anomalies: arrayToString(columns.anomalies),
        }));
      } else {
        arrangedData = await data.map((columns) => {
          let temp = {};

          aggregates.forEach((x) => {
            if (cellsConfig != null && x.field in cellsConfig) {
              columns[x.field] = cellsConfig[x.field](
                columns[x.field],
                columns
              );
            }
            if (x.aggregate !== "count") {
              temp[x.field] = columns[x.field]
                ? Number(columns[x.field].toFixed(2))
                : "";
            }
          });
          return {
            ...columns,
            ...temp,
            week_day: I18n.t(`excel.day.${columns.week_day}`),
          };
        });
      }

      setArrangedData({
        data: expanded
          ? process([].concat.apply([], arrangedData.reverse()), {
              group: group,
            }).data
          : process([].concat.apply([], arrangedData), { group: group }).data,
        aggregates: aggregateBy(data, aggregates),
      });
    };

    arrangeData(data);
  }, [data, aggregates, columns]);

  const configCellsValues = (data) => {
    if (cellsConfig != null) {
      let rows = getSafe(() => data[0].items);
      if (rows) {
        rows.forEach((row) => {
          Object.entries(cellsConfig).forEach(([key, func]) => {
            if (key in row) {
              row[key] = func(row[key], row);
            }
          });
        });
        data[0].items = rows;
      }
    }
    return data;
  };

  return (
    <DownloadExcel
      type={type}
      fileName={fileName.replace(".", "")}
      data={configCellsValues(arrangedData.data)}
      aggregates={arrangedData.aggregates}
      {...{ buttonText, multi, buttonIcon, group, columns, isMobile }}
    />
  );
};
const mapStateToProps = (state) => {
  const { isMobile } = state.publicValues;
  return { isMobile };
};
export default connect(mapStateToProps)(SingleExcelFile);
