import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import {
  storeCategory,
  updateCategory,
} from "../../../../redux/actions/ItemAction";

// ======== HOOKS ========
import { useScreenSize } from "../../../../Hooks";

// ======== ICONS ========

// ======== CONSTANTS ========
import { CLOSE_FORM } from "../../../../redux/actions/ItemAction/type";

// ======== COMPONENTS ========
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import { default as Grid } from "@mui/material/Unstable_Grid2";
import { LoadingButton } from "@mui/lab";
import Form from "../../../../components/Forms/Form";
import { useFormContext } from "react-hook-form";
import FormTextField from "../../../../components/Forms/inputs/FormTextField";
import FormSwitch from "../../../../components/Forms/inputs/FormSwitch";
import { NEW_ITEM_ID } from "../../const";
import FormCheckBox from "../../../../components/Forms/inputs/FormCheckBox";
import { SORT_PRODUCTS_BY_ORDER_COLLECTION } from "../../../../privilegesIndexes";

export const initialCategory = {
  id: NEW_ITEM_ID,
  name: null,
  code: null,
  enable: true,
  with_vat: false,
  with_deposit: false,
};

export const CategoriesDetails = ({ storeCategory, updateCategory }) => {
  // ======== HOOKS ========

  const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();

  const dispatch = useDispatch();

  const { isNewCategory, details } = useSelector(({ goods, branches }) => {
    const { details } = goods.categories.data;

    //reshaped to fit the form controller
    const selectedBranchesArrayToObject = branches.branches.reduce(
      (acc, branch) => {
        acc[branch.id] =
          details.data?.selected_branches?.find((id) => +id === branch.id) !==
          undefined;
        return acc;
      },
      {}
    );

    return {
      isNewCategory: details.data.id === NEW_ITEM_ID,
      details: {
        ...details.data,
        selected_branches: selectedBranchesArrayToObject,
      },
    };
  });

  // ======== STATES ========

  // ======== HANDLERS ========

  const handleSubmit = (category) => {
    /* 
        Form data passes null as string 'null'
        Laravel middleware (ConvertEmptyStringsToNull) convert empty strings back to null
        Null value can be then validate as |nullbale inside method controller
        FRONT END => null -> '' | BACK END => '' -> null
    */

    //reshaped to store as minimum data as needed
    const selectedBranchesObjectToArray = Object.entries(
      category.selected_branches
    )
      .filter(([branch, isSelected]) => isSelected)
      .map(([branch, isSelected]) => parseInt(branch));

    const filterData = {
      ...category,
      code: category?.code ?? "",
      selected_branches: selectedBranchesObjectToArray,
    };

    (isNewCategory ? storeCategory : updateCategory)(filterData);
  };

  // ======== CONSTANTS ========

  // const defaultValues = useMemo(() => details, [details.id]);

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  const CardJSX = (
    <Fragment>
      <CardHeader avatar="פרטים כלליים" />
      <CardContent>
        <Form
          formId="categories-details"
          onSubmit={handleSubmit}
          defaultValues={details}
        >
          <FormFields currentCategory={details} />
        </Form>
      </CardContent>
      <CardActions sx={{ padding: 2 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: "rgb(238, 49, 49)" }}
          color="secondary"
          size="large"
          onClick={() => dispatch({ type: CLOSE_FORM })}
        >
          בטל
        </Button>

        <LoadingButton
          form="categories-details"
          variant="contained"
          color="primary"
          size="large"
          type="submit"
        >
          {isNewCategory ? "שלח" : "עדכן"}
        </LoadingButton>
      </CardActions>
    </Fragment>
  );

  return (
    <Box
      sx={{
        maxWidth: 1,
        my: 3,
        boxShadow: "0px 5px 16px 0px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Card variant="outlined" sx={{ padding: 0 }}>
        {CardJSX}
      </Card>
    </Box>
  );
};

const FormFields = ({ currentCategory }) => {
  // ======== HOOKS ========

  const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();

  const { branches } = useSelector(({ branches, goods }) => {
    return {
      branches: branches.branches,
    };
  });

  const accessPermissions = useSelector(
    (state) => state.user_privileges.privileges_access
  );

  const categories = useSelector(
    (state) => state.goods.categories.data.master.data
  );

  const [takenCatgOrderLocationValues, setTakenCatgOrderLocationValues] =
    useState([]);

  const dispatch = useDispatch();

  const { control, watch, setValue } = useFormContext();

  const [enable] = watch(["enable"]);
  const [debitByBranch] = watch(["debitByBranch"]);

  // ======== STATES ========

  // ======== UI ========

  useEffect(() => {
    const newTakenCatgOrderLocationValues = categories
      .filter((catg) => catg?.order_location_placement)
      .map((catg) => catg?.order_location_placement);
    setTakenCatgOrderLocationValues(newTakenCatgOrderLocationValues);
  }, [categories]);

  useEffect(() => {
    !enable && setValue("order_location_placement", null);
  }, [enable]);

  return (
    <Grid container spacing={isMobile || isTablet ? 2 : 5}>
      <Grid container xs={12}>
        <Grid xs={12} md={4} className="flex-center-start">
          <FormTextField
            fullWidth
            {...{
              control,
              label: "שם קטגוריה",
              name: "name",
              rules: {
                required: "שדה חובה",
              },
            }}
          />
        </Grid>

        <Grid xs={6} md={2} className="flex-center-start">
          <FormSwitch
            {...{
              control,
              label: enable ? "פעיל" : "לא פעיל",
              name: "enable",
            }}
          />
        </Grid>

        <Grid xs={6} md={2} className="flex-center-start">
          <FormSwitch
            {...{
              control,
              label: "כולל מע״מ",
              name: "with_vat",
            }}
          />
        </Grid>

        <Grid xs={6} md={2} className="flex-center-start">
          <FormSwitch
            {...{
              control,
              label: "קיים פיקדון",
              name: "with_deposit",
            }}
          />
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Divider sx={{ padding: "20px" }} />
      </Grid>
      <Grid container xs={12}>
        <Grid xs={6} md={4} className="flex-center-start">
          <FormTextField
            fullWidth
            {...{
              control,
              label: "חשבון חובה",
              name: "code",
            }}
          />
        </Grid>

        <Grid xs={2} className="flex-center-start">
          <FormSwitch
            {...{
              control,
              label: "לפי סניף",
              name: "debitByBranch",
            }}
          />
        </Grid>
        {accessPermissions?.includes(SORT_PRODUCTS_BY_ORDER_COLLECTION) && (
          <>
            <Grid xs={12}>
              <Divider sx={{ padding: "20px" }} />
            </Grid>
            <Grid xs={6} md={4} className="flex-center-start">
              <FormTextField
                fullWidth
                {...{
                  control,
                  label: "מיקום בהזמנה",
                  name: "order_location_placement",
                  isDisabled: !enable,
                  rules: {
                    validate: {
                      unique: (value) =>
                        !takenCatgOrderLocationValues.includes(+value) ||
                        +value === currentCategory?.order_location_placement ||
                        "ערך זה כבר משויך לקטגוריה אחרת",
                      positive: (value) =>
                        +value > 0 || !value || "ערך חייב להיות גדול מ-0",
                    },
                  },
                  type: "number",
                }}
              />
            </Grid>
          </>
        )}
        <Grid container xs={12}>
          {debitByBranch &&
            branches.map((branch) => (
              <Grid xs={6} md={2}>
                <FormCheckBox
                  {...{
                    control,
                    label: branch.name,
                    name: `selected_branches[${branch.id}]`,
                  }}
                  key={branch.id}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = { storeCategory, updateCategory };

export default connect(null, mapDispatchToProps)(CategoriesDetails);
