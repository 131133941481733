import { mapKeys } from "lodash";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
import { LOGOUT } from "../actions/loginAction/types";
import {
  FETCH_NETWORKS,
  FETCH_NETWORK_BRANCHES,
  FETCH_NETWORK_CATEGORIES,
  FETCH_NETWORK_PRODUCTS_CATEGORIES,
  FETCH_NETWORK_SUPPLIERS,
} from "../actions/networkAction/types";

const INITIAL_STATE = {
  categories: [],
  productsCategories: [],
  suppliers: [],
  branches: [],
  networks: {},
};

const networkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_NETWORK_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case FETCH_NETWORK_PRODUCTS_CATEGORIES:
      return {
        ...state,
        productsCategories: action.payload,
      };
    case FETCH_NETWORK_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
      };
      case FETCH_NETWORK_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case FETCH_NETWORKS:
      return {
        ...state,
        networks:mapKeys(action.payload,"value") ,
      };
    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default networkReducer;
