import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import {
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "../../../../../components/buttons/Button";
import { saveDailyNotes } from "../../../../../redux/actions/scheduleAction";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const AddDailyNotes = ({ event, handleOpen, updateTable }) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [dailyNotes, setDailyNotes] = useState("");
  const dispatch = useDispatch();

  // ======== STATES ========

  // ======== HANDLERS ========

  const updateDailyNotes = ({ target }) => {
    setDailyNotes(target.value);
  };

  const handleSubmit = () => {
    const tempEventAttributes = {
      id: event.id,
      daily_notes: dailyNotes,
    };

    dispatch(saveDailyNotes(tempEventAttributes, handleOpen, updateTable));
  };
  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      margin={4}
      component={"div"}
    >
      <Typography variant={"h4"}>הערות יומיות</Typography>
      <Box padding={2} component={"div"}>
        <TextField onChange={updateDailyNotes} multiline maxRows={10} rows={5} />
      </Box>
      <Button
        onClick={() => handleSubmit()}
        className={`btn--submit ${isMobile ? "fluid" : ""}`}
        textValue="שמור הערות"
      />
    </Box>
  );
};

export default AddDailyNotes;
