import { mapKeys, omit,isEqual } from "lodash";
import { deepCompare } from "../../helpers";
// import { deepCompare } from "../../helpers";
import {
  ADD_MESSAGE,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  FETCH_MESSAGES,
  FETCH_MESSAGES_TO_SHOW,
  ACKNOWLEDGED_MESSAGES
} from "../actions/messageAction/types";

const INITIAL_STATE = {
  currentMessagesToShow: {},
  allMessages: {},
  acknowledgedMessages: {},
  isAcknowledged:false
};

const compare = (a,b)=>{
  const A = Object.values(a).map(({id,updated_at})=>({id,updated_at}))
  const B = Object.values(b).map(({id,updated_at})=>({id,updated_at}))
  return deepCompare(A,B)
}

const messageReducer = (state = INITIAL_STATE, action) => {
  const { type, payload,isAcknowledged } = action;
  switch (type) {
    case FETCH_MESSAGES_TO_SHOW:
      const currentMessagesToShow = mapKeys(payload, "id")
      return {
        ...state,
        currentMessagesToShow,
        isAcknowledged:compare(state.acknowledgedMessages,currentMessagesToShow)
      };
    case ACKNOWLEDGED_MESSAGES:
      return {
        ...state,
        acknowledgedMessages: payload,
        isAcknowledged
      };
    case FETCH_MESSAGES:
      return {
        ...state,
        allMessages: mapKeys(payload, "id"),
      };
    case ADD_MESSAGE:
      return {
        ...state,
        allMessages: { ...state.allMessages, [payload.id]: payload },
      };
    case EDIT_MESSAGE:
      return {
        ...state,
        allMessages: { ...state.allMessages, ...payload },
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        allMessages: omit(state.allMessages, payload),
      };
    default:
      return state;
  }
};

export default messageReducer;
