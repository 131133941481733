import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-responsive-modal";
import Button from "../../../../components/buttons/Button";
import { isEmpty } from "lodash";
import BtnTooltip from "../../../../components/tooltips/BtnTooltip";
import { Collapse, Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import { connect } from "react-redux";
import {
  obligateEmployeesToWork,
  updateSchedule,
} from "../../../../redux/actions/scheduleAction";
import { FaUserLock } from "react-icons/fa";
import { getSafe } from "../../../../helpers";

const ObligateByDay = ({
  day,
  existing_shifts,
  events,
  submission_required_dates,
  obligateEmployeesToWork,
  updateSchedule,
}) => {
  const [open, setOpen] = useState(false);
  const [submissionRequired, setSubmissionRequired] = useState({});
  const [showSuccessSnackBar, setShowSuccessSnackBar] = useState(false);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);

  
  useEffect(() => {
    setSubmissionRequired(submission_required_dates?.[day.actual_date] ?? {});
  }, [submission_required_dates]);

  const checkBoxRender = useMemo(() => {
    const hirarcy = {};
    // init from existing shift
    Object.values(existing_shifts).forEach((existing_shift) => {
      hirarcy[existing_shift.id] = {
        id: existing_shift.id,
        name: existing_shift.name,
        departments: {},
      };
    });

    // add departments by shift
    Object.values(events)
      .filter(({ week_day }) => {
        return week_day === day.day_num;
      })
      .forEach((event) => {
        if (
          event.department && hirarcy[event.new_sub_shift_id] && 
          !(event.department.id in hirarcy[event.new_sub_shift_id].departments)
        ) {
          hirarcy[event.new_sub_shift_id].departments[event.department.id] = {
            id: event.department.id,
            name: event.department.name,
          };
        }
      });

    return hirarcy;
  }, [existing_shifts, events]);

  const col_date = day.actual_date;
  const isDateRequired =
    submission_required_dates && submission_required_dates[col_date];

  useEffect(() => {
    if (submission_required_dates?.[col_date]) {
      setSubmissionRequired(submission_required_dates?.[col_date].shift_ids);
    }
  }, [submission_required_dates]);



  const handleAllCheck = (e) => {
    let shiftIds = e.target.value;
    shiftIds = shiftIds.split(",");
    const checked = e.target.checked;
    const currentRequired = { ...submissionRequired };
    if (checked) {
      shiftIds.map((shift) => {
        if (!(shift in currentRequired)) {
          currentRequired[shift] = Object.keys(
            checkBoxRender[shift].departments
          ).map((id) => parseInt(id));
        }
      });
      setSubmissionRequired(currentRequired);
    } else {
      setSubmissionRequired({});
    }
  };

  const handleShiftCheck = (e) => {
    const shiftId = e.target.value;
    const checked = e.target.checked;
    const currentRequired = { ...submissionRequired };
    if (checked) {
      if (!(shiftId in currentRequired)) {
        // check all shif's departments
        currentRequired[shiftId] = Object.keys(
          checkBoxRender[shiftId].departments
        ).map((id) => parseInt(id));
      }
    } else {
      delete currentRequired[shiftId];
    }
    setSubmissionRequired(currentRequired);
  };

  const handleDepartmentCheck = (e, shiftId) => {
    const deparmtentId = e.target.value;
    const checked = e.target.checked;
    const currentRequired = { ...submissionRequired };
    if (checked) {
      currentRequired[shiftId].push(parseInt(deparmtentId));
    } else {
      currentRequired[shiftId] = currentRequired[shiftId].filter(
        (depId) => depId != deparmtentId
      );
    }
    setSubmissionRequired(currentRequired);
  };

  const handleSubmit = async () => {
    if (
      submissionRequired === submission_required_dates?.[col_date]?.shift_ids
    ) {
      return setOpen(false);
    }
    try {
      const errorResponse = await obligateEmployeesToWork(col_date, submissionRequired);
      if(errorResponse) {
        setOpen(false);
        return setShowErrorSnackBar(true);
      }
      setOpen(false);
      setShowSuccessSnackBar(true);
    } catch (error) {
      setShowErrorSnackBar(true);
      console.log(error);
    }
  };

  const abortObligationWork = () => {
    try {
      delete submission_required_dates[col_date];
      updateSchedule({
        submission_required_dates,
      });
      setSubmissionRequired({});
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Snackbar
        open={showSuccessSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => setShowSuccessSnackBar(false)}
      >
        <Alert variant="filled" severity="success">
          {`!יום ננעל בהצלחה`}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => setShowErrorSnackBar(false)}
      >
        <Alert variant="filled" severity="error">
          התרחשה תקלה בעת נעילת יום
        </Alert>
      </Snackbar>
      <Modal
        animationDuration={0}
        styles={{
          modal: { maxWidth: "50rem", padding: "3.5rem", textAlign: "center" },
        }}
        center
        open={open}
        onClose={() => setOpen(false)}
      >
        <h1>חובת הגשה</h1>
        <h2>בחר/י לאיזה מחלקות ברצונך לחייב הגשת משמרות</h2>
        <div
          className="checkbox"
          style={{
            border: "2px solid  #EAEAEA",
            borderRadius: "5px",
            padding: "1rem",
          }}
        >
          <label className="checkbox--container">
            חובה לכולם
            <input
              type="checkbox"
              value={existing_shifts.map((shifts) => shifts.id)}
              checked={
                Object.keys(submissionRequired || {}).length ===
                existing_shifts.map((shifts) => shifts.id).length
              }
              onChange={handleAllCheck}
            />
            <span className="checkbox--checkmark"></span>
          </label>

          {Object.values(checkBoxRender).map((shift) => (
            <div key={shift.id}>
              <label className="checkbox--container">
                משמרת {shift.name}
                <input
                  type="checkbox"
                  value={shift.id}
                  checked={!!submissionRequired?.[shift.id]}
                  onChange={handleShiftCheck}
                />
                <span className="checkbox--checkmark"></span>
              </label>
              <Collapse in={!!submissionRequired?.[shift.id]}>
                {Object.values(shift.departments).map((department) => (
                  <label
                    key={department.id}
                    className="checkbox--container checkbox--nested"
                  >
                    {department.name}
                    <input
                      type="checkbox"
                      value={department.id}
                      checked={getSafe(
                        () =>
                          submissionRequired[shift.id].includes(department.id),
                        false
                      )}
                      onChange={(e) => handleDepartmentCheck(e, shift.id)}
                    />
                    <span
                      className="checkbox--checkmark"
                      style={{ right: "2rem" }}
                    ></span>
                  </label>
                ))}
              </Collapse>
            </div>
          ))}
        </div>

        <div
          style={{ width: "fit-content", margin: "0 auto", marginTop: "2rem" }}
          className="flex"
        >
          {isDateRequired ? (
            <Button
              onClick={abortObligationWork}
              className="btn--abort"
              textValue="ביטול חובה"
            />
          ) : (
            <Button
              onClick={() => setOpen(false)}
              className="btn--neutral"
              textValue="ביטול"
            />
          )}
          <Button
            disabled={isEmpty(submissionRequired)}
            onClick={handleSubmit}
            className="btn--submit"
            textValue="אישור"
          />
        </div>
      </Modal>
      <BtnTooltip
        placement="right"
        text={
          isDateRequired
            ? ` קיימת חובת הגשה בוצע ע"י ${submission_required_dates[col_date].required_by}`
            : "לחץ כאן כדי לחייב הגשה בתאריך זה"
        }
      >
        <button
          type="button"
          className={`btn padding-0 fit-content ${
            isDateRequired ? "btn--outline__abort " : ""
          }`}
          style={{ fontSize: "1.6rem" }}
          onClick={() => setOpen(true)}
        >
          <FaUserLock size="1.4em" />
        </button>
      </BtnTooltip>
    </>
  );
};
const mapStateToProps = (state) => {
  const { selected_branch } = state.branches;
  const { events, schedule, existing_shifts } = state.schedule;
  let { submission_required_dates } = schedule;
  submission_required_dates = submission_required_dates;
  return {
    events,
    branch_id: selected_branch.id,
    submission_required_dates,
    existing_shifts,
  };
};
export default connect(mapStateToProps, {
  obligateEmployeesToWork,
  updateSchedule,
})(ObligateByDay);
