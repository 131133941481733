import { chunk } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useExpanded, useTable } from "react-table";
import { useReactToPrint } from "react-to-print";

const TablesDivider = ({
  columns,
  data,
  printDate,
  printMeta,
  holidays,
  printTitle,
  setPrint,
  printAll = false,
}) => {
  const tableRef = useRef();
  const handlePrintTable = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: () => setPrint(false),
  });

  useEffect(() => {
    return (() => {
      if (!printAll) {
        handlePrintTable();
      }
    })();
  });

  return (
    <div ref={tableRef}>
      {chunk(
        columns.filter(
          (x) => x.isVisible && x.id !== "expander" && x.id !== "buttons"
        ),
        20
      ).map((chunked, chunked_idx) => (
        <PrintTable
          printMeta={printMeta}
          title={printDate ? `${printTitle} לתאריך ${printDate}` : printTitle}
          key={chunked_idx}
          columns={chunked}
          holidays={holidays}
          data={data}
          size={chunked.length}
          first={!chunked_idx}
        />
      ))}
    </div>
  );
};

const PrintTable = ({
  columns,
  holidays,
  data,
  title,
  printMeta = [],
  size,
  first,
}) => {
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded
  );

  return (
    <div className="table-for-print page-break">
      <h2 className="table__h-print">{title}</h2>
      <div className="table__meta-print">
        {printMeta.map((elem, index) => (
          <span style={{ padding: "0 1rem" }} key={index}>
            {elem}
          </span>
        ))}
        {!first ? <span>{"המשך טבלה>>>"}</span> : ""}
      </div>
      <table
        style={first ? { width: "100%" } : { width: `${size * 5}%` }}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((group) => (
            <tr {...group.getHeaderGroupProps()}>
              {group.headers.map((column) => (
                <th style={{ width: "5%" }} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{
                        width: "5%",
                      }}
                      {...cell.getCellProps()}
                      key={cell.column.id}
                    >
                      {cell.render("Cell", { holidays })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td style={{ width: "5%" }} {...column.getFooterProps()}>
                  {column.id === "working_date"
                    ? `סה״כ ${
                        [
                          ...new Set(
                            data
                              .filter(
                                (row) =>
                                  !row.vacation &&
                                  !row.sick &&
                                  !row.reserve &&
                                  !row.is_week_summary &&
                                  !row.is_padded
                              )
                              .map((x) => x.working_date)
                          ),
                        ].length
                      } ימים`
                    : column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};

export default TablesDivider;
