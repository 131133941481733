import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";

// ======== ICONS ========

// ======== CONSTANTS ========
import {
  requiredFieldError,
  mobileNumberValidation,
  IDValidation,
} from "../../private/services/validations";

// ======== COMPONENTS ========
import { Grid } from "@mui/material";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { stringFilterDigits } from "../../../helpers";
import { getOtpCode } from "../services/api";
import { setError } from "../../../redux/actions/errorAction";

const OtpNumberInputForm = ({ lastSuccessfullCredentials }) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const { control, setValue, watch } = useFormContext();

  // ======== STATES ========

  // ======== HANDLERS ========
  const handleResendOtp = async () => {
    try {
      const response = await getOtpCode(lastSuccessfullCredentials);
    } catch (error) {
      const errorMessage = error?.message || "שגיאה";
      dispatch(setError(errorMessage));
    }
  };

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Grid container direction="column">
      <Grid item sx={12}>
        <FormTextField
          fullWidth
          style={{ width: "300px" }}
          {...{
            control,
            handleChange: ({ name, value }) => {
              setValue(name, stringFilterDigits(value));
            },
            label: "יש להקליד את הקוד",
            name: "otpNumber",
            rules: {
              required: requiredFieldError,
            },
          }}
        />
        <Typography variant="h6" xs={{ padding: "10px" }}>
          לא קיבלת את הקוד? לחץ לקבלת הקוד פעם נוספת ב-
          <Link component="button" variant="body2" onClick={handleResendOtp}>
            SMS
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OtpNumberInputForm;
