import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useScreenSize } from "../../Hooks";
import { hideConfirmBar } from "../../redux/actions/confirmAction";

const ConfirmBar = ({
  isOpen,
  confirmMsg,
  hideConfirmBar,
  collapsed,
}) => {
  const { isMobile } = useScreenSize();
  const [size, setSize] = useState("-225");
  useEffect(() => {
    setSize(isMobile ? "0" : collapsed ? "-80px" : "-225");
  }, [isMobile, collapsed]);

  if (isOpen) {
    setTimeout(() => {
      hideConfirmBar();
    }, 3500);
  }

  return (
    <div
      style={isOpen ? { transform: `translateX(${size})` } : {}}
      className={`confirm-bar ${isOpen ? "confirm-bar__show" : ""}`}
      open={isOpen}
    >
      <i className="fa fa-check-circle" /> {confirmMsg}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isOpen, confirmMsg } = state.confirmBar;
  const { collapsed, isMobile } = state.publicValues;

  return { isOpen, confirmMsg, collapsed };
};

export default connect(mapStateToProps, { hideConfirmBar })(ConfirmBar);
