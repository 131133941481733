import Axios from "axios";
import { FaBullseye } from "react-icons/fa";
import { LOGOUT_ADMIN } from "../AdminLoginAction/types";
import { SET_ERROR } from "../errorAction/types";
import { LOGOUT } from "../loginAction/types";
import {
  FETCH_NETWORKS,
  FETCH_NETWORK_BRANCHES,
  FETCH_NETWORK_CATEGORIES,
  FETCH_NETWORK_PRODUCTS_CATEGORIES,
  FETCH_NETWORK_SUPPLIERS,
} from "./types";

export const getNetworkCategories =
  (countable = false, branch_id = 0) =>
  async (dispatch, getState) => {
    try {
      const response = await Axios.get("/networks/getCategories", {
        params: {
          countableOnly: countable,
          branch_id,
        },
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });
      const { data } = response;
      dispatch({
        type: FETCH_NETWORK_CATEGORIES,
        payload: data,
      });
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        }
        if (e.response.status === 404) {
          dispatch({
            type: FETCH_NETWORK_CATEGORIES,
            payload: {},
          });
        }
      }
      console.error(e);
    }
  };
export const getNetworkProductsCategories =
  (countable = false, branch_id = 0) =>
  async (dispatch, getState) => {
    try {
      const response = await Axios.get("/networks/getProductsCategories", {
        params: {
          countableOnly: countable,
          branch_id,
        },
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });
      const { data } = response;
      dispatch({
        type: FETCH_NETWORK_PRODUCTS_CATEGORIES,
        payload: data,
      });
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        }
        if (e.response.status === 404) {
          dispatch({
            type: FETCH_NETWORK_PRODUCTS_CATEGORIES,
            payload: {},
          });
        }
      }
      console.error(e);
    }
  };

export const getNetworkSuppliers = () => async (dispatch, getState) => {
  try {
    const response = await Axios.get("/networks/getSuppliers", {
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });
    const { data } = response;
    dispatch({
      type: FETCH_NETWORK_SUPPLIERS,
      payload: data,
    });
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      if (status === 401) {
        dispatch({ type: LOGOUT });
      }
      if (status === 404) {
        dispatch({
          type: FETCH_NETWORK_SUPPLIERS,
          payload: [],
        });
      }
    }
    console.error(e);
  }
};
export const getNetworkBranches = () => async (dispatch, getState) => {
  try {
    const response = await Axios.get("/networks/getBranches", {
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });
    const { data } = response;

    dispatch({
      type: FETCH_NETWORK_BRANCHES,
      payload: data,
    });
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      if (status === 401) {
        dispatch({ type: LOGOUT });
      }
      if (status === 404) {
        dispatch({
          type: FETCH_NETWORK_BRANCHES,
          payload: [],
        });
      }
    }
    console.error(e);
  }
};

export const fetchNetworks = () => async (dispatch, getState) => {
  try {
    const { data } = await Axios.get("admin/network", {
      headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
    });

    dispatch({
      type: FETCH_NETWORKS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    if (e.response?.status === 401) {
      dispatch({ type: LOGOUT_ADMIN });
    } else if (e.response?.status === 500) {
      dispatch({
        type: SET_ERROR,
        errorTitle: "ארעה שגיאה בשרת",
        error:
          "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
      });
    }
  }
};
