import { Divider, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { ControlSignaturePad } from "../../../components/Forms/inputs/SignaturPad";
import EmailTagInput from "../../../components/inputs/EmailsTagTextField";
import SimpleReactTable from "../../../components/tables/SimpleReactTable";
import { fixPrice } from "../../../helpers";
import { useScreenSize } from "../../../Hooks";
import { Context } from "../Show";
import "./style.css"

const AccessibilityProcedure = () => {
  const { parts, user, selectedBranch } = useContext(Context);
  const { isMobile } = useScreenSize();
  const { control,setValue } = useFormContext();
  const [emails,setEmails] = useState([]);
  const { replace } = useFieldArray({ control, name: "parts" });
  const signature = useWatch({ control, name: "signature" });

  useEffect(()=>{ 
    setValue("in_charge","in_charge_notification")
    setValue("branch_name",selectedBranch.name);
    if(user.identifier){
        setValue("identifier",user.identifier);
    }
  },[])

  useEffect(()=>{
    setValue("send_emails",emails)
  },[emails])

  return (
      
<div style={{display:"flex",flexDirection:"column", margin:isMobile?0:50, justifyContent:"center",gap: "3rem"}}>
    <div
      style={{
        margin:isMobile?0:50,
        display: "flex",
        flexDirection: "column",
        justifyContent:"center",
        gap: "3rem",
        fontSize: isMobile ? "1.8rem" : "2rem",
        maxWidth:'100%'
      }}
      >
        <h2
          style={{
            textAlign: "center",
            fontSize: isMobile ? "2rem" : "",
            textDecoration:"underline"
          }}
        >
            עקרונות כלליים להתנהגות נגישה ורגישה    
        </h2>
        <h3 
          style={{
            textAlign: "center",
            fontSize: isMobile ? "2rem" : "",
          }}>שם המסעדה :{selectedBranch.name}</h3>
        <p>עובד יקר,</p>
        <p>כארגון אשר חרט על דגלו מתן שירות נגיש ורגיש לכלל האוכלוסיות אנו עושים כל מאמץ לייצר חוויה נעימה בכל מפגש של אדם מוגבל עם עובד הרשת</p>
        <p>סרטון המסביר בקצרה על הנושא</p>
        <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/CcAHu7L9D_0?controls=0&amp;start=1" 
            title="YouTube video player" 
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen>
        </iframe>    
        <p>יש לצפות בסרטון, לעבור על דף המידע המצורף ולחתום בתחתית כל עמוד</p>    
        <ul className="hebrew-list">
            <li >
                <strong>סוגי מוגבלויות:</strong>
                <ul  style={{marginRight:'50px'}}>
                    <li>פיזית – כגון: מוגבלות בניידות אנשים בכיסא גלגלים או משתמשים באביזרי הליכה, אנשים עם מוגבלות בידיים וכד'.</li>
                    <li>חושית – כבדי ראיה ושמיעה, עיוורים וחרשים.</li>
                    <li>נפשית – מחלות נפש שונות , הלומי קרב וכד</li>
                    <li>שכלית קוגנטבית – אנשים על הרצף האוטיסטי וכד'</li>
                </ul>
                 <p style={{fontWeight:'bold',textDecoration:"underline"}}>דגשים להתנהגות נגישה ורגישה :</p>
                 <ol style={{marginRight:'50px'}}>
                    <li>התנהגו באדיבות בכבוד ובהתחשבות כמו אל כל לקוח.</li>
                    <li>התרכזו באדם שבא לקבל שירות ולא במגבלה שלו.</li>
                    <li>התנהגו בטבעיות השתמשו בשכל הישר, אם אתם לא יודעים איך לעזור אל תהיו נבוכים תשאלו איך אפשר לעזור.</li>
                    <li>כאשר נראה לכם שאדם זקוק לעזרה אל תכפו את עזרתכם, שאלו אם אפשר לעזור ואיך אפשר לעזור.</li>
                    <li>דברו ישירות עם האדם – לא המלווה או העוזר שלו.</li>
                    <li>אל תגעו באדם או באביזר של אדם כדי לעזור לו לפני שקיבלתם רשות.</li>
                    <li>אל תלטפו כלב נחייה ללא רשות.</li>
                    <li>
                        כאשר מדברים עם אדם שיש לו קושי בתקשורת (הבנה, דיבור, שמיעה, שפה וכד') וודאו שהבנתם את האדם ושהוא הבין אתכם, אם לא הבנתם שאלו שוב.
                        בהתאם לצורך אפשר להשתמש באמצעי עזר כגון: לולאת השראה (מתאים בד"כ לאנשים עם מכשיר שמיעה מותאם), כתיבה על דף נייר (אדם חרש או מתקשה בדיבור), תפריט נגיש להיעזר בתמונות.
                    </li>
                    <li>אנשים עם מוגבלות לרוב יותר עצמאיים ממה שאנחנו חושבים, במקום נגיש הם בדרך כלל יכולים להסתדר. לכן וודאו שאין מכשולים בדרך וכל המעברים נגישים וחופשיים. (אל הכניסה, אל הדלפק, אל השירותים וכמובן המעברים באזור הישיבה)</li>
                    <li>אין להסיע אדם בכיסא גלגלים ללא רשותו.</li>
                    <li>הציעו עזרה בפתיחת דלתות כבדות לאנשים המתניידים בכיסא גלגלים והמתקשים בהליכה אך קבלו את הסכמת האדם לעזור לו.</li>
                    <li>הציעו עזרה לאנשים עם מוגבלות פיזית לשאת את המזון לשולחן אך קבלו את הסכמת האדם לעזור לו.</li>
                    <li>אדם או ילד שמתנהג בצורה לא רגילה או באי שקט לאפשר לו לקבל שירות ללא עמידה בתור.(יתכן והוא עם מוגבלות שכלית או נפשית)</li>
                    <li>אדם שמתפרץ או מדבר בצורה קצת תוקפנית  -  יש להישאר רגוע ועניני לאפשר לו לקבל שירות ללא עמידה בתור (יתכן שהאדם פגוע נפשית)</li>
                 </ol>
            </li>
            <li>
                <strong>דגשים להתנהגות נגישה לאדם לקוי שמיעה או חרש:</strong>
                <p>כאשר אתם מדברים עם אדם לקויי שמיעה או חרש :</p>
                <ul style={{marginRight:'50px'}}>
                    <li>דברו לאט וברור – משפט קצר</li>
                    <li>אפשר להשתמש בתנועות ידיים והבעות פנים כדי לסייע בהבנה.</li>
                    <li>אל תצעקו!! ואל תגזימו בתנועות הידיים</li>
                    <li>אם לא שמעו אתכם ניתן לכתוב אל נייר</li>
                    <li>הציעו להשתמש בלולאת השראה (מתאים לחלק מאוכלוסיית לקויי שמיעה)</li>
                    <li>העזרו בתפריטים הכתובים ו/או הנגישים.</li>
                </ul>
                <strong>חשוב ביותר:</strong>
                <p>אם השיטה הנהוגה במקום היא כריזה קולית לקבלת ההזמנה – יש להחליט ולקבוע שיטה אלטרנטיבית. (הבאת ההזמנה לאדם, מכשיר רטט. אחר...)</p>
                <p>3 דגשים להתנהגות נגישה לאדם עם מוגבלות בראיה או עיור</p>
                <p>כאשר אתם נותנים שירות לאדם עם מוגבלות בראיה:</p>
                <ul style={{marginRight:'50px'}}>
                    <li>הקריאו לו /ספרו לו את התפריט</li>
                    <li>השתמשו בתפריט נגיש  / מסך תמונות כתב מוגדל ללקויי ראיה.</li>
                    <li>בעת מתן כסף – יש לספור את הכסף בקול.</li>
                    <li>הסבירו לו מה יש על המגש.</li>
                    <li>שאלו האם זקוק לעזרה להגיע לשולחן. לא להוביל ללא בקשתו הסכמתו – לשאול איך להוביל אותו.</li>
                    <li>וודאו שאין מכשולים</li>
                    <li>אם נעזר בכלב נחיה ללכת בצדו הנגדי של הכלב.</li>
                    <li>לא ללטף את הכלב ללא רשות.</li>
                </ul>
            </li>
        </ul>
        <p>לסיכום:</p>
        <p>
            זכרו יש לדבר ולהתנהג בטבעיות לא לרחם ובטח לא לזלזל.
            כאשר אדם עם מוגבלות מבקש התאמה בסביבה או בשירות זו אינה תלונה אלה צורך בעזרה.
            שימו לב: ישנם מוגבלויות שאינן נראות לעין.
            לכן השתדלו להיענות בחיוב לבקשות של כל אדם ככל האפשר בסבלנות גם אם נדרש יותר זמן לתקשר ולתת שירות.
        </p>
        <h3 style={{textDecoration:"underline"}}>כתב יידוע עובדים - חוק שוויון זכויות לאנשים עם מוגבלות</h3>
        <p>עובדים ועובדות יקרים,</p>
        <p>
            בשנת 1998 נחקק בישראל "חוק שוויון זכויות לאנשים עם מוגבלות".
            מטרת החוק היא: "להגן על כבודו וחירותו של אדם עם מוגבלות, ולעגן את זכותו להשתתפות שוויונית ופעילה בחברה בכל תחומי החיים, ולתת מענה הולם לצרכיו המיוחדים באופן שיאפשר לו לחיות את חייו בעצמאות מרבית, בפרטיות ובכבוד, תוך מיצוי מלא יכולתו."
        </p>
        <p>
            החוק קובע כי חל איסור להפלות במתן שירות לציבור האנשים עם מוגבלות.
            כלומר, חובה לתת או לאפשר שירות שוויוני לאדם עם מוגבלות ככל הלקוחות ולא בנפרד מהם וזאת ברגישות לצרכיו של האדם וביחס אדיב ומכבד.
            לכן, עפ"י החוק אנו נדרשים להיות נגישים לאנשים עם מוגבלות – ולבצע התאמות למקום הפיזי ולשירות שאנו נותנים.
            מהי נגישות כהגדרתה בחוק: אפשרות הגעה למקום, אפשרות תנועה והתמצאות בו, שימוש והנאה משירות, קבלת מידע, שימוש במיתקנים הנמצאים במקום, והכל באופן שוויוני מכובד, עצמאי ובטיחותי
        </p>
        <p>{selectedBranch.company_name} רואה ערך חברתי וחשיבות רבה במתן יחס ושירות שוויוני ללא הפליה לכל האנשים </p>
        <p>אנו מחויבים לביצוע כל התאמות הנגישות האפשריות הנדרשות עפ"י החוק בכל הסניפים, הנגשה פיזית והנגשת השירות וזאת על מנת לאפשר לכל קהל לקוחותינו להגיע אלינו ללא מכשול, לקבל שירות נגיש ובעקר ליהנות מחוויית הבילוי אצלנו בסניפים.</p>
        <p>על כל העובדים חלה חובה לעבור הדרכה למתן שירות נגיש שבמהלכה גם יכירו את נהלי הנגישות של הרשת.</p>
        <p>ב {selectedBranch.company_name} ממונה <strong>{selectedBranch.in_charge_notification_name}</strong> לתפקיד רכז הנגישות</p>
        <p>תפקיד רכז הנגישות להדריך ולתת מענה לפניות העובדים ופניות הציבור בנושא זה.</p>
        <p>פרטי קשר : 
            <span style={{marginRight:"10px"}}>טל:
                <span style={{marginRight:"10px"}}>
                {selectedBranch.in_charge_notification_phone}</span>
                </span>
            <span style={{marginRight:"10px"}}>מייל:
                <span style={{marginRight:"10px"}}>
                    {selectedBranch.in_charge_notification_mail}
                </span>
                </span>
        </p>

        <div class="signature-container">
        <p>חתימה</p>
        <img src={signature?signature:""}  class="signature-image"/>
        <span class="signature-underline"></span>
    </div>
    <Divider style={{width:'100%'}}/>
    <div style={{display:"flex",flexDirection:'column'}}>
        <div style={{width:'300px',marginBottom:"10px"}}>
        <Typography>תעודת זהות</Typography>
        <FormTextField
        {...{
            fullWidth:true,
            name:"identifier",
            rules:{required:REQUIRED_MESSAGE}
        }}/>
        </div>
        <div>
        <EmailTagInput emails={emails} setEmails={setEmails} label = "מיילים לשליחה"/>
        </div>
    </div>
    <div>
        <Typography fontSize={16}>חתימה על הטופס</Typography>
        <ControlSignaturePad
        {...{
            name:"signature",
            rules:{ required: REQUIRED_MESSAGE }
        }}/>

    </div>
    </div>

    </div>
  );
};

export default AccessibilityProcedure;

  