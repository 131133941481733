export const workersURI = "workersManagement";
export const employeeIncludedTypesURI = `${workersURI}/includedTypes`;
export const updateEmailURI = `${workersURI}/updateEmail`;
export const validateAttributeURI = `${workersURI}/attributeValidation`;
export const newPosWorkersURI = `${workersURI}/getNewWorkers`;
export const getAllActiveWorkers = `${workersURI}/getAllActiveWorkers`;
export const getShiftPositions = `${workersURI}/getShiftPositions`;
export const getSalaryOfShiftPositions = `${workersURI}/getSalaryOfShiftPositions`;
export const uploadProfileImageURI = `${workersURI}/uploadProfileImage`;
export const getDailyTipStructure = `${workersURI}/getDailyTipStructure`;
export const getBranchesSettingsURI = `${workersURI}/getBranchesSettings`;
export const getSalaryStructureURI = `${workersURI}/findStructure`;
