import StoreBase from "./StoreBase";
import { action, computed, observable, makeObservable } from "mobx";

class MainStore extends StoreBase {
  isLoading = false;
  isMobile = false;
  isTablet = false;

  constructor() {
    super();
    makeObservable(this, {
      // Observables
      isLoading: observable,
      isMobile: observable,
      isTablet: observable,

      // Computeds

      // Actions -
      setIsLoading: action,
      setIsMobile: action,
      setIsTablet: action,
    });
  }

  setIsLoading = (flag) => {
    this.isLoading = flag;
  };
  setIsMobile = (flag) => {
    this.isMobile = flag;
  };
  setIsTablet = (flag) => {
    this.isTablet = flag;
  };
}

export default MainStore;
