import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dateReqFormat } from "../../helpers";
import Axios from "axios";
import { addWeeks, endOfWeek, format, startOfWeek } from "date-fns";
import { objectToFormData } from "../../components/Forms/helpers";
import { setConfirm, setSnackBar } from "../actions/confirmAction";
import { setError } from "../actions/errorAction";
import { startLoading, stopLoading } from "../../redux/actions/loaderAction";

const currentDate = new Date();
const INITIAL_STATE = {
  filters: {
    selectedDates: {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ),
      key: "selection",
      label: "החודש",
    },
  },
  incomeCashObj: {
    id: null,
    branch: { id: null, name: "" },
    z_numbers: [],
    date: dateReqFormat(new Date()),
    total_with_vat: 0,
    totalIncomeCash: null,
    pettyCashSum: 0,
    pettyCashDeposit: 0,
    note: "",
    img: "",
  },
  z_Obj: [],
  checkedBranches: [],
  incomeCashTable: [],
  emails: [],
  setAmountTotal: 0,
  isLoading: false,
  isEditMode: false,
  validationError: "",
};
const BASE_URL = "income/incomesCash";
const incomeCashSlice = createSlice({
  name: "incomeCash",
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
    setIncomeCashObj: (state, action) => {
      state.incomeCashObj = action.payload;
    },
    setTotalWithVat: (state, action) => {
      state.incomeCashObj.total_with_vat = action.payload;
    },
    setEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setValidationError: (state, action) => {
      state.validationError = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchZnumbers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchZnumbers.fulfilled, (state, action) => {
      state.z_Obj = action.payload.zData;
      state.incomeCashObj.pettyCashSum = action.payload.pettyCashSum;
      state.isLoading = false;
    });
    builder.addCase(fetchZnumbers.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchIncomesCash.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIncomesCash.fulfilled, (state, action) => {
      state.incomeCashTable = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchIncomesCash.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchIncome.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIncome.fulfilled, (state, action) => {
      state.incomeCashObj = action.payload.incomeCashObj;
      state.z_Obj = action.payload.allZ;
      state.isLoading = false;
    });
    builder.addCase(fetchIncome.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getEmails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEmails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emails = action.payload;
    });
    builder.addCase(getEmails.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emails = action.payload;
    });
    builder.addCase(deleteEmail.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emails = action.payload;
    });
    builder.addCase(createEmail.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const sendUpdateZed = createAsyncThunk(
  "incomeCash/incomeCashUpdate",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const incomeCashObj = getState().incomeCash.incomeCashObj;
    const formData = objectToFormData(incomeCashObj);
    let res;
    if (!incomeCashObj.id) {
      try {
        dispatch(startLoading());
        res = await Axios.post(`${BASE_URL}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status == 200) {
          dispatch(setConfirm("הפקדת מזומן נוצרה בהצלחה!"));
        }
      } catch (e) {
        dispatch(
          setError(
            "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
            "ארעה שגיאה הטופס לא מולא כמו שצריך"
          )
        );
      } finally {
        dispatch(stopLoading());
      }
    } else {
      try {
        dispatch(startLoading());
        res = await Axios.post(`${BASE_URL}/${incomeCashObj.id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status == 200) {
          dispatch(setConfirm("הפקדת מזומן נוצרה בהצלחה!"));
        }
      } catch (e) {
        dispatch(
          setError(
            "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
            "ארעה שגיאה הטופס לא מולא כמו שצריך"
          )
        );
      } finally {
        dispatch(stopLoading());
      }
    }
  }
);

export const fetchZnumbers = createAsyncThunk(
  "income/incomesCash/getLastZ",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const res = await Axios.get(`${BASE_URL}/getLastZ`, {
      params: {
        branch_id: payload.id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const fetchIncomesCash = createAsyncThunk(
  "income/incomesCash",
  async (
    { checkedBranches, checkedZedOptions, checkedStatusOptions },
    { dispatch, getState }
  ) => {
    const token = getState().auth.token;
    const user = getState().auth.user;
    const filters = getState().incomeCash.filters;
    const res = await Axios.get(`${BASE_URL}`, {
      params: {
        branch_id: checkedBranches,
        zed: checkedZedOptions.length > 0 ? checkedZedOptions : [3],
        status:
          checkedStatusOptions.length > 0 ? checkedStatusOptions : [1, 2, 3],
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const fetchIncome = createAsyncThunk(
  "income/incomeCash",
  async (incomeCashId, { dispatch, getState }) => {
    const token = getState().auth.token;
    const res = await Axios.get(`${BASE_URL}/${incomeCashId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const deleteIncomeCash =
  (payload, checkedBranches) => async (dispatch, getState) => {
    const token = getState().auth.token;
    try {
      dispatch(startLoading());
      const res = await Axios.delete(`${BASE_URL}/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res;
    } catch (e) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
    } finally {
      dispatch(stopLoading());
      dispatch(fetchIncomesCash(checkedBranches));
    }
  };
export const getEmails = createAsyncThunk(
  "incomeCash/getEmails",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const res = await Axios.get(`${BASE_URL}/emails`, {
      params: {
        branch_id: payload.id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);

export const deleteEmail = createAsyncThunk(
  "incomeCash/deleteEmail",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const { selected_branch } = getState().branches;

    const res = await Axios.delete(`${BASE_URL}/emails`, {
      params: {
        branch_id: selected_branch.id,
        email: payload,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);

export const createEmail = createAsyncThunk(
  "incomeCash/createEmail",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const { selected_branch } = getState().branches;
    const res = await Axios.post(
      `${BASE_URL}/emails`,
      {
        branchId: selected_branch.id,
        email: payload,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return data;
  }
);
export const changeSweepingIncomes = createAsyncThunk(
  "incomeCash/changeSweepingIncomes",
  async ({ payload, checkedItems }, { dispatch, getState }) => {
    const token = getState().auth.token;

    const res = await Axios.post(
      `${BASE_URL}/changeSweepingIncomes`,
      {
        incomeCashId: checkedItems,
        status: payload.status.id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return data;
  }
);

export const downloadFile = (payload) => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    dispatch(startLoading("מוריד קובץ..."));
    const res = await Axios.get(`${BASE_URL}/download`, {
      params: {
        incomeCashId: payload,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    let fileData;
    if (res.data) {
      fileData = res.data;
      const downloadLink = document.createElement("a");
      downloadLink.href = fileData;
      downloadLink.download = `הפקדת מזומן ${payload}`;
      downloadLink.click();
    }
    return res;
  } catch (e) {
    dispatch(
      setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const { reducer: incomeCashReducer, actions: IncomeCashActions } =
  incomeCashSlice;
