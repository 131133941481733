import { Link, Tooltip } from "@mui/material";
import {
  CountFooter,
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";

import {
  dynamicToFixed,
  getSafeDivision,
  thousandSeparator,
  numberFormat,
} from "../../../helpers";
import { VAT } from "../../orders/manageOrders/constants";

export const getData = (
  sale_events, //raw data
  total_sales
) => {
  return sale_events.map((sale_event) => {
    const {
      actual_use,
      box_type,
      has_tax,
      inventory,
      manufacturer_number,
      name,
      package_items,
      part_number,
      price,
      scale,
      supplier_name,
      by_scale,
    } = sale_event;

    const item_scale = by_scale ? box_type : scale;
    const total_sales_without_vat = total_sales / VAT;
    const thousandUse =
      getSafeDivision(1000, total_sales_without_vat) * actual_use;

    const fourThousandUse =
      getSafeDivision(4000, total_sales_without_vat) * actual_use;

    return {
      name,
      part_number,
      manufacturer_number,
      supplier_name,
      scale_amount: `${numberFormat(inventory)} ${scale}`,
      package_amount: by_scale
        ? `${numberFormat(package_items)} ${box_type}`
        : "",
      price_with_vat: price * (has_tax ? VAT : 1),
      actual_use: `${numberFormat(actual_use)} ${item_scale}`,
      thousand_use: `${numberFormat(thousandUse)} ${item_scale}`,
      four_thousand_use: `${numberFormat(fourThousandUse)} ${item_scale}`,
    };
  });
};

export const excelRowConfig = (row) => {
  row.price_with_vat = dynamicToFixed(row.price_with_vat);

  return row;
};

export const aggregates = [{ field: "price_with_vat", aggregate: "sum" }];

// export const sortBy = [{ id: "avg_foodcost", desc: true }];

export const columns = [
  {
    Header: "שם",
    accessor: "name",
    Footer: () => "סה״כ",
  },
  {
    Header: "מק״ט",
    accessor: "part_number",
  },
  {
    Header: "ברקוד יצרן",
    accessor: "manufacturer_number",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "כמות",
    accessor: "scale_amount",
  },
  {
    Header: "יחידת מידה במארז",
    accessor: "package_amount",
  },
  {
    Header: "מחיר כולל מע״מ",
    accessor: "price_with_vat",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "כמות שנצרכה",
    accessor: "actual_use",
  },
  {
    Header: () => (
      <Tooltip
        title={
          <span style={{ fontSize: "1.5rem" }}>
            כמות שנצרכה לחלק לשווי מכירה ללא מע״מ כפול 1000
          </span>
        }
        placement="top-start"
      >
        <Link
          component="span"
          sx={{
            fontSize: "1.6rem",
          }}
        >
          צריכה ל-₪1000
        </Link>
      </Tooltip>
    ),
    accessor: "thousand_use",
  },
  {
    Header: () => (
      <Tooltip
        title={
          <span style={{ fontSize: "1.5rem" }}>
            כמות שנצרכה לחלק לשווי מכירה ללא מע״מ כפול 4000
          </span>
        }
        placement="top-start"
      >
        <Link
          component="span"
          sx={{
            fontSize: "1.6rem",
          }}
        >
          צריכה ל-₪4000
        </Link>
      </Tooltip>
    ),
    accessor: "four_thousand_use",
  },
];
