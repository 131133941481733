import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";

// ======== ICONS ========

// ======== CONSTANTS ========
import {
  requiredFieldError,
  emailValidation,
} from "../../private/services/validations";

// ======== COMPONENTS ========
import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import FormCB from "../../../components/Forms/inputs/FormCB";
import InputAdornment from "@mui/material/InputAdornment";

const PermanentInputForm = () => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const { control } = useFormContext();
  // ======== STATES ========

  const [showPassword, setShowPassword] = useState(false);

  // ======== HANDLERS ========
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const ShowHidePass = ({ showPassword, type, handleClickShowPassword }) => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label={`toggle ${type} visibility`}
          onClick={handleClickShowPassword}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <FormTextField
          fullWidth
          {...{
            control,
            label: "שם משתמש",
            name: "email",
            rules: {
              required: requiredFieldError,
              validate: (value) => emailValidation(value),
            },
          }}
        />
      </Grid>
      <Grid item>
        <FormTextField
          fullWidth
          InputProps={{
            endAdornment: (
              <ShowHidePass
                type="password"
                password={showPassword}
                handleClickShowPassword={handleClickShowPassword}
              />
            ),
          }}
          {...{
            control,
            label: "סיסמה",
            name: "password",
            type: showPassword ? "text" : "password",
            id: "password",
            rules: {
              required: requiredFieldError,
            },
          }}
        />
      </Grid>
      <Grid item container sx={{ justifyContent: "space-around" }}>
        <Grid item>
          <FormCB
            {...{
              control,
              name: "remember_me",
              label: "זכור אותי",
            }}
          />
        </Grid>
        <Grid item>
          <Button component={Link} to="/users/restore">
            שחזור סיסמה
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PermanentInputForm;
