import React from "react";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
  ExcelExportFooter,
} from "@progress/kendo-react-excel-export";
import { RiFileExcel2Line } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";
import { RiFileExcel2Fill } from "react-icons/ri";

import { I18n } from "react-redux-i18n";
import { getSafe } from "../../../helpers";
import BtnTooltip from "../../tooltips/BtnTooltip";
import { IconButton } from "@mui/material";

class CustomFooter extends ExcelExportFooter {
  render() {
    const { title, aggregates, data, footerConfig } = this.props;
    let res = 0;
    if (footerConfig[title]) {
      return footerConfig[title](title, data);
    }
    if (getSafe(() => aggregates[title].count)) {
      return aggregates[title].count;
    } else if (aggregates[title].average) {
      res = Number(getSafe(() => aggregates[title].average.toFixed(2), 0));
      if (isNaN(res)) {
        return 0;
      }

      return res;
    } else {
      res = Number(getSafe(() => aggregates[title].sum.toFixed(2), 0));
      if (isNaN(res)) {
        return 0;
      }

      return res;
    }
  }
}

const CustomGroupHeader = (props) => `${props.value}`;
const CustomGroupFooter = (props) => {
  if (getSafe(() => props.aggregates[props.field].count)) {
    return props.aggregates[props.field].count;
  } else {
    const res = Number(
      getSafe(() => props.aggregates[props.field].sum.toFixed(2), 0)
    );
    if (isNaN(res)) {
      return 0;
    }
    return res;
  }
};

class DownloadExcel extends React.Component {
  _exporter;
  export = () => {
    this.save(this._exporter);
  };

  save = (component) => {
    const options = component.workbookOptions();
    const rows = options.sheets[0].rows;

    rows.splice(2, 1);
    if (rows[rows.length - 1] && rows[rows.length - 1].type === "footer") {
      rows.splice(rows.length - 2, 1);
    }

    rows.forEach((row) => {
      if (row.type === "data") {
        row.cells.forEach((cell, index) => {
          cell.background = "#fff";
          if (cell.value !== undefined) {
            cell.borderBottom = "#";
            cell.borderTop = "#";
            cell.borderLeft = "#";
            cell.borderRight = "#";
            cell.wrap = true;
          } else {
            cell.borderBottom = "#";
            cell.borderTop = "#";
          }
          if (row.cells[1].value === 'סה"כ') {
            cell.background = "#e2e2e2";
            cell.borderBottom = "#838383";
            cell.borderTop = "#838383";
            cell.borderLeft = "#838383";
            cell.borderRight = "#838383";
          }
        });
      }
      if (row.type === "header") {
        row.cells.forEach((cell, index) => {
          if (row.cells.length === 2 || row.cells.length === 3) {
            cell.background = "#fff";
            cell.fontSize = 18;
            cell.color = "#000";
            cell.bold = true;
            cell.borderBottom = "#";
            cell.borderTop = "#";
          } else {
            cell.background = "#0090d0";
          }
        });
      }
      if (row.type === "footer") {
        row.cells[0].background = "#0090d0";
        row.cells[1].background = "#0090d0";
      }
      if (row.type === "group-footer") {
        row.cells.forEach((cell, index) => {
          cell.background = "#838383";
        });
      }
    });

    component.save(options);
  };

  render() {
    const {
      group = null,
      fileName,
      data,
      columns,
      aggregates,
      is_sub,
      style = {},
      customFooterTitle,
      translation_endpoint = "",
      footerConfig = {},
      customBtnToolTipText = "",
    } = this.props;
    let once = true;

    const getTitle = () => {
      if (customFooterTitle && once) {
        once = false;
        return true;
      }
      return false;
    };

    return (
      <div style={style}>
        <BtnTooltip
          placement="top"
          text={
            customBtnToolTipText
              ? customBtnToolTipText
              : is_sub
              ? "ייצא לאקסל מפורט"
              : "ייצא לאקסל"
          }
        >
          <IconButton style={{ color: "#1D6F42" }} onClick={this.export}>
            {customBtnToolTipText ? (
              <RiFileExcel2Fill size="1.3em" />
            ) : is_sub ? (
              <SiMicrosoftexcel size="1.3em" />
            ) : (
              <RiFileExcel2Line size="1.3em" />
            )}
          </IconButton>
        </BtnTooltip>
        <ExcelExport
          dir="rtl"
          data={data}
          fileName={fileName}
          group={group}
          ref={(exporter) => {
            this._exporter = exporter;
          }}
        >
          <ExcelExportColumnGroup
            title={fileName}
            headerCellOptions={{ textAlign: "center" }}
          >
            {columns
              .map((col, col_idx) => {
                if (
                  [
                    "action",
                    "title_0",
                    "title_1",
                    "selection",
                    "selection_placeholder_0",
                    "expander",
                  ].includes(col.id) ||
                  col.disable_export_excel
                ) {
                  return null;
                } else if (aggregates[col.id]) {
                  return (
                    <ExcelExportColumn
                      key={col_idx}
                      field={col.id}
                      title={`${I18n.t(
                        `excel${
                          translation_endpoint
                            ? `.${translation_endpoint}.`
                            : "."
                        }${col.id}`
                      )}`}
                      cellOptions={{ textAlign: "center" }}
                      headerCellOptions={{
                        bold: true,
                        color: "#fff",
                        fontSize: "1.4rem",
                        textAlign: "center",
                        background: "#0090d0",
                        wrap: true,
                      }}
                      footer={() => (
                        <CustomFooter
                          title={col.id}
                          data={data[0].items}
                          {...{ footerConfig, aggregates }}
                        />
                      )}
                      footerCellOptions={{
                        bold: true,
                        color: "#fff",
                        fontSize: "1.4rem",
                        textAlign: "center",
                        background: "#0090d0",
                        wrap: true,
                      }}
                      groupHeaderCellOptions={{
                        textAlign: "center",
                        bold: true,
                        fontSize: "1.4rem",
                      }}
                      groupFooterCellOptions={{
                        textAlign: "center",
                        bold: true,
                        color: "#fff",
                        fontSize: "1.4rem",
                      }}
                      groupHeader={CustomGroupHeader}
                      groupFooter={CustomGroupFooter}
                    />
                  );
                }
                return (
                  <ExcelExportColumn
                    key={col_idx}
                    field={col.id}
                    title={`${I18n.t(
                      `excel${
                        translation_endpoint ? `.${translation_endpoint}.` : "."
                      }${col.id ? col.id : col.id}`
                    )}`}
                    headerCellOptions={{
                      bold: true,
                      color: "#fff",
                      fontSize: "1.4rem",
                      textAlign: "center",
                      background: "#0090d0",
                      wrap: true,
                    }}
                    cellOptions={{ textAlign: "center" }}
                    groupHeaderCellOptions={{
                      textAlign: "center",
                      bold: true,
                      fontSize: "1.4rem",
                    }}
                    groupFooterCellOptions={{
                      textAlign: "center",
                      bold: true,
                      color: "#fff",
                      fontSize: "1.4rem",
                    }}
                    groupHeader={CustomGroupHeader}
                    footerCellOptions={{
                      bold: true,
                      color: "#fff",
                      fontSize: "1.4rem",
                      textAlign: "center",
                      background: "#0090d0",
                      wrap: true,
                      value: getTitle() ? customFooterTitle : "",
                    }}
                  />
                );
              })
              .filter((x) => x)}
          </ExcelExportColumnGroup>

          <ExcelExportColumn
            hidden
            field="file_title"
            width={100}
            title="file_title"
            headerCellOptions={{ textAlign: "center" }}
            cellOptions={{ textAlign: "center" }}
            groupHeaderCellOptions={{ textAlign: "center" }}
            groupHeader={() => " "}
          />
        </ExcelExport>
      </div>
    );
  }
}

export default DownloadExcel;
