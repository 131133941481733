import React, { Fragment, useRef } from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  TableFooter,
  Collapse,
  Paper,
  TableContainer,
} from "@mui/material";
import { getSafe } from "../../../../helpers";
import ButtonIcon from "../../../../components/buttons/ButtonIcon";
import { AiOutlinePlus } from "react-icons/ai";

const SimpleTable = ({
  columns,
  data,
  cellSize = "small",
  footerCells = [],
  alignCenter = false,
  expand,
  propsToCell = () => {},
  expandedRows = [],
  expandKey,
  expandRowRender,
  emptyMessage = "לא נמצאו רשומות מתאימות",
  tableClassName = "",
  newLineButton = false,
  newLineAlt = "",
  newLineFunc = () => {},
  direction = "rtl",
}) => {
  const containerRef = useRef();

  return (
    <>
      {newLineButton && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
          }}
        >
          <ButtonIcon
            icon={AiOutlinePlus}
            iconSize="20"
            onClick={newLineFunc}
            alt={newLineAlt}
            style={{
              margin: "0",
              backgroundColor: "#ffffff",
              color: "#00a2ea",
            }}
          />
        </div>
      )}
      <TableContainer
        ref={containerRef}
        style={{ direction: direction, position: "relative" }}
        component={Paper}
      >
        <Table
          className={`simple-table ${tableClassName} ${
            alignCenter ? "simple-table-align-center" : ""
          }`}
        >
          <TableHead>
            <TableRow>
              {columns?.map((column) => {
                if (!column.hidden) {
                  if (column.width) {
                    return (
                      <TableCell
                        size={cellSize}
                        width={column.width}
                        key={column.name}
                      >
                        {column.title}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell size={cellSize} key={column.name}>
                        {column.title}
                      </TableCell>
                    );
                  }
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(!data || !data.length) && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Typography align="center">{emptyMessage}</Typography>
                </TableCell>
              </TableRow>
            )}
            {data?.map((row, index) => {
              return (
                <Fragment key={`${row.id}${index}`}>
                  <TableRow
                    className={getSafe(() => row.className, "")}
                    key={JSON.stringify(row)}
                  >
                    {columns.map((column) => {
                      if (!column.hidden) {
                        if (column.width) {
                          return (
                            <TableCell
                              size={cellSize}
                              width={column.width}
                              key={JSON.stringify([column.name, row])}
                            >
                              {column.render(
                                row[column.name],
                                row,
                                index,
                                propsToCell()
                              )}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              size={cellSize}
                              key={JSON.stringify([column.name, row])}
                            >
                              {column.render(
                                row[column.name],
                                row,
                                index,
                                propsToCell()
                              )}
                            </TableCell>
                          );
                        }
                      }
                    })}
                  </TableRow>
                  {expand && row.expendRows.length > 1 && (
                    <TableRow key={`exp${JSON.stringify(row)}`}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={columns.length}
                      >
                        <Collapse in={expandedRows.includes(row[expandKey])}>
                          {expandRowRender(row.expendRows)}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
          {footerCells.length > 0 && (
            <TableFooter>
              <TableRow>
                {footerCells.map((cell) => {
                  return (
                    <TableCell key={cell.name} colSpan={cell.colSpan}>
                      {cell.data}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default SimpleTable;
