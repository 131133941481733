import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import { default as Grid } from "@mui/material/Unstable_Grid2";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";
import { getSafeDivision } from "../../helpers";
import { Pie } from "../orders_analysis/graphs/Pie";
import { GRAPHS_MAX_RECORDS } from "./const";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const Graphs = ({ data }) => {
  // ======== HOOKS ========

  const { by_code } = data;

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========
  const topDishesByPrice = useMemo(() => {
    const topTotals = by_code
      .sort((a, b) => b.total - a.total)
      .slice(0, GRAPHS_MAX_RECORDS);

    const total = parseInt(
      topTotals.reduce((sum, topTotal) => sum + topTotal.total, 0)
    );

    const pieItems = topTotals.map((topTotal) => ({
      id: topTotal.product_code,
      label: topTotal.name,
      value: parseInt(topTotal.total),
      percent: getSafeDivision(topTotal.total, total) * 100,
    }));

    return {
      data: pieItems,
      isPrice: true,
      total,
      title: "המנות הכי נמכרות (מחיר)",
    };
  }, [data]);

  const topDishesByAmount = useMemo(() => {
    const topAmounts = by_code
      .sort((a, b) => b.amount - a.amount)
      .slice(0, GRAPHS_MAX_RECORDS);

    const totalAmounts = topAmounts.reduce(
      (sum, topAmount) => sum + topAmount.amount,
      0
    );

    const pieItems = topAmounts.map((topAmount) => ({
      id: topAmount.product_code,
      label: topAmount.name,
      value: topAmount.amount,
      percent: getSafeDivision(topAmount.amount, totalAmounts) * 100,
    }));

    return {
      data: pieItems,
      totalAmounts,
      title: "המנות הכי נמכרות (כמות)",
    };
  }, [data]);

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Grid
      container
      spacing={2}
      sx={{
        margin: "20px",
      }}
    >
      <Grid md={12} lg={6}>
        <Pie {...topDishesByAmount} />
      </Grid>
      <Grid md={12} lg={6}>
        <Pie {...topDishesByPrice} />
      </Grid>
    </Grid>
  );
};

export default Graphs;
