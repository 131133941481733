import { startOfMonth } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import BackgroundCard from "../../components/card/BackgroundCard";
import MuiTabs from "../../components/menu/MuiTabs";
import {
  setActive,
  setIsFrame,
  setTitle,
} from "../../redux/actions/publicAction";

import { Divider } from "@mui/material";
import { dateSubtract } from "../../helpers";
import { logout } from "../../redux/actions/loginAction";

import { useScreenSize } from "../../Hooks";
import Dashboard from "./Dashboard";
import ReportsEmployees from "../reports/employees/ReportsEmployees";
const TAB_OPTION_REPORTS = 0;
const TAB_OPTION_DASHBOARD = 1;

const ExpenseViewSwitch = ({
  name,
  title,
  setActive,
  setTitle,
  setIsFrame,
  selected_branch,
  token,
  logout,
}) => {
  const { isMobile } = useScreenSize();
  useEffect(() => {
    setActive(name);
    setTitle(title);
    setIsFrame(false);
  }, []);

  const [selectedTab, setSelectedTab] = useState(TAB_OPTION_DASHBOARD);
  const [selectedDates, setSelectedDates] = useState({
    startDate: startOfMonth(new Date()),
    endDate: new Date(dateSubtract()),
    key: "selection",
    label: "החודש",
  });

  const tabOptions = useMemo(() => {
    let options = [
      { id: TAB_OPTION_REPORTS, label: "דוחות" },
      { id: TAB_OPTION_DASHBOARD, label: "דשבורד" },
    ];
    return options;
  }, [isMobile]);

  const renderTab = () => {
    switch (selectedTab) {
      case TAB_OPTION_DASHBOARD:
        return (
          <Dashboard
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
          />
        );
      case TAB_OPTION_REPORTS:
        return (
          <ReportsEmployees
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <BackgroundCard style={{ paddingBottom: "50rem" }}>
      <MuiTabs
        defaultValue={TAB_OPTION_DASHBOARD}
        options={tabOptions}
        onChange={(option) => {
          setSelectedTab(option.id);
        }}
      />
      <Divider />
      {renderTab()}
    </BackgroundCard>
  );
};

const mapStateToProps = (state) => {
  const { selected_branch } = state.branches;
  const { token } = state.auth;
  const { isMobile } = state.publicValues;
  return { selected_branch, token, isMobile };
};
export default connect(mapStateToProps, {
  setActive,
  setTitle,
  setIsFrame,
  logout,
})(ExpenseViewSwitch);
