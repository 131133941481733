import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Button from "../components/buttons/Button";
import Modal from "react-responsive-modal";
import { restoreOTP } from "../redux/actions/loginAction";
import { reset } from "redux-form";
import { SET_ERROR } from "../redux/actions/errorAction/types";

class PasswordRestore extends React.Component {
  renderError = ({ error, touched }) => {
    if (touched && error)
      return (
        <p>
          * <span className="error-msg">{error}</span>{" "}
        </p>
      );
  };

  //called in <Field>(redux-form tag) and render the input by the props
  //input is a field in the <Field> tag that handle the input behind the scenes
  renderInput = ({ type, placeholder, input, meta, className, ...rest }) => {
    return (
      <div className={className}>
        <input type={type} placeholder={placeholder} {...input} {...rest} />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    const { login_user_web_id } = this.props;
    this.props.restoreOTP(login_user_web_id, formValues);
  };

  render() {
    const {
      phone_number,
      identifier,
      email,
      attemps,
      setOTPModal,
      dispatch,
      open,
      restorePassword,
      reset,
      setLoader,
    } = this.props;

    return (
      // <Modal
      //   styles={{
      //     modal: { padding: "3rem", maxWidth: "40rem", textAlign: "center" },
      //   }}
      //   open={open}
      //   center
      //   closeOnOverlayClick={false}
      //   animationDuration={0}
      //   onClose={() => {
      //     setOTPModal(null);
      //     reset("restore_password_user");
      //   }}
      // >
      <div style={{ textAlign: "right" }}>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <h1>מערכת רסטיגו</h1>
          <h2>הזן קוד אימות</h2>
          {email ? (
            <p style={{ display: "inline-block" }}>
              קוד אימות נשלח כעת למייל שלך
            </p>
          ) : (
            <></>
          )}
          {phone_number ? (
            <>
              {" "}
              <p style={{ display: "inline-block" }}>
                קוד אימות נשלח כעת לסלולרי שלך שמספרו
              </p>
              <p style={{ direction: "ltr", display: "inline-block" }}>
                {phone_number}
              </p>
            </>
          ) : (
            <></>
          )}
          <p>אנא הקלד את הקוד ולחץ על כניסה</p>
          <label>
            קוד אימות:
            <Field
              maxLength="6"
              name="code"
              type="text"
              icon="lock"
              className="login__card--input"
              placeholder=""
              autoComplete="off"
              autoCorrect="off"
              spellCheck="off"
              component={this.renderInput}
            />
          </label>
          {attemps >= 3 && (
            <label
              style={{ color: "red" }}
            >{`בוצעו ${attemps} נסיונות נותרו עוד כ -  ${
              5 - attemps
            } נסיונות לפני שהמשתמש ייחסם`}</label>
          )}
          <p>
            לא קיבלת את הקוד? לקבלת הקוד פעם נוספת
            <Button
              onClick={() => {
                if (attemps <= 5) {
                  restorePassword(
                    { identifier, email, phone_number },
                    setOTPModal,
                    setLoader
                  );
                } else {
                  dispatch({
                    type: SET_ERROR,
                    errorTitle: `שגיאת אבטחה`,
                    error:
                      "חשבונך נחסם עקב ריבוי נסיונות נסה שוב מאוחר יותר. \r\n ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
                  });
                }
              }}
              className="btn--hyperlink"
              textValue="לחץ כאן"
            />
          </p>
          <div
            className={`slide ${
              this.props.code
                ? this.props.code.length === 6
                  ? "slide--expand"
                  : ""
                : ""
            }`}
          >
            {" "}
            <h2>הגדר סיסמא</h2>
            <Field
              name="password"
              type="password"
              icon="lock"
              className="login__card--input"
              placeholder="הגדר סיסמא חדשה"
              component={this.renderInput}
            />
            <Field
              name="password_second"
              type="password"
              icon="lock"
              className="login__card--input"
              placeholder="וידוא סיסמא"
              component={this.renderInput}
            />
            <Button type="submit" className="btn--submit" textValue="כניסה" />
          </div>
        </form>
      </div>
      // </Modal>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  //   const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,10}$/;
  if (!formValues.password) errors.password = "נדרש להגדיר סיסמא";
  else if (formValues.password.length < 8 || formValues.password.length > 10)
    errors.password = "סיסמא חייבת להכיל 8-10 תוים";

  //   else if (!formValues.password.match(decimal))
  //     errors.password =
  //       "סיסמא חייבת להכיל לפחות פעם אחת: אות גדולה, אות קטנה, מספר, וסימן מיוחד($%&..) באורך של 8-10 תוים";

  if (!formValues.password_second) errors.password_second = "וודא סיסמא";
  else if (!formValues.password)
    errors.password_second = "הגדר סיסמא בשדה הראשון";
  else if (formValues.password_second !== formValues.password)
    errors.password_second = "סיסמא לא תואמת";

  if (!formValues.code) errors.code = "נדרש להכניס קוד אימות";
  else if (formValues.code.length !== 6) errors.code = "הכנס 6 ספרות";

  return errors;
};

const selector = formValueSelector("restore_password_user");

const mapStateToProps = (state) => {
  const code = selector(state, "code");
  return { locale: state.i18n.locale, code };
};

const formWrapped = reduxForm({
  form: "restore_password_user",
  enableReinitialize: true,
  validate,
})(PasswordRestore);

export default connect(mapStateToProps, { restoreOTP, reset })(formWrapped);
