import { SET_SNACK_BAR, HIDE_SNACK_BAR } from "../actions/confirmAction/types";

const INITIAL_STATE = {
  isOpen: false,
  confirmMsg: "",
  severity: true,
  direction: "left",
  position: "BOTTOM-CENTER",
  hide: 4000,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  // const { confirmMsg, severity, direction, position, hide } = payload;

  // console.log("confirmMsg", confirmMsg);

  switch (type) {
    case SET_SNACK_BAR:
      return {
        ...payload,
        isOpen: true,
      };
    case HIDE_SNACK_BAR:
      return INITIAL_STATE;
    default:
      return state;
  }
};
