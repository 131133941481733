import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { addWeeks, endOfWeek, format, startOfWeek } from "date-fns";
import { mapKeys } from "lodash";
import { arrangeData } from "../../pages/NewHomePage/helpersNewHomePage";

const BASE_URL = "v2/expenseSummary";
const currentDate = new Date();
const INITIAL_STATE = {
  filters: {
    selectedDates: {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 1
      ),
      key: "selection",
      label: "החודש",
    },
    branch_id: null,
  },
  selectedGoal: {},
  goalsList: {},
  isLoading: true,
  data: {
    total: 0,
    totalDiners: 1,
    dateBreakdown: {},
    elementsBreakdown: {},
    channelsBreakdown: {},
    shfitsBreakdown: {},
    goals: {},
  },
  incomesData: {
    extras: {},
    fees: {},
    incomes: {},
    last_year_income: 0,
  },
  emails: [],
  incomeSettings: { paymentTypes: {}, channelTypes: {}, shiftTypes: {} },
  bulletsData: {},
  operationalExpense: {},
  managementExpense: {},
  fundingExpense: {},
  laborExpense: {},
};
export const fetchIncomeSettings = createAsyncThunk(
  "income/settingsv2",
  async (payload, { dispatch, getState }) => {
    const filters = getState().homePage.filters;
    const token = getState().auth.token;
    const res = await Axios.get(`income/settingsv2`, {
      params: {
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const getEmails = createAsyncThunk(
  "expenseSummary/getEmails",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.get(`${BASE_URL}/getEmails`, {
      params: {
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);
export const deleteEmail = createAsyncThunk(
  "expenseSummary/deleteEmail",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.delete(`${BASE_URL}/deleteEmail`, {
      params: {
        branch_id: filters.branch_id.id ?? payload.branch_id,
        email: payload,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);

export const createEmail = createAsyncThunk(
  "expenseSummary/createEmail",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.post(
      `${BASE_URL}/createEmail`,
      {
        branchId: filters.branch_id.id ?? payload.branch_id,
        email: payload,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return data;
  }
);

export const fetchExpenseData = createAsyncThunk(
  "expenseSummary/fetchExpenseData",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.get(BASE_URL, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);

export const fetchExpenseOperationalData = createAsyncThunk(
  "expenseSummary/operational",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.get(`${BASE_URL}/operational`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
        expense_type: 2,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);
export const fetchExpenseManagementData = createAsyncThunk(
  "expenseSummary/management",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.get(`${BASE_URL}/management`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);
export const fetchExpenseFundingData = createAsyncThunk(
  "expenseSummary/funding",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.get(`${BASE_URL}/funding`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);
export const fetchLaborCostData = createAsyncThunk(
  "expenseSummary/laborCost",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.get(`${BASE_URL}/laborCost`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branch_id: filters.branch_id.id ?? payload.branch_id,
        section: "LaborCost",
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);

export const fetchIncomeData = createAsyncThunk(
  "income/fetchIncomeData",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.get(`income/v2`, {
      params: {
        branch_id: filters.branch_id.id ?? payload.branch_id,
        startDate: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        endDate: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const fetchExpenseGoals = createAsyncThunk(
  "income/fetchExpenseGoals",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.get(`expenseGoals/getGoals`, {
      params: {
        branch_ids: [filters.branch_id.id ?? payload.branch_id],
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const updateExpenseGoals = createAsyncThunk(
  "income/updateExpenseGoals",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.post(
      `expenseGoals/updateGoal`,
      {
        branchId: filters.branch_id.id ?? payload.branch_id,
        name: payload.name,
        display_type: payload.display_type,
        goal: payload.goal,
        id: payload.id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return data;
  }
);
export const createExpenseGoal = createAsyncThunk(
  "income/createExpenseGoal",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().homePage.filters;
    const res = await Axios.post(
      `expenseGoals/storeGoal`,
      {
        branchId: filters.branch_id.id ?? payload.branch_id,
        name: payload.name,
        display_type: payload.display_type,
        goal: payload.goal,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = await res.data;
    return data;
  }
);

export const deleteExpenseGoal = createAsyncThunk(
  "income/deleteExpenseGoal",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const res = await Axios.delete(`expenseGoals/${payload}/deleteGoal`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const fetchAllExpenses = createAsyncThunk(
  "expenseSummary/fetchAllExpenses",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;
      const filters = getState().homePage.filters;

      const fetchLaborCostData =  Axios.get(`${BASE_URL}/laborCost`, {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id,
          section: "LaborCost",
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      // Create two Axios requests
      const fetchFoodCost = Axios.get(`${BASE_URL}/foodcost`, {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      const fetchOperational = Axios.get(`${BASE_URL}/operational`, {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id,
          expense_type: 2,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      const fetchManagementData =  Axios.get(`${BASE_URL}/management`, {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      const fetchFundingData =  Axios.get(`${BASE_URL}/funding`, {
        params: {
          start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
          branch_id: filters.branch_id.id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      const fetchIncomesData =  Axios.get(`income/v2`, {
        params: {
          branch_id: filters.branch_id.id,
          startDate: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
          endDate: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      const [
        laborCostResponse,
        foodCostResponse,
        operationalResponse,
        managementResponse,
        fundingResponse,
        incomesResponse,
      ] = await Axios.all([
        fetchLaborCostData,
        fetchFoodCost,
        fetchOperational,
        fetchManagementData,
        fetchFundingData,
        fetchIncomesData,
      ]);
      const foodCostData = foodCostResponse.data;
      const operationalData = operationalResponse.data;
      const managementData = managementResponse.data;
      const fundingData = fundingResponse.data;
      const laborCostData = laborCostResponse.data;
      const incomesData = incomesResponse.data;

      // Return an object with all the data
      return {
        foodCostData,
        operationalData,
        managementData,
        fundingData,
        laborCostData,
        incomesData,
      };
    } catch (error) {
      // Handle errors if needed
      throw error;
    }
  }
);

const homePageSlice = createSlice({
  name: "homePage",
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
    setGoalsList: (state, action) => {
      state.goalsList = action.payload;
    },
    removeGoal: (state, action) => {
      const idToRemove = action.payload;
      state.goalsList = state.goalsList.filter(
        (goal) => +goal.id !== idToRemove
      );
    },
    updateGoal: (state, action) => {
      const { name, goal } = action.payload;
      // Find the goal to update
      const goalToUpdate = state.goalsList.find(
        (eachGoal) => eachGoal.name === name
      );

      if (goalToUpdate) {
        if (goalToUpdate.display_type == "1" && goal > 55) {
          goalToUpdate.goal = 55;
        } else {
          goalToUpdate.goal = goal;
        }
      }
    },
    updateGoalThatExists: (state, action) => {
      const { id, goal } = action.payload;
      // Find the goal to update
      const goalToUpdate = state.goalsList.find(
        (eachGoal) => +eachGoal.id === id
      );

      if (goalToUpdate) {
        // Update the 'goal' property with the new value
        if (goalToUpdate.display_type == "1" && goal > 55) {
          goalToUpdate.goal = 55;
        } else {
          goalToUpdate.goal = goal;
        }
      }
    },
    updateDisplayType: (state, action) => {
      const { name, display_type } = action.payload;
      const goalToUpdate = state.goalsList.find((goal) => goal.name == name);
      if (goalToUpdate) {
        goalToUpdate.display_type = display_type;
        if (goalToUpdate.goal > 55) {
          goalToUpdate.goal = 55;
        }
      }
    },
    updateDisplayTypeThatExists: (state, action) => {
      const { id, display_type } = action.payload;
      const goalToUpdate = state.goalsList.find((goal) => goal.id == id);
      if (goalToUpdate) {
        goalToUpdate.display_type = display_type;
        if (goalToUpdate.goal > 55) {
          goalToUpdate.goal = 55;
        }
      }
    },
    toggleEditMode: (state, action) => {
      const id = action.payload;
      const goalToUpdate = state.goalsList.find((goal) => +goal.id === id);
      if (goalToUpdate) {
        goalToUpdate.editMode = !goalToUpdate.editMode;
      }
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchExpenseData.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchExpenseData.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.bulletsData = action.payload;
    // });
    // builder.addCase(fetchExpenseData.rejected, (state, action) => {
    //   state.isLoading = false;
    // });

    builder.addCase(getEmails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEmails.fulfilled, (state, action) => {
      // state.isLoading = false;
      state.emails = action.payload;
    });
    builder.addCase(getEmails.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emails = action.payload;
    });
    builder.addCase(deleteEmail.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emails = action.payload;
    });
    builder.addCase(createEmail.rejected, (state, action) => {
      state.isLoading = false;
    });

    // builder.addCase(fetchExpenseOperationalData.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchExpenseOperationalData.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.operationalExpense = action.payload;
    // });
    // builder.addCase(fetchExpenseOperationalData.rejected, (state, action) => {
    //   state.isLoading = false;
    // });

    // builder.addCase(fetchExpenseManagementData.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchExpenseManagementData.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.managementExpense = action.payload;
    // });
    // builder.addCase(fetchExpenseManagementData.rejected, (state, action) => {
    //   state.isLoading = false;
    // });

    // builder.addCase(fetchExpenseFundingData.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchExpenseFundingData.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.fundingExpense = action.payload;
    // });
    // builder.addCase(fetchExpenseFundingData.rejected, (state, action) => {
    //   state.isLoading = false;
    // });

    // builder.addCase(fetchLaborCostData.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchLaborCostData.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.laborExpense = action.payload;
    // });
    // builder.addCase(fetchLaborCostData.rejected, (state, action) => {
    //   state.isLoading = false;
    // });

    // builder.addCase(fetchIncomeData.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(fetchIncomeData.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.incomesData = action.payload;
    //   const {
    //     total,
    //     totalDiners,
    //     dateBreakdown,
    //     elementsBreakdown,
    //     channelsBreakdown,
    //     shfitsBreakdown,
    //     goals,
    //   } = arrangeData(
    //     action.payload.incomes,
    //     action.payload.extras,
    //     action.payload.fees,
    //     action.payload.goalsData,
    //     state.filters.selectedDates,
    //     state.incomeSettings
    //   );
    //   state.data = {
    //     total,
    //     totalDiners,
    //     dateBreakdown,
    //     elementsBreakdown,
    //     channelsBreakdown,
    //     shfitsBreakdown,
    //     goals,
    //   };
    // });

    // builder.addCase(fetchIncomeData.rejected, (state, action) => {
    //   state.isLoading = false;
    // });
    builder.addCase(fetchExpenseGoals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpenseGoals.fulfilled, (state, action) => {
      // state.isLoading = false;
      state.goalsList = action.payload;
    });
    builder.addCase(fetchExpenseGoals.rejected, (state) => {
      // state.isLoading = false;
    });

    builder.addCase(fetchIncomeSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIncomeSettings.fulfilled, (state, action) => {
      // state.isLoading = false;
      const data = action.payload;
      state.incomeSettings = {
        paymentTypes: mapKeys(data.paymentTypes, "original_type"),
        channelTypes: mapKeys(data.channelTypes, "original_type"),
        shiftTypes: mapKeys(data.shiftTypes, "original_type"),
      };
    });
    builder.addCase(fetchIncomeSettings.rejected, (state) => {
      // state.isLoading = false;
    });

    builder.addCase(fetchAllExpenses.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAllExpenses.fulfilled, (state, action) => {
      // Update state with different types of expense data
      state.bulletsData = action.payload.foodCostData.bullets_data;
      state.operationalExpense = action.payload.operationalData;
      state.managementExpense = action.payload.managementData;
      state.fundingExpense = action.payload.fundingData;
      state.laborExpense = action.payload.laborCostData;
      state.incomesData = action.payload.incomesData;

      // Process additional data if needed
      const {
        total,
        totalDiners,
        dateBreakdown,
        elementsBreakdown,
        channelsBreakdown,
        shfitsBreakdown,
        goals,
      } = arrangeData(
        action.payload.incomesData.incomes,
        action.payload.incomesData.extras,
        action.payload.incomesData.fees,
        action.payload.incomesData.goalsData,
        state.filters.selectedDates,
        state.incomeSettings
      );
      state.data = {
        total,
        totalDiners,
        dateBreakdown,
        elementsBreakdown,
        channelsBreakdown,
        shfitsBreakdown,
        goals,
      };
      state.isLoading = false;
    });

    builder.addCase(fetchAllExpenses.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { reducer: homePageReducer, actions: homePageActions } =
  homePageSlice;
