import Axios from "axios";
import { isArray, isFunction } from "lodash";
import React, { useCallback, useEffect, useState, createContext } from "react";
import { useFormContext } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { REQUIRED_MESSAGE } from "../../components/Forms/constants";
import FormDialog from "../../components/Forms/FormDialog";
import FormCheckBox from "../../components/Forms/inputs/FormCheckBox";
import { setError } from "../../redux/actions/errorAction";
import {
  TYPE_ACCESSIBILITY,
  TYPE_CLOTHES,
  TYPE_CUT_CONVERSATION,
  TYPE_EQUIPMENT,
  TYPE_PROCEDURE,
  TYPE_SALARY_CONVERSATION,
  TYPE_SEXUAL_HARRASMENT,
  TYPE_WORK_AGREEMENT,
  TYPE_SUMMONS_FORM,
  TYPE_TERMINATION_FORM,
  TYPE_CLOTHING_MANAGER_FORM,
  TYPE_SUMMONS_FORM_NEW,
  TYPE_TERMINATION_FORM_NEW,
  TYPE_PROTOCOL_TERMINATION,
  TYPE_RETURN_CLOTHING_MANAGER_FORM,
  TYPE_CONFIRMATION_EMPLOYMENT_PERIOD,
  TYPE_RESIGNATION_LETTER,
} from "./consts";
import { startLoading, stopLoading } from "../../redux/actions/loaderAction";
import { setConfirm, setSnackBar } from "../../redux/actions/confirmAction";
import WorkAgreement from "./WorkAgreement/Show";
import ClothManager from "./ClothByManager/Show";
import ClothingFields from "./Clothes/Show";
import { logout } from "../../redux/actions/loginAction";
import { PART_TYPE_IDENTIFER } from "./WorkAgreement/consts";
import { PART_EMPLOYEE_LIST } from "./ClothByManager/const";
import SexualHarrasment from "./SexualHarrasment/Show";
import AccessibilityProcedure from "./Accessibility/Show";
import { useNavigate } from "react-router-dom";
import Equipments from "./Equipments/Show";
import CutConversationShow from "./managerProcedures/Show";
import SummonsForm from "./SummonsFormHearing/Show";
import TerminationForm from "./WorkerTerminationForm/Show";
import SummonsFormNew from "./SummonsFormNew/Show";
import TerminationFormNew from "./WorkTerminationFormNew/Show";
import ProtocolTermination from "./ProtocolTermination/Show";
import ReturnClothingManager from "./ReturnClothByManager/Show";
import ConfirmationEmployeementPeriod from "./ConfirmationEmploymentPeriod/Show";
import ResignationLetter from "./ResignationLetter/Show";

export const Context = createContext();

export const Show = ({
  procedureId,
  token,
  setProcedureId,
  fetchProcedures,
  user,
  selectedBranch,
  setError,
  startLoading,
  stopLoading,
  setConfirm,
  logout,
}) => {
  const [procedure, setProcedure] = useState(null);
  const [multiple, setMultiple] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setMultiple([TYPE_CLOTHES, TYPE_EQUIPMENT].includes(procedure?.type));
  }, [procedure?.type]);

  useEffect(() => {
    if (!procedureId) {
      setProcedure(null);
      return;
    }
    const fetchProcedure = async () => {
      try {
        const { data: procedure } = await Axios.get(
          `procedures/${procedureId}`,

          {
            params: { extraData: ["parts", "authSigned"], procedureId },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setProcedure(procedure);
      } catch (error) {
        setError();
      }
    };
    fetchProcedure();
  }, [procedureId, setError, token]);

  const close = useCallback(
    (refresh) => {
      setProcedureId(null);
      isFunction(refresh) && refresh();
    },
    [setProcedureId]
  );

  const onSubmit = useCallback(
    async ({ readed, ...data }) => {
      if (!multiple && procedure?.auth_signed) {
        close();
        return;
      }
      if (
        procedure.procedure_type_id.id === 4 ||
        procedure.procedure_type_id.id === 6
      ) {
        delete data.parts;
        if (procedure.procedure_type_id.id === 4) {
          data.send_emails = selectedBranch.in_charge_sexual_mail
            ? [...data.send_emails, selectedBranch.in_charge_sexual_mail]
            : [...data.send_emails];
        } else if (procedure.procedure_type_id.id === 6) {
          data.send_emails = selectedBranch.in_charge_notification_mail
            ? [...data.send_emails, selectedBranch.in_charge_notification_mail]
            : [...data.send_emails];
        }
        let dataToSend = { data: data };
        try {
          try {
            startLoading();
            const res = await Axios.post(
              `procedures/${procedureId}/set-behaviour-readed`,
              dataToSend,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            if ((res.status = 200)) {
              setConfirm("", "הטופס נשלח בהצלחה");
              close(fetchProcedures);
              navigate("users");
            }
          } catch (error) {
            setError();
          } finally {
            stopLoading();
          }
        } catch (err) {
        } finally {
          return;
        }
      }

      let formData = new FormData();
      let identifier = {};
      const formattedData = data.parts.map((part) => {
        if (isArray(part.value)) {
          part.value = part.value[0];
        }
        return part;
      });

      if (data.identifier) {
        identifier = {
          id: PART_TYPE_IDENTIFER,
          type: PART_TYPE_IDENTIFER,
          value: data.identifier,
        };
      }
      let worker_name = {};
      if (data.label) {
        worker_name = {
          id: PART_EMPLOYEE_LIST,
          value: data.label,
        };
      }
      const dataToSend = {
        readed,
        parts: formattedData,
      };

      if (Object.keys(identifier).length !== 0) {
        dataToSend.identifier = identifier;
      }

      if (Object.keys(worker_name).length !== 0) {
        dataToSend.worker_name = worker_name;
      }

      if (data.employee_id) {
        dataToSend.employee_id = data.employee_id;
      }
      if (procedure.procedure_type_id.id == TYPE_PROTOCOL_TERMINATION) {
        dataToSend.manager_signature = data.manager_signature;
        dataToSend.worker_signature = data.worker_signature;
      }
      if (procedure.procedure_type_id.id === TYPE_RESIGNATION_LETTER) {
        dataToSend.worker_signature = data.worker_signature;
      }

      try {
        startLoading();
        await Axios.post(`procedures/${procedureId}/set-readed`, dataToSend, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setConfirm("", "הטופס נשלח בהצלחה");
        close(fetchProcedures);
      } catch (error) {
        setError();
      } finally {
        stopLoading();
      }
    },
    [
      token,
      procedureId,
      setError,
      fetchProcedures,
      multiple,
      procedure,
      startLoading,
      stopLoading,
      setConfirm,
    ]
  );

  const [DNDIndex, setDNDIndex] = useState([]);
  const [identifierIndex, setIdentifierIndex] = useState([]);

  // TODO: Snack bar
  const uploadFile = async (file) => {
    var formData = new FormData();

    formData.append("file", file);
    formData.append("procedureId", procedure.id);

    try {
      const res = await Axios.post("procedures/files/upload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        dispatch(setSnackBar("הקובץ עלה בהצלחה! "));
      }
    } catch (err) {
      dispatch(
        setSnackBar(
          "הקובץ לא עלה הייתה שגיאה נא ליצור קשר עם שירות הלקוחות! ",
          false
        )
      );

      switch (err?.response?.status) {
        case 401:
          logout();
          break;

        case 422:
          setError(err.message, "התרחשה שגיאה");
          close();
          break;

        default:
          setError("אנא פנה לתמיכה", "התרחשה שגיאה בשרת");
          close();
          break;
      }
    }
  };

  return (
    <FormDialog
      noValidate
      formId="showProcedrue"
      open={!!procedureId}
      navigate={"/users/knowledge/procedures"}
      fullScreen
      title={procedure?.name}
      {...{ close, onSubmit }}
    >
      {{
        fields: (
          <Context.Provider
            value={{
              parts: procedure?.parts,
              logo: procedure?.logo?.preview
                ? procedure?.logo?.preview
                : procedure?.logo
                ? procedure?.logo
                : "",
              user,
              selectedBranch,
              mainSection: procedure?.main_section,
              procedure,
              DNDIndex,
              setDNDIndex,
              identifierIndex,
              setIdentifierIndex,
              uploadFile,
            }}
          >
            {procedure?.procedure_type_id.id === TYPE_WORK_AGREEMENT ||
            procedure?.procedure_type_id.id === TYPE_PROCEDURE ? (
              <WorkAgreement />
            ) : procedure?.procedure_type_id.id === TYPE_CLOTHES ? (
              <ClothingFields />
            ) : procedure?.procedure_type_id.id === TYPE_EQUIPMENT ? (
              <Equipments />
            ) : procedure?.procedure_type_id.id === TYPE_SEXUAL_HARRASMENT ? (
              <SexualHarrasment />
            ) : procedure?.procedure_type_id.id === TYPE_ACCESSIBILITY ? (
              <AccessibilityProcedure />
            ) : procedure?.procedure_type_id.id === TYPE_CUT_CONVERSATION ||
              procedure?.procedure_type_id.id === TYPE_SALARY_CONVERSATION ? (
              <CutConversationShow />
            ) : procedure?.procedure_type_id.id === TYPE_SUMMONS_FORM ||
              procedure?.procedure_type_id.id === TYPE_SUMMONS_FORM ? (
              <SummonsForm />
            ) : procedure?.procedure_type_id.id === TYPE_TERMINATION_FORM ||
              procedure?.procedure_type_id.id === TYPE_TERMINATION_FORM ? (
              <TerminationForm />
            ) : procedure?.procedure_type_id.id ===
                TYPE_CLOTHING_MANAGER_FORM ||
              procedure?.procedure_type_id.id === TYPE_CLOTHING_MANAGER_FORM ? (
              <ClothManager />
            ) : procedure?.procedure_type_id.id === TYPE_SUMMONS_FORM_NEW ||
              procedure?.procedure_type_id.id === TYPE_SUMMONS_FORM_NEW ? (
              <SummonsFormNew />
            ) : procedure?.procedure_type_id.id === TYPE_TERMINATION_FORM_NEW ||
              procedure?.procedure_type_id.id === TYPE_TERMINATION_FORM_NEW ? (
              <TerminationFormNew />
            ) : procedure?.procedure_type_id === TYPE_PROTOCOL_TERMINATION ||
              procedure?.procedure_type_id.id === TYPE_PROTOCOL_TERMINATION ? (
              <ProtocolTermination />
            ) : procedure?.procedure_type_id ===
                TYPE_RETURN_CLOTHING_MANAGER_FORM ||
              procedure?.procedure_type_id.id ===
                TYPE_RETURN_CLOTHING_MANAGER_FORM ? (
              <ReturnClothingManager />
            ) : procedure?.procedure_type_id ===
                TYPE_CONFIRMATION_EMPLOYMENT_PERIOD ||
              procedure?.procedure_type_id.id ===
                TYPE_CONFIRMATION_EMPLOYMENT_PERIOD ? (
              <ConfirmationEmployeementPeriod />
            ) : procedure?.procedure_type_id === TYPE_RESIGNATION_LETTER ||
              procedure?.procedure_type_id.id === TYPE_RESIGNATION_LETTER ? (
              <ResignationLetter />
            ) : null}
          </Context.Provider>
        ),
        extraActions: (
          <ExtraActions readed={procedure?.auth_signed} {...{ multiple }} />
        ),
      }}
    </FormDialog>
  );
};

const ExtraActions = ({ readed, multiple }) => {
  const { control, setValue } = useFormContext();
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    const disabled = multiple ? false : !!readed;
    setValue("readed", disabled);
    setDisabled(disabled);
  }, [multiple, readed]);

  return (
    <FormCheckBox
      {...{ control, disabled }}
      label="אני מאשר/ת"
      name="readed"
      rules={{ required: REQUIRED_MESSAGE }}
    />
  );
};

const mapStateToProps = (state) => {
  const { token, user } = state.auth;
  const { selected_branch: selectedBranch } = state.branches;
  return { token, user, selectedBranch };
};
const mapDispatchToProps = {
  setError,
  startLoading,
  stopLoading,
  setConfirm,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Show);
