import { createElement, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useScreenSize } from "../Hooks";
import Layout from "../layouts/Layout";
import MobileLayout from "../layouts/mobile/Layout";
import { logoutAdmin } from "../redux/actions/AdminLoginAction";
import { fetchUser, logout } from "../redux/actions/loginAction";
import { fetchEmployees, fetchRoles } from "../redux/actions/scheduleAction";

const UAuth = ({
  token,
  user,
  logout,
  selected_branch,
  routes,
  fetchEmployees,
  fetchRoles,
  connected_users,
  fetchUser,
}) => {
  const { isMobile, isTablet } = useScreenSize();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.id) {
      navigate("/users/login", { replace: true });
    } else {
      fetchUser(user.id);
    }
  }, [user.id, fetchUser]);

  useEffect(() => {
    if (!selected_branch?.id) {
      return;
    }
    fetchEmployees();
    fetchRoles();
  }, [selected_branch?.id, fetchEmployees, fetchRoles]);

  return createElement(isMobile || isTablet ? MobileLayout : Layout, {
    user,
    token,
    selected_branch,
    logout,
    routes,
    baseRoute: "users",
    connected_users,
  });
};
const UGuest = ({ token }) => {
  return !!token && <Navigate to="/users" replace />;
};
const AAuth = ({ token, user, logout, routes }) => {
  const { isMobile } = useScreenSize();

  return token ? (
    createElement(isMobile ? MobileLayout : Layout, {
      token,
      user,
      logout,
      routes,
      baseRoute: "rs-admin",
    })
  ) : (
    <Navigate to="/rs-admin/login" replace />
  );
};
const AGuest = ({ token, children }) => {
  return !token ? children : <Navigate to="/rs-admin" replace />;
};

const mapStateToProps = (state) => {
  const { token, user } = state.auth;
  const { selected_branch } = state.branches;
  const { connected_users } = state.user_privileges;

  return { token, user, selected_branch, connected_users };
};
const adminMapStateToProps = (state) => {
  const { token, admin: user } = state.authAdmin;
  return { token, user };
};

const mapDispatchToProps = {
  logout,
  fetchEmployees,
  fetchRoles,
  fetchUser,
};
const AdminMapDispatchToProps = {
  logout: logoutAdmin,
};

const UserAuth = connect(mapStateToProps, mapDispatchToProps)(UAuth);
const UserGuest = connect(mapStateToProps, mapDispatchToProps)(UGuest);
const AdminAuth = connect(adminMapStateToProps, AdminMapDispatchToProps)(AAuth);
const AdminGuest = connect(
  adminMapStateToProps,
  AdminMapDispatchToProps
)(AGuest);

export { UserAuth, UserGuest, AdminAuth, AdminGuest };
