import {
  Button,
  CircularProgress,
  // Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { default as Grid } from "@mui/material/Unstable_Grid2";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaCheck, FaSatelliteDish, FaTimes } from "react-icons/fa";
import Form from "../../../../../components/Forms/Form";
import FormTextField from "../../../../../components/Forms/inputs/FormTextField";
import { COLOR_ABORT, COLOR_CONFIRM } from "../../../../../const/colors";
import { useScreenSize } from "../../../../../Hooks";

const defaultValues = {
  ip: "",
  port: "",
  by_range: 1,
  enabled: 1,
};

const MakorCreateForm = ({ onSubmit, token }) => {
  const { isMobile } = useScreenSize()
  return (
    <div style={{ width:'100%' }}>

    <Form {...{ onSubmit, defaultValues,formId:'form' }}>
      <FormContext {...{ token, isMobile }} />
    </Form>
    </div>
  );
};

const FormContext = ({ token, isMobile }) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [checkingPort, setCheckingPort] = useState(false);
  const [isPortOpen, setIsPortOpen] = useState(null);

  const checkPort = async () => {
    setCheckingPort(true);
    const { ip, port } = getValues();
    try {
      const response = await Axios.get("admin/attendanceClocks/checkPort", {
        params: {
          ip,
          port,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      setIsPortOpen(response.data.isOpen);
    } catch (err) {
      console.error(err);
    }
    setCheckingPort(false);
  };

  useEffect(() => {
    if (isPortOpen !== null) {
      setTimeout(() => setIsPortOpen(null), 2000);
    }
  }, [isPortOpen]);
  return (
    <Grid
      // style={isMobile ? { marginTop: "1rem" } : {}}
      container
      // sx={{ margin: 0 }}
      // spacing={2}
      
    >
      <Grid  xs={12} md={5}>
        <FormTextField
          fullWidth
          {...{ control }}
          name="ip"
          label="IP"
          error={errors.ip}
          rules={{
            required: "שדה חובה",
            pattern: {
              value: /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(.|$)){4}\b/,
              message: "כתובת IP אינה תקינה",
            },
          }}
        />
      </Grid>
      <Grid  xs={12} md={5}>
        <FormTextField
          fullWidth
          {...{ control }}
          name="port"
          label="Port"
          type="number"
          error={errors.port}
          rules={{
            required: "שדה חובה",
            minLength: {
              value: 4,
              message: "פורט אינו תקין",
            },
          }}
        />
      </Grid>
      <Grid  xs={2}>
        <div className="flex-column-center">
          <Tooltip
            arrow
            placement="right"
            title={
              <Typography align="left" style={{ color: "white" }}>
                בדיקת תקשורת
              </Typography>
            }
          >
            <IconButton style={{ marginTop: "1rem" }} onClick={checkPort}>
              {checkingPort ? (
                <CircularProgress size="2rem" color="inherit" />
              ) : isPortOpen === null ? (
                <FaSatelliteDish />
              ) : isPortOpen ? (
                <FaCheck color={COLOR_CONFIRM} />
              ) : (
                <FaTimes color={COLOR_ABORT} />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  );
};

export default MakorCreateForm;
