export const generateUserLogsColumns = () => {
  let columns = [
    {
      Header: " תאריך ושעה",
      accessor: "updated_at",
      Cell: ({ row }) => {
        const { updated_at } = row.original;
        const dateObj = new Date(updated_at);

        // Format time as hh:mm
        const time = `${("0" + dateObj.getHours()).slice(-2)}:${(
          "0" + dateObj.getMinutes()
        ).slice(-2)}`;

        // Format date as DD/MM/YYYY
        const day = ("0" + dateObj.getDate()).slice(-2);
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const year = dateObj.getFullYear();
        const date = `${day}/${month}/${year}`;

        // Return formatted date and time
        return (
          <div>
            {date} {time}
          </div>
        );
      },
      displayMobile: true,
    },
    {
      Header: "נציג",
      accessor: "admin_info",
      displayMobile: true,
      Cell: ({ value }) => <>{`${value.first_name} ${value.last_name}`}</>,
    },
    {
      Header: "שם פרטי",
      accessor: "first_name",
      displayMobile: true,
      Cell: ({
        row: {
          original: { user_info },
        },
      }) => <>{user_info.firstName}</>,
    },
    {
      Header: "שם משפחה",
      accessor: "last_name",
      displayMobile: true,
      Cell: ({
        row: {
          original: { user_info },
        },
      }) => <>{user_info.lastName}</>,
    },
    {
      Header: "סניף",
      accessor: "user_info",
      displayMobile: true,
      Cell: ({ value }) => <>{value.branch.name}</>,
    },
    {
      Header: "רשת",
      accessor: "network",
      displayMobile: true,
      Cell: ({
        row: {
          original: { user_info },
        },
      }) => <>{user_info.network_name}</>,
    },
    {
      Header: "סיבת התחברות",
      accessor: "event",
      displayMobile: true,
    },
    {
      Header: "פירוט",
      accessor: "description",
      displayMobile: true,
    },
  ];
  return columns;
};
