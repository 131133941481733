import * as React from "react";
import Button from "@mui/material/Button";
import {Snackbar as MUISnackBar} from "@mui/material";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Grow from "@mui/material/Grow";
import { useState } from "react";
import { useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { hideSnackBar } from "../../redux/actions/confirmAction";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FadeTransition = (props) => <Fade {...props} />;
const GrowTransition = (props) => <Grow {...props} />;
const SlideTransition = (props) => <Slide {...props} />;

export const SnackBar = ({
  isOpen,
  direction,
  confirmMsg,
  position,
  hide,
  severity,
  hideSnackBar,
}) => {
  const [anchor, setAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });

  const [color, setColor] = useState("success");

  useEffect(() => {
    setColor(severity ? "success" : "warning");
  }, [severity]);

  useEffect(() => {
    switch (position) {
      case "TOP-LEFT":
        setAnchor({
          vertical: "top",
          horizontal: "left",
        });
        break;

      case "TOP-CENTER":
        setAnchor({
          vertical: "top",
          horizontal: "center",
        });
        break;

      case "TOP-RIGHT":
        setAnchor({
          vertical: "top",
          horizontal: "right",
        });
        break;

      case "BOTTOM-RIGHT":
        setAnchor({
          vertical: "bottom",
          horizontal: "right",
        });
        break;

      case "BOTTOM-CENTER":
        setAnchor({
          vertical: "bottom",
          horizontal: "center",
        });
        break;

      case "BOTTOM-LEFT":
        setAnchor({
          vertical: "bottom",
          horizontal: "left",
        });
        break;

      default:
      case "TOP-CENTER":
        setAnchor({
          vertical: "top",
          horizontal: "center",
        });
        break;
    }
  }, [position]);

  return (
    <MUISnackBar
      open={isOpen}
      onClose={hideSnackBar}
      anchorOrigin={anchor}
      TransitionComponent={SlideTransition}
      TransitionProps={{ direction }}
      key={SlideTransition.name}
      autoHideDuration={hide}
    >
      <Alert onClose={hideSnackBar} severity={color} sx={{ width: "100%" }}>
        <div className="flex-center">{confirmMsg}</div>
      </Alert>
    </MUISnackBar>
  );
};

const mapStateToProps = (state) => {
  const { isOpen, confirmMsg, severity, direction, position, hide } =
    state.snackBar;

  return {
    isOpen,
    confirmMsg,
    severity,
    direction,
    position,
    hide,
  };
};

export default connect(mapStateToProps, { hideSnackBar })(SnackBar);
