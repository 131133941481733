import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import validator from "validator";
import Button from "../components/buttons/Button";
import { adminLoginOTP } from "../redux/actions/AdminLoginAction";

const OTPModal = ({
  phone_number,
  open,
  onClose,
  password,
  email,
  adminLogin,
  setOTPModal,
  adminLoginOTP
}) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);

  const handleCodeChange = (e) => {
    if (validator.isNumeric(e.target.value) || e.target.value === "") {
      setCode(e.target.value);
      if (error) {
        setError(null);
      }
    }
  };

  const submitCodeLogin = () => {
    if (code.length !== 6) setError("הכנס 6 ספרות");
    else adminLoginOTP(email, password, code);
  };

  const renderError = () => (
    <p>
      * <span className="error-msg">{error}</span>{" "}
    </p>
  );

  return (
    <Modal
      styles={{ modal: { maxWidth: "fit-content", textAlign: "center" } }}
      open={open}
      center
      animationDuration={0}
      onClose={() => onClose()}
    >
      <div style={{ textAlign: "right" }}>
        <h1>מערכת רסטיגו</h1>
        <h2>הזן סיסמא זמנית</h2>
        <p style={{ display: "inline-block" }}>
          קוד אימות נשלח כעת לסלולרי שלך שמספרו
        </p>{" "}
        <p style={{ direction: "ltr", display: "inline-block" }}>
          {phone_number
            ? phone_number
            : ""}
        </p>
        <p>אנא הקלד את הקוד ולחץ על כניסה.</p>
        <label>
          קוד אימות:
          <input
            onChange={(e) => handleCodeChange(e)}
            value={code}
            maxLength="6"
            style={{ width: "20rem" }}
            type="text"
          />
          {error ? renderError() : <></>}
        </label>
        <p>
          לא קיבלת את הקוד? לחץ לקבלת הקוד פעם נוספת ב-
          <Button
            onClick={() => adminLogin({email, password}, setOTPModal)}
            className="btn--hyperlink"
            textValue="SMS"
          />
        </p>
      </div>
      <Button
      type="button"
        onClick={() => submitCodeLogin()}
        className="btn--submit"
        textValue="כניסה"
      />
    </Modal>
  );
};

export default connect(null, {adminLoginOTP})(OTPModal);
