import React, { useEffect, useMemo, useState } from "react";
import Model, {
  Header,
  Content,
  Footer,
} from "../../../../../components/Model";
import Button from "../../../../../components/buttons/Button";
import ButtonIcon from "../../../../../components/buttons/ButtonIcon";
import { connect } from "react-redux";
import {
  addHoursToEvent,
  savePlannedHours,
} from "../../../../../redux/actions/scheduleAction";
import HoursInput from "../../../../../components/inputs/HoursInput";
import { isEmpty, isUndefined } from "lodash";
import {
  fixDate,
  fixHour,
  getTotalHours,
  toSeconds,
} from "../../../../../helpers";
import SegmentedControl from "../../../../../components/SegmentedControl";
import { isEqual, startOfDay, getHours, getMinutes, isBefore } from "date-fns";
import { Row } from "react-flexbox-grid";
import Popover from "react-popover";
import TransferEmployee from "./TransferEmployee";
import { FaExchangeAlt, FaGift, FaRegClock } from "react-icons/fa";
import Modal from "react-responsive-modal";
import BonusModal from "./BonusModal";
import { CANT_EDIT_SELF_PRIVILEGE } from "../../../../../const/privilegesButtons";
import BtnTooltip from "../../../../../components/tooltips/BtnTooltip";
import { useScreenSize } from "../../../../../Hooks";
import { BsClockHistory } from "react-icons/bs";
import { Box } from "@mui/material";
import Logs from "./Logs";
import { isFunction } from "lodash";
import ReportBonusModal from "./ReportBonusModal";
const EditShiftHours = ({
  event = null,
  style,
  reload = null,
  addHoursToEvent,
  savePlannedHours,
  handleOpen,
  standby_departments,
  saveToReport = false,
  setInsideModal,
  insideModal,
  employees_transfer = 0,
  cant_edit_self_id,
  updateTable = null,
  validateChanges = false,
  setOpenBonusModal,
  openBonusModal,
  selected_branch,
  fromReportsTable = false,
}) => {
  const { isMobile } = useScreenSize();
  const { manual, planned, clock, trim } = event.hours;
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [defaultStart, setDefaultStart] = useState(null);
  const [defaultEnd, setDefaultEnd] = useState(null);
  const [standbyValue, setStandbyValue] = useState(event.standby || 0);
  const [option, setOption] = useState(0);
  const [options, setOptions] = useState([
    { id: 0, text: "תכנון", onClick: () => {} },
  ]);
  const [formErrors, setFormErrors] = useState({});

  const validate = () => {
    const errors = {};
    const dateToComp = new Date(event.working_date);
    const today = new Date();

    if (option === 1) {
      if (employee && employee.id === cant_edit_self_id) {
        errors.self = "לא ניתן לעדכן שעות באופן עצמאי";
      }
    }

    if (isEqual(startOfDay(dateToComp), startOfDay(today)) && option) {
      if (start !== null) {
        if (
          toSeconds(`${getHours(today)}:${getMinutes(today)}`) <
          toSeconds(fixHour(start))
        ) {
          errors.hours = "שעת כניסה מאוחרת מהשעה הנוכחית";
        }
      }
      if (end !== null) {
        if (
          toSeconds(`${getHours(today)}:${getMinutes(today)}`) + 3600 <
          toSeconds(fixHour(end))
        ) {
          errors.hours = "שעת יציאה מאוחרת מהשעה הנוכחית";
        }
      }
    }
    if (end === start && (end || start)) errors.hours = "שעת התחלה וסיום זהות";

    if (!option) {
      if (!start) errors.hours = "חובה להכניס שעת כניסה";
    }

    setFormErrors(errors);
    if (!isEmpty(errors)) return false;
    else return true;
  };

  useEffect(() => {
    const options = [
      { id: 0, text: "תכנון", onClick: () => setOption(0) },
      { id: 1, text: "בפועל", onClick: () => setOption(1) },
    ];
    const dateToComp = new Date(event.working_date);
    const today = new Date();
    if (event.employee) {
      if (isEqual(startOfDay(dateToComp), startOfDay(today))) {
        setOptions(options);
        if (
          `${getHours(today)}:${getMinutes(today)}` >
          fixHour(event.hours.planned.start)
        )
          setOption(1);
      } else if (isBefore(startOfDay(dateToComp), startOfDay(today))) {
        setOptions(options);
        setOption(1);
      }
      setDefaultEnd(setDefaultHourManual("end"));
      setDefaultStart(setDefaultHourManual("start"));
    }
  }, [event]);

  const renderError = (name) => {
    if (!isUndefined(formErrors[name]))
      return (
        <p style={{ width: "100%", textAlign: "center" }}>
          <span className="error-msg">{formErrors[name]}</span>{" "}
        </p>
      );
  };

  const saveHours = (event) => {
    if (validateChanges) {
      if (!start && !clock.start) return;
      if (!end && !clock.end) return;
    }
    if (option) {
      if (validate()) {
        addHoursToEvent(
          { event_id: event.id, start, end, standby: standbyValue },
          handleOpen,
          saveToReport,
          saveToReport ? reload : null,
          updateTable
        );
      }
    } else {
      if (!(planned.start === start && planned.end === end))
        if (validate()) {
          savePlannedHours(
            { event_id: event.id, start, end },
            handleOpen,
            saveToReport,
            saveToReport ? reload : null,
            updateTable
          );
        }
    }
  };

  const setDefaultHourManual = (status) => {
    if (manual[status]) return manual[status];
    else if (trim[status] || clock[status]) return null;

    return planned[status];
  };
  const { employee } = event;
  const renderIfActual = useMemo(
    () => () => {
      if (event.actual_end && event.actual_start) {
        return (
          <>
            {employees_transfer && !saveToReport ? (
              <>
                <Modal
                  animationDuration={0}
                  center
                  open={insideModal === 2 && isMobile}
                  onClose={() => setInsideModal(null)}
                >
                  <TransferEmployee
                    event={event}
                    onAbort={() => setInsideModal(null)}
                  />
                </Modal>
                <Popover
                  isOpen={insideModal === 2 && !isMobile}
                  place="below"
                  onOuterAction={() => {
                    setInsideModal(null);
                  }}
                  enterExitTransitionDurationMs={300}
                  body={
                    <TransferEmployee
                      event={event}
                      onAbort={() => setInsideModal(null)}
                    />
                  }
                >
                  <div>
                    <ButtonIcon
                      className="btn--hyperlink btn--hyperlink__black model--btn__transfer"
                      alt="העברת עובד"
                      icon={FaExchangeAlt}
                      onClick={() => setInsideModal(2)}
                      iconSize="1.4em"
                    />
                  </div>
                </Popover>
              </>
            ) : (
              <></>
            )}
            {selected_branch?.bonus_enable && fromReportsTable ? (
              <>
                <Modal
                  animationDuration={0}
                  center
                  open={openBonusModal}
                  onClose={() => setOpenBonusModal(false)}
                  styles={{
                    modal: { width: isMobile ? "90%" : "20%" },
                  }} // Set the width of the modal
                >
                  <Box>
                    <ReportBonusModal
                      employee={event}
                      onAbort={() => setOpenBonusModal(false)}
                    />
                  </Box>
                </Modal>
                <Popover
                  isOpen={openBonusModal}
                  enterExitTransitionDurationMs={300}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  placement="bottom-end"
                >
                  <ButtonIcon
                    className="btn--hyperlink btn--hyperlink__black model--btn__bonus"
                    alt="הוספת בונוס"
                    icon={FaGift}
                    onClick={() => setOpenBonusModal(!openBonusModal)}
                    iconSize="1.4em"
                  />
                </Popover>
              </>
            ) : event.role &&
              selected_branch.bonus_enable &&
              !fromReportsTable ? (
              <>
                <Modal
                  animationDuration={0}
                  center
                  open={insideModal === 3 && isMobile}
                  onClose={() => setInsideModal(null)}
                >
                  <BonusModal
                    event={event}
                    onAbort={() => setInsideModal(null)}
                  />
                </Modal>
                <Popover
                  isOpen={insideModal === 3 && !isMobile}
                  place="below"
                  // onOuterAction={() => {
                  //   setInsideModal(null);
                  // }}
                  enterExitTransitionDurationMs={300}
                  body={
                    <BonusModal
                      event={event}
                      onAbort={() => setInsideModal(null)}
                    />
                  }
                >
                  <div>
                    <ButtonIcon
                      className="btn--hyperlink btn--hyperlink__black model--btn__bonus"
                      alt="הוספת בונוס"
                      icon={FaGift}
                      onClick={() => setInsideModal(3)}
                      iconSize="1.4em"
                    />
                  </div>
                </Popover>
              </>
            ) : (
              <></>
            )}
            {event?.logs?.length > 0 && (
              <Modal
                animationDuration={0}
                center
                open={insideModal === 4 && isMobile}
                onClose={() => setInsideModal(null)}
              >
                <Logs logs={event.logs} />
              </Modal>
            )}
          </>
        );
      }
    },
    [event, employees_transfer, openBonusModal]
  );

  return (
    <Model style={style} className="editHours">
      <Header>
        <h3>שעות משמרת</h3>
        <p style={{ fontSize: "1rem", fontWeight: "500" }}>
          {event.role ? event.role.name : event.employee.name}{" "}
          {fixDate(event.working_date)}
        </p>
        {renderIfActual()}
      </Header>
      <SegmentedControl options={options} selected={option} />
      <div style={{ margin: "0.5rem 0" }} className="flex-even ">
        <h3>יציאה</h3>
        <h3>כניסה</h3>
      </div>
      {option === 0 ? (
        <>
          <Content className={`model--edit-hours left-to-right `}>
            <div className="flex-even " style={{ width: "20rem" }}>
              <div>
                <HoursInput getClock={setStart} defaultHour={planned.start} />
              </div>
              <div>
                <HoursInput getClock={setEnd} defaultHour={planned.end} />
              </div>
            </div>
          </Content>
          <Content>
            <label>{`סה"כ שעות: ${getTotalHours(
              planned.start,
              planned.end
            )}`}</label>
          </Content>
        </>
      ) : (
        <>
          {clock.start || clock.end ? (
            <>
              <Row style={{ margin: "0 15px", borderTop: "solid 1px #EAEAEA" }}>
                <h4>שעון</h4>
              </Row>
              <Content className="model--edit-hours left-to-right">
                <div className="flex-even " style={{ width: "20rem" }}>
                  <div>
                    <HoursInput disabled={true} defaultHour={clock.start} />
                  </div>
                  <div>
                    <HoursInput disabled={true} defaultHour={clock.end} />
                  </div>
                </div>
              </Content>
              <Content>
                <label>{`סה"כ שעות: ${getTotalHours(
                  clock.start,
                  clock.end
                )}`}</label>
              </Content>

              {clock.start &&
              clock.end &&
              getTotalHours(clock.start, clock.end) > 12 ? (
                <Content>
                  <Button
                    icon={FaRegClock}
                    onClick={() => {
                      setDefaultEnd(clock.start);
                      setDefaultStart(clock.end);
                    }}
                    className="btn--hyperlink"
                    textValue="האם טעו? החלף שעות כניסה ביציאה"
                  />
                </Content>
              ) : (
                <></>
              )}
            </>
          ) : (
            ""
          )}
          {trim.start || trim.end ? (
            <>
              <Row style={{ margin: "0 15px", borderTop: "solid 1px #EAEAEA" }}>
                <h4>חיתוך</h4>
              </Row>
              <Content className="model--edit-hours left-to-right">
                <div className="flex-even " style={{ width: "20rem" }}>
                  <div>
                    <HoursInput
                      disabled={true}
                      defaultHour={trim.start || clock.start}
                    />
                  </div>
                  <div>
                    <HoursInput
                      disabled={true}
                      defaultHour={trim.end || clock.end}
                    />
                  </div>
                </div>
              </Content>
              <Content>
                <label>{`סה"כ שעות: ${getTotalHours(
                  trim.start || clock.start,
                  trim.end || clock.end
                )}`}</label>
              </Content>
            </>
          ) : (
            ""
          )}
          <Row style={{ margin: "0 15px", borderTop: "solid 1px #EAEAEA" }}>
            <h4>ידני</h4>
          </Row>
          <Content className={`model--edit-hours left-to-right `}>
            <div className="flex-between">
              {event?.logs?.length > 0 && isFunction(setInsideModal) && (
                <Popover
                  isOpen={insideModal === 4 && !isMobile}
                  place={event.week_day >= 4 ? "right" : "left"}
                  onOuterAction={() => {
                    setInsideModal(null);
                  }}
                  enterExitTransitionDurationMs={300}
                  body={<Logs logs={event.logs} />}
                >
                  <div>
                    <ButtonIcon
                      className="btn--hyperlink"
                      alt="הסטוריית עדכונים"
                      icon={BsClockHistory}
                      onClick={() => setInsideModal(4)}
                      iconSize="1.4em"
                    />
                  </div>
                </Popover>
              )}
              <div className="flex-even " style={{ width: "20rem" }}>
                <div>
                  <HoursInput
                    errors={formErrors}
                    setErrors={setFormErrors}
                    getClock={setStart}
                    defaultHour={defaultStart}
                  />
                </div>
                <div>
                  <HoursInput
                    errors={formErrors}
                    setErrors={setFormErrors}
                    getClock={setEnd}
                    defaultHour={defaultEnd}
                  />
                </div>
              </div>
            </div>
          </Content>
          <Content>
            <label>{`סה"כ שעות: ${getTotalHours(
              start || manual.start || trim.start || clock.start,
              end || manual.end || trim.end || clock.end
            )}`}</label>
          </Content>
          <Row
            style={{ margin: "0 15px", borderTop: "solid 1px #EAEAEA" }}
          ></Row>

          {event.department ? (
            standby_departments.includes(event.department.id) ? (
              <Content>
                <div style={{ paddingTop: "0.5rem" }}>
                  <label
                    style={{ display: "inline-block", width: "fit-content" }}
                  >
                    המתנה בדקות:
                  </label>
                  <input
                    min={0}
                    max={180}
                    type="number"
                    style={{
                      display: "inline-block",
                      padding: "0.5rem",
                      margin: "0.5rem",
                      width: "5rem",
                    }}
                    onChange={(e) => setStandbyValue(e.target.value)}
                    value={standbyValue}
                  />
                </div>
              </Content>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </>
      )}
      {renderError("hours")}

      <Footer
        style={{ borderTop: "solid 1px #EAEAEA" }}
        className={"model--footer"}
      >
        <Button
          className="btn--neutral"
          onClick={() => handleOpen(null)}
          textValue="cancel"
        />
        <Button
          className="btn--submit"
          onClick={() => saveHours(event)}
          textValue="save"
        />
        {renderError("self")}
      </Footer>
    </Model>
  );
};
const mapStateToProps = (state) => {
  const { standby_departments } = state.schedule.schedule;
  const { isMobile } = state.publicValues;
  const { employees_transfer, privileges_buttons } = state.user_privileges;
  const { user } = state.auth;
  const { selected_branch } = state.branches;

  //TODO: need to apply privileges_buttons.includes(<cant assign self id>)
  const cant_edit_self_id = privileges_buttons.includes(
    CANT_EDIT_SELF_PRIVILEGE
  )
    ? user.id
    : null;
  return {
    standby_departments,
    isMobile,
    employees_transfer,
    cant_edit_self_id,
    selected_branch,
  };
};

export default connect(mapStateToProps, { addHoursToEvent, savePlannedHours })(
  EditShiftHours
);
