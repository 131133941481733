import axios from "axios";

// if (process.env.NODE_ENV === "development")
//   axios.defaults.baseURL = "http://restigo.test/api/";
// if (process.env.NODE_ENV === "production")
//   axios.defaults.baseURL = "https://php7.restigo.co.il/api/";
// axios.defaults.baseURL = "http://restigo.test/api/";
// axios.defaults.baseURL = ;

axios.defaults.baseURL = process.env.REACT_APP_API_URL
export const BASE_URL = axios.defaults.baseURL.replace("/api/","");

class AdminAuthService {
  login(email, pass) {
    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", pass);
    return axios.post("admin/jwtlogin", formData).then((res) => {
      if (res.status === 401) {
        throw new Error("Auth Error");
      }
      return res.data;
    });
  }
}
export default new AdminAuthService();
