import {
  SET_PAGINATION_FILTERS,
  GET_TASKS_TYPE,
  SET_DATA_LOADING,
  SET_TASKS,
  RESET_PAGINATION_DATA,
  ADD_CATEGORY,
  UPDATE_EMAIL,
  SET_TASK_FILTER,
  GET_TASKS_EMAILS,
  SET_TASK_TYPE,
  RESET_TASKS_FILTERS,
  SET_REPORTS_FILTER,
  SET_DELETED_AT,
  SET_BRANCH_EMPLOYEE,
  SET_CURRENT_TASK,
  SET_CURR_TASK_DATA,
  UPDATE_SELECTED_PARTS,
  SET_TASK_DAY,
  UPDATE_AMOUNT_OF_DONE_PARTS,
  UPDATE_EDIT_FIELD,
  UPDATE_NEW_COMMENT,
  SET_HISTORY_TASKS,
} from "../actions/tasksActions/types";

const INITIAL_STATE = {
  tasks: [],
  branchEmployees: [],
  taskHistory: [],
  employeeTasks: [],
  showTask: null,
  tasksType: [],
  tasksEmails: [],
  filter: {
    status: [2],
    branchId: [],
    categoryId: [],
    departments: [],
    days: [],

    categoryManageStatus: [2],
    emailManageStatus: [2],
    reportsFilters: {
      branches: [],
      departments: [],
      freeSearch: "",
      taskDays: [],
      days: [],
      checklists: [],
    },

    currentPage: 1,
    per_page: 15,
    total: 0,
  },
  currentTaskType: 3,
  selectedNewTask: 3,
  isDataLoading: false,
  currentTask: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_TASKS_TYPE:
      return {
        ...state,
        tasksType: [...payload],
      };
    case SET_PAGINATION_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          currentPage: payload.current_page,
          per_page: payload.per_page,
          total: payload.total,
        },
      };
    case RESET_PAGINATION_DATA:
      return {
        ...state,
        filter: { ...state.filter, currentPage: 1, per_page: 15, total: 0 },
      };
    case SET_DATA_LOADING:
      return { ...state, isDataLoading: payload };
    case SET_TASKS:
      return { ...state, tasks: payload };
    case ADD_CATEGORY:
      return { ...state, tasksType: [...state.tasksType, payload] };
    case UPDATE_EMAIL:
      return { ...state, taskEmails: [...payload] };
    case SET_TASK_FILTER:
      return {
        ...state,
        filter: { ...state.filter, [payload.name]: payload.value },
      };
    case GET_TASKS_EMAILS:
      return {
        ...state,
        tasksEmails: [...payload],
      };
    case SET_TASK_TYPE:
      return {
        ...state,
        currentTaskType: payload,
      };
    case SET_TASK_DAY:
      return {
        ...state,
        currentTaskDay: payload,
      };
    case RESET_TASKS_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          reportsFilters: {
            ...INITIAL_STATE.filter.reportsFilters,
            days: [new Date().getDay() + 1],
          },
        },
      };
    case SET_REPORTS_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          reportsFilters: {
            ...state.filter.reportsFilters,
            [payload.name]: payload.value,
          },
        },
      };
    case SET_DELETED_AT:
      return {
        ...state,
        tasks: [...payload],
      };
    case SET_BRANCH_EMPLOYEE:
      return { ...state, branchEmployees: payload };

    case SET_HISTORY_TASKS:
      return { ...state, taskHistory: payload };
    case SET_CURRENT_TASK:
      return {
        ...state,
        selectedNewTask: payload,
      };
    case SET_CURR_TASK_DATA:
      return {
        ...state,
        currentTask: { ...payload, amountOfDoneParts: 0 },
      };
    case UPDATE_SELECTED_PARTS:
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          selectedOptions: payload,
        },
      };
    case UPDATE_AMOUNT_OF_DONE_PARTS:
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          totalAmountOfDoneParts: payload,
        },
      };
    case UPDATE_EDIT_FIELD:
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          initialEditField: payload,
        },
      };
    case UPDATE_NEW_COMMENT:
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          selectedOptions: payload,
        },
      };
    default:
      return state;
  }
};
