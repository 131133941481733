class StoreBase {
rootStore;

  constructor(externalStore) {
    if (externalStore) {
      Object.assign(this, { ...externalStore });
    }
  }

  init(rootStore) {
    this.rootStore = rootStore;

    return this;
  }
}

export default StoreBase;
