import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import {
  getBranchesSettingsURI,
  getDailyTipStructure,
  getSalaryStructureURI,
  getShiftPositions,
  uploadProfileImageURI,
  workersURI,
} from "../services/constants";
import {
  IDENTIFIER,
  SALARY_PER_HOUR,
  calcHoursSettings,
  dayLengthOptions,
  dayLengthTypeOptions,
  employeeBreakOptions,
  employerCostOptions,
  fixedHoursOptions,
  hourCalcMethod,
  minTipsOptions,
  salarySubtypeOptions,
  salaryTypeOptions,
  tipAllowanceOptions,
} from "../constants";
import { travelOptions } from "../constants";
import { generateUniqueEmail, getDefaultSalaryType } from "../helpers";

const initialState = {
  data: {
    master: {
      isLoading: false,
      thunkStatus: "idle",
      data: [],
      freeSearch: {
        isLoading: false,
        data: [],
      },
      pagination: {
        current_page: 0,
        per_page: 100,
        total: 1,
      },
    },
    details: {
      isLoading: false,
      data: {},
    },
  },
  shiftPositions: {
    isLoading: false,
    thunkStatus: "idle",
    data: [],
  },
  branchSettings: {},
  error: null,
};

export const usersSlice = createSlice({
  name: "workers",
  initialState,
  reducers: {
    setWorkersMasterPage: (state, action) => {
      const { value, name } = action.payload;
      state.data.master.pagination[[name]] = value;
    },
    closeDetailsForm: (state, _action) => {
      state.data.details.data = initialState.data.details.data;
    },
    addNewUser: (state, action) => {
      state.data.details.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWorkers.pending, (state, action) => {
        state.data.master.isLoading = true;
        state.data.master.thunkStatus = "loading";
      })
      .addCase(fetchWorkers.fulfilled, (state, action) => {
        if (!action.payload?.data) {
          state.data.master.isLoading = false;
          state.data.master.thunkStatus = "failed";
          return;
        }
        state.data.master.thunkStatus = "succeeded";
        const { total, current_page, per_page, data } = action.payload;
        state.data.master.data = data;
        state.data.master.pagination = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
        state.data.master.isLoading = false;
      })
      .addCase(fetchWorkers.rejected, (state, action) => {
        state.data.master.isLoading = false;
        state.data.master.thunkStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchWorker.pending, (state, action) => {
        state.data.details.isLoading = true;
        state.data.details.thunkStatus = "loading";
      })
      .addCase(fetchWorker.fulfilled, (state, action) => {
        if (!action.payload) {
          state.data.details.isLoading = false;
          state.data.details.thunkStatus = "failed";
          return;
        }
        state.data.details.isLoading = false;
        state.data.details.thunkStatus = "succeeded";
        // state.data.details.data = action.payload;
        const salary_by_subtype = Object.values(
          action.payload.info.salary_by_subtype ?? {}
        );
        const userData = {
          ...action.payload,
          type_identifier: action.payload.type_identifier ?? IDENTIFIER,
          info: {
            ...action.payload.info,
            salary_by_subtype: salary_by_subtype.map((salary) => {
              return {
                ...salary,

                subtype: state.shiftPositions.data.find(
                  ({ value }) => value == salary.subtype
                ),
                salary_type: salarySubtypeOptions.find(
                  ({ value }) => value == salary.salary_type
                ),
              };
            }),
            travel_cost_type: travelOptions.find(
              (travel) => travel.value === action.payload.info?.travel_cost_type
            ),
            employer_cost: employerCostOptions.find(
              (employer) => employer.value === action.payload.info.employer_cost
            ),
            hours_calc_method: hourCalcMethod.find(
              (hourMethod) =>
                hourMethod.value === action.payload.info.hours_calc_method
            ),
            salary_type: salaryTypeOptions.find(
              ({ value }) =>
                value ===
                getDefaultSalaryType(
                  action.payload.info.salary_type,
                  action.payload.info.global_salary
                )
            ),
            calc_hours_settings: action.payload.info.calc_hours_settings
              ? Object.keys(action.payload.info.calc_hours_settings).map(
                  (hourSetting) =>
                    calcHoursSettings.find(
                      (calcSetting) => calcSetting.id === hourSetting
                    )
                )
              : [],
            day_length_level: dayLengthOptions.find(
              (day) => day.value === action.payload.info.day_length_level
            ),
            day_length_type: dayLengthTypeOptions.find(
              (day) => day.value === action.payload.info.day_length_type
            ),
            has_break: employeeBreakOptions.find(
              (day) => day.value === action.payload.info.has_break
            ),
            fixed_hours: fixedHoursOptions.find(
              (hour) => hour.value === action.payload.info.fixed_hours
            ),
            night_shift_length_level: dayLengthOptions.find(
              (day) =>
                day.value === action.payload.info.night_shift_length_level
            ),
            min_tip_method: minTipsOptions.find(
              (tip) => tip.value === action.payload.info.min_tip_method
            ),
            tips_allowance_method: tipAllowanceOptions.find(
              (tip) => tip.value === action.payload.info.tips_allowance_method
            ),
            min_tips_by_shift_day_array: Object.entries(
              action.payload.info.min_tips_by_shift_day_array
            ),
          },
        };
        state.data.details.data = userData;
      })
      .addCase(fetchWorker.rejected, (state, action) => {
        state.data.details.isLoading = false;
        state.data.details.thunkStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchShiftPositions.pending, (state, action) => {
        state.shiftPositions.isLoading = true;
        state.shiftPositions.thunkStatus = "loading";
      })
      .addCase(fetchShiftPositions.fulfilled, (state, action) => {
        if (!action.payload) {
          state.shiftPositions.isLoading = false;
          state.shiftPositions.thunkStatus = "failed";
          return;
        }
        state.shiftPositions.isLoading = false;
        state.shiftPositions.thunkStatus = "succeeded";
        state.shiftPositions.data = action.payload;
      })
      .addCase(fetchShiftPositions.rejected, (state, action) => {
        state.shiftPositions.isLoading = false;
        state.shiftPositions.thunkStatus = "failed";
        state.error = action.payload;
      })
      .addCase(uploadProfileImage.pending, (state, action) => {})
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        state.data.details.data.image = "";
        state.data.details.data.image = action.payload;
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {})

      .addCase(getDailyTipsStructure.pending, (state, action) => {})
      .addCase(getDailyTipsStructure.fulfilled, (state, action) => {
        state.data.details.data.info.min_tips_by_shift_day_array =
          Object.entries(action.payload["min_tips_by_shift_day_array"]);
        state.data.details.data.info.min_tips_by_shift_day =
          action.payload["min_tips_by_shift_day"];
      })
      .addCase(getDailyTipsStructure.rejected, (state, action) => {
        state.data.details.data.info.min_tips_by_shift_day_array = [];
        state.data.details.data.info.min_tips_by_shift_day = {};
      })
      .addCase(getBranchesSettings.fulfilled, (state, action) => {
        state.branchSettings = action.payload;
      })
      .addCase(getBranchesSettings.rejected, (state, action) => {
        state.branchSettings = {};
      });
  },
});

export const fetchWorkers = createAsyncThunk(
  "workers/fetchWorkers",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    const { current_page, per_page } =
      getState().workers.data.master.pagination;

    const {
      branchIds,
      status,
      positions,
      privilegesHierarchy,
      selectedOther,
      workerIds,
    } = getState().workersFilter.fields;

    const convertedStatus = !!status.length
      ? status.map((value) => value - 1)
      : [1];

    const additionalFilter = selectedOther?.id ? selectedOther?.id : null;

    const params = {
      workerIds,
      branchIds,
      status: convertedStatus,
      positions,
      privileges: privilegesHierarchy,
      additionalFilter,
      page: current_page + 1,
      per_page,
    };

    try {
      const res = await Axios.get(workersURI, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      let data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const fetchWorker = createAsyncThunk(
  "workers/fetchOneWorker",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    const id = payload.id;

    try {
      const res = await Axios.get(`${workersURI}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let data = await res.data;

      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const fetchShiftPositions = createAsyncThunk(
  "workers/fetchShiftPositions",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    try {
      const res = await Axios.get(getShiftPositions, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const uploadProfileImage = createAsyncThunk(
  "workers/uploadProfileImage",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;
    try {
      const res = await Axios.post(
        `${uploadProfileImageURI}/${payload.id}`,
        payload.file,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await res.data;
      return data;
    } catch (err) {}
  }
);

export const createNewUser = (payload) => (dispatch, getState) => {
  let newUserObj = { new_user: true, info: {} };
  const currentState = getState();

  newUserObj.email = generateUniqueEmail();
  newUserObj.branch_id =
    currentState.auth.user.privilege_id !== 1
      ? currentState.branches.branches[0].id
      : null;
  newUserObj.info.travel_cost_type = { value: 1, label: "נסיעות יומי" };
  newUserObj.type_identifier = IDENTIFIER;
  newUserObj.privileges_id = 9;
  newUserObj.info.travel_cost = newUserObj.enable = true;
  newUserObj.info.repeated_expenses_id = 0;
  newUserObj.info.hours_calc_method = 0;
  newUserObj.info.calc_hours_settings = [];
  newUserObj.info.day_length_by_day = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];
  newUserObj.info.min_tips_by_shift_day_array = [];
  newUserObj.info.has_break = { value: 1, label: "כן" };
  newUserObj.info.hours_calc_method = { value: 0, label: "ברמת סניף" };
  newUserObj.info.day_length_level = { value: 1, label: "סניף" };
  newUserObj.info.night_shift_length_level = { value: 1, label: "סניף" };
  newUserObj.info.fixed_hours = { value: 0, label: "ללא" };
  newUserObj.info.min_tip_method = { value: 1, label: "לפי סניף" };
  newUserObj.info.tips_allowance_method = { value: 1, label: "לפי סניף" };
  newUserObj.info.salary_type = { value: SALARY_PER_HOUR, label: "שכר שעתי" };
  newUserObj.info.shift_settings = {};
  newUserObj.info.free_monthly = 0;
  dispatch(addNewUser(newUserObj));
};
export const getDailyTipsStructure = createAsyncThunk(
  "workers/getDailyTipsStructure",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;
    try {
      const res = await Axios.get(getDailyTipStructure, {
        headers: { Authorization: `Bearer ${token}` },
        params: { branch_id: getState().branches.selected_branch.id },
      });
      const data = await res.data;
      return data;
    } catch (err) {}
  }
);

export const getBranchesSettings = createAsyncThunk(
  "workers/getBranchesSettings",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const network_id = getState().auth.user.network_id;
    try {
      const res = await Axios.get(`${getBranchesSettingsURI}/${network_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = await res.data;
      return data;
    } catch (err) {}
  }
);
export const getEmployeeTypeStructures =
  (payload) => async (dispatch, getState) => {
    const token = getState().auth.token;
    const { branch_id, employee_type_id, setValue } = payload;
    try {
      const res = await Axios.get(`${getSalaryStructureURI}`, {
        params: { branch_id, employee_type_id },
        headers: { Authorization: `Bearer ${token}` },
      });

      const { salary_structure, hours_structure, shift_settings } =
        await res.data;

      setValue(
        "info.salary_by_subtype",
        Object.values(salary_structure.salary_by_subtype).map((salary) => {
          console.log(salary, getState().workers.shiftPositions, "salary");

          return {
            ...salary,

            subtype: getState().workers.shiftPositions.data.find(
              ({ id }) => id === salary.subtype
            ),
            salary_type: salarySubtypeOptions.find(
              ({ value }) => value === salary.salary_type
            ),
          };
        })
      );
      if (shift_settings) {
        setValue(
          "info.shift_settings[min_shifts]",
          shift_settings.weekday_amount
        );
        setValue(
          "info.shift_settings[min_weekend]",
          shift_settings.weekend_amount
        );
      }

      setValue("info.adjusted_salary", salary_structure.adjusted_salary);
      setValue("info.hourly_salary", salary_structure.hourly_salary);
      setValue("info.travel_cost", salary_structure.travel_cost);
      setValue("info.const_travel_cost", salary_structure.const_travel_cost);

      setValue("info.day_length", hours_structure.day_length);
      setValue("info.night_shift_length", hours_structure.night_shift_length);
      setValue(
        "info.fixed_hours",
        fixedHoursOptions.find(
          (hour) => hour.value === hours_structure.fixed_hours
        )
      );
      setValue(
        "info.has_break",
        employeeBreakOptions.find(
          (day) => day.value === hours_structure.has_break
        )
      );
      setValue(
        "info.calc_hours_settings",
        hours_structure.calc_hours_settings ?? {}
      );
      setValue(
        "info.day_length_by_day",
        hours_structure.day_length_by_day ?? {}
      );
      setValue(
        "info.day_length_by_day",
        hours_structure.day_length_by_day ?? {}
      );
      setValue(
        "info.day_length_type",
        dayLengthTypeOptions.find(
          (day) => day.value === hours_structure.day_length_type
        )
      );
      setValue(
        "info.salary_type",
        salaryTypeOptions.find(
          ({ value }) => value === salary_structure.salary_type
        )
      );
    } catch (err) {}
  };

export const getEmployeeTypeHourStructure =
  (payload) => async (dispatch, getState) => {
    const token = getState().auth.token;
    const { branch_id, employee_type_id, setValue } = payload;
    try {
      const res = await Axios.get(
        `${getSalaryStructureURI}`,

        {
          params: { branch_id, employee_type_id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log(getState().workers.shiftPositions.data);
      const data = await res.data;
      setValue(
        "info.salary_by_subtype",
        Object.values(data.salary_by_subtype).map((salary) => {
          return {
            ...salary,

            subtype: getState().workers.shiftPositions.data.find(
              ({ value }) => value === salary.subtype
            ),
            salary_type: salarySubtypeOptions.find(
              ({ value }) => value === salary.salary_type
            ),
          };
        })
      );
      setValue("info.adjusted_salary", data.adjusted_salary);
      setValue(
        "info.salary_type",
        salarySubtypeOptions.find(({ value }) => value === data.salary_type)
      );
      setValue("info.hourly_salary", data.hourly_salary);
      setValue("info.travel_cost", data.travel_cost);
      setValue("info.const_travel_cost", data.const_travel_cost);
    } catch (err) {}
  };

export const selectPagination = (state) => state.workers.data.master.pagination;
export const selectMaster = (state) => state.workers.data.master;
export const selectDetails = (state) => state.workers.data.details;
export const selectWorkerDetails = (state) => state.workers.data.details.data;
export const selecShiftPositions = (state) => state.workers.shiftPositions.data;

export default usersSlice.reducer;

export const { setWorkersMasterPage, closeDetailsForm, addNewUser } =
  usersSlice.actions;
