export const FETCH_SUPPLIERS = "FETCH_SUPPLIERS";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_ITEMS = "FETCH_ITEMS";
export const FETCH_OPEN_ORDERS = "FETCH_OPEN_ORDERS";
export const FETCH_CONST_ORDERS = "FETCH_CONST_ORDERS";
export const FETCH_SINGLE_CONST_ORDER = "FETCH_SINGLE_CONST_ORDER";
export const ADD_ORDER = "ADD_ORDER";
export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";
export const SET_CURRENT_CONST_ORDER = "SET_CURRENT_CONST_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const SAVE_ITEM = "SAVE_ITEM";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const FETCH_SUPPLIERS_AND_OPEN = "FETCH_SUPPLIERS_AND_OPEN";
export const EDIT_NOTES = "EDIT_NOTES";
export const GET_SINGLE_ORDER_DATA = "GET_SINGLE_ORDER_DATA";
