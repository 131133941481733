import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";
import { EXCEL_TRANSLATIONS } from "../const";
import ReactTable from "../../../components/tables/ReactTable";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

const SalesCostTable = ({ tableData, cellsPropsById, scroll }) => {
  // ======== HOOKS ========

  const tableRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========
  const {
    excelRowConfig,
    excelColConfig,
    data,
    columns,
    title,
    aggregates,
    sortBy,
  } = tableData;

  // ======== HANDLERS ========

  // ======== CONSTANTS ========
  const tableStyles = {
    table: {
      "& .MuiTableCell-root": {
        fontSize: "1.6rem !important",
      },
    },
  };

  // ======== EFFECTS ========

  //auto scrolling
  useEffect(() => {
    if (scroll) {
      setTimeout(() => {
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [tableData]);

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <div style={{ marginTop: "32px" }} ref={tableRef}>
      <ReactTable
        styles={tableStyles}
        translation_endpoint={EXCEL_TRANSLATIONS}
        sortBy={[{ id: "date", desc: true }]}
        isExportedToExcel
        isPaginate
        {...{
          excelRowConfig,
          title,
          data,
          columns,
          cellsPropsById,
          excelColConfig,
          aggregates,
          sortBy,
        }}
        showFooter
      />
    </div>
  );
};

export default SalesCostTable;
