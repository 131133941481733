import {
  Button,
  CardHeader,
  CircularProgress,
  FormControl,
  // Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { default as Grid } from "@mui/material/Unstable_Grid2";
import Axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { AiOutlinePlus, AiOutlinePlusSquare } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { connect } from "react-redux";
import BackgroundCard from "../../../../components/card/BackgroundCard";
import { useScreenSize } from "../../../../Hooks";
import { setConfirm } from "../../../../redux/actions/confirmAction";
import { setError } from "../../../../redux/actions/errorAction";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderAction";
import CreateFormModel from "./createForms.jsx/CreateFormModel";
import Table from "./table/Table";

export const MAKOR_PROVIDER = "makor";
export const RESTIGO_ATTENDANCE_APP_PROVIDER = "restigo_attendance_app";

let cancelToken;
const AttendanceClocks = ({
  token,
  adminId,
  setError,
  startLoading,
  stopLoading,
  setConfirm,
}) => {
  const { isMobile } = useScreenSize()
  // ============= state ===========
  const [filters, setFilters] = useState({
    status: 1,
    provider: MAKOR_PROVIDER,
  });

  const [attendanceClocks, setAttendanceClocks] = useState([]);
  const [realDataProvider, setRealDataProvider] = useState(MAKOR_PROVIDER);

  const [pagination, setPagintation] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 50,
    from: 1,
    to: 1,
    total: 1,
  });

  const [loading, setLoading] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  // =============== handlers & functions ==============
  const handleFiltersChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters({ ...filters, [name]: value });
  };

  const handleChangePage = (event, newPage) => {
    setPagintation({ ...pagination, current_page: newPage });
  };

  const handleChangeFreeSearchChange = (e) => {
    const value = e.target.value.trim();
    if (value.length < 3) return;
    debounceFetchPointOfSales(value);
  };

  const debounceFetchPointOfSales = useMemo(
    () =>
      debounce((freeSearch) => {
        fetchAttendanceClocks(freeSearch);
      }, 1000),
    [filters.provider, filters.status]
  );

  const updateDataAfterServerCall = (updateAttendanceClock, destroy) => {
    const data = [...attendanceClocks];
    const idx = data.findIndex(
      (clock) => clock.id === updateAttendanceClock.id
    );
    if (idx < 0) return;
    if (destroy) {
      data.splice(idx, 1);
    } else {
      data[idx] = updateAttendanceClock;
    }
    setAttendanceClocks(data);
  };

  //================= server calls ===================

  const fetchAttendanceClocks = async (freeSearch = null) => {
    if (cancelToken) cancelToken.cancel("the request canceled");
    cancelToken = Axios.CancelToken.source();
    const ctoken = cancelToken.token;
    setLoading(true);
    try {
      const response = await Axios.get("admin/attendanceClocks", {
        params: {
          ...filters,
          freeSearch,
          page: pagination.current_page,
          per_page: pagination.per_page,
        },
        headers: { Authorization: `Bearer ${token}` },
        cancelToken: ctoken,
      });
      const { data } = response;
      setAttendanceClocks(data.data);
      delete data.data;
      setPagintation(data);
      setRealDataProvider(filters.provider);
    } catch (err) {
      console.error(err);
      if (!Axios.isCancel(err)) {
        setError(err?.response?.data?.message, "ארעה שגיאה בשרת");
      }
    }
    setLoading(false);
  };

  const updateEnable = async (id, provider, enabled) => {
    startLoading("מעדכן סטטוס...");
    try {
      const response = await Axios.put(
        `admin/attendanceClocks/${provider}/${id}/updateEnable`,
        {
          updated_by: adminId,
          enabled,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const attendanceClock = response.data;
      updateDataAfterServerCall(attendanceClock);
      setConfirm("סטטוס עודכן בהצלחה");
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message, "ארעה שגיאה בשרת");
    }
    stopLoading();
  };

  const destroy = async (id, provider) => {
    startLoading("מוחק...");
    try {
      const response = await Axios.delete(
        `admin/attendanceClocks/${provider}/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const attendanceClock = response.data;
      updateDataAfterServerCall(attendanceClock, true);
      setConfirm("שעון נוכחות נמחק בהצלחה");
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message, "ארעה שגיאה בשרת");
    }
    stopLoading();
  };

  //================= effects ===================

  useEffect(() => {
    fetchAttendanceClocks();
  }, [pagination.current_page, pagination.per_page]);

  return (
    <>
      <BackgroundCard>
        <CardHeader
          title="ניהול שעוני נוכחות"
          action={
            <IconButton
              onClick={() => setShowCreateDialog(true)}
              className="padding-05"
              color="primary"
            >
              <AiOutlinePlusSquare size="1.6em" />
            </IconButton>
          }
        />
      
        <Grid container  spacing={isMobile ? 2 : 5}>
          <Grid  xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>סטטוס חיבור</InputLabel>
              <Select
                label="סטטוס חיבור"
                name="status"
                variant="outlined"
                value={filters.status}
                onChange={handleFiltersChange}
              >
                <MenuItem value={1}>ממשק מחובר</MenuItem>
                <MenuItem value={0}>ממשק לא מחובר</MenuItem>
                <MenuItem value={-99}>הכל</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid  xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>ספק</InputLabel>
              <Select
                label="ספק"
                name="provider"
                value={filters.provider}
                variant="outlined"
                onChange={handleFiltersChange}
              >
                <MenuItem value={MAKOR_PROVIDER}>מקור-טק</MenuItem>
                <MenuItem value={RESTIGO_ATTENDANCE_APP_PROVIDER}>
                  אפליקציית נוכחות רסטיגו
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid  xs={12} md={1}>
            <div
              className="flex-end"
              style={{ height: "100%", alignItems: "center" }}
            >
              <Button
                onClick={() => fetchAttendanceClocks()}
                color="primary"
                variant="contained"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress style={{ color: "white" }} size="2em" />
                ) : (
                  "בצע"
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container >
          <Grid  xs={12} md={3}>
            <TextField
              label="חיפוש סניף/רשת"
              onChange={handleChangeFreeSearchChange}
            />
          </Grid>
        </Grid>
        <Grid container  >
          <Grid  xs={6} md={2}>
            <FormControl fullWidth size="small" style={{ height: "1.rem" }}>
              <InputLabel>מספר שורות בעמוד</InputLabel>
              <Select
                name="status"
                value={pagination.per_page}
                variant="standard"
                onChange={(e) =>
                  setPagintation({ ...pagination, per_page: e.target.value })
                }
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={9999999}>הכל</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      
      
        <Table
          {...{
            attendanceClocks,
            realDataProvider,
            isMobile,
            pagination,
            loading,
            handleChangePage,
            updateDataAfterServerCall,
            updateEnable,
            destroy,
          }}
        />
        <CreateFormModel
          open={showCreateDialog}
          setOpen={setShowCreateDialog}
          fetchAttendanceClocks={fetchAttendanceClocks}
        />
      </BackgroundCard>
    </>
  );
};

const mapStateToProps = (state) => {
  const { token, admin } = state.authAdmin;
  const { isMobile } = state.publicValues;
  return { token, isMobile, adminId: admin.id };
};

export default connect(mapStateToProps, {
  setError,
  startLoading,
  stopLoading,
  setConfirm,
})(AttendanceClocks);
