import { Button } from "@mui/material";
import React, { useState } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { COLOR_RESTIGO_PRIMARY } from "../../../const/colors";
import ErrorMessage from "../ErrorMessage";

const SignaturPad = ({
  height = 100,
  width = 300,
  style,
  label,
  id,
  onChange,
  required,
}) => {
  const canvasRef = useRef(null);
  const [writingMode, setWritingMode] = useState(false);
  const getCursorPosition = useCallback((e) => {
    const { clientX, clientY, target } = e;
    const { x, y } = target.getBoundingClientRect();
    const positionX = clientX - x;
    const positionY = clientY - y;
    return [positionX, positionY];
  }, []);

  const onPointerDown = (e) => {
    e.preventDefault();
    setWritingMode(true);
    const ctx = canvasRef.current.getContext("2d");
    const [positionX, positionY] = getCursorPosition(e);
    ctx.lineWidth = 3;
    ctx.lineJoin = "round";
    ctx.beginPath();
    ctx.moveTo(positionX, positionY);
  };

  const stopDrawing = () => {
    setWritingMode(false);
    if (!onChange) {
      return;
    }
    const imageURL = canvasRef.current.toDataURL();
    onChange(imageURL);
  };

  const onPointerMove = (e) => {
    e.preventDefault();
    if (!writingMode) {
      return;
    }
    const ctx = canvasRef.current.getContext("2d");
    const [positionX, positionY] = getCursorPosition(e);
    ctx.lineTo(positionX, positionY);
    ctx.stroke();
  };

  const clear = (e) => {
    e.preventDefault();
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, width, height);
    onChange && onChange(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: width,
        // maxHeight: height,
      }}
    >
      {!!label && (
        <label
          htmlFor={id}
          style={{ ...(required && { color: COLOR_RESTIGO_PRIMARY }) }}
        >
          {label}
          {required && "*"}
        </label>
      )}
      {/* <div {...{ id }} style={{ backgroundColor: "red" }}> */}
      <canvas
        ref={canvasRef}
        {...{
          height,
          width,
          onPointerDown,
          onPointerMove,
        }}
        onPointerUp={stopDrawing}
        onPointerLeave={stopDrawing}
        style={{
          borderWidth: "1px",
          borderColor: "black",
          borderStyle: "solid",
          touchAction: "none",
          ...style,
        }}
      />
      {/* </div> */}
      <Button onClick={clear} variant="outlined" style={{ minWidth: "150px" }}>
        נקה
      </Button>
    </div>
  );
};

const ControlSignaturePad = ({
  name,
  label,
  rules,
  defaultValue,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      {...{ control, name, rules, ...(!!defaultValue && { defaultValue }) }}
      render={({
        fieldState: { error },
        field: { name, onBlur, onChange, ref, value },
      }) => {
        return (
          <>
            <SignaturPad
              
              
              required={!!rules?.required}
              {...props}
              id={name}
              {...{
                ...(!!error && { style: { borderColor: "red" } }),
                label,
                onChange
              }}
            />
            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        );
      }}
    />
  );
};

export { SignaturPad, ControlSignaturePad };
