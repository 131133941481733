
import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-responsive-modal";
//import Button from "../../../components/buttons/Button";
import ReactTable from "../../../components/tables/ReactTable";
import { IoClose } from "react-icons/io5";
import {

  thousandSeparator,
} from "../../../helpers";
import { ClipLoader } from "react-spinners";
import {

  MenuItem,
  Select ,

} from "@mui/material";
import {
  FaCheck,
  FaPencilAlt,
  FaSave,

} from "react-icons/fa";
import { useScreenSize } from "../../../Hooks";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import Axios from "axios";
import DropdownSelect from "../../../components/dropdown/DropdownSelect";
const GoalsForm = ({ open, data,setData, onClose, setViewToShow,selected_branch, title,token,expenses ,setExpenses,setEditData,fetchBranchGoals,loading,departmentOptions={}}) => {
  //console.log(expenses,data);
  const { isMobile } = useScreenSize();
  const[updated,setUpdated]= useState({});
  const[gFilter,setGfilter]= useState("");
  const [packageToEdit, setPackageToEdit] = useState({
    id: -99,
    name: "פתיחת יעד חדש",
  });
  const getbranch=()=>{
    return selected_branch;
  };
  const setBranchGoals = useMemo(
    () => async (data) => {
      
        const res = await Axios.post(`/newGoals/${getbranch()}/create`, {  
          params: data,
          headers: { Authorization: `Bearer ${token}` },
        });
        //console.log(res);
      
      }
  );
  const UpdateExpenses=()=>{
    let newExpenses = {};
    let setExpense=false;
    for(let ex in expenses){  
      newExpenses[ expenses[ex].id] ={...expenses[ex]};
      let id = expenses[ex].employee_type;
      let res = data.filter( el => el.employee_type_id==id );
      if(res.length>0){
        setExpense=true;
        newExpenses[ expenses[ex].id].nav = (res[0].precent==1) ? res[0].goal:-1 ;
        newExpenses[ expenses[ex].id].navg = (res[0].precent==0) ? res[0].goal:-1 ;
      }
    }
    if(setExpense)
      setExpenses(Object.values(newExpenses));
  };
const getData=useMemo(()=>{
  let dc =[...data];
  let ids={};
  
 
  for(let ex in expenses){  
    //console.log(expenses[ex]);
    let id = expenses[ex].employee_type;
    let res = data.filter( el => el.employee_type_id==id );
    
    // if(!res.length){
    //   console.log(id);
    //   ids[id]={
    //     'precent' :0 ,
    //      'goal' :0,
    //      'branch_id' :selected_branch.id ,
    //      'employee_type_id': id,
    //      'type' :3 ,
    //      'department_name' :expenses[ex].department_name,
         
    //  };
      
    // }
 
    
  }
  for(let i in ids){
    setBranchGoals(ids[i]);
    ids[i].show=false;
    dc.push(ids[i]);
  } 
  //if(setExpense)
    //setExpenses(Object.values(newExpenses));
  //console.log(ids);
  //setEditData(dc);
  //fetchBranchGoals();
  return [...dc];
});
useEffect(() => {
  //UpdateExpenses();
 //getData();
}, [expenses]);
const cleanVal =(v) =>{
  if(typeof v ==="string")
    return v.split("%")[0];
  else return v;
};
const checkUpdated = (v) =>{
  const cloneUp = {...updated};
  if(!(v in updated)){
    cloneUp[v]=false;
    //setUpdated({...cloneUp});
  }
  return updated[v];
};
const ret = {};
if(departmentOptions){
  Object.keys(departmentOptions).forEach(key => {
    ret[departmentOptions[key]] = key;
  });
  departmentOptions={...ret};
  departmentOptions[999]="מצטבר";
}

// const departmentOptions = {
// 999:"מצטבר",
// 0:"אחר",
// 1:"מנהלים",
// 2:"טבחים",
// 3:"מלצרים",
// 5:"מנהלי משמרת",
// 6:"מארחות",
// 7:"ברמנים",
// 8:"שליחים",
// 9:"טלפנים",
// 10:"הנהלת חשבונות",
// 11:"עובדים זרים",
// 12:"קונדיטוריה",
// 13:"מטבח - הכנות",
// 14:"שוטפי כלים",
// 15:"צ'קרים",
// 16:"מנקים",
// 17:"עובדים כלליים",
// 23:"עובדי דלפק",
// 24:"קופאי",
// 25:"עובד ייצור",
// 26:"מלצרי אירועים",
// 27:"מצילים",
// 28:"ראנר",
// 29:"מתלמד",
// 30:"תגבור",
// 31:"אריזה",
// 32:"מאפיה",
// 33:"מאפים",
// 34:"עוגיות",
// 35:"שונות",
// 36:"טאבון",

// };
const checkShow =(row) =>{
  let val = (row.original.precent) ? row.original.goal+"%":row.original.goal;
  //console.log(row);
  //const rid = row.original.id;

  if(!row.original.show)
    return(
      <div>
        {checkUpdated(row.original.id) 
          &&
        
         
          <FaCheck fill="green" 
          alt="יעד עודכן בהצלחה"
          style={{ 
            position: "relative",
            right: "-5px",
            fontSize: "15px"
          }}
          />
        }
          <span>{ (row.original.precent) ? row.original.goal+"%":thousandSeparator(row.original.goal)+"₪"}</span>
        
        </div>
    );
  else return(
        
        <div className="flex-between"style={{justifyContent:" flex-start"}} >
          
           <input id={"goalVal-"+row.original.id} 
           //ref={(input) => { nameInput = input; }} 
           autoFocus
           style={{ width: "35%" }}
           type="text"
           onChange={

            e=>{
              // const id = e.target.id.split("-")[1];
              
              const clone =[...data];
              let son =clone.find(el => el.id==row.original.id);
              const goal = (e.target.value.length===2 && e.target.value[1]==".") ? e.target.value:parseFloat(e.target.value);
              son.goal =goal;
              setData([...clone]);
              //e.focus();
              // console.log("dude",row.original.id);
            }
           }
           
             name="goal" value={ cleanVal(val) } />
            <select
              id={"goal-"+row.original.id} 
              size="small"
              value={row.original.precent}
              name="precent"
              onChange={(e) => {
                // console.log(e);
                // const nba={
                //   'id':e.target.id.split("-")[1],
                //   'precent' :parseInt(e.target.value) ,
                //   'upType':1,
                //   //'goal' :0,
                //   // 'branch_id' :selected_branch.id ,
                //   // 'employee_type_id' :e.target.value,
                //   // 'type' :3 ,
                //   // 'department_name' :departmentOptions[e.target.value],
                // };
                // console.log(nba);
                const clone =[...data];
                let son =clone.find(el => el.id==row.original.id);
                son.precent = parseInt(e.target.value);
                setData([...clone]);
                //updateBranchGoals(nba);
              }
            }
              style={{ width: "44px" }}>
            
              <option   key={"f"+row.original.id} value={0}>
                {"₪"}
              </option>
              <option  key={"t"+row.original.id} value={1} >
                {"%"}
              </option>
          </select>
         
        
        </div>
  );
};
  const columns = 
  [
      {
        Header: "שם המחלקה",
        accessor: "department_name",
      },
      {
        Header: "יעד",
        accessor: "goal",
        Cell: ({row}) => checkShow(row),
        
        
      },
      {
        Header: "עריכה",
        accessor: "edit",
        Cell: ({row}) => {
          
          if(!row.original.show){
            return(
              <ButtonIcon
                  //loading={isLoading}
                  onClick={() => {
                    
                    // console.log(editData,editData[row.original.id]); 
                    // const edata = {...editData};
                    // edata[row.original.id]=true;
                    const edata = [...data];
                    const member = edata.find(({ id }) => id === row.original.id);
                    //console.log(member)
                    if(member){
                      member["show"] = true;
                      setEditData(edata);
                    }
                    

                  }
                }
                  className="btn--hover__confirm"
                  icon={FaPencilAlt}
                  alt="עריכה"
                />
            )
          }
            
          else
            return(
              <span>
                <ButtonIcon
            onClick={
              e => {
                if(row.original?.id)
                  updateBranchGoals(row.original);
                else{
                  setBranchGoals(row.original);
                  fetchBranchGoals();
                }
                  
              }
              
              }
            className="btn--hover__abort"
            icon={FaSave}
            alt=" שמירה"
          />
            <ButtonIcon
            onClick={
              e => {
                // console.log(editData,editData[row.original.id]); 
                // const edata = {...editData};
                // edata[row.original.id].show=false;
                // setEditData(edata);
                //console.log(e);

                // const nba={
                //   'id':e.target.id.split("-")[1],
                //   'goal' :parseInt(e.target.value) ,
                const clone ={...row.original};
                //let son =clone.find(el => el.id==row.original.id);
                if(clone.employee_type_id==999 && clone.type==6){
                  
                  clone.employee_type_id=1;
                  
                }
                clone.type = -3;
                clone.upType = 1;
                
                updateBranchGoals(clone);
                  
                // };
                // updateBranchGoals(nba);
              }
              
              }
            className="btn--hover__abort"
            icon={IoClose}
            alt=" מחיקה"
          />
              </span>
            )
            
        },
        
        
      },
    ]
  ;


  const updateBranchGoals = useMemo(
    () => async (data) => {
        //console.log(data);
        if(data.employee_type_id==999 && data.type==6){
          
          data.employee_type_id=1;
          
        }
        const res = await Axios.post(`/newGoals/${data.id}/update`, {  
          params: data,
          headers: { Authorization: `Bearer ${token}` },
        });
        //console.log(res);
        
        const cloneUp = {...updated};
        cloneUp[data.id]=true;
        setUpdated({...cloneUp});
        fetchBranchGoals();
      }
  );
  return (
    <Modal
      animationDuration={200}
      styles={(isMobile)?{

      }:{
        
        modal: {
          padding: "3.5rem",
          maxWidth: "fit-content",
          minWidth: "60rem",
         
        },
        overlay:{
          zIndex: 100 ,
        }
      }}
      open={open}
      center
      onClose={onClose}
    >
      <div className="flex-column-center">
        <div className="fluid" style={{width:"76%",margin:"auto"}}>
          <h2>ניהול יעדים</h2>
          
           <DropdownSelect
              selected={packageToEdit}
              setSelected={setPackageToEdit}
              style={{ width: "100%", margin: "auto",background:"white" } }
              onChange={e => 
                {
                  console.log(e);
                  const nba={
                          'precent' :0 ,
                          'goal' :0,
                          'branch_id' :selected_branch.id ,
                          'employee_type_id' :(e.id!=="999") ? e.id:1,
                          'type' :(e.id!=="999") ? 3:6 ,
                          'department_name' :e.name,
                          'show':true,
                  };
                  const clone =[...data];
                  clone.unshift(nba);
                  setData([...clone]);
                  //setBranchGoals(nba);
                  //fetchBranchGoals();
                }  
              }
              options={Object.keys(departmentOptions).map(
                (x) => {
                  return{id:x,name:departmentOptions[x]};
                  }
                )}
             
            />
        </div>
        
        {loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ):(
          <ReactTable
          {...{
            cellsPropsById: (id) => {
              if (["id"].includes(id)) {
                return {
                  setViewToShow,
                };
              }
            },
            styles: {
              wrapper: { maxHeight: "calc(100vh - 10rem)",minWidth:"400px" },
              container: { maxHeight: "calc(100vh - 23.4rem)",minWidth:"400px" },
            },
            //translation_endpoint: "newGoals",
            title,
            columns,
            data:getData,
          }}
          />
        )}
        
      </div>
    </Modal>
  );
};
// const mapStateToProps = (state) => {
//   const { token } = state.token;
//   const { selected_branch } = state.selected_branch;

//   return { token, selected_branch  };
// };

export default GoalsForm;
