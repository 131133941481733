import produce from "immer";
import { isArray } from "lodash";
import {
  ADD_RELATION,
  INITIALIZE_STATE,
  LOAD_BANKS,
  LOAD_DEFAULT_EMAILS,
  LOAD_EMPLOYEE_DATA,
  SET_EXTRA_FILES,
  UPDATE_FIELD,
  UPDATE_RELATION_ARRAY,
  UPDATE_RELATION_ID,
} from "../actions/form101Action/type";
import { BANK_DETAILS_EXISTS } from "../../pages/Form101/FormFields/consts";

const INITIAL_STATE = {
  id: null,
  taxYear: new Date().getFullYear(),
  //sectionA
  employerName: null,
  employerAddress: null,
  employerPhone: null,
  deductionNum: null,
  //sectionB
  employeeFirstName: null,
  employeeLastName: null,
  identifier: null,
  identifierNum: null,
  passportNum: null,
  passportCountry: null,
  identifierFile: null,
  extra_files: [],
  armyFile: null,
  isSoldier: null,
  dob: null, //תאריך לידה
  doi: null, //תאריך עלייה
  city: null,
  street: null,
  houseNum: null,
  zipCode: null,
  phoneNumber: null,
  dialPhoneNumber: null,
  email: null,
  gender: null,
  maritalStatus: null, //familyStatus
  maritalStatusFile: null,
  citizen: null, //israel citizen
  mok: null, //kibutts member
  HMO: null,
  HMOName: null,
  //sectionC
  kids: [],
  //sectionD
  income: null,
  hasScholarship: false,
  hasAllowance: false,
  dow: null, //date of start work
  //sectionE
  otherIncome: null,
  //sectionE - if other income exists
  otherIncomeExtraMonth: false,
  otherIncomeExtraAnother: false,
  otherIncomeExtraPartial: false,
  otherIncomeExtraDaily: false,
  otherIncomeExtraAllowance: false,
  credits: null,
  allowanceEdfund: false,
  allowancePension: false,
  //sectionF
  spouseFirstName: null,
  spouseLastName: null,
  identificationBy: null,
  spouseIdentifierNum: null,
  spousePassportNum: null,
  spousePassportCountry: null,
  spouseDob: null, //תאריך לידה
  spouseDoi: null, //תאריך עלייה
  isSpouseIncome: null,
  spouseIncomeAllowance: null,
  spouseIncomeOther: null,
  //sectionG - hard coded text.
  //sectionH
  isCitizen: false,
  //isDisability - with inputs
  isDisability: false,
  isDisabilityFile: null,
  //
  //isQualifySettlement - with inputs
  isQualifySettlement: false,
  isQualifySettlementDate: null,
  settlement: null,
  settlementFile: null,
  //
  //isNewcomer - withInputs
  isNewcomer: false,
  isNewcomerDate: null,
  isNewcomerFile: null,
  //
  //isSpouse-with Inputs
  isSpouse: false,
  isSpouseFile: null,
  //
  isSingleParent: false,
  //isMyCustody - with inputs
  isMyCustody: false,
  //isMyCustody/isMykids - inputs
  bornNum: null,
  custody5YearNum: null,
  custody12YearNum: null,
  custody17YearNum: null,
  custody18YearNum: null,
  //
  //isMyKids - with inputs
  isMyKids: false,
  //
  //isSingleParentCustody - with Inputs
  isSingleParentCustody: false,
  //
  //isNotCustody - with inputs
  isNotCustody: false,
  isNotCustodyFile: null,
  //
  //isDisabilityKids - with inputs
  isDisabilityKids: false,
  numOfDisabilityKids: null,
  isDisabilityKidsFile: null,
  //
  //isAlimony - with inputs
  isAlimony: false,
  //
  isSixteen: false,
  //isDischargedSoldier - with inputs
  isDischargedSoldier: false,
  dos: null,
  doe: null,
  releasePaperFile: null,
  //
  //isFinishDegree - with inputs
  isFinishDegree: false,
  isFinishDegreeFile: null,
  //

  //SectionI
  taxCoordination: null,
  askReason: 0,
  //askReason = 0
  noIncomeFile: null,
  //askReason = 1
  extra_incomes: [],
  //askReason = 2
  okAssessorFile: null,

  //SectionL
  signature: null,
  //attachments
  emails: [],
  bank_details_exists: null,
  bankName: null,
  branchNum: null,
  account: null,
  signed: null,
  bankDetailsFile: null,
};

export default produce((state, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_DEFAULT_EMAILS:
      state.emails = payload;
      return;
    case INITIALIZE_STATE:
      return (state = { ...state, ...payload });

    case UPDATE_FIELD:
      if (!!payload.index) {
        if (payload.field) {
          state[payload.name][payload.index][payload.field] = payload.value;
        } else {
          state[payload.name][payload.index] = payload.value;
        }
      } else {
        state[payload.name] = payload.value;
      }
      break;
    case SET_EXTRA_FILES:
      if (payload.filter) {
        state.extra_files = state.extra_files.filter(
          (file) => file.id !== payload.id
        );
      } else {
        state.extra_files.push(payload.value);
      }
      break;
    case ADD_RELATION:
      state[payload.name].push(payload.fields);
      break;
    case UPDATE_RELATION_ID:
      state[payload.name][state[payload.name].length - 1].id = payload.id;
      break;
    case UPDATE_RELATION_ARRAY:
      state[payload.name] = payload.value;
      break;
    default:
      break;
  }
}, INITIAL_STATE);
