import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";

// ======== ICONS ========

// ======== CONSTANTS ========
import {
  requiredFieldError,
  mobileNumberValidation,
  IDValidation,
} from "../../private/services/validations";

// ======== COMPONENTS ========
import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import FormCB from "../../../components/Forms/inputs/FormCB";
import InputAdornment from "@mui/material/InputAdornment";
import { stringFilterDigits } from "../../../helpers";

const OtpInputsForm = () => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();
  const { control, watch, setValue } = useFormContext();
  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========
  const mobileNumber = watch("mobileNumber");
  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Grid container direction="column">
      <Grid item>
        <FormTextField
          fullWidth
          {...{
            control,
            handleChange: ({ name, value }) => {
              setValue(name, stringFilterDigits(value));
            },
            label: "מספר טלפון נייד",
            name: "mobileNumber",
            rules: {
              required: requiredFieldError,
              validate: (value) => mobileNumberValidation(value),
            },
          }}
        />
      </Grid>
      <Grid item>
        <FormTextField
          fullWidth
          {...{
            control,
            handleChange: ({ name, value }) => {
              setValue(name, stringFilterDigits(value));
            },
            label: "ת.ז.",
            name: "identifier",
            id: "identifier",
            rules: {
              required: requiredFieldError,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OtpInputsForm;
