import { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import ButtonIcon from "../../../../components/buttons/ButtonIcon";
import BtnTooltip from "../../../../components/tooltips/BtnTooltip";
import SendSms from "../../../schedule/manager/table/models/SendSms";

export const work_force_columns = [
  {
    Header: "שם עובד",
    accessor: "full_name",
  },
  {
    Header: "מ. עובד",
    accessor: "number",

    Footer: (self) => self.rows.length,
  },
  {
    Header: "מ. במערכת שכר",
    accessor: "micpal_number",
  },
  {
    Header: "תפקיד",
    accessor: "job_title",
  },
  {
    Header: "הרשאה",
    accessor: "privilege_title",
  },
  {
    Header: "תעודת זהות",
    accessor: "identifier",
  },
  {
    Header: "תאריך לידה",
    accessor: "hebrew_date_of_birth",
  },
  {
    Header: "גיל",
    accessor: "age",
  },
  {
    Header: "טלפון",
    accessor: "mobileNumber",
  },
  {
    Header: "כתובת",
    accessor: "address",
    Cell: ({ row }) => (
      <div>
        {(row.original.address ?? "") +
          (row.original.address && row.original.city ? ", " : " ") +
          (row.original.city ?? "")}
      </div>
    ),
  },
  {
    Header: "מוגדר נסיעות",
    accessor: "is_travel_cost",
  },
  {
    Header: "סניף",
    accessor: "branch_name",
  },
  {
    Header: "מוגדר שכר",
    accessor: "is_salary",
  },
  {
    Header: "שכר",
    accessor: "salary",
    Cell: ({ row }) => {
      const { salary } = row.original;
      return (
        <BtnTooltip placement="right" maxWidth={"50vw"} text={salary}>
          <div style={{ maxWidth: "15rem" }} className="limit-text-three-dots">
            {salary}
          </div>
        </BtnTooltip>
      );
    },
  },
  {
    Header: "כתובת מייל",
    accessor: "email",
  },
  {
    Header: "תאריך הגשה אחרון",
    accessor: "last_submission",
  },
  {
    Header: "טופס 101",
    accessor: "form_101_date_time",
  },
  {
    Header: "הסכם עבודה",
    accessor: "work_agreement_date_time",
  },
  {
    Header: "תקנון מניעת הטרדה מינית",
    accessor: "sexual_harassment_date_time",
  },
  {
    Header: "אישור נגישות",
    accessor: "employee_notification_date_time",
  },
  {
    Header: "תאריך הקמה",
    accessor: "hebrew_create_date",
  },
  {
    Header: "תאריך דיווח נוכחות אחרון",
    accessor: "last_working_date",
  },
  {
    Header: "פרטי בנק",
    accessor: "bank_details",
  },
  {
    Header: "",
    accessor: "buttons",
    Cell: ({ row }) => {
      const { id, full_name, branch_name } = row.original;
      const [openSms, setOpenSms] = useState(false);
      return (
        <>
          <SendSms
            event={{
              employee: {
                id: id,
                name: full_name,
              },
            }}
            open={openSms}
            setOpen={setOpenSms}
            fromEmployeeModal={true}
            branch_name={branch_name}
          />
          <ButtonIcon onClick={() => setOpenSms(true)} icon={FaEnvelope} />
        </>
      );
    },
  },
];

export const work_force_hidden = [
  "micpal_number",
  // "job_title",
  // "privilege_title",
  // "identifier",
  // "hebrew_date_of_birth",
  "age",
  "mobileNumber",
  // "is_travel_cost",
  "is_salary",
  // "email",
  "last_submission",
  // "form_101_date_time",
  // "work_agreement_date_time",
  // "sexual_harassment_date_time",
  // "employee_notification_date_time",
  // "hebrew_create_date",
  "last_working_date",
  // "number",
  // "salary",
  "address",
  "bank_details",
];
