import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import moment from "moment";
// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const WorkTerminationFormNew = (props) => {
  // ======== HOOKS ========

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            לכבוד <br></br>
            <span>
              {props.employee_name ? props.employee_name.split(",")[0] : ""},
            </span>
            <span> ת.ז {props.identifier}</span>
            <br></br>
            <br></br>
          </p>
          <p>
            תאריך : {moment(new Date()).format("DD.MM.YYYY")} <span> </span>{" "}
          </p>
        </div>
        <p>
          הנדון:{" "}
          <strong>
            <u>הודעה על סיום העסקתך בחברה - {props.company_name}</u>
          </strong>
        </p>{" "}
        <br></br>
        בהמשך לשיחת השימוע שנערכה לך בתאריך{" "}
        {props.hearingDate
          ? moment(props.hearingDate).format("DD-MM-YYYY")
          : ""}{" "}
        החליטה החברה לסיים העסקתך עקב תום חוזה
        <p>
          ניתנת לך בזאת ימי הודעה מוקדמת לפיטורין בהתאם לדין, כך שיחסי
          עובד-מעסיק יגיעו לסיומם ביום{" "}
          {props.endNoticeDate
            ? moment(props.endNoticeDate).format("DD-MM-YYYY")
            : ""}{" "}
          הנך נדרש לעבוד בחברה בתקופת ההודעה המוקדמת, והחברה תשלם לך את שכרך
          עבור תקופה זו.
        </p>
        <p>
          ככל שלא תופיע לעבודה החברה לא תשלם לך שכר עבור תקופת ההודעה המוקדמת.
        </p>
        <p>גמר חשבון יבוצע עם תשלום שכרך האחרון.</p>
        <p>
          הרינו לאשר כי הועסקת בחברה מתאריך{" "}
          {props.employeeCreatedAt
            ? moment(props.employeeCreatedAt).format("DD-MM-YYYY")
            : ""}{" "}
          עד תאריך:{" "}
          {props.terminationDate
            ? moment(props.terminationDate).format("DD-MM-YYYY")
            : ""}
        </p>
        <p>
          הנך מתבקש להשיב לחברה כל רכוש ו/או מסמך של החברה המצויים ברשותך ולא
          לשמור על עותק ממנו.
        </p>
        <p>אנו מאחלים לך הצלחה בהמשך דרכך.</p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <div>
            <p>בכבוד רב,</p>
          </div>
          <div>
            <p> {props.user.full_name}</p>
          </div>
          <div>{props.company_name}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default WorkTerminationFormNew;
