import React, { useEffect, useState } from "react";

const Pagination = ({
  previousPage,
  gotoPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  pageIndex,
  pageCount,
  style,
}) => {
  const [visiblePages, setVisiblePages] = useState([]);

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  const changePage = (page) => {
    const activePage = pageIndex + 1;

    if (page === activePage) {
      return;
    }

    const newVisiblePages = getVisiblePages(page, pageCount);

    setVisiblePages(filterPages(newVisiblePages, pageCount));
  };

  useEffect(() => {
    setVisiblePages(getVisiblePages(pageIndex, pageCount));
  }, [pageCount, pageIndex]);

  return (
    <div style={style} className="pagination-wrapper">
      <div className="pagination">
        <a
          className="prev page-numbers"
          onClick={() => {
            if (pageIndex - 1 >= 0) {
              changePage(pageIndex - 1);
              previousPage();
            }
          }}
          disabled={!canPreviousPage}
        >
          <i className="fa fa-angle-left" />
        </a>
        {visiblePages.map((page, index, array) => {
          return pageIndex === page - 1 ? (
            <span
              key={page}
              aria-current="page"
              className="page-numbers current"
            >
              {array[index - 1] + 2 < page ? `${page}...` : page}
            </span>
          ) : (
            <a
              key={page}
              className="page-numbers"
              onClick={() => {
                gotoPage(page - 1);
              }}
            >
              {array[index - 1] + 2 < page ? `${page}...` : page}
            </a>
          );
        })}
        <a
          className="next page-numbers"
          onClick={() => {
            changePage(pageIndex + 1);
            nextPage();
          }}
          disabled={!canNextPage}
        >
          <i className="fa fa-angle-right" />
        </a>
      </div>
    </div>
  );
};

export default Pagination