import Axios from "axios";
import adminAuth from "../../../config/adminAuth";
// import { BASE_URL } from "../../../config/auth";
import { SET_ERROR } from "../errorAction/types";
import { LOGIN, LOGOUT } from "../loginAction/types";
import { ADMIN_LOGIN, LOGOUT_ADMIN } from "./types";
const BASE_URL = process.env.REACT_APP_URL;
export const adminLogin =
  ({ email, password }, setOTPModal) =>
  async (dispatch) => {
    try {
      const response = await adminAuth.login(email, password);

      if (response.data.localAutoConnect) {
        const token = response.data.login.auth_token;
        const admin = response.data.login.data;
        dispatch({ type: ADMIN_LOGIN, payload: { token, admin } });
        return;
      }

      setOTPModal({ ...response.data, email, password });
    } catch (e) {
      console.error(e);
      switch (e.response?.status) {
        case 429:
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת אבטחה`,
            error:
              "חשבונך נחסם עקב ריבוי נסיונות נסה שוב מאוחר יותר. \r\n ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          });
          break;
        case 409:
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת אבטחה`,
            error:
              "מספר טלפון לא תקין. \r\n ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          });
          break;

        default:
          break;
      }
    }
  };

export const restoreAdminPassword =
  ({ identifier, phone_number, email }, setOTPModal) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("admin/restorepass", {
        identifier,
        phone_number,
        email,
      });

      setOTPModal({ ...response.data.data });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת אבטחה`,
            error: "משתמש לא מורשה. \r\n ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          });
        } else if (e.response.status === 429) {
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת אבטחה`,
            error:
              "חשבונך נחסם עקב ריבוי נסיונות נסה שוב מאוחר יותר. \r\n ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          });
        }
    }
  };

export const restoreAdminOTP =
  (login_admin_web_id, { password, code }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("admin/OTPrestore", {
        login_admin_web_id,
        password,
        code,
      });

      const token = response.data.auth_token;
      const admin = response.data.data;
      dispatch({ type: ADMIN_LOGIN, payload: { token, admin } });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת אבטחה`,
            error: "קוד אימות לא תואם",
            timeout: true,
          });
        }
    }
  };

export const adminLoginOTP = (email, password, code) => async (dispatch) => {
  try {
    const response = await Axios.post("admin/jwtOTPlogin", {
      email,
      password,
      code,
    });

    const token = response.data.auth_token;
    const admin = response.data.data;
    dispatch({ type: ADMIN_LOGIN, payload: { token, admin } });
  } catch (e) {
    console.error(e);
    if (e.response)
      if (e.response.status === 401) {
        dispatch({
          type: SET_ERROR,
          errorTitle: `שגיאת אבטחה`,
          error: "קוד אימות לא תואם",
        });
      }
  }
};

export const logAsUser =
  (user_id, { event, description }) =>
  async (dispatch, getState) => {
    try {
      // dispatch({
      //   type: LOGOUT,
      // });

      const { data } = await Axios.post(
        "admin/loguser",
        {
          user_id,
          admin_id: getState().authAdmin.admin.login_admin_web_id,
          description,
          event: event.value,
        },
        {
          headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
        }
      );
      const user = data.data;
      const token = data.auth_token;

      dispatch({
        type: LOGIN,
        payload: { token, user },
      });

      window.open(`${BASE_URL}/logout`, "_blank");
    } catch (e) {
      console.error(e);
    }
  };

//Logout
export const logoutAdmin = () => async (dispatch) => {
  dispatch({ type: LOGOUT_ADMIN });
};
