import { FaLink } from "react-icons/fa";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import { PriceCell } from "../../../components/tables/components/PriceFooter";

import { VAT } from "../../orders/manageOrders/constants";
import { BLUE_COLOR } from "../../products/const";

export const getLinkModalItemsData = (
  items, // raw data
  selectedCategories // local state
) => {
  return items
    .filter(
      (item) =>
        selectedCategories.length === 0 ||
        selectedCategories.includes(item.category_id)
    )
    .map(({ id, name, supplier_name, unit_price_with_vat, part_number }) => ({
      id,
      name,
      supplier_name,
      part_number,
      unit_price_with_vat,
    }));
};

export const getLinkModalProductsData = (
  products, // raw data
  selectedCategories // local state
) => {
  return products
    .filter(
      (product) =>
        selectedCategories.length === 0 ||
        selectedCategories.includes(product.category_id)
    )
    .map(({ id, name, worth_without_VAT }) => ({
      id,
      name,
      price_with_vat: worth_without_VAT * VAT,
    }));
};

export const linkModaItemsTableColumns = [
  {
    Header: "שם",
    accessor: "name",
    Cell: ({ value }) => {
      return (
        <span style={{ maxWidth: "150px", whiteSpace: true }}>{value}</span>
      );
    },
  },
  {
    Header: "מק״ט",
    accessor: "part_number",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "מחיר יח׳ כולל מע״מ",
    accessor: "unit_price_with_vat",
    Cell: (props) => PriceCell(props, 1, false),
  },
  {
    Header: "קישור מוצר",
    accessor: "actions",
    Cell: ({ setSelectedLink, row }) => (
      <span className="flex-around-center">
        <ButtonIcon
          onClick={() => setSelectedLink(row.original)}
          className="btn--normal"
          style={{ color: BLUE_COLOR }}
          icon={FaLink}
          alt="קשר"
        />
      </span>
    ),
  },
];

export const linkModaProductsTableColumns = [
  {
    Header: "שם",
    accessor: "name",
    filter: "fuzzyText",
    Cell: ({ value }) => {
      return (
        <span style={{ maxWidth: "150px", whiteSpace: true }}>{value}</span>
      );
    },
  },
  {
    Header: "מחיר כולל מע״מ",
    accessor: "price_with_vat",
    filter: "fuzzyText",
    Cell: (props) => PriceCell(props, 1, false),
  },
  {
    Header: "קישור מוצר",
    accessor: "actions",
    Cell: ({ setSelectedLink, row }) => (
      <span className="flex-around-center">
        <ButtonIcon
          onClick={() => setSelectedLink(row.original)}
          className="btn--normal"
          style={{ color: BLUE_COLOR }}
          icon={FaLink}
          alt="קשר"
        />
      </span>
    ),
  },
];
