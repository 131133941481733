import {
  FETCH_EMPLOYEE_TYPES,
  FETCH_REQUIRED_PROCEDURES,
} from "../actions/generalAction/types";

const INITIAL_STATE = {
  employeeTypes: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_EMPLOYEE_TYPES:
      return { ...state, employeeTypes: [...payload] };
    case FETCH_REQUIRED_PROCEDURES:
      const { requiredProcedures, requiredProceduresTypes } = payload;
      return { ...state, requiredProcedures, requiredProceduresTypes };
    default:
      return state;
  }
};
