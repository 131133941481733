import { FaPencilAlt, FaSave, FaTrash } from "react-icons/fa";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import { IoClose } from "react-icons/io5";
import { AiOutlineClockCircle, AiOutlinePercentage } from "react-icons/ai";
import { MdDone } from "react-icons/md";
import { BiErrorCircle, BiShekel } from "react-icons/bi";
import BtnTooltip from "../../../components/tooltips/BtnTooltip";
import DropdownSelect from "../../../components/dropdown/DropdownSelect";
import {
  Autocomplete,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { PRECENT_SIGN, SHEKEL_SIGN } from "../../../const";
import { fixPercentage, fixPrice, fixPricePrecent } from "../../../helpers";

export const CategoryGoalsStatus = {
  TYPE_PENDING: 0,
  TYPE_SUCCESS: 1,
  TYPE_ERROR: 2,
};

export const TYPE_SHEKEL = 1;
export const TYPE_PERCENT = 2;

function addCommas(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const renderStatus = {
  1: (
    <BtnTooltip text={"נשמר בהצלחה"}>
      <MdDone />
    </BtnTooltip>
  ),
  2: (
    <BtnTooltip text={"לא נשמר"}>
      <BiErrorCircle />
    </BtnTooltip>
  ),
};
export const columns = [
  {
    Header: "שם קטגוריה",
    accessor: "category_name",
    Cell: ({ value, row, categories, goals, updateGoalField }) => {
      if (row.original.show) {
        return (
          <Autocomplete
            sx={{ width: 150 }}
            // getOptionLabel={(option) => option.label}
            getOptionDisabled={(option) =>
              goals.some((category) => option.value === category.category_id)
            }
            options={categories}
            value={categories.find((category) => category.label === value)}
            onChange={(e, inputValue) => {
              const categoryIndex = goals.findIndex(
                (category) => category.category_id === row.original.category_id
              );
              let category = { ...goals[categoryIndex] };

              category.category_name = inputValue.label;
              category.category_id = inputValue.value;
              updateGoalField({ index: categoryIndex, category });
            }}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                //   value={value}
                label="בחר קטגוריה"
                size="small"
              />
            )}
          />
        );
      } else {
        return <span>{value}</span>;
      }
    },
  },
  {
    Header: "יעד",
    accessor: "goal",
    Cell: ({ value, row, updateGoalField, goals }) => {
      if (!row.original.show) {
        if (!value) {
          return "";
        }
        if (row.original.goal_type === TYPE_SHEKEL) {
          return <span>{`${fixPrice(value)}`}</span>;
        } else {
          return <span>{`${fixPricePrecent(value)}`}</span>;
        }
      } else {
        return (
          <TextField
            onChange={(e) => {
              let input = e.target.value;
              // Remove commas and any non-digit characters
              const sanitizedInput = input.replace(/[^\d]/g, "");

              // Check if the sanitized input is not empty
              if (sanitizedInput !== "") {
                // Convert the sanitized input to a number
                const numberValue = +sanitizedInput;

                // Find the category index
                const categoryIndex = goals.findIndex(
                  (category) =>
                    category.category_id === row.original.category_id
                );

                // Update the category with the new goal
                let category = { ...goals[categoryIndex] };
                category.goal = numberValue;
                updateGoalField({ index: categoryIndex, category });
              } else {
                // Set the goal to 0 if the input is empty
                const categoryIndex = goals.findIndex(
                  (category) =>
                    category.category_id === row.original.category_id
                );
                let category = { ...goals[categoryIndex] };
                category.goal = 0;
                updateGoalField({ index: categoryIndex, category });
              }
            }}
            size={"small"}
            value={addCommas(value)} // Format value with commas
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { paddingLeft: 0 },
              endAdornment: (
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={row.original.goal_type}
                  onChange={(e, newAlignment) => {
                    if (newAlignment !== null) {
                      const categoryIndex = goals.findIndex(
                        (category) =>
                          category.category_id === row.original.category_id
                      );
                      let category = { ...goals[categoryIndex] };
                      category.goal_type = newAlignment;
                      updateGoalField({ index: categoryIndex, category });
                    }
                  }}
                >
                  <ToggleButton value={1}>
                    <BiShekel size={20} />
                  </ToggleButton>
                  <ToggleButton value={2}>
                    <AiOutlinePercentage size={20} />
                  </ToggleButton>
                </ToggleButtonGroup>
              ),
            }}
          />
        );
      }
    },
  },
  {
    Header: "סטטוס",
    accessor: "status",
    Cell: ({ value }) => {
      return <span>{renderStatus[value]}</span>;
    },
  },
  {
    Header: "עריכה",
    accessor: "edit",
    Cell: ({
      row,
      updateGoalsArray,
      goals,
      updateCategoryGoalAsync,
      handleDeleteGoal,
      isSavingGoal,
    }) => {
      if (!row.original.show) {
        return (
          <>
            <ButtonIcon
              //loading={isLoading}
              onClick={() => {
                updateGoalsArray(row.original, true);
              }}
              className="btn--hover__confirm"
              icon={FaPencilAlt}
              alt="עריכה"
            />
            <ButtonIcon
              //loading={isLoading}
              onClick={() => {
                handleDeleteGoal(row.original);
              }}
              style={{ color: "red" }}
              className="btn--hover__confirm"
              icon={FaTrash}
              alt="מחיקה"
            />
          </>
        );
      } else
        return (
          <span>
            <ButtonIcon
              onClick={(e) => {
                const categoryIndex = goals.findIndex(
                  (category) =>
                    category.category_id === row.original.category_id
                );
                const category = { ...goals[categoryIndex] };

                updateCategoryGoalAsync({ category, categoryIndex });
              }}
              disabled={isSavingGoal}
              className="btn--hover__abort"
              icon={FaSave}
              alt=" שמירה"
            />
            <ButtonIcon
              onClick={(e) => {
                updateGoalsArray(row.original, false);
              }}
              className="btn--hover__abort"
              icon={IoClose}
              alt="יציאה ממצב עריכה"
            />
          </span>
        );
    },
  },
];
