import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import {
  getAllActiveWorkers,
  employeeIncludedTypesURI,
} from "../../services/constants";

const ALL_STATUSES = 2;

const initialState = {
  fields: {
    branchIds: [],
    status: [ALL_STATUSES],
    positions: [],
    privilegesHierarchy: [],
    selectedOther: null,
    workerIds: [],
  },
  allWorkers: {
    data: [],
    isLoading: false,
    thunkStatus: "idle",
  },
  allPositions: {
    data: [],
    isLoading: false,
    thunkStatus: "idle",
  },
  error: null,
};

export const workersFilterSlice = createSlice({
  name: "workersFilter",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { key, value } = action.payload;
      state.fields[key] = value;
    },
    resetFilter: (state, action) => {
      state.fields = initialState.fields;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActiveWorkers.pending, (state, action) => {
        state.allWorkers.isLoading = true;
        state.allWorkers.thunkStatus = "loading";
      })
      .addCase(fetchActiveWorkers.rejected, (state, action) => {
        state.allWorkers.isLoading = false;
        state.allWorkers.thunkStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchActiveWorkers.fulfilled, (state, action) => {
        if (!action.payload) {
          state.allWorkers.isLoading = false;
          state.allWorkers.thunkStatus = "failed";
          return;
        }
        state.allWorkers.thunkStatus = "succeeded";
        state.allWorkers.data = action.payload;
        state.allWorkers.isLoading = false;
      })
      .addCase(fetchEmployeeTypes.pending, (state, action) => {
        state.allPositions.isLoading = true;
        state.allPositions.thunkStatus = "loading";
      })
      .addCase(fetchEmployeeTypes.rejected, (state, action) => {
        state.allPositions.isLoading = false;
        state.allPositions.thunkStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchEmployeeTypes.fulfilled, (state, action) => {
        if (!action.payload) {
          state.allPositions.isLoading = false;
          state.allPositions.thunkStatus = "failed";
          return;
        }
        state.allPositions.thunkStatus = "succeeded";
        state.allPositions.data = action.payload;
        state.allPositions.isLoading = false;
      });
  },
});

export const fetchActiveWorkers = createAsyncThunk(
  "workers/fetchActiveWorkers",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    try {
      const res = await Axios.get(getAllActiveWorkers, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const fetchEmployeeTypes = createAsyncThunk(
  "workers/fetchEmployeeTypes",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    try {
      const res = await Axios.get(employeeIncludedTypesURI, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

export const selectFields = (state) => state.workersFilter.fields;
export const selectAllWorkers = (state) => state.workersFilter.allWorkers.data;
export const selectAllPositions = (state) =>
  state.workersFilter.allPositions.data;

export default workersFilterSlice.reducer;

export const { setFilter, resetFilter } = workersFilterSlice.actions;
