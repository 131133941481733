import { IconButton } from "@mui/material";
import Tooltip from "rc-tooltip";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useScreenSize } from "../../../../Hooks";
import iconImg from "../../../../images/latitude_longitude_explanation.png";

const FindCoordinatesExplanation = ({}) => {
  const { isMobile } = useScreenSize()
  return (
    <Tooltip
      trigger={["hover", "click"]}
      placement="bottom"
      overlayInnerStyle={{
        direction: "rtl",
        // maxWidth,
        textAlign: "right",
      }}
      arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      overlay={
        <div>
          <p>
            למציאת קווי אורך ורוחב יש להיכנס לgoogle maps ולהקיש על מיקום הסניף
            במפה
          </p>
          <br />
          <img
            alt=""
            style={{ maxWidth: isMobile ? "300px" : "400px" }}
            src={iconImg}
          />
        </div>
      }
    >
      <IconButton className="padding-0">
        <AiFillQuestionCircle />
      </IconButton>
    </Tooltip>
  );
};

export default FindCoordinatesExplanation;
