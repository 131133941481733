import React from 'react';
import { createStore } from './createStore';

export const store = createStore();

let storeContext = React.createContext(store);

export const StoreProvider = ({ externalStores, children }) => {
  storeContext = React.createContext(externalStores || store);

  return <storeContext.Provider value={externalStores || store}>{children}</storeContext.Provider>;
};

export const useStore = () => {
  const storeInstance = React.useContext(storeContext);
 
  window.alex = storeInstance;
  if (!storeInstance) {
    throw new Error('You have forgotten to use StoreProvider, go fish');
  }
  return storeInstance;
};
