// Login

import React from "react";
import { connect } from "react-redux";
import { adminLogin } from "../redux/actions/AdminLoginAction";
import { Field, reduxForm } from "redux-form";
import LoginButton from "../components/buttons/LoginButton";
import { I18n } from "react-redux-i18n";
import Button from "../components/buttons/Button";
import { getClassDirection } from "../helpers";
import { Link } from "react-router-dom";
import OTPModal from "./OTPModal";
import logo from "../images/Logo_Resigo_pat.png";

class LoginAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { OTPModal: null };
  }

  renderError = ({ error, touched }) => {
    if (touched && error)
      return (
        <p>
          * <span className="error-msg">{error}</span>{" "}
        </p>
      );
  };

  //called in <Field>(redux-form tag) and render the input by the props
  //input is a field in the <Field> tag that handle the input behind the scenes
  renderInput = ({ type, placeholder, input, meta, className }) => {
    return (
      <div className={className}>
        <input type={type} placeholder={placeholder} {...input} />
        {this.renderError(meta)}
      </div>
    );
  };

  setOTPModal = (OTPModal) => {
    this.setState({ OTPModal });
  };

  onSubmit = (formValues) => {
    this.props.adminLogin(formValues, this.setOTPModal);
  };

  render() {
    const { locale } = this.props;

    return (
      <div className="login login--admin">
        <OTPModal
          setOTPModal={this.setOTPModal}
          adminLogin={this.props.adminLogin}
          onClose={() => this.setState({ OTPModal: null })}
          open={this.state.OTPModal !== null}
          {...this.state.OTPModal}
        />
        <div
          style={{ height: "fit-content" }}
          className={`login__card ${getClassDirection(locale)}`}
        >
          <div className="flex-center">
            <img style={{ width: "50%" }} src={logo} alt="restigo logo" />
          </div>
          <h1>התחברות אדמין</h1>
          <p className="details-primary">{I18n.t("login.details")}</p>
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <Field
              name="email"
              type="email"
              icon="user"
              className="login__card--input"
              placeholder={I18n.t("login.email")}
              component={this.renderInput}
            />
            <Field
              name="password"
              type="password"
              icon="lock"
              className="login__card--input"
              placeholder={I18n.t("login.password")}
              component={this.renderInput}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 0.5rem 1rem 0.5rem",
              }}
            >
              <div>
                <Link to="/Rs-admin/restore">
                  {" "}
                  <Button
                    className="btn--hyperlink"
                    onClick={() => {}}
                    textValue="passRecovery"
                  />
                </Link>
              </div>
            </div>

            <LoginButton className="btn--submit-login" />
          </form>
        </div>
      </div>
    );
  }
}

// עצרתי פה צריך להכין את כפתור שחזר סיסמא

const validate = (formValues) => {
  const errors = {};
  // if (!formValues.email) errors.email = I18n.t("login.errors.email_missing");
  // else if (!validator.isEmail(formValues.email))
  //   errors.email = I18n.t("login.errors.email_format");
  // else if (!formValues.email.match(/^[a-zA-Z0-9._%+-]+@restigo.co.il$/))
  //   errors.email = "מייל ללא הרשאה";
  // // const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,10}$/;
  // if (!formValues.password)
  //   errors.password = I18n.t("login.errors.password_missing");
  // else if (!formValues.password.match(decimal))
  // errors.password =
  //   "סיסמא חייבת להכיל לפחות פעם אחת: אות גדולה, אות קטנה, מספר, וסימן מיוחד($%&..) באורך של 8-10 תוים";

  return errors;
};

const mapStateToProps = (state) => {
  return { locale: state.i18n.locale };
};

const formWrapped = reduxForm({
  form: "login_admin",
  enableReinitialize: true,
  validate,
})(LoginAdmin);

export default connect(mapStateToProps, { adminLogin })(formWrapped);
