import { connect } from "react-redux";
import Popover from "react-popover";
import {
  Card,
  CardHeader,
  CircularProgress,
  // Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { default as Grid } from "@mui/material/Unstable_Grid2";
import Tooltip from "rc-tooltip";
import React, { useEffect, useState } from "react";
import { setError } from "../../../../../redux/actions/errorAction";
import { setConfirmBar } from "../../../../../redux/actions/confirmAction";
import { RESTIGO_ATTENDANCE_APP_PROVIDER } from "../AttendanceClocks";
import { AiFillQuestionCircle, AiOutlineClose } from "react-icons/ai";
import { FaPencilAlt, FaSave } from "react-icons/fa";
import Axios from "axios";

import iconImg from "../../../../../images/latitude_longitude_explanation.png";
import { fixDateHours } from "../../../../../helpers";
import FindCoordinatesExplanation from "../FindCoordinatesExplanation";
import { useScreenSize } from "../../../../../Hooks";

const EditAttendanceAppModel = ({
  updateDataAfterServerCall,
  attendanceApp,
  token,
  adminId,
  setConfirmBar,
  setError,
}) => {
  const { isMobile } = useScreenSize()
  const {
    id,
    latitude,
    longitude,
    radius,
    branch_name,
    updater_name,
    updated_at,
  } = attendanceApp;

  // ============= state ===========
  const [data, setData] = useState({
    latitude,
    longitude,
    radius,
  });
  const [open, setopen] = useState(false);
  const [loading, setLoading] = useState(false);

  // =============== handlers & functions ==============

  const handleChange = (key, value) => {
    switch (key) {
      default:
        setData({ ...data, [key]: value });
    }
  };

  const handleClose = () => {
    setopen(false);
    // update state on close (delete changes)
    setData({
      latitude,
      longitude,
      radius,
    });
  };

  //================= server calls ===================
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await Axios.put(
        `admin/attendanceClocks/${RESTIGO_ATTENDANCE_APP_PROVIDER}/${id}`,
        { ...data, updated_by: adminId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const makorClient = response.data;
      setopen(false);
      updateDataAfterServerCall(makorClient);
      setConfirmBar("הגדרות אפליקציית נוכחות עודכנו בהצלחה");
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message, "ארעה שגיאה בשרת");
    }
    setLoading(false);
  };

  //================= effects =======================

  useEffect(() => {
    //update state on data change (from outside)
    setData({
      latitude,
      longitude,
      radius,
    });
  }, [latitude, longitude, radius]);

  return (
    <Popover
      place="right"
      isOpen={open}
      onOuterAction={() => handleClose()}
      style={{ zIndex: 4 }}
      body={
        <Card className="padding-05" style={{ maxWidth: "30rem" }}>
          <CardHeader
            className="padding-05 padding-bottom-0"
            title={`עריכת נתוני שעון - ${branch_name}`}
            action={
              <div>
                <FindCoordinatesExplanation {...{ isMobile }} />
                <IconButton onClick={() => handleClose()}>
                  <AiOutlineClose />
                </IconButton>
              </div>
            }
          />
          <Grid container className="padding-05" spacing={2}>
            <Grid  xs={12}>
              <TextField
                size="small"
                type="number"
                label="קו רוחב"
                onChange={(e) => handleChange("latitude", e.target.value)}
                value={data.latitude}
              />
            </Grid>
            <Grid  xs={12}>
              <TextField
                size="small"
                type="number"
                label="קו אורך"
                onChange={(e) => handleChange("longitude", e.target.value)}
                value={data.longitude}
              />
            </Grid>
            <Grid  xs={12}>
              <TextField
                size="small"
                type="number"
                label="רדיוס (מטר)"
                onChange={(e) => handleChange("radius", e.target.value)}
                value={data.radius}
              />
            </Grid>
          </Grid>
          <hr />
          <div className="flex-between">
            <div>
              <p>{`עודכן לאחרונה ב ${fixDateHours(updated_at)}`}</p>
              <p>{updater_name ? `ע״י ${updater_name}` : null}</p>
            </div>
            <IconButton color="primary" onClick={handleSubmit}>
              {loading ? (
                <CircularProgress size="2rem" color="inherit" />
              ) : (
                <FaSave />
              )}
            </IconButton>
          </div>
        </Card>
      }
    >
      <IconButton onClick={() => setopen(!open)}>{<FaPencilAlt />}</IconButton>
    </Popover>
  );
};

const mapStateToProps = (state) => {
  const { token, admin } = state.authAdmin;
  const { isMobile } = state.publicValues;
  return { token, isMobile, adminId: admin.id };
};
export default connect(mapStateToProps, { setError, setConfirmBar })(
  EditAttendanceAppModel
);
