import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import AddIcon from "@mui/icons-material/Add";

// ======== HOOKS ========
import { Button, useMediaQuery, useTheme } from "@mui/material";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const AddButton = ({ label, onClickHandler = () => {} }) => {
  // ======== HOOKS ========

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Button
      startIcon={<AddIcon />}
      // style={
      //   isMobile || isTablet
      //     ? {
      //         width: "100%",
      //         marginRight: ".5rem",
      //         whiteSpace: "nowrap",
      //         cursor: "pointer",
      //       }
      //     : { whiteSpace: "nowrap", cursor: "pointer" }
      // }
      variant="outlined"
      color="primary"
      size="large"
      onClick={onClickHandler}
    >
      {label}
    </Button>
  );
};

export default AddButton;
