import React from "react";
import { ClipLoader } from "react-spinners";
import BtnTooltip from "../tooltips/BtnTooltip";

export const SummeryBox = ({
  selected,
  onClick,
  height,
  width,
  fontSize,
  className = "",
  isLoading,
  title,
  text1,
  text1Tooltip = "",
  text2 = null,
  text2Tooltip = "",
  index,
  flexColumnClass = true,
}) => {
  return (
    <button
      type="button"
      style={{ height, width }}
      onClick={onClick}
      className={`btn btn--none ${
        flexColumnClass ? "flex-column-center" : ""
      } summeryBox ${selected ? "summeryBox__selected" : ""} ${className}`}
    >
      {isLoading ? (
        <div style={{ height: "100%" }} className="flex-center">
          <ClipLoader size={60} color="#00a2ea" loading={true} />
        </div>
      ) : (
        <>
          <h2 style={{ whiteSpace: "nowrap" }}>{title}</h2>
          <div className="summeryText">
            <BtnTooltip text={text1Tooltip}>
              <p
                style={{ direction: "ltr" }}
                className={`summeryText--${index}`}
              >
                {text1}
              </p>
            </BtnTooltip>

            {text2 !== null && (
              <BtnTooltip text={text2Tooltip}>
                <p
                  style={{ direction: "ltr", fontSize }}
                  className={`summeryText--${index}`}
                >
                  {text2}
                </p>
              </BtnTooltip>
            )}
          </div>
        </>
      )}
    </button>
  );
};
