import { useContext, useEffect, useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { ControlSignaturePad } from "../../../components/Forms/inputs/SignaturPad";
import SimpleReactTable from "../../../components/tables/SimpleReactTable";
import { fixPrice } from "../../../helpers";
import { useScreenSize } from "../../../Hooks";
import { Context } from "../Show";

const Equipments = () => {
  const { parts, user, selectedBranch } = useContext(Context);
  const { isMobile } = useScreenSize();
  const { control } = useFormContext();
  const { replace } = useFieldArray({ control, name: "parts" });

  useEffect(() => {
    replace(parts?.map(({ id }) => ({ id })) ?? []);
  }, [parts]);

  const identifier = useWatch({ control, name: "identifier" });
  const data = useMemo(() => parts ?? [], [parts]);
  const personalData = useMemo(
    () => [
      {
        identifier: "",
        full_name: user.name,
        department: user.type_info.title,
      },
    ],
    []
  );
  const personalColumns = useMemo(
    () => [
      {
        Header: "תעודת זהות",
        accessor: "identifier",
        Cell: () => {
          return (
            <FormTextField
              name="identifier"
              label="ת.ז."
              rules={{
                required: "שדה זה הוא שדה חובה",
                pattern: {
                  value: /^\d{9}$/,
                  message: "מס' ת.ז. אינו תקין, נדרש להזין מס' תקין",
                },
              }}
            />
          );
        },
      },
      {
        Header: "שם העובד",
        accessor: "full_name",
      },
      {
        Header: "מחלקה",
        accessor: "department",
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "שם פריט",
        accessor: "label",
      },
      {
        Header: "עלות",
        accessor: "value",
        Cell: ({ value }) => fixPrice(value),
      },
      {
        Header: "כמות",
        id: "qty",
        Cell: ({ row: { index } }) => {
          return (
            <FormTextField
              name={`parts.${index}.value`}
              {...{ control }}
              label="כמות פריטים"
              rules={{ required: REQUIRED_MESSAGE }}
              type="number"
            />
          );
        },
      },
    ],
    []
  );
  return (
    <div
      style={{
        margin: 50,
        display: "flex",
        flexDirection: "column",
        gap: "3rem",
        fontSize: isMobile ? "1.8rem" : "2rem",
      }}
    >
      <div>
        <h2
          style={{
            textAlign: "center",
            fontSize: isMobile ? "2rem" : "",
          }}
        >
          טופס השאלת ציוד וניכוי בגין אובדן
        </h2>
        <SimpleReactTable
          {...{ columns: personalColumns, data: personalData }}
          style={{ maxWidth: !isMobile ? "40%" : "" }}
        />
      </div>
      <p>
        לטובת ביצוע תפקידך מושאל לך ציוד כמפורט מטה- ציוד זה הינו רכוש חברת בחר
        פריט. ומושאל לך לצורך ביצוע עבודתך בחברה בלבד. עליך להשיב את כל הציוד
        לחברה עם סיום העסקתך מכל סיבה שהיא, ו/או בכל עת שתורה לך החברה לעשות כן.
        שמור על תקינות הציוד ודווח למהלך במידה והציוד אינו תקין.
      </p>

      <div>
        <p>פרטי הציוד :</p>
        <SimpleReactTable
          {...{ columns, data }}
          style={{ maxWidth: !isMobile ? "40%" : "" }}
        />
      </div>

      <div>
        לכבוד: <br />
        אני הח"מ {user.name}, מס' ת.ז. {identifier} מתחייב לשמור על הציוד, לא
        לחבל בו ולא לעשות בו שימוש שלא לצורך עבודתי. <br />
        להשיבו למעסיק עם סיום העסקתי בחברה ו/או במועד אחר שהחברה תורה לי להשיבו.
        <br />
        ואני מאשר/ת בזאת לחברה במקרה זה לנכות משכר עבודתי או מכל סכום אחר
        המגיעידוע לי כי במקרה של אובדן הציוד ו/או נזק שיגרם לו מכל סיבה שהיא
        ו/או אי החזרתו למעסיק כאמור, רשאית החברה לחייב אותי בעלות הציוד ואני
        מאשר/ת בזאת לחברה במקרה זה לנכות משכר עבודתי או מכל סכום אחר המגיע לי
        מאת החברה את עלות הנזק ו/או האובדן, בהתאם לטבלת החיובים ונוהל חיוב על
        ציוד, וזאת בהתאם להוראות סעיף <br />
        <a href="http://www.koloved.net/Document.aspx?lawId=2805">
          {" "}
          וזאת בהתאם להוראות סעיף 25 (א)(6) לחוק הגנת השכר, התשי"ח - 1958.
        </a>
        <br />
        תאריך : {new Date().toLocaleDateString()} <br />
        שם העובד: {user.name} <br />
        ת.ז : {identifier} <br />
      </div>
    </div>
  );
};

export default Equipments;
