// authReducer.js
import { REMEMBER, FORGOT } from "../actions/loginAction/types";

const INITIAL_STATE = {
  payload: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REMEMBER:
      return { ...state, payload: action.payload };
      case FORGOT:
        return { ...state, payload: {} };
    default:
      return state;
  }
};
