import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { startOfWeek, addWeeks, endOfWeek, format } from "date-fns";

import {
  itemsURI,
  suppliersURI,
  categoriesURI,
  htmlSpecialCharacters,
} from "../../pages/purchaseRequisition/newRequisition/const";
import { startLoading, stopLoading } from "../actions/loaderAction";
import { setError } from "../actions/errorAction";
import { setSnackBar } from "../../redux/actions/confirmAction";
import {
  resetDaysOfWeek,
  mapCatalogItemWithWeekDays,
} from "../actions/requisitionAction";
import { uniqBy, keyBy, isEmpty } from "lodash";
import moment from "moment";

const activeMails = [2];
const ONLY_ORDER_ABLE_ITEMS = 3;
const ONLY_WITH_ORDER_METHOD = 4;

const initialState = {
  requisitionWeek: { week_start: null, week_end: null, week: [] },
  requisitionDateRange: {
    startRange: startOfWeek(addWeeks(new Date(), 1)),
    endRange: endOfWeek(addWeeks(new Date(), 1)),
  },
  filter: {
    branchId: {},
    categoryId: [],
    suppliersId: [],
  },
  suppliers: [],
  asocSuppliers: [],
  categories: [],
  data: {
    master: {
      isLoading: false,
      thunkStatus: "idle",
      data: [],
      freeSearch: {
        isLoading: false,
        data: [],
      },
      pagination: {
        current_page: 0,
        per_page: 20,
        total: 1,
      },
    },
    details: {
      isLoading: false,
      data: [],
    },
  },
  cart: {
    items: {},
    requisitionDraft: null,
    isSaved: false,
    prevWeekIncomes: 0,
  },
  settings: {
    emailSection: {
      status: activeMails,
      emails: [],
      pagination: {
        current_page: 0,
        per_page: 50,
        total: 1,
      },
    },
  },
  error: null,
};

export const newRequisitionSlice = createSlice({
  name: "newRequisition",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { key, value } = action.payload;
      state.filter[key] = value;
    },
    incrementItemInRequisition: (state, action) => {
      const { itemId, day } = action.payload;
      const existinItem = state.data.master.data.find(
        (item) => item.id === itemId
      );
      if (existinItem) {
        existinItem[day]++;
        if (state.cart.items[[itemId]]) {
          state.cart.items[[itemId]].weekDays[[day]]++;
        } else {
          state.cart.items[[itemId]] = {
            ...existinItem,
            weekDays: {
              sunday: 0,
              monday: 0,
              tuesday: 0,
              wednesday: 0,
              thursday: 0,
              friday: 0,
              saturday: 0,
            },
          };
          state.cart.items[[itemId]].weekDays[[day]]++;
        }
      }
    },
    decrementItemInRequisition: (state, action) => {
      const { itemId, day } = action.payload;
      const existinItem = state.data.master.data.find(
        (item) => item.id === itemId
      );
      if (existinItem && existinItem[day] > 0) {
        existinItem[day]--;
        state.cart.items[[itemId]].weekDays[[day]]--;
        const sumOfItemsInWeek = Object.values(
          state.cart.items[[itemId]].weekDays
        )
          .filter((value) => typeof value === "number")
          .reduce((sum, value) => sum + value, 0);
        if (sumOfItemsInWeek === 0) {
          delete state.cart.items[[itemId]];
        }
      }
    },
    setNumOfItemInRequisition: (state, action) => {
      const { itemId, day, incNumber } = action.payload;
      if (Number(incNumber) < 0) {
        return;
      }
      const existinItem = state.data.master.data.find(
        (item) => item.id === itemId
      );
      if (existinItem) {
        existinItem[day] = Number(incNumber);
        if (state.cart.items[[itemId]]) {
          state.cart.items[[itemId]].weekDays[[day]] = Number(incNumber);
          const sumOfItemsInWeek = Object.values(
            state.cart.items[[itemId]].weekDays
          )
            .filter((value) => typeof value === "number")
            .reduce((sum, value) => sum + value, 0);
          if (sumOfItemsInWeek === 0) {
            delete state.cart.items[[itemId]];
          }
        } else {
          state.cart.items[[itemId]] = {
            ...existinItem,
            weekDays: {
              sunday: 0,
              monday: 0,
              tuesday: 0,
              wednesday: 0,
              thursday: 0,
              friday: 0,
              saturday: 0,
            },
          };
          state.cart.items[[itemId]].weekDays[[day]] = Number(incNumber);
        }
      }
    },
    setWeekDate: (state, action) => {
      // const { week, week_start, week_end, option } = action.payload;
      state.requisitionWeek = action.payload;
    },
    setDatesRange: (state, action) => {
      state.requisitionDateRange = action.payload;
    },
    setStartDateInRange: (state, action) => {
      state.requisitionDateRange.startRange = action.payload;
    },
    setEndDateInRange: (state, action) => {
      state.requisitionDateRange.endRange = action.payload;
    },
    setCatalogItemsPage: (state, action) => {
      const { value, name } = action.payload;
      state.data.master.pagination[[name]] = value;
    },
    closeSnackbar: (state, action) => {
      state.cart.isSaved = false;
    },
    setItemsFromSearchingToTable: (state, action) => {
      const data = action.payload;
      const itemsArray = data.map((catalogItem) =>
        mapCatalogItemWithWeekDays(
          catalogItem,
          state.cart.items,
          state.asocSuppliers
        )
      );
      state.data.master.data = itemsArray;

      state.data.master.pagination = {
        total: action.payload.length,
        current_page: 0,
        per_page: 10,
      };
    },
    resetDetailsOfitem: (state, action) => {
      state.data.details.data = initialState.data.details;
    },
    setMailStatus: (state, action) => {
      const { filterType, value } = action.payload;
      state.settings.emailSection[[filterType]] = value;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchItems.pending, (state, action) => {
        state.data.master.isLoading = true;
        state.data.master.thunkStatus = "loading";
      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        if (!action.payload?.data) {
          state.data.master.isLoading = false;
          state.data.master.thunkStatus = "failed";
          return;
        }
        state.data.master.isLoading = false;
        state.data.master.thunkStatus = "succeeded";
        let itemsArray;
        let paginationItemsData;
        if (Array.isArray(action.payload)) {
          itemsArray = action.payload;
          paginationItemsData = {
            total: itemsArray.length,
            current_page: 0,
            per_page: 10,
          };
        } else {
          const { total, current_page, per_page, data } = action.payload;
          itemsArray = data.map((catalogItem) => {
            if (isEmpty(state.asocSuppliers)) {
              catalogItem["hasArrival"] = true;
            } else {
              catalogItem["hasArrival"] = !isEmpty(
                state.asocSuppliers[catalogItem.supplier_id]?.supplier_arrival
              );
            }
            catalogItem["sunday"] = state.cart.items[catalogItem.id]
              ? state.cart.items[catalogItem.id].weekDays["sunday"]
              : 0;
            catalogItem["monday"] = state.cart.items[catalogItem.id]
              ? state.cart.items[catalogItem.id].weekDays["monday"]
              : 0;
            catalogItem["tuesday"] = state.cart.items[catalogItem.id]
              ? state.cart.items[catalogItem.id].weekDays["tuesday"]
              : 0;
            catalogItem["wednesday"] = state.cart.items[catalogItem.id]
              ? state.cart.items[catalogItem.id].weekDays["wednesday"]
              : 0;
            catalogItem["thursday"] = state.cart.items[catalogItem.id]
              ? state.cart.items[catalogItem.id].weekDays["thursday"]
              : 0;
            catalogItem["friday"] = state.cart.items[catalogItem.id]
              ? state.cart.items[catalogItem.id].weekDays["friday"]
              : 0;
            catalogItem["saturday"] = state.cart.items[catalogItem.id]
              ? state.cart.items[catalogItem.id].weekDays["saturday"]
              : 0;

            return catalogItem;
          });
          paginationItemsData = {
            total,
            current_page: current_page - 1,
            per_page: Number(per_page),
          };
        }
        state.data.master.data = itemsArray;
        state.data.master.pagination = paginationItemsData;
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.isLoading = false;
        state.data.master.thunkStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchItemsFreeSearch.pending, (state, action) => {
        state.data.master.freeSearch.isLoading = true;
      })
      .addCase(fetchItemsFreeSearch.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.data.master.freeSearch.data = uniqBy(action.payload, "id");
        state.data.master.freeSearch.isLoading = false;
      })
      .addCase(fetchItemsFreeSearch.rejected, (state, action) => {
        state.data.master.freeSearch.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = Object.values(action.payload)
          ?.filter(({ enable }) => enable)
          .map(({ id, name }) => ({
            value: parseInt(id),
            label: htmlSpecialCharacters(name),
          }));
      })
      .addCase(fetchSuppliers.fulfilled, (state, action) => {
        const fetchedSuppliers = action.payload.map(
          ({ supplier_id, name, supplier_arrival }) => ({
            value: supplier_id,
            label: htmlSpecialCharacters(name),
            supplier_arrival: JSON.parse(supplier_arrival),
          })
        );
        state.suppliers = fetchedSuppliers;
        state.asocSuppliers = keyBy(fetchedSuppliers, "value");
      })
      .addCase(confirmRequisition.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(confirmRequisition.fulfilled, (state, action) => {
        if (action.payload?.id) {
          state.cart.items = {};
          state.data.master.data.forEach((itemObj) => resetDaysOfWeek(itemObj));
          state.cart.isSaved = true;
          const { id, branch_name } = action.payload;
          state.cart.requisitionDraft = {
            id: id,
            branch_name: branch_name,
          };
        }
      })
      .addCase(fetchRequisitionItemsSummary.pending, (state, action) => {
        state.data.details.isLoading = true;
      })
      .addCase(fetchRequisitionItemsSummary.rejected, (state, action) => {
        state.data.details.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchRequisitionItemsSummary.fulfilled, (state, action) => {
        state.data.details.isLoading = false;
        if (!action.payload) {
          return;
        }
        state.data.details.data = action.payload;
      })
      .addCase(duplicateCartByWeek.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.cart.items = action.payload;
        state.data.master.data.forEach((catalogItem) => {
          const itemId = catalogItem.id;
          const weekDays = state.cart.items[itemId]?.weekDays || {};

          catalogItem.sunday = weekDays.sunday || 0;
          catalogItem.monday = weekDays.monday || 0;
          catalogItem.tuesday = weekDays.tuesday || 0;
          catalogItem.wednesday = weekDays.wednesday || 0;
          catalogItem.thursday = weekDays.thursday || 0;
          catalogItem.friday = weekDays.friday || 0;
          catalogItem.saturday = weekDays.saturday || 0;
        });
      })
      .addCase(createNewRequisition.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        const { requisition, groupedItems, totalIncomeLastWeek } = action.payload;
        state.cart.requisitionDraft = {
          id: requisition.id,
          branch_name: requisition.branch_name,
        };

        state.cart.items = isEmpty(groupedItems) ? {} : groupedItems;
        state.cart.prevWeekIncomes = totalIncomeLastWeek;
      })
      .addCase(createNewRequisition.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateRequisitionDraft.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
      })
      .addCase(createEmail.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createEmail.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.settings.emailSection.emails.unshift(action.payload);
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        const udpdatedEmail = action.payload;
        const index = state.settings.emailSection.emails.findIndex(
          (email) => email.id === udpdatedEmail.id
        );
        state.settings.emailSection.emails[index] = udpdatedEmail;
      })
      .addCase(getEmails.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getEmails.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.settings.emailSection.emails = action.payload?.data;
      });
  },
});

//=========== Async Thunks =======================

export const fetchItems = createAsyncThunk(
  "newRequisition/fetchItems",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    const {
      branchId: { id: branchId },
      categoryId,
      suppliersId,
    } = getState().newRequisition.filter;

    const { current_page, per_page } =
      getState().newRequisition.data.master.pagination;

    const params = {
      status: [1],
      branchId,
      other: [ONLY_ORDER_ABLE_ITEMS, ONLY_WITH_ORDER_METHOD],
      ...(!!categoryId.length && {
        categoryId,
      }),
      ...(!!suppliersId.length && {
        suppliersId,
      }),
      page: current_page + 1,
      per_page,
    };

    try {
      const { data } = await Axios.get(itemsURI, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSuppliers = createAsyncThunk(
  "newRequisition/fetchSuppliers",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;

    try {
      const res = await Axios.get("purchaseRequisition/suppliers", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCategories = createAsyncThunk(
  "newRequisition/fetchCategories",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const params = { status: [1] };
    try {
      const { data } = await Axios.get(categoriesURI, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const updateRequisitionDraft = createAsyncThunk(
  "newRequisition/updateDraft",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const {
      branchId: { id: branchId },
    } = getState().newRequisition.filter;

    const { week_start, week_end } = getState().newRequisition.requisitionWeek;
    const { startRange, endRange } =
      getState().newRequisition.requisitionDateRange;
    const { items, requisitionDraft } = getState().newRequisition.cart;
    if (!items) {
      return;
    }
    const requisitionItems = Object.values(items);

    const body = {
      branchId,
      week_start: startRange,
      week_end: endRange,
      requisitionItems,
    };

    try {
      const { data } = await Axios.post(
        `purchaseRequisition/${requisitionDraft?.id}/saveChanges`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const createNewRequisition = createAsyncThunk(
  "newRequisition/createNewRequisition",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    dispatch(startLoading());
    const token = getState().auth.token;

    const {
      branchId: { id: branchId },
    } = getState().newRequisition.filter;

    const { week_start, week_end } = getState().newRequisition.requisitionWeek;
    const { startRange, endRange } =
      getState().newRequisition.requisitionDateRange;

    const body = {
      branchId,
      week_start: startRange,
      week_end: endRange,
    };

    try {
      const { data } = await Axios.post("purchaseRequisition", body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      return rejectWithValue(error);
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const confirmRequisition = createAsyncThunk(
  "newRequisition/confirmRequisition",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    dispatch(startLoading());
    const token = getState().auth.token;

    const {
      branchId: { id: branchId },
    } = getState().newRequisition.filter;

    const { startRange, endRange } =
      getState().newRequisition.requisitionDateRange;
    const { items, requisitionDraft } = getState().newRequisition.cart;
    const requisitionItems = Object.values(items);

    const formatedStartRange = moment.isMoment(startRange)
      ? startRange.format("YYYY-MM-DD")
      : format(startRange, "yyyy-MM-dd");
    const formatedEndRange = moment.isMoment(endRange)
      ? endRange.format("YYYY-MM-DD")
      : format(endRange, "yyyy-MM-dd");
    const body = {
      branchId,
      week_start: formatedStartRange,
      week_end: formatedEndRange,
      requisitionItems,
    };

    try {
      const { data } = await Axios.put(
        `purchaseRequisition/${requisitionDraft?.id}`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      return rejectWithValue(error?.response);
    } finally {
      dispatch(stopLoading());
      dispatch(setSnackBar("עודכן בהצלחה!"));
    }
  }
);

export const fetchItemsFreeSearch = createAsyncThunk(
  "newRequisition/fetchItemsFreeSearch",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const {
      branchId: { id: branchId },
    } = getState().newRequisition.filter;

    const params = {
      freeSearch: payload,
      other: [ONLY_ORDER_ABLE_ITEMS, ONLY_WITH_ORDER_METHOD],
      status: [1],
      branchId,
    };

    try {
      const { data } = await Axios.get(itemsURI, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const fetchRequisitionItemsSummary = createAsyncThunk(
  "newRequisition/fetchRequisitionItemsSummary",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;

    const {
      branchId: { id: branchId },
    } = getState().newRequisition.filter;
    const params = {
      branchId,
      itemId: payload,
    };
    try {
      const res = await Axios.get(`purchaseRequisition/getItemSummary`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const duplicateCartByWeek = createAsyncThunk(
  "newRequisition/duplicateCartByWeek",
  async (payload, { dispatch, getState }) => {
    dispatch(startLoading());
    const token = getState().auth.token;
    const { startDate, endDate } = payload;

    const {
      branchId: { id: branchId },
    } = getState().newRequisition.filter;
    const params = {
      branchId,
      startDate,
      endDate,
    };
    try {
      const res = await Axios.get(`purchaseRequisition/duplicateCart`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      console.log("error", error);
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const getEmails = createAsyncThunk(
  "newRequisition/getEmails",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const token = getState().auth.token;
    const branchId = getState().auth.user.branch_id;

    // const {
    //   branchId: { id: branchId },
    // } = getState().newRequisition.filter;

    const mailingStatus =
      getState().newRequisition.settings.emailSection.status;

    const { current_page, per_page } =
      getState().newRequisition.settings.emailSection.pagination;

    const params = {
      ...(!!mailingStatus.length
        ? {
            status: mailingStatus.map((value) => value - 1),
          }
        : {
            status: [1],
          }),
      page: current_page + 1,
      per_page,
      branchId,
    };

    try {
      const res = await Axios.get("purchaseRequisition/getEmails", {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      rejectWithValue(error);
    }
  }
);

export const createEmail = createAsyncThunk(
  "newRequisition/createEmail",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    dispatch(startLoading());
    const token = getState().auth.token;

    const {
      branchId: { id: branchId },
    } = getState().newRequisition.filter;
    const body = {
      branchId,
      emailData: payload,
    };
    try {
      const res = await Axios.post("purchaseRequisition/createEmail", body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await res.data;
      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      return rejectWithValue(error);
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const updateEmail = createAsyncThunk(
  "newRequisition/updateEmail",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    dispatch(startLoading());
    const token = getState().auth.token;

    const body = {
      emailData: payload,
    };
    try {
      const res = await Axios.put(
        `purchaseRequisition/updateEmail/${payload?.id}`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
      return rejectWithValue(error);
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const selectAllItems = (state) => state.newRequisition;

export default newRequisitionSlice.reducer;

export const { actions: newRequisitionActions } = newRequisitionSlice;

export const { setItemsFromSearchingToTable, resetDetailsOfitem } =
  newRequisitionSlice.actions;
