import React from "react";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
  ExcelExportFooter,
} from "@progress/kendo-react-excel-export";
import ButtonIcon from "../buttons/ButtonIcon";
import Button from "../buttons/Button";
import { FaFileExcel } from "react-icons/fa";
import { I18n } from "react-redux-i18n";
import { getSafe } from "../../helpers";

class CustomFooter extends ExcelExportFooter {
  render() {
    const { title, aggregates, data } = this.props;
    let res = 0;

    if (title === "working_date") {
      return [
        ...new Set(
          data
            .filter(
              (row) =>
                !row.vacation && !row.sick && !row.reserve && !row.is_padded
            )
            .map((x) => x.working_date)
        ),
      ].length;
    }

    if (title === "allowance_percentage") {
      if (aggregates["allowance"]) {
        res = Number(
          (100 * aggregates["allowance"].sum) /
            aggregates["total_tip_before_allowance"].sum
        ).toFixed(2);
        if (isNaN(res)) {
          return 0;
        }
        return res;
      } else {
        res = Number(
          (100 * aggregates["tips_allowance"].sum) /
            aggregates["tips_total_before_allowance"].sum
        ).toFixed(2);
      }
      if (isNaN(res)) {
        return 0;
      }
      return Number(res);
    } else if (getSafe(() => aggregates[title].count)) {
      return aggregates[title].count;
    } else if (aggregates[title].average) {
      res = Number(getSafe(() => aggregates[title].average.toFixed(2), 0));
      if (isNaN(res)) {
        return 0;
      }
      return res;
    } else {
      res = Number(getSafe(() => aggregates[title].sum.toFixed(2), 0));
      if (isNaN(res)) {
        return 0;
      }
      return res;
    }
  }
}

const CustomGroupHeader = (props) => `${props.value}`;
const CustomGroupFooter = (props) => {
  if (props.field === "working_date" && props.aggregates[props.field]) {
    return [
      ...new Set(
        props.aggregates[props.field].group.items.map((x) => x.working_date)
      ),
    ].length;
  }

  if (getSafe(() => props.aggregates[props.field].count)) {
    return props.aggregates[props.field].count;
  } else {
    const res = Number(
      getSafe(() => props.aggregates[props.field].sum.toFixed(2), 0)
    );
    if (isNaN(res)) {
      return 0;
    }
    return res;
  }
};

class DownloadExcel extends React.Component {
  _exporter;
  export = () => {
    this.save(this._exporter);
  };

  save = (component) => {
    const options = component.workbookOptions();
    const rows = options.sheets[0].rows;
    rows.splice(2, 1);
    //rows.splice(rows.length - 2, 1);

    rows.forEach((row) => {
      if (row.type === "data") {
        row.cells.forEach((cell, index) => {
          cell.background = "#fff";
          if (cell.value !== undefined) {
            cell.borderBottom = "#";
            cell.borderTop = "#";
            cell.borderLeft = "#";
            cell.borderRight = "#";
          } else {
            cell.borderBottom = "#";
            cell.borderTop = "#";
          }
          if (row.cells[1]?.value === 'סה"כ') {
            cell.background = "#e2e2e2";
            cell.borderBottom = "#838383";
            cell.borderTop = "#838383";
            cell.borderLeft = "#838383";
            cell.borderRight = "#838383";
          }
          // else if (row.cells[0].value === true) {
          //   cell.background = "#fff";
          //   cell.borderBottom = "#838383";
          //   cell.borderTop = "#838383";
          //   cell.borderLeft = "#838383";
          //   cell.borderRight = "#838383";
          // }
        });
      }
      if (row.type === "header") {
        row.cells.forEach((cell, index) => {
          if (row.cells.length === 2 || row.cells.length === 3) {
            cell.background = "#fff";
            cell.fontSize = 18;
            cell.color = "#000";
            cell.bold = true;
            cell.borderBottom = "#";
            cell.borderTop = "#";
          } else {
            cell.background = "#0090d0";
          }
        });
      }
      if (row.type === "footer") {
        if (
          rows[0].cells[1].value
            ? rows[0].cells[1].value.includes("דוח שכר תפקיד")
            : false
        ) {
          row.cells.forEach((cell, index) => {
            if (cell.value !== undefined) {
              const expandedItems = this.props?.data[0]?.items;
              let lengthOfExpanders = expandedItems?.filter(
                (row) => !row.expander
              )?.length;
              lengthOfExpanders =
                lengthOfExpanders === 2
                  ? lengthOfExpanders
                  : lengthOfExpanders + 1;
              lengthOfExpanders =
                expandedItems.length ===
                expandedItems?.filter((row) => row.expander)?.length
                  ? 2
                  : lengthOfExpanders;
              cell.value = cell.value / (lengthOfExpanders || 1);
            }
            cell.background = "#0090d0";
          });
        }
        row.cells[0].background = "#0090d0";
        row.cells[1].background = "#0090d0";
        //if(this.props.customFooterTitle)
        row.cells[0].value = "סה״כ";
      }
      if (row.type === "group-footer") {
        row.cells.forEach((cell, index) => {
          cell.background = "#838383";
        });
        if (this.props.customFooterTitle) row.cells[0].value = "סה״כ";
      }
    });

    component.save(options);
  };

  getColFooterSum = (key, aggregates) => {
    return getSafe(() => aggregates[key].sum, null) !== null ||
      key === "allowance_percentage" ||
      key === "working_date" ||
      getSafe(() => aggregates[key].count)
      ? true
      : false;
  };

  render() {
    const {
      isMobile,
      group = null,
      fileName,
      data,
      columns,
      aggregates,
      buttonStyle,
      multi = false,
      style = {},
      buttonIcon,
      buttonText,
      customFooterTitle,
    } = this.props;

    return (
      <div style={style}>
        {multi ? (
          <Button
            icon={FaFileExcel}
            className="btn--normal"
            textValue={buttonText ? buttonText : "ייצא הכל"}
            onClick={this.export}
            style={buttonStyle}
          />
        ) : isMobile || buttonIcon ? (
          <ButtonIcon
            icon={FaFileExcel}
            className="btn--normal"
            onClick={this.export}
            alt="ייצא לאקסל"
          />
        ) : (
          <Button
            icon={FaFileExcel}
            className="btn--normal"
            textValue="ייצא לאקסל"
            onClick={this.export}
          />
        )}
        <ExcelExport
          dir="rtl"
          data={data}
          fileName={fileName}
          group={group}
          ref={(exporter) => {
            this._exporter = exporter;
          }}
        >
          <ExcelExportColumnGroup
            title={fileName}
            headerCellOptions={{ textAlign: "center" }}
          >
            {columns
              .map((col, col_idx) => {
                if (
                  col.accessor === "buttons" ||
                  col.accessor === "expander" ||
                  col.accessor === "actions" ||
                  col.disable_export_excel
                )
                  return null;
                if (this.getColFooterSum(col.accessor, aggregates)) {
                  return (
                    <ExcelExportColumn
                      key={col_idx}
                      field={col.accessor}
                      // width={80}
                      title={`${I18n.t(`excel.${col.accessor}`)}`}
                      cellOptions={{ textAlign: "center" }}
                      headerCellOptions={{
                        bold: true,
                        color: "#fff",
                        fontSize: "1.4rem",
                        textAlign: "center",
                        background: "#0090d0",
                        wrap: true,
                      }}
                      footer={() => (
                        <CustomFooter
                          title={col.accessor}
                          aggregates={aggregates}
                          data={data[0].items}
                        />
                      )}
                      footerCellOptions={{
                        bold: true,
                        color: "#fff",
                        fontSize: "1.4rem",
                        textAlign: "center",
                        background: "#0090d0",
                        wrap: true,
                      }}
                      groupHeaderCellOptions={{
                        textAlign: "center",
                        bold: true,
                        fontSize: "1.4rem",
                      }}
                      groupFooterCellOptions={{
                        textAlign: "center",
                        bold: true,
                        color: "#fff",
                        fontSize: "1.4rem",
                      }}
                      groupHeader={CustomGroupHeader}
                      groupFooter={CustomGroupFooter}
                    />
                  );
                }
                return (
                  <ExcelExportColumn
                    key={col_idx}
                    field={col.accessor}
                    width={col.accessor === "expander" ? 1 : null}
                    title={`${I18n.t(
                      `excel.${col.id ? col.id : col.accessor}`
                    )}`}
                    headerCellOptions={
                      col.accessor === "expander"
                        ? {}
                        : {
                            bold: true,
                            color: "#fff",
                            fontSize: "1.4rem",
                            textAlign: "center",
                            background: "#0090d0",
                            wrap: true,
                          }
                    }
                    cellOptions={{ textAlign: "center" }}
                    groupHeaderCellOptions={{
                      textAlign: "center",
                      bold: true,
                      fontSize: "1.4rem",
                    }}
                    groupFooterCellOptions={{
                      textAlign: "center",
                      bold: true,
                      color: "#fff",
                      fontSize: "1.4rem",
                    }}
                    groupHeader={CustomGroupHeader}
                    footerCellOptions={{
                      bold: true,
                      color: "#fff",
                      fontSize: "1.4rem",
                      textAlign: "center",
                      background: "#0090d0",
                      wrap: true,
                    }}
                  />
                );
              })
              .filter((x) => x !== null)}
          </ExcelExportColumnGroup>
          <ExcelExportColumn
            hidden
            field="file_title"
            width={100}
            title="file_title"
            headerCellOptions={{ textAlign: "center" }}
            cellOptions={{ textAlign: "center" }}
            groupHeaderCellOptions={{ textAlign: "center" }}
            groupHeader={() => " "}
          />
        </ExcelExport>
      </div>
    );
  }
}

export default DownloadExcel;
