import React, { useMemo, useState } from "react";
import Modal from "react-responsive-modal";
import validator from "validator";
import SubmitButton from "../../components/buttons/SubmitButton";

export const MailInputModal = ({ open, onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const validate = useMemo(
    () => (email) => {
      if (validator.isEmail(email)) {
        setError(null);
        return true;
      } else {
        setError("פורמט אימייל לא תקין.");
        return false;
      }
    },
    []
  );

  const handleSubmit = useMemo(
    () => async () => {
      if (validate(email)) {
        onSubmit(email);
        onClose();
      }
    },
    [email, onSubmit, validate]
  );

  const onChange = (email) => {
    setEmail(email);
    setError(null);
  };

  const onBlur = () => (email === "" ? setError(null) : validate(email));

  return (
    <Modal
      open={open}
      closeOnOverlayClick={false}
      classNames={{ modal: "dialog-modal" }}
      styles={{
        modal: {
          maxHeight: "unset",
          textAlign: "center",
          padding: "2.5rem",
        },
      }}
      center
      onClose={onClose}
      animationDuration={200}
    >
      <h2>שליחת קובץ במייל</h2>
      <form onSubmit={handleSubmit}>
        <label>הזן כתובת אימייל:</label>
        <div style={{ marginBottom: "1rem" }}>
          <input
            value={email}
            onBlur={() => onBlur()}
            onChange={(e) => onChange(e.target.value)}
          />
          {error && (
            <p>
              * <span className="error-msg">{error}</span>{" "}
            </p>
          )}
        </div>
        <SubmitButton />
      </form>
    </Modal>
  );
};
