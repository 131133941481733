// Login

import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validator from "validator";
import { I18n } from "react-redux-i18n";
import Button from "../components/buttons/Button";
import { getClassDirection } from "../helpers";
import PasswordRestore from "./PasswordRestore";
import { restoreAdminPassword } from "../redux/actions/AdminLoginAction";


class RestoreAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { OTPModal: null };
  }

  renderError = ({ error, touched }) => {
    if (touched && error)
      return (
        <p>
          * <span className="error-msg">{error}</span>{" "}
        </p>
      );
  };

  //called in <Field>(redux-form tag) and render the input by the props
  //input is a field in the <Field> tag that handle the input behind the scenes
  renderInput = ({ type, placeholder, input, meta, className }) => {
    return (
      <div className={className}>
        <input type={type} placeholder={placeholder} {...input} />
        {this.renderError(meta)}
      </div>
    );
  };


 setOTPModal = (OTPModal) => {
    this.setState({ OTPModal });
  };

  onSubmit = (formValues) => {
    this.props.restoreAdminPassword(formValues,this.setOTPModal);
  };

 

  render() {
    const { locale } = this.props;
    return (
      <div className="login">
        <PasswordRestore
          setOTPModal={this.setOTPModal}
          restoreAdminPassword={this.props.restoreAdminPassword}
          onClose={() => this.setState({ OTPModal: null })}
          open={this.state.OTPModal !== null}
          {...this.state.OTPModal}
        />
        <div
          style={{ height: "fit-content" }}
          className={`login__card ${getClassDirection(locale)}`}
        >
          <h1>שחזור סיסמא אדמין</h1>
          <p className="details-primary">הזן פרטי משתמש לצורך וידוא</p>
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <Field
              name="identifier"
              type="text"
              icon="user"
              className="login__card--input"
              placeholder="מספר תעודת זהות"
              component={this.renderInput}
            />
            <Field
              name="phone_number"
              type="text"
              icon="user"
              className="login__card--input"
              placeholder="טלפון"
              component={this.renderInput}
            />
            <Field
              name="email"
              type="email"
              icon="user"
              className="login__card--input"
              placeholder="אימייל"
              component={this.renderInput}
            />

            <Button
              type="submit"
              className="btn--submit-login"
              textValue="שלח"
            />
          </form>
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) errors.email = I18n.t("login.errors.email_missing");
  else if (!validator.isEmail(formValues.email))
    errors.email = I18n.t("login.errors.email_format");
  else if (!formValues.email.match(/^[a-zA-Z0-9._%+-]+@restigo.co.il$/))
    errors.email = "מייל ללא הרשאה";
  if (!formValues.phone_number) errors.phone_number = "הזן מספר טלפון";
  else if (!validator.isNumeric(formValues.phone_number))
    errors.phone_number = "שדה זה מקבל רק מספרים";
  else if (formValues.phone_number.length !== 10)
    errors.phone_number = "נדרש מספר באורך 10 ספרות";
  if (!formValues.identifier) errors.identifier = "הזן מספר ת.ז.";
  else if (!validator.isNumeric(formValues.identifier))
    errors.identifier = "שדה זה מקבל רק מספרים";
  else if (formValues.identifier.length !== 9)
    errors.identifier = "נדרש מספר ת.ז. באורך 9 ספרות";

  return errors;
};

const mapStateToProps = (state) => {
  return { locale: state.i18n.locale };
};

const formWrapped = reduxForm({
  form: "restore_admin",
  enableReinitialize: true,
  validate,
})(RestoreAdmin);

export default connect(mapStateToProps, { restoreAdminPassword })(formWrapped);
