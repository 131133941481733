import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
} from "@mui/material";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { BsTagFill } from "react-icons/bs";
import Axios from "axios";
import SimpleReactTable from "../../../../components/tables/SimpleReactTable";
import { fixPrice } from "../../../../helpers";
import { setError } from "../../../../redux/actions/errorAction";
import { MultiSelectFilter } from "../../../../components/tables/Filters";
import { setDialogModal } from "../../../../redux/actions/DialogAction";
import { COLOR_RESTIGO_PRIMARY } from "../../../../const/colors";

const REQUIRED_TEXT = "נדרש אישור";

const ModalTipAproove = ({
  children,
  token,
  user,
  setError,
  required,
  setDialogModal,
}) => {
  const [tips, setTips] = useState([]);
  const [hasRequiredTips, setHasRequiredTips] = useState(false);
  const [extraDataPopoverAnchorEl, setExtraDataPopoverAnchorEl] =
    useState(null);
  const [extraDataTip, setExtraDataTip] = useState(null);

  const closeExtraDataPopover = () => {
    setExtraDataPopoverAnchorEl(null);
    setExtraDataTip(null);
  };

  const data = useMemo(() => {
    const now = new Date();
    const dta = new Date(
      now.getTime() - 86400000 * user.branch?.delay_tip_aproove
    );

    return tips.map(
      ({ id, date, total_tip, cach, aprooved_at, shift_event, ...rest }) => {
        const tipDate = new Date(date);
        return {
          id,
          date,
          total_tip,
          cach,
          aprooved_at,
          shift_event,
          required:
            tipDate.getTime() < dta.getTime()
              ? REQUIRED_TEXT
              : "אישור אופציונלי",
          ...rest,
        };
      }
    );
  }, [tips, user]);

  useEffect(() => {
    const hrt = data.some(({ required }) => required === REQUIRED_TEXT);
    setHasRequiredTips(hrt);
  }, [data]);

  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };

  const fetchTips = useCallback(() => {
    const fetch = async () => {
      if (!user?.id || !user.branch.mandatory_tip_aproove) {
        setTips([]);
        return;
      }
      try {
        const { data: tips } = await Axios.get("tip-events", {
          params: {
            user_id: user.id,
            archived: false,
            aprooved: false,
            from: user.branch.mandatory_tip_aproove_from,
          },
          headers: { Authorization: `Bearer ${token}` },
        });
        setTips(tips);
      } catch (error) {
        switch (error?.response?.status) {
          default:
            setError();
            break;
        }
      }
    };
    fetch();
  }, [token, user]);

  const aprooveTip = useCallback(
    (tip) => {
      const aproove = async () => {
        try {
          await Axios.post(`tip-events/${tip}/aproove`, null, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (error) {
          switch (error?.response?.status) {
            default:
              setError();
              break;
          }
        } finally {
          fetchTips();
        }
      };

      setDialogModal({
        title: "האם ברצונך לאשר טיפים",
        onConfirm: aproove,
      });
    },
    [token, setError, fetchTips, setDialogModal]
  );

  const endRowButtons = useMemo(
    () => [
      {
        icon: <BsTagFill color={COLOR_RESTIGO_PRIMARY} />,
        onClick: (e, { original }) => {
          setExtraDataPopoverAnchorEl(e.currentTarget);
          setExtraDataTip(original);
        },
      },
    ],
    []
  );

  useEffect(() => {
    fetchTips();
  }, [fetchTips]);

  const columns = useMemo(
    () => [
      {
        Header: "משמרת",
        accessor: "date",
        sortMobile: true,
        sort: true,
        Cell: ({
          value,
          row: {
            original: {
              shift_event: {
                new_sub_shift: { name },
              },
            },
          },
        }) => `${name} ${value}`,
      },
      {
        Header: 'סה"כ טיפ',
        accessor: "total_tip",
        Cell: ({ value }) => fixPrice(value),
      },
      {
        Header: "טיפ מזומן ברוטו",
        accessor: "cash",
        Cell: ({ value }) => fixPrice(value),
      },
      {
        Header: "סוג טיפ",
        accessor: "required",
        filter: "includesValue",
        Filter: MultiSelectFilter,
      },
      {
        Header: "סטטוס",
        accessor: "aprooved_at",
        Cell: ({
          value,
          row: {
            original: { id },
          },
        }) =>
          value ?? (
            <Button
              onClick={() => {
                aprooveTip(id);
              }}
              sx={{ color: "red" }}
            >
              לחץ כאן לאישור
            </Button>
          ),
      },
    ],
    [aprooveTip]
  );

  return (
    !!tips.length && (
      <>
        <Dialog
          open={(required && hasRequiredTips) || open}
          fullWidth
          maxWidth={"lg"}
          variant="allowmenu"
        >
          <DialogTitle>
            <CardHeader
              title="אישור טיפים"
              action={
                <IconButton onClick={close} disabled={required}>
                  <AiOutlineClose />
                </IconButton>
              }
            />
          </DialogTitle>
          <DialogContent>
            <SimpleReactTable {...{ columns, data, endRowButtons }} />
            <Popover
              id="extraDataPopover"
              open={!!extraDataPopoverAnchorEl}
              anchorEl={extraDataPopoverAnchorEl}
              onClose={closeExtraDataPopover}
            >
              <ExtraData tip={extraDataTip} {...{ closeExtraDataPopover }} />
            </Popover>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={close} disabled={required}>
              סגור
            </Button>
          </DialogActions>
        </Dialog>
        {!!children && children(setOpen, tips)}
      </>
    )
  );
};

const ExtraData = ({ tip, closeExtraDataPopover }) => {
  return (
    !!tip && (
      <div style={{ margin: 15, width: "250px", fontSize: "2rem" }}>
        <div style={{ textAlign: "center" }}>
          <BsTagFill color={COLOR_RESTIGO_PRIMARY} />
          <h5>{`פירוט טיפים לתאריך ${tip.date}`}</h5>
        </div>
        <div>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span>שעת כניסה:</span>
            <span>{tip.shift_event.actual_start}</span>
          </p>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span>שעת יציאה:</span>
            <span>{tip.shift_event.actual_end}</span>
          </p>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span>שעות לטיפ:</span>
            <span>{tip.tip_hours}</span>
          </p>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={closeExtraDataPopover}
          >
            סגור
          </Button>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  const { token, user } = state.auth;
  return { token, user };
};

const mapDispatchToProps = { setError, setDialogModal };

export default connect(mapStateToProps, mapDispatchToProps)(ModalTipAproove);
