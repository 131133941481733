import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/buttons/Button";

const SubmissionsNotes = ({ onAbort, onSave, text = "" }) => {
  const [inputValue, setInputValue] = useState(text);

  const textRef = useRef();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      textRef.current.focus();
      setInputValue(text);
    }
    return () => {
      mounted = false;
    };
  }, [text]);

  return (
    <div
      className="notes-textarea notes-textarea__model"
      style={{ width: "26rem", height: "26rem" }}
    >
      <textarea
        style={{ direction: "rtl" }}
        ref={textRef}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      ></textarea>
      <div style={{ height: "31%" }} className="flex-center">
        <button
          type="button"
          className="btn btn--submit "
          onClick={(e) => {
            e.stopPropagation();
            onSave(inputValue);
          }}
        >
          שמור
        </button>
        <Button
          className="btn--neutral "
          onClick={() => {
            setInputValue(text);
            onAbort();
          }}
          textValue="ביטול"
        />
      </div>
    </div>
  );
};
export default SubmissionsNotes;
