import {
  TAB_DISHES,
  TAB_OPTIONS,
  TAB_UNIFIED_PRODUCTS,
} from "../../pages/products/const";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
import { LOGOUT } from "../actions/loginAction/types";
import {
  FETCH_PRODUCT,
  FETCH_PRODUCTS,
  SELECTED_TAB,
  CLOSE_FORM,
  FETCH_ITEMS,
  UPDATE_PRODUCT,
  DUPLICATE_PRODUCT,
  CLREAR_FREE_SERACH,
  ADD_PRODUCT_INGREDIENT,
  SET_INGREDIENT,
  DELETE_PRODUCT_INGREDIENT,
  EDIT_PRODUCT_INGREDIENT,
  FETCH_POS_CODES,
  FETCH_FEES,
  CREATE_PRODUCT,
  FETCH_CATEGORIES,
  UPDATE_CATEGORY,
  ADD_CATEGORY,
  SET_PAGINATION,
  SET_FILTER,
  RESET_FILTER,
  SET_PAGE,
  CREATE_FEE_LOADING,
  PRODUCTS_LOADING,
  PRODUCT_DETAILS_LOADING,
  CATEGORIES_LOADING,
  POS_CODES_LOADING,
  FETCH_FEES_LOADING,
  SET_FEE,
  CREATE_FEE,
  UNIFIED_LOADING,
  FETCH_UNIFIED,
  UNIFIED_LOADING_DETAILS,
  FETCH_UNIFIED_DETAILS,
  UNIFIED_LOADING_INGREDIENTS,
  FETCH_UNIFIED_INGREDIENTS,
  FETCH_SEARCH_INGREDIENTS,
  SEARCH_INGREDIENTS_LOADING,
  ADD_UNIFIED_INGREDIENT,
  EDIT_UNIFIED_INGREDIENT,
  DELETE_UNIFIED_INGREDIENT,
  CREATE_UNIFIED,
  UPDATE_UNIFIED,
  LOADING_FREESEARCH,
  ALLERGENS_LOADING,
  FETCH_ALLERGENS,
  UPDATE_ALLERGEN,
  ADD_ALLERGEN,
  FETCH_MEALS,
  FETCH_DISHES,
  MEALS_LOADING,
  DISHES_LOADING,
  UPDATE_MEAL,
  DUPLICATE_MEAL,
  UPDATE_DISH,
  DUPLICATE_DISH,
  SET_MEALS_PAGINATION,
  SET_MEALS_PAGE,
  SET_DISHES_PAGINATION,
  SET_DISHES_PAGE,
  SET_UNIFIED_PAGINATION,
  SET_UNIFIED_PAGE,
  FETCH_MEALS_FREESEARCH,
  SET_MEALS_FREE_SEARCH,
  FETCH_DISHES_FREESEARCH,
  SET_DISHES_FREE_SEARCH,
  FETCH_UNIFIED_FREESEARCH,
  SET_UNIFIED_FREE_SEARCH,
} from "../actions/productsAction/types";

const INITIAL_STATE = {
  tab: {
    id: TAB_DISHES,
    label: TAB_OPTIONS.find((option) => option.id === TAB_DISHES).label,
  },

  productDetails: {
    loading: false,
    data: {},
  },

  meals: {
    loading: false,
    data: [],
    //TODO
    mealDetails: {
      loading: false,
      data: {},
    },
    pagination: {
      current_page: 0,
      per_page: 100,
      total: 1,
    },
    freeSearch: {
      loading: false,
      data: [],
    },
  },

  dishes: {
    loading: false,
    data: [],
    //TODO
    dishDetails: {
      loading: false,
      data: {},
    },
    pagination: {
      current_page: 0,
      per_page: 100,
      total: 1,
    },
    freeSearch: {
      loading: false,
      data: [],
    },
  },

  unified: {
    loading: false,
    data: [],
    //TODO
    unifiedDetails: {
      loading: false,
      data: {},
    },
    pagination: {
      current_page: 0,
      per_page: 100,
      total: 1,
    },
    freeSearch: {
      loading: false,
      data: [],
    },
  },

  unifiedDetails: {
    loading: false,
    data: {},
  },
  categories: {
    loading: false,
    data: [],
  },
  allergens: {
    loading: false,
    data: [],
  },
  filter: {
    status: [2],
    branchId: [],
    categoryId: [],
    dishCode: [],
    dishIngredient: [],
  },

  posCodes: {
    loading: false,
    data: [],
  },
  fees: {
    loading: false,
    data: [],
  },
  freeSearchItems: {
    loading: false,
    data: [],
  },
  ingredient: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    // TAB
    case SELECTED_TAB:
      return { ...state, tab: { ...payload } };

    // MEALS
    case MEALS_LOADING:
      return {
        ...state,
        meals: { ...state.meals, loading: payload },
      };
    case FETCH_MEALS:
    case UPDATE_MEAL:
    case DUPLICATE_MEAL:
      return {
        ...state,
        meals: { ...state.meals, data: [...payload] },
      };

    case SET_MEALS_PAGINATION:
      return {
        ...state,
        meals: { ...state.meals, pagination: { ...payload } },
      };
    case SET_MEALS_PAGE:
      return {
        ...state,
        meals: {
          ...state.meals,
          pagination: {
            ...state.meals.pagination,
            [payload.name]: payload.value,
          },
        },
      };

    case LOADING_FREESEARCH:
      return {
        ...state,
        meals: {
          ...state.meals,
          freeSearch: {
            ...state.meals.freeSearch,
            loading: payload,
          },
        },
      };

    case FETCH_MEALS_FREESEARCH:
    case SET_MEALS_FREE_SEARCH:
      return {
        ...state,
        meals: {
          ...state.meals,
          freeSearch: {
            ...state.meals.freeSearch,
            data: [...payload],
          },
        },
      };

    // DISHES
    case DISHES_LOADING:
      return {
        ...state,
        dishes: { ...state.dishes, loading: payload },
      };

    case FETCH_DISHES:
    case UPDATE_DISH:
    case DUPLICATE_DISH:
      return {
        ...state,
        dishes: { ...state.dishes, data: [...payload] },
      };

    case SET_DISHES_PAGINATION:
      return {
        ...state,
        dishes: { ...state.dishes, pagination: { ...payload } },
      };
    case SET_DISHES_PAGE:
      return {
        ...state,
        dishes: {
          ...state.dishes,
          pagination: {
            ...state.dishes.pagination,
            [payload.name]: payload.value,
          },
        },
      };

    case LOADING_FREESEARCH:
      return {
        ...state,
        dishes: {
          ...state.dishes,
          freeSearch: {
            ...state.dishes.freeSearch,
            loading: payload,
          },
        },
      };

    case FETCH_DISHES_FREESEARCH:
    case SET_DISHES_FREE_SEARCH:
      return {
        ...state,
        dishes: {
          ...state.dishes,
          freeSearch: {
            ...state.dishes.freeSearch,
            data: [...payload],
          },
        },
      };

    // UNIFIED
    case UNIFIED_LOADING:
      return {
        ...state,
        unified: { ...state.unified, loading: payload },
      };

    case FETCH_UNIFIED:
    case UPDATE_UNIFIED:
      return {
        ...state,
        unified: { ...state.unified, data: [...payload] },
      };

    case SET_UNIFIED_PAGINATION:
      return {
        ...state,
        unified: { ...state.unified, pagination: { ...payload } },
      };
    case SET_UNIFIED_PAGE:
      return {
        ...state,
        unified: {
          ...state.unified,
          pagination: {
            ...state.unified.pagination,
            [payload.name]: payload.value,
          },
        },
      };

    case LOADING_FREESEARCH:
      return {
        ...state,
        unified: {
          ...state.unified,
          freeSearch: {
            ...state.unified.freeSearch,
            loading: payload,
          },
        },
      };

    case FETCH_UNIFIED_FREESEARCH:
    case SET_UNIFIED_FREE_SEARCH:
      return {
        ...state,
        unified: {
          ...state.unified,
          freeSearch: {
            ...state.unified.freeSearch,
            data: [...payload],
          },
        },
      };

    case UNIFIED_LOADING_DETAILS:
      return {
        ...state,
        unifiedDetails: { ...state.unifiedDetails, loading: payload },
      };

    case FETCH_UNIFIED_DETAILS:
    case CREATE_UNIFIED:
    case ADD_UNIFIED_INGREDIENT:
    case DELETE_UNIFIED_INGREDIENT:
      return {
        ...state,
        unifiedDetails: { ...state.unifiedDetails, data: { ...payload } },
      };

    case EDIT_UNIFIED_INGREDIENT:
      return {
        ...state,
        unifiedDetails: {
          ...state.unifiedDetails,
          data: { ...state.unifiedDetails.data, items: [...payload] },
        },
      };

    case SEARCH_INGREDIENTS_LOADING:
      return {
        ...state,
        freeSearchItems: { ...state.freeSearchItems, loading: payload },
      };

    case FETCH_SEARCH_INGREDIENTS:
      return {
        ...state,
        freeSearchItems: { ...state.freeSearchItems, data: [...payload] },
      };

    case CLREAR_FREE_SERACH:
      return {
        ...state,
        freeSearchItems: {
          loading: false,
          data: [],
        },
      };

    case SET_FILTER:
      return {
        ...state,
        filter: { ...state.filter, [payload.name]: payload.value },
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: INITIAL_STATE.filter,
      };

    case CLOSE_FORM:
      return {
        ...state,
        productDetails: {
          loading: false,
          data: {},
        },
        unifiedDetails: {
          loading: false,
          data: {},
        },
        fees: {
          loading: false,
          data: [],
        },
        ingredient: {},
      };

    case SET_INGREDIENT:
      return { ...state, ingredient: { ...payload } };

    case CATEGORIES_LOADING:
      return {
        ...state,
        categories: { ...state.categories, loading: payload },
      };

    case FETCH_CATEGORIES:
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: { ...state.categories, data: [...payload] },
      };

    case ADD_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: [...state.categories.data, { ...payload }],
        },
      };

    case ALLERGENS_LOADING:
      return {
        ...state,
        allergens: { ...state.allergens, loading: payload },
      };

    case FETCH_ALLERGENS:
    case UPDATE_ALLERGEN:
      return {
        ...state,
        allergens: { ...state.allergens, data: [...payload] },
      };

    case ADD_ALLERGEN:
      return {
        ...state,
        allergens: {
          ...state.allergens,
          data: [...state.allergens.data, { ...payload }],
        },
      };

    case POS_CODES_LOADING:
      return {
        ...state,
        posCodes: { ...state.posCodes, loading: payload },
      };

    case FETCH_POS_CODES:
      return {
        ...state,
        posCodes: { ...state.posCodes, data: [...payload] },
      };

    case FETCH_FEES_LOADING:
    case CREATE_FEE_LOADING:
      return {
        ...state,
        fees: { ...state.fees, loading: payload },
      };

    case FETCH_FEES:
    case SET_FEE:
      return {
        ...state,
        fees: { ...state.fees, data: [...payload] },
      };
    case CREATE_FEE:
      return {
        ...state,
        fees: { ...state.fees, data: [...state.fees.data, payload] },
      };

    case PRODUCT_DETAILS_LOADING:
      return {
        ...state,
        productDetails: { ...state.productDetails, loading: payload },
      };

    case FETCH_PRODUCT:
    case ADD_PRODUCT_INGREDIENT:
    case DELETE_PRODUCT_INGREDIENT:
    case EDIT_PRODUCT_INGREDIENT:
    case CREATE_PRODUCT:
      return {
        ...state,
        productDetails: { ...state.productDetails, data: { ...payload } },
      };

    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
