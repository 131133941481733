import { reverse } from "lodash";
import React, { useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { COLOR_SUBMIT } from "../const/colors";
import { getSafe } from "../helpers";
import { useScreenSize } from "../Hooks";
import { ButtonGroup } from "./buttons/ButtonGroup";

const PageNav = ({ options = [], default_selected = null, onClick }) => {
  const [selected, setSelected] = useState(options[0]);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (default_selected) {
      setSelected(default_selected);
    }
  }, [default_selected]);

  const handleOptionClicked = (selected) => {
    setSelected(selected);
    onClick(selected);
  };

  if (!isMobile) {
    return (
      <div>
        <ButtonGroup jumps={200} padding={"4rem"}>
          {options.map((option, index) => (
            <button
              type="button"
              onClick={() => handleOptionClicked(option)}
              key={index}
              className={`page-nav--option cursor-pointer btn--hover__submit ${
                getSafe(() => option.name === selected.name)
                  ? "page-nav--option__selected"
                  : ""
              }`}
            >
              {isMobile
                ? I18n.t(`pageNav.${option.name}`).replace("דוח ", "")
                : I18n.t(`pageNav.${option.name}`)}
            </button>
          ))}
        </ButtonGroup>
      </div>
    );
  } else {
    return (
      <div className="flex-between-center fluid margin-y-05">
        <i
          onClick={() => {
            handleOptionClicked(options[selected.index - 1]);
          }}
          style={{ color: COLOR_SUBMIT, fontSize: "2.2rem" }}
          className={selected.index - 1 < 0 ? "" : "fa fa-chevron-right"}
        />
        <h2 className="page-nav--option__selected">
          {I18n.t(`pageNav.${selected.name}`)}
        </h2>
        <i
          onClick={() => {
            handleOptionClicked(options[selected.index + 1]);
          }}
          style={{ color: COLOR_SUBMIT, fontSize: "2.2rem" }}
          className={
            selected.index + 1 === options.length ? "" : "fa fa-chevron-left"
          }
        />
      </div>
    );
  }
};

export default PageNav;
