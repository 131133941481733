import { TextField } from "@mui/material";
import Axios from "axios";
import { chunk, cloneDeep, isEmpty, mapKeys, uniq } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { FaCheck, FaTimes, FaUserLock } from "react-icons/fa";
import { connect } from "react-redux";
import Button from "../../../components/buttons/Button";
import DropdownSelect from "../../../components/dropdown/DropdownSelect";
import SwitchInput from "../../../components/inputs/SwitchInput";
import PageNav from "../../../components/PageNav";
import BtnTooltip from "../../../components/tooltips/BtnTooltip";
import { COLOR_WARNINGS } from "../../../const/colors";
import { getSafe } from "../../../helpers";
import { useScreenSize } from "../../../Hooks";
import { logoutAdmin } from "../../../redux/actions/AdminLoginAction";
import { setConfirm } from "../../../redux/actions/confirmAction";
import { setDialogModal } from "../../../redux/actions/DialogAction";
import { setError } from "../../../redux/actions/errorAction";
import { startLoading, stopLoading } from "../../../redux/actions/loaderAction";

const setPermissionsHierarchy = async (array) => {
  const clone_array = cloneDeep(array);
  let titles = await mapKeys(clone_array, "privilege_title_id");

  titles = await Object.values(titles).sort(
    (a, b) => a.privilege_title_id - b.privilege_title_id
  );

  titles = await titles.map((item) => {
    const { privilege_title_id, privilege_title_name } = item;
    return {
      name: privilege_title_name || "כללי",
      id: privilege_title_id,
      items: [
        ...clone_array
          .map((item, index) => {
            if (item.privilege_title_id === privilege_title_id) {
              return item;
            } else return null;
          })
          .filter((el) => el !== null),
      ],
    };
  });
  return titles;
};

const PermissionsForm = ({
  pages_permissions,
  tabs_permissions,
  base_permissions,
  other_permissions,
  privileges,
  branch_id,
  network_id,
  branch_name,
  network_name,
  token,
  admin_id,
  logoutAdmin,
  setConfirm,
  privilegesObject,
  setDialogModal,
  setError,
  stopLoading,
  startLoading,
}) => {
  const { isMobile } = useScreenSize();
  const [selectedPage, setSelectedPage] = useState({ name: "pages", index: 0 });
  const [checkboxForm, setCheckboxForm] = useState([]);
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [saveForAll, setSaveForAll] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({
    id: -99,
    name: "בחר חבילה",
  });
  const [description, setDescription] = useState("");

  const FORM_VALUES_INIT = {
    pages: [],
    tabs: [],
    base: [],
    other: [],
  };
  const [formValues, setFormValues] = useState({});
  const [lastSaved, setLastSaved] = useState({});
  const [packages, setPackages] = useState([]);

  const fetchPackages = useMemo(
    () => async () => {
      try {
        const { data } = await Axios.get(`admin/packages`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPackages([{ id: -99, name: "בחר חבילה" }, ...data]);
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logoutAdmin();
          } else if (e.response.status === 500) {
            setError("", "ארעה שגיאה בשרת");
          }
      }
    },
    []
  );

  useEffect(() => {
    fetchPackages();
  }, []);

  const constructDefaultChecked = useMemo(
    () => async () => {
      if (!privileges) return;
      let defaultFormValues = {};
      await privileges.forEach((x) => {
        defaultFormValues[x.id] = FORM_VALUES_INIT;
      });
      setFormValues(defaultFormValues);
    },
    [privilegesObject]
  );

  const loadFromPackage = useMemo(
    () => async (packageObj) => {
      let FormValues = {};
      for (const [key, value] of Object.entries(packageObj)) {
        FormValues[key] = { ...FORM_VALUES_INIT, ...value };
      }
      setFormValues(FormValues);
    },
    []
  );

  useEffect(() => {
    constructDefaultChecked();
  }, [constructDefaultChecked]);

  useEffect(() => {
    if (
      !isEmpty(selectedPackage) &&
      selectedPackage.data &&
      selectedPackage.id !== -99
    ) {
      loadFromPackage(selectedPackage.data);
    } else {
      setFormValues(lastSaved);
    }
  }, [lastSaved, selectedPackage]);

  const fetchData = useMemo(
    () => async () => {
      try {
        if (!branch_id) return setLastSaved({});
        const response = await Axios.post(
          `admin/branches/${branch_id}/permissions`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setLastSaved(response.data);
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logoutAdmin();
          } else if (e.response.status === 500) {
            setError("", "ארעה שגיאה בשרת");
          }
      }
    },
    [branch_id, logoutAdmin, token]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onCheckboxChange = (permission_id, value) => {
    const page = selectedPage.name;
    const privilege_id = selectedPrivilege.id;
    const alreadyExists = getSafe(() => formValues[privilege_id][page]);
    if (alreadyExists) {
      if (alreadyExists.includes(permission_id) && !value) {
        setFormValues({
          ...formValues,
          [privilege_id]: {
            ...formValues[privilege_id],
            [page]: formValues[privilege_id][page].filter(
              (id) => id !== permission_id
            ),
          },
        });
      } else {
        setFormValues({
          ...formValues,
          [privilege_id]: {
            ...formValues[privilege_id],
            [page]: [
              ...(formValues[privilege_id][page]
                ? formValues[privilege_id][page]
                : []),
              permission_id,
            ],
          },
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [privilege_id]: {
          ...(formValues[privilege_id] ? formValues[privilege_id] : {}),
          [page]: [permission_id],
        },
      });
    }
  };

  const onCheckboxChangeAll = (permission_ids_array, value) => {
    const page = selectedPage.name;
    const privilege_id = selectedPrivilege.id;
    const alreadyExists = getSafe(() => formValues[privilege_id][page]);
    if (alreadyExists) {
      if (!value) {
        setFormValues({
          ...formValues,
          [privilege_id]: {
            ...formValues[privilege_id],
            [page]: formValues[privilege_id][page].filter(
              (id) => !permission_ids_array.includes(id)
            ),
          },
        });
      } else {
        setFormValues({
          ...formValues,
          [privilege_id]: {
            ...formValues[privilege_id],
            [page]: uniq([
              ...(formValues[privilege_id][page]
                ? formValues[privilege_id][page]
                : []),
              ...permission_ids_array,
            ]),
          },
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [privilege_id]: {
          ...(formValues[privilege_id] ? formValues[privilege_id] : {}),
          [page]: [permission_ids_array],
        },
      });
    }
  };

  useEffect(() => {
    const run = async () => {
      let array = [];
      switch (selectedPage.name) {
        case "pages":
          array = pages_permissions;
          break;
        case "tabs":
          array = tabs_permissions;
          break;
        case "base":
          array = base_permissions;
          break;
        case "other":
          array = other_permissions;
          break;
        default:
          return;
      }
      array = await setPermissionsHierarchy(array);
      setCheckboxForm(array);
    };
    run();
  }, [
    selectedPage,
    pages_permissions,
    tabs_permissions,
    base_permissions,
    other_permissions,
  ]);
  const save = async () => {
    try {
      if (!branch_id) return;

      startLoading("מעדכן הרשאות...");
      await Axios.post(
        `admin/branches/${branch_id}/permissions/update`,
        {
          saveForAll,
          data: formValues,
          selectedPackage: selectedPackage.id !== -99 && selectedPackage.id,
          description,
          admin_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setConfirm("הרשאות עודכנו בהצלחה");
      setLastSaved(formValues);
      setSelectedPackage({ id: -99, name: "בחר חבילה" });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          logoutAdmin();
        } else if (e.response.status === 500) {
          setError("", "ארעה שגיאה בשרת");
        }
    }
    stopLoading();
  };

  const handleSubmit = () => {
    setDialogModal({
      onConfirm: save,
      iconJSX: () => FaUserLock({ size: "7em", color: COLOR_WARNINGS }),
      title: `שינוי הרשאות`,
      text: `האם את/ה בטוח/ה בהגדרת הרשאות אלו ל${
        saveForAll ? "כל הסניפים ברשת" : "סניף"
      } ${saveForAll ? network_name : branch_name}?`,
      textArea: (
        <TextField
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          multiline
          rows={5}
          maxRows={9}
          label="פירוט קצר"
        />
      ),
    });
  };

  const checkboxFormRender = useMemo(() => {
    let result = checkboxForm.map((x) => ({
      ...x,
      items: x.items.filter((item) => {
        if (selectedPrivilege) {
          return getSafe(
            () =>
              !privilegesObject[selectedPrivilege.id][
                `disabled_${selectedPage.name}`
              ].includes(selectedPage.name === "pages" ? item.id : item.index),
            true
          );
        } else return true;
      }),
    }));
    return result.filter((x) => x.items.length);
  }, [checkboxForm, selectedPage, selectedPrivilege, privilegesObject]);

  const renderForm = (checkedByUser) => {
    return chunk(checkboxFormRender, 5).map((chunk, chunk_idx) => (
      <Row key={chunk_idx}>
        {chunk.map((title, row_index) => {
          const items_ids_array = title.items.map((x) => x.id);
          return (
            <Col className="permissions--form-card" key={row_index}>
              <h2 style={{ width: "100%" }}>{title.name}</h2>
              <Row>
                <div className="flex-start" style={{ width: "100%" }}>
                  <SwitchInput
                    no_color={true}
                    checked_default={items_ids_array.every((id) =>
                      checkedByUser.includes(id)
                    )}
                    onChange={(value) =>
                      onCheckboxChangeAll(items_ids_array, value)
                    }
                    label="סמן הכל"
                    strong={true}
                  />
                </div>
              </Row>
              {title.items.map((item, col_index) => {
                return (
                  <Row key={`${selectedPage.name}${item.id}`}>
                    <div className="flex-start" style={{ width: "100%" }}>
                      {item.spec ? (
                        <BtnTooltip text={item.spec}>
                          <i
                            style={{ fontSize: "1.6rem" }}
                            className="fas fa-info-circle"
                          ></i>
                        </BtnTooltip>
                      ) : (
                        ""
                      )}
                      <SwitchInput
                        checked_default={getSafe(() =>
                          checkedByUser.includes(item.id)
                        )}
                        onChange={(value) => onCheckboxChange(item.id, value)}
                        label={
                          selectedPage.name === "pages"
                            ? item.hebrewName
                            : item.name
                        }
                      />
                    </div>
                  </Row>
                );
              })}
            </Col>
          );
        })}
      </Row>
    ));
  };

  const nav_options = useMemo(() => ["pages", "tabs", "base", "other"], []);

  const handleSetAll = useMemo(
    () =>
      (add = true) => {
        const page = selectedPage.name;
        const privilege_id = selectedPrivilege.id;

        if (add) {
          const filled = [].concat.apply(
            [],
            checkboxFormRender.map((x) => x.items.map((y) => y.id))
          );

          setFormValues({
            ...formValues,
            [privilege_id]: {
              ...formValues[privilege_id],
              [page]: filled,
            },
          });
        } else {
          setFormValues({
            ...formValues,
            [privilege_id]: {
              ...formValues[privilege_id],
              [page]: [],
            },
          });
        }
      },
    [checkboxFormRender, formValues, selectedPage, selectedPrivilege]
  );

  return (
    <div>
      {isMobile ? (
        <PageNav
          isMobile={isMobile}
          options={nav_options.map((name, index) => ({ name, index }))}
          default_selected={selectedPage}
          onClick={setSelectedPage}
        />
      ) : (
        ""
      )}
      <div className="permissions--form-filter">
        <div
          className="flex-center"
          style={{
            marginLeft: "0.5rem",
            padding: "1rem",
            border: "2px solid #EAEAEA",
            borderRadius: "5px",
          }}
        >
          <SwitchInput
            onChange={(value) => setSaveForAll(value)}
            label="שמור עבור כלל הרשת"
          />
        </div>
        <div
          style={{
            marginLeft: "0.5rem",
          }}
        >
          <DropdownSelect
            options={privileges}
            selected={selectedPrivilege}
            setSelected={setSelectedPrivilege}
          />
        </div>
        <div
          style={{
            marginLeft: "0.5rem",
          }}
        >
          <DropdownSelect
            setSelected={setSelectedPackage}
            options={packages}
            selected={selectedPackage}
          />
        </div>
        <div>
          <Button
            className="btn--submit"
            onClick={handleSubmit}
            textValue="שמור"
          />
        </div>
      </div>

      {isMobile ? (
        ""
      ) : (
        <PageNav
          isMobile={isMobile}
          options={nav_options.map((name, index) => ({ name, index }))}
          default_selected={selectedPage}
          onClick={setSelectedPage}
        />
      )}

      <div className="permissions--cards-container">
        {checkboxFormRender.length ? (
          <div
            className={`flex-center ${isMobile ? "margin-top" : "fit-content"}`}
          >
            <Button
              className="btn--outline btn--outline__confirm"
              icon={FaCheck}
              textValue="סמן הכל"
              onClick={() => handleSetAll()}
            />
            <Button
              className="btn--outline btn--outline__abort"
              icon={FaTimes}
              textValue="הסר הכל"
              onClick={() => handleSetAll(false)}
            />
          </div>
        ) : (
          ""
        )}

        {renderForm(
          getSafe(
            () =>
              formValues[selectedPrivilege.id][selectedPage.name]
                ? formValues[selectedPrivilege.id][selectedPage.name]
                : [],
            []
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isMobile } = state.publicValues;
  const { token } = state.authAdmin;
  const admin_id = state.authAdmin.admin.id;
  let {
    pages_permissions,
    tabs_permissions,
    base_permissions,
    other_permissions,
  } = state.admin;

  const privilegesObject = state.admin.privileges;
  const privileges = Object.values(privilegesObject);

  pages_permissions = Object.values(pages_permissions);
  tabs_permissions = Object.values(tabs_permissions);
  base_permissions = Object.values(base_permissions);
  other_permissions = Object.values(other_permissions);

  return {
    token,
    admin_id,
    isMobile,
    pages_permissions,
    tabs_permissions,
    base_permissions,
    other_permissions,
    privileges,
    privilegesObject,
  };
};

export default connect(mapStateToProps, {
  logoutAdmin,
  setConfirm,
  setDialogModal,
  setError,
  stopLoading,
  startLoading,
})(PermissionsForm);
