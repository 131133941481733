import React from "react";
import { connect } from "react-redux";
import ModalTipAproove from "../pages/schedule/employee/modals/ModalTipAproove";

const TipsAproove = ({ children }) => {
  return (
    <div>
      <ModalTipAproove required />
      {children}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TipsAproove);
