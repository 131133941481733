export const LOAD_EMPLOYEE_DATA = "LOAD_EMPLOYEE_DATA";
export const LOAD_DEFAULT_EMAILS = "LOAD_DEFAULT_EMAILS";
export const UPDATE_RELATION_ID = "UPDATE_RELATION_ID";
export const ADD_RELATION = "ADD_RELATION";
export const UPDATE_RELATION_ARRAY = "UPDATE_RELATION_ARRAY";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const SET_SPOUSE_DETAILS_FULL = "SET_SPOUSE_DETAILS_FULL";
export const INITIALIZE_STATE = "INITIALIZE_STATE";
export const SET_EXTRA_FILES = "SET_EXTRA_FILES";
export const LOAD_BANKS = "LOAD_BANKS";
