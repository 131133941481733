import { Typography } from "@mui/material";
import Axios from "axios";
import { LOGOUT_ADMIN } from "../AdminLoginAction/types";
import { SET_CONFIRM } from "../confirmAction/types";
import { setDialogModal } from "../DialogAction";
import { setError } from "../errorAction";
import { SET_ERROR } from "../errorAction/types";
import {
  FETCH_USERS,
  FETCH_PRIVILEGES,
  FETCH_PERMISSIONS,
  FETCH_BRANCHES_ADMIN,
} from "./types";

export const getUsers = (params) => async (dispatch, getState) => {
  try {
    const response = await Axios.get("admin/users", {
      params,
      headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
    });

    dispatch({ type: FETCH_USERS, payload: response.data });
  } catch (e) {
    console.error(e);
    if (e.response) {
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT_ADMIN });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: "",
        });
      }
    }
  }
};

export const fetchPrivileges = () => async (dispatch, getState) => {
  try {
    const response = await Axios.get("admin/users/privileges", {
      headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
    });

    dispatch({ type: FETCH_PRIVILEGES, payload: response.data });
  } catch (e) {
    console.error(e);
    if (e.response) {
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT_ADMIN });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: "",
        });
      }
    }
  }
};

export const clearPrivileges = () => (dispatch) => {
  dispatch({ type: FETCH_PRIVILEGES, payload: {} });
};
export const clearPermissions = () => (dispatch) => {
  dispatch({ type: FETCH_PERMISSIONS, payload: {} });
};

export const fetchPermissions = () => async (dispatch, getState) => {
  try {
    const response = await Axios.get("admin/permissions", {
      headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
    });

    dispatch({ type: FETCH_PERMISSIONS, payload: { ...response.data } });
  } catch (e) {
    if (e.response) {
      switch (e?.response?.status) {
        case 401:
          dispatch({ type: LOGOUT_ADMIN });
          break;
        default:
          dispatch(setError());
          break;
      }
    }
  }
};
export const fetchBranches = () => async (dispatch, getState) => {
  try {
    const response = await Axios.get("admin/branches", {
      headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
    });

    dispatch({ type: FETCH_BRANCHES_ADMIN, payload: { ...response.data } });
  } catch (e) {
    console.error(e);
    if (e.response) {
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT_ADMIN });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: "",
        });
      }
    }
  }
};

export const resetUserPassword =
  (user_id = null, sending_method = "email", isDefault = false) =>
  async (dispatch, getState) => {
    try {
      const admin_id = getState().authAdmin.admin.login_admin_web_id;
      if (!user_id) throw new Error("user_id is undefined");
      const response = await Axios.post(
        `admin/users/${user_id}/resetpass`,
        {
          sending_method,
          isDefault,
          user_id,
          admin_id,
        },
        {
          headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
        }
      );

      dispatch(
        setDialogModal({
          title: "סיסמתך החדשה היא:",
          text: (
            <Typography style={{ fontSize: "2rem", fontWeight: "bold" }}>
              {response.data}
            </Typography>
          ),
        })
      );
      // dispatch({
      //   type: SET_CONFIRM,
      //   confirmTitle: "סיסמא אופסה בהצלחה",
      //   confirm: ``,
      // });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT_ADMIN });
        } else if (e.response.status === 409) {
          let msg = "";
          if (e.response.data.code === 1) msg = "אימייל משתמש לא תקין";
          else if (e.response.data.code === 2)
            msg = "מספר טלפון של משתמש לא תקין";
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת מערכת`,
            error: msg,
          });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: "",
          });
        }
    }
  };

export const sendSmsToUserFromAdmin =
  ({ employee_id, text }) =>
  async (dispatch, getState) => {
    try {
      if (!employee_id) throw new Error("user_id is undefined");
      const response = await Axios.post(
        `admin/users/${employee_id}/sendSms`,
        {
          text,
        },
        {
          headers: { Authorization: `Bearer ${getState().authAdmin.token}` },
        }
      );

      dispatch({
        type: SET_CONFIRM,
        confirmTitle: "SMS נשלח",
        confirm: ``,
      });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT_ADMIN });
        } else if (e.response.status === 409) {
          let msg = "מספר טלפון של משתמש לא תקין";
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת מערכת`,
            error: msg,
          });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: "",
          });
        }
    }
  };
