import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const EMPLOYEE_CARD_SETTINGS_URL = "employeeCardFormSettings";
export const BREAK_CODE_DEFAULT = 0;
export const SUM_NUMBER_DEFAULT = 13;
export const EXCEL_DOWNLOAD_URL = "downloadExcel";
export const COLOR_SUBMIT = "#00a2ea";
export const ConfirmButton = styled(Button)({
  color: "#FFFFFF",
  backgroundColor: "#00a2ea",
  "&:hover": {
    backgroundColor: "#47cc00",
  },
});

export const CancelButton = styled(Button)({
  color: "#FFFFFF",
  backgroundColor: "#838383",
  "&:hover": {
    backgroundColor: "#585858",
  },
});

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
