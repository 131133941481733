import { IconButton, Tooltip, Typography } from "@mui/material";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaRegCheckCircle, FaRegTimesCircle, FaTrash } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import Button from "../../../../../components/buttons/Button";
import PopoverEllipsisButton from "../../../../../components/popover/PopoverEllipsisButton";
import { COLOR_ABORT, COLOR_CONFIRM } from "../../../../../const/colors";
import { fixDateHours } from "../../../../../helpers";
import {
  MAKOR_PROVIDER,
  RESTIGO_ATTENDANCE_APP_PROVIDER,
} from "../AttendanceClocks";
import EditAttendanceAppModel from "./EditAttendanceAppModel";
import EditMakorClientModel from "./EditMakorClientModel";

const commonColumns = [
  {
    Header: "מזהה סניף",
    accessor: "branch_id",
    noSort: true,
  },
  {
    Header: "שם סניף",
    accessor: "branch_name",
  },
  {
    Header: "מזהה רשת",
    accessor: "network_id",
    noSort: true,
  },
  {
    Header: "שם רשת",
    accessor: "network_name",
    noSort: true,
  },
];

export const makorColumns = [
  ...commonColumns,
  {
    Header: "ניסיון עיבוד נתונים אחרון",
    accessor: "last_processing_employees_time",
    noSort: true,
    Cell: ({ value }) => value ? fixDateHours(value) : 'לא קיים',
  },
  {
    Header: "סטטוס",
    accessor: "enabled",
    Cell: ({ value, row, handleStatusClick }) => {
      const { id } = row.original;
      const clockState = value ? "מחובר" : "מנותק";
      const action = value ? "לנתק" : "לחבר";
      return (
        <Tooltip
          arrow
          placement="right"
          title={
            <Typography align="left" style={{ color: "white" }}>
              {`שעון ${clockState} לחץ כדי ${action}`}
            </Typography>
          }
        >
          <IconButton
            onClick={() => handleStatusClick(id, MAKOR_PROVIDER, !value)}
          >
            {value ? (
              <FaRegCheckCircle color={COLOR_CONFIRM} />
            ) : (
              <GiCancel color={COLOR_ABORT} />
            )}
          </IconButton>
        </Tooltip>
      );
    },
  },
  {
    Header: "פעולות",
    accessor: "actions",
    noSort: true,
    Cell: ({ row, updateDataAfterServerCall, handleDeleteClick }) => {
      const { id } = row.original;
      return (
        <>
          <EditMakorClientModel
            makorClient={row.original}
            updateDataAfterServerCall={updateDataAfterServerCall}
          />
          <IconButton onClick={() => handleDeleteClick(id, MAKOR_PROVIDER)}>
            <FaTrash />
          </IconButton>
        </>
      );
    },
  },
];

const commonColumnsMobile = [
  {
    Header: "מידע",
    accessor: "data",
    noSort: true,
    Cell: ({ row }) => {
      const { branch_id, branch_name, network_name, enabled } = row.original;

      return (
        <div className="flex-column-right">
          <h5>{`${branch_name} - ${network_name} (${branch_id})`}</h5>

          <div className="flex-start">
            {enabled ? (
              <>
                <FaRegCheckCircle
                  style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                  color={COLOR_CONFIRM}
                />
                <p style={{ marginTop: "0.3rem" }}>מחובר</p>
              </>
            ) : (
              <>
                <GiCancel
                  style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                  color={COLOR_ABORT}
                />
                <p style={{ marginTop: "0.3rem" }}>מנותק</p>
              </>
            )}
          </div>
        </div>
      );
    },
  },
];

export const makorColumnsMobile = [
  ...commonColumnsMobile,
  {
    Header: "פעולות",
    accessor: "actions",
    noSort: true,
    Cell: ({
      row,
      handleStatusClick,
      updateDataAfterServerCall,
      handleDeleteClick,
    }) => {
      const { id, enabled } = row.original;
      return (
        <div className="flex-end">
          <EditMakorClientModel
            makorClient={row.original}
            updateDataAfterServerCall={updateDataAfterServerCall}
          />
          <PopoverEllipsisButton>
            <Button
              onClick={() => handleStatusClick(id, MAKOR_PROVIDER, !enabled)}
              className="padding-05"
              iconSize="1.2em"
              icon={enabled ? GiCancel : FaRegCheckCircle}
              textValue={enabled ? "ניתוק" : "הפעלה"}
            />
            <Button
              className="padding-05"
              iconSize="1.2em"
              onClick={() => handleDeleteClick(id, MAKOR_PROVIDER)}
              icon={FaTrash}
              textValue="מחיקה"
            />
          </PopoverEllipsisButton>
        </div>
      );
    },
  },
];

export const restigoAttendanceAppColumns = [
  ...commonColumns,
  {
    Header: "סטטוס",
    accessor: "enabled",
    Cell: ({ value, row, handleStatusClick }) => {
      const { id } = row.original;
      const clockState = value ? "מחובר" : "מנותק";
      const action = value ? "לנתק" : "לחבר";
      return (
        <Tooltip
          arrow
          placement="right"
          title={
            <Typography align="left" style={{ color: "white" }}>
              {`שעון ${clockState} לחץ כדי ${action}`}
            </Typography>
          }
        >
          <IconButton
            onClick={() =>
              handleStatusClick(id, RESTIGO_ATTENDANCE_APP_PROVIDER, !value)
            }
          >
            {value ? (
              <FaRegCheckCircle color={COLOR_CONFIRM} />
            ) : (
              <GiCancel color={COLOR_ABORT} />
            )}
          </IconButton>
        </Tooltip>
      );
    },
  },
  {
    Header: "פעולות",
    accessor: "actions",
    noSort: true,
    Cell: ({ row, updateDataAfterServerCall, handleDeleteClick }) => {
      const { id } = row.original;
      return (
        <>
          <EditAttendanceAppModel
            attendanceApp={row.original}
            updateDataAfterServerCall={updateDataAfterServerCall}
          />
          <IconButton
            onClick={() =>
              handleDeleteClick(id, RESTIGO_ATTENDANCE_APP_PROVIDER)
            }
          >
            <FaTrash />
          </IconButton>
        </>
      );
    },
  },
];

export const restigoAttendanceAppColumnsMobile = [
  ...commonColumnsMobile,
  {
    Header: "פעולות",
    accessor: "actions",
    noSort: true,
    Cell: ({
      row,
      handleStatusClick,
      updateDataAfterServerCall,
      handleDeleteClick,
    }) => {
      const { id, enabled } = row.original;
      return (
        <div className="flex-end">
           <EditAttendanceAppModel
            attendanceApp={row.original}
            updateDataAfterServerCall={updateDataAfterServerCall}
          />
          <PopoverEllipsisButton>
            <Button
              onClick={() => handleStatusClick(id, RESTIGO_ATTENDANCE_APP_PROVIDER, !enabled)}
              className="padding-05"
              iconSize="1.2em"
              icon={enabled ? GiCancel : FaRegCheckCircle}
              textValue={enabled ? "ניתוק" : "הפעלה"}
            />
            <Button
              className="padding-05"
              iconSize="1.2em"
              onClick={() => handleDeleteClick(id, RESTIGO_ATTENDANCE_APP_PROVIDER)}
              icon={FaTrash}
              textValue="מחיקה"
            />
          </PopoverEllipsisButton>
        </div>
      );
    },
  },
];
