import { Button } from "@mui/material";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import Axios from "axios";
import { useEffect, useState } from "react";

const Fallback = ({ error }) => {
  const token = useSelector(({ auth }) => auth.token);
  const [didAutoReloadFail, setDidAutoReloadFail] = useState(false);

  useEffect(() => {
    handleAutoReload();
  }, []);

  const sendLogError = () => {
    Axios.post(
      "/clientLogs/renderCrash",
      { trace: error.stack },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  const handleAutoReload = () => {
    const pageRefreshedKey = "page-has-been-force-refreshed";

    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(pageRefreshedKey) || "false"
    );

    if (!pageHasAlreadyBeenForceRefreshed) {
      window.localStorage.setItem(pageRefreshedKey, "true");
      setDidAutoReloadFail(false);
      window.location.reload();
    } else {
      window.localStorage.setItem(pageRefreshedKey, "false");
      setDidAutoReloadFail(true);
      sendLogError();
    }
  };

  return (
    didAutoReloadFail && (
      <div className="flex-column-center" style={{ marginTop: "20px" }}>
        <h1>גירסה חדשה עלתה, נדרש לבצע רענון</h1>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
        >
          לרענון לחץ כאן
        </Button>
      </div>
    )
  );
};

export default Fallback;
