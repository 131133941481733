import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { FaUserTag } from "react-icons/fa";
import ReactTable from "../../../components/tables/ReactTable";
import { dHiddenColumns, dDefaultVisibleColumns } from "../columnDepByDate";
import { BsArrowReturnLeft } from "react-icons/bs";

const SimpleDisplayTable = ({
  hour,
  data,
  onClose,
  message = true,
  title,
  parentTitle,
  columns,
  modalHiddenColumns,
  setModalHiddenColumns,
}) => {
  const [modalVisible, setModalVisible] = useState(true);
  const [child, setChild] = useState(message);
  const [childData, setcChildData] = useState({});

  const oDisplayTableMemo = (d, t, c, m, h = [], dv = []) => {
    setChild(true);
    setcChildData({ d, t, c, m, h, dv, pdata: data });
  };
  const excelAgg = [
    { field: "travel_cost", aggregate: "sum" },
    { field: "meal_cost", aggregate: "sum" },
    { field: "health_cost", aggregate: "sum" },
    { field: "holiday_cost", aggregate: "sum" },
    { field: "global_cost", aggregate: "sum" },
    { field: "hours_200", aggregate: "sum" },
    { field: "hours_100", aggregate: "sum" },
    { field: "hours_125", aggregate: "sum" },
    { field: "hours_150_shabat", aggregate: "sum" },
    { field: "hours_150", aggregate: "sum" },
    { field: "hours_175", aggregate: "sum" },
    { field: "hours_200", aggregate: "sum" },
    { field: "break", aggregate: "sum" },
    { field: "hours_total", aggregate: "sum" },
    { field: "tip_complete_cost", aggregate: "sum" },
    { field: "bonus_cost", aggregate: "sum" },
    { field: "total_cost_after_employer_cost", aggregate: "sum" },
    { field: "employer_cost", aggregate: "sum" },
    { field: "total_cost_before_employer_cost", aggregate: "sum" },
  ];
  //console.log(modalHiddenColumns);
  if (!child)
    return (
      <Modal
        animationDuration={200}
        styles={{
          modal: {
            padding: "3.5rem",
            maxWidth: "fit-content",
          },
        }}
        //visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        open={modalVisible}
        center={true}
        onClose={() => onClose()}
      >
        <div className="flex-column-center">
          <div className="fluid">
            <h2>
              <FaUserTag fill="#00A2DE"> </FaUserTag>
              {parentTitle}
            </h2>
          </div>

          <ReactTable
            {...{
              styles: {
                wrapper: {
                  maxHeight: "calc(100vh - 10rem)",
                  maxWidth: "calc(100vw - 23.4rem)",
                },
                container: {
                  maxHeight: "calc(100vh - 23.4rem)",
                  maxWidth: "calc(100vw - 23.4rem)",
                },
                MuiPopoverRoot: { zIndex: "99999999 !important" },
              },
              //sortBy:[{ id: "department_name" }],
              cellsPropsById: (id) => {
                if (
                  ["full_name"].includes(id) ||
                  ["date"].includes(id) ||
                  ["department_name"].includes(id)
                ) {
                  return { oDisplayTableMemo };
                }
              },
              customFooterTitle: "סה״כ",
              aggregates: excelAgg,
              showFooter: true,
              translation_endpoint: "expenseDashboardDatesDepartments",
              hiddenColumns: dHiddenColumns,
              defaultVisibleColumns: modalHiddenColumns.length
                ? modalHiddenColumns
                : dDefaultVisibleColumns,
              title,
              columns,
              data: data,
              isExportedToExcel: true,
              setModalHiddenColumns,
            }}
          />
        </div>
      </Modal>
    );
  else
    return (
      <Modal
        animationDuration={200}
        styles={{
          modal: {
            padding: "3.5rem",
            maxWidth: "fit-content",
          },
        }}
        //visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        open={modalVisible}
        center={true}
        onClose={() => onClose()}
      >
        <div className="flex-column-center">
          <div className="fluid">
            <h2>
              <FaUserTag fill="#00A2DE"> </FaUserTag>
              {childData?.t}
            </h2>
          </div>
          <BsArrowReturnLeft
            fill="black"
            style={{
              cursor: "pointer",
              fontSize: "20px",
              left: "-42%",
              top: "-32px",
              position: "relative",
              color: "black",
            }}
            onClick={() => {
              setChild(false);
            }}
          ></BsArrowReturnLeft>
          <ReactTable
            {...{
              customFooterTitle: "סה״כ",
              aggregates: excelAgg,
              styles: {
                wrapper: {
                  maxHeight: "calc(100vh - 10rem)",
                  maxWidth: "calc(100vw - 23.4rem)",
                },
                container: {
                  maxHeight: "calc(100vh - 23.4rem)",
                  maxWidth: "calc(100vw - 23.4rem)",
                },
                model: { zIndex: 9999999 },
              },
              translation_endpoint: "expenseDashboardDatesDepartments",
              isPaginate: true,
              showFooter: true,
              showCheckbox: false,
              stickyHeader: true,
              isExportedToExcel: true,
              title: childData?.t,
              columns: childData?.c,
              data: childData?.d,
              hiddenColumns: dHiddenColumns,
              defaultVisibleColumns: modalHiddenColumns.length
                ? modalHiddenColumns
                : dDefaultVisibleColumns,
              setModalHiddenColumns,
            }}
          />
        </div>
      </Modal>
    );
};

export default SimpleDisplayTable;
