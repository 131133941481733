export const itemsURI = "v3/items";
export const suppliersURI = `${itemsURI}/suppliers`;
export const categoriesURI = "v3/categories";

export const htmlSpecialCharacters = (string) =>
  string
    .replace("&amp;", "")
    .replace("&quot;", "''")
    .replace("quot;", "''")
    .replace("&quot;", "''")
    .replace("&#039;", "'");

export const COUNT_TYPE_NONE = 0;
export const COUNT_TYPE_ITEM = 1;
export const COUNT_TYPE_WEIGHT = 2;
export const COUNT_TYPE_BOX_TYPE = 3;
export const COUNT_TYPE_BOX_SCALE = 4;
export const COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE = 5;

export const COUNT_TYPES_HEBREW = {
  [COUNT_TYPE_NONE]: "ללא",
  [COUNT_TYPE_ITEM]: "מוצר",
  [COUNT_TYPE_WEIGHT]: "שקיל - יחידת מידה",
  [COUNT_TYPE_BOX_TYPE]: "צורת מארז",
  [COUNT_TYPE_BOX_SCALE]: "יחידת מידה במארז",
  [COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE]: "מארז + יחידת מידה במארז",
};

export const daysOfWeek = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};
