import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";

const PART_EQUIPMENT = 0;

const PARTS = {
  [PART_EQUIPMENT]: {
    label: "מחיר",
    extraData: [
      { name: "label", component: FormTextField, label: "שם פריט" },
      {
        name: "value",
        component: FormTextField,
        label: "מחיר",

        rules: { required: REQUIRED_MESSAGE },
        type: "number",
      },
    ],
  },
};

export { PARTS };
