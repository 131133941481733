import {
  SET_CONFIRM,
  HIDE_CONFIRM,
  SET_CONFIRM_BAR,
  HIDE_CONFIRM_BAR,
  INIT_CONFIRM,
  SET_SNACK_BAR,
  HIDE_SNACK_BAR,
} from "./types";

//set confirm
export const setConfirm =
  (confirm, confirmTitle = "", timeout = 1000) =>
  async (dispatch) => {
    dispatch({ type: SET_CONFIRM, confirm, confirmTitle, timeout });
  };
//hide confirm
export const hideConfirm = () => async (dispatch) => {
  dispatch({ type: HIDE_CONFIRM });
};
//hide confirm
export const initConfirm = () => async (dispatch) => {
  dispatch({ type: INIT_CONFIRM });
};

//set confirmBar
export const setConfirmBar = (confirmMsg) => async (dispatch) => {
  dispatch({ type: SET_CONFIRM_BAR, confirmMsg });
};
//hide confirmBar
export const hideConfirmBar = () => async (dispatch) => {
  dispatch({ type: HIDE_CONFIRM_BAR });
};

//set snakBar
export const setSnackBar =
  (
    confirmMsg = "",
    severity = true,
    direction = "left",
    position = "BOTTOM-CENTER",
    hide = 4000
  ) =>
  async (dispatch) => {
    dispatch({
      type: SET_SNACK_BAR,
      payload: {
        confirmMsg,
        severity,
        direction,
        position,
        hide,
      },
    });
  };

//hide snakBar
export const hideSnackBar = () => async (dispatch) => {
  dispatch({ type: HIDE_SNACK_BAR });
};
