import { default as Grid } from "@mui/material/Unstable_Grid2";
import { Pagination } from "@mui/lab";
import React, { useMemo } from "react";
import { FaRegCheckCircle, FaTrash } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import ReactTable from "../../../../../components/tables/ReactTable";
import { COLOR_ABORT, COLOR_CONFIRM } from "../../../../../const/colors";
import { setDialogModal } from "../../../../../redux/actions/DialogAction";
import { MAKOR_PROVIDER } from "../AttendanceClocks";
import {
  makorColumns,
  makorColumnsMobile,
  restigoAttendanceAppColumns,
  restigoAttendanceAppColumnsMobile,
} from "./columns";
import { useScreenSize } from "../../../../../Hooks";

const Table = ({
  attendanceClocks,
  realDataProvider,
  pagination,
  loading,
  handleChangePage,
  updateDataAfterServerCall,
  setDialogModal,
  updateEnable,
  destroy,
}) => {
  const { isMobile } = useScreenSize()
  const memoColumns = useMemo(
    () =>
      realDataProvider === MAKOR_PROVIDER
        ? isMobile
          ? makorColumnsMobile
          : makorColumns
        : isMobile
        ? restigoAttendanceAppColumnsMobile
        : restigoAttendanceAppColumns,
    [isMobile, realDataProvider]
  );

  const handleStatusClick = (id, provider, enable) => {
    const icon = enable
      ? () => FaRegCheckCircle({ size: "7em", color: COLOR_CONFIRM })
      : () => GiCancel({ size: "7em", color: COLOR_ABORT });
    const action = enable ? "חיבור" : "ניתוק";
    setDialogModal({
      iconJSX: icon,
      title: `${action} שעון נוכחות`,
      text: "האם את/ה בטוח/ה בפעולה זו?",
      onConfirm: () => updateEnable(id, provider, enable),
      btnConfirmClass: enable
        ? "color__confirm--background"
        : "color__danger--background",
    });
  };
  const handleDeleteClick = (id, provider) => {
    setDialogModal({
      iconJSX: () => FaTrash({ size: "7em", color: COLOR_ABORT }),
      title: "מחיקת שעון נוכחות",
      text: "האם את/ה בטוח/ה בפעולה זו?",
      onConfirm: () => destroy(id, provider),
      btnConfirmClass: "color__danger--background",
    });
  };

  return (
    <>
      <ReactTable
        loading={loading}
        styles={{
          wrapper: { maxHeight: "calc(100vh - 10rem)" },
          container: { maxHeight: "calc(100vh - 23.4rem)" },
        }}
        title={I18n.t(`attendanceClocks.title_${realDataProvider}`)}
        data={attendanceClocks}
        columns={memoColumns}
        cellsPropsById={(id) => {
          return {
            updateDataAfterServerCall,
            handleStatusClick,
            handleDeleteClick,
          };
        }}
        // prevent table helper parse numbers
        costumeValuePreparation={{
          latitude: (row)=>row.latitude, 
          longitude: (row)=>row.longitude, 
          
        }}
      />
      <Grid container spacing={isMobile ? 0 : 5}>
        <Grid md={4}>
          {`מציג ${pagination.from} - ${pagination.to} שעוני נוכחות מתוך ${pagination.total}`}
        </Grid>
        <Grid md={8}>
          <Pagination
            style={{ marginTop: "1rem" }}
            color="primary"
            count={pagination.last_page}
            page={pagination.current_page}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default connect(null, { setDialogModal })(Table);
