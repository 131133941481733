import { REQUIRED_MESSAGE } from "../../components/Forms/constants";

// filters
export const FILTERS_STATUS_ENABLE = 1;
export const FILTERS_STATUS_DISABLED = 0;

export const FILTERS_STATUS_OPTIONS = [
  {
    id: FILTERS_STATUS_ENABLE,
    label: "פעילים",
  },
  {
    id: FILTERS_STATUS_DISABLED,
    label: "לא פעילים",
  },
];

export const SHIFT_SETTINGS_FIELDS = [
  {
    id: "info.shift_settings[min_shifts]",
    label: "מינימום בקשות א׳ - ה׳",
    required: REQUIRED_MESSAGE,
  },
  {
    id: "info.shift_settings[min_weekend]",
    label: "מינימום בקשות סוף שבוע",
    required: REQUIRED_MESSAGE,
  },
  {
    id: "min_shift_assign",
    label: "מינימום שיבוצים",
    required: REQUIRED_MESSAGE,
  },
  {
    id: "info.shift_settings[max_shifts]",
    label: "מקסימום שיבוצים",
    required: REQUIRED_MESSAGE,
  },
];
export const FILTER_OTHER_OPTIONS = [
  { name: "עובדים ללא טפסים", id: 1 },
  { name: "ללא מספר במערכת שכר", id: 2 },
];
export const travelOptions = [
  { value: 1, label: "נסיעות יומי" },
  // { value: 2, label: "יומי משתנה" },
  { value: 2, label: "נסיעות יומי קבוע" },
  { value: 3, label: "חודשי קבוע" },
];
export const calcHoursSettings = [
  { id: "regular_hours", label: "100%" },
  { id: "extra_hours", label: "125%/150%" },
  { id: "shabat_hours", label: "שעות שבת" },
  { id: "night_shift", label: "משמרת לילה" },
  // { id: "shabat_extra_hours", label: "175%/200%" },
  // { id: "shabat_hours_break", label: "הפרש שעות שישי - שבת" },
  // { id: "night_shift_bakery", label: "הרחבת משמרת לילה" },
];

export const dayLengthOptions = [
  { value: 1, label: "סניף" },
  { value: 2, label: "עובד" },
];
export const dayLengthTypeOptions = [
  { value: 1, label: "קבוע" },
  { value: 2, label: "לפי יום" },
];
export const employerCostOptions = [
  { value: 0, label: "לא" },
  // { value: 2, label: "יומי משתנה" },
  { value: 1, label: "עלות מעביד" },
  { value: 2, label: "עלות מעביד כולל פנסיה" },
];
export const hourCalcMethod = [
  { value: 0, label: "ברמת סניף" },
  { value: 1, label: "ברמת עובד" },
];
export const employeeBreakOptions = [
  { value: 0, label: "לא" },
  { value: 1, label: "כן" },
];
export const fixedHoursOptions = [
  { value: 0, label: "ללא" },
  { value: 1, label: "שעת התחלה" },
  { value: 2, label: "שעת סיום" },
  { value: 3, label: "שעת התחלה וסיום" },
];

export const minTipsOptions = [
  { value: 1, label: "לפי סניף" },
  // { value: 2, label: "לפי תפקיד" },
  { value: 3, label: "אישי - שעתי" },
  { value: 4, label: "אישי - משמרת" },
  { value: 5, label: "אישי - לפי יום" },
];

export const priviligesOptions = [
  { value: 1, label: "לפי סניף" },
  // { value: 2, label: "לפי תפקיד" },
  { value: 3, label: "אישי - שעתי" },
  { value: 4, label: "אישי - משמרת" },
  { value: 5, label: "אישי - לפי יום" },
];

export const HOURLY_MIN_TIP = 3;
export const SHIFT_MIN_TIP = 4;
export const DAILY_MIN_TIP = 5;

export const tipAllowanceOptions = [
  { value: 1, label: "לפי סניף" },
  { value: 3, label: "אישי" },
];
export const CONST_DAILY_TRAVEL_COST = 2;
export const MONTHLY_TRAVEL_COST = 3;

export const SALARY_PER_HOUR = 0;
export const SALARY_BY_POSITION = 1;
export const SALARY_IS_VARIES = 2;
export const SALARY_BASIC_AND_BONUS = 3;

export const GLOBAL_SALARY = 4;

export const salaryTypeOptions = [
  { value: SALARY_PER_HOUR, label: "שכר שעתי" },
  { value: GLOBAL_SALARY, label: "שכר גלובאלי" },
  { value: SALARY_BY_POSITION, label: "שכר לפי תפקיד" },
  { value: SALARY_IS_VARIES, label: "שכר משתנה" },
  { value: SALARY_BASIC_AND_BONUS, label: "בסיס + תוספת" },
];

export const SALARY_BY_SHIFT = 1;
export const salarySubtypeOptions = [
  { value: SALARY_PER_HOUR, label: "שעתי" },
  { value: SALARY_BY_SHIFT, label: "לפי משמרת" },
  { value: SALARY_IS_VARIES, label: "שעתי משתנה" },
  { value: SALARY_BASIC_AND_BONUS, label: "בסיס+תוספות" },
];

export const MICPAL_NAMES = {
  1: "מיכפל",
  2: "עוקץ",
  3: "שקלולית",
  4: "חילנט",
  5: "הר-גל",
};
// rules for react hook form
export const MAX_TEXT_LENGHT = 25;
export const maxTextError = `השדה מוגבל ל ${MAX_TEXT_LENGHT} תווים`;
export const requiredFieldError = "שדה חובה";
export const mobileNumberIsNotValidError = "טפלון סלולרי לא תקין";
export const emailIsNotValidError = "דואר אלקטרוני לא תקין";
export const idIsNotValidError = "תעודת זהות לא תקינה";
export const ageIsNotValidError =
  "לתשומת ליבך , ניתן להעסיק עובד מגיל 16 שנים ומעלה";

// herarrachies of privileges
export const ADMINISTRATOR = 1;
export const RESTAURANT_MANAGER = 2;
export const SHIFT_MANAGER = 4;
export const KITCHEN_MANAGER = 5;
export const GENERAL_EMPLOYEE = 9;
export const MAKING_ORDERS = 10;
export const PURCHASING_MANAGER = 11;
export const AREA_MANAGER = 12;
export const REGIONAL_PURCHASING_MANAGER = 13;

export const accessLevels = {
  [ADMINISTRATOR]: [
    RESTAURANT_MANAGER,
    SHIFT_MANAGER,
    KITCHEN_MANAGER,
    GENERAL_EMPLOYEE,
    MAKING_ORDERS,
    PURCHASING_MANAGER,
    AREA_MANAGER,
    REGIONAL_PURCHASING_MANAGER,
  ],
  [AREA_MANAGER]: [
    RESTAURANT_MANAGER,
    SHIFT_MANAGER,
    KITCHEN_MANAGER,
    GENERAL_EMPLOYEE,
    MAKING_ORDERS,
    PURCHASING_MANAGER,
    REGIONAL_PURCHASING_MANAGER,
  ],
  [REGIONAL_PURCHASING_MANAGER]: [
    RESTAURANT_MANAGER,
    SHIFT_MANAGER,
    KITCHEN_MANAGER,
    GENERAL_EMPLOYEE,
    MAKING_ORDERS,
    PURCHASING_MANAGER,
  ],
  [RESTAURANT_MANAGER]: [
    SHIFT_MANAGER,
    KITCHEN_MANAGER,
    GENERAL_EMPLOYEE,
    MAKING_ORDERS,
    PURCHASING_MANAGER,
  ],
  [SHIFT_MANAGER]: [
    KITCHEN_MANAGER,
    GENERAL_EMPLOYEE,
    MAKING_ORDERS,
    PURCHASING_MANAGER,
  ],
  [KITCHEN_MANAGER]: [GENERAL_EMPLOYEE, MAKING_ORDERS, PURCHASING_MANAGER],
  [PURCHASING_MANAGER]: [GENERAL_EMPLOYEE, MAKING_ORDERS],
  [MAKING_ORDERS]: [GENERAL_EMPLOYEE],
  [GENERAL_EMPLOYEE]: [],
};

export const EMPLOYER_COST_WITHOUT_PENSION = 1;
export const EMPLOYER_COST_WITH_PENSION = 2;

// ============= MORE INFO SECTION ===============
export const IDENTIFIER = "0";
export const PASSPORT = "1";
export const MIN_WORKING_AGE = 16;
