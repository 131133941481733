// confirmReducer.js
import {
  SET_CONFIRM,
  HIDE_CONFIRM,
  INIT_CONFIRM,
} from "../actions/confirmAction/types";

const INITIAL_STATE = {
  confirmMsg: null,
  confirmTitle: null,
  isOpen: false,
  timeout: 1000,
};

export default (state = INITIAL_STATE, action) => {
  const { confirm, confirmTitle,timeout } = action;
  switch (action.type) {
    case SET_CONFIRM:
      return {
        confirmMsg: confirm,
        confirmTitle,
        isOpen: true,
        timeout,
      };
    case HIDE_CONFIRM:
      return { ...state, isOpen: false };
    case INIT_CONFIRM:
      return INITIAL_STATE;
    default:
      return state;
  }
};
