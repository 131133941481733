import StoreBase from "./StoreBase";
import {
  action,
  computed,
  observable,
  makeObservable,
  runInAction,
} from "mobx";
import * as yup from "yup";
import { isEmpty, omit } from "lodash-es";

const AT_LEAST_TWO_NOTES_MESSAGE = "שדה זה מחוייב בלפחות שני תוים.";
const MUST_BE_EMAIL_FORMAT_MESSAGE = "פורמט כתובת מייל לא תקין.";
const PHONE_NUMBER_LENGTH_MESSAGE = "אורך מספר טלפון מחוייב ב10 תוים.";
const PHONE_NUMBER_MESSAGE = "חייב להכיל רק מספרים.";

const formSchema = yup.object().shape({
  firstName: yup.string().min(2, AT_LEAST_TWO_NOTES_MESSAGE),
  lastName: yup.string().min(2, AT_LEAST_TWO_NOTES_MESSAGE),
  email: yup.string().email(MUST_BE_EMAIL_FORMAT_MESSAGE),
  mobileNumber: yup
    .string()
    .length(10, PHONE_NUMBER_LENGTH_MESSAGE)
    .matches(/^\d+$/, PHONE_NUMBER_MESSAGE),
});

const fieldsMeta = {
  firstName: {
    label: "שם פרטי",
  },
  lastName: {
    label: "שם משפחה",
  },
  privilegeId: {
    label: "סוג הרשאה",
    type: "select",
    // options: [
    //   { value: 1, label: "מנהל רשת" },
    //   { value: 2, label: "מנהל מסעדה" },
    // ],
  },
  // connectedUsers: {
  //   label: "משתמשים מקושרים",
  //   type: "select",
  //   // options: [
  //   //   { value: 1, label: "מנהל רשת" },
  //   //   { value: 2, label: "מנהל מסעדה" },
  //   // ],
  // },
  email: {
    label: "אימייל",
    type: "email",
  },
  mobileNumber: {
    label: "טלפון נייד",
    type: "tel",
  },
};

class DummyFormStore extends StoreBase {
  formFields = {
    firstName: "",
    lastName: "",
    privilegeId: "",
    email: "",
    mobileNumber: "",
  };

  errors = {};

  constructor(defaultFormFields) {
    super();
    makeObservable(this, {
      // Observables
      formFields: observable,
      errors: observable,
      // Computeds
      payload: computed,
      fieldKeys: computed,
      // Actions -
      // validate: action,
      handleChange: action,
      initDefaultValues: action,
      validate: action,
    });
  }

  get formFieldsMeta() {
    return fieldsMeta;
  }

  get fieldKeys() {
    return Object.keys(this.formFields);
  }

  get payload() {
    return this.formFields;
  }

  get isPayloadValid() {
    return isEmpty(this.errors);
  }

  // get dirtyFields() {
  //   return {
  //     name: this.formFields.length !== defaultValueWeGotFromConstructor.name.length
  //   }
  // }

  // 1. errors as observable, and then use a validate action that sets values to errors.
  // 2. errors as computed

  validate = (key) => {
    formSchema
      .validate({ [key]: this.formFields[key] }, { abortEarly: false })
      .then(() => {
        runInAction(() => {
          this.errors = omit(this.errors, key);
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.errors = { ...this.errors, [key]: err.message };
        });
      });
  };

  // get errors() {
  //   // return {};
  //  try{
  //    formSchema.validateSync(this.formFields, {abortEarly: false});
  //    return {}
  //  }   catch (e) {
  //    console.log(e)
  //   return e.errors;
  // }
  // }

  handleChange = (key, value) => {
    this.formFields[key] = value;
  };

  initDefaultValues = (defaultValues) => {
    this.formFields = { ...this.formFields, ...defaultValues };
  };

}

export default DummyFormStore;
