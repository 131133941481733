import Axios from "axios";
import { isEmpty } from "lodash";
import sortByFunc from "lodash/sortBy";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Popover from "react-popover";
import { I18n } from "react-redux-i18n";
import { useReactToPrint } from "react-to-print";
import { dateReqFormat, fixDate, getSafe } from "../../../helpers";
import ReportsTable from "./ReportsTable";
import TableOptionsModal from "./TableOptionsModal";
import { isArray } from "lodash";
import {
  anomalies_columns,
  daily_tips_columns,
  futureAssignsReport_columns,
  futureAssignsReport_hidden,
  summary_tips_columns,
  summary_tips_hidden,
  tips_aggregates,
  tips_columns,
  tips_hidden,
  transfer_aggregates,
  transfer_columns,
  transfer_hidden,
  waiter_aggregates,
  waiter_columns,
  waiter_hidden,
} from "./tableColumns";
import ExportToExcelFiles from "./ExportToExcelFiles";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Badge, Link } from "@mui/material";
import {
  FaBook,
  FaCog,
  FaSortAlphaDown,
  FaSortNumericDown,
  FaArrowDown,
  FaArrowUp,
  FaFileExcel,
  FaUserEdit,
  FaEnvelope,
} from "react-icons/fa";
import NewButton from "../../../components/buttons/NewButton";
import { Printer } from "react-feather";
import Modal from "react-responsive-modal";
import { setDialogModal } from "../../../redux/actions/DialogAction";
import { BASE_URL } from "../../../config/auth";
import fileDownload from "js-file-download";
import MicpalPreviewTable from "./Accountecy/MicpalPreviewTable";
import Button from "../../../components/buttons/Button";
import { connect, useSelector } from "react-redux";
import {
  work_force_columns,
  work_force_hidden,
} from "./columns/workForceColumns";
import {
  event_aggregates,
  event_columns,
  event_columns_mobile,
  event_hidden,
} from "./columns/events";
import {
  salary_aggregates,
  salary_columns,
  salary_department_columns,
  salary_hidden,
  salary_position_columns,
  salary_excel_config,
  salary_by_identifier_columns,
} from "./columns/salary";
import HilanetPreviewTable from "./Accountecy/HilanetPreviewTable";
import { useScreenSize } from "../../../Hooks";
import { executeScroll } from "../../../redux/actions/helpers";
import EditUnvalidShifts from "./components/EditUnvalidShifts";
import ExcelExportFileButtons from "./components/ExcelExportFile";
import ExcelUntitledExport from "./components/ExcelUntitledExport";
import StyledMenu from "./components/StyledMenu";
import PdfSendModal from "./PdfSendModal";
import { ClockLoader } from "react-spinners";
import { BsClockHistory } from "react-icons/bs";
import { COLOR_RESTIGO_PRIMARY } from "../../../const/colors";
import ManualHoursModal from "./ManualHoursModal";
import { HIDE_TABLE_SETTINGS } from "../../../privilegesIndexes";
import { changeFileExtension } from "../../../helpers";
import { attendanceExportTypes, EXPORT_TYPE_HARGAL } from "../constants";
const EXPORT_TYPE_HILANET = 4;
const CHECKED_COLUMNS_FOR_SINGLE_EMPLOYEE = [
  "hours_100",
  "hours_125",
  "hours_150",
  "hours_175",
  "hours_200",
  "hours_in_holiday_hours_150_shabat",
  "vacation",
  "sick",
  "reserve",
];

const TableConstructor = ({
  data,
  type,
  selected_branch,
  date,
  token,
  logout,
  showAllBranches,
  setError,
  setDialogModal,
  checkedBranches,
  isAllowedToEdit,
  ...rest
}) => {
  const { isMobile } = useScreenSize();
  const [pageSize, setPageSize] = useState(500);
  const [openOptions, setOpenOptions] = useState(false);
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [pdfProgress, setPdfProgress] = useState(false);
  const [pdfObj, setPdfObj] = useState({});
  const [pdfDep, setPdfDep] = useState({});
  const [sendPdf, setSendPdf] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [printAll, setPrintAll] = useState(false);
  const [sortByNumber, setSortByNumber] = useState(false);
  const [isHaveAnomaliesInReport, setIsHaveAnomaliesInReport] = useState(false);
  const [manualHoursOpen, setManualHoursOpen] = useState(false);
  // const [sortedData, setSortedData] = useState([]);

  const [openUnvalidShiftReport, setOpenUnvalidShiftReport] = useState(false);
  const [attendanceAnomaliesErrors, setAttendanceAnomaliesErrors] = useState(
    []
  );
  const [showAllDates, setShowAllDates] = useState(false);
  const [isPadDatesOnProcess, setIsPadDatesOnProcess] = useState(false);
  const futureAssignsReport_memo_columns = futureAssignsReport_columns;
  const privileges = useSelector(
    (state) => state.user_privileges.privileges_buttons
  );
  const { special_requests_count } = useSelector((state) => state.submissions);
  const [imporToExcel, setImportToExcel] = useState(false);
  const event_memo_columns = isMobile ? event_columns_mobile : event_columns;

  const transfer_memo_columns = transfer_columns;
  const waiter_memo_columns = waiter_columns;
  const salary_memo_columns = salary_position_columns;
  const work_force_memo_columns = work_force_columns;
  const tips_memo_columns = tips_columns;
  const daily_tips_memo_columns = daily_tips_columns;
  const summary_tips_memo_columns = summary_tips_columns;
  const anomalies_memo_columns = anomalies_columns;
  const salary_position_memo_columns = salary_position_columns;

  const salary_by_identifier_memo_column = salary_by_identifier_columns;

  const salary_department_memo_columns = salary_department_columns.filter(
    (salary) =>
      salary.accessor !== "total_allowance_from_cash" &&
      salary.accessor !== "total_allowance_from_credit"
  );

  const closeExportExcelModal = () => {
    setImportToExcel(false);
  };

  const { isManager } = rest;
  const {
    aggregates,
    hiddenColumns,
    columns,
    columnsToExcel,
    multi,
    showMichpal,
    isSingleEmployee,
    sortBy,
    excelConfig,
    showUnvalidShifts,
  } = useMemo(() => {
    switch (type) {
      case "events":
        return {
          multi: true,
          hiddenColumns: event_hidden,
          aggregates: event_aggregates,
          columns: event_memo_columns,
          columnsToExcel: event_memo_columns[0].columns,
          showMichpal: false,
          isSingleEmployee: true,
          excelConfig: {},
          showUnvalidShifts: true,
        };
      case "events_summary":
        return {
          multi: true,
          hiddenColumns: event_hidden,
          aggregates: event_aggregates,
          columns: event_memo_columns,
          columnsToExcel: event_memo_columns[0].columns,
          showMichpal: false,
          isSingleEmployee: true,
          excelConfig: {},
          showUnvalidShifts: true,
        };
      case "futureAssignsReport":
        return {
          multi: false,
          hiddenColumns: futureAssignsReport_hidden,
          aggregates: [],
          columns: futureAssignsReport_memo_columns,
          columnsToExcel: futureAssignsReport_memo_columns,
          showMichpal: false,
          isSingleEmployee: false,
          sortBy: [
            { id: "working_date", desc: false },
            { id: "employee_name", desc: false },
          ],
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "waiter":
        return {
          multi: false,
          hiddenColumns: waiter_hidden,
          aggregates: waiter_aggregates,
          columns: waiter_memo_columns,
          showMichpal: false,
          isSingleEmployee: false,
          sortBy: [{ id: "employee_first_name", desc: false }],
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "salary":
        return {
          multi: false,
          hiddenColumns: salary_hidden,
          aggregates: salary_aggregates,
          columns: salary_memo_columns,
          columnsToExcel: salary_memo_columns,
          showMichpal: true,
          isSingleEmployee: false,
          excelConfig: salary_excel_config,
          showUnvalidShifts: true,
        };
      case "salary_position":
        return {
          multi: false,
          hiddenColumns: salary_hidden,
          aggregates: salary_aggregates,
          columns: salary_position_memo_columns,
          columnsToExcel: salary_position_memo_columns,
          showMichpal: true,
          isSingleEmployee: false,
          excelConfig: salary_excel_config,
          showUnvalidShifts: true,
        };
      case "salary_department":
        return {
          multi: false,
          hiddenColumns: salary_hidden,
          aggregates: salary_aggregates,
          columns: salary_department_memo_columns,
          columnsToExcel: salary_department_memo_columns,
          showMichpal: false,
          isSingleEmployee: false,
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "tips":
        return {
          multi: true,
          hiddenColumns: tips_hidden,
          aggregates: tips_aggregates,
          columns: tips_memo_columns,
          columnsToExcel: tips_memo_columns[0].columns,
          showMichpal: false,
          isSingleEmployee: true,
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "daily_tips":
        return {
          multi: false,
          hiddenColumns: tips_hidden,
          aggregates: tips_aggregates,
          columns: daily_tips_memo_columns,
          columnsToExcel: daily_tips_memo_columns,
          showMichpal: false,
          isSingleEmployee: false,
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "summary_tips":
        return {
          multi: false,
          hiddenColumns: summary_tips_hidden,
          aggregates: tips_aggregates,
          columns: summary_tips_memo_columns,
          showMichpal: false,
          isSingleEmployee: false,
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "anomalies":
        return {
          multi: false,
          hiddenColumns: [],
          aggregates: [],
          columns: anomalies_memo_columns,
          showMichpal: false,
          isSingleEmployee: false,
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "transfers":
        return {
          multi: true,
          hiddenColumns: transfer_hidden,
          aggregates: transfer_aggregates,
          columns: transfer_memo_columns,
          columnsToExcel: transfer_memo_columns[0].columns,
          showMichpal: false,
          isSingleEmployee: true,
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "work_force":
        return {
          multi: false,
          hiddenColumns: work_force_hidden,
          aggregates: [],
          columns: work_force_memo_columns,
          showMichpal: false,
          isSingleEmployee: false,
          excelConfig: {},
          showUnvalidShifts: false,
        };
      case "salary_by_identifier":
        return {
          multi: false,
          hiddenColumns: salary_hidden,
          aggregates: salary_aggregates,
          columns: salary_by_identifier_memo_column,
          columnsToExcel: salary_by_identifier_memo_column,
          showMichpal: true,
          isSingleEmployee: false,
          excelConfig: salary_excel_config,
          showUnvalidShifts: true,
        };
      default:
        return {
          multi: false,
          hiddenColumns: [],
          aggregates: [],
          columns: [],
          columnsToExcel: [],
          showMichpal: false,
          isSingleEmployee: false,
          excelConfig: {},
          showUnvalidShifts: false,
        };
    }
  }, [type, data]);

  const fetchBranchViewOptions = useMemo(
    () => async (branch_id, report_title) => {
      try {
        const res = await Axios.get(`branch/${branch_id}/reportsOptions`, {
          params: {
            report_title,
          },
          headers: { Authorization: `Bearer ${token}` },
        });

        const defaultVisible = {
          meal_cost: "meal_cost",
          travel_cost: "travel_cost",
          hours_150: "hours_150",
          hours_100: "hours_100",
          hours_150_shabat: "hours_150_shabat",
          hours_125: "hours_125",
          hours_in_holiday: "hours_in_holiday",
          role_name: "role_name",
          employee_last_name: "employee_last_name",
          employee_first_name: "employee_first_name",
          actual_working_days: "actual_working_days",
          origin_department_name: "origin_department_name",
        };

        const tipColumnsForSingleEmployee = {
          tip_per_hour_bruto: "tip_per_hour_bruto",
          tip_per_hour_neto: "tip_per_hour_neto",
          total_tip: "total_tip",
          total_tip_before_allowance: "total_tip_before_allowance",
          total_allowance_from_credit: "total_allowance_from_credit",
          total_allowance_from_cash: "total_allowance_from_cash",
          allowance: "allowance",
          complete: "complete",
        };
        if (!isManager) {
          return setCheckedColumns([
            ...CHECKED_COLUMNS_FOR_SINGLE_EMPLOYEE,
            ...res.data
              .filter(
                (option) =>
                  option.active &&
                  tipColumnsForSingleEmployee[option.column_title]
              )
              .map((x) => x.column_title),
          ]);
        }
        setCheckedColumns([
          ...res.data
            .filter((x) => {
              if (defaultVisible[x.column_title]) {
                delete defaultVisible[x.column_title];
              }
              return x.active;
            })
            .map((x) => x.column_title),
          ...Object.values(defaultVisible),
        ]);
      } catch (e) {
        console.error(e);

        if (e.response.status === 401) {
          logout();
        } else if (e.response.status === 500) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת");
        }
      }
    },
    [isManager]
  );

  const exportMicpal = useMemo(
    () =>
      async (fileExtension = null) => {
        let checkedEmployeesToProps = rest.checkedEmployees;
        if (
          type === "salary_by_identifier" &&
          checkedEmployeesToProps?.length
        ) {
          checkedEmployeesToProps = rest.options?.employees
            ?.filter((employee) =>
              rest.checkedEmployees.includes(employee.value)
            )
            .map((employee) => employee.additionalEmployeeId);
        }
        const formData = new FormData();
        formData.append("expenses_start_submit", dateReqFormat(date.start));
        formData.append("expenses_end_submit", dateReqFormat(date.end));
        formData.append(
          "employee_ids",
          JSON.stringify(checkedEmployeesToProps)
        );
        formData.append(
          "department_ids",
          JSON.stringify(rest.checkedDepartments)
        );

        formData.append("react", 1);
        let endpoint = "exportToAttendanceMicpal";
        if (selected_branch.micpal_working === EXPORT_TYPE_HILANET) {
          endpoint = "exportToAttendanceHilanet";
        }
        if (selected_branch.micpal_working === EXPORT_TYPE_HARGAL) {
          endpoint = "exportToHargal";
        }
        await Axios.post(
          `${BASE_URL}/api/branchs/${selected_branch.id}/${endpoint}`,
          formData,
          { responseType: "stream" }
        )
          .then((res) => {
            const exportType = selected_branch.micpal_working;
            let resDataName = res.data.name;
            if (fileExtension) {
              resDataName = changeFileExtension(res.data.name, fileExtension);
            }
            if (exportType === 1) {
              setDialogModal({
                title:
                  "ייצוא למיכפל - תצוגה מקדימה לחברה " +
                  res.data.company_number +
                  " לחודש " +
                  res.data.month_year,
                btnConfirmText: "ייצא קובץ",
                onConfirm: () => {
                  const file_name = resDataName;
                  const file = res.data.string;
                  fileDownload(file, file_name);
                },
                text: (
                  <MicpalPreviewTable
                    attendance={true}
                    tableItems={res.data.array}
                  />
                ),
                maxWidthStyle: "44%",
              });
            } else if (exportType === EXPORT_TYPE_HILANET) {
              setDialogModal({
                title: "ייצוא לחילנט - תצוגה מקדימה",
                btnConfirmText: "ייצא קובץ",
                onConfirm: () => {
                  const file_name = resDataName;
                  const file = res.data.string;
                  fileDownload(file, file_name);
                },
                text: <HilanetPreviewTable data={res.data.array} />,
                maxWidthStyle: "44%",
              });
            }
          })
          .catch((err) => {
            setError();
          });
      },
    [selected_branch, date, rest, setDialogModal, checkedBranches]
  );

  const openMicpalAnomaliesNotification = () => {
    setDialogModal({
      title: "לתשומת ליבך קיימות משמרות פתוחות בטווח התאריכים הנבחר",
      onAbort: () => exportMicpal(type === "events" ? "dat" : null),
      btnAbortText: attendanceExportTypes[selected_branch.micpal_working],
      btnConfirmText: "הצג משמרות פתוחות",
      btnAbortClass: "btn--confirm",
      onConfirm: () => setOpenUnvalidShiftReport(true),
    });
  };

  const openPdfNotification = async () => {
    //const employees = {};
    const departments = {};
    for (const s in sortedData) {
      for (const e in sortedData[s].body) {
        if (departments[sortedData[s].body[e].department_id] === null) continue;
        departments[sortedData[s].body[e].department_id] = {
          name: sortedData[s].body[e].department_name,
          id: sortedData[s].body[e].department_id,
        };
      }
    }
    setPdfModalOpen(true);
    setPdfDep({
      ...departments,
    });
  };
  const openUntitleExcelModalAnomalies = () => {
    setDialogModal({
      title: "לתשומת ליבך קיימות משמרות פתוחות בטווח התאריכים הנבחר",
      customButton: (
        <ExcelUntitledExport
          type={type}
          sortedData={sortedData}
          showAllBranches={showAllBranches}
          selected_branch={selected_branch}
          date={date}
          isMobile={isMobile}
          columnsToExcel={columnsToExcel}
          checkedColumns={checkedColumns}
          hiddenColumns={hiddenColumns}
          aggregates={aggregates}
        />
      ),
      showAbortButton: false,
      btnConfirmText: "הצג משמרות פתוחות",
      onConfirm: () => setOpenUnvalidShiftReport(true),
    });
  };

  const openAllExcelModalAnomalies = () => {
    setDialogModal({
      title: "לתשומת ליבך קיימות משמרות פתוחות בטווח התאריכים הנבחר",
      customButton: (
        <ExcelExportFileButtons
          type={type}
          sortedData={sortedData}
          showAllBranches={showAllBranches}
          selected_branch={selected_branch}
          date={date}
          isMobile={isMobile}
          columnsToExcel={columnsToExcel}
          checkedColumns={checkedColumns}
          hiddenColumns={hiddenColumns}
          aggregates={aggregates}
        />
      ),
      showAbortButton: false,
      btnConfirmText: "הצג משמרות פתוחות",
      onConfirm: () => setOpenUnvalidShiftReport(true),
    });
  };

  useEffect(() => {
    if (!isEmpty(selected_branch) && type && data.length) {
      fetchBranchViewOptions(selected_branch.id, type);
    }
  }, [selected_branch, type, data]);

  const saveViewOptions = useMemo(
    () => async (columns) => {
      try {
        if (isEmpty(selected_branch)) return;
        const res = await Axios.post(
          `branch/${selected_branch.id}/reportsOptions`,
          {
            report_title: type,
            columns,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } catch (e) {
        console.error(e);
        if (e.response.status === 401) {
          logout();
        } else if (e.response.status === 500) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת");
        }
      }
    },
    [selected_branch, type]
  );

  const contentRef = useRef();
  const handlePrintTable = useReactToPrint({
    content: () => contentRef.current,
    onAfterPrint: () => setPrintAll(false),
  });

  useEffect(() => {
    return (() => {
      if (printAll) {
        handlePrintTable();
      }
    })();
  });
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const downloadZipFile = async () => {
    console.log("test");
    const res = await Axios.get(`${DOWNLOAD_ZIP_FILE}`, {
      params: {
        selected_branch: selected_branch.id,
        start_date: formatDate(date.start),
        end_date: formatDate(date.end),
      },

      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    const link = document.createElement("a");
    link.href = data;
    link.click();
    return data;
  };

  // ======== CONSTANTS ========
  const DOWNLOAD_ZIP_FILE = "newSubmission/downloadZipFile";

  const sortedData = useMemo(() => {
    let temp = [...data];
    temp = temp.map((shift) => {
      if (
        shift?.planned_time_difference &&
        shift?.planned_time_difference?.charAt(0) === "-"
      ) {
        const [hoursPart, minutesPart] =
          shift.planned_time_difference.split(":");
        let newHoursPart = Number(hoursPart) + 24;
        let newMinutesPart = Number(minutesPart);
        if (newMinutesPart > 0) {
          newHoursPart -= 1;
          newMinutesPart = 60 - newMinutesPart;
        }
        if (newMinutesPart < 10 && newMinutesPart > 0) {
          newMinutesPart = "0" + newMinutesPart;
        }
        if (newHoursPart < 10) {
          newHoursPart = "0" + newHoursPart;
        }
        return {
          ...shift,
          planned_time_difference: [newHoursPart, newMinutesPart, "00"].join(
            ":"
          ),
        };
      } else {
        return shift;
      }
    });

    if (multi) {
      if (sortByNumber) {
        temp = sortByFunc(temp, (o) => Number(o.body[0].employee_number));
      } else {
        temp = sortByFunc(temp, (o) => o.body[0]?.employee_name);
      }
    }

    return temp;
  }, [data, sortByNumber, multi, sortByFunc]);

  const reportEndRef = useRef(null);
  const reportStartRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpenMobileSettings = Boolean(anchorEl);
  const openMobileSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMobileSettings = () => {
    setAnchorEl(null);
  };

  const handleShowUnvalidShifts = () => {
    if (attendanceAnomaliesErrors.length > 0) {
      setOpenUnvalidShiftReport(true);
    }
  };

  return sortedData.length ? (
    <>
      <EditUnvalidShifts
        date={date}
        type={type}
        checkedBranches={checkedBranches}
        handleSubmit={rest.handleSubmit}
        checkedEmployees={rest.checkedEmployees}
        checkedDepartments={rest.checkedDepartments}
        trigerAnomaliesFetchData={rest.trigerAnomaliesFetchData}
        openUnvalidShiftReport={openUnvalidShiftReport}
        setOpenUnvalidShiftReport={setOpenUnvalidShiftReport}
        setIsHaveAnomaliesInReport={setIsHaveAnomaliesInReport}
        setAttendanceAnomaliesErrors={setAttendanceAnomaliesErrors}
        attendanceAnomaliesErrors={attendanceAnomaliesErrors}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "40px",
        }}
      >
        <div style={{ display: "flex" }}>
          {isManager && !isMobile && type === "events" && (
            <div
              style={
                isMobile ? {} : { marginLeft: "2rem", marginTop: "0.6rem" }
              }
            >
              <label className="checkbox--container">
                הצג כלל תאריכים בחודש
                <input
                  type="checkbox"
                  checked={showAllDates}
                  onChange={(e) => {
                    setShowAllDates(e.target.checked);
                    setIsPadDatesOnProcess((prevState) => !prevState);
                  }}
                />
                <span className="checkbox--checkmark"></span>
              </label>
            </div>
          )}
        </div>
        <div
          ref={reportStartRef}
          className={isMobile ? "flex-end" : "flex-end"}
          style={isMobile ? { margin: "1rem 0", width: "100%" } : {}}
        >
          {isManager && multi && sortedData.length > 0 ? (
            <>
              <ButtonIcon
                alt="שליחה לפי מחלקה"
                iconSize="1.8em"
                icon={FaEnvelope}
                onClick={() => openPdfNotification()}
              />
              {!isMobile &&
                (!sortByNumber ? (
                  <ButtonIcon
                    alt="מיין לפי מספר עובד"
                    iconSize="1.8em"
                    icon={FaSortNumericDown}
                    onClick={() => setSortByNumber(true)}
                  />
                ) : (
                  <ButtonIcon
                    alt="מיין לפי שם"
                    iconSize="1.8em"
                    icon={FaSortAlphaDown}
                    onClick={() => setSortByNumber(false)}
                  />
                ))}
              {isAllowedToEdit && (
                <div
                  style={{
                    display: "inline-block",
                    width: "fit-content",
                    position: "relative",
                  }}
                >
                  {special_requests_count > 0 && (
                    <div className="red-circle">{special_requests_count}</div>
                  )}
                  <Link to="/users/employees/schedule/submissions/specialRequest">
                    <ButtonIcon
                      alt="בקשות מיוחדות"
                      onClick={() => {}}
                      iconSize="1.4em"
                      icon={({ size }) => (
                        <i
                          style={{ fontSize: size }}
                          className="fas fa-envelope-open-text"
                        ></i>
                      )}
                    />
                  </Link>
                </div>
              )}
            </>
          ) : (
            ""
          )}
          {isManager && !isMobile && type === "events" && (
            <>
              {getSafe(
                () => selected_branch.micpal_working === EXPORT_TYPE_HILANET
              ) && (
                <Button
                  onClick={
                    isHaveAnomaliesInReport
                      ? openMicpalAnomaliesNotification
                      : exportMicpal
                  }
                  className="btn--normal"
                  icon={FaBook}
                  textValue="ייצא לחילנט נוכחות"
                />
              )}
            </>
          )}
          {isManager ? (
            isMobile ? (
              <>
                <ButtonIcon
                  alt="יצוא נתונים"
                  onClick={() => {
                    setImportToExcel(true);
                  }}
                  icon={FaFileExcel}
                ></ButtonIcon>
                {imporToExcel && (
                  <ExportToExcelFiles
                    imporToExcel={imporToExcel}
                    closeExcelModal={closeExportExcelModal}
                    openUntitleExcelModalAnomalies={
                      openUntitleExcelModalAnomalies
                    }
                    openAllExcelModalAnomalies={openAllExcelModalAnomalies}
                    isHaveAnomaliesInReport={isHaveAnomaliesInReport}
                    exportMicpal={exportMicpal}
                    openMicpalAnomaliesNotification={
                      openMicpalAnomaliesNotification
                    }
                    setPrintAll={setPrintAll}
                    micpalWorking={selected_branch.micpal_working}
                    downloadZipFile={downloadZipFile}
                  />
                )}
                <Modal
                  animationDuration={0}
                  styles={{ modal: { padding: "2rem", width: "fit-content" } }}
                  open={openOptions}
                  center
                  onClose={() => {
                    saveViewOptions(
                      hiddenColumns.map((x) => ({
                        title: x,
                        active: checkedColumns.includes(x) ? 1 : 0,
                      }))
                    );

                    setOpenOptions(false);
                  }}
                >
                  <TableOptionsModal
                    allColumns={columnsToExcel ? columnsToExcel : columns}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    handleOpen={setOpenOptions}
                    hiddenColumns={hiddenColumns}
                    setCheckedColumns={setCheckedColumns}
                    checkedColumns={checkedColumns}
                    isMobile={isMobile}
                  />
                </Modal>

                {showUnvalidShifts ? (
                  <IconButton
                    aria-label="more"
                    id="longMobileButton"
                    aria-controls={
                      isOpenMobileSettings ? "long-menu" : undefined
                    }
                    aria-expanded={isOpenMobileSettings ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={openMobileSettings}
                    style={{
                      backgroundColor: "#f7f7f7",
                      borderRadius: 0,
                      width: "40px",
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                ) : // <ButtonIcon
                //   alt="הגדרות טבלה"
                //   onClick={() => {
                //     if (openOptions) {
                //       saveViewOptions(
                //         hiddenColumns.map((x) => ({
                //           title: x,
                //           active: checkedColumns.includes(x) ? 1 : 0,
                //         }))
                //       );
                //     }
                //     setOpenOptions(!openOptions);
                //   }}
                //   icon={FaCog}
                // />
                null}
                <StyledMenu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={isOpenMobileSettings}
                  onClose={handleCloseMobileSettings}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseMobileSettings();
                      setOpenOptions(!openOptions);
                    }}
                    disableRipple
                    style={{
                      backgroundColor: isHaveAnomaliesInReport
                        ? "white"
                        : "#f7f7f7",
                    }}
                  >
                    <ButtonIcon alt="הגדרות טבלה" icon={FaCog} />
                    <p style={{ fontSize: "1.4rem", fontWeight: "500" }}>
                      הגדרות טבלה
                    </p>
                  </MenuItem>
                  {!isHaveAnomaliesInReport && multi && (
                    <Divider sx={{ my: 0.5 }} />
                  )}
                  {!isHaveAnomaliesInReport && multi && (
                    <MenuItem
                      onClick={handleCloseMobileSettings}
                      disableRipple
                      style={{ backgroundColor: "#f7f7f7" }}
                    >
                      <ExcelExportFileButtons
                        type={type}
                        sortedData={sortedData}
                        showAllBranches={showAllBranches}
                        selected_branch={selected_branch}
                        date={date}
                        isMobile={isMobile}
                        columnsToExcel={columnsToExcel}
                        checkedColumns={checkedColumns}
                        hiddenColumns={hiddenColumns}
                        aggregates={aggregates}
                      />
                    </MenuItem>
                  )}
                  <MenuItem
                    disableRipple
                    style={{
                      display: isHaveAnomaliesInReport ? "flex" : "none",
                    }}
                  >
                    {showUnvalidShifts &&
                    attendanceAnomaliesErrors.length > 0 ? (
                      <>
                        <Badge
                          badgeContent={
                            attendanceAnomaliesErrors &&
                            attendanceAnomaliesErrors.length
                          }
                          color="error"
                          anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        >
                          <ButtonIcon
                            alt="משמרות פתוחות"
                            iconSize="1.2em"
                            icon={FaUserEdit}
                            onClick={handleShowUnvalidShifts}
                          />
                        </Badge>
                        <p style={{ fontSize: "1.4rem", fontWeight: "500" }}>
                          שעות פתוחות
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </MenuItem>
                </StyledMenu>
              </>
            ) : (
              <>
                <Popover
                  preferPlace="below"
                  onOuterAction={() => {
                    if (openOptions) {
                      saveViewOptions(
                        hiddenColumns.map((x) => ({
                          title: x,
                          active: checkedColumns.includes(x) ? 1 : 0,
                        }))
                      );
                    }
                    setOpenOptions(false);
                  }}
                  tipSize={11}
                  enterExitTransitionDurationMs={300}
                  isOpen={openOptions}
                  body={
                    <TableOptionsModal
                      allColumns={columnsToExcel ? columnsToExcel : columns}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      handleOpen={setOpenOptions}
                      hiddenColumns={hiddenColumns}
                      setCheckedColumns={setCheckedColumns}
                      checkedColumns={checkedColumns}
                    />
                  }
                >
                  <div style={{ display: "flex" }}>
                    {(type == "events" ||
                      type == "tips" ||
                      type == "transfers") && (
                      <ButtonIcon
                        alt="יצוא נתונים"
                        onClick={() => {
                          setImportToExcel(true);
                        }}
                        icon={FaFileExcel}
                      ></ButtonIcon>
                    )}
                    {imporToExcel && (
                      <ExportToExcelFiles
                        imporToExcel={imporToExcel}
                        closeExcelModal={closeExportExcelModal}
                        openUntitleExcelModalAnomalies={
                          openUntitleExcelModalAnomalies
                        }
                        openAllExcelModalAnomalies={openAllExcelModalAnomalies}
                        isHaveAnomaliesInReport={isHaveAnomaliesInReport}
                        exportMicpal={() =>
                          exportMicpal(type === "events" ? "dat" : null)
                        }
                        openMicpalAnomaliesNotification={
                          openMicpalAnomaliesNotification
                        }
                        setPrintAll={setPrintAll}
                        micpalWorking={selected_branch.micpal_working}
                        downloadZipFile={downloadZipFile}
                      />
                    )}

                    {!privileges.includes(HIDE_TABLE_SETTINGS) ? (
                      <ButtonIcon
                        alt="הגדרות טבלה"
                        onClick={() => {
                          if (openOptions) {
                            saveViewOptions(
                              hiddenColumns.map((x) => ({
                                title: x,
                                active: checkedColumns.includes(x) ? 1 : 0,
                              }))
                            );
                          }
                          setOpenOptions(!openOptions);
                        }}
                        icon={FaCog}
                      />
                    ) : null}
                    {showUnvalidShifts &&
                    attendanceAnomaliesErrors.length > 0 ? (
                      <Badge
                        badgeContent={
                          attendanceAnomaliesErrors &&
                          attendanceAnomaliesErrors.length
                        }
                        color="error"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      >
                        <ButtonIcon
                          alt="משמרות פתוחות"
                          iconSize="1.2em"
                          icon={FaUserEdit}
                          onClick={handleShowUnvalidShifts}
                        />
                      </Badge>
                    ) : (
                      <></>
                    )}
                    {type === "events" && (
                      <ButtonIcon
                        alt="הסטוריית תיקונים ידניים"
                        style={{
                          color: COLOR_RESTIGO_PRIMARY,
                        }}
                        iconSize="1.2em"
                        icon={BsClockHistory}
                        onClick={() => setManualHoursOpen(true)}
                      />
                    )}
                  </div>
                </Popover>
              </>
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <div ref={contentRef}>
        {!multi ? (
          <ReportsTable
            {...{ sortBy }}
            showMichpal={showMichpal}
            date={date}
            multiPageSize={pageSize}
            selected_branch={selected_branch}
            checkedBranches={checkedBranches}
            columns={columns}
            type={type}
            data={sortedData}
            manualHoursData={sortedData}
            aggregates={aggregates}
            hiddenColumns={hiddenColumns.filter(function (el) {
              return !checkedColumns.includes(el);
            })}
            manualHoursOpen={manualHoursOpen}
            setManualHoursOpen={setManualHoursOpen}
            header={sortedData}
            printTitle={`${I18n.t(`pageNav.${type}`)} ${
              sortedData.length > 1 && isManager
                ? `${selected_branch.name.replaceAll(".", "")}`
                : ""
            }`}
            printDate={`${fixDate(date.start)} - ${fixDate(date.end)}`}
            isSingleEmployee={isSingleEmployee}
            printMeta={[]}
            excelConfig={excelConfig}
            isMulti={multi}
            isHaveAnomaliesInReport={isHaveAnomaliesInReport}
            setOpenUnvalidShiftReport={setOpenUnvalidShiftReport}
            showUnvalidShifts={showUnvalidShifts}
            isAllowedToEdit={isAllowedToEdit}
            {...rest}
          />
        ) : (
          <>
            {sortedData.map((table, index) => (
              <ReportsTable
                showMichpal={showMichpal}
                date={date}
                multiPageSize={pageSize}
                selected_branch={selected_branch}
                key={table.header.employee_id}
                checkedBranches={checkedBranches}
                columns={columns}
                data={table.body}
                manualHoursData={sortedData}
                aggregates={aggregates}
                hiddenColumns={hiddenColumns.filter(function (el) {
                  return !checkedColumns.includes(el);
                })}
                manualHoursOpen={manualHoursOpen}
                setManualHoursOpen={setManualHoursOpen}
                header={table.header}
                week_summary={table.weekly_summary}
                showAllDates={showAllDates}
                setIsPadDatesOnProcess={setIsPadDatesOnProcess}
                printTitle={`${I18n.t(`pageNav.${type}`)} `}
                printDate={`${fixDate(date.start)} - ${fixDate(date.end)}`}
                isSingleEmployee={isSingleEmployee}
                printMeta={[
                  `שם עובד: ${table.header.employee_name}`,
                  `מספר עובד: ${
                    table.body[0]?.employee_number ?? table.header?.employee_id
                  }`,
                  `ת.ז / דרכון: ${table.body[0]?.identifier ?? ""}`,
                  `שם סניף: ${table.header.branch_name}`,
                ]}
                sendPdf={sendPdf}
                pdfProgress={pdfProgress}
                setPdfProgress={setPdfProgress}
                pdfObj={pdfObj}
                setPdfObj={setPdfObj}
                printAll={printAll}
                isHaveAnomaliesInReport={isHaveAnomaliesInReport}
                isMulti={multi}
                isAllowedToEdit={isAllowedToEdit}
                {...rest}
              />
            ))}

            <div ref={reportEndRef}></div>
          </>
        )}
      </div>
      {isManager && multi && sortedData.length > 1 && (
        <div className="flex-center">
          <ButtonIcon
            alt="מעבר לעובד ראשון"
            iconSize="1.8em"
            icon={FaArrowUp}
            onClick={() => executeScroll(reportStartRef)}
          />
        </div>
      )}
      {isPadDatesOnProcess && (
        <Dialog
          open={isPadDatesOnProcess}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => setIsHaveAnomaliesInReport(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {`הוספת כלל הימים לדוח`}
          </DialogTitle>
          <DialogContent>
            <div className="flex-column-center">
              <ClockLoader
                css={`
                  display: block;
                `}
                size={100}
                color={"#00b1ff"}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
      {pdfModalOpen && (
        <PdfSendModal
          departments={pdfDep}
          data={pdfObj}
          token={token}
          printDate={`${fixDate(date.start)} - ${fixDate(date.end)}`}
          approve={setSendPdf}
          onClose={() => setPdfModalOpen(false)}
          hiddenColumns={hiddenColumns.filter(function (el) {
            return !checkedColumns.includes(el);
          })}
        />
      )}
      {manualHoursOpen && type === "events" && (
        <ManualHoursModal
          data={sortedData}
          setOpen={setManualHoursOpen}
          date={date}
          type={type}
          checkedBranches={checkedBranches}
          handleSubmit={rest.handleSubmit}
          checkedEmployees={rest.checkedEmployees}
          checkedDepartments={rest.checkedDepartments}
          trigerAnomaliesFetchData={rest.trigerAnomaliesFetchData}
          openUnvalidShiftReport={openUnvalidShiftReport}
          setOpenUnvalidShiftReport={setOpenUnvalidShiftReport}
          setIsHaveAnomaliesInReport={setIsHaveAnomaliesInReport}
          setAttendanceAnomaliesErrors={setAttendanceAnomaliesErrors}
          attendanceAnomaliesErrors={attendanceAnomaliesErrors}
        />
      )}
    </>
  ) : (
    ""
  );
};

export default connect(null, { setDialogModal })(TableConstructor);
