import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
import {
  LOGOUT,
  FETCH_USER_PRIVILEGES,
  UPDATE_FORM_NOT_FILLED,
} from "../actions/loginAction/types";

// UserPrivilegesReducer.js
const INITIAL_STATE = {
  privilege_id: null,
  privileges_pages: [],
  privileges_tabs: [],
  privileges_buttons: [],
  privileges_access: [],
  employees_transfer: null,
  forms_not_filled: [],
  shift_watch_privileges: [],
  connected_users: [],
};

const UserPrivilegesReducer = (state = INITIAL_STATE, action) => {
  const {
    type,
    privileges_pages,
    privileges_tabs,
    privileges_buttons,
    privileges_access,
    employees_transfer,
    privilege_id,
    forms_not_filled,
    connected_users,
    shift_watch_privileges,
  } = action;
  switch (type) {
    case FETCH_USER_PRIVILEGES:
      return {
        ...state,
        privilege_id,
        privileges_pages,
        privileges_tabs,
        privileges_buttons,
        privileges_access,
        employees_transfer,
        forms_not_filled,
        shift_watch_privileges,
        connected_users,
      };

    case UPDATE_FORM_NOT_FILLED:
      return {
        ...state,
        forms_not_filled,
      };
    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default UserPrivilegesReducer;
