import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import Button from "../../../../../components/buttons/Button";
import ButtonIcon from "../../../../../components/buttons/ButtonIcon";
import { setDialogModal } from "../../../../../redux/actions/DialogAction";

import { MdSave } from "react-icons/md";
import { HiX } from "react-icons/hi";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";
import {
  addBonusToEmployee,
  editBonusToEmployee,
  deleteBonusToEmployee,
} from "../../../../../redux/actions/scheduleAction";

// ======== HOOKS ========
import { useMediaQuery, useTheme } from "@mui/material";

// ======== ICONS ========
import { FaGift, FaTrash } from "react-icons/fa";

// ======== CONSTANTS ========
import { isUndefined } from "lodash";

import { getSafe } from "../../../../../helpers";

// ======== COMPONENTS ========

export const ReportBonusModal = ({ employee, onAbort }) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========

  const [bonusAmount, setBonusAmount] = useState(
    getSafe(() => employee?.event.amount) ? employee.event.amount : undefined
  );
  const bonusTypes = [
    { id: 0, label: "בונוס" },
    { id: 1, label: "מפרעה" },
  ];
  const [bonusType, setBonusType] = useState(
    getSafe(() => employee?.event.bonus_type) ? employee.event.bonus_type : 0
  );
  const [note, setNote] = useState(
    getSafe(() => employee?.event.note) ? employee.event.note : undefined
  );
  const [error, setError] = useState(null);

  // ======== HANDLERS ========
  const renderError = useMemo(
    () => () => {
      if (error) {
        return (
          <p style={{ width: "100%", textAlign: "center" }}>
            <span className="error-msg">{error}</span>{" "}
          </p>
        );
      }
    },
    [error]
  );
  const handleConfirm = (employee) => {
    if (isUndefined(bonusAmount)) return setError("הוסף סכום");
    onAbort();
    dispatch(
      setDialogModal({
        title: bonusType == 1 ? "הוספת מפרעה" : "הוספת בונוס",
        text: `האם להוסיף ${bonusAmount} ש"ח ${
          bonusType == 1 ? "מפרעה" : "בונוס"
        } ל ${employee.employee_name} ?`,
        onConfirm: () => {
          if (employee?.event) {
            dispatch(
              editBonusToEmployee({
                bonus_id: employee.event.id,
                amount: bonusAmount,
                note,
                bonusType,
              })
            );
          } else {
            dispatch(
              addBonusToEmployee({
                event_id: employee.id,
                employee_id: employee.employee.id,
                amount: bonusAmount,
                note,
                bonusType,
              })
            );
          }
        },
        iconJSX: () => FaGift({ size: "7em" }),
      })
    );
  };

  const handleDelete = (bonus_id) => {
    onAbort();
    dispatch(
      setDialogModal({
        title: bonusType === 1 ? "הסרת מפרעה" : "הסרת בונוס",
        text: `האם לבטל את ${bonusType === 1 ? "מפרעה" : "בונוס"} של ${
          employee.employee_name
        } לתא זה?`,
        onConfirm: () => dispatch(deleteBonusToEmployee(bonus_id)),
        iconJSX: () => FaGift({ size: "7em" }),
        btnConfirmClass: "color__danger--background",
      })
    );
  };

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
        xs, extra-small: 0px
        sm, small: 500px
        md, medium: 960px
        lg, large: 1280px
        xl, extra-large: 1536px
      */

  return (
    <Grid
      container
      sx={{
        height: "100%",
        display: "flex",
      }}
    >
      <Grid item xs={10}>
        <h2>{bonusType == 1 ? "הוספת מפרעה" : "הוספת בונוס"}</h2>
      </Grid>
      <Grid item xs={10}>
        <FormControl>
          <RadioGroup
            row
            value={bonusType}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {
              setBonusType(e.target.value);
            }}
          >
            {bonusTypes.map((type) => {
              return (
                <FormControlLabel
                  key={type.id}
                  value={type.id}
                  control={<Radio />}
                  label={type.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={10}>
        <TextField
          required
          name="bonus_or_on_account"
          value={bonusAmount}
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <strong>₪</strong>
              </InputAdornment>
            ),
            inputProps: {
              className: "only-numbers",
            },
          }}
          onChange={(e) => {
            e.preventDefault();
            if (error) {
              setError(null);
            }
            setBonusAmount(e.target.value);
          }}
          label="הזן סכום"
          style={{
            width: "70%",
          }}
          size="small"
        />
      </Grid>
      {renderError()}
      <Grid item xs={10}>
        <Typography variant="body1">הערות</Typography>
        <TextField
          multiline
          rows={4}
          onChange={(e) => {
            e.preventDefault();
            setNote(e.target.value);
          }}
          value={note}
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid
        item
        container
        xs={10}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={7} lg={7}>
          <Button
            className="btn--abort"
            textValue="הסר בונוס"
            onClick={() => handleDelete(employee?.event.id)}
            color="#db1e1e"
            iconSize="1.8rem"
            disabled={!employee?.event}
          ></Button>
        </Grid>
        <Grid item xs={4} lg={4}>
          <Button
            className="btn--submit"
            textValue="אישור"
            onClick={() => handleConfirm(employee)}
            color="#00a2ea"
            iconSize="1.8rem"
          ></Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportBonusModal;
