import React from "react";
import { I18n } from "react-redux-i18n";

import SimpleTable from "../../../orders/manageOrders/receiveOrder/SimpleTable";

const ExportPreviewTable = ({ tableItems, attendance = false }) => {
  const tableColumns = [
    {
      title: "שם עובד",
      name: "employee_name",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "מספר עובד",
      name: "employee_number",
      render: (data, row) => {
        return parseInt(data);
      },
    },
    {
      title: "מספר הזהות",
      name: "identifier",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "שם דיווח",
      name: "name",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "סמל דיווח",
      name: "report_code",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "כמות",
      name: "amount",
      render: (data, row) => {
        return parseInt(data) / 100;
      },
    },
    {
      title: "מחיר",
      name: "cost",
      render: (data, row) => {
        return parseInt(data) / 100;
      },
    },
  ];

  const tableAttendanceColumns = [
    {
      title: "שם עובד",
      name: "employee_name",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "מספר הזהות",
      name: "identifier",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "מס' מיכפל",
      name: "micpal_clock_number",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "תאריך",
      name: "date",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "שעה",
      name: "time",
      render: (data, row) => {
        return data;
      },
    },

    {
      title: "סטטוס",
      name: "type",
      render: (data, row) => {
        return I18n.t(`micpal.${data}`);
      },
    },
  ];

  return (
    <SimpleTable
      columns={attendance ? tableAttendanceColumns : tableColumns}
      data={tableItems}
      cellSize="medium"
    />
  );
};

export default ExportPreviewTable;
