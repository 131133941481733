import Axios from "axios";
import authService from "../../../config/auth";
import { setError } from "../../../redux/actions/errorAction";
import { LOGIN } from "../../../redux/actions/loginAction/types";

export const login = (credentials) => async (dispatch) => {
  const { email, password, remember_me } = credentials;
  try {
    const response = await authService.login(email, password, remember_me);
    const token = response.auth_token;
    const response_user = await Axios.post("jwttokenlogin", null, {
      params: {
        token,
        fcm_token: localStorage.getItem("fcm_token"),
      },
    });

    const user = response_user.data;
    dispatch({
      type: LOGIN,
      payload: { token, user, password, email },
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          setError(
            "אימייל משתמש או סיסמא שגויים, יש לוודא כי השדות נכונים",
            "פרטי התחברות לא נכונים"
          )
        );
      } else if (error.response.status === 403) {
        dispatch(setError("משתמש זה כבר אינו פעיל במערכת"));
      }
    }
  }
};

export const getOtpCode = async (credentials) => {
  try {
    const response = await Axios.post(`getOtpCodePreLogin`, credentials);
    return response.data;
  } catch (error) {
    const status = error?.response?.status;
    let hebrewErrorMessage = "";

    switch (status) {
      case 409:
        hebrewErrorMessage =
          "נמצאו מס' משתמשים אשר מתאימים לפרטים שהוזנו, לפרטים נוספים פנה לצוות RESTIGO";
        break;
      case 429:
        hebrewErrorMessage = "בוצע מקסימום נסיונות לקבלת קוד חד פעמי";
        break;
      default:
        hebrewErrorMessage = "תקלה בשרת";
    }

    throw new Error(hebrewErrorMessage);
  }
};

export const loginByOtp = (credentials) => async (dispatch) => {
  try {
    const response = await Axios.post(`loginByMobileNumber`, credentials);
    const token = response.data.data.auth_token;
    const response_user = await Axios.post("jwttokenlogin", null, {
      params: {
        token,
      },
    });

    const user = response_user.data;
    dispatch({
      type: LOGIN,
      payload: { token, user, password: null, email: user?.email },
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(setError("פרטי התחברות אינם נכונים"));
      } else if (error.response.status === 403) {
        dispatch(setError("משתמש זה כבר אינו פעיל במערכת"));
      }
    }
  }
};
