import { IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import OutsideAlerter from "../../../Hooks";

const TableSearch = ({
  className = "",
  style,
  handleSearchChange,
  isCollapse = false,
  placeholder = "חיפוש",
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [collapsed, setCollapsed] = useState(true);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    handleSearchChange(e.target.value);
  };

  const search = useRef();

  return (
    <OutsideAlerter style={{display:"flex",justifyContent:"center",alignItems:"center"}} onOuterClick={() => setCollapsed(true)}>
        <div className="flex-center fluid">
        <IconButton
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          if (collapsed) search.current.focus();
          else search.current.blur();
          setCollapsed(!collapsed);
        }}
      >
        <FaSearch />
      </IconButton>
      {/* <button>
          <i className="fa fa-search"></i>
        </button> */}
      <div
        style={style}
        className={`search-table ${className} ${
          collapsed && isCollapse ? "search-table__collapsed" : ""
        }`}
      >
        <input
          ref={search}
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          name="search"
          value={searchValue || ""}
          onChange={(e) => handleChange(e)}
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => {
            e.target.select();
          }}
        />
      </div>
        </div>
 
    </OutsideAlerter>
  );
};
export default TableSearch;
