import React from "react";
import { Skeleton } from "@mui/material";
// "react-loading-skeleton";
import Styled from "styled-components";
// 350

const SkeletonWrapper = Styled.div`
margin-top:2rem;
height: calc(100vh - 360px);
`;

const SkeletonReports = ({ loader, size = 10 }) => {
  return loader ? (
    <SkeletonWrapper style={{ height: "auto" }}>
      {Array(size)
        .fill()
        .map((item, index) => (
          <div key={index}>
            <Skeleton height={"5rem"} />
          </div>
        ))}
    </SkeletonWrapper>
  ) : (
    <></>
  );
};

export default SkeletonReports;
