import Axios from "axios";
import { cloneDeep, mapKeys, omit, orderBy, set } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Printer } from "react-feather";
import { Col, Row } from "react-flexbox-grid";
import Store from "../../../config/store";
import {
  FaBook,
  FaExclamationTriangle,
  FaRegPaperPlane,
  FaEnvelope,
  FaFileExcel,
  FaSms,
} from "react-icons/fa";
import { I18n } from "react-redux-i18n";
import { ClipLoader } from "react-spinners";
import {
  useExpanded,
  useFilters,
  useGroupBy,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Button from "../../../components/buttons/Button";
import NewButton from "../../../components/buttons/NewButton";
import NewButtonIcon from "../../../components/buttons/NewButtonIcon";
import SingleExcelFile from "../../../components/excel/SingleExcelFile";
import { BASE_URL } from "../../../config/auth";
import { dateReqFormat, getSafe } from "../../../helpers";
import icon from "../../../images/metro-warning.svg";
import EditShiftHours from "../../schedule/manager/table/models/EditShiftHours";
import fileDownload from "js-file-download";
import { setDialogModal } from "../../../redux/actions/DialogAction";
import { connect, Provider, useSelector } from "react-redux";
import TablesDivider from "../../../components/print/PrintTable";

import MicpalPreviewTable from "./Accountecy/MicpalPreviewTable";
import Pagination from "../../../components/tables/Pagination";
import AddNewAssign from "../../schedule/manager/table/models/AddNewAssign";
import { COLOR_WARNINGS } from "../../../const/colors";
import EmployeesList from "./Accountecy/EmployeesList";
import { setError } from "../../../redux/actions/errorAction";
import { MailInputModal } from "../MailInputModal";
import { logout } from "../../../redux/actions/loginAction";
import { setConfirm } from "../../../redux/actions/confirmAction";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import SendSms from "../../schedule/manager/table/models/SendSms";
import ExportPreviewTable from "./Accountecy/ExportPreviewTable";
import { useScreenSize } from "../../../Hooks";
import Logs from "../../schedule/manager/table/models/Logs";
import Dialog from "../../../components/modals/Dialog";
import { renderToString } from "react-dom/server";
import Modal from "react-responsive-modal";
import { Box, Typography } from "@mui/material";
import AddDailyNotes from "../../schedule/manager/table/models/AddDailyNotes";
import ManualHoursModal from "./ManualHoursModal";
import Excel101Settings from "./Excel101Settings";
import {
  attendanceExportTypes,
  attendanceExportHeader,
  EXPORT_TYPE_HARGAL,
} from "../constants";
import HargalPreviewTable from "./Accountecy/HargalPreviewTable";
const EXPORT_MAIL_OPTIONS = [0, "sendMicaplToMail", 0, "sendShiklulitToMail"];
const EXPORT_OPTIONS = [
  0,
  "exportToMicpal",
  "exportToOketz",
  "exportToShiklulit",
  0,
  "exportToHargal",
];

const ReportsTable = ({
  columns,
  data,
  token,
  is_weekly_limit,
  reload,
  printButtonTextValue = 'הדפס דו"ח',
  printTitle,
  printDate,
  setConfirm,
  printMeta,
  logout,
  checkedBranches,
  type,
  header,
  isManager,
  deleteEvent,
  hiddenColumns = [],
  aggregates = [],
  isTableExpanded = false,
  selected_branch = null,
  date,
  setDialogModal,
  isSingleEmployee = false,
  multiPageSize = 500,
  printAll = false,
  fetchData,
  blocked_from_date,
  isAllowedToEdit,
  showMichpal,
  checkedDepartments,
  checkedEmployees,
  setError,
  sendPdf,
  pdfObj,
  setPdfObj,
  week_summary = null,
  showAllDates = false,
  setIsPadDatesOnProcess,
  branches = [],
  sortBy = [
    { id: "employee_last_name", desc: false },
    {
      id: "working_date",
      desc: false,
    },
    {
      id: "date",
      desc: false,
    },
    {
      id: "actual_start",
      desc: false,
    },
    {
      id: "full_name",
      desc: false,
    },
  ],
  excelConfig = {},
  isMulti,
  isHaveAnomaliesInReport,
  setOpenUnvalidShiftReport,
  showUnvalidShifts,
  options,
}) => {
  const { isMobile } = useScreenSize();
  const [dataState, setDataState] = useState(data);
  const [addAssignEmployee, setAddAssignEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clonedWeekSummary, setClonedWeekSummary] = useState(null);
  const [openBonusModal, setOpenBonusModal] = useState(false);
  const [openExcel101Settings, setOpenExcel101Settings] = useState(false);
  const holidays = useSelector((state) => state.schedule.schedule.holidays);
  //const [sentPdf, setSentPdf] = useState(false); //in case of on time send

  const genrateHoursReportWithWeekSummary = (
    weekSummaryFromApi,
    totalDataOfHours
  ) => {
    for (let [numOfWeek, summaryOfWeek] of Object.entries(weekSummaryFromApi)) {
      let total = 0;
      for (let [hoursSummaryKey, value] of Object.entries(summaryOfWeek)) {
        if (
          hoursSummaryKey === "working_date" ||
          hoursSummaryKey === "number_of_week"
        ) {
          continue;
        }
        total += value;
      }
      if (total === 0) {
        delete weekSummaryFromApi[numOfWeek];
      }
    }
    let employeeName = totalDataOfHours[0]?.employee_name;
    const weekSummary = Object.values(weekSummaryFromApi).map((week_sum) => {
      return {
        ...week_sum,
        is_week_summary: true,
        employee_name: employeeName,
      };
    });
    const unitedData = [...totalDataOfHours, ...weekSummary];
    const orderedData = orderBy(unitedData, ["working_date"]);
    if (typeof setIsPadDatesOnProcess === "function") {
      setIsPadDatesOnProcess(false);
    }
    return orderedData;
  };

  const getMonthOfDate = (date) => {
    return new Date(date).getMonth();
  };

  const getDaysInMonth = (date) => {
    const month = getMonthOfDate(date);
    const year = new Date(date).getFullYear();
    return new Date(year, month, 0).getDate();
  };
  const download = useMemo(
    () => async (file_id) => {
      try {
        const response = await Axios.get(
          `NewSubmissionRequest/${file_id}/file/download`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        return response.data;
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logout();
          } else if (e.response.status === 500) {
            setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
          } else if (e.response.status === 404) {
            setError("תמונה לא קיימת", "מחיקת תמונה", false, true);
          }
      }
    },
    [token]
  );
  const getDiffBetweenDates = (startDate, endDate) => {
    const date1 = new Date(moment(startDate).format("YYYY-MM-DD"));
    const date2 = new Date(moment(endDate).format("YYYY-MM-DD"));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const padAttendanceReportWithAllDates = (
    weekSummaryFromApi,
    totalDataOfHours
  ) => {
    let [startRange, endRange] = Object.values(date);

    const paddedDay = cloneDeep(totalDataOfHours?.[0]);
    Object.keys(paddedDay).forEach((key) => {
      if (
        key === "branch_id" ||
        key === "branch_name" ||
        key === "employee_id" ||
        key === "employee_name" ||
        key === "employee_number" ||
        key === "user_enable"
      ) {
        return paddedDay[key];
      }
      return (paddedDay[key] = null);
    });
    paddedDay["is_padded"] = true;

    const daysInRange = getDiffBetweenDates(startRange, endRange) + 1;
    const paddedLaborData = Array(daysInRange).fill(paddedDay);

    const generatedLaborData = new Map();
    paddedLaborData.map((labor) => {
      labor["working_date"] = moment(startRange).format("YYYY-MM-DD");
      labor["week_day"] = new Date(startRange).getDay();
      generatedLaborData.set(
        moment(startRange).format("YYYY-MM-DD"),
        cloneDeep(labor)
      );
      startRange = new Date(
        new Date(startRange).setDate(new Date(startRange).getDate() + 1)
      );
      return labor;
    });
    const skippedShifts = [];
    totalDataOfHours.map((realLabor, index) => {
      if (
        realLabor?.working_date == totalDataOfHours[index - 1]?.["working_date"]
      ) {
        skippedShifts.push(realLabor);
      } else {
        generatedLaborData.set(realLabor.working_date, realLabor);
      }
      return realLabor;
    });

    const newTotalData = [...generatedLaborData.values(), ...skippedShifts];
    if (!is_weekly_limit) {
      const orderedData = orderBy(newTotalData, ["working_date"]);
      if (typeof setIsPadDatesOnProcess === "function") {
        setIsPadDatesOnProcess(false);
      }
      return orderedData;
    }
    let employeeName = totalDataOfHours[0]?.employee_name;
    const weekSummary = Object.values(weekSummaryFromApi).map((week_sum) => {
      return {
        ...week_sum,
        is_week_summary: true,
        employee_name: employeeName,
      };
    });
    const unitedData = [...newTotalData, ...weekSummary];
    const orderedData = orderBy(unitedData, ["working_date"]);
    if (typeof setIsPadDatesOnProcess === "function") {
      setIsPadDatesOnProcess(false);
    }
    return orderedData;
  };

  useEffect(() => {
    setClonedWeekSummary(cloneDeep(week_summary));
  }, []);

  useEffect(() => {
    if (showAllDates && data.length) {
      const orderedData = padAttendanceReportWithAllDates(
        clonedWeekSummary || week_summary,
        data
      );
      setDataState(orderedData);
      return;
    }
    if (week_summary && is_weekly_limit) {
      const orderedData = genrateHoursReportWithWeekSummary(week_summary, data);
      setDataState(orderedData);
      return;
    }
    if (typeof setIsPadDatesOnProcess === "function") {
      setIsPadDatesOnProcess(false);
    }
    setDataState(data);
  }, [data, showAllDates]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    allColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    footerGroups,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: dataState,
      initialState: {
        pageIndex: 0,
        pageSize: 500,
        hiddenColumns: [...hiddenColumns.filter((x) => x !== "cost")],
        sortBy,
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  const [activeRow, setActiveRow] = useState(null);
  const [print, setPrint] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [openMailInput, setOpenMailInput] = useState(false);
  const [openSms, setOpenSms] = useState(false);
  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns]);

  useEffect(() => {
    setPageSize(multiPageSize);
  }, [multiPageSize]);

  const exportMicpal = useMemo(
    () => async () => {
      let checkedEmployeesToProps = checkedEmployees;
      if (type === "salary_by_identifier" && checkedEmployeesToProps?.length) {
        checkedEmployeesToProps = options?.employees
          ?.filter((employee) => checkedEmployees.includes(employee.value))
          .map((employee) => employee.additionalEmployeeId);
      }
      const formData = new FormData();
      formData.append("expenses_start_submit", dateReqFormat(date.start));
      formData.append("expenses_end_submit", dateReqFormat(date.end));
      formData.append("employee_ids", JSON.stringify(checkedEmployeesToProps));
      formData.append("department_ids", JSON.stringify(checkedDepartments));
      formData.append("react", 1);
      if (type === "salary_by_identifier") {
        formData.append("branches_id", JSON.stringify(checkedBranches));
      }
      await Axios.post(
        `${BASE_URL}/api/branchs/${selected_branch.id}/${
          EXPORT_OPTIONS[selected_branch.micpal_working]
        }`,
        formData,
        { responseType: "stream" }
      ).then((res) => {
        if (
          EXPORT_OPTIONS[selected_branch.micpal_working] === "exportToMicpal" ||
          EXPORT_OPTIONS[selected_branch.micpal_working] === "exportToHargal"
        ) {
          setDialogModal({
            title:
              `${
                attendanceExportHeader[selected_branch.micpal_working]
              } - תצוגה מקדימה לחברה ` +
              res.data.company_number +
              " לחודש " +
              res.data.month_year,
            btnConfirmText: "ייצא קובץ",
            thirdButtonText: "שלח קובץ במייל",
            showThirdButton: true,
            thirdButtonClass: "color__white",
            closeAfterThird: false,
            thirdButtonAction: () => {
              setOpenMailInput(true);
            },
            thirdButtonIcon: FaRegPaperPlane,
            onConfirm: () => {
              const file_name = res.data.name;
              let file = res.data.string;
              if (selected_branch.micpal_working === EXPORT_TYPE_HARGAL) {
                const content = file.join("\n");
                const blob = new Blob([content], { type: "text/plain" });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = file_name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
              } else {
                fileDownload(file, file_name);
              }
            },
            text:
              EXPORT_OPTIONS[selected_branch.micpal_working] ===
              "exportToMicpal" ? (
                <MicpalPreviewTable tableItems={res.data.array} />
              ) : (
                <HargalPreviewTable tableItems={res.data.array} />
              ),
            maxWidthStyle: "44%",
          });
        } else if (
          EXPORT_OPTIONS[selected_branch.micpal_working] === "exportToShiklulit"
        ) {
          let { array } = res.data;
          if (array != undefined) {
            setDialogModal({
              title:
                "ייצוא לשיקלולית - תצוגה מקדימה לחודש " + res.data.month_year,
              btnConfirmText: "ייצא קובץ",
              thirdButtonText: "שלח קובץ במייל",
              showThirdButton: true,
              thirdButtonClass: "color__white",
              closeAfterThird: false,
              thirdButtonAction: () => {
                setOpenMailInput(true);
              },
              thirdButtonIcon: FaRegPaperPlane,
              onConfirm: () => {
                const file_name = res.data.name;
                const file = res.data.string;
                fileDownload(file, file_name);
              },
              text: <ExportPreviewTable tableItems={res.data.array} />,
              maxWidthStyle: "44%",
            });
          } else {
            let { file_content, file_name, employees_without_employee_number } =
              res.data;
            employees_without_employee_number = Object.values(
              employees_without_employee_number || {}
            );
            if (
              employees_without_employee_number &&
              employees_without_employee_number.length
            ) {
              setDialogModal({
                showAbortButton: false,
                title: "עובדים ללא מס' במערכת שכר",
                btnConfirmText: "המשך",
                iconJSX: () =>
                  FaExclamationTriangle({ size: "7em", color: COLOR_WARNINGS }),
                text: (
                  <EmployeesList
                    data={Object.values(employees_without_employee_number)}
                  />
                ),
              });
            }
            if (file_content === "") {
              setError(
                "לא נמצאו עובדים עם מספרי עובד במערכת שכר",
                "לתשומת לבך",
                false,
                true
              );
            } else {
              fileDownload(file_content, file_name);
            }
          }
        } else {
          var headers = res.headers;
          var contentDisposition = headers["content-disposition"];
          const file_name = contentDisposition.split("=")[1];
          const file = res.data.substring(1);
          fileDownload(file, file_name);
        }
      });
    },
    [
      selected_branch,
      date,
      setDialogModal,
      checkedDepartments,
      checkedEmployees,
    ]
  );

  const exportMicpalByMail = useMemo(
    () => async (email) => {
      try {
        await Axios.post(
          `${BASE_URL}/api/accountancy/${
            EXPORT_MAIL_OPTIONS[selected_branch.micpal_working]
          }`,
          {
            expenses_start_submit: dateReqFormat(date.start),
            expenses_end_submit: dateReqFormat(date.end),
            employee_ids: JSON.stringify(checkedEmployees),
            department_ids: JSON.stringify(checkedDepartments),
            email_to: email,
            branch_id: selected_branch.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setConfirm("", "נשלח בהצלחה");
      } catch (e) {
        if (e.response) {
          if (e.response.status === 401) {
            logout();
          } else if (e.response.status === 500) {
            setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
          }
        }
      }
    },
    [selected_branch, date, checkedDepartments, checkedEmployees]
  );

  const getFirstSummaryOfRelatedIndex = (fromIndex, allIndexes) => {
    if (!is_weekly_limit) {
      return 0;
    }
    let indexOfFirstSummary = allIndexes[0];
    allIndexes.findLast((el) => {
      if (el > fromIndex) {
        indexOfFirstSummary = el;
      }
    });
    return indexOfFirstSummary;
  };

  function getAllIndexesOfWeekSummary(arr, val) {
    let indexes = [];
    if (!is_weekly_limit) {
      return indexes;
    }
    arr.map((element, index) => {
      if (element?.is_week_summary == val) {
        indexes.push(index);
      }
    });
    return indexes;
  }

  const subtractFromWeekSummary = (
    allReport,
    idxOfChanged,
    indexOfFirstSummary
  ) => {
    for (const hours in allReport[indexOfFirstSummary]) {
      if (
        hours === "working_date" ||
        hours === "number_of_week" ||
        hours === "is_week_summary" ||
        hours === "name" ||
        hours === "employee_name"
      ) {
        continue;
      }
      allReport[indexOfFirstSummary][hours] -= allReport[idxOfChanged][hours];
    }
    let total = 0;
    for (let [hoursSummaryKey, value] of Object.entries(
      allReport[indexOfFirstSummary]
    )) {
      if (
        hoursSummaryKey === "working_date" ||
        hoursSummaryKey === "number_of_week" ||
        hoursSummaryKey === "employee_name" ||
        hoursSummaryKey === "name" ||
        hoursSummaryKey === "is_week_summary" ||
        hoursSummaryKey === "days"
      ) {
        continue;
      }
      total += value;
    }
    if (total === 0) {
      delete allReport[indexOfFirstSummary];
    }
  };

  const handleEventToDelete = ({ event, index }) => {
    let allIndexes = getAllIndexesOfWeekSummary(dataState, true);
    let indexOfFirstSummary = getFirstSummaryOfRelatedIndex(index, allIndexes);
    setDialogModal({
      icon,
      title: "מחיקת שיבוץ",
      text: `פעולה זו תסיר את העובד ואת השעות בפועל משיבוץ זה לצמיתות. האם אתה בטוח בפעולה זו?`,
      onConfirm: () => {
        deleteEvent({
          event,
          isReport: true,
          onSuccess: () => {
            let tempData = cloneDeep(dataState);
            if (is_weekly_limit && indexOfFirstSummary) {
              subtractFromWeekSummary(tempData, index, indexOfFirstSummary);
            }
            if (showAllDates) {
              Object.keys(tempData[index]).forEach((key) => {
                if (
                  key === "branch_id" ||
                  key === "branch_name" ||
                  key === "employee_id" ||
                  key === "employee_name" ||
                  key === "employee_number" ||
                  key === "user_enable" ||
                  key === "number_of_week" ||
                  key === "working_date"
                ) {
                  return tempData[index][key];
                }
                return (tempData[index][key] = null);
              });
              tempData[index]["is_padded"] = true;
              // tempData.splice(index, 1, tempData[index]);
              setDataState(tempData);
            } else {
              tempData.splice(index, 1);
              setDataState(tempData);
            }
          },
        });
      },
    });
  };

  const visibleColumns = allColumns
    .map((column) => {
      if (column.isVisible) {
        return {
          Header: column.Header,
          Footer: column.Footer,
          accessor: column.id,
        };
      }
      return null;
    })
    .filter((column) => column);

  const setDepthClass = ({ depth, canExpand }) => {
    if (canExpand)
      switch (depth) {
        case 0:
          return "table--depth-first";
        case 1:
          return "table--depth-second";
        case 2:
          return "table--depth-third";
        default:
          return "";
      }

    return "";
  };

  const sendPDF = async (employee_id, save = false, department_id = 0) => {
    //setPdfProgress(true);

    const html_table = renderToString(
      <div style={{ direction: "rtl" }}>
        <Provider store={Store}>
          <TablesDivider
            printDate={printDate}
            printTitle={printTitle}
            printMeta={printMeta}
            columns={allColumns}
            data={rows
              .map((x) => {
                if (x.isExpanded && x.canExpand) {
                  return null;
                }
                return x.original;
              })
              .filter((x) => x)}
            holidays={holidays}
            isTableExpanded={isTableExpanded}
          />
        </Provider>
      </div>
    );

    const sendOb = {
      table: html_table,
      employee_id: employee_id,
      department_id: department_id,
      employee_name: printTitle,
      date: printDate,
      branch: selected_branch.network_name,
    };
    if (save) {
      pdfObj[employee_id] = sendOb;
      setPdfObj({ ...pdfObj });
    } else {
      setLoading(true);
      await Axios.post(`${BASE_URL}/api/htmlMailPdf`, sendOb, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        setLoading(false);
        setConfirm("", "נשלח בהצלחה");
      });
    }
  };
  useEffect(() => {
    if (sendPdf && rows.length)
      sendPDF(
        rows[0].original.employee_id,
        true,
        rows[0].original.department_id
      );
  }, [sendPdf]);
  const updateTable = async (event, remove_id = null) => {
    if (fetchData && event.employee) {
      const data = await fetchData([event.employee.id]);

      if (data) {
        if (data.length && data[0].body) {
          if (data[0].weekly_summary && is_weekly_limit && !showAllDates) {
            const weekSummaryFromUpdate = data[0].weekly_summary;
            const orderedData = genrateHoursReportWithWeekSummary(
              weekSummaryFromUpdate,
              data[0].body
            );
            setDataState(orderedData);
            return;
          }
          if (showAllDates) {
            const paddedDataAfterUpdate = padAttendanceReportWithAllDates(
              data[0].weekly_summary,
              data[0].body
            );
            setDataState(paddedDataAfterUpdate);
            return;
          }
          setDataState(data[0].body);
        } else if (remove_id) {
          setDataState((prev) =>
            Object.values({
              ...omit(mapKeys(prev, "id"), remove_id),
              ...mapKeys(data, "id"),
            })
          );
        }
      }
    }
    setEventToEdit(null);
  };

  const openExcelModalAnomalies = (rows) => {
    setDialogModal({
      title: "לתשומת ליבך קיימות משמרות פתוחות בטווח התאריכים הנבחר",
      customButton: (
        <SingleExcelFile
          data={rows.map((row) => ({
            ...row.values,
            expander: row.canExpand,
          }))}
          type={type}
          fileName={`${printTitle} ${
            isSingleEmployee ? rows[0].values["employee_name"] : ""
          } ${printDate}`}
          columns={visibleColumns ? visibleColumns : columns}
          aggregates={aggregates}
          expanded={isTableExpanded}
          cellsConfig={excelConfig}
        />
      ),
      showAbortButton: false,
      btnConfirmText: "הצג משמרות פתוחות",
      onConfirm: () => setOpenUnvalidShiftReport(true),
    });
  };

  const openMicpalAnomaliesNotification = () => {
    setDialogModal({
      title: "לתשומת ליבך קיימות משמרות פתוחות בטווח התאריכים הנבחר",
      onAbort: () => exportMicpal(),
      btnAbortText: attendanceExportTypes[selected_branch.micpal_working],
      btnConfirmText: "הצג משמרות פתוחות",
      btnAbortClass: "btn--confirm",
      onConfirm: () => setOpenUnvalidShiftReport(true),
    });
  };
  const handleOpenSettingsModal = () => {
    setOpenExcel101Settings(!openExcel101Settings);
  };
  const showExcelSettings = () => {
    return checkedBranches.some((branchId) => {
      const branch = branches.find((branch) => branch.id === branchId);
      return branch && branch.excel_export_101 === 1;
    });
  };
  const getCheckedBranchesData = () => {
    return checkedBranches
      .map((branchId) => {
        const branch = branches.find((branch) => branch.id === branchId);
        return branch ? { id: branch.id, name: branch.name } : null;
      })
      .filter((branch) => branch !== null);
  };

  return dataState.length ? (
    <>
      {loading && (
        <div
          style={{
            height: "100%",
            position: "relative",
            top: "100px",
            left: "0%",
          }}
          className="flex-center"
        >
          <ClipLoader size={60} color="#00a2ea" loading={true} />
        </div>
      )}
      {isSingleEmployee ? (
        <SendSms
          event={{
            employee: {
              id: rows[0].original.employee_id,
              name: rows[0].original.employee_name,
            },
          }}
          open={openSms}
          setOpen={setOpenSms}
          fromEmployeeModal={true}
          branch_name={selected_branch.name}
        />
      ) : (
        ""
      )}
      {openMailInput && (
        <MailInputModal
          open={openMailInput}
          onClose={() => setOpenMailInput(false)}
          onSubmit={(email) => exportMicpalByMail([email])}
        />
      )}
      <Dialog
        setOpen={() => {
          setEventToEdit(null);
        }}
        open={!!(eventToEdit != null && isManager)}
        sx={{ padding: "2rem" }}
        transitionDuration={0}
      >
        {/* <Modal
        animationDuration={0}
        styles={{ modal: { padding: "2rem", width: "fit-content" } }}
        open={eventToEdit != null && isManager}
        center
        onClose={() => setEventToEdit(null)}
        > */}

        {!!eventToEdit &&
          (eventToEdit.typeLogs ? (
            <Logs logs={eventToEdit.logs} />
          ) : eventToEdit.openHoursEdit ? (
            <EditShiftHours
              style={{ boxShadow: "none" }}
              saveToReport={true}
              // reload={reload}
              handleOpen={setEventToEdit}
              event={eventToEdit}
              updateTable={updateTable}
              setOpenBonusModal={setOpenBonusModal}
              openBonusModal={openBonusModal}
              fromReportsTable={true}
            />
          ) : (
            <AddDailyNotes
              event={eventToEdit}
              handleOpen={setEventToEdit}
              updateTable={updateTable}
            />
          ))}
        {/* </Modal> */}
      </Dialog>
      <Dialog
        transitionDuration={0}
        setOpen={() => {
          setAddAssignEmployee(null);
        }}
        open={!!(addAssignEmployee && isManager)}
        sx={{ padding: "2rem" }}
      >
        {/* <Modal
        animationDuration={0}
        styles={{ modal: { padding: "2rem", width: "fit-content" } }}
        open={addAssignEmployee && isManager}
        center
        onClose={() => setAddAssignEmployee(null)}
        > */}
        {addAssignEmployee ? (
          <AddNewAssign
            style={{
              boxShadow: "none",
              maxWidth: isMobile ? "400px" : null,
            }}
            saveToReport={true}
            handleOpen={setAddAssignEmployee}
            employee={addAssignEmployee}
            updateTable={updateTable}
          />
        ) : (
          <></>
        )}
        {/* </Modal> */}
      </Dialog>
      <Row className="not-print" style={{ marginBottom: "3rem" }}>
        <Col
          xs
          style={{ borderTop: "2px solid #0090d0", margin: "1rem" }}
        ></Col>
      </Row>
      <Row
        className="not-print"
        style={
          isMobile
            ? { padding: "0", margin: "0" }
            : { padding: "1rem 1rem 0 1rem" }
        }
      >
        <div className="flex-center-start " style={{ width: "50%" }}>
          {header.employee_name && isManager && <h2>{header.employee_name}</h2>}
        </div>
        {isMobile ? (
          <div style={{ width: "100%" }} className="flex-end">
            {isSingleEmployee
              ? isManager && (
                  <div>
                    <ButtonIcon
                      onClick={() => sendPDF(rows[0].original.employee_id)}
                      icon={FaEnvelope}
                    />
                    <ButtonIcon onClick={() => setOpenSms(true)} icon={FaSms} />
                  </div>
                )
              : ""}
            <NewButtonIcon
              icon={<Printer size={16} />}
              // onClick={handlePrintTable}
              onClick={() => setPrint(true)}
              alt="הדפס"
            />
            <SingleExcelFile
              data={rows.map((row) => ({ ...row.values }))}
              fileName={`${printTitle} ${
                isSingleEmployee ? rows[0].values["employee_name"] : ""
              } ${printDate}`}
              columns={visibleColumns ? visibleColumns : columns}
              aggregates={aggregates}
            />
          </div>
        ) : (
          <div style={{ width: "50%" }} className="flex-end">
            {isSingleEmployee
              ? isManager && (
                  <div>
                    <ButtonIcon
                      onClick={() => sendPDF(rows[0].original.employee_id)}
                      icon={FaEnvelope}
                    />
                    <ButtonIcon onClick={() => setOpenSms(true)} icon={FaSms} />
                  </div>
                )
              : ""}
            <NewButton
              icon={<Printer size={16} />}
              textValue={printButtonTextValue}
              // onClick={handlePrintTable}
              onClick={() => setPrint(true)}
            />
            {!isHaveAnomaliesInReport || isMulti || !showUnvalidShifts ? (
              <SingleExcelFile
                data={rows
                  .filter((row) => row.original.is_week_summary !== true)
                  .map((row) => ({
                    ...row.values,
                    expander: row.canExpand,
                  }))}
                fileName={`${printTitle} ${
                  isSingleEmployee ? rows[0].values["employee_name"] : ""
                } ${printDate}`}
                columns={visibleColumns ? visibleColumns : columns}
                aggregates={aggregates}
                expanded={isTableExpanded}
                cellsConfig={excelConfig}
              />
            ) : (
              <Button
                onClick={() => openExcelModalAnomalies(rows)}
                className="btn--normal"
                icon={FaFileExcel}
                textValue="ייצא לאקסל"
              />
            )}
            {showExcelSettings() && (
              <>
                <Excel101Settings
                  setOpenExcel101Settings={setOpenExcel101Settings}
                  openExcel101Settings={openExcel101Settings}
                  checkedBranches={getCheckedBranchesData()}
                  date={date}
                  checkedDepartments={checkedDepartments}
                  checkedEmployees={checkedEmployees}
                />
                <Button
                  onClick={() => handleOpenSettingsModal()}
                  className="btn--normal"
                  textValue="ייצוא 101 לאקסל"
                />
              </>
            )}
            {showMichpal &&
            getSafe(() => selected_branch.micpal_working) &&
            EXPORT_OPTIONS[selected_branch.micpal_working] ? (
              <Button
                onClick={
                  isHaveAnomaliesInReport
                    ? openMicpalAnomaliesNotification
                    : exportMicpal
                }
                className="btn--normal"
                icon={FaBook}
                textValue={I18n.t(
                  `micpal.${EXPORT_OPTIONS[selected_branch.micpal_working]}`
                )}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </Row>
      <div className={`not-print table  ${isMobile ? "frame" : ""}`}>
        <div className={isMobile ? "frame" : "overflow"}>
          <table
            style={isMobile ? { width: "1570px" } : {}}
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.id === "buttons" ? "not-print" : ""}
                      style={
                        column.id === "buttons"
                          ? isManager
                            ? { width: "1px" }
                            : { display: "none" }
                          : {}
                      }
                      {...(column.id === "buttons"
                        ? { ...column.getHeaderProps() }
                        : {
                            ...column.getHeaderProps(
                              column.getSortByToggleProps()
                            ),
                          })}
                    >
                      <span>
                        {column.id === "buttons" ||
                        column.id === "expander" ||
                        column.id === "title_0" ? (
                          <></>
                        ) : column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fa fa-sort-down" />
                          ) : (
                            <i className="fa fa-sort-up" />
                          )
                        ) : (
                          <i className="fa fa-sort" />
                        )}
                      </span>
                      {column.render("Header", {
                        title: `${printTitle} ${
                          isSingleEmployee
                            ? rows[0].values["employee_name"]
                            : ""
                        } ${printDate}`,
                        blocked_from_date,
                        isManager,
                        isAllowedToEdit,
                        isMobile,
                        setAddAssignEmployee,
                      })}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className={page[0].canExpand ? "" : "routine"}
              {...getTableBodyProps()}
            >
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => setActiveRow(i === activeRow ? null : i)}
                    className={`${
                      activeRow === i ? "table--active-row" : ""
                    } ${setDepthClass(row)}`}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={
                            cell.column.id === "buttons"
                              ? isManager
                                ? { width: "1px" }
                                : { display: "none" }
                              : cell.row.original.className === "error"
                              ? { color: "red" }
                              : {}
                          }
                          className={
                            cell.column.id === "buttons" ? "not-print" : ""
                          }
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell", {
                            deleteEvent,
                            handleEventToDelete,
                            setDataState,
                            data,
                            setEventToEdit,
                            blocked_from_date,
                            holidays,
                            isManager,
                            isAllowedToEdit,
                            download,
                          })}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td
                      style={
                        column.id === "buttons"
                          ? isManager
                            ? { width: "1px" }
                            : { display: "none" }
                          : {}
                      }
                      {...column.getFooterProps()}
                    >
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <Pagination
          previousPage={previousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageIndex={pageIndex}
          pageCount={pageCount}
        />
      </div>
      {(print || printAll) && (
        <TablesDivider
          printDate={printDate}
          printTitle={printTitle}
          printMeta={printMeta}
          columns={allColumns}
          holidays={holidays}
          data={rows
            .map((x) => {
              if (x.isExpanded && x.canExpand) {
                return null;
              }
              return x.original;
            })
            .filter((x) => x)}
          setPrint={setPrint}
          printAll={printAll}
          isTableExpanded={isTableExpanded}
        />
      )}
    </>
  ) : (
    ""
  );
};
const mapStateToProps = (state) => {
  const { token } = state.auth;
  const { selected_branch } = state.branches;
  const branches = state.branches.branches;
  const { is_weekly_limit } = selected_branch;
  return { token, is_weekly_limit, branches };
};
export default connect(mapStateToProps, {
  setDialogModal,
  setError,
  logout,
  setConfirm,
})(ReportsTable);
