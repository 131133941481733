import { Box, IconButton } from "@mui/material";
import { Button } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { AiFillDelete } from "react-icons/ai";
import { FaCloudUploadAlt } from "react-icons/fa";
import { niceBytes } from "../../helpers/fileUpload";
import Typography from "@mui/material/Typography";
import PreviewFile from "./PreviewFile";
import { isFile } from "../Forms/helpers";
import ErrorMessage from "../Forms/ErrorMessage";
import { SIZE_LIMIT_IN_SERVER } from "../../const";
import { Controller } from "react-hook-form";

const ControlDnd = ({
  style,
  children,
  maxFiles = 1,
  maxSize = SIZE_LIMIT_IN_SERVER,
  accept = ["application/pdf"],
  name,
  openDnd,
  disabled,
  control,
  rules,
}) => {
  return (
    <Controller
      {...{ name, control, rules }}
      render={({
        field: { name, onChange, ref, value },
        fieldState: { error },
      }) => (
        <DND
          {...{
            style,
            maxFiles,
            maxSize,
            accept,
            error,
            name,
            inputRefForValidation: ref,
            disabled,
            value,
            setValue: onChange,
          }}
        />
      )}
    />
  );
};

const DND = ({
  style,
  children,
  maxFiles = 1,
  maxSize = SIZE_LIMIT_IN_SERVER,
  value,
  accept,
  error,
  setValue,
  name,
  inputRefForValidation,
  disabled,
}) => {
  const [preview, setPreview] = useState([]);
  const [globalErrors, setGlobalErrors] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    setErrorMessages({
      "file-too-large": `קובץ גדול מ: ${niceBytes(maxSize)}`,
      "file-invalid-type": `חייב להיות ${
        typeof accept === "string"
          ? `מסוג: ${accept}`
          : `אחד מהסוגים: ${accept?.join(", ")}`
      }`,
    });
  }, [maxSize, accept]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = [...acceptedFiles];
      if (!files.length) return;
      setValue(files);
    },
    [setValue]
  );

  useEffect(() => {
    setPreview(
      Array.isArray(value)
        ? value.map((file) =>
            isFile(file)
              ? {
                  preview: URL.createObjectURL(file),
                  type: file?.type,
                  name: file?.name,
                  size: file?.size,
                }
              : {
                  preview: file?.preview,
                  type: file?.type,
                  name: file?.name,
                  size: file?.size,
                }
          )
        : []
    );
  }, [value]);

  useEffect(() => {
    return () => {
      preview?.forEach((file) => {
        if (isFile(file)) URL.revokeObjectURL(file.preview);
      });
    };
  }, [preview]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    disabled,
    maxSize,
  });

  useEffect(() => {
    let errors = [];
    let toMuch = false;
    fileRejections.forEach((reject) =>
      reject.errors.forEach((error) => {
        switch (error.code) {
          case "too-many-files":
            toMuch = true;
            break;
          default:
            errors.push(`${reject.file.name} ${errorMessages[error.code]}`);
            break;
        }
      })
    );
    toMuch &&
      errors.unshift(`יותר מידי קבצים כמות הקבצים המותרת היא: ${maxFiles}`);
    setGlobalErrors([...errors]);
  }, [fileRejections, maxFiles, errorMessages]);

  // const clearFiles = () => {
  //   setValue([]);
  //   acceptedFiles.length = 0;
  //   acceptedFiles.splice(0, acceptedFiles.length);
  //   if (inputRef?.current) inputRef.current.value = "";
  // };

  const removeFile = (index) => {
    let newValue = [...acceptedFiles];
    newValue.splice(index, 1);
    acceptedFiles.splice(index, 1);
    setValue(newValue);
  };

  const RenderUploadList = ({ file, index }) => {
    return (
      <div className="FileDropZoneManager">
        <div className="PreviewContainer">
          <div
            className="PreviewImgContainer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PreviewFile {...{ file }} width="50" height="50" />
          </div>
          <div className="PreviewFileName flex-column-right">
            <Typography
              className="left-to-right"
              variant="h5"
              component="div"
              color="textPrimary"
            >
              {file.name}
            </Typography>
            <Typography
              className="left-to-right"
              variant="subtitle1"
              component="div"
              color="textSecondary"
            >
              {niceBytes(file.size)}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flex: 1,
            }}
          >
            <span className="PreviewDeleteButton">
              <IconButton
                size="medium"
                style={{ color: "gray" }}
                onClick={() => removeFile(index)}
              >
                {AiFillDelete({ size: "1.8rem" })}
              </IconButton>
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!preview.length ? (
        <div className="FileDropZoneManager">
          <div
            className={`DropZone ${
              isDragActive
                ? "DragIsActive"
                : !!error || fileRejections.length
                ? "DragIsError"
                : ""
            }`}
            {...getRootProps()}
            style={
              style ?? {
                width: "100%",
                height: "100%",
              }
            }
          >
            <input ref={inputRefForValidation} {...getInputProps({ name })} />
            <Box>
              {FaCloudUploadAlt({ size: "6rem", color: "lightBlue" })}
              {children ? (
                children(isDragActive)
              ) : isDragActive ? (
                <p className="DragIsActiveSpan">זרוק כאן ...</p>
              ) : (
                <div className="DragSpan">
                  <Typography
                    variant="h5"
                    display="block"
                    component="div"
                    gutterBottom
                    color="textSecondary"
                  >
                    {"גרור " +
                      (accept.find((s) => s === "application/pdf")
                        ? "קובץ"
                        : "תמונה") +
                      " לכאן"}
                  </Typography>
                  <Typography
                    variant="h4"
                    display="block"
                    component="div"
                    gutterBottom
                    color="textPrimary"
                  >
                    או
                  </Typography>
                  <Button color="primary" variant="contained" size="large">
                    <Typography variant="h6" color="white">
                      {"לחץ " +
                        "לבחירת " +
                        (accept.find((s) => s === "application/pdf")
                          ? "קובץ"
                          : "תמונה")}
                    </Typography>
                  </Button>
                </div>
              )}
            </Box>
          </div>
        </div>
      ) : (
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-evenly",
          }}
        >
          <div className="DropZone">
            <div>
              {preview.map((file, index) => (
                <RenderUploadList {...{ file, index }} key={index} />
              ))}
            </div>
          </div>
        </Box>
      )}
      <div>
        <ErrorMessage>{error?.message}</ErrorMessage>
        {globalErrors.map((error, index) => (
          <div key={index}>
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        ))}
      </div>
    </>
  );
};
export { DND, ControlDnd };
