import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { debounce } from "lodash";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { fetchEmployeesBySendingId } from "../../../redux/actions/scheduleAction/index";
import { Context } from "../Show";
import React from "react";
import { dateReqFormat } from "../../../helpers";

// ======== REDUX ========
import { useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========

// ======== ICONS ========

// ======== CONSTANTS ========
import {
  PARTS,
  PART_EMPLOYEE_NAME,
  INPUT,
  PART_TYPE_IDENTIFER,
  PART_WORKER_QUESTION,
  PART_DATE,
} from "./const";

// ======== COMPONENTS ========

const Show = (props) => {
  // ======== HOOKS ========
  const { parts, user } = useContext(Context);
  const { setValue, watch } = useFormContext();
  const branchEmployees = useSelector((state) => state.employees);
  const branchInfo = useSelector((state) => state.auth.user.branch);
  const { branches } = useSelector((state) => state);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("s"));
  const dispatch = useDispatch();

  // ======== STATES ========
  const [identifier, setIdentifier] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [needWorkPeriod, setNeedWorkPeriod] = useState("");
  const [procedureBranches, setProcedureBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState(null);
  const [createdDate, setCreatedDate] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeCompanyName, setEmployeeCompanyName] = useState(" ");
  //   const classes = useStyles();

  // ======== HANDLERS ========
  const handleBranchChange = (e, id, index) => {
    setBranchSelected(e.label);
    dispatch(fetchEmployeesBySendingId(e.id));
  };
  const handleEmployeeChange = (e, id, index) => {
    const identifierId = parts.find(
      (item) => item.type === PART_TYPE_IDENTIFER
    );

    setValue(`parts.${index}`, { id, value: e.label });
    setValue("employee_id", e.id);
    setCreatedDate(e.createdDate);
    setEmployeeName(e.name);
    setEmployeeCompanyName(e.company_name);
    if (identifierId) {
      setValue(`parts.${PART_TYPE_IDENTIFER}`, {
        id: identifierId.id,
        value: e.identifier,
      });
      setIdentifier(e.identifier);
    }
  };
  const debouncedData = debounce(
    (e, id, index) => {
      if (index == PART_WORKER_QUESTION) {
        setValue(`parts.${index}`, { id, value: e });
      } else {
        setValue(`parts.${index}`, { id, value: e.value });
        switch (e.name) {
          case `parts.${PART_TYPE_IDENTIFER}.value`:
            setIdentifier(e.value);
            break;
          case `parts.${PART_DATE}.value`:
            setLastDate(e.value);
            break;
          default:
            return;
        }
      }
    },
    [100]
  );
  function decodeHtmlEntities(text) {
    const txt = document.createElement("textarea");
    txt.innerHTML = text;
    return txt.value;
  }
  const memoEmployess = useMemo(() => {
    return Object.entries(branchEmployees).map(([key, value]) => {
      return {
        id: key,
        label: value.full_name + ", " + value.department_name,
        name: value.full_name,
        identifier: value.employee_ID_Number,
        department: { id: value.department_id, label: value.department_name },
        salary: value.employee_base_sal,
        createdDate: moment(value.createdDate).format("DD/MM/YYYY"),
        company_name: value.company_name,
      };
    });
  }, [branchEmployees]);

  const dateReqFormat = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // ======== CONSTANTS ========

  // ======== EFFECTS ========
  useEffect(() => {
    const updatedBranches = branches.branches.map((branch) => ({
      id: branch.id,
      label: branch.name,
    }));
    setProcedureBranches(updatedBranches);
    parts.forEach((part) => {
      if (part.type === PART_DATE) {
        setValue(`parts.${PART_DATE}`, {
          id: part.id,
          value: dateReqFormat(new Date()),
        });
        setLastDate(dateReqFormat(new Date()));
      }
    });
  }, []);

  // ======== UI ========

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: isTablet ? 0 : 50,
        justifyContent: "center",
        gap: "3rem",
      }}
    >
      <div
        style={{
          margin: isTablet ? 0 : 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "3rem",
          fontSize: isMobile ? "1.8rem" : "2rem",
          maxWidth: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography>שם סניף</Typography>
            <ControlAutoComplete
              {...{
                fullWidth: true,
                name: `branch`,
                rules: { required: REQUIRED_MESSAGE },
                options: procedureBranches,
                handleInputChange: (value) => {
                  handleBranchChange(value);
                },
              }}
            />
          </Grid>
          {parts?.map(({ label, value, type, ...part }, index) => {
            switch (type) {
              case PART_EMPLOYEE_NAME:
                return (
                  <Grid item xs={6}>
                    <Typography>שם העובד</Typography>
                    <ControlAutoComplete
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        options: memoEmployess,
                        handleInputChange: (value) =>
                          handleEmployeeChange(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_TYPE_IDENTIFER:
                return (
                  <Grid item xs={6}>
                    <Typography>תעודת זהות</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        id: part.id,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );

              case PART_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography> תאריך</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        max: dateReqFormat(
                          new Date() // Maxium is today
                        ),
                      }}
                      defaultValue={dateReqFormat(new Date())}
                    />
                  </Grid>
                );

              case PART_WORKER_QUESTION:
                return (
                  <Grid item xs={6}>
                    <Typography>סיום סיבת העסקה</Typography>
                    <input
                      type="checkbox"
                      checked={needWorkPeriod === true}
                      onChange={(value) => {
                        setNeedWorkPeriod(true);
                        debouncedData(part.id, type);
                      }}
                    />
                    <label style={{ fontSize: "15px" }}>פיטורים</label>
                    <br />
                    <input
                      type="checkbox"
                      checked={needWorkPeriod === false}
                      onChange={(value) => {
                        setNeedWorkPeriod(false);
                        debouncedData(part.id, type);
                      }}
                    />
                    <label style={{ fontSize: "15px" }}>התפטרות</label>
                  </Grid>
                );
            }
          })}
        </Grid>
        <Divider style={{ width: "100%" }} />
        <div>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <p>
              <strong>תאריך : </strong>{" "}
              {moment(new Date()).format("DD/MM/YYYY")}
            </p>
          </div>
          <br></br>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>לכבוד: {employeeName}</h3>
            <p> באמצעות דואר אלקטרוני </p>
            <p> מבלי לפגוע בזכויות </p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2>
            <u>הנדון: אישור על תקופת העסקה</u>
          </h2>
        </div>
        <p>
          1. הננו מאשרים בזאת כי העובד/ת {employeeName} ת.ז/דרכון {identifier},
          הועסק/ה על ידי חברת {decodeHtmlEntities(employeeCompanyName)},{" "}
          {branchInfo.company_h_p ?? ""} החל מיום {createdDate} ועד ליום{" "}
          {moment(lastDate).format("DD/MM/YYYY")}
        </p>

        <p>2. סיבת סיום העסקה: {needWorkPeriod ? "פיטורים" : "התפטרות"}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <h3>בברכה,</h3>
        <h3>
          {user.full_name}, {user.type_info?.title ?? ""}
        </h3>
        <h3> {employeeCompanyName}</h3>
      </div>
    </div>
  );
};

export default Show;
