// submissionReducer.js
import {
  FETCH_SUBMISSIONS,
  ADD_SUBMISSIONS,
  EDIT_VACATION_STATUS,
  SET_SPECIAL_REQUEST_COUNT,
} from "../actions/scheduleAction/types";
import { mapKeys } from "lodash";
import { LOGOUT } from "../actions/loginAction/types";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";

const INITIAL_STATE = {
  submissions: {},
  matrix: {},
  notes: {},
  notes_matrix: {},
  shifts_and_days: [],
  roles_and_days: [],
  special_requests_count: 0,
  special_requests: {},
};

const submissionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SUBMISSIONS:
      return {
        ...state,
        submissions: mapKeys(action.payload.submissions, "id"),
        matrix: action.payload.matrix,
        notes: mapKeys(action.payload.notes, "id"),
        notes_matrix: action.payload.notes_matrix,
        shifts_and_days: action.payload.shifts_and_days,
        roles_and_days: action.payload.roles_and_days,
        special_requests: action.payload.special_requests,
      };
    case EDIT_VACATION_STATUS:
      return {
        ...state,
        notes: mapKeys(action.payload.notes, "id"),
        notes_matrix: action.payload.notes_matrix,
      };
    case SET_SPECIAL_REQUEST_COUNT:
      return {
        ...state,
        special_requests_count: action.payload,
      };
    case ADD_SUBMISSIONS:
      return {
        ...state,
        submissions: {
          ...state.submissions,
          ...mapKeys(action.payload.submissions, "id"),
        },
        matrix: action.payload.matrix,
        notes: {
          ...state.notes,
          ...mapKeys(action.payload.notes, "id"),
        },
        notes_matrix: action.payload.notes_matrix,
      };
    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default submissionReducer;
