//DialogModal
import { isFunction } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-responsive-modal";
import { hideDialog } from "../../redux/actions/DialogAction";
import Button from "../buttons/Button";

const DialogModal = ({
  title,
  text,
  onConfirm,
  onAbort = null,
  icon,
  iconJSX,
  textArea,
  btnConfirmText = "אישור",
  btnAbortText = "ביטול",
  btnConfirmClass = null,
  btnAbortClass = null,
  isOpen,
  hideDialog,
  closeAfterConfirm = true,
  closeAfterThird = true,
  showThirdButton = false,
  showAbortButton = true,
  showConfirmButton = true,
  thirdButtonText,
  thirdButtonClass,
  thirdButtonAction,
  fourthButtonText,
  fourthButtonClass,
  fourthButtonAction,
  showFourthButton = false,
  closeAfterFourth = true,
  fourthButtonIcon,
  fifthButtonText,
  fifthButtonClass,
  fifthButtonAction,
  showFifthButton = false,
  closeAfterFifth = true,
  fifthButtonIcon,
  closeOnEsc = true,
  closeOnOverlayClick = true,
  showCloseIcon = true,
  maxWidthStyle = "50rem",
  no_scroll = false,
  thirdButtonIcon,
  customButton,
}) => {
  const handleConfirm = () => {
    if (isFunction(onConfirm)) {
      onConfirm();
    }

    if (closeAfterConfirm) {
      hideDialog();
    }
  };

  const handleAbort = () => {
    if (isFunction(onAbort)) {
      onAbort();
    }
    hideDialog();
  };

  const handleThirdButtonClick = () => {
    if (isFunction(thirdButtonAction)) {
      thirdButtonAction();
    }
    if (closeAfterThird) {
      hideDialog();
    }
  };

  const handleFourthButtonClick = () => {
    if (isFunction(fourthButtonAction)) {
      fourthButtonAction();
    }
    if (closeAfterFourth) {
      hideDialog();
    }
  };

  const handleFifthButtonClick = () => {
    if (isFunction(fifthButtonAction)) {
      fifthButtonAction();
    }
    if (closeAfterFifth) {
      hideDialog();
    }
  };

  return (
    <Modal
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={closeOnOverlayClick}
      showCloseIcon={showCloseIcon}
      classNames={{ modal: "dialog-modal" }}
      styles={{
        modal: {
          maxWidth: maxWidthStyle,
          textAlign: "center",
          padding: "2.5rem",
        },
      }}
      center
      open={isOpen}
      onClose={() => {
        handleAbort();
        hideDialog();
      }}
      animationDuration={200}
    >
      {iconJSX ? (
        iconJSX()
      ) : (
        <img alt="" style={{ width: "84px" }} src={icon} />
      )}

      <h2>{title}</h2>
      <div
        className={no_scroll ? "" : "paragraph"}
        style={{ fontSize: "1.4rem", fontWeight: "500" }}
      >
        {text}
      </div>
      {textArea && textArea}
      <div
        style={{ width: "fit-content", margin: "0 auto", marginTop: "2rem" }}
        className={`flex ${
          showFourthButton && showFifthButton ? "flex-wrap " : ""
        } `}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleConfirm();
          }
        }}
      >
        {customButton ? customButton : ""}
        {showAbortButton && (
          <Button
            onClick={handleAbort}
            className={`btn--neutral ${btnAbortClass ? btnAbortClass : ""}`}
            textValue={btnAbortText}
          />
        )}
        {showConfirmButton && (
          <Button
            onClick={handleConfirm}
            className={`btn--submit ${btnConfirmClass ? btnConfirmClass : ""}`}
            textValue={btnConfirmText}
          />
        )}

        {showThirdButton ? (
          <Button
            onClick={handleThirdButtonClick}
            className={`btn--submit ${
              thirdButtonClass ? thirdButtonClass : ""
            }`}
            textValue={thirdButtonText}
            icon={thirdButtonIcon}
          />
        ) : (
          ""
        )}
        {showFourthButton ? (
          <Button
            onClick={handleFourthButtonClick}
            className={`btn--darkgrey ${
              fourthButtonClass ? fourthButtonClass : ""
            }`}
            textValue={fourthButtonText}
            icon={fourthButtonIcon}
          />
        ) : (
          ""
        )}
        {showFifthButton ? (
          <Button
            onClick={handleFifthButtonClick}
            className={`btn--skyblue ${
              fifthButtonClass ? fifthButtonClass : ""
            }`}
            textValue={fifthButtonText}
            icon={fifthButtonIcon}
          />
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const {
    title,
    text,
    onConfirm,
    onAbort,
    icon,
    iconJSX,
    textArea,
    btnConfirmText,
    btnAbortText,
    isOpen,
    btnConfirmClass,
    btnAbortClass,
    closeAfterConfirm,
    showThirdButton,
    showAbortButton,
    showConfirmButton,
    thirdButtonText,
    thirdButtonClass,
    thirdButtonAction,
    fourthButtonText,
    fourthButtonClass,
    fourthButtonAction,
    showFourthButton,
    closeAfterFourth,
    fourthButtonIcon,
    fifthButtonText,
    fifthButtonClass,
    fifthButtonAction,
    showFifthButton,
    closeAfterFifth,
    fifthButtonIcon,
    closeOnEsc,
    closeOnOverlayClick,
    showCloseIcon,
    maxWidthStyle,
    no_scroll,
    thirdButtonIcon,
    closeAfterThird,
    customButton,
  } = state.dialog;
  return {
    title,
    text,
    onConfirm,
    onAbort,
    icon,
    btnConfirmText,
    btnAbortText,
    isOpen,
    btnConfirmClass,
    btnAbortClass,
    iconJSX,
    textArea,
    closeAfterConfirm,
    showThirdButton,
    showAbortButton,
    showConfirmButton,
    thirdButtonText,
    thirdButtonClass,
    thirdButtonAction,
    fourthButtonText,
    fourthButtonClass,
    fourthButtonAction,
    showFourthButton,
    closeAfterFourth,
    fourthButtonIcon,
    fifthButtonText,
    fifthButtonClass,
    fifthButtonAction,
    showFifthButton,
    closeAfterFifth,
    fifthButtonIcon,
    closeOnEsc,
    closeOnOverlayClick,
    showCloseIcon,
    maxWidthStyle,
    no_scroll,
    thirdButtonIcon,
    closeAfterThird,
    customButton,
  };
};

export default connect(mapStateToProps, { hideDialog })(DialogModal);
