import React, { useState, useEffect, useRef, useMemo } from "react";
import { isArray, isFunction } from "lodash";
import OutsideAlerter from "../../Hooks";

const DropdownSelect = ({
  style,
  headerStyle = {},
  options = [],
  selected = null,
  setSelected = () => {},
  onChange = () => {},
  className = "",
  title = "",
  isSearchable = true,
  touched = false,
  onTouched,
  alerterStyle = {},
  disabled = false,
  label = null,
}) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const dSelect = useRef("dSelect");

  const filterBySearch = useMemo(
    () => async (subString) => {
      const filterList = await options.filter((option) =>
        option.name.includes(subString)
      );
      setList(
        filterList.length ? filterList : [{ name: "אפשרות לא קיימת", id: -999 }]
      );
    },
    [options]
  );
  useEffect(() => {
    if (!selected) {
      if (options.length) {
        setSelected(options[0]);
        if (isFunction(options[0].func)) options[0].func();
      }
    }
  }, [options]);

  useEffect(() => {
    setList(options);
  }, [options]);

  const onOptionClick = (option) => {
    if (option === selected || option.id === -999) {
      return setShow(false);
    }
    setSelected(option);
    setShow(false);
    if (isFunction(option.func)) option.func();
    if (isFunction(onChange)) onChange(option);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    filterBySearch(value);
  };

  return options.length ? (
    <OutsideAlerter style={alerterStyle} onOuterClick={() => setShow(false)}>
      {label && (
        <div className="margin-left-right">
          <label>{label}</label>
        </div>
      )}
      <div style={style} className={`dropdown-select ${className}`}>
        <button
          ref={dSelect}
          disabled={disabled}
          type="button"
          className={`btn--none  dropdown-select--header ${
            disabled ? "disabled" : ""
          }`}
          style={headerStyle}
          onClick={() => {
            if (touched) {
              onTouched();
            }
            setShow(!show);
          }}
        >
          {title}
          {options.length ? (selected ? selected.name : options[0].name) : ""}
          <span>
            <i className="fa fa-caret-down dropdown-select__icon"></i>
          </span>
        </button>
        <div
          className={`dropdown-select--content ${
            show ? "dropdown-select--content__show" : ""
          }`}
        >
          {isSearchable ? (
            <div className="dropdown-select--option__search">
              <input
                className="dropdown-select--search"
                type="search"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                name="search"
                value={searchValue}
                onChange={(e) => handleSearchChange(e)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    const found = options.find(
                      (option) => option.name === e.target.value
                    );
                    if (found) {
                      onOptionClick(found);
                      setSearchValue("");
                      filterBySearch("");
                    }
                  }
                }}
              />
              <i className="fa fa-search"></i>
            </div>
          ) : (
            <></>
          )}
          {isArray(list) &&
            list.map((option, index) => {
              return (
                <div
                  className={`dropdown-select--option ${
                    selected
                      ? selected.id === option.id
                        ? "dropdown-select--option__selected"
                        : "dropdown-select--option__hover"
                      : "dropdown-select--option__hover"
                  }`}
                  onClick={() => onOptionClick(option)}
                  key={index}
                >
                  {option.name}
                </div>
              );
            })}
        </div>
      </div>
    </OutsideAlerter>
  ) : (
    <></>
  );
};

export default DropdownSelect;
