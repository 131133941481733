//Message.jsx
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { COLOR_ABORT } from "../const/colors";
import {
  getMessages,
  setAcknowledgedMessages,
} from "../redux/actions/messageAction";

const Message = ({
  privilege_id,
  network_id,
  branch_id,
  getMessages,
  messages = [],
  default_open,
  isAcknowledged,
  currentMessagesToShow,
  setAcknowledgedMessages,
}) => {
  const [open, setOpen] = useState(default_open);

  useEffect(() => {
    setOpen(default_open);
  }, [default_open]);

  useEffect(() => {
    getMessages({ network_id, branch_id, privilege_id });
  }, [getMessages, network_id, branch_id, privilege_id]);

  return messages.length ? (
    <>
      <Modal
        styles={{
          modal: { textAlign: "center", padding: "2rem" },
        }}
        classNames={{ modal: "dialog-modal" }}
        center
        open={open}
        onClose={() => setOpen(false)}
        animationDuration={0}
      >
        <h1 style={{ borderBottom: "1px solid #ccc", padding: "2rem" }}>
          הודעות
        </h1>
        <div className="paragraph">
          {messages.length
            ? messages.map((x) => {
                return (
                  <div
                    key={x.id}
                    style={{ borderBottom: "1px solid #ccc", padding: "2rem" }}
                    className="u-right-text"
                  >
                    <h3 style={x.force ? { color: COLOR_ABORT } : {}}>
                      {x.title}
                    </h3>
                    <p className="paragraph">{x.text}</p>
                    {x.link ? (
                      <>
                        {" "}
                        <br />
                        <p className="paragraph"> מצורף קישור:</p>
                        <a href={x.link} target="_blank">
                          {x.link}
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            : null}
        </div>
        <label className=" dropdown-select--checkbox">
          <input
            type="checkbox"
            checked={isAcknowledged}
            onChange={(e) =>{
              setAcknowledgedMessages(e.target.checked, currentMessagesToShow)
            }}
          />
          <span className="dropdown-select--checkmark"></span>

          <span className="u-right-text">קראתי</span>
        </label>
      </Modal>

      <button
        style={{ position: "relative" }}
        className="btn btn--none message--button"
        type="button"
        onClick={() => setOpen(true)}
      >
        <div className="red-circle">{messages.length}</div>
        <i className="fa fa-envelope" />
      </button>
    </>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => {
  const { privilege_id } = state.user_privileges;
  const { network_id, branch_id } = state.auth.user;
  const { currentMessagesToShow, isAcknowledged } = state.messages;
  const messages = Object.values(currentMessagesToShow).sort(
    (a, b) => b.force - a.force
  );
  const default_open = Boolean(messages.find((x) => x.force)) && !isAcknowledged;

  return {
    privilege_id,
    network_id,
    branch_id,
    messages,
    default_open,
    isAcknowledged,
    currentMessagesToShow,
  };
};

export default connect(mapStateToProps, {
  getMessages,
  setAcknowledgedMessages,
})(Message);
