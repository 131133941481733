import {
  INVENTORY_DAILY_COUNT,
  INVENTORY_EXPENDITURE,
  INVENTORY_INVENTORY_COUNT,
  INVENTORY_PULL,
  INVENTORY_THROW,
  INVENTORY_TRANSFER,
  INVENTORY_PRODUCTION
} from "../../privilegesIndexes";

export const INVENTORY_TYPE_COUNT = 1;
export const INVENTORY_TYPE_THROW = 2;
export const INVENTORY_TYPE_TRANSFER = 3;
export const INVENTORY_TYPE_PULL = 4;
export const INVENTORY_TYPE_SALE = 5;
export const INVENTORY_TYPE_EXPENDITURE = 7;
export const INVENTORY_TYPE_DAILY_COUNT = 8;
export const INVENTORY_TYPE_PRODUCTION = 9;

export const INVENTORY_STATUS_PENDING = 0;
export const INVENTORY_STATUS_CANCELED = 1;
export const INVENTORY_STATUS_ACEPTED = 2;

export const getInventoryType = (type) => {
  return {
    [INVENTORY_TYPE_COUNT]: INVENTORY_TYPE_HEBREW[INVENTORY_TYPE_COUNT],
    [INVENTORY_TYPE_THROW]: INVENTORY_TYPE_HEBREW[INVENTORY_TYPE_THROW],
    [INVENTORY_TYPE_TRANSFER]: INVENTORY_TYPE_HEBREW[INVENTORY_TYPE_TRANSFER],
    [INVENTORY_TYPE_PULL]: INVENTORY_TYPE_HEBREW[INVENTORY_TYPE_PULL],
    [INVENTORY_TYPE_SALE]: INVENTORY_TYPE_HEBREW[INVENTORY_TYPE_SALE],
    [INVENTORY_TYPE_EXPENDITURE]: INVENTORY_TYPE_HEBREW[INVENTORY_TYPE_EXPENDITURE],
    [INVENTORY_TYPE_DAILY_COUNT]: INVENTORY_TYPE_HEBREW[INVENTORY_TYPE_DAILY_COUNT],
    [INVENTORY_TYPE_PRODUCTION]: INVENTORY_TYPE_HEBREW[INVENTORY_TYPE_PRODUCTION],
  }[type]
}

export const INVENTORY_TYPES_OPTIONS = [
  {
    value: INVENTORY_TYPE_COUNT,
    label: "ספירות מלאי",
    name: "ספירה חודשית",
    tabIndex: INVENTORY_INVENTORY_COUNT,
    index: 0,
  },
  {
    value: INVENTORY_TYPE_THROW,
    label: "זריקה/פחת",
    name: "זריקה/פחת",
    tabIndex: INVENTORY_THROW,
    index: 1,
  },
  {
    value: INVENTORY_TYPE_TRANSFER,
    label: "העברות",
    name: "העברה",
    tabIndex: INVENTORY_TRANSFER,
    index: 2,
  },
  {
    value: INVENTORY_TYPE_PULL,
    label: "משיכות",
    name: "משיכה",
    tabIndex: INVENTORY_PULL,
    index: 3,
  },
  {
    value: INVENTORY_TYPE_EXPENDITURE,
    label: "הוצאה מהמלאי",
    name: "הוצאה מהמלאי",
    tabIndex: INVENTORY_EXPENDITURE,
    index: 4,
  },
  {
    value: INVENTORY_TYPE_DAILY_COUNT,
    label: "ספירה לתאריך",
    name: "ספירה לתאריך",
    tabIndex: INVENTORY_DAILY_COUNT,
    index: 5,
  },
  {
    value: INVENTORY_TYPE_PRODUCTION,
    label: "ייצור למלאי",
    name: "ייצור למלאי",
    tabIndex: INVENTORY_PRODUCTION,
    index: 6,
  },
];

export const INVENTORY_TYPE_HEBREW = [
  '',
  'ספירת מלאי',
  'זריקה',
  'העברה',
  'משיכה',
  'מכירה',
  '',
  'הוצאה מהמלאי',
  'ספירת מלאי יומית',
  'ייצור למלאי',
];

export const TRANSFER_STATUS_TYPES = [
  {id: 0, name: "ממתינה לאישור"},
  {id: 1, name: "אושרה"},
  {id: 2, name: "בוטלה"},
];

// InventoryForm filter itemsType drop-down
export const ITEMS_TYPE_ITEMS = 1;
export const ITEMS_TYPE_PRODUCTS = 2;


// Logs types

export const ACTION_TYPE_LOCK = 'LOCK';
export const ACTION_TYPE_UNLOCK = 'UNLOCK';
export const ACTION_TYPE_CREATE = 'CREATE';
export const ACTION_TYPE_DELETE = 'DELETE';
export const ACTION_TYPE_STATUS = 'STATUS';
export const ACTION_TYPE_WORTH = 'WORTH';
export const ACTION_TYPE_DATE = 'DATE';
export const ACTION_TYPE_ADD_ITEM = 'ADD_ITEM';
export const ACTION_TYPE_CHANGE_ITEM = 'CHANGE_ITEM';
export const ACTION_TYPE_REMOVE_ITEM = 'REMOVE_ITEM';
export const ACTION_TYPE_ADD_PRODUCT = 'ADD_PRODUCT';
export const ACTION_TYPE_CHANGE_PRODUCT = 'CHANGE_PRODUCT';
export const ACTION_TYPE_REMOVE_PRODUCT = 'REMOVE_PRODUCT';

export const titlesForDestocking = {
  [INVENTORY_TYPE_EXPENDITURE]: "סוג הוצאה",
  [INVENTORY_TYPE_THROW]: "סוג זריקה",
}

export const salesOption = {
  value: INVENTORY_TYPE_SALE,
  label: "מכירה",
  name: "מכירה",
  tabIndex: 269,
  index: 7,
};