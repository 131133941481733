import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dateReqFormat } from "../../helpers";
import Axios from "axios";
import { addWeeks, endOfWeek, format, startOfWeek } from "date-fns";
import { objectToFormData } from "../../components/Forms/helpers";
import { setConfirm, setSnackBar } from "../actions/confirmAction";
import { setError } from "../actions/errorAction";
import { startLoading, stopLoading } from "../../redux/actions/loaderAction";
import { action } from "mobx";

const currentDate = new Date();
const BASE_URL = "tickets";
const ADMIN = "admin";
const DOWNLOAD_URL = "downloadFile";
// const TYPES_URL = "pettyCashBranches";

// const BASE_SHIFTS_URL = "pettyCashShifts";
// const BASE_PETTY_CASH_FORM = "depositPettyCash";
// const BASE_FETCH_TABLE = "depositPettyCash"
const INITIAL_STATE = {
  ticketObj: {
    id: null,
    detail: "",
    ticket_status: { value: 1, label: "פתוח" },
    ticket_type: "",
    extra_files: [],
    comment_extra_files: [],
  },
  ticketTypes: [],
  ticketStatuses: [],
  adminsList: [],
  networksList: [],
  branchesList: [],
  ticketsData: [],
  ticketFormEmployeeObj: {},
  commentsData: [],
  isLoading: false,
  isEditMode: false,
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
    setEditModeFalse: (state, action) => {
      state.isEditMode = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTicketStatusOptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTicketStatusOptions.fulfilled, (state, action) => {
      state.ticketStatuses = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTicketStatusOptions.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getTicketTypeOptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTicketTypeOptions.fulfilled, (state, action) => {
      state.ticketTypes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTicketTypeOptions.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAdmins.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdmins.fulfilled, (state, action) => {
      state.adminsList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAdmins.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getNetworks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNetworks.fulfilled, (state, action) => {
      state.networksList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getNetworks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllBranches.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBranches.fulfilled, (state, action) => {
      state.branchesList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllBranches.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchDataTable.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDataTable.fulfilled, (state, action) => {
      state.ticketsData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchDataTable.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchTicketComments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTicketComments.fulfilled, (state, action) => {
      state.commentsData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchTicketComments.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getEmployeeTicket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getEmployeeTicket.fulfilled, (state, action) => {
      const {
        id,
        user,
        status,
        type,
        details,
        network_name,
        branch_name,
        privilege_title,
        subject,
      } = action.payload;

      state.ticketFormEmployeeObj = {
        employee: {
          name: user.firstName + " " + user.lastName,
          privilege_title: privilege_title,
          branch_name: branch_name,
          network_name: network_name,
          mobileNumber: user.mobileNumber,
          id: user.id,
        },
      };
      const ticket_type = {
        id: type.id,
        value: type.id,
        label: type.label,
      };

      const ticket_status = {
        id: status.id,
        value: status.id,
        label: status.label,
      };
      const obj = {
        id,
        ticket_status,
        ticket_type,
        details,
        subject,
      };

      state.ticketObj = obj;

      state.isEditMode = true;
      state.isLoading = false;
    });
    builder.addCase(getEmployeeTicket.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(submitTicket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitTicket.fulfilled, (state, action) => {
      state.isEditMode = false;
      state.ticketObj = {
        id: null,
        detail: "",
        ticket_status: { value: 1, label: "פתוח" },
        ticket_type: "",
        extra_files: [],
        comment_extra_files: [],
      };
      state.isLoading = false;
    });
    builder.addCase(submitTicket.rejected, (state) => {
      state.isEditMode = false;
      state.isLoading = false;
    });

    builder.addCase(changeStatusToDeleted.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeStatusToDeleted.fulfilled, (state, action) => {
      const ticketId = action.payload.ticket.id;
      state.ticketsData = state.ticketsData.map((ticket) =>
        ticket.id === ticketId
          ? {
              ...ticket,
              ticket_status_id: action.payload.ticket.ticket_status_id,
              ticket_status: "נמחקו",
            }
          : ticket
      );
      state.isLoading = false;
    });
    builder.addCase(changeStatusToDeleted.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(changeStatusToDone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeStatusToDone.fulfilled, (state, action) => {
      const ticketId = action.payload.ticket.id;
      state.ticketsData = state.ticketsData.map((ticket) =>
        ticket.id === ticketId
          ? {
              ...ticket,
              ticket_status_id: action.payload.ticket.ticket_status_id,
              ticket_status: "טופל",
            }
          : ticket
      );
      state.isLoading = false;
    });
    builder.addCase(changeStatusToDone.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getTicketStatusOptions = createAsyncThunk(
  "getStatuses",
  async (payload, { dispatch, getState }) => {
    const token = getState().authAdmin.token;
    const res = await Axios.get(`${ADMIN}/${BASE_URL}/status`, {
      params: {},
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;

    return data;
  }
);

export const getTicketTypeOptions = createAsyncThunk(
  "getTypes",
  async (payload, { dispatch, getState }) => {
    const token = getState().authAdmin.token;
    const res = await Axios.get(`${ADMIN}/${BASE_URL}/types`, {
      params: {},
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;

    return data;
  }
);

export const submitTicket = createAsyncThunk(
  "submitTicket",
  async (payload, { dispatch, getState }) => {
    const token = getState().authAdmin.token;
    const ticketId = getState().ticketReducer.ticketObj?.id;
    let res;
    const formData = objectToFormData(payload); // Only because of files
    // const formProps = Object.fromEntries(formData);
    // console.log(formProps);

    try {
      dispatch(startLoading());

      if (ticketId) {
        res = await Axios.post(
          `${ADMIN}/${BASE_URL}/submitTicket/${ticketId}`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        // If ticketId does not exist, perform a POST request
        res = await Axios.post(`${ADMIN}/${BASE_URL}/submitTicket`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      if (res.status == 201) {
        dispatch(setConfirm("מילוי פניה ללקוח בוצעה בהצלחה!"));
      }
      if (res.status == 200) {
        dispatch(setConfirm("עריכת פניה בוצעה בהצלחה!"));
      }
    } catch (e) {
      dispatch(
        setError(
          "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          "ארעה שגיאה הטופס לא מולא כמו שצריך"
        )
      );
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const getAdmins = createAsyncThunk(
  "getAdmins",
  async (payload, { dispatch, getState }) => {
    const token = getState().authAdmin.token;

    const res = await Axios.get(`${ADMIN}/${BASE_URL}/admins`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const getNetworks = createAsyncThunk(
  "getNetworks",
  async (payload, { dispatch, getState }) => {
    const token = getState().authAdmin.token;

    const res = await Axios.get(`${ADMIN}/${BASE_URL}/networks`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const getAllBranches = createAsyncThunk(
  "getAllBranches",
  async ({ networkIds }, { dispatch, getState }) => {
    const token = getState().authAdmin.token;
    const res = await Axios.get(`${ADMIN}/${BASE_URL}/branches`, {
      params: { networkIds },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const fetchDataTable = createAsyncThunk(
  "fetchDataTable",
  async (
    {
      firstName,
      checkedBranches,
      checkedTypes,
      checkedStatuses,
      checkedAdmins,
    },
    { dispatch, getState }
  ) => {
    const token = getState().authAdmin.token;
    const params = {
      freeSearchKey: firstName ?? "",
      branch_id: checkedBranches.length > 0 ? checkedBranches : [],
      type_id: checkedTypes.length > 0 ? checkedTypes : [],
      status_ids: checkedStatuses.length > 0 ? checkedStatuses : [],
      admin_id: checkedAdmins.length > 0 ? checkedAdmins : [],
    };
    try {
      const { data } = await Axios.get(`${ADMIN}/${BASE_URL}/fetchFreeSearch`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (e) {
    } finally {
    }
  }
);

export const changeStatusToDeleted = createAsyncThunk(
  "changeStatusToDeleted",
  async ({ id }, { dispatch, getState }) => {
    const token = getState().authAdmin.token;

    try {
      dispatch(startLoading());
      const { data } = await Axios.post(
        `${ADMIN}/${BASE_URL}/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch(setConfirm("מחיקת טיקט בוצעה בהצלחה!"));
      return data.original;
    } catch (e) {
      dispatch(
        setError(
          "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          "ארעה שגיאה - טיקט לא נמחק"
        )
      );
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const getEmployeeTicket = createAsyncThunk(
  "getEmployeeTicket",
  async ({ id }, { dispatch, getState }) => {
    const token = getState().authAdmin.token;
    const res = await Axios.get(`${ADMIN}/${BASE_URL}/${id}`, {
      params: {},
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;

    return data;
  }
);

export const fetchTicketComments = createAsyncThunk(
  "fetchTicketComments",
  async ({ id }, { dispatch, getState }) => {
    const token = getState().authAdmin.token;
    const res = await Axios.get(`${ADMIN}/${BASE_URL}/comments/${id}`, {
      params: {},
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;

    return data;
  }
);

export const deleteComment = createAsyncThunk(
  "deleteComment",
  async ({ id }, { dispatch, getState }) => {
    const token = getState().authAdmin.token;
    let res;

    try {
      dispatch(startLoading());
      res = await Axios.post(
        `${ADMIN}/${BASE_URL}/comments/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.status == 200) {
        dispatch(setConfirm("מחיקת תגובה בוצעה בהצלחה!"));
      }
    } catch (e) {
      dispatch(
        setError(
          "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          "ארעה שגיאה - טיקט לא נמחק"
        )
      );
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const changeStatusToDone = createAsyncThunk(
  "changeStatusToDone",
  async ({ id, sendSms }, { dispatch, getState }) => {
    const token = getState().authAdmin.token;

    try {
      dispatch(startLoading());
      const { data } = await Axios.post(
        `${ADMIN}/${BASE_URL}/set-done/${id}`,
        { send_sms: sendSms },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      dispatch(
        setConfirm("הטיקט הועבר למצב טופל - נשלח עדכון למספר הטלפון של הלקוח!")
      );
      return data;
    } catch (e) {
      dispatch(
        setError(
          "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          "ארעה שגיאה - טיקט לא הועבר למצב טופל"
        )
      );
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const downloadTicketFiles = createAsyncThunk(
  "downloadTicketFiles",
  async ({ id }, { dispatch, getState }) => {
    const token = getState().authAdmin.token;

    try {
      let url = "";
      dispatch(startLoading("מוריד קובץ..."));
      const response = await Axios.get(`${ADMIN}/${BASE_URL}/${DOWNLOAD_URL}`, {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        const link = document.createElement("a");
        link.href = response.data;
        link.click();
      }
    } catch (error) {
      dispatch(setError());
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const downloadTicketCommentFiles = createAsyncThunk(
  "downloadTicketCommentFiles",
  async ({ id }, { dispatch, getState }) => {
    const token = getState().authAdmin.token;

    try {
      let url = "";
      dispatch(startLoading("מוריד קובץ..."));
      const response = await Axios.get(
        `${ADMIN}/${BASE_URL}/comments/${DOWNLOAD_URL}`,
        {
          params: {
            id: id,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data) {
        const link = document.createElement("a");
        link.href = response.data;
        link.click();
      }
    } catch (error) {
      dispatch(setError());
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const { reducer: ticketReducer, actions: TicketActions } = ticketSlice;
