import Axios from "axios";
import store from "../config/store";

const jwtAxios = Axios.create();

//request interceptor -> when sending request to server (from react to laravel)
//in the request we will send the jwt token
jwtAxios.interceptors.request.use((request) => {
  const tokenFromState = store.getState().authAdmin.token;
  request.headers = {
    //get the current token from redux
    Authorization: `Bearer ${tokenFromState}`,
  };
  return request;
});
export default jwtAxios;
