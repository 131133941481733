import React, { useEffect, useMemo } from "react";
import {
  fixDate,
  getHebDayByDate,
  getSafe,
  thousandSeparator,
} from "../../helpers";
import { SummeryBox } from "./SummeryBox";
import { isEmpty, mapKeys } from "lodash";
import { COLOR_SUBMIT } from "../../const/colors";
import BtnTooltip from "../../components/tooltips/BtnTooltip";
import { ClipLoader } from "react-spinners";
import { BsTagsFill } from "react-icons/bs";
import { useScreenSize } from "../../Hooks";
export const SummeryContainer = ({
  data = [],
  dates = [],
  selectedDateData = {},
  setSelectedDateData,
  onClickBox,
  selectedBox,
  last_4_days,
  total,
  totalExtra,
  dayAvg,
  lastYearIncome,
  loading,
  workingDaysUntillEndOfMonth,
  totalIncome,
  openDisplayTable,
  showWarningAccu,
  accGoal = "",
  precentageEmployerCost,
  calendarData,
  incomes = [],
}) => {
  const { isMobile } = useScreenSize();
  const dateArray = dates;
  const data_obj_by_date = useMemo(() => mapKeys(data, "date"), [data]);
  //console.log(totalIncome);

  total = total ? total : 0;
  totalExtra = totalExtra ? totalExtra : 0;
  last_4_days = last_4_days ? last_4_days : 0;
  //console.log(dateArray,data_obj_by_date,selectedDateData);
  // if(dateArray.length && isEmpty(selectedDateData))
  //   setSelectedDateData({ ...data_obj_by_date[dateArray.length - 1] });
  const handleOptionClicked = (date, index) => {
    //console.log(index,date);
    if (!(date in data_obj_by_date))
      setSelectedDateData({ cost: 0, date: date, index: index });
    else setSelectedDateData({ ...data_obj_by_date[date] });
  };
  const culcPrecentage = (n1, n2) => {
    // let dif =n1-n2;
    let av = (n2 / n1) * 100;
    // console.log((dif/av)*100);
    return av.toFixed(0);
  };
  const getPrecentage = (cost) => {
    if (!cost) return 0;
    //console.log(totalIncome,cost,cost/totalIncome*100);
    let av = (cost / totalIncome) * 100;
    av = isFinite(av) ? av : 0;
    return av.toFixed(1);
  };
  const totalIcomes = (inc) => {
    let incomeExtrasTotal = inc.reduce((a, { total_without_vat }) => {
      if (typeof a == "object") return a.cost + total_without_vat;
      return a + total_without_vat;
    }, 0);
    return incomeExtrasTotal;
  };
  // useEffect(() => {
  //   if (dateArray.length && !isEmpty(data_obj_by_date)) {
  //     setSelectedDateData({
  //       ...data_obj_by_date[dateArray[dateArray.length - 1]],
  //       index: dateArray.length - 1,
  //     });
  //   }
  // }, [dateArray, data_obj_by_date]);

  return (
    <div className="summeryBoxContainer">
      {" "}
      <SummeryBox selected={1 === selectedBox} onClick={() => onClickBox(1)}>
        {loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ) : (
          <>
            <div className="flex-between-center  margin-y-05">
              <i
                onClick={() => {
                  handleOptionClicked(
                    dates[selectedDateData.index - 1],
                    selectedDateData.index - 1
                  );
                }}
                style={{ color: COLOR_SUBMIT, fontSize: "1.8rem" }}
                className={
                  selectedDateData.index - 1 < 0 ? "" : "fa fa-chevron-right"
                }
              />
              <h2 className="summeryBoxByDate" style={{ fontSize: "1.9rem" }}>
                {isMobile ? getHebDayByDate(selectedDateData.date) + " - " : ""}
                {fixDate(selectedDateData.date)}
              </h2>
              <i
                onClick={() => {
                  handleOptionClicked(
                    dates[selectedDateData.index + 1],
                    selectedDateData.index + 1
                  );
                }}
                style={{ color: COLOR_SUBMIT, fontSize: "1.8rem" }}
                className={
                  selectedDateData.index + 1 === dateArray.length
                    ? ""
                    : "fa fa-chevron-left"
                }
              />
            </div>
            {isEmpty(selectedDateData) ? (
              ""
            ) : (
              <div
                className="summeryText"
                style={
                  isMobile ? { fontSize: "93%", textAlign: "initial" } : {}
                }
              >
                {isMobile ? (
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className="summeryText--1"
                      style={{ fontSize: "140%", marginLeft: "calc(37vw)" }}
                    >
                      <div className="flex-evenly-center">
                        <h3>
                          <span>&#8362;</span>
                          {getSafe(
                            () =>
                              thousandSeparator(
                                Number(selectedDateData.cost).toFixed(0)
                              ),
                            ""
                          )}
                        </h3>
                        <h3>
                          <BsTagsFill
                            onClick={() => {
                              //console.log("asdasdasd");
                              const costNoTax = getSafe(() => {
                                const unCost =
                                  isNaN(
                                    selectedDateData.cost /
                                      precentageEmployerCost
                                  ) ||
                                  !isFinite(
                                    selectedDateData.cost /
                                      precentageEmployerCost
                                  )
                                    ? 0
                                    : selectedDateData.cost /
                                      precentageEmployerCost;
                                return thousandSeparator(
                                  Number(unCost).toFixed(0)
                                );
                              }, "");
                              openDisplayTable(
                                {
                                  total_cost_before_employer_cost:
                                    thousandSeparator(
                                      Number(costNoTax).toFixed(0)
                                    ),
                                  navg: 0,
                                },
                                "",
                                false,
                                true
                              );
                            }}
                            fill="#00A2DE"
                          />
                        </h3>
                      </div>
                    </div>
                    <i style={{ marginLeft: "calc(37vw)", color: "#11998e" }}>
                      סה״כ כולל ע. מעביד
                    </i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text='סה"כ ללא ע"מ'>
                    <p className="summeryText--1">
                      <span>&#8362;</span>
                      {getSafe(() => {
                        const unCost =
                          isNaN(
                            selectedDateData.cost / precentageEmployerCost
                          ) ||
                          !isFinite(
                            selectedDateData.cost / precentageEmployerCost
                          )
                            ? 0
                            : selectedDateData.cost / precentageEmployerCost;
                        return thousandSeparator(Number(unCost).toFixed(0));
                      }, "")}
                    </p>
                  </BtnTooltip>
                )}

                {isMobile ? (
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className="summeryText--1"
                      style={{ fontSize: "140%" }}
                    >
                      <h3>
                        {getSafe(
                          () => getPrecentage(selectedDateData.cost),
                          "0%"
                        )}
                        <span>%</span>
                      </h3>
                    </div>
                    <i style={{ color: "#11998e" }}>% ע. מעביד</i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text='סה"כ עם ע"מ'>
                    <p className="summeryText--1">
                      <span>&#8362;</span>
                      {getSafe(
                        () =>
                          thousandSeparator(
                            Number(selectedDateData.cost).toFixed(0)
                          ),
                        ""
                      )}
                    </p>
                  </BtnTooltip>
                )}

                {!isMobile ? (
                  <BtnTooltip placement="left" text="אחוז מהכנסה">
                    <p className="summeryText--1">
                      <span></span>
                      {getSafe(() => {
                        return Number(selectedDateData.total_precent) + "%";
                      }, "0%")}
                    </p>
                  </BtnTooltip>
                ) : (
                  <BtnTooltip placement="left" text="אחוז מהכנסה">
                    <p className="summeryText--1"></p>
                  </BtnTooltip>
                )}
              </div>
            )}
          </>
        )}
      </SummeryBox>
      <SummeryBox selected={2 === selectedBox} onClick={() => {}}>
        {loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ) : (
          <>
            <h2>
              ממוצע 4 ימי {getHebDayByDate(selectedDateData.date)} האחרונים
            </h2>
            {isEmpty(selectedDateData) ? (
              ""
            ) : (
              <div
                className="summeryText"
                style={
                  isMobile ? { fontSize: "91%", textAlign: "initial" } : {}
                }
              >
                {isMobile ? (
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className="summeryText--2"
                      style={{ fontSize: "140%", marginLeft: "calc(30vw)" }}
                    >
                      <div className="flex-evenly-center">
                        <h3>
                          <span>&#8362;</span>
                          {getSafe(() => {
                            const average =
                              getHebDayByDate(selectedDateData.date) in
                              calendarData.sum
                                ? calendarData.sum[
                                    getHebDayByDate(selectedDateData.date)
                                  ].average
                                : 0;

                            return thousandSeparator(
                              Number(average).toFixed(0)
                            );
                          }, "")}
                        </h3>
                        <h3>
                          <BsTagsFill
                            onClick={() => {
                              //console.log("asdasdasd");
                              const averageNoTax = getSafe(() => {
                                const average =
                                  getHebDayByDate(selectedDateData.date) in
                                  calendarData.sum
                                    ? calendarData.sum[
                                        getHebDayByDate(selectedDateData.date)
                                      ].average
                                    : 0;
                                const unCost =
                                  isNaN(average / precentageEmployerCost) ||
                                  !isFinite(average / precentageEmployerCost)
                                    ? 0
                                    : average / precentageEmployerCost;
                                return thousandSeparator(
                                  Number(unCost).toFixed(0)
                                );
                              }, "");

                              const totalPrecentage = getSafe(() => {
                                const average =
                                  getHebDayByDate(selectedDateData.date) in
                                  calendarData.sum
                                    ? calendarData.sum[
                                        getHebDayByDate(selectedDateData.date)
                                      ].average
                                    : 0;
                                return getPrecentage(average) + "%";
                              }, "");
                              openDisplayTable(
                                {
                                  total_cost_before_employer_cost:
                                    thousandSeparator(
                                      Number(averageNoTax).toFixed(0)
                                    ),
                                  navg: totalPrecentage,
                                },
                                "",
                                false,
                                true
                              );
                            }}
                            fill="#00A2DE"
                          />
                        </h3>
                      </div>
                    </div>
                    <i style={{ marginLeft: "calc(35vw)", color: "#CC3566" }}>
                      סה"כ כולל ע. מעביד
                    </i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text='סה"כ ללא ע"מ'>
                    <p className="summeryText--2">
                      <span>&#8362;</span>
                      {getSafe(() => {
                        const average =
                          getHebDayByDate(selectedDateData.date) in
                          calendarData.sum
                            ? calendarData.sum[
                                getHebDayByDate(selectedDateData.date)
                              ].average
                            : 0;
                        const unCost =
                          isNaN(average / precentageEmployerCost) ||
                          !isFinite(average / precentageEmployerCost)
                            ? 0
                            : average / precentageEmployerCost;
                        return thousandSeparator(Number(unCost).toFixed(0));
                      }, "")}
                    </p>
                  </BtnTooltip>
                )}
                {isMobile ? (
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className="summeryText--2"
                      style={{ fontSize: "140%" }}
                    >
                      <h3>
                        {getSafe(() => {
                          const average =
                            getHebDayByDate(selectedDateData.date) in
                            calendarData.sum
                              ? calendarData.sum[
                                  getHebDayByDate(selectedDateData.date)
                                ].average
                              : 0;
                          return getPrecentage(average);
                        }, "")}
                        <span>%</span>
                      </h3>
                    </div>
                    <i style={{ color: "#CC3566" }}>ממוצע % ע. מעביד</i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text='סה"כ עם ע"מ'>
                    <p className="summeryText--2">
                      <span>&#8362;</span>
                      {getSafe(() => {
                        const average =
                          getHebDayByDate(selectedDateData.date) in
                          calendarData.sum
                            ? calendarData.sum[
                                getHebDayByDate(selectedDateData.date)
                              ].average
                            : 0;

                        return thousandSeparator(Number(average).toFixed(0));
                      }, "")}
                    </p>
                  </BtnTooltip>
                )}
                {!isMobile && (
                  <BtnTooltip placement="left" text="אחוז מהכנסה">
                    <p className="summeryText--2">
                      <span></span>
                      {getSafe(() => {
                        const average =
                          getHebDayByDate(selectedDateData.date) in
                          calendarData.sum
                            ? calendarData.sum[
                                getHebDayByDate(selectedDateData.date)
                              ].average
                            : 0;
                        return getPrecentage(average) + "%";
                      }, "0%")}
                    </p>
                  </BtnTooltip>
                )}
              </div>
            )}
          </>
        )}
      </SummeryBox>
      <SummeryBox selected={3 === selectedBox} onClick={() => {}}>
        {loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ) : (
          <>
            <h2>ממוצע יומי לתקופה</h2>
            {isEmpty(selectedDateData) ? (
              ""
            ) : (
              <div
                className="summeryText"
                style={
                  isMobile ? { fontSize: "93%", textAlign: "initial" } : {}
                }
              >
                {isMobile ? (
                  <div style={{ marginLeft: "calc(37vw)", marginTop: "10px" }}>
                    <div
                      className="summeryText--3"
                      style={{ fontSize: "140%" }}
                    >
                      <div className="flex-evenly-center">
                        <h3>
                          <span>&#8362;</span>
                          {getSafe(
                            () => thousandSeparator(Number(dayAvg).toFixed(0)),
                            ""
                          )}
                        </h3>
                        <h3>
                          <BsTagsFill
                            onClick={() => {
                              //console.log("asdasdasd");
                              const dayAvgNoTax = getSafe(() => {
                                const unCost =
                                  isNaN(dayAvg / precentageEmployerCost) ||
                                  !isFinite(dayAvg / precentageEmployerCost)
                                    ? 0
                                    : dayAvg / precentageEmployerCost;
                                return thousandSeparator(
                                  Number(unCost).toFixed(0)
                                );
                              }, "");
                              openDisplayTable(
                                {
                                  total_cost_before_employer_cost:
                                    thousandSeparator(
                                      Number(dayAvgNoTax).toFixed(0)
                                    ),
                                  navg: 0,
                                },
                                "",
                                false,
                                true
                              );
                            }}
                            fill="#00A2DE"
                          />
                        </h3>
                      </div>
                    </div>
                    <i style={{ color: "#f2994a" }}>סה״כ כולל ע. מעביד</i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text='סה"כ ללא ע"מ'>
                    <p className="summeryText--3">
                      <span>&#8362;</span>
                      {getSafe(() => {
                        const unCost =
                          isNaN(dayAvg / precentageEmployerCost) ||
                          !isFinite(dayAvg / precentageEmployerCost)
                            ? 0
                            : dayAvg / precentageEmployerCost;
                        return thousandSeparator(Number(unCost).toFixed(0));
                      }, "")}
                    </p>
                  </BtnTooltip>
                )}

                {isMobile ? (
                  ""
                ) : (
                  <BtnTooltip placement="left" text='סה"כ עם ע"מ'>
                    <p className="summeryText--3">
                      <span>&#8362;</span>
                      {getSafe(
                        () => thousandSeparator(Number(dayAvg).toFixed(0)),
                        ""
                      )}
                    </p>
                  </BtnTooltip>
                )}
                {isMobile ? (
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className="summeryText--3"
                      style={{ fontSize: "140%" }}
                    >
                      <h3>
                        {getSafe(() => getPrecentage(dayAvg), "")}
                        <span>%</span>
                      </h3>
                    </div>
                    <i style={{ color: "#f2994a" }}>% ע. מעביד</i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text="אחוז מהכנסה">
                    <p className="summeryText--3">
                      <span></span>
                      {getSafe(() => {
                        return getPrecentage(dayAvg) + "%";
                      }, "0%")}
                    </p>
                  </BtnTooltip>
                )}
              </div>
            )}{" "}
          </>
        )}
      </SummeryBox>
      <SummeryBox selected={4 === selectedBox} onClick={() => onClickBox(4)}>
        {loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ) : (
          <>
            <h2>מצטבר</h2>
            {isEmpty(selectedDateData) ? (
              ""
            ) : (
              <div
                className="summeryText"
                style={
                  isMobile ? { fontSize: "93%", textAlign: "initial" } : {}
                }
              >
                {isMobile ? (
                  <div style={{ marginLeft: "calc(37vw)", marginTop: "10px" }}>
                    <div
                      className="summeryText--4"
                      style={{ fontSize: "140%" }}
                    >
                      <div className="flex-evenly-center">
                        <h3>
                          <span>&#8362;</span>
                          {getSafe(
                            () =>
                              thousandSeparator(Number(totalExtra).toFixed(0)),
                            ""
                          )}
                        </h3>
                        <h3>
                          <BsTagsFill
                            onClick={() => {
                              //console.log("asdasdasd");

                              const totalNoTax = getSafe(
                                () =>
                                  thousandSeparator(Number(total).toFixed(0)),
                                ""
                              );
                              openDisplayTable(
                                {
                                  total_cost_before_employer_cost: totalNoTax,
                                  navg: getPrecentage(totalExtra) + "%",
                                },
                                "",
                                false,
                                true
                              );
                            }}
                            fill="#00A2DE"
                          />
                        </h3>
                      </div>
                    </div>
                    <i style={{ color: "#00A2DE" }}>סה״כ כולל ע. מעביד</i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text='סה"כ ללא ע"מ'>
                    <p className="summeryText--4">
                      <span>&#8362;</span>
                      {getSafe(
                        () => thousandSeparator(Number(total).toFixed(0)),
                        ""
                      )}
                    </p>
                  </BtnTooltip>
                )}
                {isMobile ? (
                  ""
                ) : (
                  <BtnTooltip placement="left" text='סה"כ עם ע"מ'>
                    <p className="summeryText--4">
                      <span>&#8362;</span>
                      {getSafe(
                        () => thousandSeparator(Number(totalExtra).toFixed(0)),
                        ""
                      )}
                    </p>
                  </BtnTooltip>
                )}
                {isMobile ? (
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className="summeryText--4"
                      style={{ fontSize: "140%" }}
                    >
                      <h3>
                        {getSafe(() => getPrecentage(totalExtra) + "%", "0%")}
                      </h3>
                    </div>
                    <i style={{ color: "#00A2DE" }}>% ע. מעביד</i>
                  </div>
                ) : (
                  <div style={{ display: "inline-flex" }}>
                    <BtnTooltip placement="left" text="אחוז מהכנסה">
                      <p className="summeryText--4">
                        <span></span>
                        {getSafe(() => {
                          return getPrecentage(totalExtra) + "%";
                        }, "0%")}
                      </p>
                    </BtnTooltip>
                    <BtnTooltip
                      placement="left"
                      fill="#00A2DE"
                      text={
                        showWarningAccu
                          ? "הינך חורג מהיעד " + accGoal
                          : "הינך עומד ביעד " + accGoal
                      }
                    >
                      <i
                        className="fas fa-info-circle"
                        style={{
                          fontSize: "1.4rem",
                          lineHeight: "2",
                          position: "relative",
                          color: "red",
                          left: "-4px",
                          color: showWarningAccu ? "#d62727" : "#47cc02",
                        }}
                      ></i>
                    </BtnTooltip>
                  </div>
                )}
              </div>
            )}{" "}
          </>
        )}
      </SummeryBox>
      <SummeryBox onClick={() => {}}>
        {loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ) : (
          <>
            <h2>צפי לסוף התקופה</h2>
            {isEmpty(selectedDateData) ? (
              ""
            ) : (
              <div
                className="summeryText"
                style={
                  isMobile ? { fontSize: "93%", textAlign: "initial" } : {}
                }
              >
                {isMobile ? (
                  <div style={{ marginLeft: "calc(37vw)", marginTop: "10px" }}>
                    <div
                      className="summeryText--5"
                      style={{ fontSize: "140%" }}
                    >
                      <div className="flex-evenly-center">
                        <h3>
                          <span>&#8362;</span>
                          {getSafe(() => {
                            const approx =
                              (dayAvg * workingDaysUntillEndOfMonth) /
                              precentageEmployerCost;
                            const unCost =
                              isNaN(approx) || !isFinite(approx) ? 0 : approx;
                            return thousandSeparator(Number(unCost).toFixed(0));
                          }, "")}
                        </h3>
                        <h3>
                          <BsTagsFill
                            onClick={() => {
                              openDisplayTable(
                                {
                                  total_cost_before_employer_cost:
                                    thousandSeparator(
                                      Number(selectedDateData.cost).toFixed(0)
                                    ),
                                  navg: 0,
                                },
                                "",
                                false,
                                true
                              );
                            }}
                            fill="#00A2DE"
                          />
                        </h3>
                      </div>
                    </div>
                    <i style={{ color: "#1d2671" }}>סה״כ כולל ע. מעביד</i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text='סה"כ ללא ע"מ'>
                    <p className="summeryText--1">
                      <span>&#8362;</span>
                      {getSafe(() => {
                        const approx =
                          (dayAvg * workingDaysUntillEndOfMonth) /
                          precentageEmployerCost;
                        const unCost =
                          isNaN(approx) || !isFinite(approx) ? 0 : approx;
                        return thousandSeparator(Number(unCost).toFixed(0));
                      }, "")}
                    </p>
                  </BtnTooltip>
                )}
                {isMobile ? (
                  ""
                ) : (
                  <BtnTooltip placement="left" text='סה"כ עם ע"מ'>
                    <p className="summeryText--1">
                      <span>&#8362;</span>
                      {getSafe(
                        () =>
                          thousandSeparator(
                            Number(
                              dayAvg * workingDaysUntillEndOfMonth
                            ).toFixed(0)
                          ),
                        ""
                      )}
                    </p>
                  </BtnTooltip>
                )}
                {isMobile ? (
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className="summeryText--5"
                      style={{ fontSize: "140%" }}
                    >
                      <h3>
                        {getSafe(
                          () =>
                            culcPrecentage(
                              totalIncome,
                              dayAvg * workingDaysUntillEndOfMonth
                            ).toFixed(0),

                          "0"
                        )}
                        <span>%</span>
                      </h3>
                    </div>
                    <i style={{ color: "#1d2671" }}>% ע. מעביד</i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text="אחוז מהכנסה">
                    <p className="summeryText--1">
                      <span></span>
                      {getSafe(
                        () => {
                          const untilWorkCost = getPrecentage(
                            dayAvg * workingDaysUntillEndOfMonth
                          );
                          //console.log(untilWorkCost);
                          return Number(untilWorkCost).toFixed(1) + "%";
                        }, //culcPrecentage(totalIncome,dayAvg * workingDaysUntillEndOfMonth).toFixed(0)+"%",
                        "0%"
                      )}
                    </p>
                  </BtnTooltip>
                )}
              </div>
            )}{" "}
          </>
        )}
      </SummeryBox>
      <SummeryBox selected={5 === selectedBox} onClick={() => onClickBox(4)}>
        {loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ) : (
          <>
            <h2>מצטבר שנה שעברה</h2>
            {isEmpty(selectedDateData) ? (
              ""
            ) : (
              <div
                className="summeryText"
                style={
                  isMobile ? { fontSize: "93%", textAlign: "initial" } : {}
                }
              >
                {isMobile ? (
                  <div style={{ marginLeft: "calc(37vw)", marginTop: "10px" }}>
                    <div
                      className="summeryText--6"
                      style={{ fontSize: "140%" }}
                    >
                      <div className="flex-evenly-center">
                        <h3>
                          <span>&#8362;</span>
                          {getSafe(
                            () =>
                              thousandSeparator(
                                Number(lastYearIncome.total).toFixed(0)
                              ),
                            ""
                          )}
                        </h3>
                        <h3>
                          <BsTagsFill
                            onClick={() => {
                              //console.log("asdasdasd");
                              openDisplayTable(
                                {
                                  total_cost_before_employer_cost:
                                    thousandSeparator(
                                      Number(selectedDateData.cost).toFixed(0)
                                    ),
                                  navg: 0,
                                },
                                "",
                                false,
                                true
                              );
                            }}
                            fill="#00A2DE"
                          />
                        </h3>
                      </div>
                    </div>
                    <i style={{ color: "#1d2671" }}>סה״כ כולל ע. מעביד</i>
                  </div>
                ) : (
                  <BtnTooltip placement="left" text='סה"כ ללא ע"מ'>
                    <p className="summeryText--5">
                      <span>&#8362;</span>
                      {getSafe(() => {
                        const unCost =
                          isNaN(
                            lastYearIncome.total / precentageEmployerCost
                          ) ||
                          !isFinite(
                            lastYearIncome.total / precentageEmployerCost
                          )
                            ? 0
                            : lastYearIncome.total / precentageEmployerCost;
                        return thousandSeparator(Number(unCost).toFixed(0));
                      }, "")}
                    </p>
                  </BtnTooltip>
                )}
                {isMobile ? (
                  ""
                ) : (
                  <BtnTooltip placement="left" text='סה"כ עם ע"מ'>
                    <p className="summeryText--5">
                      <span>&#8362;</span>
                      {getSafe(
                        () =>
                          thousandSeparator(
                            Number(lastYearIncome.total).toFixed(0)
                          ),
                        ""
                      )}
                    </p>
                  </BtnTooltip>
                )}

                {!isMobile ? (
                  <BtnTooltip placement="left" text="אחוז מהכנסה">
                    <p className="summeryText--5">
                      <span></span>
                      {getSafe(() => {
                        return getPrecentage(lastYearIncome.total) + "%";
                      }, "0%")}
                    </p>
                  </BtnTooltip>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className="summeryText--5"
                      style={{ fontSize: "140%" }}
                    >
                      <h3>
                        {getSafe(() => getPrecentage(lastYearIncome.total), "")}
                        <span>%</span>
                      </h3>
                    </div>
                    <i style={{ color: "#1d2671" }}>% ע. מעביד</i>
                  </div>
                )}
              </div>
            )}{" "}
          </>
        )}
      </SummeryBox>
    </div>
  );
};
