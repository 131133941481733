import React from "react";
import PropTypes from "prop-types";
import BtnTooltip from "../tooltips/BtnTooltip";
import { CircularProgress } from "@mui/material";
import { RESTIGO_BACKGROUND_BLUE_COLOR } from "../../pages/goods/const";

const ButtonIcon = ({
  style,
  onClick,
  icon,
  type = "button",
  iconSize = "1.2em",
  className = "",
  alt = "",
  altPlacement = "top",
  disabled = false,
  active = false,
  loading = false,
  notifier,
}) => {
  return (
    <>
      <BtnTooltip hide={alt === ""} placement={altPlacement} text={alt}>
        <button
          disabled={disabled || loading}
          type={type}
          style={style}
          className={`btn btn--icon ${className} ${
            active ? "btn--active" : ""
          }`}
          onClick={onClick}
        >
          {loading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <>
              {icon({ size: iconSize })}
              {!!notifier && (
                <span
                  style={{
                    borderRadius: "50%",
                    backgroundColor: RESTIGO_BACKGROUND_BLUE_COLOR,
                    position: "absolute",
                    width: "20px",
                    color: "white",
                    top: "-5px",
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {notifier}
                </span>
              )}
            </>
          )}
        </button>
      </BtnTooltip>
    </>
  );
};

ButtonIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.func.isRequired,
};

export default ButtonIcon;
