import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import Button from "../../../../../components/buttons/Button";
import { setDialogModal } from "../../../../../redux/actions/DialogAction";
import { isUndefined } from "lodash";
import { FaGift, FaTrash } from "react-icons/fa";
import {
  addBonusToEmployee,
  editBonusToEmployee,
  deleteBonusToEmployee,
} from "../../../../../redux/actions/scheduleAction";
import { getSafe } from "../../../../../helpers";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import ButtonIcon from "../../../../../components/buttons/ButtonIcon";

import { MdSave } from "react-icons/md";
import { HiX } from "react-icons/hi";

const TransferEmployee = ({
  event,
  onAbort,
  setDialogModal,
  addBonusToEmployee,
  editBonusToEmployee,
  deleteBonusToEmployee,
}) => {
  const { employee } = event;

  const [error, setError] = useState(null);
  const [bonusAmount, setBonusAmount] = useState(
    getSafe(() => event.bonus.amount) ? event.bonus.amount : undefined
  );
  const bonusTypes = [
    { id: 0, label: "בונוס" },
    { id: 1, label: "מפרעה" },
  ];
  const [bonusType, setBonusType] = useState(
    getSafe(() => event.bonus.bonus_type) ? event.bonus.bonus_type : 0
  );
  const [note, setNote] = useState(
    getSafe(() => event.bonus.note) ? event.bonus.note : undefined
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const renderError = useMemo(
    () => () => {
      if (error) {
        return (
          <p style={{ width: "100%", textAlign: "center" }}>
            <span className="error-msg">{error}</span>{" "}
          </p>
        );
      }
    },
    [error]
  );

  const handleConfirm = () => {
    if (isUndefined(bonusAmount)) return setError("הוסף סכום");
    onAbort();
    setDialogModal({
      title: bonusType == 1 ? "הוספת מפרעה" : "הוספת בונוס",
      text: `האם להוסיף ${bonusAmount} ש"ח ${
        bonusType == 1 ? "מפרעה" : "בונוס"
      } ל ${employee.name} ?`,
      onConfirm: () => {
        if (event.bonus) {
          editBonusToEmployee({
            bonus_id: event.bonus.id,
            amount: bonusAmount,
            note,
            bonusType,
          });
        } else {
          addBonusToEmployee({
            event_id: event.id,
            employee_id: event.employee.id,
            amount: bonusAmount,
            note,
            bonusType,
          });
        }
      },
      iconJSX: () => FaGift({ size: "7em" }),
    });
  };

  const handleDelete = (bonus_id) => {
    onAbort();
    setDialogModal({
      title: bonusType === 1 ? "הסרת מפרעה" : "הסרת בונוס",
      text: `האם לבטל את ${bonusType === 1 ? "מפרעה" : "בונוס"} של ${
        employee.name
      } לתא זה?`,
      onConfirm: () => deleteBonusToEmployee(bonus_id),
      iconJSX: () => FaGift({ size: "7em" }),
      btnConfirmClass: "color__danger--background",
    });
  };

  return (
    <div className={`flex-column-center bonus-modal`}>
      <h2>{bonusType == 1 ? "הוספת מפרעה" : "הוספת בונוס"}</h2>

      <FormControl>
        <RadioGroup
          row
          style={{ justifyContent: "center" }}
          value={bonusType}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => {
            console.log(e.target.value);
            setBonusType(e.target.value);
          }}
        >
          {bonusTypes.map((type) => {
            return (
              <FormControlLabel
                value={type.id}
                control={<Radio />}
                label={type.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <TextField
        required
        name="bonus_or_on_account"
        value={bonusAmount}
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <strong>₪</strong>
            </InputAdornment>
          ),
          inputProps: {
            className: "only-numbers",
          },
        }}
        onChange={(e) => {
          e.preventDefault();
          if (error) {
            setError(null);
          }
          setBonusAmount(e.target.value);
        }}
        label="הזן סכום"
        style={{
          width: "70%",
        }}
        size="small"
      />
      {renderError()}
      <label style={{ marginBottom: "0.5rem", width: "70%" }}>
        הערות
        <textarea
          onChange={(e) => {
            e.preventDefault();
            setNote(e.target.value);
          }}
          value={note}
          maxLength={100}
          style={{ display: "block", width: "100%" }}
        />
      </label>
      <div
        style={{ width: "100%", marginTop: "10px" }}
        className="flex"
        justifyContent="space-evenly"
      >
        <div>
          <ButtonIcon
            className="btn--neutral"
            onClick={onAbort}
            icon={HiX}
            iconSize="1.8rem"
            style={{ width: "30px", height: "30px" }}
          ></ButtonIcon>
        </div>
        <div>
          {event.bonus ? (
            <ButtonIcon
              style={{ width: "30px", height: "30px" }}
              icon={FaTrash}
              className="btn--abort"
              textValue="הסר בונוס"
              onClick={() => handleDelete(event.bonus.id)}
              color="#db1e1e"
              iconSize="1.8rem"
            ></ButtonIcon>
          ) : (
            <></>
          )}
        </div>
        <div>
          <ButtonIcon
            className="btn--submit"
            textValue="אישור"
            onClick={handleConfirm}
            icon={MdSave}
            color="#00a2ea"
            iconSize="1.8rem"
            style={{ width: "30px", height: "30px" }}
          ></ButtonIcon>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  setDialogModal,
  addBonusToEmployee,
  editBonusToEmployee,
  deleteBonusToEmployee,
})(TransferEmployee);
