import React from "react";
import Select from "react-select";

const MultiSelect = ({
  options,
  selected,
  setSelected,
  filterBySelected = null,
  placeholder,
  ref,
}) => {
  const handleChange = (selectedOption) => {
    setSelected(selectedOption ? selectedOption : []);
    if (filterBySelected)
      filterBySelected(selectedOption ? selectedOption : []);
  };

  return options.length ? (
    <Select
      isMulti={true}
      value={selected}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      isSearchable
      noOptionsMessage={() => <>אפשרות לא קיימת</>}
      isClearable={false}
      closeMenuOnSelect={false}
      def
    />
  ) : (
    <></>
  );
};

export default MultiSelect;
