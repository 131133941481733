// confirmBarReducer.js
import { SET_CONFIRM_BAR, HIDE_CONFIRM_BAR } from "../actions/confirmAction/types";

const INITIAL_STATE = {
  confirmMsg: null,
  isOpen: false,
};

export default (state = INITIAL_STATE, action) => {
  const { confirmMsg } = action;
  switch (action.type) {
    case SET_CONFIRM_BAR:
      return {
        confirmMsg,
        isOpen: true,
      };
    case HIDE_CONFIRM_BAR:
      return INITIAL_STATE;
    default:
      return state;
  }
};
