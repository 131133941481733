// branchReducer.js

import { isEmpty, mapKeys } from "lodash";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
import { FETCH_BRANCHES, SET_BRANCH } from "../actions/branchesAction/types";
import { LOGOUT } from "../actions/loginAction/types";

const INITIAL_STATE = {
  branches: [],
  selected_branch: {},
};

const changeBranch = (selected_branch, payload) => {
  if (!isEmpty(selected_branch)) {
    const branches = mapKeys(payload, "id");
    if (branches[selected_branch.id]) {
      return branches[selected_branch.id];
    }
  }
  return payload[0];
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BRANCH:
      console.log("setting branch");

      return {
        ...state,
        selected_branch: action.payload,
      };
    case FETCH_BRANCHES:
      return {
        ...state,
        branches: action.payload,
        selected_branch: changeBranch(state.selected_branch, action.payload),
      };
    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
