import React, { useState } from "react";
import EllipsisButton from "../buttons/EllipsisButton";
import Model from "../Model";
import Popover from "react-popover";

const PopoverEllipsisButton = ({
  children,
  ellipsisButtonBackgroundColor = "#f7f7f7",
  style = null
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      place="right"
      isOpen={open}
      onOuterAction={() => setOpen(false)}
      body={<Model className="flex-column-right" {...{style}}>{children}</Model>}
      style={{ zIndex: 4 }}
    >
      <EllipsisButton
        className="btn--icon"
        onClick={() => setOpen(!open)}
        backgroundColor={ellipsisButtonBackgroundColor}
        style={{ cursor: 'pointer'}}
      >
        <i className="fa fa-ellipsis-h"></i>
      </EllipsisButton>
    </Popover>
  );
};
export default PopoverEllipsisButton;
