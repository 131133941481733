import React from "react";
//  import { Pie } from 'react-chartjs-2';
import { ResponsivePie } from "@nivo/pie";
import { animated } from "@react-spring/web";

import { thousandSeparator } from "../../../helpers";

const CenteredMetric = ({ centerX, centerY }, isPrice, total) => {
  return isPrice ? (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "30px",
        fontWeight: 600,
      }}
    >
      {`₪${thousandSeparator(total.toFixed(1))}`}
    </text>
  ) : (
    ""
  );
};

export const Pie = ({ data = [], title, isPrice = false, total }) => {
  return data.length ? (
    <div
      className="u-center-text"
      style={{ height: "calc(33vw - 100px)", width: "100%" }}
    >
      <h2>{title}</h2>

      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: -20 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: "nivo" }}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        arcLinkLabelsTextOffset={60}
        arcLinkLabelsDiagonalLength={40}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        arcLinkLabel="label"
        arcLinkLabelsSkipAngle={7}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        // arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={15}
        arcLinkLabelsColor="transparent"
        arcLabelsComponent={({ datum, label, style }) => (
          <animated.g
            transform={style.transform}
            style={{ pointerEvents: "none" }}
          >
            <text
              textAnchor="middle"
              dominantBaseline="central"
              fill={style.textColor}
              style={{
                fontSize: 13,
                fontWeight: 800,
                width: "100px",
              }}
            >
              {label}
            </text>
          </animated.g>
        )}
        // onClick={(node, event) => {
        //   node.data.onClick(node.data);
        // }}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          (props) => CenteredMetric(props, isPrice, total),
        ]}
        tooltip={function (e) {
          var t = e.datum;
          return (
            <div className="pie-tooltip">
              <p>{t.label}</p>
              {isPrice ? (
                <p>{`${Number(t.data.percent).toFixed(0)}%`}</p>
              ) : (
                <></>
              )}
              <p>
                {" "}
                {isPrice
                  ? `₪${thousandSeparator(t.value)}`
                  : thousandSeparator(t.value)}
              </p>
              <div
                style={{
                  margin: "auto",
                  height: "2rem",
                  width: "2rem",
                  backgroundColor: t.color,
                  border: "1px solid black",
                }}
              ></div>
            </div>
          );
        }}
        arcLabel={(e) =>
          isPrice
            ? `₪${thousandSeparator(e.value)}, ${Number(e.data.percent).toFixed(
                0
              )}%`
            : thousandSeparator(e.value)
        }
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 3]] }}
        // CenteredMetric={(props) => {
        //   console.log(props);
        //   CenteredMetric(props, isPrice);
        // }}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: true,
            translateX: 60,
            translateY: 5,
            itemsSpacing: 6,
            itemWidth: 30,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "right-to-left",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </div>
  ) : (
    ""
  );
};
