import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { BASE_URL } from "../../pages/DataAnalayze/consts";
import { format } from "date-fns";

const currentDate = new Date();
const INITIAL_STATE = {
  filters: {
    selectedDates: {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 1
      ),
      key: "selection",
      label: "החודש",
    },
    branch_id: null,
    branches: [],
  },
  isLoading: false,
  bullets_data: {},
  summary_table: [],
};
const dataAnalyzeSlice = createSlice({
  name: "dataAnalyze",
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataAnalyze.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDataAnalyze.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bullets_data = action.payload.total_summary;
      state.summary_table = action.payload.summary_per_branch;
    });
    builder.addCase(getDataAnalyze.rejected, (state, action) => {
      state.isLoading = false;
      state.bullets_data = [];
      state.summary_table = [];
    });
  },
});

export const getDataAnalyze = createAsyncThunk(
  "dataAnalyze/getDataAnalyze",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filters = getState().dataAnalyze.filters;

    const res = await Axios.get(`${BASE_URL}`, {
      params: {
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
        branches: filters.branches,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);
export const { reducer: dataAnalyzeReducer, actions: dataAnalyzeActions } =
  dataAnalyzeSlice;
