
import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { FaUserTag} from "react-icons/fa";
//import Button from "../../../components/buttons/Button";
import ReactTable from "../../../components/tables/ReactTable";
import {
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";
import {
  ClickAwayListener,
  Tooltip,
  MenuItem,
  Select ,
  Typography,
} from "@mui/material";
import {  BsTagsFill} from "react-icons/bs";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import Axios from "axios";
const DisplayTable = ({ open, data, onClose, setViewToShow, message=true,title,columns}) => {
  const [modalVisible, setModalVisible] = useState(true);
  if(!message)
  return (
    <Modal
      animationDuration={200}
      styles={{
        modal: {
          padding: "3.5rem",
          maxWidth: "fit-content",
         
        },
      }}
      
      onRequestClose={() => setModalVisible(false)}
      center={true}
      open={modalVisible}
      onClose={() => onClose()}
    >
      <div className="flex-column-center">
        <FaUserTag fill='#00A2DE'> </FaUserTag>
        <div className="fluid">
          <h2>{title}</h2>
        </div>

        <ReactTable
          {...{
            // cellsPropsById: (id) => {
            //   if (["id"].includes(id)) {
            //     return {
            //       setViewToShow,
            //     };
            //   }
            // },
            styles: {
              wrapper: { maxHeight: "calc(100vh - 10rem)" },
              container: { maxHeight: "calc(100vh - 23.4rem)" },
            },
            translation_endpoint: "displayTable",
            title,
            columns,
            data:data,
            isExportedToExcel: true,
          }}
        />
      </div>
    </Modal>
  );
  else 
   return (
    <Modal
          animationDuration={200}
          styles={{
            modal: {
              padding: "3.5rem",
              maxWidth: "fit-content",
             
            },
          }}
          onRequestClose={() => setModalVisible(false)}
          center={true}
          open={modalVisible}
          onClose={() => onClose()}
        >
          <div className="flex-column-center">
        <div className="fluid">
          <BsTagsFill fill='#00A2DE'></BsTagsFill>
        </div>
        <div>
              <p>
                <label>סה״כ ללא עלות מעביד</label>
                <span> {data?.total_cost_before_employer_cost}</span>
              </p>
              <p>
                <label>עלות מעביד</label>
                <span> {data?.navg}</span>
              </p>
            </div>

        </div>
            
          
      </Modal>
   );
};

export default DisplayTable;
