import React, { useMemo, useRef, useState } from "react";
import PermissionsFilter from "./PermissionsFilter";
import Axios from "axios";
import { connect } from "react-redux";
import PaginateTable from "../../../components/tables/PaginateTable";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import { FaPencilAlt } from "react-icons/fa";
import PermissionsForm from "./PermissionsForm";
import { isEmpty } from "lodash";
import { logoutAdmin } from "../../../redux/actions/AdminLoginAction";
import BtnTooltip from "../../../components/tooltips/BtnTooltip";
import { useScreenSize } from "../../../Hooks";

const PermissionsView = ({ token, logoutAdmin }) => {
  const { isMobile } = useScreenSize();
  const BRANCH_INIT_STATE = {
    branch_id: null,
    network_id: null,
    branch_name: null,
    network_name: null,
  };
  const [table, setTable] = useState({ data: [], current_page:1,last_page:1,per_page: 15,total: 0, });
  const [formValues, setFormValues] = useState(null);
  const [branch, setBranch] = useState(BRANCH_INIT_STATE);
  const [loading, setLoading] = useState(false);

  const formViewRef = useRef(null);

  const executeScroll = (ref) =>
    ref.current.scrollIntoView({ behavior: "smooth", inline: "center" });

    const handleChangePage = (event, newPage) => {
      setTable({ ...table, current_page: newPage });
    };
  const fetchData = useMemo(
    () =>
      async ({ pageIndex }) => {
        try {
          if (!formValues || isEmpty(formValues)) return;
          setLoading(true);
          const response = await Axios.post(
            "admin/branches",
            { ...formValues, page: pageIndex },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          const { data, current_page, last_page,per_page,total} = response.data;
          setBranch(BRANCH_INIT_STATE);
          setTable({
            data,
            current_page,
            total,
            per_page,
            last_page
          });
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          if (e.response)
            if (e.response.status === 401) {
              logoutAdmin();
            }
        }
      },
    [formValues]
  );

  const onSubmit = (formValues) => {
    setTable({ data: [], current_page:1,last_page:1,per_page: 15,total: 0, });
    setFormValues(formValues);
  };

  return (
    <div className="permissions">
      <h1> ניהול הרשאות</h1>
      <PermissionsFilter isMobile={isMobile} onSubmit={onSubmit} />
      <PaginateTable
        columns={[
          { Header: "מזהה סניף", accessor: "id" },
          {
            Header: "שם הסניף",
            accessor: "name",
          },
          {
            Header: "שם הרשת",
            accessor: "network_name",
            Cell: ({ row }) => {
              const { network_name, network } = row.original;
              return (
                <div className="flex-start" style={{ width: "100%" }}>
                  <BtnTooltip text={network.info || ""}>
                    <i
                      style={{ fontSize: "1.4rem" }}
                      className="fas fa-info-circle"
                    ></i>
                  </BtnTooltip>
                  <text style={{ margin: "0 .5rem" }}>{network_name}</text>
                </div>
              );
            },
          },
          {
            Header: "חבילה",
            accessor: "package",
            Cell: ({ value }) => {
              return (
                <div className="flex-start" style={{ width: "100%" }}>
                  <text style={{ margin: "0 .5rem" }}>
                    {value ? (value.name ? value.name : "") : ""}
                  </text>
                </div>
              );
            },
          },
          {
            Header: "תאריך עדכון",
            accessor: "last_permission_update",
            Cell: ({ value }) => {
              return (
                <div className="flex-start" style={{ width: "100%" }}>
                  <text style={{ margin: "0 .5rem" }}>{value}</text>
                </div>
              );
            },
          },
          {
            Header: "גורם מעדכן",
            accessor: "admin",
            Cell: ({ value }) => {
              return (
                <div className="flex-start" style={{ width: "100%" }}>
                  <text style={{ margin: "0 .5rem" }}>
                    {value ? `${value.first_name} ${value.last_name}` : ""}
                  </text>
                </div>
              );
            },
          },
          {
            Header: "",
            width: 1,
            accessor: "buttons",
            Cell: (props) => {
              const { id, network_id, network_name, name } = props.row.original;
              return (
                <div style={{ width: "90px" }}>
                  <div className="flex-end">
                    <ButtonIcon
                      alt="ערוך הרשאות"
                      onClick={() => {
                        setBranch({
                          branch_id: id,
                          branch_name: name,
                          network_id,
                          network_name,
                        });
                        executeScroll(formViewRef);
                      }}
                      icon={FaPencilAlt}
                    />
                  </div>
                </div>
              );
            },
          },
        ]}
        data={table.data}
        fetchData={fetchData}
        pagination={table}
        setPagination={handleChangePage}
        loading={loading}
      />
      <div ref={formViewRef} style={{ minHeight: "100vh" }}>
        {branch.branch_id ? (
          <PermissionsForm isMobile={isMobile} {...branch} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isMobile, isTablet } = state.publicValues;
  const { token } = state.authAdmin;

  return { isMobile: isMobile || isTablet, token };
};

export default connect(mapStateToProps, { logoutAdmin })(PermissionsView);
