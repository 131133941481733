export const REQUIRED_MESSAGE = "שדה זה הינו שדה חובה";

const TYPE_EXTRA_ACTIONS_POSITION_TOP = "top";
const TYPE_EXTRA_ACTIONS_POSITION_BOTTOM = "bottom";
const TYPE_EXTRA_ACTIONS_POSITION_END = "end";
export {
  TYPE_EXTRA_ACTIONS_POSITION_BOTTOM,
  TYPE_EXTRA_ACTIONS_POSITION_END,
  TYPE_EXTRA_ACTIONS_POSITION_TOP,
};
