import {
  CountFooter,
  DateCell,
  PercentageCell,
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";

import {
  dynamicToFixed,
  fixDate,
  fixPercentage,
  fixPrice,
  getSafeDivision,
  thousandSeparator,
} from "../../../helpers";
import { GREEN_COLOR, RED_COLOR } from "../../products/const";
import { DEFAULT_MAX_FC, FcHeader } from "../const";

export const getData = (
  soldDishes, //raw data
  branches //redux state
) => {
  return soldDishes.map((dish) => {
    const { date, amount, total, worth, branch_id } = dish;
    const branch = branches.find((branch) => branch.id === branch_id);

    const avg_price = getSafeDivision(total, amount);
    const avg_foodcost = getSafeDivision(worth, total) * 100;

    return {
      date,
      branch_name: branch.name,
      amount,
      total_with_vat: total,
      avg_price,
      worth,
      avg_foodcost,
    };
  });
};

export const excelRowConfig = (row) => {
  row.date = fixDate(row.date);
  row.amount = dynamicToFixed(row.amount);
  row.total_with_vat = dynamicToFixed(row.total_with_vat);
  row.worth = dynamicToFixed(row.worth);
  row.avg_price = dynamicToFixed(row.avg_price);
  row.avg_foodcost = fixPercentage(row.avg_foodcost, 2);

  return row;
};

export const aggregates = [
  { field: "amount", aggregate: "sum" },
  { field: "total_with_vat", aggregate: "sum" },
  { field: "worth", aggregate: "sum" },
  { field: "avg_price", aggregate: "sum" },
];

export const sortBy = [{ id: "avg_foodcost", desc: true }];

export const columns = [
  {
    Header: "תאריך",
    accessor: "date",
    Cell: DateCell,
    Footer: () => "סה״כ",
  },
  {
    Header: "שם סניף",
    accessor: "branch_name",
  },
  {
    Header: "כמות שנמכרה",
    accessor: "amount",
    Cell: ({ value }) => (
      <span className="is-number">{thousandSeparator(value)}</span>
    ),
    Footer: CountFooter,
  },
  {
    Header: "מכירות כולל מע״מ",
    accessor: "total_with_vat",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "עלות רכיבים",
    accessor: "worth",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "מחיר מנה",
    accessor: "avg_price",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: FcHeader,
    accessor: "avg_foodcost",
    Cell: (props) => (
      <span
        style={{
          color: props.value <= DEFAULT_MAX_FC ? GREEN_COLOR : RED_COLOR,
        }}
      >
        {PercentageCell(props)}
      </span>
    ),
  },
];
