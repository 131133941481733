import { useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import SimpleReactTable from "../../../components/tables/SimpleReactTable";
import { fixPrice } from "../../../helpers";
import { useScreenSize } from "../../../Hooks";
import { Context } from "../Show";
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { fetchEmployeesBySendingId } from "../../../redux/actions/scheduleAction/index";
const Show = () => {
  const dispatch = useDispatch();
  const { parts, user, selectedBranch, procedure } = useContext(Context);
  const { isMobile } = useScreenSize();
  const { control, setValue } = useFormContext();
  const { replace } = useFieldArray({ control, name: "parts" });
  const branchEmployees = useSelector((state) => state.employees);
  const identifier = useWatch({ control, name: "identifier" });
  const label = useWatch({ control, name: "label" });
  const employee_id = useWatch({ control, name: "employee_id" });
  const [procedureBranches, setProcedureBranches] = useState([]);
  const { procedures } = useSelector((state) => state.manageProcedures);
  const [branchSelected, setBranchSelected] = useState(null);
  const currProceudre = procedures.find((item) => item.id === procedure.id);

  useEffect(() => {
    replace(parts?.map(({ id }) => ({ id })) ?? []);
    const updatedBranches = currProceudre.branches.map((branch) => ({
      id: branch.id,
      label: branch.name, // Change "name" to "label"
    }));
    setProcedureBranches(updatedBranches);
  }, [parts]);

  const handleEmployeeChange = (e) => {
    setValue("identifier", e.identifier); // Set the identifier field with the selected employee's ID
    setValue("label", e.label);
    setValue("employee_id", e.id);
  };
  const handleIdChange = (value) => {};

  const handleBranchChange = (e, id, index) => {
    setBranchSelected(e.label);
    dispatch(fetchEmployeesBySendingId(e.id));
  };
  const memoEmployess = useMemo(() => {
    return Object.entries(branchEmployees).map(([key, value]) => {
      return {
        id: key,
        label: value.full_name + ", " + value.department_name,
        name: value.full_name,
        identifier: value.employee_ID_Number,
        department: { id: value.department_id, label: value.department_name },
        salary: value.employee_base_sal,
      };
    });
  }, [branchEmployees]);

  const data = useMemo(() => parts ?? [], [parts]);

  const columns = useMemo(
    () => [
      {
        Header: "שם פריט",
        accessor: "label",
      },
      {
        Header: "עלות",
        accessor: "value",
        Cell: ({ value }) => fixPrice(value),
      },
      {
        Header: "כמות",
        Cell: ({ row: { index } }) => {
          return (
            <FormTextField
              name={`parts.${index}.value`}
              {...{ control }}
              label="כמות פריטים"
              rules={{ required: REQUIRED_MESSAGE }}
              type="number"
            />
          );
        },
      },
    ],
    []
  );
  return (
    <div
      style={{
        margin: 50,
        display: "flex",
        flexDirection: "column",
        gap: "3rem",
        fontSize: isMobile ? "1.8rem" : "2rem",
      }}
    >
      <Grid item xs={6}>
        <Typography>שם סניף</Typography>
        <ControlAutoComplete
          {...{
            fullWidth: true,
            name: `branch`,
            rules: { required: REQUIRED_MESSAGE },
            options: procedureBranches,
            handleInputChange: (value) => {
              handleBranchChange(value);
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>שם העובד</Typography>
        <ControlAutoComplete
          {...{
            fullWidth: true,
            name: `label`,
            rules: { required: REQUIRED_MESSAGE },
            options: memoEmployess,
            handleInputChange: (value) => handleEmployeeChange(value),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>תעודת זהות</Typography>
        <FormTextField
          {...{
            fullWidth: true,
            name: `identifier`,
            rules: { required: REQUIRED_MESSAGE },
            handleChange: (value) => handleIdChange(value),
          }}
        />
      </Grid>
      <div
        style={{
          margin: 50,
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          fontSize: isMobile ? "1.8rem" : "2rem",
        }}
      >
        <div>
          <h2
            style={{
              textAlign: "center",
              fontSize: isMobile ? "2rem" : "",
            }}
          >
            טופס השאלת ביגוד וניכוי בגין אובדן
          </h2>
        </div>
        <div>
          <p>פרטי ביגוד :</p>
          <SimpleReactTable
            {...{ columns, data }}
            style={{ maxWidth: !isMobile ? "40%" : "" }}
          />
        </div>
        <div>
          <p>
            לטובת ביצוע תפקידך בחברת {branchSelected ?? selectedBranch.name}{" "}
            להלן "החברה" הידועה גם כ : {branchSelected ?? selectedBranch.name}{" "}
            מרשת {selectedBranch.network.name}
            . <br />
            מושאל לך ביגוד כמפורט מטה ביגוד זה הינו רכוש החברה ומושאל לך לצורך
            ביצוע עבודתך בחברה בלבד. <br />
            שמור על תקינות הביגוד ודווח למנהלך במידה והביגוד אינו תקין.
          </p>
        </div>
        <div>
          לכבוד {branchSelected ?? selectedBranch.name} <br />
          אני הח"מ {label ? label.split(",")[0] : ""}, מס' ת.ז. {identifier}{" "}
          מתחייב לשמור על הביגוד, לא לחבל בו ולא לעשות בו שימוש שלא לצורך
          עבודתי. <br />
          ידוע לי כי במקרה של אובדן הביגוד ו/או נזק שיגרם לו מכל סיבה שהיא,
          רשאית החברה לחייב אותי בעלות הביגוד <br />
          ואני מאשר/ת בזאת לחברה במקרה זה לנכות משכר עבודתי או מכל סכום אחר
          המגיע לי מאת החברה את עלות הנזק ו/או האובדן, בהתאם לטבלת החיובים ונוהל
          חיוב על ביגוד, <br />
          וזאת בהתאם להוראות סעיף 25 (א)(6) לחוק הגנת השכר, התשי"ח - 1958.{" "}
          <br />
          תאריך : {new Date().toLocaleDateString()} <br />
          שם העובד: {label ? label.split(",")[0] : ""} <br />
          ת.ז : {identifier} <br />
        </div>
        <div>הוזן על ידי : {user.name}</div>
      </div>
    </div>
  );
};

export default Show;
