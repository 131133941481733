import { useContext, useEffect, useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { ControlSignaturePad } from "../../../components/Forms/inputs/SignaturPad";
import SimpleReactTable from "../../../components/tables/SimpleReactTable";
import { fixPrice } from "../../../helpers";
import { useScreenSize } from "../../../Hooks";
import { Context } from "../Show";
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";

const ClothingFields = () => {
  const { parts, user, selectedBranch } = useContext(Context);
  const { isMobile } = useScreenSize();
  const { control, setValue } = useFormContext();
  const { replace } = useFieldArray({ control, name: "parts" });

  useEffect(() => {
    replace(parts?.map(({ id }) => ({ id })) ?? []);
  }, [parts]);

  const identifier = useWatch({ control, name: "identifier" });
  const data = useMemo(() => parts ?? [], [parts]);
  const setSizeChange = (e, index) => {
    console.log("e", e);
    setValue(`parts.${index}.extraValue`, e.label);
  };
  const sizeOptions = [
    { value: 1, label: "S" },
    { value: 2, label: "M" },
    { value: 3, label: "L" },
    { value: 4, label: "XL" },
    { value: 5, label: "XL" },
    { value: 6, label: "XXL" },
    { value: 7, label: "XXXL" },
  ];
  console.log("data", data);
  const columns = useMemo(
    () => [
      {
        Header: "שם פריט",
        accessor: "label",
      },
      {
        Header: "עלות",
        accessor: "value",
        Cell: ({ value }) => fixPrice(value),
      },
      {
        Header: "כמות",
        id: "qty",
        Cell: ({ row: { index } }) => {
          return (
            <FormTextField
              name={`parts.${index}.value`}
              {...{ control }}
              label="כמות פריטים"
              rules={{ required: REQUIRED_MESSAGE }}
              type="number"
            />
          );
        },
      },
      {
        Header: "מידה",
        id: "size",
        Cell: ({ row }) => {
          console.log(row.original);
          return (
            <>
              <Typography>מידת הבגד</Typography>
              <ControlAutoComplete
                {...{
                  fullWidth: true,
                  name: `parts.${row.original.index}.extraValue`,
                  rules: { required: REQUIRED_MESSAGE },
                  options: sizeOptions,
                  handleInputChange: (value) =>
                    setSizeChange(value, row.original.index),
                }}
              />
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <div
      style={{
        margin: 50,
        display: "flex",
        flexDirection: "column",
        gap: "3rem",
        fontSize: isMobile ? "1.8rem" : "2rem",
      }}
    >
      <div>
        <h2
          style={{
            textAlign: "center",
            fontSize: isMobile ? "2rem" : "",
          }}
        >
          טופס השאלת ביגוד וניכוי בגין אובדן
        </h2>
        <p>שם פרטי ומשפחה: {user.name}</p>
        <p>מחלקה: {user.type_info.title}</p>
        <div style={{ margin: "20px" }}>
          ת.ז.:
          <FormTextField
            name="identifier"
            label="ת.ז."
            rules={{
              required: "שדה זה הוא שדה חובה",
              pattern: {
                value: /^\d{9}$/,
                message: "מס' ת.ז. אינו תקין, נדרש להזין מס' תקין",
              },
            }}
            type="number"
          />
        </div>
      </div>
      <div>
        <p>פרטי ביגוד :</p>
        <SimpleReactTable
          {...{ columns, data }}
          style={{ maxWidth: !isMobile ? "40%" : "" }}
        />
      </div>
      <div>
        <p>
          לטובת ביצוע תפקידך בחברת {selectedBranch.company_name} להלן "החברה"
          הידועה גם כ : {selectedBranch.name} מרשת {selectedBranch.network.name}
          . <br />
          מושאל לך ביגוד כמפורט מטה ביגוד זה הינו רכוש החברה ומושאל לך לצורך
          ביצוע עבודתך בחברה בלבד. <br />
          שמור על תקינות הביגוד ודווח למנהלך במידה והביגוד אינו תקין.
        </p>
      </div>
      <div>
        לכבוד {selectedBranch.company_name} <br />
        אני הח"מ {user.name}, מס' ת.ז. {identifier} מתחייב לשמור על הביגוד, לא
        לחבל בו ולא לעשות בו שימוש שלא לצורך עבודתי. <br />
        ידוע לי כי במקרה של אובדן הביגוד ו/או נזק שיגרם לו מכל סיבה שהיא, רשאית
        החברה לחייב אותי בעלות הביגוד <br />
        ואני מאשר/ת בזאת לחברה במקרה זה לנכות משכר עבודתי או מכל סכום אחר המגיע
        לי מאת החברה את עלות הנזק ו/או האובדן, בהתאם לטבלת החיובים ונוהל חיוב על
        ביגוד, <br />
        וזאת בהתאם להוראות סעיף 25 (א)(6) לחוק הגנת השכר, התשי"ח - 1958. <br />
        תאריך : {new Date().toLocaleDateString()} <br />
        שם העובד: {user.name} <br />
        ת.ז : {identifier} <br />
      </div>
    </div>
  );
};
export default ClothingFields;
