import React from "react";

import SimpleTable from "../../../orders/manageOrders/receiveOrder/SimpleTable";

const EmployeesList = ({ data }) => {
  const columns = [
    {
      title: "שם עובד",
      name: "employee_name",
      render: (data, row) => {
        return data;
      },
    },
    {
      title: "מ. עובד",
      name: "employee_id",
      render: (data, row) => {
        return parseInt(data);
      },
    },
    {
      title: "מספר ת.ז",
      name: "identifier",
      render: (data, row) => {
        return data;
      },
    }
  ];

  return <SimpleTable {...{ columns, data }} cellSize="medium" />;
};

export default EmployeesList;
