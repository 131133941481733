export class RootStore {
  constructor(stores) {
    const combined = (Object.keys(stores) ).reduce((acc, item) => {
      return {
        ...acc,
        [item]: stores[item].init(this),
      };
    }, {});
    Object.assign(this, { ...combined });
  }
}
