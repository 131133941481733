import React, { useEffect, useMemo, useState } from "react";
import Axios from "axios";
import { logoutAdmin } from "../../../redux/actions/AdminLoginAction";
import { setError } from "../../../redux/actions/errorAction";
import { connect } from "react-redux";
import LayoutContent from "../../../layouts/LayoutContent";
import ReactTable from "../../../components/tables/ReactTable";
import { columns } from "./columns";
import { mapKeys, omit } from "lodash";
import { setDialogModal } from "../../../redux/actions/DialogAction";
import { FaRegTrashAlt, FaShekelSign } from "react-icons/fa";
import { FiPlusSquare } from "react-icons/fi";
import EditHolidayModal from "./EditHolidayModal";
import {
  Button,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MobxDateRangePicker from "../../../components/datepicker/mobx-date-range/MobxDateRangePicker";
import { observer } from "mobx-react";
import { useStore } from "../../../stores/setupContext";
import { endOfYear, getDay, startOfYear } from "date-fns";
import { fixDate } from "../../../helpers";
import { HEBREW_DAYS } from "../../../const";
import MultiSelectCheckbox from "../../../components/dropdown/MultiSelectCheckBox";
import { clone } from "lodash";
import { AiOutlineReload } from "react-icons/ai";
import BtnTooltip from "../../../components/tooltips/BtnTooltip";
import { LoadingButton } from "@mui/lab";
import AgeSalaryModal from "./AgeSalaryModal";

let cancelToken;
export const TYPE_HOLIDAY = 1;
export const TYPE_SPECIAL_EVENT = 2;
export const TYPE_ELECTION = 3;
export const TYPES = [
  {
    id: TYPE_HOLIDAY,
    name: "חג",
  },
  {
    id: TYPE_SPECIAL_EVENT,
    name: "מועד מיוחד",
  },
  {
    id: TYPE_ELECTION,
    name: "בחירות",
  },
];

const HolidayView = ({
  token,
  setError,
  logoutAdmin,
  dateRangeFormatted,
  setDialogModal,
}) => {
  const [data, setData] = useState([]);
  const [filteredData, setFiteredData] = useState([]);
  const [holidayToEdit, setHolidayToEdit] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const columns_memo = useMemo(() => columns, []);
  const [loadHolidays, setLoadHolidays] = useState(false);
  const [openAgeSalaryModal, setAgeSalaryModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const fetchAll = useMemo(
    () => async () => {
      try {
        if (!token) return;
        //Check if there are any previous pending requests
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        cancelToken = Axios.CancelToken.source();
        const { data } = await Axios.get(`admin/holidays`, {
          params: {
            dateRange: dateRangeFormatted,
            type: selectedTypes,
          },
          cancelToken: cancelToken.token,
          headers: { Authorization: `Bearer ${token}` },
        });
        setData(data);
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logoutAdmin();
          } else if (e.response.status === 500) {
            setError("", "ארעה שגיאה בשרת");
          }
      }
    },
    [token, logoutAdmin, setError, dateRangeFormatted]
  );

  const updateHoliday = useMemo(
    () => async (id, formValues) => {
      try {
        if (!token) return;
        const { data } = await Axios.post(
          `admin/holidays/${id}/update`,
          formValues,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setData((prev) => {
          let newData = { ...mapKeys(prev, "id"), [data.id]: data };
          newData = Object.values(newData);
          return newData;
        });
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logoutAdmin();
          } else if (e.response.status === 500) {
            setError("", "ארעה שגיאה בשרת");
          }
      }
    },
    [token, logoutAdmin, setError]
  );

  const loadNewYearHoliday = useMemo(
    () => async () => {
      try {
        if (!token) return;
        setLoadHolidays(true);
        const { data, status } = await Axios.post(
          "admin/holidays/updateHolidays",
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (status === 200) {
          setLoadHolidays(false);
          fetchAll();
        }
      } catch (e) {
        if (e.response)
          if (e.response.status === 401) {
            logoutAdmin();
          } else if (e.response.status === 500) {
            setLoadHolidays(false);
            setError("", "ארעה שגיאה בשרת");
          }
      }
    },
    [token]
  );

  const createHoliday = useMemo(
    () => async (formValues) => {
      try {
        if (!token) return;
        const { data } = await Axios.post(`admin/holidays`, formValues, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setData((prev) => {
          let newData = { ...mapKeys(prev, "id"), [data.id]: data };
          newData = Object.values(newData);
          return newData;
        });
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logoutAdmin();
          } else if (e.response.status === 500) {
            setError("", "ארעה שגיאה בשרת");
          }
      }
    },
    [token, logoutAdmin, setError]
  );

  const deleteHoliday = useMemo(
    () => async (id) => {
      try {
        if (!token) return;
        const { data } = await Axios.post(
          `admin/holidays/${id}/delete`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setData((prev) => {
          let newData = mapKeys(prev, "id");
          newData = Object.values(omit(newData, data));
          return newData;
        });
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logoutAdmin();
          } else if (e.response.status === 500) {
            setError("", "ארעה שגיאה בשרת");
          }
      }
    },
    [token, logoutAdmin, setError]
  );

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  useEffect(() => {
    let cloned = [...data];
    if (!data.length || !selectedTypes.length) {
      setFiteredData(cloned);
      return;
    }
    setFiteredData(
      cloned.filter((holiday) => selectedTypes.includes(holiday.type))
    );
  }, [selectedTypes.length, data]);

  const handleDeleteHoliday = (id, name) =>
    setDialogModal({
      title: "הסרת חג",
      text: `האם את/ה בטוח בהסרת חג ${name}?`,
      onConfirm: () => deleteHoliday(id),
      iconJSX: () => FaRegTrashAlt({ size: "7em", color: "#db1e1e" }),
      btnConfirmClass: "color__danger--background",
    });

  return (
    <LayoutContent>
      <EditHolidayModal
        {...{
          open: openModal,
          defaultFormValues: holidayToEdit,
          hideModal: () => {
            setOpenModal(false);
            setHolidayToEdit(undefined);
          },
          createHoliday,
          updateHoliday,
        }}
      />
      <h1>ניהול חגים</h1>
      <div className="flex-start">
        <MobxDateRangePicker
          defaultValue={{
            startDate: startOfYear(new Date()),
            endDate: endOfYear(new Date()),
            key: "selection",
            label: `שנה נוכחית`,
          }}
        />

        <MultiSelectCheckbox
          title="סוג"
          checked={selectedTypes}
          setChecked={setSelectedTypes}
          options={TYPES.map((x) => ({ label: x.name, value: x.id }))}
        />
      </div>
      <ReactTable
        {...{
          moreButtons: (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="Open Modal"
                  color="primary"
                  onClick={() => setOpenModal(true)}
                >
                  <FiPlusSquare size={isMobile ? 17 : 20} />
                </IconButton>
                <BtnTooltip placement={"top"} text={"טען חגים לשנה החדשה"}>
                  <LoadingButton
                    style={{ minWidth: "0px" }}
                    color="primary"
                    loading={loadHolidays}
                    onClick={() => loadNewYearHoliday()}
                  >
                    <AiOutlineReload size={isMobile ? 15 : 20} />
                  </LoadingButton>
                </BtnTooltip>
                <BtnTooltip placement={"top"} text={"עדכון תעריף מינימום"}>
                  <Button
                    style={{ minWidth: "0px" }}
                    color="primary"
                    onClick={() => setAgeSalaryModal(true)}
                  >
                    <FaShekelSign size={isMobile ? 15 : 20} />
                  </Button>
                </BtnTooltip>
              </div>
            </>
          ),
          sortBy: [{ id: "start_date", desc: false }],
          data: filteredData,
          columns: columns_memo,
          isPaginate: true,
          //   translation_endpoint: "smsRequest",
          title: "חגים",
          newValuePreparation: {
            start_date_string: ({ start_date }) => fixDate(start_date),
            end_date_string: ({ end_date }) => fixDate(end_date),
            start_day: ({ start_date }) =>
              HEBREW_DAYS[getDay(new Date(start_date))],
            end_day: ({ end_date }) => HEBREW_DAYS[getDay(new Date(end_date))],
          },
          costumeValuePreparation: {
            start_date: ({ start_date }) => start_date,
            end_date: ({ end_date }) => end_date,
          },
          cellsPropsById: (id) => {
            if (["action"].includes(id)) {
              return {
                handleDeleteHoliday,
                handleHolidayToEdit: (holiday) => {
                  setHolidayToEdit(holiday);
                  setOpenModal(true);
                },
              };
            }
          },
        }}
      />
      <AgeSalaryModal
        open={openAgeSalaryModal}
        onClose={() => setAgeSalaryModal(false)}
      />
    </LayoutContent>
  );
};

const Observed = observer(HolidayView);

const WithStoreConnection = (props) => {
  const { dateRangePicker } = useStore();
  const { dateRangeFormatted } = dateRangePicker;

  return (
    <Observed
      {...{
        ...props,
        dateRangeFormatted,
      }}
    />
  );
};

const HolidayViewObserver = observer((props) => WithStoreConnection(props));

const mapStateToProps = (state) => {
  const { token } = state.authAdmin;

  return { token };
};

export default connect(mapStateToProps, {
  logoutAdmin,
  setError,
  setDialogModal,
})(HolidayViewObserver);
