import * as PROCEDURES_CONSTS from "./Procedures/consts";
import * as CLOTHES_CONSTS from "./Clothes/consts";
import * as WORK_AGREEMENTS_CONSTS from "./WorkAgreement/consts";
import * as EQUIPMENT_CONSTS from "./Equipments/const";
import * as CUT_CONV_CONSTS from "./managerProcedures/const";
import * as SUMMONS_FORM_CONSTS from "./SummonsFormHearing/const";
import * as TERMINATION_FORM_CONSTS from "./WorkerTerminationForm/consts";
import * as CLOTHING_MANAGER_CONSTS from "./ClothByManager/const";
import * as SUMMONS_FORM_NEW from "./SummonsFormNew/const";
import * as TERMINATION_FORM_CONSTS_NEW from "./WorkTerminationFormNew/consts";
import * as PROTOCOL_TERMINATION_CONSTS from "./ProtocolTermination/consts";
import * as RETURN_CLOTHING_MANAGER_CONSTS from "./ReturnClothByManager/const";
import * as CONFIRMATION_EMPLOYMENT_CONSTS from "./ConfirmationEmploymentPeriod/const";
import * as RESIGNATION_LETTER_CONSTS from "./ResignationLetter/const";

const TO_WATCH_STATUS = "לצפייה בלבד";
const TO_READ_STATUS = "לא נקרא";
const READED_STATUS = "נקרא";
const TYPE_PROCEDURE = 1;
const TYPE_CLOTHES = 2;
const TYPE_WORK_AGREEMENT = 3;
const TYPE_SEXUAL_HARRASMENT = 4;
const TYPE_ACCESSIBILITY = 6;
const TYPE_EQUIPMENT = 7;
const TYPE_CUT_CONVERSATION = 8;
const TYPE_SALARY_CONVERSATION = 9;
const TYPE_SUMMONS_FORM = 11;
const TYPE_TERMINATION_FORM = 12;
const TYPE_CLOTHING_MANAGER_FORM = 13;
const TYPE_SUMMONS_FORM_NEW = 14;
const TYPE_TERMINATION_FORM_NEW = 15;
const TYPE_PROTOCOL_TERMINATION = 16;
const TYPE_RETURN_CLOTHING_MANAGER_FORM = 17;
const TYPE_CONFIRMATION_EMPLOYMENT_PERIOD = 18;
const TYPE_RESIGNATION_LETTER = 19;
export const MANAGE_NAV = "MANAGE";
export const REPORTS_NAV = "REPORTS";

const KNOWLEADGE_MANAGE = [
  {
    value: MANAGE_NAV,
    id: 0,
    label: "ניהול ידע",
  },
  {
    value: REPORTS_NAV,
    id: 1,
    label: "דוחות",
  },
];
export const TYPES_LABELS = [
  {
    id: 1,
    label: "נוהל",
  },
  { id: 2, label: "ביגוד" },
  { id: 3, label: "הסכם עבודה" },
];
// "נוהל", "ביגוד", "הסכם עבודה"
const TYPES_ROUTES = ["procedures", "clothing", "work_agreements"];

// PROCEDURES FILTERS - STATUS
export const FILTERS_STATUS = [
  {
    id: 1,
    label: "פעילים",
  },
  {
    id: 0,
    label: "לא פעילים",
  },
];
const PARTS = {
  [TYPE_PROCEDURE]: PROCEDURES_CONSTS.PARTS,
  [TYPE_CLOTHES]: CLOTHES_CONSTS.PARTS,
  [TYPE_WORK_AGREEMENT]: WORK_AGREEMENTS_CONSTS.PARTS,
  [TYPE_EQUIPMENT]: EQUIPMENT_CONSTS.PARTS,
  [TYPE_CUT_CONVERSATION]: CUT_CONV_CONSTS.PARTS,
  [TYPE_SUMMONS_FORM]: SUMMONS_FORM_CONSTS.PARTS,
  [TYPE_TERMINATION_FORM]: TERMINATION_FORM_CONSTS.PARTS,
  [TYPE_CLOTHING_MANAGER_FORM]: CLOTHING_MANAGER_CONSTS.PARTS,
  [TYPE_SUMMONS_FORM_NEW]: SUMMONS_FORM_NEW.PARTS,
  [TYPE_TERMINATION_FORM_NEW]: TERMINATION_FORM_CONSTS_NEW.PARTS,
  [TYPE_PROTOCOL_TERMINATION]: PROTOCOL_TERMINATION_CONSTS.PARTS,
  [TYPE_RETURN_CLOTHING_MANAGER_FORM]: RETURN_CLOTHING_MANAGER_CONSTS.PARTS,
  [TYPE_CONFIRMATION_EMPLOYMENT_PERIOD]: CONFIRMATION_EMPLOYMENT_CONSTS.PARTS,
  [TYPE_RESIGNATION_LETTER]: RESIGNATION_LETTER_CONSTS.PARTS,
};

export {
  TO_READ_STATUS,
  TO_WATCH_STATUS,
  READED_STATUS,
  TYPE_PROCEDURE,
  KNOWLEADGE_MANAGE,
  TYPE_CLOTHES,
  TYPE_SEXUAL_HARRASMENT,
  TYPE_ACCESSIBILITY,
  TYPE_WORK_AGREEMENT,
  TYPE_EQUIPMENT,
  TYPE_CUT_CONVERSATION,
  TYPE_SALARY_CONVERSATION,
  TYPES_ROUTES,
  PARTS,
  TYPE_SUMMONS_FORM,
  TYPE_TERMINATION_FORM,
  TYPE_CLOTHING_MANAGER_FORM,
  TYPE_SUMMONS_FORM_NEW,
  TYPE_TERMINATION_FORM_NEW,
  TYPE_PROTOCOL_TERMINATION,
  TYPE_RETURN_CLOTHING_MANAGER_FORM,
  TYPE_CONFIRMATION_EMPLOYMENT_PERIOD,
  TYPE_RESIGNATION_LETTER,
};
