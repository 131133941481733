import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "react-date-range/dist/styles";
import {
  defaultInputRanges,
  defaultStaticRanges,
} from "react-date-range/dist/defaultRanges";
import { rangeShape } from "react-date-range/dist/components/DayCell";
import cx from "classnames";
import Button from "../../buttons/Button";

class DefinedRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeOffset: 0,
      focusedInput: -1,
    };
  }

  handleRangeChange = (range, label) => {
    const { onChange, ranges, focusedRange } = this.props;
    const selectedRange = ranges[focusedRange[0]];
    if (!onChange || !selectedRange) return;
    onChange({
      [selectedRange.key || `range${focusedRange[0] + 1}`]: {
        ...selectedRange,
        ...range,
        label,
      },
    });
  };

  getRangeOptionValue(option) {
    const { ranges = [], focusedRange = [] } = this.props;

    if (typeof option.getCurrentValue !== "function") {
      return "";
    }

    const selectedRange = ranges[focusedRange[0]] || {};
    return option.getCurrentValue(selectedRange) || "";
  }

  getSelectedRange(ranges, staticRange) {
    const focusedRangeIndex = ranges.findIndex((range) => {
      if (!range.startDate || !range.endDate || range.disabled) return false;
      return staticRange.isSelected(range);
    });
    const selectedRange = ranges[focusedRangeIndex];
    return { selectedRange, focusedRangeIndex };
  }

  render() {
    const {
      headerContent,
      onPreviewChange,
      staticRanges,
      ranges,
      renderStaticRangeLabel,
      rangeColors,
      className,
      onClose,
      setShow
    } = this.props;

    return (
      <div
        style={{ margin: "auto 0" }}
        className={cx(styles.definedRangesWrapper, className)}
      >
        {headerContent}
        <div className={styles.staticRanges}>
          {staticRanges.map((staticRange, i) => {
            const { selectedRange, focusedRangeIndex } = this.getSelectedRange(
              ranges,
              staticRange
            );
            let labelContent;

            if (staticRange.hasCustomRendering) {
              labelContent = renderStaticRangeLabel(staticRange);
            } else {
              labelContent = staticRange.label;
            }

            return (
              <button
                type="button"
                className={cx(styles.staticRange, {
                  [styles.staticRangeSelected]: Boolean(selectedRange),
                })}
                style={{
                  color: selectedRange
                    ? selectedRange.color || rangeColors[focusedRangeIndex]
                    : null,
                }}
                key={i}
                onClick={() => {
                  this.handleRangeChange(
                    staticRange.range(this.props),
                    labelContent
                  );
                  // if (this.props.setShow) this.props.setShow(false);
                }}
                onFocus={() =>
                  onPreviewChange &&
                  onPreviewChange(staticRange.range(this.props))
                }
                onMouseOver={() =>
                  onPreviewChange &&
                  onPreviewChange(staticRange.range(this.props))
                }
                onMouseLeave={() => {
                  onPreviewChange && onPreviewChange();
                }}
              >
                <span tabIndex={-1} className={styles.staticRangeLabel}>
                  {labelContent}
                </span>
              </button>
            );
          })}

        </div>
        <Button
            style={{margin:"auto", marginBottom:"1.5rem",fontSize: "1.4rem", padding: "1rem 3rem" }}
            onClick={onClose?onClose:() =>setShow(false)}
            className="btn--normal"
            textValue="סגור"
          />
      </div>
    );
  }
}

DefinedRange.propTypes = {
  inputRanges: PropTypes.array,
  staticRanges: PropTypes.array,
  ranges: PropTypes.arrayOf(rangeShape),
  focusedRange: PropTypes.arrayOf(PropTypes.number),
  onPreviewChange: PropTypes.func,
  onChange: PropTypes.func,
  footerContent: PropTypes.any,
  headerContent: PropTypes.any,
  rangeColors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  renderStaticRangeLabel: PropTypes.func,
};

DefinedRange.defaultProps = {
  inputRanges: defaultInputRanges,
  staticRanges: defaultStaticRanges,
  ranges: [],
  rangeColors: ["#3d91ff", "#3ecf8e", "#fed14c"],
  focusedRange: [0, 0],
};

export default DefinedRange;
