import { uniqBy } from "lodash";

import {
  FILTERS_STATUS_ENABLE,
  NEW_ITEM_ID,
  TAB_ITEMS,
  TAB_OPTIONS,
  TAB_PATH_ITEMS,
} from "../../pages/goods/const";
import { htmlSpecialCharacters } from "../../pages/products/const";
import {
  CLOSE_FORM,
  EDIT_CATEGORY,
  EDIT_ITEM,
  FETCH_CATALOG,
  FETCH_CATEGORIES,
  FETCH_ITEMS,
  FETCH_ITEMS_FREE_SEARCH,
  FETCH_POS_CODES,
  RESET_FILTER,
  SET_TAB,
  SET_CATALOG,
  SET_FREESEARCH_ID,
  SET_ITEMS_PAGE,
  SET_MULTI_UPLOAD_BRANCHES,
  SET_PRODUCT,
  SET_SUPPLIERS_OPTIONS,
  TOGGLE_CATEGORY,
  TOGGLE_ITEM,
  SET_FILTER,
  START_LOADING_MASTER_ITEMS,
  END_LOADING_MASTER_ITEMS,
  START_LOADING_DETAILS_CATEGORY,
  END_LOADING_DETAILS_CATEGORY,
  START_LOADING_CATALOG,
  END_LOADING_CATALOG,
  START_LOADING_MASTER_CATEGORY,
  END_LOADING_MASTER_CATEGORY,
  START_LOADING_SUPPLIERS,
  END_LOADING_SUPPLIERS,
  START_LOADING_POS_CODES,
  END_LOADING_POS_CODES,
  START_LOADING_FREE_SEARCH,
  END_LOADING_FREE_SEARCH,
  START_LOADING_PRODUCTS,
  END_LOADING_PRODUCTS,
  START_LOADING_SWAP_INGREDIENT,
  END_LOADING_SWAP_INGREDIENT,
  START_LOADING_ITEM_DETAILS,
  END_LOADING_ITEM_DETAILS,
  CREATE_NEW_CATEGORY,
  CREATE_NEW_ITEM,
  STORE_ITEM,
  STORE_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_ITEM,
  START_LOADING_UNIFIED_PRODUCTS,
  END_LOADING_UNIFIED_PRODUCTS,
  FETCH_UNIFIED_PRODUCTS,
  RESET_CATALOG,
  SET_ITEM_ERROR,
  RESET_STATE,
  SET_DID_CATEGORY_CHANGE,
  START_LOADING_PRICE_GAPS,
  END_LOADING_PRICE_GAPS,
  FETCH_PRICE_GAPS,
  START_LOADING_ALLERGENS,
  END_LOADING_ALLERGENS,
  FETCH_ALLERGENS,
} from "../actions/ItemAction/type";
import produce, { current } from "immer";
import { initialCategory } from "../../pages/goods/catgories/details/CategoriesDetails";
import { BsGear } from "react-icons/bs";
import { defineds } from "../../components/datepicker/const";

const INITIAL_STATE = {
  tab: {
    id: TAB_ITEMS,
    label: TAB_OPTIONS[TAB_ITEMS].label,
    path: TAB_PATH_ITEMS,
  },
  items: {
    filter: {
      status: [FILTERS_STATUS_ENABLE + 1],
      branchId: {},
      categoryId: [],
      suppliersId: [],
      other: [],
      selectedOther: null,
    },
    options: {
      categories: {
        isLoading: false,
        data: [],
      },
      suppliers: {
        isLoading: false,
        data: [],
      },
      posCodes: {
        isLoading: false,
        data: [],
      },
      unifiedProducts: {
        isLoading: false,
        data: [],
      },
      allergens: {
        isLoading: false,
        data: [],
      },
    },
    freeSearch: {
      isLoading: false,
      data: [],
    },
    pagination: {
      current_page: 0,
      per_page: 50,
      total: 1,
    },
    data: {
      master: { isLoading: false, data: [] },
      details: {
        isLoading: false,
        didCategoryChange: false,
        data: {},
        errors: [],
      },
      products: {
        isLoading: false,
        isSwapLoading: false,
        freeSearchId: null,
        data: [],
      },
      suppliersCatalog: {
        isLoading: false,
        data: [],
      },
      multiUploadBranches: {
        isLoading: false,
        data: [],
      },
    },
  },
  categories: {
    filter: {
      status: [FILTERS_STATUS_ENABLE + 1],
      other: [],
    },
    freeSearch: {
      isLoading: false,
      data: [],
    },
    pagination: {
      current_page: 0,
      per_page: 100,
      total: 1,
    },
    data: {
      master: { isLoading: false, data: [] },
      details: {
        isLoading: false,
        data: {},
        lastCreatedItem: {},
      },
    },
  },
  price_gaps: {
    filter: {
      dateRange: {
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
        label: "שבוע שעבר",
        key: "selection",
      },
      branchId: {},
      categoryId: [],
      suppliersId: [],
      other: [],
      selectedOther: null,
    },
    pagination: {
      current_page: 0,
      per_page: 100,
      total: 1,
    },
    data: {
      master: { isLoading: false, data: [] },
    },
  },
  unified: {
    filter: {
      status: [FILTERS_STATUS_ENABLE + 1],
      branchId: {},
    },
    pagination: {
      current_page: 0,
      per_page: 100,
      total: 1,
    },
    data: {
      master: { isLoading: false, data: [] },
    },
  },
};

export default produce((draft, action, state) => {
  const { type, payload } = action;
  switch (type) {
    // =========== GENERAL START ===========

    case SET_DID_CATEGORY_CHANGE:
      draft.items.data.details.didCategoryChange = payload;
      break;

    case RESET_STATE:
      Object.keys(draft).forEach((key) => {
        draft[key] = INITIAL_STATE[key];
      });
      break;

    case SET_TAB:
      draft.tab = payload;
      draft[[draft.tab.path]].data.details =
        INITIAL_STATE[[draft.tab.path]].data.details;
      break;

    case CLOSE_FORM:
      draft[[draft.tab.path]].data.details.data =
        INITIAL_STATE[[draft.tab.path]].data.details.data;
      draft.items.data.suppliersCatalog.data = [];
      draft.items.data.products.data = [];
      draft.items.data.products.freeSearchId = null;
      draft.items.data.details.errors = [];
      draft.items.data.details.didCategoryChange = false;
      break;
    // =========== GENERAL END ===========

    // =========== FILTERS START ===========
    case SET_FILTER:
      const { filterType, value } = payload;

      if (filterType === "other") {
        draft[[draft.tab.path]].filter[[filterType]] = [value.id];
        draft[[draft.tab.path]].filter.selectedOther = value;
      } else {
        draft[[draft.tab.path]].filter[[filterType]] = value;
      }
      break;
    case RESET_FILTER:
      draft[[draft.tab.path]].filter = INITIAL_STATE[[draft.tab.path]].filter;
      break;
    // =========== FILTERS END ===========

    //  =========== ITEMS CRUD SECTION START ===========

    // ITEMS MASTER LOADING
    case START_LOADING_MASTER_ITEMS:
    case END_LOADING_MASTER_ITEMS:
      draft.items.data.master.isLoading = payload;
      break;

    // ITEMS DETAILS LOADING
    case START_LOADING_ITEM_DETAILS:
    case END_LOADING_ITEM_DETAILS:
      draft.items.data.details.isLoading = payload;
      break;

    // ITEMS MASTER
    case FETCH_ITEMS:
      let itemsArray;
      let paginationData;
      if (Array.isArray(payload)) {
        itemsArray = payload;
        paginationData = {
          total: itemsArray.length,
          current_page: 0,
          per_page: 10,
        };
      } else {
        const { total, current_page, per_page, data } = payload;
        itemsArray = data;
        paginationData = {
          total,
          current_page: current_page - 1,
          per_page: Number(per_page),
        };
      }

      draft.items.data.master.data = itemsArray;

      draft[[draft.tab.path]].pagination = paginationData;

      break;

    // ITEMS DETAILS
    // ITEMS CREATE NEW (button) ACTION
    case EDIT_ITEM:
    case CREATE_NEW_ITEM:
      draft.items.freeSearch.data = [];
      draft.items.data.details.data = { ...payload, itemScale: payload.scale };
      break;

    // DELETE ITEMS
    case TOGGLE_ITEM:
      const masterItems = draft.items.data.master.data;
      const index = masterItems.findIndex(({ id }) => id === payload.id);
      if (index !== -1) masterItems[index] = payload;
      break;

    // CREATE ITEMS
    case STORE_ITEM:
      draft.items.data.master.data.unshift(payload);
      // Close details form

      draft[[draft.tab.path]].data.details.data =
        INITIAL_STATE[[draft.tab.path]].data.details.data;
      draft.items.data.details.lastCreatedItem = {
        supplier: payload.supplier,
        category: payload.category_id,
        supplier_id: payload.supplier_id,
        supplier_name: payload.supplier_name,
      };
      break;

    // UPDATE ITEMS
    case UPDATE_ITEM:
      const masterItemsUpdate = draft.items.data.master.data;
      const masterItemsIndex = masterItemsUpdate.findIndex(
        ({ id }) => id === payload.id
      );
      if (masterItemsIndex !== -1)
        masterItemsUpdate[masterItemsIndex] = {
          ...masterItemsUpdate[masterItemsIndex],
          payload,
        };
      break;

    //  =========== ITEMS CRUD SECTION END ===========

    case SET_ITEM_ERROR:
      draft.items.data.details.errors = payload;
      break;

    //  =========== CATEGORY CRUD SECTION START ===========

    // CATEGORY MASTER LOADING
    case START_LOADING_MASTER_CATEGORY:
    case END_LOADING_MASTER_CATEGORY:
      draft.categories.data.master.isLoading = payload;
      draft.items.options.categories.isLoading = payload;
      break;

    // CATEGORY DETAILS LOADING
    case START_LOADING_DETAILS_CATEGORY:
    case END_LOADING_DETAILS_CATEGORY:
      draft.categories.data.details.isLoading = payload;
      break;

    // CATEGORY MASTER
    case FETCH_CATEGORIES:
      draft.categories.data.master.data = payload;
      draft.items.options.categories.data = payload
        .filter(({ enable }) => enable)
        .map(({ id, name, const_category_id, with_vat, with_deposit }) => ({
          value: parseInt(id),
          label: htmlSpecialCharacters(name),
          const_category_id,
          with_vat,
          with_deposit,
        }));
      break;

    // CATEGORY DETAILS
    case EDIT_CATEGORY:
      draft.categories.data.details.data = payload;
      break;

    // CATEGORY CREATE NEW (button) ACTION
    case CREATE_NEW_CATEGORY:
      draft.categories.data.details.data = initialCategory;
      break;

    // DELETE CATEGORY
    // UPDATE CATEGORY
    case TOGGLE_CATEGORY:
    case UPDATE_CATEGORY:
      // Update master categories
      const masterCategories = draft.categories.data.master.data;
      const categoryIndex = masterCategories.findIndex(
        ({ id, const_category_id }) => {
          if (payload.const_category_id) {
            return const_category_id === payload.const_category_id;
          } else {
            return id === payload.id;
          }
        }
      );

      if (categoryIndex !== -1) {
        masterCategories[categoryIndex] = payload;
      }

      // Close categories details form
      draft.categories.data.details.data =
        INITIAL_STATE.categories.data.details.data;
      break;

    // CREATE CATEGORY
    case STORE_CATEGORY:
      // Update master with new category
      draft.categories.data.master.data.unshift(payload);

      // Add new category to options
      if (payload.enable) {
        draft.items.options.categories.data.unshift({
          value: parseInt(payload.id),
          label: htmlSpecialCharacters(payload.name),
        });
      }
      // Close categories details form
      draft.categories.data.details.data =
        INITIAL_STATE.categories.data.details.data;
      break;

    //  =========== CATEGORY CRUD SECTION END ===========

    // SUPPLIERS
    case START_LOADING_SUPPLIERS:
    case END_LOADING_SUPPLIERS:
      draft.items.options.suppliers.isLoading = payload;
      break;

    case SET_SUPPLIERS_OPTIONS:
      draft.items.options.suppliers.data = payload.map(
        ({
          supplier_id,
          name,
          has_department,
          is_stornext,
          discount,
          is_taklit,
        }) => ({
          value: supplier_id,
          label: htmlSpecialCharacters(name),
          isConnected: has_department,
          is_stornext: is_stornext,
          discount,
          is_taklit,
        })
      );
      break;

    case START_LOADING_FREE_SEARCH:
    case END_LOADING_FREE_SEARCH:
      draft.items.freeSearch.isLoading = payload;
      break;
    case FETCH_ITEMS_FREE_SEARCH:
      draft.items.freeSearch.data = uniqBy(payload, "id");
      break;

    // POS CODES
    case START_LOADING_POS_CODES:
    case END_LOADING_POS_CODES:
      draft.items.options.posCodes.isLoading = payload;
      break;
    case FETCH_POS_CODES:
      draft.items.options.posCodes.data = payload;
      break;

    // ALLERGENS
    case START_LOADING_ALLERGENS:
    case END_LOADING_ALLERGENS:
      draft.items.options.allergens.isLoading = payload;
      break;
    case FETCH_ALLERGENS:
      draft.items.options.allergens.data = payload;
      break;

    // UNIFIED PRODUCTS
    case START_LOADING_UNIFIED_PRODUCTS:
    case END_LOADING_UNIFIED_PRODUCTS:
      draft.items.options.unifiedProducts.isLoading = payload;
      break;
    case FETCH_UNIFIED_PRODUCTS:
      draft.items.options.unifiedProducts.data = payload.map(
        ({ unified_product_id, name }) => ({
          value: parseInt(unified_product_id),
          label: htmlSpecialCharacters(name),
        })
      );
      break;

    // PAGINATION
    case SET_ITEMS_PAGE:
      draft[[draft.tab.path]].pagination[[payload.name]] = payload.value;
      break;

    // MULTI UPLOAD
    case SET_MULTI_UPLOAD_BRANCHES:
      draft.items.data.multiUploadBranches.data = payload;
      break;

    // SUPPLIER CATALOG
    case START_LOADING_CATALOG:
    case END_LOADING_CATALOG:
      draft.items.data.suppliersCatalog.isLoading = payload;
      break;

    case FETCH_CATALOG:
      draft.items.data.suppliersCatalog.data = payload;
      break;
    case RESET_CATALOG:
      draft.items.data.suppliersCatalog.data = [];
      break;

    case START_LOADING_PRODUCTS:
    case END_LOADING_PRODUCTS:
      draft.items.data.products.isLoading = payload;
      break;
    case SET_PRODUCT:
      draft.items.data.products.data = payload;
      break;

    case SET_FREESEARCH_ID:
      draft.items.data.products.freeSearchId = payload;
      break;

    case START_LOADING_SWAP_INGREDIENT:
    case END_LOADING_SWAP_INGREDIENT:
      draft.items.data.products.isSwapLoading = payload;
      break;

    //PRICE GAP
    case START_LOADING_PRICE_GAPS:
    case END_LOADING_PRICE_GAPS:
      draft.price_gaps.data.master.isLoading = payload;
      break;

    case FETCH_PRICE_GAPS:
      const { total, current_page, per_page, data } = payload;

      draft.price_gaps.data.master.data = data;

      draft[[draft.tab.path]].pagination = {
        total,
        current_page: current_page - 1,
        per_page: Number(per_page),
      };
      break;

    default:
      break;
  }
}, INITIAL_STATE);
