import { GrDocumentCsv } from "react-icons/gr";
import { AiOutlineFileUnknown } from "react-icons/ai";
import Zoom from "react-medium-image-zoom";
import { IconButton } from "@mui/material";
import { createElement, useState, useLayoutEffect, useEffect } from "react";
import { useRef } from "react";
import { BsArrowsFullscreen } from "react-icons/bs";

const PreviewFile = ({
  file: { type, preview, name },
  width = "auto",
  height = "auto",
  pdfWidth,
  pdfHeight,
  imgWidth,
  imgHeight,
  videoWidth,
  videoHeight,
}) => {
  const [render, setRender] = useState(null);
  useLayoutEffect(() => {
    setRender(() => {
      switch (type) {
        case "application/pdf":
          return Pdf;
        case "text/csv":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return Excel;
        case "video/mp4":
        case "video/ogg":
        case "video/webm":
          return Video;

        case "image/png":
        case "image/jpg":
        case "image/jpeg":
          return Img;

        default:
          return Default;
      }
    });
  }, [type]);
  return (
    <>
      {render
        ? createElement(render, {
            type,
            preview,
            name,
            width,
            height,
            pdfWidth,
            pdfHeight,
            imgWidth,
            imgHeight,
            videoHeight,
            videoWidth,
          })
        : null}
    </>
  );
};

const Pdf = ({ preview, type, width, height, pdfHeight, pdfWidth }) => {
  const [actualWidth, setActualWidth] = useState("300px");
  const [actualHeight, setActualHeight] = useState("150px");
  useEffect(() => {
    setActualWidth(pdfWidth ?? (width === "auto" ? "300px" : width));
  }, [width, pdfWidth]);
  useEffect(() => {
    setActualHeight(pdfHeight ?? (height === "auto" ? "150px" : height));
  }, [height, pdfHeight]);

  const IframeRef = useRef(null);
  return (
    // <div
    //   style={{
    //     width: actualWidth,
    //     height: actualHeight,
    //     display: "flex",
    //   }}
    // >
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <IconButton
          color="primary"
          onClick={() => {
            const docEl = IframeRef.current;
            const requestFullScreen =
              docEl.requestFullscreen ||
              docEl.mozRequestFullScreen ||
              docEl.webkitRequestFullScreen ||
              docEl.msRequestFullscreen;

            requestFullScreen.call(docEl);

            // IframeRef.current.requestFullscreen();
          }}
        >
          <p>מסך מלא</p> <BsArrowsFullscreen />
        </IconButton>
      </div>
      <iframe
        ref={IframeRef}
        title="iframe title"
        src={
          process.env.NODE_ENV === "development"
            ? preview
            : `https://docs.google.com/viewer?url=${preview}&embedded=true`
        }
        width={actualWidth}
        height={actualHeight}
        allowFullScreen
        {...{
          type,
        }}
      />
    </>

    // </div>
  );
};

const Video = ({ preview, width, height, videoHeight, videoWidth }) => {
  return (
    <video
      src={preview}
      width={videoWidth ?? width}
      height={videoHeight ?? height}
    />
  );
};
const checkPreview = (preview) => {
  if(preview.split("http".length>2))
    return "http"+preview.split("http")[preview.split("http").length-1];
  else
    return preview;
};
const Img = ({ preview, width, height, name, imgWidth, imgHeight }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <span>לחץ על התמונה להגדלה</span>
      <Zoom>
        <img
          src={preview}
          width={imgWidth ?? width}
          height={imgHeight ?? height}
          alt={name}
          style={{
            objectFit: "contain",
          }}
        />
      </Zoom>
    </div>
  );
};

const Excel = () => {
  return <GrDocumentCsv size="3rem" />;
};
const Default = () => {
  return <AiOutlineFileUnknown size="3rem" />;
};

export default PreviewFile;
