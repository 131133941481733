import React, { useEffect, useMemo, useState } from "react";
import Model, { Content, Footer, Header } from "../../../components/Model";

import Select from "react-select";
import { isFunction } from "lodash";
import { I18n } from "react-redux-i18n";
import { useScreenSize } from "../../../Hooks";

const TableOptionsModel = ({
  allColumns,
  pageSize,
  setPageSize,
  hiddenColumns,
  setCheckedColumns,
  checkedColumns,
}) => {
  const { isMobile } = useScreenSize();
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const filterBySearch = useMemo(
    () => async (subString) => {
      const filterList = await allColumns.filter((column) =>
        (isFunction(column.Header)
          ? `${I18n.t(`excel.${column.accessor}`)}`
          : column.Header
        ).includes(subString)
      );

      setList(filterList.length ? filterList : []);
    },
    [allColumns]
  );
  const handleSelectedCol = (e, value) => {
    if (checkedColumns.includes(value))
      setCheckedColumns(checkedColumns.filter((x) => x !== value));
    else setCheckedColumns([...checkedColumns, value]);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    filterBySearch(value);
  };

  useEffect(() => {
    setList(allColumns);
  }, [allColumns]);

  return (
    <Model
      style={
        isMobile
          ? { width: "25rem", boxShadow: "unset", padding: 0 }
          : { width: "25rem" }
      }
    >
      <Header>
        <h3>הגדרות טבלה</h3>
      </Header>
      <Content>
        <Select
          styles={{
            container: (provided) => ({ ...provided, width: "70%", zIndex: 5 }),
          }}
          value={
            pageSize === 500
              ? { value: 500, label: "הצג הכל" }
              : { value: pageSize, label: pageSize }
          }
          onChange={(e) => {
            if (e.value === 500) setPageSize(500);
            else setPageSize(Number(e.value));
          }}
          options={[
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 25, label: 25 },
            { value: 30, label: 30 },
            { value: 40, label: 40 },
            { value: 50, label: 50 },
            { value: 500, label: "הצג הכל" },
          ]}
        />
      </Content>
      <Header style={{ height: "3rem", paddingTop: 0 }}>
        <h3>תצוגת עמודות</h3>
      </Header>
      <div className="checkbox">
        <div className="dropdown-select--option__search">
          <input
            className="dropdown-select--search"
            type="search"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            name="search"
            value={searchValue}
            onChange={(e) => handleSearchChange(e)}
          />
          <i className="fa fa-search"></i>
        </div>
        {list.length ? (
          list.map((column) => {
            if (hiddenColumns.includes(column.accessor)) {
              return (
                <div key={column.accessor}>
                  <label className="checkbox--container">
                    {isFunction(column.Header)
                      ? `${I18n.t(`excel.${column.accessor}`)}`
                      : column.Header}
                    <input
                      type="checkbox"
                      checked={checkedColumns.includes(column.accessor)}
                      onChange={(e) => handleSelectedCol(e, column.accessor)}
                    />
                    <span className="checkbox--checkmark"></span>
                  </label>
                </div>
              );
            } else return "";
          })
        ) : (
          <div className="u-center-text">לא נמצאו תוצאות</div>
        )}
      </div>

      <Footer className="model--footer"></Footer>
    </Model>
  );
};

export default TableOptionsModel;
