import { Link } from "@mui/material";
import {
  PercentageCell,
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";
import { GOAL_TYPE_PERCENTAGE } from "../../NewHomePage/GoalColumns";
import { GREEN_COLOR, RED_COLOR } from "../../products/const";
import {
  dynamicToFixed,
  fixPercentage,
  getSafeDivision,
  predictByDates,
  submitWrapper,
} from "../../../helpers";
import { fixPrice } from "../../../helpers";
import { FcHeader } from "../const";

export const getData = (
  by_branch, // raw data
  branchesFoodCostGoal, // raw data
  branches, //redux state
  datesRange // [start, end]
) => {
  return by_branch.map((branchSoldDish) => {
    const { amount, total, worth, branch_id } = branchSoldDish;

    const branch = branches.find((branch) => branch.id === branch_id);
    const avg_foodcost = getSafeDivision(worth, total) * 100;

    const sale_predict = predictByDates(
      datesRange[0],
      datesRange[1],
      total,
      branch.day_of_work
    );

    const branchFoodCostGoal = branchesFoodCostGoal.find(
      (goal) => goal.branch_id === branch.id
    );

    return {
      branch_name: branch.name,
      branch_id: branch.id,
      total_with_vat: total,
      worth,
      sale_predict,
      avg_foodcost,
      foodcost_goal: branchFoodCostGoal && {
        type: branchFoodCostGoal.display_type,
        value: branchFoodCostGoal.goal,
        is_positive:
          branchFoodCostGoal.goal >
          (branchFoodCostGoal.display_type == GOAL_TYPE_PERCENTAGE
            ? avg_foodcost
            : worth),
      },
    };
  });
};

export const excelRowConfig = (row) => {
  row.total_with_vat = dynamicToFixed(row.total_with_vat);
  row.worth = dynamicToFixed(row.worth);
  row.sale_predict = dynamicToFixed(row.sale_predict);
  row.avg_foodcost = dynamicToFixed(row.avg_foodcost);
  row.foodcost_goal = row.foodcost_goal
    ? row.foodcost_goal.type === GOAL_TYPE_PERCENTAGE
      ? fixPercentage(row.foodcost_goal.value, 2)
      : fixPrice(row.foodcost_goal.value, 1, false)
    : "";

  return row;
};

export const aggregates = [
  { field: "total_with_vat", aggregate: "sum" },
  { field: "worth", aggregate: "sum" },
  { field: "sale_predict", aggregate: "sum" },
];

export const sortBy = [{ id: "avg_foodcost", desc: true }];

export const columns = [
  {
    Header: "סניף",
    accessor: "branch_name",
    Cell: ({ value, row, getGroupedSoldDishesByBranch, dispatch }) => (
      <Link
        component="button"
        sx={{
          fontSize: "1.6rem",
        }}
        onClick={() =>
          submitWrapper(getGroupedSoldDishesByBranch, dispatch, [row.original])
        }
      >
        {value}
      </Link>
    ),
    Footer: () => "סה״כ",
  },
  {
    Header: "סה״כ מכירות כולל מע״מ",
    accessor: "total_with_vat",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "עלות רכיבים",
    accessor: "worth",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "צפי סוף תקופה",
    accessor: "sale_predict",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: FcHeader,
    accessor: "avg_foodcost",
    Cell: PercentageCell,
  },
  {
    Header: "יעד F.C",
    accessor: "foodcost_goal",
    Cell: ({ value: foodcost_goal }) => {
      if (foodcost_goal) {
        const { type, value, is_positive } = foodcost_goal;

        return (
          <span
            className="is-number"
            style={{ color: is_positive ? GREEN_COLOR : RED_COLOR }}
          >
            {type == GOAL_TYPE_PERCENTAGE
              ? `${dynamicToFixed(value)}%`
              : `₪${dynamicToFixed(value)}`}
          </span>
        );
      } else return "";
    },
  },
];
