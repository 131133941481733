import React, { useState, useEffect, useMemo } from "react";
import { FaExchangeAlt } from "react-icons/fa";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import Button from "../../../../../components/buttons/Button";
import DropdownSelect from "../../../../../components/dropdown/DropdownSelect";
import { fixDate, fixHour, getSafe, getTotalHours } from "../../../../../helpers";
import { setDialogModal } from "../../../../../redux/actions/DialogAction";
import {
  deleteTransfer,
  transferEmployee,
} from "../../../../../redux/actions/scheduleAction";
import icon from "../../../../../images/exchange-alt-solid.svg";

const TransferEmployee = ({
  event,
  branches,
  selected_branch,
  onAbort,
  deleteTransfer,
  transferEmployee,
  setDialogModal,
}) => {
  const {
    employee,
    working_date,
    actual_end,
    actual_start,
    has_transfer,
    transfer,
  } = event;

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);

  const renderError = useMemo(
    () => () => {
      if (error) {
        return (
          <p style={{ width: "100%", textAlign: "center" }}>
            <span className="error-msg">{error}</span>{" "}
          </p>
        );
      }
    },
    [error]
  );

  useEffect(() => {
    const list = branches.filter((x) => x.id !== selected_branch.id);
    setOptions(list);
    setSelectedBranch(
      has_transfer
        ? {
            id: transfer.destination_branch_id,
            name: transfer.destination_branch_name,
          }
        : { id: -99, name: "בחר סניף" }
    );
  }, [selected_branch, branches]);

  const start_time = fixHour(actual_start);
  const end_time = fixHour(actual_end);

  const handleConfirm = () => {
    if (selectedBranch.id === -99) return setError("חובה לבחור סניף");
    onAbort();
    setDialogModal({
      title: "העברת עובד",
      text: `האם להעביר את שעות העבודה של ${employee.name} בתאריך ${fixDate(
        working_date
      )} בין השעות ${end_time} - ${start_time} (סה"כ ${getTotalHours(
        start_time,
        end_time
      )} שעות) לסניף ${selectedBranch.name}?`,
      onConfirm: () => transferEmployee(event, selected_branch, selectedBranch),
      icon,
    });
  };

  const handleDelete = () => {
    onAbort();
    setDialogModal({
      title: "העברת עובד",
      text: `האם לבטל את העברת שעות העבודה של ${employee.name} בתאריך ${fixDate(
        working_date
      )} בין השעות ${end_time} - ${start_time} (סה"כ ${getTotalHours(
        start_time,
        end_time
      )} שעות) לסניף ${selectedBranch.name}?`,
      onConfirm: () => deleteTransfer(event),
      icon,
      btnConfirmClass: "color__danger--background",
    });
  };

  return (
    <div
      className={`flex-column-center transferEmployee ${
        has_transfer ? "transferEmployee--withDelete" : ""
      }`}
    >
      <h2>העברת עובד</h2>
      <div className="fluid margin-y">
        <DropdownSelect
          style={{ minWidth: "100%", maxWidth: "100%" }}
          selected={selectedBranch}
          setSelected={setSelectedBranch}
          options={options}
          touched={error}
          onTouched={() => setError(null)}
        />

        {renderError()}
      </div>
      <div style={{ width: "100%" }} className="flex">
        <Button className="btn--neutral" textValue="ביטול" onClick={onAbort} />
        {has_transfer ? (
          <Button
            className="btn--abort"
            textValue="מחיקת העברה"
            onClick={handleDelete}
          />
        ) : (
          <></>
        )}
        <Button
          className="btn--submit"
          textValue="אישור"
          onClick={handleConfirm}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { selected_branch } = state.branches;
  const branches = getSafe(()=>state.auth.user.network_branches,[])
  return { branches, selected_branch };
};
export default connect(mapStateToProps, {
  deleteTransfer,
  transferEmployee,
  setDialogModal,
})(TransferEmployee);
