import { is_israeli_id_number } from "../../../components/Forms/helpers";

// rules for react hook form
export const MAX_TEXT_LENGHT = 25;
export const maxTextError = `השדה מוגבל ל ${MAX_TEXT_LENGHT} תווים`;
export const requiredFieldError = "שדה חובה";
export const emailIsNotValidError = "דואר אלקטרוני לא תקין";
export const mobileNumberIsNotValidError = "טלפון סלולרי לא תקין";
export const MIN_TEXT_LENGTH = 6;
export const minTextError = `השדה צריך להכיל מינימום ${MIN_TEXT_LENGTH} תווים`;
export const passwordNotValidMessage =
  "על הסיסמה להכיל מינימום 6 תווים, לפחות אות 1 קטנה באנגלית, לפחות אות 1 גדולה באנגלית, לפחות ספרה 1";
export const idIsNotValidError = "תעודת זהות לא תקינה";

export const passwordValidation = (value) => {
  // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/;
  if (!passwordRegex.test(value)) return passwordNotValidMessage;
};

export const mobileNumberValidation = (value) => {
  const regex = /^\d{3}-?[2-9]\d{6}$/;
  if (value?.length && !regex.test(value)) return mobileNumberIsNotValidError;
};

export const emailValidation = (value) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!regex.test(value)) return emailIsNotValidError;
};

export const IDValidation = (value) => {
  return is_israeli_id_number(value) || idIsNotValidError;
};
