import React, {useEffect, useState } from "react";
import Axios from "axios";
import Button from "../../../components/buttons/Button";
import { BASE_URL } from "../../../config/auth";
import { useScreenSize } from "../../../Hooks";
import { isArray } from "lodash";
import Modal from "react-responsive-modal";
import { ClipLoader } from "react-spinners";
const PdfSendModal = ({
  onClose,
  printDate,
  data,
  departments,
  approve,
  token
}) => {
  const { isMobile } = useScreenSize()
  const [list, setList] = useState([]);
  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(false);
  //console.log(data);

  const handleChange = (e, value) => {
    if (isArray(value)) {
      if (list === value) setList([]);
      else setList(value);
    } else {
      if(value===-1 && !list.includes(value))
        setList([...Object.values(departments).map(({id})=> id) ,-1]);
      else if(value===-1 && list.includes(value))
        setList([]);
      else if (list.includes(value))
        setList(list.filter((x) => x !== value));
      else setList([...list, value]);
    }
    
  };
  const handleAbort = (e) => {
    //console.log("abort");
    onClose();
  };
  const handleApprove = async(e) => {
    setLoading(true);
    const parts =[];
    for(const d in data){
      if(list.length && list.includes(data[d].department_id)){
        parts.push(data[d]);
      }else{
        parts.push(data[d]);
      }
    }
    //console.log(parts);
    await Axios.post(
      `${BASE_URL}/api/htmlMailPdf`,
      {
        parts:parts,
        queue:true
      }
      ,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
    ).then((res) => {
      //console.log(res);
      setLoading(false);
    });
    onClose();
  };
  const handleConfirm = (e) => {
    setSend(true);
    approve(true);
  };
   useEffect(()=>{
    if(Object.values(departments).length)
      setList([...Object.values(departments).map(({id})=> id) ,-1]);
   },[departments]
   );

  return (
    <Modal
    open={true}
    closeOnOverlayClick={false}
    classNames={{ modal: "dialog-modal" }}
    styles={{
      modal: {
        maxHeight: "unset",
        textAlign: "center",
        padding: "2.5rem",
      },
    }}
    center
    onClose={onClose}
    animationDuration={200}
    >

{loading ? (
              <div style={{ height: "100%" }} className="flex-center">
                <ClipLoader size={60} color="#00a2ea" loading={true} />
              </div>
            ) : ( 
    
      (send) ? 
    (
      <div>
        האם לשלוח דוחות נוכחות במייל למחלקות הנבחרות עבור
      <div>
      <div>
        {printDate}   
      </div>
          </div>
          <Button
            onClick={handleAbort}
            className={`btn--neutral`}
            textValue={"ביטול"}
          />
            <Button
            onClick={handleApprove}
            className={`btn--submit`}
            textValue={"שליחה"}
          />
      </div>
    ):
    (
      <div>
                 
        <div style={{    marginTop: "35px"}} key={"9999"}>
         <label  className="checkbox--container">
           בחר/י הכל
           <input
             type="checkbox"
             value={-1}
             checked={list.includes(-1)}
             onChange={(e) => handleChange(e, -1)}
             key={-1}
           />
           <span className="checkbox--checkmark"></span>
         </label>
         
       </div>
        { 

              Object.values(departments).map((department, index) => (
                <div style={{    marginTop: "35px"}}>
                { (department?.name) ? (
                  <div key={index+"9999"}>
                  <label  className="checkbox--container">
                    {department.name}
                    <input
                      type="checkbox"
                      value={department.id}
                      checked={list.includes(department.id)}
                      onChange={(e) => handleChange(e, department.id)}
                      key={department.id}
                    />
                    <span className="checkbox--checkmark"></span>
                  </label>
                  
                </div>
                )
              :("")
              }
                      </div>
              ))
            }
            <div style={{    marginTop: "35px"}}>
            <Button
            onClick={handleAbort}
            className={`btn--neutral`}
            textValue={"ביטול"}
          />
            <Button
            onClick={handleConfirm}
            className={`btn--submit`}
            textValue={"אישור"}
          />
          </div>
      </div>
    )
    )
    }
   
   




    </Modal>
  );
};

export default PdfSendModal;
