import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";

export default function OnClickTooltip({ title, IconComponent }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
        >
          <IconButton onClick={handleTooltipOpen}>{IconComponent}</IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
