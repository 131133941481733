//dialogReducer.js

import { HIDE_DIALOG, SET_DIALOG } from "../actions/DialogAction/types";
import def_icon from "../../images/yellow-metro-warning.svg";

const INITIAL_STATE = {
  title: "",
  text: "",
  onConfirm: null,
  onAbort: null,
  icon: null,
  iconJSX: null,
  btnConfirmClass: null,
  btnAbortClass: null,
  btnConfirmText: "אישור",
  btnAbortText: "ביטול",
  isOpen: false,
  closeAfterConfirm: true,
  closeAfterThird: true,
  showThirdButton: false,
  showAbortButton: true,
  showConfirmButton: true,
  thirdButtonText: null,
  thirdButtonClass: null,
  thirdButtonAction: null,
  fourthButtonText: null,
  fourthButtonClass: null,
  fourthButtonAction: null,
  showFourthButton: false,
  closeAfterFourth: true,
  fourthButtonIcon: undefined,
  fifthButtonText: null,
  fifthButtonClass: null,
  fifthButtonAction: null,
  showFifthButton: false,
  closeAfterFifth: true,
  fifthButtonIcon: undefined,
  closeOnEsc: true,
  closeOnOverlayClick: true,
  showCloseIcon: true,
  maxWidthStyle: "50rem",
  no_scroll: false,
  thirdButtonIcon: undefined,
  customButton: undefined,
};

const dialogReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_DIALOG:
      return { ...payload, isOpen: true };
    case HIDE_DIALOG:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default dialogReducer;
