import { SET_DIALOG, HIDE_DIALOG } from "./types";

//set dialog
export const setDialogModal = (props) => (dispatch) => {
  dispatch({
    type: SET_DIALOG,
    payload: props,
  });
};

//hide dialog
export const hideDialog = () => (dispatch) => {
  dispatch({ type: HIDE_DIALOG });
};
