import { Footer } from "../../components/Model";
import {
  PriceCell,
  PriceFooter,
} from "../../components/tables/components/PriceFooter";
import { fixPercentage, fixPrice } from "../../helpers";
import { VAT_COEFFICIENT } from "../products/const";

export const BASE_URL = "dataAnalyze";

function calculateProfit(row) {
  return (
    row.total_income / VAT_COEFFICIENT -
    (row.total_combined_food_cost -
      row.operational_cost_received -
      row.management_cost_received -
      row.funding_cost_received)
  );
}
export const dataAnalyzeColumns = [
  {
    Header: "סניף",
    accessor: "branch_name",
    Cell: ({ row, value, branches }) => {
      if (value === "Unknown") {
        const branch = branches.find(
          (branch) => branch.id === row.original.branch_id
        );

        return branch.name;
      }
      return value;
    },
    Footer: () => "סה״כ",
  },
  {
    Header: "הכנסות ללא מע״מ",
    accessor: "total_income",
    Cell: ({ value }) => {
      return <span>{fixPrice(value)}</span>;
    },
    Footer: PriceFooter,
  },
  {
    Header: "הכנסות כולל מע״מ",
    accessor: "total_income_vat",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "Food cost",
    accessor: "total_combined_food_cost",
    Cell: (props) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {PriceCell(props)}
          <span>
            {fixPercentage(
              props.row.original.total_combined_food_cost_percentage,
              1
            )}
          </span>
        </div>
      );
    },

    Footer: PriceFooter,
  },
  {
    Header: "הוצאות עובדים",
    accessor: "employee_cost",
    Cell: (props) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {PriceCell(props)}
          <span>
            {fixPercentage(
              props.row.original.total_employee_cost_percentage,
              1
            )}
          </span>
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "הוצאות תפעול",
    accessor: "operational_cost_received",
    Cell: (props) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {PriceCell(props)}
          <span>
            {fixPercentage(
              props.row.original.operational_cost_received_percentage,
              1
            )}
          </span>
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "הנהלה וכלליות",
    accessor: "management_cost_received",
    Cell: (props) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {PriceCell(props)}
          <span>
            {fixPercentage(
              props.row.original.management_cost_received_percentage,
              1
            )}
          </span>
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "מימון ",
    accessor: "funding_cost_received",
    Cell: (props) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {PriceCell(props)}
          <span>
            {fixPercentage(
              props.row.original.funding_cost_received_percentage,
              1
            )}
          </span>
        </div>
      );
    },
    Footer: PriceFooter,
  },
  {
    Header: "רווח",
    accessor: "profit",
    Cell: (props) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {PriceCell(props)}
          <span>{fixPercentage(props.row.original.profit_percent, 1)}</span>
        </div>
      );
    },
    Footer: PriceFooter,
  },
];
