import styled from "styled-components";
// background: #f7f7f7;
const EllipsisButtonStyled = styled.button`
  border: none;
  border-radius: 100px;
  width: 4rem;
  height: 4rem;
  color: #747474;
  font-size: 2rem;
  margin: auto 0;
  outline: none;
`;

const EllipsisButton = ({
  onClick = () => {},
  backgroundColor = "#f7f7f7",
}) => {
  return (
    <EllipsisButtonStyled
      type="button"
      style={{ backgroundColor: backgroundColor }}
      className="btn--icon"
      onClick={() => onClick()}
    >
      <i className="fa fa-ellipsis-h"></i>
    </EllipsisButtonStyled>
  );
};
export default EllipsisButton;
