import React, { useEffect, useMemo, useRef, useState } from "react";
import { isArray, mapKeys } from "lodash";
import { compareString, getSafe } from "../../helpers";
import BtnTooltip from "../tooltips/BtnTooltip";
import { COLOR_ABORT } from "../../const/colors";
import { matchSorter } from "match-sorter";
import { ClickAwayListener } from "@mui/material";
import { Translate } from "react-redux-i18n";

const MultiSelectCheckbox = ({
  style,
  headerStyle = {},
  options = [],
  checked = [],
  setChecked,
  className = "",
  title = "0 סינונים פעילים",
  isSearchable = true,
  touched = false,
  onTouched,
  alerterStyle = {},
  sortByValue = false,
  onlyOne = false,
  isCollapse = false,
  limit = 0,
  label = null,
  disabled = false,
  outSideDivStyle,
  handleOutsideChangeProp = false,
  handleOutsideChange,
}) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // const [checked, setChecked] = useState([]);

  const [collapsed, setCollapsed] = useState(true);
  const dSelect = useRef("dSelect");

  const filterBySearch = useMemo(
    () => async (subString) => {
      const filterList = await matchSorter(options, subString, {
        keys: ["label"],
      });
      setList(
        filterList.length
          ? filterList
          : [{ label: "אפשרות לא קיימת", value: -999 }]
      );
    },
    [options]
  );

  useEffect(() => {
    setList([
      ...options.sort((a, b) =>
        sortByValue ? a.value - b.value : compareString(a.label, b.label)
      ),
    ]);
  }, [options, sortByValue]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    filterBySearch(value);
  };

  const options_obj = useMemo(() => mapKeys(options, "value"), [options]);
  const checked_obj = useMemo(() => mapKeys(checked), [checked]);

  const handleChange = (e, value) => {
    const handleOutsideChangeIfNeeded = (newValue) => {
      if (handleOutsideChangeProp) {
        handleOutsideChange(newValue);
      }
    };

    if (onlyOne) {
      const newValue = checked_obj[value] ? [] : [value];
      setChecked(newValue);
      handleOutsideChangeIfNeeded(newValue);
    } else if (Array.isArray(value)) {
      const newValue = checked === value ? [] : value;
      setChecked(newValue);
      handleOutsideChangeIfNeeded(newValue);
    } else {
      const newValue = !isNaN(checked_obj[value])
        ? checked.filter((x) => x !== value)
        : [...checked, value];
      setChecked(newValue);
      handleOutsideChangeIfNeeded(newValue);
    }
  };

  const onlyOneMemo = useMemo(
    () =>
      onlyOne ||
      (checked.length === 1 && checked[0]?.value === -999) ||
      options.length === 1 ||
      list.length === 1,
    [options, checked, onlyOne, list]
  );

  return options.length || disabled ? (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setShow(false);
          if (isCollapse) {
            setCollapsed(true);
          }
        }}
      >
        <div style={outSideDivStyle}>
          {label && (
            <div className="margin-left-right">
              <label>{label}</label>
            </div>
          )}
          <div
            style={style}
            className={`dropdown-select  ${className} ${
              collapsed && isCollapse ? "dropdown-select__collapsed" : ""
            }`}
          >
            <div
              ref={dSelect}
              className="dropdown-select--header"
              style={headerStyle}
              onClick={(e) => {
                e.stopPropagation();
                if (touched) {
                  onTouched();
                }
                if (isCollapse) {
                  setCollapsed(!collapsed);
                }

                setShow(!show);
              }}
            >
              {collapsed && isCollapse
                ? ""
                : checked.length > 1
                ? `${checked.length} סינונים פעילים`
                : checked.length === 1
                ? getSafe(() => options_obj[checked[0]].label, title)
                : title}
              <i className="fa fa-caret-down dropdown-select__icon"></i>
            </div>
            <div
              className={`dropdown-select--content ${
                show ? "dropdown-select--content__show" : ""
              }`}
              style={show ? { zIndex: 99 } : {}}
            >
              {isSearchable && options.length !== 1 ? (
                <div className="dropdown-select--option__search">
                  <input
                    className="dropdown-select--search"
                    type="search"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    name="search"
                    value={searchValue}
                    onChange={(e) => handleSearchChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        const found = options.find(
                          (option) => option.label === e.target.value
                        );
                        if (found) {
                          handleChange(null, found.value);
                          setSearchValue("");
                          setList(options);
                        }
                      }
                    }}
                  />
                  <i className="fa fa-search"></i>
                </div>
              ) : (
                <></>
              )}
              {onlyOneMemo || searchValue || limit > 0 ? (
                ""
              ) : (
                <label className=" dropdown-select--checkbox">
                  {disabled ? (
                    <>
                      <span className="padding-8">לא נמצא {title}</span>
                    </>
                  ) : (
                    <>
                      <input
                        type="checkbox"
                        value={checked.length === list.length}
                        checked={checked.length === list.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setChecked([...list.map((x) => x.value)]);
                            if (handleOutsideChangeProp) {
                              handleOutsideChange([
                                ...list.map((x) => x.value),
                              ]);
                            }
                          } else {
                            setChecked([]);
                            if (handleOutsideChangeProp) {
                              handleOutsideChange([]);
                            }
                          }
                        }}
                      />
                      <span className="dropdown-select--checkmark"></span>

                      <span className="u-right-text">
                        {checked.length === options.length
                          ? "הסר הכל"
                          : "סמן הכל"}
                      </span>
                    </>
                  )}
                </label>
              )}
              {list.map((option, index) => {
                const checked_input = !isNaN(checked_obj[option.value]);
                const disabled =
                  limit > 0 && limit === checked.length && !checked_input;
                return option.value === -999 ? (
                  <label key={option.value}>{option.label}</label>
                ) : (
                  <BtnTooltip
                    key={option.value}
                    textColor={COLOR_ABORT}
                    placement="left"
                    hide={!disabled}
                    text={`הבחירה מוגבלת עד ${limit} ${title}`}
                  >
                    <label className=" dropdown-select--checkbox">
                      <input
                        disabled={disabled}
                        type="checkbox"
                        value={option.value}
                        checked={checked_input}
                        onChange={(e) => handleChange(e, option.value)}
                      />
                      <span className="dropdown-select--checkmark"></span>

                      <span className="u-right-text">
                        <span> {option.label || ""}</span>
                        {/* {<Translate value={option.label || ""} />} */}
                      </span>
                    </label>
                  </BtnTooltip>
                );
              })}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </>
  ) : (
    <></>
  );
};

export default MultiSelectCheckbox;
