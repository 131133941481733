import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import Modal from "react-responsive-modal";
import ReactTable from "../../../components/tables/ReactTable";
import { manualHoursTable } from "./tableColumns";
import EditShiftHours from "../../schedule/manager/table/models/EditShiftHours";
import { dateReqFormat } from "../../../helpers";
import Axios from "axios";
import Logs from "../../schedule/manager/table/models/Logs";

// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const ManualHoursModal = ({
  data,
  setOpen,
  trigerAnomaliesFetchData,
  user,
  token,
  date,
  type,
  checkedBranches,
  isManager,
  selected_branch,
  handleSubmit,
  deleteEvent,
  checkedEmployees,
  checkedDepartments,
  openUnvalidShiftReport,
  setOpenUnvalidShiftReport,
  setIsHaveAnomaliesInReport,
  logout,
  setDialogModal,
  startLoading,
  stopLoading,
  setAttendanceAnomaliesErrors,
  attendanceAnomaliesErrors,
}) => {
  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  // ======== STATES ========
  const [eventToEdit, setEventToEdit] = useState(null);
  const [showLogsModal, setShowModal] = useState(null);

  // ======== HANDLERS ========
  const updateTable = async (event, remove_id = null) => {
    setAttendanceAnomaliesErrors((prev) =>
      prev.filter((employee) => employee.id !== remove_id)
    );
    setEventToEdit(null);
  };

  const fetchData = useMemo(
    () => async () => {
      const route =
        type === "salary_by_identifier" ? "multipleAnomalies" : "anomalies";
      const startDate = dateReqFormat(date.start);
      const endDate = dateReqFormat(date.end);
      try {
        const response = await Axios.get(`reports/employees/${route}`, {
          params: {
            branch:
              type === "salary_by_identifier"
                ? checkedBranches
                : isManager
                ? selected_branch.id
                : user.branch_id,
            startDate, //required
            endDate, //required
            department_ids: isManager ? checkedDepartments : null,
            employee_ids: isManager ? checkedEmployees : [user.id],
            show_all_branches: false,
          },
          headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
      } catch (e) {
        switch (e?.response?.status) {
          case 401:
            logout();
            break;
          default:
            break;
        }
        return [];
      }
    },
    [date, user, token]
  );
  // ======== CONSTANTS ========

  // ======== EFFECTS ========
  const manualHoursData = useMemo(() => {
    let reducedData = data.reduce((accumulator, employee) => {
      // Concatenate the bodies of employees with manual start or end times
      return accumulator.concat(employee.body);
    }, []);

    return reducedData.filter((row) => row.manual_start || row.manual_end);
  }, [data]);

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <>
      <Modal
        open={!!(eventToEdit != null)}
        onClose={() => setEventToEdit(null)}
        styles={{
          modal: {
            width: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        center
      >
        {!!eventToEdit && (
          <EditShiftHours
            style={{ boxShadow: "none" }}
            saveToReport={true}
            handleOpen={setEventToEdit}
            event={eventToEdit}
            updateTable={updateTable}
            validateChanges={true}
          />
        )}
      </Modal>
      <Modal
        open={true}
        closeOnOverlayClick={false}
        classNames={{ modal: "dialog-modal" }}
        styles={{
          modal: {
            padding: "3.5rem",
            maxWidth: "fit-content",
            minWidth: "60rem",
          },
        }}
        center
        onClose={() => {
          setOpen(false);
        }}
        animationDuration={200}
      >
        <div>
          <div>
            <h2>עובדים עם תיקון שעות ידני (תיקון מנהל)</h2>
          </div>
          <ReactTable
            styles={{
              wrapper: { maxHeight: "calc(100vh - 10rem)" },
              container: { maxHeight: "calc(100vh - 50rem)" },
            }}
            cellsPropsById={(id) => {
              return {
                setEventToEdit,
                setShowModal,
              };
            }}
            alignToCenter
            isPaginate={true}
            isExportedToExcel={isMobile ? false : true}
            data={manualHoursData}
            columns={manualHoursTable}
            sortBy={[{ id: "working_date" }]}
          />
        </div>
      </Modal>

      {showLogsModal?.logs?.length > 0 && (
        <Modal
          animationDuration={0}
          center
          open={showLogsModal.openModal}
          onClose={() => setShowModal(null)}
        >
          <Logs logs={showLogsModal.logs} />
        </Modal>
      )}
    </>
  );
};

export default ManualHoursModal;
