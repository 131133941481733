import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import SelectComponent from "../../../components/Forms/inputs/Select";
import FormCheckBox from "../../../components/Forms/inputs/FormCheckBox";
import moment from "moment";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";

const PART_EMPLOYEE_NAME = 0;
const PART_TYPE_IDENTIFER = 1;
const PART_WHEN_SUMMONS_DATE = 2;
const PART_DATE = 3;
const PART_OTHER_PARTICIPANTS = 4;
const PART_SUBJECT = 5;
const PART_MAIN_CONTENT = 6;
const PART_HOUR_SUMMON = 7;
const PART_PLACE_TAKEN = 8;
const PART_TYPE_EMPLOYEE_TYPE_TITLE = 9;
const INPUT = "שדה";

const REQUIRED_CHECKBOX = {
  name: "required",
  component: FormCheckBox,
  label: "שדה חובה",
  defaultValue: true,
};

const INPUT_LABEL_FIELD = (defaultValue = "") => ({
  name: "label",
  component: FormTextField,
  label: "כותרת השדה",
  defaultValue,
  rules: { required: REQUIRED_MESSAGE },
});

const PARTS = {
  [PART_EMPLOYEE_NAME]: {
    label: "שם העובד",
    defaultValue: () => "שם העובד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("שם העובד"),
      {
        name: "value",
        component: FormTextField,
        disabled: true,
      },
    ],
    show: {
      rules: { required: REQUIRED_MESSAGE },
      component: ControlAutoComplete,
      size: "small",
      inputProps: { label: "שם העובד" },
    },
  },
  [PART_TYPE_EMPLOYEE_TYPE_TITLE]: {
    label: "תפקיד",
    defaultValue: () => "תפקיד",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תפקיד"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "תפקיד",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      disabled: true,
    },
  },
  [PART_TYPE_IDENTIFER]: {
    label: "ת.ז.",
    defaultValue: () => "ת.ז.",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("ת.ז."),
      {
        name: "value",
        component: FormTextField,
        placeholder: "ת.ז.",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      type: "text",
      fullWidth: true,

      rules: {
        pattern: {
          value: new RegExp(/^\d{0,9}$/),
          message: "מספר ת.ז. לא תקין יש לנסות שוב",
        },
      },
    },
  },
  [PART_OTHER_PARTICIPANTS]: {
    label: "השימוע יערך בפני ",
    defaultValue: () => "השימוע יערך בפני ",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("השימוע יערך בפני "),
      {
        name: "value",
        component: FormTextField,
        placeholder: "השימוע יערך בפני ",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_DATE]: {
    label: "תאריך שליחת המסמך",
    defaultValue: () => "תאריך שליחת המסמך",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך שליחת המסמך"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        placeholder: "תאריך שליחת המסמך",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_WHEN_SUMMONS_DATE]: {
    label: "תאריך השימוע",
    defaultValue: () => "תאריך השימוע",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("תאריך השימוע"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,

        placeholder: "תאריך השימוע",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_HOUR_SUMMON]: {
    label: "שעת השימוע ",
    defaultValue: () => "שעת השימוע",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD(" שעת השימוע"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,
        placeholder: " שעת השימוע",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
  [PART_SUBJECT]: {
    label: "הנדון",
    defaultValue: () => "הנדון",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("הנדון"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "הנדון",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 3,
      fullWidth: true,
    },
  },
  [PART_MAIN_CONTENT]: {
    label: "סיבה לשקילת פיטורין",
    defaultValue: () => "סיבה לשקילת פיטורין",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD("סיבה לשקילת פיטורין"),
      {
        name: "value",
        component: FormTextField,
        placeholder: "סיבה לשקילת פיטורין",
        disabled: true,
      },
    ],
    show: {
      component: FormTextField,
      multiline: true,
      rows: 9,
      fullWidth: true,
    },
  },
  [PART_PLACE_TAKEN]: {
    label: "מיקום השימוע ",
    defaultValue: () => "מיקום השימוע",
    extraData: [
      REQUIRED_CHECKBOX,
      INPUT_LABEL_FIELD(" מיקום השימוע"),
      {
        name: "value",
        component: FormTextField,
        fullWidth: true,
        placeholder: " מיקום השימוע",
        disabled: true,
      },
    ],
    show: { component: FormTextField, type: "date", fullWidth: true },
  },
};
export {
  PARTS,
  PART_EMPLOYEE_NAME,
  INPUT,
  PART_WHEN_SUMMONS_DATE,
  PART_HOUR_SUMMON,
  PART_TYPE_IDENTIFER,
  PART_DATE,
  PART_OTHER_PARTICIPANTS,
  PART_SUBJECT,
  PART_MAIN_CONTENT,
  PART_PLACE_TAKEN,
  PART_TYPE_EMPLOYEE_TYPE_TITLE,
};
