import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import Button from "../../../../components/buttons/Button";
import { isArray, mapKeys } from "lodash";
import BtnTooltip from "../../../../components/tooltips/BtnTooltip";
import { Collapse, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { sendDaySmsToDepartments } from "../../../../redux/actions/smsAction";
import { MdOutlineSendToMobile } from "react-icons/md";
import Axios from "axios";
import { FaEnvelope } from "react-icons/fa";

const SmsByDay = ({
  branch_id,
  branch_name = null,
  sendDaySmsToDepartments,
  token,
  day,
  events,
}) => {
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(`עדכון מ${branch_name} `);
  const [smsRemainCount, setSmsRemainCount] = useState(0);

  const fetchCurrentRemainCount = useMemo(
    () => async () => {
      if (!branch_id || !token) {
        return;
      }
      try {
        if (!token) return;
        const { data } = await Axios.get(
          `branches/${branch_id}/getBranchSmsBank`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSmsRemainCount(data.remain_count);
      } catch (e) {
        console.error(e);
      }
    },
    [branch_id, token]
  );

  useEffect(() => {
    if (open) {
      fetchCurrentRemainCount();
    }
  }, [fetchCurrentRemainCount, open]);

  const eventsArray = useMemo(
    () =>
      Object.values(events).filter(
        ({week_day,employee}) =>{
            
             return employee && week_day === day.day_num
        }
      ),
    [events, day]
  );

  const departments = useMemo(() => {
    let departments = mapKeys(eventsArray, "department.id");
    departments = Object.values(departments);
    return departments.map((item) => item.department);
  }, [eventsArray]);

  const dep_ids = useMemo(() => departments.map((x) => x.id), [departments]);
  const checked_obj = useMemo(() => mapKeys(checked), [checked]);

  const handleChange = (e, value) => {
    if (isArray(value)) {
      if (checked === value) setChecked([]);
      else setChecked(value);
    } else {
      if (checked_obj[value]) setChecked(checked.filter((x) => x !== value));
      else setChecked([...checked, value]);
    }
  };
  const textAreaRef = useRef();

  const handleSubmit = () => {
    const employee_ids = eventsArray
      .filter(({ department }) => checked_obj[department?.id])
      .map(({ employee }) => employee.id);
    sendDaySmsToDepartments({
      employee_ids,
      text,
    });
    setOpen(false);
  };

  return (
    <>
      <Modal
        animationDuration={0}
        styles={{
          modal: { maxWidth: "50rem", padding: "3.5rem", textAlign: "center" },
        }}
        center
        open={open}
        onClose={() => setOpen(false)}
      >
        <h1>{`שליחת SMS לשיבוצים ב${day.day_name} ${day.date}`}</h1>
        <h2>בחר\י לאיזה מחלקות לשלוח את הSMS</h2>
        <p>רק מחלקות להן קיים שיבוץ יופיעו</p>
        <div
          className="checkbox"
          style={{
            border: "2px solid  #EAEAEA",
            borderRadius: "5px",
            padding: "1rem",
          }}
        >
          <label className="checkbox--container">
            שלח לכולם
            <input
              type="checkbox"
              value={dep_ids}
              checked={checked.length === dep_ids.length}
              onChange={(e) => handleChange(e, dep_ids)}
            />
            <span className="checkbox--checkmark"></span>
          </label>
          {departments.map((department, index) => (
            <label key={index} className="checkbox--container">
              {department.name}
              <input
                type="checkbox"
                value={department.id}
                checked={checked_obj[department.id]}
                onChange={(e) => handleChange(e, department.id)}
              />
              <span className="checkbox--checkmark"></span>
            </label>
          ))}
        </div>
        <div style={{ fontSize: "1.5rem" }} className="strong">
          כמות SMS עדכנית לשימוש: {smsRemainCount}
        </div>
        <Collapse in={checked.length > 0} timeout="auto" unmountOnExit>
          <div
            style={{
              margin: "0 auto",
              marginTop: "2rem",
            }}
          >
            <h3>תוכן ההודעה</h3>
            <textarea
              ref={textAreaRef}
              onFocus={(e) => {
                e.preventDefault();
                const { selectionStart, selectionEnd } = e.target;
                textAreaRef.current.selectionStart = selectionEnd + text.length;
              }}
              maxLength={160}
              style={{ border: "1px solid #ccc" }}
              className="textarea-white"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            ></textarea>
            <p>{`${text.length}/160 תווים`}</p>
          </div>
        </Collapse>

        <div
          style={{ width: "fit-content", margin: "0 auto", marginTop: "2rem" }}
          className="flex"
        >
          <Button
            onClick={() => setOpen(false)}
            className="btn--neutral"
            textValue="ביטול"
          />
          <Button
            onClick={handleSubmit}
            className="btn--submit"
            textValue="שלח"
          />
        </div>
      </Modal>
      <BtnTooltip placement="top" text="שליחת SMS מחלקות">
        <button
          type="button"
          style={{ margin: "0" }}
          className={`btn btn--none padding-0 fit-content`}
          onClick={() => setOpen(true)}
        >
          <FaEnvelope size="1.4em" />
        </button>
      </BtnTooltip>
    </>
  );
};
const mapStateToProps = (state) => {
  const { token } = state.auth;
  const { selected_branch } = state.branches;
  const { events } = state.schedule;

  return {
    token,
    events,
    branch_id: selected_branch.id,
    branch_name: selected_branch.name,
  };
};
export default connect(mapStateToProps, { sendDaySmsToDepartments })(SmsByDay);
