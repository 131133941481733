import {
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import React, { useEffect, useMemo, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { debounce, isObject, isString } from "lodash-es";
import Axios from "axios";
import { connect } from "react-redux";
import { decodeHtml, getSafe } from "../../../helpers";
import { logout } from "../../../redux/actions/loginAction";
import { setError } from "../../../redux/actions/errorAction";

//for Autocomplete element
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoCompleteSearch = ({ token, setError, logout, setSelectedItems }) => {
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [autocompleteSelectedItems, setAutocompleteSelectedItems] = useState(
    []
  );
  const [loading, setLoading] = useState(false);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item.name} ${item.part_number} `,
  });

  const handleAutoCompleteChange = (items) => {
    const selected = items.filter((item) => isObject(item));
    setAutocompleteSelectedItems(selected);
    setSelectedItems(selected);
  };

  const handleAutoCompleteInputChange = (searchValue) => {
    if (!isString(searchValue)) {
      return;
    }
    if (searchValue.trim().length >= 3) {
      fetchAutoCompleteOptions(searchValue);
    }
  };

  useEffect(() => {
    if (!autoCompleteOpen) {
      setAutoCompleteOptions([]);
    }
  }, [autoCompleteOpen]);

  const fetchAutoCompleteOptions = useMemo(
    () =>
      debounce(async (search_item) => {
        setLoading(true);
        try {
          const response = await Axios.get("v2/items/freeSearch", {
            params: {
              search_item,
              excludeDummyItems: true,
              excludeItems: autocompleteSelectedItems.map((item) => item.id),
            },
            headers: { Authorization: `Bearer ${token}` },
          });
          const { data } = response;
          setAutoCompleteOptions(data);
        } catch (e) {
          console.error(e);
          if (e.response)
            if (e.response.status === 401) {
              logout();
            } else if (e.response.status === 500) {
              setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
            }
        }
        setLoading(false);
      }, 500),
    [token, autocompleteSelectedItems, logout, setError]
  );

  return (
    <Autocomplete
      freeSolo
      multiple
      value={autocompleteSelectedItems}
      onChange={(option, value) => {
        handleAutoCompleteChange(value);
      }}
      open={autoCompleteOpen}
      onOpen={() => {
        setAutoCompleteOpen(true);
      }}
      onClose={() => {
        setAutoCompleteOpen(false);
      }}
      clearOnBlur={false}
      loading={loading}
      loadingText="טוען..."
      onInputChange={(e) =>
        handleAutoCompleteInputChange(getSafe(() => e.target.value, ""))
      }
      filterOptions={filterOptions}
      options={autoCompleteOptions}
      noOptionsText={"לא נמצאו מוצרים"}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <div {...props}>
          <Checkbox
            inputProps={{ style: { position: "absolute", right: 0 } }}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginLeft: 8 }}
            checked={autocompleteSelectedItems.includes(option)}
          />
          {decodeHtml(
            `${option.name} (${option.part_number}) - ${option.supplier_name}`
          )}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="ניתן לחפש מוצרים לפי שם או מק״ט. להתחלת החיפוש יש ללחוץ על ״אישור״"
          placeholder="חפש מוצר"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { token } = state.auth;
  return { token };
};

export default connect(mapStateToProps, { setError, logout })(
  AutoCompleteSearch
);
