import React from "react";
import {
  fixDate,
  fixDateHours,
  fixPrice,
  fixPriceAndNothing,
  fixPercentage,
  thousandSeparator,
} from "../../../helpers";
import { sumFooter } from "../helpers";

export const PriceFooter = ({ rows, column }) => {
  const total = sumFooter(rows, column.id);
  return <div className="money">{fixPrice(total)}</div>;
};
export const PriceFooterOneAfterDot = ({ rows, column }) => {
  const total = sumFooter(rows, column.id);
  return <div className="money">{fixPrice(total, 1)}</div>;
};
export const PercentageFooter = ({ rows, column }) => {
  const total = sumFooter(rows, column.id);
  return <div className="is-number">{fixPercentage(total, 2)}</div>;
};

export const CountFooter = ({ rows, column }) => {
  const total = sumFooter(rows, column.id);
  return <div className="money">{thousandSeparator(total)}</div>;
};
export const CountFooterNum = ({ rows, column }) => {
  const total = sumFooter(rows, column.id);
  return <div className="money">{Number(total).toFixed(2)}</div>;
};
export const PriceCell = ({ row, column }, fixed = 0, round = true) => {
  return (
    <span className="is-number">
      {fixPrice(row.original[column.id], fixed, round)}
    </span>
  );
};

export const PriceCellOneAfterDot = ({ row, column }) => {
  return (
    <span className="is-number">
      {row.original.id
        ? fixPrice(row.original[column.id])
        : fixPriceAndNothing(row.original[column.id], "")}
    </span>
  );
};

export const PercentageCell = ({ row, column }) => {
  return (
    <span className="is-number">
      {fixPercentage(row.original[column.id], 2)}
    </span>
  );
};

export const PriceCellDouble = ({ row, column }) => {
  return (
    <span className="is-number">{fixPrice(row.original[column.id], 2)}</span>
  );
};

export const DateCell = ({ row, column }) => fixDate(row.original[column.id]);
export const DateHouerCell = ({ row, column }) =>
  fixDateHours(row.original[column.id]);
