import { Link, Tooltip } from "@mui/material";
import {
  CountFooter,
  PercentageCell,
  PercentageFooter,
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";

import {
  dynamicToFixed,
  fixPercentage,
  fixPrice,
  getSafeDivision,
  thousandSeparator,
} from "../../../helpers";
import { FcHeader } from "../const";

export const getData = (
  by_code //raw data
) => {
  return by_code.map((dish) => {
    const { product_code, name, link, amount, total, worth } = dish;

    const avg_price = getSafeDivision(total, amount);
    const avg_foodcost = getSafeDivision(worth, total) * 100;

    return {
      product_code,
      name,
      link,
      amount,
      total_with_vat: total,
      avg_price,
      worth,
      avg_foodcost,
    };
  });
};

export const excelRowConfig = (row) => {
  row.link = row?.link?.name || "לא מקושר";

  row.amount = dynamicToFixed(row.amount);
  row.total_with_vat = dynamicToFixed(row.total_with_vat);
  row.worth = dynamicToFixed(row.worth);
  row.avg_price = dynamicToFixed(row.avg_price);
  row.avg_foodcost = fixPercentage(row.avg_foodcost, 2);

  return row;
};

export const aggregates = [
  { field: "amount", aggregate: "sum" },
  { field: "total_with_vat", aggregate: "sum" },
  { field: "worth", aggregate: "sum" },
  { field: "avg_price", aggregate: "sum" },
];

export const sortBy = [{ id: "avg_foodcost", desc: true }];

export const columns = [
  {
    Header: "קוד קופה",
    accessor: "product_code",
    Footer: () => "סה״כ",
  },
  {
    Header: "שם",
    accessor: "name",
  },
  {
    Header: "מוצר מקושר",
    accessor: "link",
    Cell: ({ value }) => {
      if (!value) return "לא מקושר";
      const { name, category_name, supplier_name, sale_price_with_vat } = value;

      const toolTipRows = [
        `מחיר מכירה: ${fixPrice(sale_price_with_vat, 1, false)}`,
        `קטגוריה: ${category_name}`,
      ];

      if (supplier_name) toolTipRows.push(`ספק: ${supplier_name}`);

      return (
        <Tooltip
          title={
            <div className="flex-column-right" style={{ fontSize: "1.5rem" }}>
              {toolTipRows.map((row, key) => (
                <span {...{ key }}>{row}</span>
              ))}
            </div>
          }
          placement="top-start"
        >
          <Link
            component="span"
            sx={{
              fontSize: "1.6rem",
            }}
          >
            {name}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    Header: "כמות שנמכרה",
    accessor: "amount",
    Cell: ({ value }) => (
      <span className="is-number">{thousandSeparator(value)}</span>
    ),
    Footer: CountFooter,
  },
  {
    Header: "מכירות כולל מע״מ",
    accessor: "total_with_vat",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "עלות רכיבים",
    accessor: "worth",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "מחיר מנה",
    accessor: "avg_price",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: FcHeader,
    accessor: "avg_foodcost",
    Cell: PercentageCell,
  },
];
