import React, { useState } from "react";
import Model, {
  Header,
  Content,
  Footer,
} from "../../../../../components/Model";
import Button from "../../../../../components/buttons/Button";
import { connect } from "react-redux";
import { addNewAssignFromReport } from "../../../../../redux/actions/scheduleAction";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import he from "date-fns/locale/he";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import HoursInput from "../../../../../components/inputs/HoursInput";
import { isEmpty, isUndefined } from "lodash";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  dateReqFormat,
  fixDate,
  getSafe,
  getTotalHours,
} from "../../../../../helpers";
import { CANT_EDIT_SELF_PRIVILEGE } from "../../../../../const/privilegesButtons";
import DayPicker from "../../../../../components/datepicker/DayPicker";
import Dropdown from "../../../../../components/dropdown/Dropdown";
import { TextField, useMediaQuery, useTheme } from "@mui/material";

const AddNewAssign = ({
  style,
  handleOpen,
  saveToReport = false,
  employee,
  updateTable = null,
  cant_edit_self_id,
  addNewAssignFromReport,
}) => {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [date, setDate] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const validate = () => {
    const errors = {};
    if (employee && employee.id === cant_edit_self_id) {
      errors.self = "לא ניתן לעדכן שעות באופן עצמאי";
    }
    if (end === start && end && start) errors.hours = "שעת התחלה וסיום זהות";
    if (!start) errors.hours = "חובה להכניס שעת כניסה";
    if (!end) errors.hours = "חובה להכניס שעת כניסה";
    if (!date) errors.hours = "חובה להכניס תאריך";
    setFormErrors(errors);
    if (!isEmpty(errors)) return false;
    else return true;
  };
  const renderError = (name) => {
    if (!isUndefined(formErrors[name]))
      return (
        <p style={{ width: "100%", textAlign: "center" }}>
          <span className="error-msg">{formErrors[name]}</span>{" "}
        </p>
      );
  };

  const submit = () => {
    if (validate()) {
      addNewAssignFromReport({
        start,
        end,
        date: dateReqFormat(date),
        employee_id: employee.id,
        handleOpen,
        saveToReport,
        updateTable,
      });
    }
  };

  return (
    <Model style={style}>
      <Header>
        <h3>יצירת שיבוץ חדש</h3>
        <p style={{ fontWeight: "500" }}>{getSafe(() => employee.name, "")}</p>
      </Header>
      <Dropdown
     
        text={`${date ? fixDate(date) : "בחר תאריך"}`}
      >
        <DayPicker setDate={setDate} afterDate={new Date()} />
      </Dropdown>

      <div style={{ margin: "0.5rem 0" }} className="flex-even ">
        <h3>יציאה</h3>
        <h3>כניסה</h3>
      </div>

      <Content className={`model--edit-hours left-to-right `}>
        <div className="flex-even " style={{ width: "20rem" }}>
          <div>
            <HoursInput
              errors={formErrors}
              setErrors={setFormErrors}
              getClock={setStart}
            />
          </div>
          <div>
            <HoursInput
              errors={formErrors}
              setErrors={setFormErrors}
              getClock={setEnd}
            />
          </div>
        </div>
      </Content>
      <Content>
        <label>{`סה"כ שעות: ${getTotalHours(start, end)}`}</label>
      </Content>
      {renderError("hours")}

      <Footer
        style={{ borderTop: "solid 1px #EAEAEA" }}
        className={"model--footer"}
      >
        <Button
          className="btn--neutral"
          onClick={() => handleOpen(null)}
          textValue="cancel"
        />
        <Button className="btn--submit" onClick={submit} textValue="save" />
        {renderError("self")}
      </Footer>
    </Model>
  );
};
const mapStateToProps = (state) => {
  const { privileges_buttons } = state.user_privileges;
  const { user } = state.auth;

  const cant_edit_self_id = privileges_buttons.includes(
    CANT_EDIT_SELF_PRIVILEGE
  )
    ? user.id
    : null;

  return {
    cant_edit_self_id,
  };
};

export default connect(mapStateToProps, { addNewAssignFromReport })(
  AddNewAssign
);
