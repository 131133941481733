import { decodeHtml, fixDate, fixDateHours } from "../../../helpers";
import validator from "validator";
import { forIn } from "lodash";

const DATE_HOURS_FORMAT =
  /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;

export const sumFooter = (rows, key) =>
  rows.reduce(
    (sum, row) =>
      row.depth ? sum : (row.original[key] ? row.original[key] : 0) + sum,
    0
  );

export const prepareValueColumn = (
  value,
  key,
  costumeValuePreparation = {},
  row = {}
) => {
  if (costumeValuePreparation[key]) {
    return costumeValuePreparation[key](row);
  }
  const type = typeof value;
  switch (type) {
    case "string": {
      if (validator.isNumeric(value)) {
        return Number(Number(value).toFixed(2));
      }
      return decodeHtml(value);
    }
    default: {
      if (value instanceof Date) {
        return fixDate(value);
      } else if (value instanceof Object && key === "subRows") {
        return arrangeData(value);
      } else if (value instanceof Object) {
        return forIn(
          value,
          (obj_value, obj_key, object) =>
            (object[obj_key] = prepareValueColumn(
              obj_value,
              obj_key,
              costumeValuePreparation,
              object
            ))
        );
      }
      return value;
    }
  }
};

export const arrangeData = (
  data,
  costumeValuePreparation,
  newValuePreparation
) => {
  return Array.isArray(data)
    ? data.map((row) => {
        let newRow = forIn(
          row,
          (value, key, object) =>
            (object[key] = prepareValueColumn(
              value,
              key,
              costumeValuePreparation,
              object
            ))
        );
        forIn(newValuePreparation, (func, key, object) => {
          // console.log(func(row));
          newRow[key] = func(row);
        });
        return newRow;
      })
    : [];
};
