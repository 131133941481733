import Axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Printer } from "react-feather";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { BounceLoader } from "react-spinners";
import NewButton from "../../../components/buttons/NewButton";
import ReactTable from "../../../components/tables/ReactTable";
import { fixDate, fixDateHours, printView } from "../../../helpers";
import { setError } from "../../../redux/actions/errorAction";
import { logout } from "../../../redux/actions/loginAction";
import { items_columns } from "../../orders/manageOrders/itemColumns";

const ViewOrder = ({ order_id, token, logout, setError, onClose }) => {
  const [order, setOrder] = useState(null);
  const [loader, setLoader] = useState(true);

  const item_columns_memo = useMemo(() => items_columns, []);
  const fetchOrder = useMemo(
    () => async (order_id) => {
      try {
        setLoader(true);
        const res = await Axios.get(`newOrder/${order_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const { data } = res;
        setOrder(data);
        setLoader(false);
      } catch (e) {
        setLoader(false);
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logout();
          } else if (e.response.status === 500) {
            setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
          }
      }
    },
    [logout, token, setError]
  );

  useEffect(() => {
    if (order_id) {
      fetchOrder(order_id);
    }
  }, [order_id, fetchOrder]);

  const printOrder = async () => {
    try {
      const res = await Axios.post(
        `newOrder/${order.id}/printOrder`,
        { new_order_id: order.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      printView(res.data);
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          logout();
        } else if (e.response.status === 500) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
        }
    }
  };

  return (
    <Modal
      animationDuration={200}
      styles={{
        modal: {
          padding: "3.5rem",
          maxWidth: "fit-content",
          minWidth: "60rem",
        },
      }}
      open={order_id}
      center
      onClose={onClose}
    >
      {order && !loader ? (
        <div className="flex-column-center">
          <div className="fluid">
            <h2>{`צפייה בהזמנה מס': ${order.id}`}</h2>
            <p className="paragraph fluid u-right-text">{`שם הספק: ${order.supplier_name}`}</p>
            <p className="paragraph fluid u-right-text">{`סניף שולח: ${order.branch_name}`}</p>{" "}
          </div>

          <p className="paragraph fluid u-right-text">{`תאריך הזמנה: ${
            order.sent_at ? fixDate(order.sent_at) : "ללא"
          }`}</p>
          <p className="paragraph fluid u-right-text">{`הערות: ${
            order.notes ? order.notes : " ללא הערות"
          }`}</p>

          <ReactTable
            {...{
              footersPropsById: (id) => {
                if (["total_price"].includes(id)) {
                  return {
                    show_price: true,
                  };
                }
              },
              cellsPropsById: (id) => {
                if (["total_price", "price"].includes(id)) {
                  return {
                    show_price: true,
                  };
                }
              },
              headersPropsById: (id) => {
                if (["total_price", "price"].includes(id)) {
                  return {
                    show_price: true,
                  };
                }
              },
              styles: {
                wrapper: { maxHeight: "calc(100vh - 10rem)" },
                container: { maxHeight: "calc(100vh - 23.4rem)" },
              },
              translation_endpoint: "itemsTable",
              title: `רשימת מוצרים הזמנה ${order.id}`,
              columns: item_columns_memo,
              data: order.items,
              isExportedToExcel: false,
              showFooter: true,
              showCheckbox: false,
              sortBy: [{ id: "name" }],
            }}
          />

          <p className="paragraph">{`עודכן ע"י: ${order.user} ב-${fixDateHours(
            order.updated_at
          )}`}</p>

          <NewButton
            icon={<Printer size={16} />}
            textValue={"הדפסת הזמנה"}
            onClick={() => printOrder()}
          />
        </div>
      ) : (
        <div style={{ minHeight: "20rem" }} className="flex-center">
          <BounceLoader color="#00a2ea" loading={loader} />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { token } = state.auth;
  return { token };
};

export default connect(mapStateToProps, { logout, setError })(ViewOrder);
