import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

// ======== HOOKS ========
import moment from "moment";
// ======== ICONS ========

// ======== CONSTANTS ========

// ======== COMPONENTS ========

export const WorkTerminatinoShow = (props) => {
  // ======== HOOKS ========

  // ======== STATES ========

  // ======== HANDLERS ========

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>
            הנדון:{" "}
            <span>
              {props.employee_name ? props.employee_name.split(",")[0] : ""}
            </span>{" "}
            מכתב פיטורים לאחר שימוע - סיום העסקה
          </h1>
          <p>
            <strong>תאריך : </strong> {moment(new Date()).format("DD-MM-YYYY")}
          </p>
        </div>
        <br></br>
        בהמשך לשיחת השימוע שנערכה לך בתאריך{" "}
        {props.hearingDate
          ? moment(props.hearingDate).format("DD-MM-YYYY")
          : ""}{" "}
        לצערנו אנו מודיעים לך על סיום יחסי עובד מעביד.
        <p>
          סיום עבודתך בחברה ייכנס לתוקף בתום תקופת ההודעה המוקדמת, בתאריך{" "}
          {props.terminationDate
            ? moment(props.terminationDate).format("DD-MM-YYYY")
            : ""}{" "}
          .
        </p>{" "}
        {props.needWorkPeriod ? (
          <p>אתה מתבקש להמשיך ולעבוד עד לתום תקופת ההודעה המוקדמת.</p>
        ) : (
          <p>אינך מתבקש להמשיך ולעבוד עד לתום תקופת ההודעה המוקדמת.</p>
        )}
        תקופת ההודעה המוקדמת תסתיים בתאריך{" "}
        {props.endNoticeDate
          ? moment(props.endNoticeDate).format("DD-MM-YYYY")
          : ""}
        .<p>אנו מאחלים לך הצלחה בהמשך דרכך.</p>
        <p>בברכה,</p>
        <br></br>
        <br></br>
        {props.company_name}
      </div>
    </Fragment>
  );
};

export default WorkTerminatinoShow;
