// usersReducer.js

import { mapKeys } from "lodash";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
import {
  FETCH_BRANCHES_ADMIN,
  FETCH_PERMISSIONS,
  FETCH_PRIVILEGES,
  FETCH_USERS,
} from "../actions/adminAction/types";

const INITIAL_STATE = {
  privileges: {},
  pages_permissions: {},
  tabs_permissions: {},
  base_permissions: {},
  other_permissions: {},
  general_permissions: {},
  branches: {},
};

const adminReducer  = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PRIVILEGES:
      return { ...state, privileges: { ...mapKeys(payload, "id") } };
    case FETCH_BRANCHES_ADMIN:
      return { ...state, branches: { ...mapKeys(payload, "value") } };
    case FETCH_PERMISSIONS:
      const {
        pages_permissions,
        tabs_permissions,
        base_permissions,
        other_permissions,
        general_permissions
      } = payload;
      return {
        ...state,
        pages_permissions: { ...mapKeys(pages_permissions, "id") },
        tabs_permissions: { ...mapKeys(tabs_permissions, "id") },
        base_permissions: { ...mapKeys(base_permissions, "id") },
        other_permissions: { ...mapKeys(other_permissions, "id") },
        general_permissions: { ...mapKeys(general_permissions, "id") },
      };
    case LOGOUT_ADMIN:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default adminReducer