import React from "react";
import DayPicker from "react-day-picker";

const MONTHS = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];

const WEEKDAYS_LONG = [
  "ראשון",
  "שני",
  "שלישי",
  "רביעי",
  "חמישי",
  "שישי",
  "שבת",
];
const WEEKDAYS_SHORT = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];

class DayPickerControlled extends React.Component {
  state = {
    date: this.props.initDate,
  };

  handleDayChange = (date) => {
    if (this.props.afterDate < date || this.props.beforeDate > date) return;
    this.setState({
      date,
    });
  };

  render() {
    const { date } = this.state;
    return (
      <div className="SelectedWeekExample">
        <DayPicker
          months={MONTHS}
          toMonth={this.props.afterDate}
          fromMonth={this.props.beforeDate}
          showOutsideDays
          onDayMouseDown={(e) => {
            this.props.setDate(e);
            this.props.setShow(false);
          }}
          onDayClick={this.handleDayChange}
          weekdaysLong={WEEKDAYS_LONG}
          weekdaysShort={WEEKDAYS_SHORT}
          selectedDays={[date]}
          month={this.props.initialMonth}
          disabledDays={[
            {
              after: this.props.afterDate,
              before: this.props.beforeDate,
            },
          ]}
        />

        {/* <button
          type="button"
          onClick={() => {
            this.props.setDate(date);
            this.props.setShow(false);
          }}
          className="btn"
          style={{ color: "#00a2ea" }}
        >
          אישור
        </button> */}
      </div>
    );
  }
}

export default DayPickerControlled;
